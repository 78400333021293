import HeaderImage from "../../../StaticImages/Blog/NewPreMedLogo.png";
import FoundersImage from "../../../StaticImages/Blog/FoundersImage.png";
import UmarBoardImage from "../../../StaticImages/Blog/UmarBoardImage.png";
import HasnainCodingImage from "../../../StaticImages/Blog/HasnainCodingImage.png";
import TeamZoomImage from "../../../StaticImages/Blog/TeamZoomImage.png";
import CoreTeamImage from "../../../StaticImages/Blog/CoreTeamImage.png";
export var AdmissionProcedureArticle = {
  HeaderImg:
    "https://premedpk-cdn.sgp1.digitaloceanspaces.com/CDN/Blog/AdmmissionProcessBlogPic1.jpg",
  Tags: ["Featured", "AKU"],
  Title: "Procedure for applying to medical universities in Pakistan.",
  Desc: "A Complete Guide About the Post-MDCAT Interview and Selection Process for Punjab, Sindh, & KPK",
  Date: "December 19, 2022",
  AuthorName: <>Syed Fariz Ali &bull; Zunaira Hameed &bull; Salvia Qazi</>,
  Article: (
    <>
      <p>
        We have been flooded with messages from students inquiring about the
        process of applying for the Merit List after receiving their MDCAT
        scores.
      </p>
      <p>
        In this blog, we've provided the procedure for Sindh, Punjab, and KPK
        Universities so that students have a clear path to all the necessary
        actions that need to be taken to get enrolled in a medical college of
        their choice.
      </p>
      <h2>Punjab</h2>
      <p>
        <strong>What is the ranking of medical universities in Punjab?</strong>
      </p>
      <p>
        If one intends to do <strong>MBBS</strong>
        <ol>
          <li>King Edward Medical University</li>
          <li>Allama Iqbal Medical College</li>
          <li>Services Institute of Medical Sciences</li>
          <li>Ameer ud Din Medical College</li>
          <li>Rawalpindi Medical College</li>
          <li>Nishtar Medical College</li>
          <li>Punjab Medical College</li>
          <li>Gujranwala Medical College</li>
          <li>Quaid-e-Azam Medical College</li>
          <li>Sahiwal Medical College</li>
          <li>Sargodha Medical College</li>
          <li>Nawaz Sharif Medical College</li>
          <li>Khawaja Muhammad Safdar Medical College</li>
          <li>Sheikh Zayed Medical College</li>
          <li>DG Khan Medical College</li>
        </ol>
        Please note that Fatima Jinnah Medical University secures seats only for
        women and you have to apply for it separately . UHS does not deal with
        it.
      </p>
      <p>
        <strong>What is the ranking of medical universities in Punjab?</strong>
      </p>
      <p>
        If one intends to do <strong>BDS</strong>
        <ol>
          <li>De, Montmorency College of Dentistry (DCD), Lahore</li>
          <li>Nishtar Institute of Dentistry (NID), Multan</li>
          <li>DI-PMC, Faisalabad</li>
        </ol>
      </p>
      <p>
        <strong>What happens when the merit list is uploaded?</strong>
        <br />
        Since the University of Health Sciences (UHS) is the administrative body
        for admission in MBBS and BDS degrees across all the public medical and
        dental colleges in Punjab, once the merit list is announced, it is
        uploaded onto the website of UHS. Keep this in your mind that this year,
        you have to upload all your relevant documents online as mentioned by
        UHS on the website. Prior to Covid-19, one had to go to UHS and submit
        all the documents in-person but now you have to upload these documents
        but do read the guidelines that are available on their website.
      </p>
      <p>
        <strong>When do we submit the required documents?</strong>
        <br />
        If you are on the merit list and have been upgraded to a medical or
        dental college, then contact their administration, do visit the
        respective university and submit the documents in person. When you
        submit the original documents and the copies, they will make you a fee
        challan which you have to submit to the bank, and have to give them the
        college receipt. This is how you secure your seat.
      </p>
      <p>
        <strong>Fee structure for public medical and dental colleges</strong>
        <br />
        To be very honest, it varies from college to college and the same
        happens with the hostel accommodation as well. When you visit the
        college to submit the documents, they’ll make you a fee challan which
        shows how much you have to pay for that year.
      </p>
      <p>
        <strong>Some general concerns of the public</strong>
        <br />
        <i>
          If I want to do MBBS and initially I’m granted admission in BDS, do I
          still need to pay for the seat:
        </i>
        <br />
        Yes, you have to pay for the seat in order to secure it. Then some
        people will go the SZABMU, AMC, etc. and will not submit their dues and
        hence you’ll be promoted to the medical college. This will only happen
        if you have submitted your dues for the BDS, or otherwise, UHS will
        consider you uninterested in MBBS or BDS and will cancel your admission.
        <br />
        <br />
        <i>
          If I get promoted to some other medical or dental college, what
          happens to my dues?
        </i>
        If you get promoted to any other college, there may be 2 possibilities,
        <ol>
          <li>
            Either your previous institute transfers your dues to your new
            college and you have to pay the remaining dues only.
          </li>
          <li>
            Or you have to submit your dues separately, and your previous
            college may give you the amount after some time (this may take 6-7
            months).
          </li>
        </ol>
        <p>
          But it is obvious that your money will either be compensated or given
          back to you. Hence you are not at loss but you have to submit your
          dues in every medical or dental college you get promoted to.
        </p>
      </p>
      <p>
        <strong>Our personal recommendations:</strong>
        <br />
        Please note that these are our personal recommendations. To follow these
        or not is totally up to you all.
        <p>
          Please see all the possible seats offered to a Candidate in Punjab as
          given below. There are a total of 3,047 seats for MBBS and 189 seats
          for BDS. So if you have your name within 3,236 seats, it is pretty
          obvious that you’ll have your admission secured even in MBBS because
          among these 3,236 students there will definitely be 189 students who
          prioritize BDS over MBBS. But if you are beyond the 3,236 seats, you
          still need to apply as some of the students have applied to AKU,
          SZABMU, AMC, etc. so you still have a good chance if your merit is
          around 3,350-3,340.
        </p>
        <p>
          If one intends to do BDS rather than MBBS, s/he should choose both
          MBBS and BDS, but prioritize BDS. Then you’ll have 18 preferences,
          your 1st preference should be DCD, Lahore, then NID, and then FMC. You
          then may prioritize MBBS according to the above-mentioned list.
        </p>
        <p>
          If one intends to do MBBS, s/he should choose both MBBS and BDS but
          s/he should choose KEMU, AIMC, SIMS, etc. up to 15 medical colleges,
          and then his/her 16th preference should be DCD, then NID, and so on.
        </p>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img
            src={
              "https://premedpk-cdn.sgp1.digitaloceanspaces.com/CDN/Blog/AdmmissionProcessBlogPic2.jpg.png"
            }
            style={{ maxHeight: "600px" }}
          />
          <br />
        </div>
      </p>
      <p>
        <h1>Sindh</h1>
      </p>
      <p>
        <strong>What happens once the merit list is released?</strong>
        <p>
          This year, Dow Medical College is the conducting body for admissions.
          The university will announce a day and time on its website for
          specific merit numbers to come to campus (the first 350 on the first
          day, the next 350 on the second day, and so on), to make an official
          decision between MBBS or BDS, and which university the students want
          to attend. You can go alone, but since this is a significant decision
          in your life, it is preferable to include at least one of your
          parents. Please make sure you are free on that day because the admin
          gets upset and usually causes problems if the student doesn't show up
          him/herself.
        </p>
      </p>
      <p>
        <strong>
          When do you get to choose between DMC self-finance and JSMU merit
          seats?
        </strong>
        <p>
          They begin calling individuals by their merit number and offering them
          the option between MBBS and BDS. The students may take a merit seat up
          to merit number 240 (the fee of which has been mentioned below). If
          all the merit seats for DMC are filled by the time your merit number
          is called, you will have the option to choose between DMC self-finance
          if seats are still available, and JSMU merit. If the self-finance
          seats are full, you are given the option of going to JSMU merit or
          KMDC merit.
        </p>
      </p>
      <p>
        <strong>What is the ranking of medical universities in Sindh?</strong>
        <ol>
          <li>Dow Medical College, Dow University of Health Sciences</li>
          <li>Sindh Medical College, Jinnah Sindh Medical University</li>
          <li>Karachi Medical and Dental College</li>
        </ol>
      </p>
      <p>
        For the rest of the colleges in Sindh, the teaching method and
        curriculum is quite similar and students usually opt for the medical
        college most accessible for them.
      </p>
      <p>
        <strong>When do we submit the required documents?</strong>
        <br />
        When you are called to DMC, you are required to take the following
        documents with you
      </p>
      <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img
            src={
              "https://premedpk-cdn.sgp1.digitaloceanspaces.com/CDN/Blog/AdmmissionProcessBlogPic3.jpg.png"
            }
            style={{ maxHeight: "600px" }}
          />
          <br />
        </div>
      <p>
        Please make sure to make multiple photocopies of all these documents
        since the admin will collect these documents from you and give them back
        at the end of your graduation. For the affidavit, purchase a 100 rupee
        stamp paper from the city court (very close to DMC, search on Google
        Maps if you are confused), print the affidavit given in the prospectus
        onto it, fill in the information, and get it signed by a court officer.
        The prospectus is usually already available at these city court lawyers,
        so you don’t have to get it typed right there and then.
      </p>
      <p>
        <strong>
          A very important aspect of this process that not a lot of people know
          about:
        </strong>
        <br />
        If you are getting admission on a JSMU merit or self-finance seat and
        you want to try for a DMC merit/sef-finance seat, you need to inform the
        admin that you are interested in upgrading your seat. If someone in the
        future decides to back out then you will be upgraded to a DMC seat. For
        this, you have to deposit around 15,000 rupees, but in the case that no
        one drops out, then you do get your deposit back.
      </p>
      <p>
        <strong>Fee structure for public universities in Sindh</strong>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img
            src={
              "https://premedpk-cdn.sgp1.digitaloceanspaces.com/CDN/Blog/AdmmissionProcessBlogPic5.jpg.png"
            }
            style={{ maxHeight: "600px" }}
          />
        </div>
        <br />
      </p>
      <h1>Khyber Pakhtunkhwa</h1>
      <p>
        <strong>What happens once the merit list is released?</strong>
        <p>
          For KPK, information about the number of applicants being called for
          interviews on specified dates will be posted on the official ETEA
          website (they might also be uploaded to the Khyber Medical University
          website). Usually, on Day 1, Merit numbers 1-350 are called to decide
          their universities and then the next day, 351-700 are called and then
          so on… The interview process is more of a formality to verify your
          documents. Students are gathered in the designated spot and will be
          called merit-wise for document verification and admission.
        </p>
      </p>
      <p>
        <strong>
          What is the ranking of KPK public sector medical colleges?
        </strong>
        <ol>
          <li>Khyber Medical College Peshawar</li>
          <li> Khyber Girls Medical College</li>
          <li> Ayub Medical College, Abbottabad</li>
          <li> Bacha Khan Medical College, Mardan</li>
          <li> Bannu Medical College, Bannu</li>
          <li> Gajju Khan Medical College Swabi</li>
          <li> Gomal Medical College</li>
          <li> KMU Institute of Medical Sciences, Kohat</li>
          <li> Saidu Medical College Swat</li>
          <li>Nowshera Medical College Nowshera</li>
        </ol>
      </p>
      <p>
        <strong>
          What is the fee structure for public sector medical colleges?
        </strong>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img
            src={
              "https://premedpk-cdn.sgp1.digitaloceanspaces.com/CDN/Blog/AdmmissionProcessBlogPic6.jpg.png"
            }
            style={{ maxHeight: "600px" }}
          />
          <br />
        </div>
        Note: The above list excludes additional fees such as hostel, transport,
        security, etc.
      </p>
      <p>
        <strong>
          When do you get to choose between Khyber Medial College self-finance
          and Ayub Medical College merit seats?
        </strong>
        <p>
          {" "}
          If all the merit seats for Khyber Medical College (KMC) are filled by
          the time your merit number is called, you’ll get the choice to either
          opt for an open merit seat in Ayub Medical College, or a self-financed
          seat in KMC. The same option also presents itself in KMC BDS and AMC
          MBBS where students then have to choose whether they want to pursue
          Dentistry at Khyber Medical College or MBBS at Ayub Medical College.
        </p>
      </p>
      <p>
        <strong>When do we submit the required documents?</strong>
        <p>
          On the interview day, you will be required to take the following
          documents along:
        </p>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img
            src={
              "https://premedpk-cdn.sgp1.digitaloceanspaces.com/CDN/Blog/AdmmissionProcessBlogPic7.jpg.png"
            }
            style={{ maxHeight: "600px" }}
          />
          <br />
        </div>
        <p>
          As far as the affidavit is concerned, you can purchase a stamp paper
          for Rs. 100/- from the city court, print the affidavit given in the
          prospectus onto it, fill the information, and get it signed by a court
          officer. Please ensure that you make multiple photocopies of the
          documents mentioned above as you might need these in the future.{" "}
          <br />
          <br />
          Is there any marks deduction policy for improvers? <br />
          <br />
          No. For the year 2022-23, KMU will neither deduct the grace marks
          awarded in FSc., nor will there be deductions based on FSc/ETEA
          improvement.
        </p>
      </p>
      <p>
        <strong>Is there any extra-credit policy in place?</strong>
        <p>
          Yes, clearing the Hafiz-e-Quran interview will get you 20 extra marks
          which will be added to the final merit list. The time and place for
          the Hafiz-e-Quran interview will also be posted on either the official
          page for ETEA, KMU, or both.
          <br />
          <br />
          The required documents would also be mentioned in the notice. However,
          it is always better to take the entire list of documents you took for
          the original interview.
        </p>
      </p>
    </>
  ),
};
