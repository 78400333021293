import React from "react";
import styles from "./FeatureBox.module.css";
import { Button } from "shards-react";
import { useHistory, useParams, withRouter } from "react-router";
import { BrowserRouter as Router, Route, Switch, Link } from "react-router-dom";
import image from "../../StaticImages/Hasnain.jpg";

const FeatureBox = (props) => {
  const history = useHistory();
  const { h1, h2, span, align, video, buttonText } = props;
  var right;
  if (align === "Right") {
    right = true;
  } else {
    right = false;
  }
  // right = true;
  //console.log(right);
  return (
    <div
      className={
        right
          ? styles.FeatureBox_MainBox
          : `${styles.FeatureBox_MainBox} ${styles.FeatureBox_Right}`
      }
    >
      <h2>{h2}</h2>
      <div className={` ${styles.FeatureFlexStart} ${styles.FeatureImage} `}>
        <video muted={true} autoPlay={true} loop playsInline webkit-playsinline>
          <source src={video} type="video/mp4" />
        </video>
      </div>
      <div className={`${styles.FeatureFlexEnd} ${styles.FeatureInfo}`}>
        <h2>{h2}</h2>
        <h1>{h1}</h1>
        <span>{span}</span>
        <div className={styles.FeatureBox_Button}>
          <Link to={"/attempt?deck=PMC%20National%20MDCAT%202020"}>
            {" "}
            <Button size="lg" theme="RedAccent">
              {buttonText}
            </Button>
          </Link>
        </div>
        <div className={styles.FeatureBox_Button_Mobile}>
          <Link to={"/attempt?deck=PMC%20National%20MDCAT%202020"}>
            {" "}
            <Button size="" theme="RedAccent">
              {buttonText}
            </Button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default FeatureBox;
