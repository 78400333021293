import React from "react";
import styles from "./QuestionComponent.module.css";
import { CardHeader } from "shards-react";
import ReportProblemIcon from "@material-ui/icons/ReportProblem";
import SkipNextIcon from "@material-ui/icons/SkipNext";
import MatButton from "@material-ui/core/Button";

import ReportQuestion from "./ReportQuestionComponent";
import { StoreMallDirectorySharp } from "@material-ui/icons";

const QuestionCardHeader = ({ skipOnClick, reportOnClick }) => {
  return (
    <CardHeader className={styles.CardHeader}>
      <div className={styles.QuestionCardHeader}>
        <div className={styles.SkipButton}>
          <MatButton
            color="primary"
            onClick={(e) => {
              if (e.screenX === 0 && e.screenY === 0) {
                //console.log("enter")
                return false;
              }
              //console.log(e)
              skipOnClick();
            }}
            disableFocusRipple
            style={{ fontFamily: "Rubik" }}
          >
            <SkipNextIcon />
            Skip to Question
          </MatButton>
        </div>
        <div className={styles.ReportButton}>
          <MatButton
            color="secondary"
            onClick={() => {
              reportOnClick();
            }}
            style={{ fontFamily: "Rubik" }}
          >
            <ReportProblemIcon />
            Report Question
          </MatButton>
        </div>
      </div>
    </CardHeader>
  );
};

export default QuestionCardHeader;
