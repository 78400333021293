import React, { useEffect, useContext, useState, Suspense } from "react";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import { formatMs, Snackbar } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { LogReport, GetTags } from "../../APIManager";
import Tags from "../Tag-Textbox/Tags";

import { FormCheckbox } from "shards-react";
import { StylesContext } from "@material-ui/styles";

import styles from "./QuestionComponent.module.css";

import NotLoggedInToggle from "./NotLoggedInToggle";

import { UserContext } from "../../userContext";

import { FrontEndUpdateQuestion } from "../../APIManager";

import { SubTopicTags } from "../../constants";
import "react-quill/dist/quill.snow.css";

const ReactQuill = React.lazy(() => import("react-quill"));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function FrontEndEditComponent({
  OnCloseCB,
  OnSubmitCB,
  deckName,
  questionID,
  isOpen,
  question,
}) {
  const [currentTags, SetCurrentTags] = useState(question.Tags);
  const [explanation, SetExplanation] = useState(question.ExplanationText);
  const [originalSuggestions, SetOriginalSuggestions] = useState([]);
  const [suggestions, SetSuggestions] = useState([]);

  const [subtopicTags, SetSubtopicTags] = useState([]);

  console.log(explanation);

  useEffect(() => {
    GetTags().then((res) => {
      var newTags = [...res.data];

      var allSubtopicTags = [];
      for (var key in SubTopicTags) {
        allSubtopicTags = [...allSubtopicTags, ...SubTopicTags[key]];
      }
      newTags = newTags.filter((t) => !allSubtopicTags.includes(t.name));
      //SetSuggestions(newTags);
      SetOriginalSuggestions(res.data);
    });
  }, []);

  console.log(suggestions);

  useEffect(() => {
    if (currentTags.length > 0) {
      var cTags = currentTags.map((t) => t.name);

      var allSubtopicTags = [];
      for (var key in SubTopicTags) {
        allSubtopicTags = [...allSubtopicTags, ...SubTopicTags[key]];
      }
      var nSuggestions = originalSuggestions.filter(
        (t) => !allSubtopicTags.includes(t.name)
      );

      var subtopicTags = [];

      cTags.forEach((tag) => {
        if (SubTopicTags[tag]) {
          nSuggestions = [
            ...nSuggestions,
            ...originalSuggestions.filter((t) =>
              SubTopicTags[tag].includes(t.name)
            ),
            subtopicTags = [...subtopicTags, ...originalSuggestions.filter((t) =>
              SubTopicTags[tag].includes(t.name)
            )]
          ];
        }
      });
      SetSuggestions(nSuggestions);
      SetSubtopicTags(subtopicTags)
      return;
    }
    var newTags = [...originalSuggestions];

    var allSubtopicTags = [];
    for (var key in SubTopicTags) {
      allSubtopicTags = [...allSubtopicTags, ...SubTopicTags[key]];
    }
    newTags = newTags.filter((t) => !allSubtopicTags.includes(t.name));
    SetSuggestions(newTags);
  }, [currentTags, originalSuggestions]);

  function onTagDelete(i) {
    const tags = currentTags.slice(0);
    tags.splice(i, 1);
    SetCurrentTags([...tags]);
    /*  var allSubtopicTags = [];
    for (var key in SubTopicTags) {
      allSubtopicTags = [...allSubtopicTags, ...SubTopicTags[key]];
    }
    if (allSubtopicTags.includes(currentTags[i].name)) {
      const tags = currentTags.slice(0);
      tags.splice(i, 1);
      SetCurrentTags([...tags]);
    } */
  }

  function onTagAddition(tag) {
    const tags = [].concat(currentTags, tag);
    SetCurrentTags([...tags]);

    /*  var allSubtopicTags = [];
    for (var key in SubTopicTags) {
      allSubtopicTags = [...allSubtopicTags, ...SubTopicTags[key]];
    }
    if (allSubtopicTags.includes(tag.name)) {
      const tags = [].concat(currentTags, tag);
      SetCurrentTags([...tags]);
    } */
  }

  const modules = {
    toolbar: [
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link"],
      ["clean"],
    ],
  };

  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
  ];

  console.log(subtopicTags)

  return (
    <div>
      <Dialog
        scroll="body"
        open={isOpen}
        TransitionComponent={Transition}
        keepMounted
        onClose={OnCloseCB}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        maxWidth="md"
        fullWidth
      >
        <DialogTitle id="alert-dialog-slide-title">
          {"Edit Question"}
        </DialogTitle>
        <DialogContent style={{ height: "500px" }}>
          <DialogContentText id="alert-dialog-slide-description">
            Follow the instructions provided by your manager
          </DialogContentText>
          <label>Subtopic Tags:</label>
          <div className="DefaultTagComponent">
            <Tags
              suggestions={suggestions}
              onDelete={onTagDelete}
              onAddition={onTagAddition}
              tags={currentTags}
            />
          </div>
          <div>
            {subtopicTags.map((t)=> `${t.name}, `)}
          </div>
          <br />
          <label>Explanation:</label>
          <Suspense fallback={<div>Loading...</div>}>
            <div className={styles.ExplanationText}>
              <ReactQuill
                theme="snow"
                value={explanation}
                onChange={(e) => {
                  console.log();
                  SetExplanation(e);
                }}
                modules={modules}
                formats={formats}
              />
              <label hidden={explanation.length > 15} style={{ color: "red" }}>
                Question Explanation cannot be left blank
              </label>
            </div>
          </Suspense>
        </DialogContent>

        <DialogActions>
          <Button
            disabled={explanation.length < 15}
            style={{ margin: "1em" }}
            onClick={() => {
              OnSubmit();
            }}
            variant="contained"
            color="primary"
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );

  function OnSubmit() {
    FrontEndUpdateQuestion({
      deckName: deckName,
      updatedTags: currentTags,
      updatedExplanation: explanation,
      questionID: questionID,
    }).then((res) => {
      OnSubmitCB();
    });
  }

  function ResetFields() {}
}
