import React, { useState, useContext, useEffect } from "react";
import styles from "./styles.module.css";
import { Button, Modal, ModalBody, ModalHeader } from "shards-react";
import Alert from "@material-ui/lab/Alert";
import AlertTitle from "@material-ui/lab/AlertTitle";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import { BrowserRouter as Router, Route, Switch, Link } from "react-router-dom";
import { useLocation, useHistory, useParams } from "react-router-dom";
import { UserContext } from "../../userContext";

import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";

import loginIcon from "../../StaticImages/LoginPageIcon.jpg";
import { ForgotPasswordRequest, ResetPassword } from "../../APIManager";
import { VerifyPasswordResetToken } from "../../APIManager";

import GradientBtn from "../../Components/GradientBtn/GradientBtn";

const ForgotPassword = () => {
  let [userContext, SetUserContext] = useContext(UserContext);

  const { userid, token } = useParams();
  //console.log(userid)
  //console.log(token)

  const [isValidUrl, SetIsValidUrl] = useState("Loading");
  const [showModal, SetShowModal] = useState(false);
  const [waitingOnResponse, SetWaitingOnResponse] = useState(false);
  const [showPassword, SetShowPassword] = useState(false);
  const [password, SetPassword] = useState("");
  const [confirmpassword, SetConfirmpassword] = useState("");
  const [passwordValid, SetPasswordValid] = useState(true);
  const [confirmpasswordValid, SetConfirmpasswordValid] = useState(true);
  const [passwordErrorText, SetPasswordErrorText] = useState("");
  const [confirmpasswordErrorText, SetConfirmpasswordErrorText] = useState("");
  const [shakePassword, SetShakePassword] = useState(false);
  const [shakeConfirmpassword, SetShakeConfirmpassword] = useState(false);

  const [serverResponse, SetServerResponse] = useState("");
  const [serverResponseText, SetServerResponseText] = useState("");

  //console.log(userContext);
  let history = useHistory();

  useEffect(() => {
    if (userContext.isLoggedIn) {
      history.push("/dashboard");
    } else {
      VerifyPasswordResetToken(userid, token).then((res) => {
        if (res.data.success) {
          SetIsValidUrl("true");
        } else {
          SetIsValidUrl("false");
        }
      });
    }
  }, []);

  function OnPasswordChange(event) {
    var newPassword = event.target.value.trim();
    SetPassword(newPassword);
    ValidatePassword(newPassword);
    ValidateConfirmpassword(confirmpassword, newPassword);
  }

  function OnConfirmpasswordChange(event) {
    var newConfirmpassword = event.target.value.trim();
    SetConfirmpassword(newConfirmpassword);
    ValidateConfirmpassword(newConfirmpassword, password);
  }
  function ValidatePassword(newPassword) {
    if (newPassword.length === 0) {
      SetPasswordErrorText("Password can't be empty");
      SetPasswordValid(false);
      return false;
    }
    if (newPassword.length < 8) {
      SetPasswordErrorText("Password must be more than 8 characters long");
      SetPasswordValid(false);
      return false;
    } else {
      SetPasswordErrorText("");
      SetPasswordValid(true);
      return true;
    }
  }

  function ValidateConfirmpassword(newConfirmpassword, password) {
    if (newConfirmpassword !== password) {
      SetConfirmpasswordErrorText("Passwords don't match");
      SetConfirmpasswordValid(false);
      return false;
    } else {
      SetConfirmpasswordErrorText("");
      SetConfirmpasswordValid(true);
      return true;
    }
  }
  function OnSubmitClicked() {
    var passwordvalid = ValidatePassword(password);
    var confirmpasswordvalid = ValidateConfirmpassword(
      confirmpassword,
      password
    );
    SetShakePassword(!passwordvalid);
    SetShakeConfirmpassword(!confirmpasswordvalid);

    if (passwordvalid && confirmpasswordvalid) {
      SetWaitingOnResponse(true);
      SetServerResponse("");
      SetServerResponseText("");
      ResetPassword(userid, token, password)
        .then((res) => {
          SetWaitingOnResponse(false);
          ResolveServerResponse(res);
        })
        .catch(() => {
          SetWaitingOnResponse(false);
          SetServerResponse("Error");
          SetServerResponseText("Network Error. Try Again");
        });
    }
  }
  function ResolveServerResponse(res) {
    var data = res.data;
    if (data.success === true) {
      SetServerResponse("Success");
      SetServerResponseText("Successfully Reset Password");
      SetShowModal(true);
    } else {
      SetServerResponse("Error");
      SetServerResponseText(data.ErrorText);
    }
  }

  return (
    <div className={styles.MainDiv} id="LoginPageMainDiv">
      <Modal open={showModal} toggle={() => {}}>
        <ModalHeader>Success</ModalHeader>
        <ModalBody>
          <h4>Password has been changed</h4> <br />
          <br />
          <Button
            onClick={() => {
              history.push("/dashboard");
            }}
          >
            Login
          </Button>
        </ModalBody>
      </Modal>
      <div className={styles.Container}>
        <div
          className={styles.ForgotPasswordBox}
          style={{ display: isValidUrl === "true" ? "" : "none" }}
        >
          <div className={styles.Heading}>Reset Password</div>
          <div
            className={styles.ErrorAlert}
            style={serverResponse ? { display: "" } : { display: "none" }}
          >
            <Alert severity={serverResponse.toLowerCase()}>
              <AlertTitle>{serverResponse}</AlertTitle>
              {serverResponseText}
            </Alert>
          </div>
          <div
            className={styles.InputField}
            style={
              shakePassword ? { animation: "shake .5s" } : { animation: "" }
            }
            onAnimationEnd={() => {
              SetShakePassword(false);
            }}
          >
            <TextField
              className={styles.InputField}
              fullWidth
              id="outlined-basic"
              label="New Password"
              error={!passwordValid}
              variant="outlined"
              type={showPassword ? "text" : "password"}
              helperText={passwordErrorText}
              onChange={OnPasswordChange}
              inputProps={{
                style: { fontSize: "1.2em" },
                maxLength: 50,
              }} // font size of input text
              InputLabelProps={{
                style: { fontSize: "1.2em", backgroundColor: "white" },
              }} // font size of input label
              //FormHelperTextProps={{ style: { fontSize: "1.2em" } }} // font size of input label
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => {
                        SetShowPassword(!showPassword);
                      }}
                    >
                      {showPassword ? (
                        <VisibilityIcon />
                      ) : (
                        <VisibilityOffIcon />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </div>
          <div
            className={styles.InputField}
            style={
              shakeConfirmpassword
                ? { animation: "shake .5s" }
                : { animation: "" }
            }
            onAnimationEnd={() => {
              SetShakeConfirmpassword(false);
            }}
          >
            <TextField
              className={styles.InputField}
              fullWidth
              id="outlined-basic"
              label="Confirm New Password"
              error={!confirmpasswordValid}
              variant="outlined"
              type={showPassword ? "text" : "password"}
              helperText={confirmpasswordErrorText}
              onChange={OnConfirmpasswordChange}
              inputProps={{
                style: { fontSize: "1.2em" },
                maxLength: 50,
              }} // font size of input text
              InputLabelProps={{
                style: { fontSize: "1.2em", backgroundColor: "white" },
              }} // font size of input label
              //FormHelperTextProps={{ style: { fontSize: "1.2em" } }} // font size of input label
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => {
                        SetShowPassword(!showPassword);
                      }}
                    >
                      {showPassword ? (
                        <VisibilityIcon />
                      ) : (
                        <VisibilityOffIcon />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </div>
          <div className={styles.Button}>
            <GradientBtn
              style={{
                fontSize: "1.1em",
                borderRadius: "30px",
                margin: 0,
                marginRight: "1em",
                padding: "1em 1.8em",
                width: "100%",
              }}
              gradient={"#D31027 0%, #EA384D  51%, #D31027  100%"}
              disabled={waitingOnResponse}
              onClick={() => {
                OnSubmitClicked();
              }}
            >
              Reset Password
            </GradientBtn>
          </div>
        </div>
        <div
          className={styles.InvalidLink}
          style={{ display: isValidUrl === "false" ? "" : "none" }}
        >
          Invalid or expired Link <br />
          <span>
            You can request another link here{" "}
            <Link to="/forgot-password">Forgot Password</Link>
          </span>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
