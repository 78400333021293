import React, { useState, useEffect, useContext } from "react";
import styles from "./LoginPageNew.module.css";

import RocketImg from "../../StaticImages/SignUpPage/Rocket-rafiki.svg";
import Ecliple58Img from "../../StaticImages/SignUpPage/Ellipse 58.svg";

import { Button } from "shards-react";
import Alert from "@material-ui/lab/Alert";
import AlertTitle from "@material-ui/lab/AlertTitle";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import { BrowserRouter as Router, Route, Switch, Link } from "react-router-dom";
import { useLocation, useHistory } from "react-router-dom";
import { UserContext } from "../../userContext";
import { RefreshUserContext } from "../../UtilityFunctions";

import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";

import loginIcon from "../../StaticImages/LoginPageIcon.jpg";
import { Login, getLoggedInUser } from "../../APIManager";

export default function LoginPage() {
  let [userContext, SetUserContext] = useContext(UserContext);
  //console.log(userContext);
  let history = useHistory();

  useEffect(() => {
    if (userContext.isLoggedIn) {
      history.push("./dashboard");
    }
  }, []);

  const [waitingOnResponse, SetWaitingOnResponse] = useState(false);

  const [showPassword, SetShowPassword] = useState(false);
  const [shakeUsername, SetShakeUsername] = useState(false);
  const [shakePassword, SetShakePassword] = useState(false);

  const [username, SetUsername] = useState("");
  const [password, SetPassword] = useState("");
  const [usernameValid, SetUsernameValid] = useState(true);
  const [passwordValid, SetPasswordValid] = useState(true);
  const [usernameErrorText, SetUsernameErrorText] = useState("");
  const [passwordErrorText, SetPasswordErrorText] = useState("");
  const [serverError, SetServerError] = useState(false);
  const [serverErrorText, SetServerErrorText] = useState("");

  function OnUsernameChange(event) {
    var newUsername = event.target.value.trim();
    SetUsername(newUsername);
    ValidateUsername(newUsername);
  }

  function ValidateUsername(newUsername) {
    if (newUsername.length === 0) {
      SetUsernameErrorText("This field can not be empty");
      SetUsernameValid(false);
      return false;
    }
    var regex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!regex.test(newUsername)) {
      SetUsernameErrorText("Please enter a valid email address");
      SetUsernameValid(false);
      return false;
    } else {
      SetUsernameErrorText("");
      SetUsernameValid(true);
      return true;
    }
  }

  function OnPasswordChange(event) {
    var newPassword = event.target.value.trim();
    SetPassword(newPassword);
    ValidatePassword(newPassword);
  }
  function ValidatePassword(newPassword) {
    if (newPassword.length === 0) {
      SetPasswordErrorText("This field can not be empty");
      SetPasswordValid(false);
      return false;
    } else {
      SetPasswordErrorText("");
      SetPasswordValid(true);
      return true;
    }
  }

  function OnSubmitClicked() {
    var usernamevalid = ValidateUsername(username);
    var passwordvalid = ValidatePassword(password);
    SetShakeUsername(!usernamevalid);
    SetShakePassword(!passwordvalid);
    if (usernamevalid && passwordvalid) {
      SetWaitingOnResponse(true);
      SetServerError(false);
      SetServerErrorText(false);
      Login({
        username: username.toLowerCase().trim(),
        password: password.trim(),
      })
        .then((res) => {
          SetWaitingOnResponse(false);
          ResolveServerResponse(res);
        })
        .catch(() => {
          SetWaitingOnResponse(false);
          SetServerError(true);
          SetServerErrorText("Network Error. Try Again");
        });
    }
  }

  function ResolveServerResponse(res) {
    var data = res.data;
    if (data.success === true) {
      RefreshUserContext(SetUserContext, userContext).then((res) => {
        history.push("/dashboard");
      });
    } else {
      SetServerError(true);
      SetServerErrorText(data.status);
    }
  }

  return (
    <div
      className={`${styles.parentContainer} position-relative d-flex justify-content-center`}
    >
      <div className={`${styles.mainContainer} m-0 p-5 Name`}>
        <div className={`row ${styles.front}`}>
          <div class="col-6 col-md-3 d-flex flex-column justify-content-center">
            <h1 className={styles.mainTitle}>
              We've missed <span className={styles.blueUnderline}>you</span>{" "}
            </h1>
          </div>
          <div class="col-6 col-md-3">
            <img className={styles.mainGraphic} src={RocketImg} alt="" />
          </div>
          <div class="col-12 col-md d-flex flex-row-reverse">
            <div
              className={`${styles.loginCard} position-relative p-4 d-flex flex-column justify-content-between`}
            >
              <h1 className={`${styles.loginCardTitle} w-50 mt-4`}>
                Welcome Back!
              </h1>
              <div class="">
                <div
                  className={styles.ErrorAlert}
                  style={serverError ? { display: "" } : { display: "none" }}
                >
                  <Alert severity="error">
                    <AlertTitle>Error</AlertTitle>
                    {serverErrorText}
                  </Alert>
                </div>
                <div
                  className={styles.InputField}
                  style={
                    shakeUsername
                      ? { animation: "shake .5s" }
                      : { animation: "" }
                  }
                  onAnimationEnd={() => {
                    SetShakeUsername(false);
                  }}
                >
                  <TextField
                    fullWidth
                    id="email"
                    name="email"
                    label={username ? "" : "Email"}
                    error={!usernameValid}
                    variant="outlined"
                    helperText={usernameErrorText}
                    inputProps={{ style: { fontSize: "1em" } }} // font size of input text
                    InputLabelProps={{
                      style: { fontSize: "1em", backgroundColor: "white" },
                      shrink: false,
                    }} // font size of input label
                    onChange={OnUsernameChange}
                    onKeyPress={(e) => {
                      if (e.key == "Enter") {
                        OnSubmitClicked();
                      }
                    }}
                  />
                </div>

                <div
                  className={styles.InputField}
                  style={
                    shakePassword
                      ? { animation: "shake .5s" }
                      : { animation: "" }
                  }
                  onAnimationEnd={() => {
                    SetShakePassword(false);
                  }}
                >
                  <TextField
                    fullWidth
                    id="password"
                    name="password"
                    label={password ? "" : "Password"}
                    error={!passwordValid}
                    variant="outlined"
                    type={showPassword ? "text" : "password"}
                    helperText={passwordErrorText}
                    onChange={OnPasswordChange}
                    inputProps={{
                      style: { fontSize: "1em" },
                      maxLength: 50,
                    }} // font size of input text
                    InputLabelProps={{
                      style: { fontSize: "1em", backgroundColor: "white" },
                      shrink: false,
                    }} // font size of input label
                    //FormHelperTextProps={{ style: { fontSize: "1em" } }} // font size of input label
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => {
                              SetShowPassword(!showPassword);
                            }}
                          >
                            {showPassword ? (
                              <VisibilityIcon />
                            ) : (
                              <VisibilityOffIcon />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    onKeyPress={(e) => {
                      if (e.key == "Enter") {
                        OnSubmitClicked();
                      }
                    }}
                  />
                </div>
                <div class="d-flex justify-content-between align-items-center">
                  {/*   <h1 class={`${styles.signInLabel}`}>Sign In</h1>
                  <button
                    class="signInButton"
                    disabled={waitingOnResponse}
                    onClick={OnSubmitClicked}
                  >
                    <i class="bi bi-arrow-right-circle-fill"></i>
                  </button> */}
                  <button
                    className={`${styles.submitButton} mb-2 w-100`}
                    disabled={waitingOnResponse}
                    onClick={OnSubmitClicked}
                  >
                    <div class="d-flex m-0 p-0 justify-content-between">
                      <p className={`${styles.buttonLabel} m-0`}>Log in</p>
                    </div>
                  </button>
                </div>
              </div>

              <div class="row">
                <div class="col">
                  <a href="">
                    <Link to="/signup">
                      {/*  <span className={styles.smallWithoutUnderline}>
                        Don't have <br />
                        an account?
                      </span>{" "}  <br />*/}

                      <span className={styles.smallBlueUnderline}>
                        Sign up for free
                      </span>
                    </Link>
                  </a>
                </div>
                <div class="col text-end" style={{ textAlign: "end" }}>
                  <a href="">
                    <Link to="/forgot-password">
                      {/*  <span className={styles.smallWithoutUnderline}>
                        Don't remember
                        <br />
                        your password?
                      </span>{" "}
                      <br /> */}
                      <span className={styles.smallRedUnderline}>
                        Reset password
                      </span>
                    </Link>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <img
          src={Ecliple58Img}
          className={`${styles.behind} ${styles.TopLeftCircle} position-absolute`}
        />
      </div>
      <img
        src={Ecliple58Img}
        className={`${styles.blur} ${styles.BlurCircle} position-absolute`}
      />
    </div>
  );
}
