import React from "react";
import BlogVectorImg from "../../StaticImages/BlogVectorEdited.png";
import { AcingAkuTestArticle } from "./Articles/LifeAtAku";
import { PreMedStoryArticle } from "./Articles/PreMedStory";
import { MBBSvsBDSArticle } from "./Articles/MBBSvsBDS";
import { DomicileArticle } from "./Articles/Domicile";
import { AKUTestArticle } from "./Articles/AKUTest";
import { AKUInterviewArticle } from "./Articles/AKUInterview";
import { InternationalStudentsArticle } from "./Articles/InternationalStudents";
import { AKUTestVlogArticle } from "./Articles/AKUTestVlog";
import { ReapplyingtoAKUArticle } from "./Articles/ReapplyingtoAKU";
import { useParams, useHistory } from "react-router-dom";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import "./Blog.scss";
export default function Blog() {
  let history = useHistory();
  const DeviceQuery470 = useMediaQuery("(max-width:470px)");
  return (
    <div className="BlogScope">
      <section class="hero is-fullheight-desktop">
        <div class="hero-head has-text-centered">
          {!DeviceQuery470 ? (
            <h1
              class="title has-text-weight-bold spectrumUnderline"
              style={{
                marginTop: "5%",
                marginBottom: "2em",
                fontSize: "2.5rem",
              }}
            >
              Welcome to PreMed.PK's Blog
            </h1>
          ) : (
            <h1
              class="title has-text-weight-bold"
              style={{
                marginTop: "5%",
                marginBottom: "2em",
                fontSize: "2.5rem",
              }}
            >
              <div class="spectrumUnderline">Welcome to</div>
              <div class="spectrumUnderline">PreMed.PK's Blog</div>
            </h1>
          )}
          <h2 class="subtitle is-6"></h2>
        </div>
        <div class="container has-text-centered hero-cont">
          <img src={BlogVectorImg} alt="Placeholder image" class="hero-img" />
        </div>
        {/*       <!-- <div class="hero-body is-align-items-start"></div> --> */}
      </section>

      <section class="section container">
        <div class="columns is-multiline blog-cards block is-centered">
          {/*    <div class="column is-12">
            <div class="card columns is-gapless">
              <div class="column">
                <figure class="image is-16by9">
                  <img
                    src="./images/dmc.jpeg"
                    alt="Placeholder image"
                    class="blog-img"
                  />
                </figure>
              </div>
              <div class="column">
                <div class="card-content">
                  <div class="content">
                    <p class="subtitle is-6 has-text-grey-light">10 min read</p>
                    <p class="title is-3">Life in DMC</p>
                    <p class="has-text-grey is-5">
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                      Optio, possimus.
                    </p>
                  </div>
                  <div class="content">
                    <a class="icon-text has-text-weight-bold">
                      <span class="pm-span-underline">Read More</span>
                      <span class="icon">
                        <i class="fas fa-arrow-right"></i>
                      </span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
          <div class="column is-one-third">
            <div
              class="card"
              onClick={() => {
                history.push(`/blog/1`);
              }}
            >
              <div class="card-image">
                <figure class="image is-4by3">
                  {/*    <!-- <img src="https://via.placeholder.com/1280x960" alt="Placeholder image" /> --> */}
                  <img
                    src={PreMedStoryArticle.HeaderImg}
                    alt="Placeholder image"
                    class="blog-img"
                  />
                </figure>
              </div>
              <div class="card-content">
                <div class="content">
                  <p class="subtitle is-6 has-text-grey-light">10 min read</p>
                  <p class="title is-3">{PreMedStoryArticle.Title}</p>
                  <p class="has-text-grey is-5">{PreMedStoryArticle.Desc}</p>
                </div>
                <div class="content">
                  <a
                    class="icon-text has-text-weight-bold"
                    onClick={() => {
                      history.push(`/blog/1`);
                    }}
                  >
                    <span class="pm-span-underline">Read More</span>
                    <span class="icon">
                      <i class="fas fa-arrow-right"></i>
                    </span>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div class="column is-one-third">
            {" "}
            <div
              class="card"
              onClick={() => {
                history.push(`/blog/2`);
              }}
            >
              <div class="card-image">
                <figure class="image is-4by3">
                  {/*    <!-- <img src="https://via.placeholder.com/1280x960" alt="Placeholder imageasdasasd" /> --> */}
                  <img
                    src={AcingAkuTestArticle.HeaderImg}
                    alt="Placeholder image"
                    class="blog-img"
                  />
                </figure>
              </div>
              <div class="card-content">
                <div class="content">
                  <p class="subtitle is-6 has-text-grey-light">10 min read</p>
                  <p class="title is-3">{AcingAkuTestArticle.Title}</p>
                  <p class="has-text-grey is-5">{AcingAkuTestArticle.Desc}</p>
                </div>
                <div class="content">
                  <a
                    class="icon-text has-text-weight-bold"
                    onClick={() => {
                      history.push(`/blog/2`);
                    }}
                  >
                    <span class="pm-span-underline">Read More</span>
                    <span class="icon">
                      <i class="fas fa-arrow-right"></i>
                    </span>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div class="column is-one-third">
            {" "}
            <div
              class="card"
              onClick={() => {
                history.push(`/blog/3`);
              }}
            >
              <div class="card-image">
                <figure class="image is-4by3">
                  {/*    <!-- <img src="https://via.placeholder.com/1280x960" alt="Placeholder image" /> --> */}
                  <img
                    src={MBBSvsBDSArticle.HeaderImg}
                    alt="Placeholder image"
                    class="blog-img"
                  />
                </figure>
              </div>
              <div class="card-content">
                <div class="content">
                  <p class="subtitle is-6 has-text-grey-light">6 min read</p>
                  <p class="title is-3">{MBBSvsBDSArticle.Title}</p>
                  <p class="has-text-grey is-5">{MBBSvsBDSArticle.Desc}</p>
                </div>
                <div class="content">
                  <a
                    class="icon-text has-text-weight-bold"
                    onClick={() => {
                      history.push(`/blog/3`);
                    }}
                  >
                    <span class="pm-span-underline">Read More</span>
                    <span class="icon">
                      <i class="fas fa-arrow-right"></i>
                    </span>
                  </a>
                </div>
              </div>
            </div>
          </div>

          {/*   <div class="column is-one-third">
            <div
              class="card"
              onClick={() => {
                history.push(`/blog/2`);
              }}
            >
              <div class="card-image">
                <figure class="image is-4by3">
                
                  <img
                    src={AcingAkuTestArticle.HeaderImg}
                    alt="Placeholder image"
                    class="blog-img"
                  />
                </figure>
              </div>
              <div class="card-content">
                <div class="content">
                  <p class="subtitle is-6 has-text-grey-light">10 min read</p>
                  <p class="title is-3">{AcingAkuTestArticle.Title}</p>
                  <p class="has-text-grey is-5">{AcingAkuTestArticle.Desc}</p>
                </div>
                <div class="content">
                  <a
                    class="icon-text has-text-weight-bold"
                    onClick={() => {
                      history.push(`/blog/2`);
                    }}
                  >
                    <span class="pm-span-underline">Read More</span>
                    <span class="icon">
                      <i class="fas fa-arrow-right"></i>
                    </span>
                  </a>
                </div>
              </div>
            </div>
          </div> */}
        </div>
        <br />
        <div class="columns is-multiline blog-cards block is-centered">
          <div class="column is-one-third">
            <div
              class="card"
              onClick={() => {
                history.push(`/blog/4`);
              }}
            >
              <div class="card-image">
                <figure class="image is-4by3">
                  {/*    <!-- <img src="https://via.placeholder.com/1280x960" alt="Placeholder image" /> --> */}
                  <img
                    src={DomicileArticle.HeaderImg}
                    alt="Placeholder image"
                    class="blog-img"
                  />
                </figure>
              </div>
              <div class="card-content">
                <div class="content">
                  <p class="subtitle is-6 has-text-grey-light">10 min read</p>
                  <p class="title is-3">{DomicileArticle.Title}</p>
                  <p class="has-text-grey is-5">{DomicileArticle.Desc}</p>
                </div>
                <div class="content">
                  <a
                    class="icon-text has-text-weight-bold"
                    onClick={() => {
                      history.push(`/blog/4`);
                    }}
                  >
                    <span class="pm-span-underline">Read More</span>
                    <span class="icon">
                      <i class="fas fa-arrow-right"></i>
                    </span>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div class="column is-one-third">
            <div
              class="card"
              onClick={() => {
                history.push(`/blog/5`);
              }}
            >
              <div class="card-image">
                <figure class="image is-4by3">
                  {/*    <!-- <img src="https://via.placeholder.com/1280x960" alt="Placeholder image" /> --> */}
                  <img
                    src={AKUTestArticle.HeaderImg}
                    alt="Placeholder image"
                    class="blog-img"
                  />
                </figure>
              </div>
              <div class="card-content">
                <div class="content">
                  <p class="subtitle is-6 has-text-grey-light">10 min read</p>
                  <p class="title is-3">{AKUTestArticle.Title}</p>
                  <p class="has-text-grey is-5">{AKUTestArticle.Desc}</p>
                </div>
                <div class="content">
                  <a
                    class="icon-text has-text-weight-bold"
                    onClick={() => {
                      history.push(`/blog/5`);
                    }}
                  >
                    <span class="pm-span-underline">Read More</span>
                    <span class="icon">
                      <i class="fas fa-arrow-right"></i>
                    </span>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div class="column is-one-third">
            <div
              class="card"
              onClick={() => {
                history.push(`/blog/6`);
              }}
            >
              <div class="card-image">
                <figure class="image is-4by3">
                  {/*    <!-- <img src="https://via.placeholder.com/1280x960" alt="Placeholder image" /> --> */}
                  <img
                    src={AKUInterviewArticle.HeaderImg}
                    alt="Placeholder image"
                    class="blog-img"
                  />
                </figure>
              </div>
              <div class="card-content">
                <div class="content">
                  <p class="subtitle is-6 has-text-grey-light">10 min read</p>
                  <p class="title is-3">{AKUInterviewArticle.Title}</p>
                  <p class="has-text-grey is-5">{AKUInterviewArticle.Desc}</p>
                </div>
                <div class="content">
                  <a
                    class="icon-text has-text-weight-bold"
                    onClick={() => {
                      history.push(`/blog/6`);
                    }}
                  >
                    <span class="pm-span-underline">Read More</span>
                    <span class="icon">
                      <i class="fas fa-arrow-right"></i>
                    </span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="columns is-multiline blog-cards block is-centered">
          <div class="column is-one-third">
            <div
              class="card"
              onClick={() => {
                history.push(`/blog/7`);
              }}
            >
              <div class="card-image">
                <figure class="image is-16by9">
                  {/*    <!-- <img src="https://via.placeholder.com/1280x960" alt="Placeholder image" /> --> */}
                  <img
                    src={InternationalStudentsArticle.HeaderImg}
                    alt="Placeholder image"
                    class="blog-img"
                  />
                </figure>
              </div>
              <div class="card-content">
                <div class="content">
                  <p class="subtitle is-6 has-text-grey-light">10 min read</p>
                  <p class="title is-3">{InternationalStudentsArticle.Title}</p>
                  <p class="has-text-grey is-5">
                    {InternationalStudentsArticle.Desc}
                  </p>
                </div>
                <div class="content">
                  <a
                    class="icon-text has-text-weight-bold"
                    onClick={() => {
                      history.push(`/blog/7`);
                    }}
                  >
                    <span class="pm-span-underline">Read More</span>
                    <span class="icon">
                      <i class="fas fa-arrow-right"></i>
                    </span>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div class="column is-one-third">
            <div
              class="card"
              onClick={() => {
                history.push(`/blog/8`);
              }}
            >
              <div class="card-image">
                <figure class="image is-16by9">
                  {/*    <!-- <img src="https://via.placeholder.com/1280x960" alt="Placeholder image" /> --> */}
                  <img
                    src={AKUTestVlogArticle.HeaderImg}
                    alt="Placeholder image"
                    class="blog-img"
                  />
                </figure>
              </div>
              <div class="card-content">
                <div class="content">
                  <p class="subtitle is-6 has-text-grey-light">10 min read</p>
                  <p class="title is-3">{AKUTestVlogArticle.Title}</p>
                  <p class="has-text-grey is-5">{AKUTestVlogArticle.Desc}</p>
                </div>
                <div class="content">
                  <a
                    class="icon-text has-text-weight-bold"
                    onClick={() => {
                      history.push(`/blog/7`);
                    }}
                  >
                    <span class="pm-span-underline">Read More</span>
                    <span class="icon">
                      <i class="fas fa-arrow-right"></i>
                    </span>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div class="column is-one-third" style={{ height: "100%" }}>
            <div
              class="card"
              onClick={() => {
                history.push(`/blog/9`);
              }}
            >
              <div class="card-image">
                <figure class="image is-16by9">
                  {/*    <!-- <img src="https://via.placeholder.com/1280x960" alt="Placeholder image" /> --> */}
                  <img
                    src={ReapplyingtoAKUArticle.HeaderImg}
                    alt="Placeholder image"
                    class="blog-img"
                  />
                </figure>
              </div>
              <div class="card-content">
                <div class="content">
                  <p class="subtitle is-6 has-text-grey-light">10 min read</p>
                  <p class="title is-3">{ReapplyingtoAKUArticle.Title}</p>
                  <p class="has-text-grey is-5">
                    {ReapplyingtoAKUArticle.Desc}
                  </p>
                </div>
                <div class="content">
                  <a
                    class="icon-text has-text-weight-bold"
                    onClick={() => {
                      history.push(`/blog/9`);
                    }}
                  >
                    <span class="pm-span-underline">Read More</span>
                    <span class="icon">
                      <i class="fas fa-arrow-right"></i>
                    </span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*   <div class="block">
          <nav class="pagination is-centered" role="navigation">
            <ul class="pagination-list">
              <li>
                <a class="pagination-link is-current">1</a>
              </li>
              <li>
                <a class="pagination-link">2</a>
              </li>
              <li>
                <a class="pagination-link">3</a>
              </li>
              <li>
                <a class="pagination-link">4</a>
              </li>
              <li>
                <span class="pagination-ellipsis">&hellip;</span>
              </li>
              <li>
                <a class="pagination-link">10</a>
              </li>
            </ul>
          </nav>
        </div> */}
      </section>
    </div>
  );
}
