import React, { useEffect, useState, useContext } from "react";
import styles from "./QuestionComponent.module.css";
import {
  Card,
  CardHeader,
  CardTitle,
  CardImg,
  CardBody,
  CardFooter,
  Button,
  Fade,
} from "shards-react";

import { CircularProgress } from "@material-ui/core";

import QuestionText from "./QuestionText";
import QuizOptionsContainer from "./QuizOptionsContainer";
import QuestionCardHeader from "./QuestionCardHeader";
import BookMark from "./BookMark";
import TagList from "./TagList";
import NavButton from "./NavButtons";
import ReportQuestion from "./ReportQuestionComponent";
import SkipQuestion from "./SkipQuestionComponent";
//import TestSkipQuestion from "./TestSkipQuestionComponent";
import ExplanationFooter from "./ExplanationFooter";

import ProgressBar from "./ProgressBar";

import DifficultyRating from "./DifficultyRating/DifficultyRating";
import FrontEndEditComponent from "./FrontEndEditComponent";
import { UserContext } from "../../userContext";

import { GetQuestionRealDeck } from "../../APIManager";

//noti stackl for snack bar popup mamnahemnt
const Tags = ["PMC Sample 1", "Biology"];

const PracticeQuestionComponent = ({
  deckName,
  questionNo,
  NextQuestion,
  PreviousQuestion,
  currentQuestion,
  nextEnabled,
  backEnabled,
  isExplanationPurchased,
  loadingQuestion,
  SkipToQuestion,
  NoOfQuestions,
  OnAttempt,
  deckType,
  isBlurred,
  RefreshQuestion,
}) => {
  const [reportModalVisible, SetReportModalVisible] = useState(false);
  const [skipModalVisible, SetSkipModalVisible] = useState(false);
  const [options, SetOptions] = useState([]);
  const [showExplanation, SetShowExplanation] = useState(false);
  const [eliminationToolEnabled, SetEliminationToolEnabled] = useState(false);

  const [userContext, SetUserContext] = useContext(UserContext);

  const showQuestionID =
    userContext?.admin?.otherpermissions?.includes("Frontend Edit Page");

  const [editModalOpen, SetEditModalOpen] = useState(false);

  console.log(userContext);

  document.onkeydown = checkKey;

  useEffect(() => {
    var newOptions = currentQuestion?.Options?.map((option) => {
      return {
        color: "OptionColor",
        isSelected: false,
        optionLetter: option.OptionLetter,
        optionText: option.OptionText,
        isCorrect: option.IsCorrect,
      };
    });
    SetShowExplanation(false);
    SetOptions(newOptions);
  }, [currentQuestion]);

  if (!currentQuestion.QuestionText) {
    return null;
  }

  function OnOptionPressed(optionLetter) {
    var newOptions = [];
    var isCorrect = false;
    if (options.filter((option) => option.isSelected === true).length > 0) {
      return;
    } else {
      options.forEach((option) => {
        if (option.optionLetter === optionLetter) {
          isCorrect = option.isCorrect;
          newOptions.push({
            ...option,
            isSelected: true,
            color: option.isCorrect
              ? "CorrectOptionColor"
              : "InCorrectOptionColor",
          });
        } else {
          newOptions.push({
            ...option,
            isSelected: option.isCorrect,
            color: option.isCorrect ? "CorrectOptionColor" : "OptionColor",
          });
        }
      });
      SetOptions(newOptions);
      SetShowExplanation(true);
      ScrollToAnswer();
      OnAttempt(optionLetter, isCorrect);
    }
  }

  function ScrollToAnswer() {
    var element = document.getElementById("answerFooter");
    const yOffset = -100;
    setTimeout(function () {
      // window.scrollTo({top: y , behavior: "smooth"})
      document.getElementById("answerFooter")?.scrollIntoView({
        block: "end",
        behavior: "smooth",
      });
    }, 500);
  }

  return (
    <div
      className={`${styles.MainQuestionCard} ${isBlurred ? styles.Blur : null}`}
    >
      <div style={{ fontSize: "1.3em" }} hidden={!showQuestionID}>
        QuestionID: {currentQuestion?._id}{" "}
        <a
          target="_blank"
          // href={`https://admin.premed.pk/eq/${deckName}/${currentQuestion?._id}`}
          onClick={() => {
            GetQuestionRealDeck(currentQuestion._id).then((res) => {
              console.log(res.data);
              window.open(
                `https://admin.premed.pk/eq/${res.data.Name}/${currentQuestion?._id}`
              );
            });
          }}
        >
          Open in Admin
        </a>
        <Button
          size="sm"
          style={{ marginLeft: "1em" }}
          onClick={() => {
            SetEditModalOpen(true);
          }}
        >
          Edit Question
        </Button>
      </div>
      <Card>
        <FrontEndEditComponent
          OnCloseCB={() => {
            SetEditModalOpen(false);
          }}
          OnSubmitCB={() => {
            SetEditModalOpen(false);
            RefreshQuestion();
          }}
          isOpen={editModalOpen}
          deckName={deckName}
          questionID={currentQuestion._id}
          question={currentQuestion}
          key={currentQuestion._id}
        />
        <ReportQuestion
          OnCloseCB={() => {
            SetReportModalVisible(false);
          }}
          OnSubmitCB={() => {
            SetReportModalVisible(false);
          }}
          deckName={deckName}
          questionID={currentQuestion._id}
          isOpen={reportModalVisible}
        />

        {skipModalVisible ? (
          <SkipQuestion
            OnCloseCB={() => {
              SetSkipModalVisible(false);
            }}
            isOpen={skipModalVisible}
            OnSkipCB={(no) => {
              SkipToQuestion(no);
              SetSkipModalVisible(false);
            }}
            numberOfQuestions={NoOfQuestions}
          />
        ) : null}

        <QuestionCardHeader
          reportOnClick={() => {
            SetReportModalVisible(true);
          }}
          skipOnClick={() => {
            SetSkipModalVisible(true);
          }}
        />

        <CardBody className={styles.QuestionBody}>
          {/* {RenderLoadingSpinner(classes.spinner, QuestionContent)} */}
          <div className={styles.QuestionMainBody}>
            {!loadingQuestion ? (
              <>
                <BookMark
                  questionID={currentQuestion._id}
                  deckName={deckName}
                  deckType={deckType}
                />

                <QuestionText questionText={currentQuestion.QuestionText} />
                {RenderQuestionImage(currentQuestion.QuestionImage)}
                <TagList tags={currentQuestion?.Tags.map((tag) => tag.name)} />
                <QuizOptionsContainer
                  showEliminate
                  options={options}
                  onClick={OnOptionPressed}
                  eliminationToolEnabled={eliminationToolEnabled}
                  toggleEliminationTool={(e) => {
                    SetEliminationToolEnabled(!eliminationToolEnabled);
                  }}
                  key={currentQuestion._id}
                />
              </>
            ) : (
              <div className={styles.QuestionLoading}>
                <CircularProgress size={70} />
              </div>
            )}
          </div>
          <NavButton
            questionNo={questionNo}
            isBackEnabled={backEnabled}
            isNextEnabled={nextEnabled}
            onBackClick={() => {
              PreviousQuestion();
            }}
            onNextClick={() => {
              NextQuestion();
            }}
          />
          <ProgressBar questionNo={questionNo} NoOfQuestions={NoOfQuestions} />
        </CardBody>
        {!loadingQuestion && showExplanation && (
          <DifficultyRating questionID={currentQuestion._id} />
        )}
        {!loadingQuestion && (
          <ExplanationFooter
            QuestionID={currentQuestion._id}
            showAnswer={showExplanation}
            ExplanationContent={currentQuestion.ExplanationText}
            ExplanationImage={currentQuestion.ExplanationImage}
            isBlur={!isExplanationPurchased}
          />
        )}
      </Card>
    </div>
  );

  function checkKey(e) {
    e = e || window.event;

    if (e.keyCode == "38") {
      // up arrow
    } else if (e.keyCode == "40") {
    } else if (e.keyCode == "37") {
      try {
        if (
          backEnabled &&
          !loadingQuestion &&
          !skipModalVisible &&
          !reportModalVisible
        ) {
          PreviousQuestion();
          /*  ReactGA.event({
            category: "Question",
            action: "ArrowKeyNext/Back",
            label: "Yearly/Topical",
          }); */
        }
      } catch {}
    } else if (e.keyCode == "39") {
      if (
        nextEnabled &&
        !loadingQuestion &&
        !skipModalVisible &&
        !reportModalVisible
      ) {
        NextQuestion();
        /*  ReactGA.event({
          category: "Question",
          action: "ArrowKeyNext/Back",
          label: "Yearly/Topical",
        }); */
      }
    }
  }
};

function RenderQuestionImage(image) {
  if (image) {
    return (
      <div>
        <img
          src={image}
          className={styles.QuestionImage}
          style={{
            marginRight: "1rem",
            marginTop: "1rem",
            marginBottom: "1rem",
          }}
        />
      </div>
    );
  } else {
    return <div />;
  }
}

export default PracticeQuestionComponent;
