import React, { useState, useContext, useEffect } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Route, Switch, Link } from "react-router-dom";
import axios from "axios";
import { useLocation, useHistory } from "react-router-dom";

import { UserContext } from "../../../../userContext";

import {
  Card,
  CardHeader,
  CardTitle,
  CardImg,
  CardBody,
  CardFooter,
  Button,
  Fade,
  Badge,
  Modal,
  ModalBody,
  ModalHeader,
} from "shards-react";
import { Progress } from "shards-react";
import parse from "html-react-parser";
import CircularProgress from "@material-ui/core/CircularProgress";
import Chip from "@material-ui/core/Chip";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import GradientBtn from "../../../../Components/GradientBtn/GradientBtn";

import styles from "./MyDashBoard.module.css";
import CountUp from "react-countup";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import ReplayIcon from "@material-ui/icons/Replay";
import { GetRecentActivities } from "../../../../APIManager";
import {
  GetSavedQuestions,
  GetQuestions,
  GetTopicalQuestions,
  GetTotalTimePracticing,
  GetTotalQuestionsAttempted,
  GetQuestionOfTheDay,
  GetTopicalPastPaperQuestions,
} from "../../../../APIManager";

import { hiddenTags } from "../../../../Components/TagList/TagList";
import { escapeRegExp } from "lodash";

import Footer from "../../../../Components/Footer/Footer";

const MyDashboard = () => {
  const windowUrl = window.location.search;
  const params = new URLSearchParams(windowUrl);
  const isNewUser = params.get("newuser") ? true : false;
  let history = useHistory();
  const [activities, SetActivities] = useState([]);
  const [savedQuestions, SetSavedQuestions] = useState([]);
  const [totalTimePracticing, SetTotalTimePracticing] = useState("00:00:00");

  const [qotd, SetQotd] = useState({});

  const [totalQuestionsAttempted, SetTotalQuestionsAttempted] = useState(0);

  const [fetchingSavedQuestions, SetFetchingSavedQuestions] = useState(true);
  const [fetchingRecentActivity, SetFetchingRecentActivity] = useState(true);

  const [showNewUserModal, SetShowNewUserModal] = useState(isNewUser);

  const [user, setUser] = useContext(UserContext);
  const isPremium = user.addonspurchased.includes("Premium");

  useEffect(() => {
    GetTotalQuestionsAttempted().then((res) => {
      SetTotalQuestionsAttempted(res.data.totalattempts);
    });

    GetTotalTimePracticing().then((res) => {
      var formattedTotalTime = new Date(res.data).toISOString().substr(11, 8);
      SetTotalTimePracticing(formattedTotalTime);
    });

    GetRecentActivities().then((res) => {
      SetActivities(res.data);
      SetFetchingRecentActivity(false);
    });
    GetSavedQuestions().then((res) => {
      SetFetchingSavedQuestions(false);
      var a = res.data;
      a.sort(function (a, b) {
        return a.createdAt > b.createdAt
          ? -1
          : a.createdAt > b.createdAt
          ? 1
          : 0;
      });
      SetSavedQuestions(a);
    });

    GetQuestionOfTheDay().then((res) => {
      SetQotd(res.data);
    });
  }, []);

  return (
    <div className={styles.MainBox}>
      <WelcomeModal
        open={showNewUserModal}
        onClose={() => {
          SetShowNewUserModal(false);
        }}
      />
      <div className={styles.Dashboard_Main_Grid}>
        <div
          className={`${styles.Dashboard_Panel} ${styles.Dashboard_Panel_3c} ${styles.TitlePanel}`}
        >
          <div>
            <h1 className={styles.PageTitle}>My Dashboard</h1>
          </div>
        </div>
        <div className={`${styles.Dashboard_Panel} ${styles.ExploreYearly}`}>
          <Link style={{ color: "white" }} to={`/yearly`}>
            {" "}
            Solve Yearly MCQs
          </Link>
        </div>
        <div className={`${styles.Dashboard_Panel} ${styles.ExploreTopical}`}>
          <Link style={{ color: "white" }} to={`/topicals`}>
            {" "}
            Solve Topical MCQs
          </Link>
        </div>
        <div className={`${styles.Dashboard_Panel} ${styles.ExploreTest}`}>
          <Link style={{ color: "white" }} to={`/mocks`}>
            {" "}
            Attempt a Mock
          </Link>
        </div>
        <div
          className={`${styles.Dashboard_Panel} ${styles.Dashboard_Panel_2r} ${styles.Dashboard_Panel_3c} ${styles.ActivityStatsTile}`}
        >
          <div className={`${styles.ActivitySubTile}`}>
            <h2>Recent Activity</h2>
            <div className={`${styles.RecentActivity_MainFlex}`}>
              <div
                className={styles.PurchaseFeatureContainer}
                hidden={
                  activities.length < 1 || fetchingRecentActivity || isPremium
                }
              >
                <div className={styles.PurchaseFeatureText}>
                  Hey{" "}
                  <span style={{ color: "var(--QuestionBlue)" }}>
                    {user.fullname}
                  </span>
                  , view your{" "}
                  <span style={{ color: "#ee6c7c" }}>
                    recently attempted questions
                  </span>{" "}
                  by switching to our Premium Plan today!
                  <br />
                  <GradientBtn
                    style={{ fontSize: "0.5em", borderRadius: "30px" }}
                    gradient={"#D31027 0%, #EA384D  51%, #D31027  100%"}
                    onClick={() => {
                      history.push("/plans");
                    }}
                  >
                    Purchase
                  </GradientBtn>
                </div>
              </div>
              {activities.length < 1 && !fetchingRecentActivity ? (
                <div className={styles.NoData}>
                  You have no Recent Activity
                  <br />
                  Get started with our <Link to={`/yearly`}>
                    Yearly
                  </Link> or <Link to={`/topical`}>Topical</Link> Decks
                </div>
              ) : null}
              <div
                style={fetchingRecentActivity ? {} : { display: "none" }}
                className={`${styles.LoadingCircleContainer}`}
              >
                <div>
                  <CircularProgress size="70px" />
                </div>
              </div>
              <div className={isPremium ? null : styles.BlurredBox}>
                {RenderActivities(activities)}
              </div>
            </div>
            <Link
              style={{
                fontSize: "1.3em",
                display:
                  !fetchingRecentActivity && activities.length > 0
                    ? "block"
                    : "none",
              }}
              to={`/recent-activity`}
            >
              More Activity...
            </Link>
          </div>
          <div className={`${styles.SavedSubTile}`}>
            <h2>Saved Questions</h2>
            <div className={styles.SavedQuestions_MainFlex}>
              <div
                className={styles.PurchaseFeatureContainer}
                hidden={
                  savedQuestions.length < 1 ||
                  fetchingSavedQuestions ||
                  isPremium
                }
              >
                <div className={styles.PurchaseFeatureText}>
                  Hey{" "}
                  <span style={{ color: "var(--QuestionBlue)" }}>
                    {user.fullname}
                  </span>
                  , view your{" "}
                  <span style={{ color: "#ee6c7c" }}>saved questions</span> by
                  switching to our Premium Plan today!
                  <br />
                  <GradientBtn
                    style={{ fontSize: "0.5em", borderRadius: "30px" }}
                    gradient={"#D31027 0%, #EA384D  51%, #D31027  100%"}
                    onClick={() => {
                      history.push("/plans");
                    }}
                  >
                    Purchase
                  </GradientBtn>
                </div>
              </div>
              {savedQuestions.length < 1 && !fetchingSavedQuestions ? (
                <div className={styles.NoData}>You have no Saved Questions</div>
              ) : null}
              <div
                style={fetchingSavedQuestions ? {} : { display: "none" }}
                className={styles.LoadingCircleContainer}
              >
                <div>
                  <CircularProgress size="70px" />
                </div>
              </div>
              <div className={isPremium ? null : styles.BlurredBox}>
                {" "}
                <RenderSavedQuestions
                  key={savedQuestions.length + savedQuestions.length}
                  rawData={savedQuestions}
                />
              </div>
            </div>
            <Link
              style={{
                fontSize: "1.3em",
                display:
                  !fetchingSavedQuestions && savedQuestions.length > 0
                    ? "block"
                    : "none",
              }}
              to={`/saved-questions`}
            >
              More Saved Questions...
            </Link>
          </div>
        </div>
        <div
          className={`${styles.Dashboard_Panel} ${styles.StatsTile}  ${styles.TotalTimeTile}`}
        >
          <div style={{ color: "#16a61d" }} className={styles.Stats_Number}>
            {totalTimePracticing}
          </div>
          <div className={`${styles.Stats_Title}`}>Total time spent</div>
          <Link style={{ fontSize: "1.3em" }} to={`/stats`}>
            More Stats...
          </Link>
        </div>
        <div
          className={`${styles.Dashboard_Panel} ${styles.Dashboard_Panel_2r} ${styles.QOTDTile}`}
        >
          <h3>Question of the day</h3>
          <div className={styles.QOTDText}>
            {qotd.QuestionText
              ? parse(qotd?.QuestionText.replace("<p>", "").replace("</p>", ""))
              : ""}
          </div>
          <div
            className={styles.QOTDButton}
            onClick={() => {
              history.push(
                qotd.Type === "Topical"
                  ? `/deck/topical/attempt?deck=${qotd.DeckName}&question=${qotd.QuestionNumber}&qotd=1`
                  : `/deck/attempt?deck=${qotd.DeckName}&question=${qotd.QuestionNumber}&qotd=1`
              );
            }}
          >
            <Button theme="light">Attempt Now</Button>
          </div>
        </div>
        <div
          className={`${styles.Dashboard_Panel} ${styles.Dashboard_Panel_2r} ${styles.NewsTile}`}
        >
          <h3>What's New!</h3>
          <div className={styles.NewsHeading}>Launch Day</div>
          <div className={styles.NewsContent}>
            <ul>
              <li>Latest papers available from NDMCAT and NUMS!</li>
              <li>
                Introduction of <strong>Timed</strong> Test Mode!
              </li>
              <li>Skip questions effortlessly!</li>
              <li>View your stats in real-time!</li>
              <li>Save questions to review later!</li>
            </ul>
          </div>
        </div>
        <div
          className={`${styles.Dashboard_Panel} ${styles.StatsTile} ${styles.TotalAttemptsTile}`}
        >
          <div style={{ color: "#16a61d" }} className={styles.Stats_Number}>
            {totalQuestionsAttempted}
          </div>
          <div className={styles.Stats_Title}>Total Attempts</div>
          <Link style={{ fontSize: "1.3em" }} to={`/stats`}>
            More Stats...
          </Link>
        </div>
      </div>
    </div>
  );
};

export default MyDashboard;

const RenderActivities = (props) => {
  let history = useHistory();
  const sm = useMediaQuery("(max-width:500px)");
  const [fontSize, SetFontSize] = useState("1.5em");
  useEffect(() => {
    if (sm) {
      SetFontSize("1.1em");
    } else {
      SetFontSize("1.5em");
    }
  }, [sm]);

  const rawData = props;

  rawData.sort(function (a, b) {
    return a.date > b.date ? -1 : a.date > b.date ? 1 : 0;
  });
  //console.log(rawData);
  var d = new Date("2021-02-16T21:35:38.236Z");
  return (
    <div>
      {rawData.slice(0, 2).map((a) => (
        <div className={styles.ActivityMainBox}>
          <div className={styles.ActivityHeading}>
            {a.DeckName} {GetBadge(a.type)}
          </div>
          <div>Date Last Attempted: {GetFormattedTime(a.date)}</div>
          <div className={styles.ActivityProgress}>
            <span style={{ width: "80%" }}>
              <Progress theme="primary" value={(a.number * 100) / a.total} />
            </span>
            <span>
              {a.number} / {a.total}
            </span>
          </div>
          <div>
            {a.number !== a.total ? (
              <span style={{ marginRight: ".5em", marginBottom: "0.5em" }}>
                {" "}
                <Button
                  size="sm"
                  outline
                  onClick={() => {
                    if (a.type === "Topical") {
                      if (a.TopicalType === "PracticeQuestion") {
                        history.push(
                          `/deck/topical/attempt?deck=${a.DeckName}&question=${a.number}`
                        );
                      } else {
                        history.push(
                          `/deck/topical/pastpaper/attempt?deck=${a.DeckName}&question=${a.number}`
                        );
                      }
                    } else {
                      history.push(
                        `/deck/attempt?deck=${a.DeckName}&question=${a.number}`
                      );
                    }
                  }}
                >
                  <PlayArrowIcon />
                  Resume from Question {a.number}
                </Button>
              </span>
            ) : (
              <></>
            )}
            <span style={{ marginRight: ".5em", marginBottom: "0.5em" }}>
              {" "}
              <Button
                size="sm"
                outline
                onClick={() => {
                  if (a.type === "Topical") {
                    history.push(`/deck/topical/attempt?deck=${a.DeckName}`);
                  } else {
                    history.push(`/deck/attempt?deck=${a.DeckName}`);
                  }
                }}
              >
                <ReplayIcon />
                Start Over
              </Button>
            </span>
          </div>
        </div>
      ))}
    </div>
  );
};

const RenderSavedQuestions = (props) => {
  let history = useHistory();

  const GetQuestionNumber = async (deckName, qid, type) => {
    var ids = [];
    if (type === "Topical") {
      let [promiseyearly, promisetopical] = await Promise.all([
        GetTopicalPastPaperQuestions(deckName),
        GetTopicalQuestions(deckName),
      ]);

      var topicalQuestionIDs = [];
      var yearlyQuestionIDs = [];
      const data = promiseyearly.data;
      console.log(data);
      data.forEach((element) => {
        yearlyQuestionIDs.push(element.Questions._id);
      });
      const data1 = promisetopical.data;
      data1.forEach((element) => {
        topicalQuestionIDs.push(element.Questions._id);
      });

      if (topicalQuestionIDs.indexOf(qid) !== -1) {
        return {
          type: "PracticeTopical",
          no: topicalQuestionIDs.indexOf(qid) + 1,
        };
      } else {
        return {
          type: "PastpaperTopical",
          no: yearlyQuestionIDs.indexOf(qid) + 1,
        };
      }
    } else {
      const promiseyearly = await GetQuestions(deckName);
      var questionsIDs = [];
      const data = promiseyearly.data;
      data.forEach((element) => {
        questionsIDs.push(element.Questions._id);
      });
      return { type: "Yearly", no: questionsIDs.indexOf(qid) + 1 };
    }
  };

  const { rawData, onDelete } = props;
  if (!rawData || rawData.length < 1) {
    return null;
  } else {
    return (
      <div>
        {rawData.slice(0, 2).map((a) => (
          <div className={styles.SavedQuestionMainBox}>
            <div className={styles.SavedQuestionHeading}>
              {a.DeckName} {GetBadge(a.Type)}
            </div>
            <div>Date Saved: {GetDate(a.createdAt)}</div>
            {/* <div className={styles.QuestionText}>
              {parse(
                a.QuestionText.replaceAll("<p>", "").replaceAll("</p>", "").substring(0,40)
              )}
            </div> */}
            <div className={styles.Tags}>
              {a.Tags.map((tag) => (
                <Chip
                  style={
                    hiddenTags.includes(tag)
                      ? { display: "none" }
                      : { marginTop: "1em" }
                  }
                  size="medium"
                  label={tag}
                  color="secondary"
                  variant="outlined"
                />
              ))}
            </div>
            <div className={styles.DeleteButton}>
              <Button
                size="sm"
                outline
                onClick={async () => {
                  let promise = await GetQuestionNumber(
                    a.DeckName,
                    a.QuestionID,
                    a.Type
                  );
                  const { no, type } = promise;
                  if (type === "Yearly") {
                    history.push(
                      `/deck/attempt?deck=${a.DeckName}&question=${no}`
                    );
                  } else if (type === "PracticeTopical") {
                    history.push(
                      `/deck/topical/attempt?deck=${a.DeckName}&question=${no}`
                    );
                  } else {
                    history.push(
                      `/deck/topical/pastpaper/attempt?deck=${a.DeckName}&question=${no}`
                    );
                  }
                }}
              >
                Attempt
              </Button>
              {"  "}
            </div>
          </div>
        ))}
      </div>
    );
  }
};

function GetDate(date) {
  var d = new Date(date);
  return d.toLocaleTimeString() + " " + d.toLocaleDateString();
}

function GetBadge(type) {
  if (type === "Topical") {
    return <Badge theme="info">Topical</Badge>;
  } else {
    return <Badge theme="success">Yearly</Badge>;
  }
}
function GetFormattedTime(date) {
  var d = new Date(date);
  var months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  var monthName = months[d.getMonth()];
  return `${monthName} ${date[8]}${date[9]} ${d.getFullYear()}`;
}

const WelcomeModal = (props) => {
  let history = useHistory();

  const { open, onClose } = props;
  // console.log(open);
  return (
    <Modal
      open={open}
      toggle={() => {
        onClose();
      }}
      centered
    >
      <ModalHeader>Congratulations!</ModalHeader>
      <ModalBody>
        <div style={{ fontSize: "1.2em" }}>
          {" "}
          <div
            style={{
              fontSize: "1.3em",
              width: "100%",
              textAlign: "center",
              marginBottom: "1em",
            }}
          >
            {" "}
            Thankyou for signing up for PreMed.PK 🎉
          </div>
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-evenly",
              flexWrap: "wrap",
            }}
          >
            <Button onClick={onClose} style={{ marginBottom: "1em" }}>
              Explore Dashboard
            </Button>
            <Button
              onClick={() => {
                history.push("/yearly");
              }}
              style={{ marginBottom: "1em" }}
            >
              Explore Yearly Papers
            </Button>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};
