import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import styles from "./MyNotifications.module.css";
import PaymentVerifiedIcon from "../../../../StaticImages/NotificationIcons/paymentverified.png";
import TitleIcon from "../../../../StaticImages/mynotificationsicon.svg";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";

import GradientBtn from "../../../../Components/GradientBtn/GradientBtn.jsx";

import { UserContext } from "../../../../userContext";
import { NotificationContext } from "../../../../notificationContext";

import {
  GetNotifications,
  MarkNotificationsAsRead,
} from "../../../../APIManager";
import { NoMeetingRoom } from "@material-ui/icons";

export default function () {
  const [rawnotifications, SetRawNotifications] =
    useContext(NotificationContext);
  const [userContext, SetUserContext] = useContext(UserContext);

  useEffect(() => {
    GetNotifications().then((res) => {
      SetRawNotifications(res.data);
    });
  }, []);

  var Notifications = [];
  rawnotifications.forEach((notification) => {
    notification.isread = userContext.notificationsread.includes(
      notification._id
    );
    console.log(notification.isread);
    Notifications.push(notification);
  });

  Notifications.sort(function compare(a, b) {
    var dateA = new Date(a.createdAt);
    var dateB = new Date(b.createdAt);
    return dateA - dateB;
  }).reverse();

  function MarkAllAsRead() {
    var readids = [];
    rawnotifications.forEach((n) => {
      readids.push(n._id);
    });
    var user = userContext;
    user.notificationsread = readids;
    MarkNotificationsAsRead(user.notificationsread).then(() => {
      SetUserContext({ ...user });
    });
  }

  return (
    <div className={styles.MainDiv}>
      <div className={styles.PageHeader}>
        <div>
          {" "}
          <img src={TitleIcon} />
        </div>

        <div>
          <h1>My Notifications</h1>
          <h2>Get all important updates in one place.</h2>
        </div>
      </div>
      <div>
        <GradientBtn
          style={{
            fontSize: "1em",
            borderRadius: "30px",
            margin: 0,
            padding: "0.4em 2em",
            marginRight: "0.5em",
          }}
          gradient={"#00D396 9.34%, #C8FFEF 91.82%"}
          onClick={() => {
            MarkAllAsRead();
          }}
        >
          <CheckCircleIcon style={{ marginRight: "7px" }} />
          Mark all as Read
        </GradientBtn>
      </div>
      <br />
      <div className={styles.NotificationsContainer}>
        {Notifications.map((n) => {
          return (
            <NotificationPill
              content={n.Content}
              date={n.createdAt}
              isread={n.isread}
              key={`${n._id}${n.isread}`}
            />
          );
        })}
      </div>
    </div>
  );
}

const NotificationPill = ({ content, date, isread }) => {
  const history = useHistory();
  const newdate = new Date(date);
  return (
    <div
      className={`${styles.NotificationPill} ${!isread ? styles.UnRead : null}`}
    >
      <div className={styles.NotificationIcon}>
        <img src={content.IconSrc} />
      </div>
      <div className={styles.NotificationContent}>
        {content.Text}
        <span className={styles.NotificationDate}>
          {timeSince(newdate)} ago
        </span>
        <div>
          <span style={{ marginRight: ".2em", marginBottom: "0.4em" }} hidden={!content.ActionButton1Text}>
            {" "}
            <GradientBtn
              style={{
                fontSize: "0.7em",
                borderRadius: "30px",
                margin: 0,
                padding: "0.4em 1.2em",
                marginRight: "0.5em",
              }}
              gradient={"#639AF6 9.34%, #98BDF9 91.82%"}
              onClick={() => {
                history.push(content.ActionButton1URL);
              }}
            >
              <PlayArrowIcon />
              {content.ActionButton1Text}
            </GradientBtn>
          </span>
          <span hidden={!content.ActionButton2Text}>
            {" "}
            <GradientBtn
              style={{
                fontSize: "0.7em",
                borderRadius: "30px",
                margin: 0,
                padding: "0.4em 1.2em",
                marginRight: "0.5em",
              }}
              gradient={"#EC5863 9.34%, #F39EA5 91.82%"}
              onClick={() => {
                history.push(content.ActionButton2URL);
              }}
            >
              <PlayArrowIcon />
              {content.ActionButton2Text}
            </GradientBtn>
          </span>
        </div>
      </div>
    </div>
  );
};

function timeSince(date) {
  var seconds = Math.floor((new Date() - date) / 1000);

  var interval = seconds / 31536000;

  if (interval > 1) {
    return Math.floor(interval) + " years";
  }
  interval = seconds / 2592000;
  if (interval > 1) {
    return Math.floor(interval) + " months";
  }
  interval = seconds / 86400;
  if (interval > 1) {
    return Math.floor(interval) + " days";
  }
  interval = seconds / 3600;
  if (interval > 1) {
    return Math.floor(interval) + " hours";
  }
  interval = seconds / 60;
  if (interval > 1) {
    return Math.floor(interval) + " minutes";
  }
  return Math.floor(seconds) + " seconds";
}
