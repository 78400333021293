import React, { useState, useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";

import { LogReport } from "../../APIManager";

import { validateYupSchema } from "formik";

import { FormCheckbox } from "shards-react";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ReportQuestion(props) {
  const OnCloseCB = props.onCloseCB;
  const OnSubmitCB = props.onSubmitCB;
  const [deckName, SetDeckName] = React.useState(props.deckName);
  const [questionID, SetQuestionID] = React.useState(props.questionID);

  const [open, setOpen] = React.useState(props.isOpen);

  const [error, SetError] = React.useState(false);
  const [complaint, SetComplaint] = React.useState("");

  const [wrongQuestion, SetWrongQuestion] = React.useState(false);
  const [wrongOptions, SetWrongOptions] = React.useState(false);
  const [wrongExplanation, SetWrongExplanation] = React.useState(false);
  const [wrongTags, SetWrongTags] = React.useState(false);
  const [duplicateQuestion, SetDuplicateQuestion] = React.useState(false);
  const [wrongAnswer, SetWrongAnswer] = React.useState(false)

  useEffect(() => {
    ValidateComplaintInput();
  }, [complaint]);

  function ValidateComplaintInput() {
    if (complaint.length < 2) {
      SetError(true);
    } else {
      SetError(false);
    }
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    OnCloseCB();
  };

  return (
    <div>
      <Dialog
        scroll="body"
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">
          {"Report Question"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Please fill out the following carefully and as detailed as possible,
            so your problem can be addressed effectively. Once the complaint is
            reviewed, you will be emailed the response
          </DialogContentText>
          <TextField
            variant="outlined"
            onChange={(e) => {
              SetComplaint(e.target.value);
            }}
            error={error}
            autoFocus
            multiline
            helperText={error ? "Complaint cant be left empty" : ""}
            margin="dense"
            id="Complaint"
            type="text"
            fullWidth
            label="What is wrong with this question"
            inputProps={{ maxLength: 799 }}
          />

          <DialogContentText id="alert-dialog-slide-description">
            <strong style={{ fontSize: "1.3em" }}>
              {" "}
              Select all that apply
            </strong>
          </DialogContentText>
          <FormCheckbox
            inline
            checked={wrongQuestion}
            onChange={(e) => SetWrongQuestion(!wrongQuestion)}
          >
            <strong>Question is Wrong</strong>
          </FormCheckbox>

          <FormCheckbox
            inline
            checked={wrongOptions}
            onChange={(e) => SetWrongOptions(!wrongOptions)}
          >
            <strong>Options are Wrong</strong>
          </FormCheckbox>
          <FormCheckbox
            inline
            checked={wrongAnswer}
            onChange={(e) => SetWrongAnswer(!wrongAnswer)}
          >
            <strong>Answer is wrong</strong>
          </FormCheckbox>
          <FormCheckbox
            inline
            checked={wrongExplanation}
            onChange={(e) => SetWrongExplanation(!wrongExplanation)}
          >
            <strong>Explanation is wrong</strong>
          </FormCheckbox>
          <FormCheckbox
            inline
            checked={duplicateQuestion}
            onChange={(e) => SetDuplicateQuestion(!duplicateQuestion)}
          >
            <strong>Duplicate Question</strong>
          </FormCheckbox>
          <FormCheckbox
            inline
            checked={wrongTags}
            onChange={(e) => SetWrongTags(!wrongTags)}
          >
            <strong>Wrong Tags</strong>
          </FormCheckbox>
        </DialogContent>
        <DialogActions>
          <Button
            disabled={error}
            style={{ margin: "1em" }}
            onClick={() => {
              OnSubmit();
            }}
            variant="contained"
            color="primary"
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );

  function OnSubmit() {
    if (!error) {
      LogReport({
        DeckName: deckName,
        QuestionID: questionID,
        Complaint: complaint,
        Tags: GetTags(),
      });
      handleClose();
      OnSubmitCB();
    }
  }

  function GetTags() {
    const tags = [];
    if (wrongQuestion) {
      tags.push("Wrong Question");
    }
    if (wrongOptions) {
      tags.push("Wrong Options");
    }
    if (wrongExplanation) {
      tags.push("Wrong Explanation");
    }
    if (duplicateQuestion) {
      tags.push("Duplicate Question");
    }
    if (wrongTags) {
      tags.push("Wrong Tags");
    }
    return tags;
  }
}
