import React from "react";
import { useHistory } from "react-router-dom";
import {
  Button,
  FormInput,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
} from "shards-react";
import GradientBtn from "../../Components/GradientBtn/GradientBtn.jsx";
import ClearOutlinedIcon from "@material-ui/icons/ClearOutlined";

export default function NotLoggedInToggle({ onClose, open }) {
  let history = useHistory();
  return (
    <Modal
      open={open}
      toggle={onClose}
    >
      <ModalHeader className="ShardsModalClosable">
        Not Logged In{" "}
        <div className={"ShardsModalCloseBtn"}>
          <ClearOutlinedIcon onClick={onClose} />
        </div>
      </ModalHeader>
      <ModalBody>
        <div
          style={{
            fontSize: "1.2em",
            textAlign: "center",
            fontFamily: "Rubik",
          }}
        >
          Unlock this feature and many more by creating a{" "}
          <span style={{ color: "var(--QuestionBlue)" }}>Free Account</span>{" "}
          <br />
          <br />
          <GradientBtn
            style={{
              fontSize: "0.7em",
              borderRadius: "30px",
              margin: 0,
            }}
            gradient={"#D31027 0%, #EA384D  51%, #D31027  100%"}
            onClick={() => {
              history.push({
                pathname: "/signup",
              });
            }}
          >
            Create a free account
          </GradientBtn>
          <div
            style={{
              fontSize: "0.9em",
              textAlign: "center",
              fontFamily: "Rubik",
              marginTop: "1em",
            }}
          >
            Already have an account?
            <br /> <a href="/login">Login Here</a>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
}
