import React, { useState, useContext, useEffect } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { useLocation, withRouter } from "react-router";
//import { useHistory, useParams, withRouter } from "react-router";

import styles from "./App.module.css";

import Page404 from "./Pages/Page404/404";
import MockModeQuizDeck from "./Pages/TestMode/MockModeQuizDeck";
import NewSignUpForm from "./Pages/SignUpPage/SignUpPage";
import Dashboard from "./Pages/Dashboard/dashboard";
import RedeemCode from "./Pages/RedeemCode/RedeemCode";
import TestPage from "./Pages/test";
import Blog from "./Pages/Blog/Blog";
import BlogArticle from "./Pages/Blog/BlogArticle";

import Reviews from "./Pages/Reviews/Reviews";

import FBIcon from "./StaticImages/SocialIcons/icons8-facebook-144.png";
import InstaIcon from "./StaticImages/SocialIcons/icons8-instagram-144.png";
import TwitterIcon from "./StaticImages/SocialIcons/icons8-twitter-144.png";

import HomePage from "./Pages/HomePage/HomePage";
import ContactUs from "./Pages/ContactUs/ContactUs";
import OurTeam from "./Pages/OurTeam/OurTeam";
import Ambassadors from "./Pages/Ambassadors/Ambassadors.jsx";

import LoginPage from "./Pages/LoginPage/LoginPage.jsx";
import LoginPageNew from "./Pages/LoginPage/LoginPageNew.jsx";
import SignupPage from "./Pages/RegisterPage/SignupPage.jsx";
import ForgotPassword from "./Pages/ForgotPassword/ForgotPassword.jsx";
import ResetPassword from "./Pages/ResetPassword/ResetPassword.jsx";
import YearlyPage from "./Pages/YearlyPage/YearlyPage";
import TestsPage from "./Pages/TestsPage/TestsPage";
import TopicalPage from "./Pages/TopicalPage/TopicalPage";
import MocksPage from "./Pages/MocksPage/MocksPage";

import CheckoutPage from "./Pages/PaymentPages/CheckoutPage";
import PlansPage from "./Pages/PlansPage/PlansPage";

import MyNotifications from "./Pages/MyNotifications/MyNotifications";

import YearlyPracticeComponent from "./Components/QuestionComponents/YearlyPracticeComponent";
import TopicalPracticeComponent from "./Components/QuestionComponents/TopicalPracticeComponent";
import TestModeComponent from "./Components/QuestionComponents/TestModeComponent";
import MockModeComponent from "./Components/QuestionComponents/MockModeComponent";

import BookmarksModeComponent from "./Components/QuestionComponents/BookmarksModeComponent";

import RequestPaymentPage from "./Pages/Dashboard/Pages/RequestPaymentPage/RequestPaymentPage";

import PMCPage from "./Pages/PMCPage/PMCPage";

import NewFooter from "./Components/NewFooter/Footer";

import { UserContext } from "./userContext";
import { RefreshUserContext } from "./UtilityFunctions";
import ScrollToTop from "./ScrollToTop";
import PageTracker from "./PageTracker";
import { ProtectedRoute } from "./ProtectedRoute";
import { PremiumRoute } from "./PremiumRoute";
import { getLoggedInUser } from "./APIManager";
import Navbar from "./Navbar/Navbar";
import { useHistory } from "react-router-dom";
import createHistory from "history/createBrowserHistory";

import { Button, Modal, ModalBody, ModalHeader } from "shards-react";

import ReactGA from "react-ga";

import { GetOfferTime, FormatOfferDate } from "./Pages/HomePage/HomePage";

const history = createHistory();

function removeElementsByClass(className) {
  const elements = document.getElementsByClassName(className);
  while (elements.length > 0) {
    elements[0].parentNode.removeChild(elements[0]);
  }
}

const App = () => {
  //let search = window.location.search;
  //let params = new URLSearchParams(search);

  const DEPLOYMENT = 0; //0 == TESTING 1 == PRODUCTION

  const [user, setUser] = useContext(UserContext);
  const [isResolved, setResolved] = useState(false);
  const [currentPath, setCurrentPath] = useState("");

  const [showMockModal, SetShowMockModal] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      SetShowMockModal(true);
    }, 5000);
  }, []);

  var path = "";
  let location = useLocation();
  useEffect(() => {
    ReactGA.initialize("UA-198553218-3");
    ReactGA.pageview(location.pathname);
    // console.log(ReactGA)
  }, []);

  useEffect(() => {
    //  console.log("hooray");
    if (isResolved !== true) {
      RefreshUserContext(setUser, user).then((res) => {
        setCurrentPath(window.location.href);
        setResolved(true);
        removeElementsByClass("ReactPreLoaderAnimation");
      });
    } else {
      if (currentPath !== window.location.href) {
        RefreshUserContext(setUser, user).then((res) => {
          console.log(res);
          setResolved(true);
          removeElementsByClass("ReactPreLoaderAnimation");
          setCurrentPath(window.location.href);
        });
      }
    }
  });

  if (isResolved) {
    // console.log("Router ran rn");
    return (
      <Router>
        <Navbar />
        <ScrollToTop />
        <PageTracker ReactGA={ReactGA} />
        <MocksModal
          open={showMockModal && !user.addonspurchased.includes("Premium")}
          onClose={() => SetShowMockModal(false)}
        />

        <Switch
          onChange={() => {
            //    console.log("Route Changed");
          }}
        >
          {DevleopmentOnlyRoutes()}
          <Route exact path="/">
            <HomePage />
            <NewFooter />
          </Route>
          <Route exact path="/contact-us">
            <ContactUs />
            <NewFooter type="normal" />
          </Route>
          <Route exact path="/Our-Team">
            <OurTeam />
            <NewFooter type="normal" />
          </Route>
          <Route exact path="/Ambassadors">
            <Ambassadors />
            <NewFooter type="normal" />
          </Route>
          <ProtectedRoute
            exact
            path="/dashboard"
            Component={Dashboard}
          ></ProtectedRoute>
          <PremiumRoute
            exact
            path="/recent-activity"
            Component={Dashboard}
          ></PremiumRoute>
          <PremiumRoute
            exact
            path="/saved-questions"
            Component={Dashboard}
          ></PremiumRoute>
          {/*  <ProtectedRoute
            exact
            path="/incorrect-questions"
            Component={Dashboard}
          ></ProtectedRoute> */}
          <ProtectedRoute
            exact
            path="/stats"
            Component={Dashboard}
          ></ProtectedRoute>
          <ProtectedRoute
            exact
            path="/my-account"
            Component={Dashboard}
          ></ProtectedRoute>
          <Route path="/Login">
            <LoginPageNew />
            <NewFooter type="normal" />
          </Route>
          <Route path="/signup">
            <NewSignUpForm />
            <NewFooter type="normal" />
          </Route>
          <Route path="/forgot-password">
            <ForgotPassword />
            <NewFooter type="normal" />
          </Route>
          {/*  <Route path="/test">
            <TestPage />
            <NewFooter type="normal" />
          </Route> */}
          <Route path="/reset-password/:userid/:token">
            <ResetPassword />
            <NewFooter type="normal" />
          </Route>
          {/*  <ProtectedRoute
            Component={RedeemCode}
            path="/RedeemCode"
          ></ProtectedRoute> */}
          <ProtectedRoute
            exact
            path="/deck/attempt"
            Component={YearlyPracticeComponent}
          ></ProtectedRoute>
          <ProtectedRoute
            exact
            path="/deck/topical/attempt"
            Component={TopicalPracticeComponent}
          ></ProtectedRoute>
          <ProtectedRoute
            exact
            path="/deck/topical/pastpaper/attempt"
            Component={TopicalPracticeComponent}
          ></ProtectedRoute>
          <ProtectedRoute
            exact
            path="/deck/testmode"
            Component={TestModeComponent}
          ></ProtectedRoute>
       <ProtectedRoute
            exact
            path="/mock/attempt"
            Component={MockModeComponent}
          ></ProtectedRoute> 
          <ProtectedRoute
            exact
            path="/deck/saved-questions"
            Component={BookmarksModeComponent}
          ></ProtectedRoute>
          <ProtectedRoute
            exact
            path="/my-notifications"
            Component={Dashboard}
          ></ProtectedRoute>
       {/*     <ProtectedRoute
            exact
            path="/deck/mockmode"
            Component={MockModeQuizDeck}
          ></ProtectedRoute> */}
          <Route path="/yearly">
            <YearlyPage />
            <NewFooter type="normal" />
          </Route>
          <Route path="/topicals">
            <TopicalPage />
            <NewFooter type="normal" />
          </Route>
          <Route path="/mocks">
            <MocksPage />
            <NewFooter type="normal" />
          </Route>
          <Route path="/pmc">
            <PMCPage />
            <NewFooter type="normal" />
          </Route>
          <Route path="/checkout">
            <CheckoutPage />
            <NewFooter type="normal" />
          </Route>
          <Route path="/Plans">
            <PlansPage />
            <NewFooter type="normal" />
          </Route>
          <Route path="/pricing">
            <PlansPage />
            <NewFooter type="normal" />
          </Route>
          <Route exact path="/Blog">
            <Blog />
            <NewFooter type="normal" />
          </Route>
          <Route exact path="/Blog/:blogID">
            <BlogArticle />
            <NewFooter type="normal" />
          </Route>
          <Route exact path="/Reviews">
            <Reviews />
            <NewFooter type="normal" />
          </Route>
          <Route exact path="/attempt">
            <YearlyPracticeComponent />
          </Route>
          <Route exact path="/RequestPayment/:couponCode/:couponID">
            <RequestPaymentPage />
            <NewFooter type="normal" />
          </Route>
          <Route path="*">
            <Page404 />
            <NewFooter type="normal" />
          </Route>
          <Route path="404">
            <Page404 />
            <NewFooter type="normal" />
          </Route>
        </Switch>
      </Router>
    );
  } else {
    return <div></div>;
  }
  function DevleopmentOnlyRoutes() {
    if (DEPLOYMENT === 0) {
      return;
    }
  }
};

export default withRouter(App);

/* const WelcomeModal = (props) => {
  let history = useHistory();

  const { open, onClose } = props;
  // console.log(open);
  return (
    <Modal
      open={open}
      toggle={() => {
        onClose();
      }}
      centered
    >
      <ModalHeader>Announcement 📢</ModalHeader>
      <ModalBody>
        <div style={{ fontSize: "1.2em" }}>
          {" "}
          <div
            style={{
              fontSize: "1.2em",
              width: "100%",
              textAlign: "center",
              marginBottom: "1em",
            }}
          >
            {" "}
            Enjoying PreMed.PK so far?
            <br />
            Spare a minute and show us some ❤️ on our social media! Clicking on
            these buttons will open up a new tab and your progress will not be
            affected!
          </div>
          <div className={styles.SocialMediaContainer}>
            <a
              className={styles.SocialMediaIcons}
              href="https://www.facebook.com/PreMed.PK"
              target="_blank"
            >
              {" "}
              <img src={FBIcon} />{" "}
            </a>
            <a
              className={styles.SocialMediaIcons}
              href="https://www.instagram.com/premed.pk/"
              target="_blank"
            >
              {" "}
              <img src={InstaIcon} />
            </a>
            <a
              className={styles.SocialMediaIcons}
              href="https://twitter.com/premedpk?s=21"
              target="_blank"
            >
              {" "}
              <img src={TwitterIcon} />
            </a>
          </div>
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-evenly",
              flexWrap: "wrap",
            }}
          >
            <Button
              pill
              onClick={() => onClose()}
              style={{ marginBottom: "1em" }}
            >
              I will do this later for sure!
            </Button>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};
 */

const PremiumMocksModal = (props) => {
  let history = useHistory();

  const { open, onClose } = props;
  // console.log(open);
  return (
    <Modal
      open={open}
      toggle={() => {
        onClose();
      }}
      centered
    >
      <ModalHeader>Announcement 📢</ModalHeader>
      <ModalBody>
        <div style={{ fontSize: "1.2em" }}>
          {" "}
          <div
            style={{
              fontSize: "1.2em",
              width: "100%",
              textAlign: "center",
              marginBottom: "1em",
            }}
          >
            Hello there👋 We hope your preparation is going well! After an
            overwhelming response on our AKU and NUMS Mocks, we have made{" "}
            <span style={{ color: "Red", fontWeight: "800" }}>
              {" "}
              4 Provincial MDCAT Mocks{" "}
            </span>{" "}
            for our Premium users according to the latest
            <span style={{ color: "green" }}>PMC 2022 syllabus </span> to make
            sure our users are well prepared for the MDCAT! Note: The MDCAT
            mocks are <span style={{ color: "blue" }}>free</span> for existing
            Premium users and will not be charged additionally.
          </div>
          <div
            style={{
              textAlign: "center",
            }}
          >
            <Button
              pill
              onClick={() => {
                history.push("/mocks");
                onClose();
              }}
              style={{ marginBottom: "1em" }}
            >
              Explore Mocks
            </Button>
            <div
              onClick={() => {
                onClose();
              }}
            >
              No, Thanks!
            </div>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

const MocksModal = (props) => {
  let history = useHistory();

  const { open, onClose } = props;

  var offerDate = GetOfferTime()
  var formattedDate = FormatOfferDate(offerDate)

  // console.log(open);
  return (
    <Modal
      open={open}
      toggle={() => {
        onClose();
      }}
      centered
    >
      <ModalHeader>Announcement 📢</ModalHeader>
      <ModalBody>
        <div style={{ fontSize: "1.2em" }}>
          {" "}
          <div
            style={{
              fontSize: "1.2em",
              width: "100%",
              textAlign: "center",
              marginBottom: "1em",
              fontFamily: "Rubik",
            }}
          >
            Hello MDCAT aspirants👋 Join our{" "}
            <span style={{ color: "#e06666" }}>early MDCAT session</span> for{" "}
            <span style={{ color: "#6d9eeb" }}>Year 2023</span> at{" "}
            <strong style={{ fontWeight: "600" }}>50% off</strong> for just{" "}
            <span className={styles.PriceWrapper}>
              <div className={styles.PriceSlash}></div> <div>Rs. 4000</div>
            </span>{" "}
            <strong style={{ fontWeight: "600" }}>Rs. 2000</strong>. Apply promo
            code <span style={{ color: "#38761d" }}>EARLY200 </span> to save
            further <strong style={{ fontWeight: "600" }}>Rs. 200 off</strong>.
            Offer ends on <strong> 27th March 2023!</strong>
          </div>
          <div
            style={{
              textAlign: "center",
            }}
          >
            <Button
              pill
              onClick={() => {
                history.push("/pricing");
                onClose();
              }}
              style={{ marginBottom: "1em" }}
            >
              Avail the Offer!
            </Button>
            <div
              onClick={() => {
                onClose();
              }}
            >
              No, Thanks!
            </div>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};
