import { ContactsOutlined } from "@material-ui/icons";
import React, { useEffect, useState, useContext } from "react";
import { BrowserRouter as Router, Route, Switch, Link } from "react-router-dom";
import { useHistory } from "react-router-dom";

import styles from "./styles.module.css";
import { Collapse, Button, Badge } from "shards-react";

import NUMSLogo from "../../StaticImages/NUMS Logo.png";
import NTSLogo from "../../StaticImages/NTS Logo.png";
import UHSLogo from "../../StaticImages/UHS Logo.jpg";
import PMCLogo from "../../StaticImages/PMC Logo.png";
import AKULogo from "../../StaticImages/PreMedAKULogo.svg";
import ETEALogo from "../../StaticImages/etealogo.jpg";
import FMDCLogo from "../../StaticImages/fmdclogo.jpg";
import PreMedIcon from "../../StaticImages/PreMedCircleLogo.png";
import HECLogo from "../../StaticImages/HEC.png";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";

import YearlyPageIcon from "../../StaticImages/YearlyPageIcon.svg";

import { GetYearlyDecks } from "../../APIManager";
import { UserContext } from "../../userContext";

import { VerifyMockAddon } from "../../MockUtlityFunctions";

const premedMocks = [
  "PreMedPK MDCAT Mock 1",
  "PreMedPK MDCAT Mock 2",
  "PreMedPK MDCAT Mock 3",
  "PreMedPK MDCAT Mock 4",
];

const pmcPapers = [
  /*   "PMC Sample Solved Paper 1",
  "PMC Sample Solved Paper 2",
  "PMC Sample Solved Paper 3",
  "PMC Sample Solved Paper 4", */
  "PMC Practice Test 1",
  "PMC Practice Test 2",
  "PMC Practice Test 3",
  "PMC Practice Test 4",
  "PMC Practice Test 5",
  "PMC Practice Test 6",
  "PMC Practice Test 7",
  "PMC Practice Test 8",
  "PMC Practice Test 9",
  "PMC Practice Test 10",
  "PMC Practice Test 11",
  "PMC Practice Test 12",
  "PMC Practice Test 13",
  "PMC Practice Test 14",
  "PMC Practice Test 15",
  "PMC Practice Test 16",
  "PMC Practice Test 17",
  "PMC Practice Test 18",
  "PMC Practice Test 19",
  "PMC Practice Test 20",
  "PMC Practice Test 21",
  "PMC Practice Test 22",
  "PMC Practice Test 23",
  "PMC Practice Test 24",
  "PMC Practice Test 25",
  "PMC Practice Test 26",
  "PMC Practice Test 27",
  "PMC Practice Test 28",
  "PMC Practice Test 29",
  "PMC Practice Test 30",
  "PMC Practice Test 31",
  "PMC Practice Test 32",
  "PMC Practice Test 33",
  "PMC Practice Test 34",
  "PMC Practice Test 35",
  "PMC Practice Test 36",
];
const uhsPapers = ["UHS MDCAT 2019", "UHS MDCAT 2018"];
const numsPapers = [
  "NUMS 2020",
  "NUMS 2019 (Reconduct)",
  "NUMS 2019 (Cancelled)",
  "NUMS 2018",
  "NUMS 2017",
  "NUMS 2016",
  "NUMS 2015",
  "NUMS 2014",
];
const ntsPapers = [
  "Sindh MCAT NTS 2019",
  "Sindh MCAT NTS 2018",
  "Sindh MCAT NTS 2017",
  "Sindh MCAT NTS 2016 (SMBBMC Lyari)",
  "Sindh MCAT NTS 2016 (DUHS and JSMU)",
  "Sindh MCAT NTS 2015 (SMBBMC Lyari)",
  "Sindh MCAT NTS 2015 (DUHS and JSMU)",
  "Sindh MCAT NTS 2014",
  "Sindh MCAT NTS 2013",
  "Sindh MCAT NTS 2012",
  "Sindh MCAT NTS 2011",
  "Sindh MCAT NTS 2010",
];

const eteaPapers = [];
const fmdcPapers = ["FMDC 2014", "FMDC 2013", "FMDC 2012"];

const hecPapers = ["HEC Phase I 2017", "HEC Phase II 2017"];

const YearlyPage = () => {
  let history = useHistory();
  let [userContext, SetUserContext] = useContext(UserContext);
  const isPremium = userContext.addonspurchased.includes("Premium");

  const [numsCollapse, SetNumsCollapse] = useState(false);
  const [ntsCollapse, SetNtsCollapse] = useState(false);
  const [uhsCollapse, SetUhsCollapse] = useState(false);
  const [pmcCollapse, SetPmcCollapse] = useState(false);
  const [akuCollapse, SetAkuCollapse] = useState(false);
  const [eteaCollapse, SetEteaCollapse] = useState(false);
  const [fmdcCollapse, SetFmdcCollapse] = useState(false);
  const [hecCollapse, SetHecCollapse] = useState(false);
  const [premedCollapse, SetPremedCollapse] = useState(false);

  const [ntsDecks, SetNtsDecks] = useState(ntsPapers);
  const [numsDecks, SetNumsDecks] = useState(numsPapers);
  const [uhsDecks, SetUhsDecks] = useState([]);
  const [pmcDecks, SetPmcDecks] = useState(pmcPapers);
  const [eteaDecks, SetEteaDecks] = useState(eteaPapers);
  const [fmdcDecks, SetFmdcDecks] = useState(fmdcPapers);
  const [hecDecks, SetHecDecks] = useState(hecPapers);

  var akuPapers = [
    {
      Name: "AKU Mock Paper 1",
      BadgeText: "Free",
      onBadgeClick: () => {},
      isPurchased: true,
    },
    {
      Name: "AKU Mock Paper 2",
      BadgeText: "",
      onBadgeClick: () => {},
      isPurchased: VerifyMockAddon(
        "AKU Mock Paper 2",
        userContext.addonspurchased
      ),
    },
    {
      Name: "AKU Mock Paper 3",
      BadgeText: "",
      onBadgeClick: () => {},
      isPurchased: VerifyMockAddon(
        "AKU Mock Paper 3",
        userContext.addonspurchased
      ),
    },
    {
      Name: "AKU Mock Paper 4",
      BadgeText: "",
      onBadgeClick: () => {},
      isPurchased: VerifyMockAddon(
        "AKU Mock Paper 4",
        userContext.addonspurchased
      ),
    },
    {
      Name: "AKU Mock Paper 5",
      BadgeText: "",
      onBadgeClick: () => {},
      isPurchased: VerifyMockAddon(
        "AKU Mock Paper 5",
        userContext.addonspurchased
      ),
    },
  ];

  useEffect(() => {
    GetYearlyDecks().then((res) => {
      var nts = [];
      var uhs = [];
      var nums = [];
      var pmc = [];
      res.data.forEach((deck) => {
        if (deck.Group === "SINDH MCAT NTS") {
          nts.push(deck.Name);
        } else if (deck.Group === "UHS MDCAT") {
          uhs.push(deck.Name);
        } else if (deck.Group === "NUMS MDCAT") {
          nums.push(deck.Name);
        } else if (deck.Group === "PMC National MDCAT") {
          pmc.push(deck.Name);
        }
      });
      console.log(res.data);
      nts.sort().reverse();
      uhs.sort().reverse();
      nums.sort().reverse();
      pmc.sort((a, b) => {
        return pmcPapers.indexOf(a) - pmcPapers.indexOf(b);
      });
      SetNtsDecks(nts);
      SetUhsDecks(uhs);
      SetNumsDecks(nums);
      SetPmcDecks(pmc);
    });
  }, []);

  return (
    <div className={styles.GradientDiv}>
      <div className={styles.MainDiv}>
        <div className={styles.ContentDiv}>
          <div className={styles.PageHeader}>
            <h1>
              <span>
                <img src={YearlyPageIcon} />
              </span>
              <span> Yearly MCQs</span>
            </h1>
            <h4>
              Welcome
              {userContext.fullname
                ? ` ${userContext.fullname.split(" ").slice(0, 2).join(" ")}! `
                : "! "}
              Attempt a Full-Length Yearly Paper today and experience the
              feeling of giving the exam on the actual test day
            </h4>
          </div>

          <div className={styles.GroupsContainer}>
            <div className={styles.Group}>
              <div
                className={styles.GroupHeader}
                onClick={() => {
                  SetPremedCollapse(!premedCollapse);
                }}
              >
                <img
                  src={PreMedIcon}
                  className={styles.LogoImage}
                  style={{ width: "7em" }}
                />
                <span
                  className={styles.GroupName}
                  style={{ lineHeight: "0.9em", fontSize: "2.3em" }}
                >
                  MDCAT Mocks (4 Papers) <br />
                  <div
                    style={{
                      fontSize: "0.4em",
                      marginBottom: "-0.5em",
                      marginTop: "0.2em",
                      lineBreak: "normal",
                      whiteSpace: "normal",
                      lineHeight: "1.2em",
                    }}
                  >
                    MDCAT Mocks are made according to the latest PMC MDCAT 2022
                    syllabus and are valid for students from all provinces.
                  </div>
                </span>
                <div className={styles.ArrowContainer}>
                  <div className={styles.ArrowContainer}>
                    <span
                      className={`${styles.ArrowIcon} ${
                        premedCollapse ? styles.ArrowUp : styles.ArrowIcon
                      }`}
                      style={{ width: "fitContent" }}
                    >
                      <ArrowDropDownIcon
                        style={{ fontSize: "6em" }}
                        onClick={() => {
                          SetNumsCollapse(!premedCollapse);
                        }}
                      />
                    </span>
                  </div>
                </div>
              </div>
              <Collapse open={premedCollapse}>
                <div className={styles.PapersContainer}>
                  {premedMocks.map((deck) => (
                    <div key={deck.Name} className={styles.Paper}>
                      <div className={styles.PaperName}>
                        <Link
                          to={
                            userContext.addonspurchased.includes("Premium")
                              ? `/yearly`
                              : "/yearly"
                          }
                          style={{ marginRight: "0.2em" }}
                        >
                          {deck}
                        </Link>
                        <span className={styles.PaperNameBadgeSpan}>
                          {" "}
                          <label className={`${styles.premiumLabel} py-2 px-4`}>
                            {userContext.addonspurchased.includes("Premium")
                              ? "Closed"
                              : "Closed"}
                          </label>
                        </span>
                      </div>
                      <hr />
                    </div>
                  ))}
                </div>
              </Collapse>
            </div>
            <div className={styles.Group}>
              <div
                className={styles.GroupHeader}
                onClick={() => {
                  SetPmcCollapse(!pmcCollapse);
                }}
              >
                <img src={PMCLogo} className={styles.LogoImage} />
                <span className={styles.GroupName}>PMC (42 Papers)</span>
                <div className={styles.ArrowContainer}>
                  <span
                    className={`${styles.ArrowIcon} ${
                      pmcCollapse ? styles.ArrowUp : styles.ArrowIcon
                    }`}
                    style={{ width: "fitContent" }}
                  >
                    <ArrowDropDownIcon
                      style={{ fontSize: "6em" }}
                      onClick={() => {
                        SetPmcCollapse(!pmcCollapse);
                      }}
                    />
                  </span>
                </div>
              </div>
              <Collapse open={pmcCollapse}>
                <div className={styles.PapersContainer}>
                  <div key={"PMC National MDCAT 2020"} className={styles.Paper}>
                    <div className={styles.PaperName}>
                      <Link
                        to={
                          userContext.isloggedin
                            ? `/deck/attempt?deck=${"PMC National MDCAT 2020"}`
                            : `/attempt?deck=${"PMC National MDCAT 2020"}`
                        }
                        style={{ marginRight: "0.2em" }}
                      >
                        PMC National MDCAT 2020
                      </Link>
                      <span
                        className={styles.PaperNameBadgeSpan}
                        hidden={isPremium}
                      >
                        {" "}
                        <label class={`${styles.freeLabel} py-2 px-4`}>
                          Free
                        </label>
                      </span>
                    </div>
                    <hr />
                  </div>
                  {pmcDecks
                    .filter((d) => d !== "PMC National MDCAT 2020")
                    .map((deck) => (
                      <div key={deck} className={styles.Paper}>
                        <div className={styles.PaperName}>
                          <Link
                            to={`/deck/attempt?deck=${deck}`}
                            style={{ marginRight: "0.2em" }}
                          >
                            {deck} 2021
                          </Link>
                          <span
                            className={styles.PaperNameBadgeSpan}
                            hidden={isPremium}
                          >
                            {" "}
                            <label class={`${styles.premiumLabel} py-2 px-4`}>
                              Premium
                            </label>
                          </span>
                        </div>
                        <hr />
                      </div>
                    ))}
                  {/*      {Array(6)
                    .fill(0)
                    .map((a, index) => (
                      <div
                        key={`PMC Paper ${index + 31}`}
                        className={styles.Paper}
                      >
                        <div className={styles.PaperName}>
                          {`PMC Practice Test ${index + 31}`}{" "}
                          <span className={styles.PaperNameBadgeSpan}>
                            {" "}
                            <label
                              class={`${styles.comingSoonLabel} py-2 px-4`}
                            >
                              Coming Soon
                            </label>
                          </span>
                        </div>
                        <hr />
                      </div>
                    ))} */}
                  <div
                    key={"PMC Practice Test 1 2022"}
                    className={styles.Paper}
                  >
                    <div className={styles.PaperName}>
                      <Link
                        to={
                          userContext.isloggedin
                            ? `/deck/attempt?deck=${"PMC Practice Test 1 (2022)"}`
                            : `/attempt?deck=${"PMC Practice Test 1 (2022)"}`
                        }
                        style={{ marginRight: "0.2em" }}
                      >
                        PMC Practice Test 1 2022
                      </Link>
                      <span
                        className={styles.PaperNameBadgeSpan}
                        hidden={isPremium}
                      >
                        {" "}
                        <label class={`${styles.freeLabel} py-2 px-4`}>
                          Free
                        </label>
                      </span>
                    </div>
                    <hr />
                  </div>
                  {Array(4)
                    .fill(0)
                    .map((a, index) => (
                      <div
                        key={`PMC Practice Test ${index + 2} 2022`}
                        className={styles.Paper}
                      >
                        <div className={styles.PaperName}>
                          <Link
                            to={`/deck/attempt?deck=${`PMC Practice Test ${
                              index + 2
                            } (2022)`}`}
                            style={{ marginRight: "0.2em" }}
                          >
                            {`PMC Practice Test ${index + 2} 2022`}{" "}
                          </Link>
                          <span
                            className={styles.PaperNameBadgeSpan}
                            hidden={isPremium}
                          >
                            {" "}
                            <label class={`${styles.premiumLabel} py-2 px-4`}>
                              Premium
                            </label>
                          </span>
                        </div>
                        <hr />
                      </div>
                    ))}{" "}
                </div>
              </Collapse>
            </div>
            <div className={styles.Group}>
              <div
                className={styles.GroupHeader}
                onClick={() => {
                  SetNumsCollapse(!numsCollapse);
                }}
              >
                <img src={NUMSLogo} className={styles.LogoImage} />
                <span className={styles.GroupName}>
                  NUMS {numsDecks ? `(${numsDecks.length}  Papers)` : ""}{" "}
                </span>
                <div className={styles.ArrowContainer}>
                  <div className={styles.ArrowContainer}>
                    <span
                      className={`${styles.ArrowIcon} ${
                        numsCollapse ? styles.ArrowUp : styles.ArrowIcon
                      }`}
                      style={{ width: "fitContent" }}
                    >
                      <ArrowDropDownIcon
                        style={{ fontSize: "6em" }}
                        onClick={() => {
                          SetNumsCollapse(!numsCollapse);
                        }}
                      />
                    </span>
                  </div>
                </div>
              </div>
              <Collapse open={numsCollapse}>
                <div className={styles.PapersContainer}>
                  <div key={"PMC National MDCAT 2020"} className={styles.Paper}>
                    <div className={styles.PaperName}>
                      <Link
                        to={
                          userContext.isloggedin
                            ? `/deck/attempt?deck=${"NUMS 2021"}`
                            : `/attempt?deck=${"NUMS 2021"}`
                        }
                        style={{ marginRight: "0.2em" }}
                      >
                        NUMS 2021
                      </Link>
                      <span
                        className={styles.PaperNameBadgeSpan}
                        hidden={isPremium}
                      >
                        {" "}
                        <label class={`${styles.freeLabel} py-2 px-4`}>
                          Free
                        </label>
                      </span>
                    </div>
                    <hr />
                  </div>
                  {numsDecks.map((deck) => (
                    <div key={deck} className={styles.Paper}>
                      <div className={styles.PaperName}>
                        <Link
                          to={`/deck/attempt?deck=${deck}`}
                          style={{ marginRight: "0.2em" }}
                        >
                          {deck}
                        </Link>
                        <span
                          className={styles.PaperNameBadgeSpan}
                          hidden={isPremium}
                        >
                          {" "}
                          <label class={`${styles.premiumLabel} py-2 px-4`}>
                            Premium
                          </label>
                        </span>
                      </div>
                      <hr />
                    </div>
                  ))}
                </div>
              </Collapse>
            </div>
            <div className={styles.Group}>
              <div
                className={styles.GroupHeader}
                onClick={() => {
                  SetNtsCollapse(!ntsCollapse);
                }}
              >
                <img src={NTSLogo} className={styles.LogoImage} />
                <span className={styles.GroupName}>
                  NTS {ntsDecks ? `(${ntsDecks.length}  Papers)` : ""}{" "}
                </span>
                <div className={styles.ArrowContainer}>
                  <div className={styles.ArrowContainer}>
                    <span
                      className={`${styles.ArrowIcon} ${
                        ntsCollapse ? styles.ArrowUp : styles.ArrowIcon
                      }`}
                      style={{ width: "fitContent" }}
                    >
                      <ArrowDropDownIcon
                        style={{ fontSize: "6em" }}
                        onClick={() => {
                          SetNtsCollapse(!ntsCollapse);
                        }}
                      />
                    </span>
                  </div>
                </div>
              </div>
              <Collapse open={ntsCollapse}>
                <div className={styles.PapersContainer}>
                  <div key={"Sindh MCAT NTS 2019"} className={styles.Paper}>
                    <div className={styles.PaperName}>
                      <Link
                        to={
                          userContext.isloggedin
                            ? `/deck/attempt?deck=${"Sindh MCAT NTS 2019"}`
                            : `/attempt?deck=${"Sindh MCAT NTS 2019"}`
                        }
                        style={{ marginRight: "0.2em" }}
                      >
                        Sindh MCAT NTS 2019
                      </Link>
                      <span
                        className={styles.PaperNameBadgeSpan}
                        hidden={isPremium}
                      >
                        {" "}
                        <label class={`${styles.freeLabel} py-2 px-4`}>
                          Free
                        </label>
                      </span>
                    </div>
                    <hr />
                  </div>
                  {ntsDecks
                    .filter((d) => d != "Sindh MCAT NTS 2019")
                    .map((deck) => (
                      <div key={deck} className={styles.Paper}>
                        <div className={styles.PaperName}>
                          <Link
                            to={`/deck/attempt?deck=${deck}`}
                            style={{ marginRight: "0.2em" }}
                          >
                            {deck}
                          </Link>
                          <span
                            className={styles.PaperNameBadgeSpan}
                            hidden={isPremium}
                          >
                            {" "}
                            <label class={`${styles.premiumLabel} py-2 px-4`}>
                              Premium
                            </label>
                          </span>
                        </div>
                        <hr />
                      </div>
                    ))}
                </div>
              </Collapse>
            </div>
            <div className={styles.Group}>
              <div
                className={styles.GroupHeader}
                onClick={() => {
                  SetUhsCollapse(!uhsCollapse);
                }}
              >
                <img src={UHSLogo} className={styles.LogoImage} />
                <span className={styles.GroupName}>
                  UHS {uhsDecks ? `(${uhsDecks.length}  Papers)` : ""}{" "}
                </span>
                <div className={styles.ArrowContainer}>
                  <div className={styles.ArrowContainer}>
                    <span
                      className={`${styles.ArrowIcon} ${
                        uhsCollapse ? styles.ArrowUp : styles.ArrowIcon
                      }`}
                      style={{ width: "fitContent" }}
                    >
                      <ArrowDropDownIcon
                        style={{ fontSize: "6em" }}
                        onClick={() => {
                          SetUhsCollapse(!uhsCollapse);
                        }}
                      />
                    </span>
                  </div>
                </div>
              </div>
              <Collapse open={uhsCollapse}>
                <div className={styles.PapersContainer}>
                  <div key={"UHS MDCAT 2019"} className={styles.Paper}>
                    <div className={styles.PaperName}>
                      <Link
                        style={{ marginRight: "0.2em" }}
                        to={
                          userContext.isloggedin
                            ? `/deck/attempt?deck=${"UHS MDCAT 2019"}`
                            : `/attempt?deck=${"UHS MDCAT 2019"}`
                        }
                      >
                        {"UHS MDCAT 2019"}
                      </Link>
                      <span
                        className={styles.PaperNameBadgeSpan}
                        hidden={isPremium}
                      >
                        {" "}
                        <label class={`${styles.freeLabel} py-2 px-4`}>
                          Free
                        </label>
                      </span>
                    </div>
                    <hr />
                  </div>
                  {uhsDecks
                    .filter((d) => d != "UHS MDCAT 2019")
                    .map((deck) => (
                      <div key={deck} className={styles.Paper}>
                        <div className={styles.PaperName}>
                          <Link
                            style={{ marginRight: "0.2em" }}
                            to={`/deck/attempt?deck=${deck}`}
                          >
                            {deck}
                          </Link>
                          <span
                            className={styles.PaperNameBadgeSpan}
                            hidden={isPremium}
                          >
                            {" "}
                            <label class={`${styles.premiumLabel} py-2 px-4`}>
                              Premium
                            </label>
                          </span>
                        </div>
                        <hr />
                      </div>
                    ))}
                  {Array(2)
                    .fill(0)
                    .map((a, index) => (
                      <div
                        key={`UHS MDCAT ${index + 2008}`}
                        className={styles.Paper}
                      >
                        <div className={styles.PaperName}>
                          {`UHS MDCAT ${index + 2008}`}{" "}
                          <span className={styles.PaperNameBadgeSpan}>
                            {" "}
                            <label
                              class={`${styles.comingSoonLabel} py-2 px-4`}
                            >
                              Coming Soon
                            </label>
                          </span>
                        </div>
                        <hr />
                      </div>
                    ))
                    .reverse()}
                </div>
              </Collapse>
            </div>

            <div className={styles.Group}>
              <div
                className={styles.GroupHeader}
                onClick={() => {
                  SetEteaCollapse(!eteaCollapse);
                }}
              >
                <img src={ETEALogo} className={styles.LogoImage} />
                <span
                  className={styles.GroupName}
                  style={{ lineHeight: "0.9em" }}
                >
                  ETEA (7 Papers)
                </span>
                <div className={styles.ArrowContainer}>
                  <div className={styles.ArrowContainer}>
                    <span
                      className={`${styles.ArrowIcon} ${
                        eteaCollapse ? styles.ArrowUp : styles.ArrowIcon
                      }`}
                      style={{ width: "fitContent" }}
                    >
                      <ArrowDropDownIcon
                        style={{ fontSize: "6em" }}
                        onClick={() => {
                          SetEteaCollapse(!eteaCollapse);
                        }}
                      />
                    </span>
                  </div>
                </div>
              </div>
              <Collapse open={eteaCollapse}>
                <div className={styles.PapersContainer}>
                  <div key={"ETEA MDCAT 2019"} className={styles.Paper}>
                    <div className={styles.PaperName}>
                      <Link
                        style={{ marginRight: "0.2em" }}
                        to={
                          userContext.isloggedin
                            ? `/deck/attempt?deck=${"ETEA MDCAT 2019"}`
                            : `/attempt?deck=${"ETEA MDCAT 2019"}`
                        }
                      >
                        {"ETEA MDCAT 2019"}
                      </Link>
                      <span
                        className={styles.PaperNameBadgeSpan}
                        hidden={isPremium}
                      >
                        {" "}
                        <label class={`${styles.freeLabel} py-2 px-4`}>
                          Free
                        </label>
                      </span>
                    </div>
                    <hr />
                  </div>
                  {Array(6)
                    .fill(0)
                    .map((a, index) => (
                      <div
                        key={`ETEA MDCAT ${index + 2013}`}
                        className={styles.Paper}
                      >
                        <div className={styles.PaperName}>
                          <Link
                            to={`/deck/attempt?deck=${`ETEA MDCAT ${
                              index + 2013
                            }`}`}
                            style={{ marginRight: "0.2em" }}
                          >
                            {`ETEA MDCAT ${index + 2013}`}{" "}
                          </Link>
                          <span
                            className={styles.PaperNameBadgeSpan}
                            hidden={isPremium}
                          >
                            {" "}
                            {index == 8 ? (
                              <label class={`${styles.freeLabel} py-2 px-4`}>
                                Free
                              </label>
                            ) : (
                              <label class={`${styles.premiumLabel} py-2 px-4`}>
                                Premium
                              </label>
                            )}
                          </span>
                        </div>
                        <hr />
                      </div>
                    ))
                    .reverse()}{" "}
                </div>
              </Collapse>
            </div>

            <div className={styles.Group}>
              <div
                className={styles.GroupHeader}
                onClick={() => {
                  SetFmdcCollapse(!fmdcCollapse);
                }}
              >
                <img src={FMDCLogo} className={styles.LogoImage} />
                <span
                  className={styles.GroupName}
                  style={{ lineHeight: "0.9em" }}
                >
                  SZABMU/FMDC (5 Papers)
                  <br />
                </span>
                <div className={styles.ArrowContainer}>
                  <div className={styles.ArrowContainer}>
                    <span
                      className={`${styles.ArrowIcon} ${
                        fmdcCollapse ? styles.ArrowUp : styles.ArrowIcon
                      }`}
                      style={{ width: "fitContent" }}
                    >
                      <ArrowDropDownIcon
                        style={{ fontSize: "6em" }}
                        onClick={() => {
                          SetFmdcCollapse(!fmdcCollapse);
                        }}
                      />
                    </span>
                  </div>
                </div>
              </div>
              <Collapse open={fmdcCollapse}>
                <div className={styles.PapersContainer}>
                  <div key={"FMDC 2018"} className={styles.Paper}>
                    <div className={styles.PaperName}>
                      <Link
                        style={{ marginRight: "0.2em" }}
                        to={
                          userContext.isloggedin
                            ? `/deck/attempt?deck=${"FMDC 2018"}`
                            : `/attempt?deck=${"FMDC 2018"}`
                        }
                      >
                        {"FMDC 2018"}
                      </Link>
                      <span
                        className={styles.PaperNameBadgeSpan}
                        hidden={isPremium}
                      >
                        {" "}
                        <label class={`${styles.freeLabel} py-2 px-4`}>
                          Free
                        </label>
                      </span>
                    </div>
                    <hr />
                  </div>
                  <div key={"HEC Phase I 2017"} className={styles.Paper}>
                    <div className={styles.PaperName}>
                      <Link
                        style={{ marginRight: "0.2em" }}
                        to={`/deck/attempt?deck=${"HEC Phase I 2017"}`}
                      >
                        {"HEC Phase I 2017"}
                      </Link>
                      <span
                        className={styles.PaperNameBadgeSpan}
                        hidden={isPremium}
                      >
                        {" "}
                        <label class={`${styles.premiumLabel} py-2 px-4`}>
                          Premium
                        </label>
                      </span>
                    </div>
                    <hr />
                  </div>
                  <div key={"HEC Phase II 2017"} className={styles.Paper}>
                    <div className={styles.PaperName}>
                      <Link
                        style={{ marginRight: "0.2em" }}
                        to={`/deck/attempt?deck=${"HEC Phase II 2017"}`}
                      >
                        {"HEC Phase II 2017"}
                      </Link>
                      <span
                        className={styles.PaperNameBadgeSpan}
                        hidden={isPremium}
                      >
                        {" "}
                        <label class={`${styles.premiumLabel} py-2 px-4`}>
                          Premium
                        </label>
                      </span>
                    </div>
                    <hr />
                  </div>
                  <div key={"FMDC 2016"} className={styles.Paper}>
                    <div className={styles.PaperName}>
                      <Link
                        style={{ marginRight: "0.2em" }}
                        to={`/deck/attempt?deck=${"FMDC 2016"}`}
                      >
                        {"FMDC 2016"}
                      </Link>
                      <span
                        className={styles.PaperNameBadgeSpan}
                        hidden={isPremium}
                      >
                        {" "}
                        <label class={`${styles.premiumLabel} py-2 px-4`}>
                          Premium
                        </label>
                      </span>
                    </div>
                    <hr />
                  </div>
                  <div key={"FMDC 2015"} className={styles.Paper}>
                    <div className={styles.PaperName}>
                      <Link
                        style={{ marginRight: "0.2em" }}
                        to={`/deck/attempt?deck=${"FMDC 2015"}`}
                      >
                        {"FMDC 2015"}
                      </Link>
                      <span
                        className={styles.PaperNameBadgeSpan}
                        hidden={isPremium}
                      >
                        {" "}
                        <label class={`${styles.premiumLabel} py-2 px-4`}>
                          Premium
                        </label>
                      </span>
                    </div>
                    <hr />
                  </div>
                  {fmdcDecks.map((deck) => (
                    <div key={deck} className={styles.Paper}>
                      <div className={styles.PaperName}>
                        {deck}{" "}
                        <span className={styles.PaperNameBadgeSpan}>
                          {" "}
                          <label class={`${styles.comingSoonLabel} py-2 px-4`}>
                            Coming Soon
                          </label>
                        </span>
                      </div>
                      <hr />
                    </div>
                  ))}
                </div>
              </Collapse>
            </div>
            {/*   <div className={styles.Group}>
              <div
                className={styles.GroupHeader}
                onClick={() => {
                  SetHecCollapse(!hecCollapse);
                }}
              >
                <img src={HECLogo} className={styles.LogoImage} />
                <span
                  className={styles.GroupName}
                  style={{ lineHeight: "0.9em" }}
                >
                  HEC <br />
                </span>
                <div className={styles.ArrowContainer}>
                  <div className={styles.ArrowContainer}>
                    <span
                      className={`${styles.ArrowIcon} ${
                        hecCollapse ? styles.ArrowUp : styles.ArrowIcon
                      }`}
                      style={{ width: "fitContent" }}
                    >
                      <ArrowDropDownIcon
                        style={{ fontSize: "6em" }}
                        onClick={() => {
                          SetHecCollapse(!hecCollapse);
                        }}
                      />
                    </span>
                  </div>
                </div>
              </div>
              <Collapse open={hecCollapse}>
                <div className={styles.PapersContainer}>
                {hecDecks
                    .map((a, index) => (
                      <div
                        key={a}
                        className={styles.Paper}
                      >
                        <div className={styles.PaperName}>
                          <Link
                            to={`/deck/attempt?deck=${a}`}
                            style={{ marginRight: "0.2em" }}
                          >
                            {a}{" "}
                          </Link>
                          <span
                            className={styles.PaperNameBadgeSpan}
                            hidden={isPremium}
                          >
                            {" "}
                            {index == 3 ? (
                              <label class={`${styles.freeLabel} py-2 px-4`}>
                                Free
                              </label>
                            ) : (
                              <label class={`${styles.premiumLabel} py-2 px-4`}>
                                Premium
                              </label>
                            )}
                          </span>
                        </div>
                        <hr />
                      </div>
                    ))
                    .reverse()}{" "}
                </div>
              </Collapse>
            </div> */}
            <div className={styles.Group}>
              <div
                className={styles.GroupHeader}
                onClick={() => {
                  SetAkuCollapse(!akuCollapse);
                }}
              >
                <img
                  src={AKULogo}
                  className={styles.LogoImage}
                  style={{ width: "18em" }}
                />
                <span
                  className={styles.GroupName}
                  style={{ lineHeight: "0.9em", fontSize: "2.3em" }}
                >
                  AKU Mocks (5 Papers) <br />
                  <div style={{ fontSize: "0.4em", marginBottom: "-0.5em" }}>
                    Disclaimer: PreMed.PK is not officially affiliated with AKU{" "}
                  </div>
                </span>
                <div className={styles.ArrowContainer}>
                  <div className={styles.ArrowContainer}>
                    <span
                      className={`${styles.ArrowIcon} ${
                        akuCollapse ? styles.ArrowUp : styles.ArrowIcon
                      }`}
                      style={{ width: "fitContent" }}
                    >
                      <ArrowDropDownIcon
                        style={{ fontSize: "6em" }}
                        onClick={() => {
                          SetAkuCollapse(!akuCollapse);
                        }}
                      />
                    </span>
                  </div>
                </div>
              </div>
              <Collapse open={akuCollapse}>
                <div className={styles.PapersContainer}>
                  {akuPapers.map((deck) => (
                    <div key={deck.Name} className={styles.Paper}>
                      <div className={styles.PaperName}>
                        <Link
                          to={deck.isPurchased ? `/mocks` : "/mocks"}
                          style={{ marginRight: "0.2em" }}
                        >
                          {deck.Name}
                        </Link>
                        <span className={styles.PaperNameBadgeSpan}>
                          {" "}
                          <label className={`${styles.premiumLabel} py-2 px-4`}>
                            Closed
                          </label>
                        </span>
                      </div>
                      <hr />
                    </div>
                  ))}
                </div>
              </Collapse>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default YearlyPage;
