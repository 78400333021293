import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { useHistory } from "react-router-dom";
import { Alert, Badge } from "shards-react";
import "bootstrap/dist/css/bootstrap.min.css";
import "shards-ui/dist/css/shards.min.css";
import styles from "./DeckStartComponent.module.css";
import { GetDeckDescription } from "../../APIManager";
import Lightbulbicon from "../../StaticImages/light-bulb.svg";
import YearlyModeToggle from "./YearlyModeToggle";
import TutorModeIcon from "../../StaticImages/TutorModeIcon.svg";
import TitleIcon from "../../StaticImages/savedquestionsicon.svg";

import {
  Card,
  CardHeader,
  CardTitle,
  CardImg,
  CardBody,
  CardFooter,
  Button,
  Fade,
  FormRadio,
} from "shards-react";
import NUMSLogo from "../../StaticImages/NUMS Logo.png";
import NTSLogo from "../../StaticImages/NTS Logo.png";
import UHSLogo from "../../StaticImages/UHS Logo.jpg";
import PMCLogo from "../../StaticImages/PMC Logo.png";
import AKULogo from "../../StaticImages/AKU Logo.png";

const DeckMetaInfo = {
  "PMC National MDCAT 2020": {
    Eng: 20,
    Bio: 80,
    Chem: 60,
    Phys: 40,
    Time: 150,
  },
  "Sindh MCAT NTS 2019": { Eng: 20, Bio: 80, Chem: 60, Phys: 40, Time: 150 },
  "Sindh MCAT NTS 2018": { Eng: 10, Bio: 30, Chem: 30, Phys: 30, Time: 120 },
  "Sindh MCAT NTS 2017": { Eng: 10, Bio: 30, Chem: 30, Phys: 30, Time: 120 },
  "Sindh MCAT NTS 2016 (SMBBMC Lyari)": {
    Eng: 10,
    Bio: 30,
    Chem: 30,
    Phys: 30,
    Time: 120,
  },
  "Sindh MCAT NTS 2016 (DUHS and JSMU)": {
    Eng: 10,
    Bio: 30,
    Chem: 30,
    Phys: 30,
    Time: 120,
  },
  "Sindh MCAT NTS 2015 (SMBBMC Lyari)": {
    Eng: 10,
    Bio: 30,
    Chem: 30,
    Phys: 30,
    Time: 120,
  },
  "Sindh MCAT NTS 2015 (DUHS and JSMU)": {
    Eng: 10,
    Bio: 30,
    Chem: 30,
    Phys: 30,
    Time: 120,
  },
  "Sindh MCAT NTS 2014": { Eng: 10, Bio: 30, Chem: 30, Phys: 30, Time: 120 },
  "Sindh MCAT NTS 2013": { Eng: 10, Bio: 30, Chem: 30, Phys: 30, Time: 120 },
  "Sindh MCAT NTS 2012": { Eng: 10, Bio: 30, Chem: 30, Phys: 30, Time: 120 },
  "Sindh MCAT NTS 2011": { Eng: 10, Bio: 30, Chem: 30, Phys: 30, Time: 120 },
  "Sindh MCAT NTS 2010": { Eng: 10, Bio: 30, Chem: 30, Phys: 30, Time: 120 },
  "NUMS 2020": { Eng: 20, Bio: 80, Chem: 60, Phys: 40, Time: 150 },
  "NUMS 2019 (Reconduct)": { Eng: 20, Bio: 80, Chem: 60, Phys: 40, Time: 150 },
  "NUMS 2019 (Cancelled)": { Eng: 20, Bio: 80, Chem: 60, Phys: 40, Time: 150 },
  "NUMS 2018": { Eng: 20, Bio: 80, Chem: 60, Phys: 40, Time: 150 },
  "NUMS 2017": { Eng: 20, Bio: 70, Chem: 45, Phys: 45, Time: 180 },
  "NUMS 2016": { Eng: 20, Bio: 70, Chem: 45, Phys: 45, Time: 180 },
  "NUMS 2015": { Eng: 20, Bio: 80, Chem: 60, Phys: 30, Lr: 10, Time: 180 },
  "NUMS 2014": { Eng: 20, Bio: 80, Chem: 60, Phys: 30, Lr: 10, Time: 180 },
  "PMC Sample Solved Paper 1": {
    Eng: 20,
    Bio: 68,
    Chem: 56,
    Phys: 56,
    Lr: 10,
    Time: 210,
  },
  "PMC Sample Solved Paper 2": {
    Eng: 20,
    Bio: 68,
    Chem: 56,
    Phys: 56,
    Lr: 10,
    Time: 210,
  },
  "PMC Sample Solved Paper 3": {
    Eng: 20,
    Bio: 68,
    Chem: 56,
    Phys: 56,
    Lr: 10,
    Time: 210,
  },
  "PMC Sample Solved Paper 4": {
    Eng: 20,
    Bio: 68,
    Chem: 56,
    Phys: 56,
    Lr: 10,
    Time: 210,
  },
  "PMC Sample Solved Paper 5": {
    Eng: 20,
    Bio: 68,
    Chem: 56,
    Phys: 56,
    Lr: 10,
    Time: 210,
  },
  "PMC Practice Test 1": {
    Eng: 20,
    Bio: 68,
    Chem: 56,
    Phys: 56,
    Lr: 10,
    Time: 210,
  },
  "PMC Practice Test 2": {
    Eng: 20,
    Bio: 68,
    Chem: 56,
    Phys: 56,
    Lr: 10,
    Time: 210,
  },
  "PMC Practice Test 3": {
    Eng: 20,
    Bio: 68,
    Chem: 56,
    Phys: 56,
    Lr: 10,
    Time: 210,
  },
  "PMC Practice Test 4": {
    Eng: 20,
    Bio: 68,
    Chem: 56,
    Phys: 56,
    Lr: 10,
    Time: 210,
  },
  "PMC Practice Test 5": {
    Eng: 20,
    Bio: 68,
    Chem: 56,
    Phys: 56,
    Lr: 10,
    Time: 210,
  },
  "PMC Practice Test 6": {
    Eng: 20,
    Bio: 68,
    Chem: 56,
    Phys: 56,
    Lr: 10,
    Time: 210,
  },
  "UHS MDCAT 2019": {
    Eng: 20,
    Bio: 80,
    Chem: 60,
    Phys: 40,
    Time: 150,
  },
  "UHS MDCAT 2018": {
    Eng: 30,
    Bio: 88,
    Chem: 58,
    Phys: 44,
    Time: 150,
  },
};

function QuizStartScreen({
  onStartCB,
  Name,
  NoOfQuestions,
  showResume,
  resumeNumber,
  resumeCB,
  loading,
}) {
  /*  const [Name , SetDeckName] = useState(props.deckName);
const [Questions , SetQuestions] = useState(props.noQuestions); */
  const Questions = NoOfQuestions;
  const [deckDescription, SetDeckDescription] = useState("Hello");
  const history = useHistory();

  useEffect(() => {
    GetDeckDescription(Name).then((res) => {
      //SetDeckDescription(res.data);
      // console.log(res.data)
    });
  }, [Name]);

  function GetImgSrc(deck) {
    if (!deck) {
      return NTSLogo;
    }
    if (deck.includes("PMC")) {
      return PMCLogo;
    }
    if (deck.includes("NTS")) {
      return NTSLogo;
    }
    if (deck.includes("UHS")) {
      return UHSLogo;
    }
    if (deck.includes("NUMS")) {
      return NUMSLogo;
    } else {
      return NTSLogo;
    }
  }

  // console.log(props);
  if (!Name) {
    return <></>;
  }
  return (
    <div style={{ textAlign: "center", margin: "auto" }}>
      <div className={styles.MainDiv}>
        <div className={styles.DeckHeaderContainer}>
          <div>
            <img src={TitleIcon} />
          </div>
          <div className={styles.DeckHeader}>
            <div className={styles.DeckName}>Saved Question Deck</div>
            <div className={styles.Questions}>{Questions} Questions</div>
          </div>
        </div>
        <div className={styles.ModeInfoContainer}>
          <img src={TutorModeIcon} />
          <br />
          <h3>Tutor Mode</h3>
          <div>
            <div className={`d-flex ${styles.CardTextRow}`}>
              <i className={`bi bi-check ${styles.checkIcon}`}></i>
              <p className={`${styles.cardText} mb-0`}>
                This deck is NOT <strong>timed</strong>
              </p>
            </div>
            <div className={`d-flex ${styles.CardTextRow}`}>
              <i className={`bi bi-check ${styles.checkIcon}`}></i>
              <p className={`${styles.cardText} mb-0`}>
                The Correct answer and explanation will be shown instantly once
                you select any option
              </p>
            </div>
          </div>
          <Button
            theme="success"
            onClick={(e) => {
              onStartCB();
            }}
            disabled={loading}
          >
            {loading ? "Getting Deck Ready" : "Start Deck"}
          </Button>
        </div>
      </div>
    </div>
  );
}

export default QuizStartScreen;

function RenderResumeQuestion(show, no, cb) {
  if (show) {
    return (
      <Button
        style={{ margin: "1em" }}
        theme="success"
        onClick={(e) => {
          cb();
        }}
      >
        Resume from question {no}
      </Button>
    );
  }
}
