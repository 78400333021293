import React, { useState, useContext, useEffect } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import axios from "axios";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import parse from "html-react-parser";
import GradientBtn from "../../../../Components/GradientBtn/GradientBtn.jsx";
import TitleIcon from "../../../../StaticImages/savedquestionsicon.svg";
import {
  Card,
  CardHeader,
  CardTitle,
  CardImg,
  CardBody,
  CardFooter,
  Button,
  ButtonGroup,
  Fade,
  Badge,
} from "shards-react";
import Chip from "@material-ui/core/Chip";
import Skeleton from "@material-ui/lab/Skeleton";

import {
  GetSavedQuestions,
  GetTags,
  GetQuestions,
  GetTopicalQuestions,
  GetTopicalPastPaperQuestions,
  DeleteBookmark,
} from "../../../../APIManager";
import styles from "./SavedQuestions.module.css";

import Tags from "../../../../Components/Tag-Textbox/Tags";

import { hiddenTags } from "../../../../Components/TagList/TagList";
import { TagFaces } from "@material-ui/icons";

import { useLocation, useHistory } from "react-router-dom";

const SavedQuestions = () => {
  const [fetched, SetFetched] = useState(false);

  const [savedQuestions, SetSavedQuestions] = useState([]);
  const [filteredQuestions, SetFilteredQuestions] = useState([]);
  const [currentTags, SetCurrentTags] = useState([]);
  const [suggestions, SetSuggestions] = useState([]);

  const [subject, SetSubject] = useState("All");

  const [key, SetKey] = useState("");

  const history = useHistory();

  useEffect(() => {
    GetSavedQuestions().then((res) => {
      var a = res.data;
      a.sort(function (a, b) {
        return a.createdAt > b.createdAt
          ? -1
          : a.createdAt > b.createdAt
          ? 1
          : 0;
      });
      SetSavedQuestions(a);
      SetFilteredQuestions(a);
      SetFetched(true);
    });

    GetTags().then((res) => {
      SetSuggestions(res.data);
    });
  }, []);

  useEffect(() => {
    //console.log(savedQuestions.length);
    if (subject === "All") {
      var newFilteredList = savedQuestions.filter(TagsFilter);
      //console.log(newFilteredList);
      SetFilteredQuestions(newFilteredList);
      return;
    } else {
      var newFilteredList = savedQuestions.filter(SubjectFilter);
      newFilteredList = newFilteredList.filter(TagsFilter);
      SetFilteredQuestions(newFilteredList);
    }

    function SubjectFilter(question) {
      var tags = question.Tags;
      return tags.includes(subject);
    }

    function TagsFilter(question) {
      var tags = question.Tags;
      var tags = question.Tags;

      var containsAll = currentTags.every((i) => tags.includes(i.name));
      return containsAll;
    }
  }, [subject, currentTags, savedQuestions]);

  function onTagDelete(i) {
    const tags = currentTags.slice(0);
    tags.splice(i, 1);
    SetCurrentTags([...tags]);
  }

  function onTagAddition(tag) {
    const tags = [].concat(currentTags, tag);
    SetCurrentTags([...tags]);
  }

  return (
    <div className={styles.SavedQuestionsMainBox}>
      <div className={styles.PageHeader}>
        <div>
          {" "}
          <img src={TitleIcon} />
        </div>

        <div>
          <h1>My Saved Questions</h1>
          <h2>Revisit all your saved questions!</h2>
        </div>
      </div>
      <div style={{ marginBottom: "1em" }}>
        <GradientBtn
          style={{
            fontSize: "0.9em",
            borderRadius: "30px",
            margin: 0,
            padding: "0.6em 2em",
            marginRight: "0.5em",
          }}
          gradient={"#639AF6 9.34%, #98BDF9 91.82%"}
          onClick={async () => {
            history.push(`/deck/saved-questions`);
          }}
        >
          Attempt All
        </GradientBtn>
      </div>
      <div className={styles.ToolBar}>
        <FilterToolBar
          onSubjectChange={(e) => {
            SetSubject(e);
          }}
        />
      </div>
      <div className={styles.TagSearch}>
        <Tags
          suggestions={suggestions}
          onDelete={onTagDelete}
          onAddition={onTagAddition}
          tags={currentTags}
          placeholderText="Search topics to filter"
        />
      </div>

      <div className={styles.SavedQuestions_MainFlex}>
        {fetched ? (
          <RenderSavedQuestions
            key={savedQuestions.length + filteredQuestions.length}
            rawData={filteredQuestions}
            fullData={savedQuestions}
            onDelete={() => {
              GetSavedQuestions().then((res) => {
                var a = res.data;
                a.sort(function (a, b) {
                  return a.createdAt > b.createdAt
                    ? -1
                    : a.createdAt > b.createdAt
                    ? 1
                    : 0;
                });
                SetSavedQuestions([...a]);
              });
            }}
          />
        ) : (
          RenderSkeleton()
        )}
      </div>
    </div>
  );
};

export default SavedQuestions;

const RenderSkeleton = () => {
  const rawData = [1, 2, 2, 4];
  return (
    <div>
      {rawData.map((a) => (
        <div className={styles.SavedQuestionMainBox}>
          <div className={styles.SavedQuestionHeading}>
            <Skeleton />
          </div>
          <div>
            <Skeleton />
          </div>
          <div className={styles.QuestionText}>
            <Skeleton />
          </div>
        </div>
      ))}
    </div>
  );
};

function GetDate(date) {
  var d = new Date(date);
  return d.toLocaleTimeString() + " " + d.toLocaleDateString();
}

const FilterToolBar = (props) => {
  const { onSubjectChange } = props;

  const [selectedSubject, SetSelectedSubject] = useState("All");

  useEffect(() => {
    onSubjectChange(selectedSubject);
  }, [selectedSubject]);

  return (
    <div>
      <div class={`${styles.TeamBtnContainer}`}>
        <button
          type="button"
          class={selectedSubject === "All" ? `${styles.TeamRedActive}` : null}
          formTarget="All"
          onClick={() => {
            SetSelectedSubject("All");
          }}
        >
          All
        </button>
        <button
          type="button"
          class={
            selectedSubject === "English" ? `${styles.TeamRedActive}` : null
          }
          formTarget="English"
          onClick={() => {
            SetSelectedSubject("English");
          }}
        >
          English
        </button>
        <button
          type="button"
          class={
            selectedSubject === "Biology" ? `${styles.TeamRedActive}` : null
          }
          formTarget="Biology"
          onClick={() => {
            SetSelectedSubject("Biology");
          }}
        >
          Biology
        </button>
        <button
          type="button"
          class={
            selectedSubject === "Chemistry" ? `${styles.TeamRedActive}` : null
          }
          formTarget="Chemistry"
          onClick={() => {
            SetSelectedSubject("Chemistry");
          }}
        >
          Chemistry
        </button>
        <button
          type="button"
          class={
            selectedSubject === "Physics" ? `${styles.TeamRedActive}` : null
          }
          formTarget="Physics"
          onClick={() => {
            SetSelectedSubject("Physics");
          }}
        >
          Physics
        </button>
      </div>
    </div>
  );
};

const RenderSavedQuestions = (props) => {
  let history = useHistory();

  const GetQuestionNumber = async (deckName, qid, type) => {
    var ids = [];
    if (type === "Topical") {
      let [promiseyearly, promisetopical] = await Promise.all([
        GetTopicalPastPaperQuestions(deckName),
        GetTopicalQuestions(deckName),
      ]);

      var topicalQuestionIDs = [];
      var yearlyQuestionIDs = [];
      const data = promiseyearly.data;
      console.log(data);
      data.forEach((element) => {
        yearlyQuestionIDs.push(element.Questions._id);
      });
      const data1 = promisetopical.data;
      data1.forEach((element) => {
        topicalQuestionIDs.push(element.Questions._id);
      });

      if (topicalQuestionIDs.indexOf(qid) !== -1) {
        return {
          type: "PracticeTopical",
          no: topicalQuestionIDs.indexOf(qid) + 1,
        };
      } else {
        return {
          type: "PastpaperTopical",
          no: yearlyQuestionIDs.indexOf(qid) + 1,
        };
      }
    } else {
      const promiseyearly = await GetQuestions(deckName);
      var questionsIDs = [];
      const data = promiseyearly.data;
      data.forEach((element) => {
        questionsIDs.push(element.Questions._id);
      });
      return { type: "Yearly", no: questionsIDs.indexOf(qid) + 1 };
    }
  };

  const { rawData, onDelete, fullData } = props;
  if (rawData.length < 1 && fullData.length > 0) {
    return (
      <div className={styles.NoBookmarks}>
        No saved questions were found for this subject/topic
        <br />
      </div>
    );
  }
  if (rawData.length < 1 && fullData.length < 1) {
    return (
      <div className={styles.NoBookmarks}>
        You have no saved questions
        <br />
      </div>
    );
  }
  return (
    <div>
      {rawData.map((a) => (
        <div className={styles.SavedQuestionMainBox}>
          <div className={styles.SavedQuestionHeading}>
            {a.DeckName} {GetBadge(a.Type)}
          </div>
          <div>Date Saved: {GetDate(a.createdAt)}</div>
          <div className={styles.QuestionText}>
            {parse(a.QuestionText.replace("<p>", "").replace("</p>", ""))}
          </div>
          <div className={styles.Tags}>
            {a.Tags.map((tag) => (
              <Chip
                style={hiddenTags.includes(tag) ? { display: "none" } : {}}
                size="medium"
                label={tag}
                variant="outlined"
              />
            ))}
          </div>
          <div className={styles.DeleteButton}>
            <GradientBtn
              style={{
                fontSize: "0.9em",
                borderRadius: "30px",
                margin: 0,
                padding: "0.6em 2em",
                marginRight: "0.5em",
              }}
              gradient={"#639AF6 9.34%, #98BDF9 91.82%"}
              onClick={async () => {
                let promise = await GetQuestionNumber(
                  a.DeckName,
                  a.QuestionID,
                  a.Type
                );
                const { no, type } = promise;
                if (type === "Yearly") {
                  history.push(
                    `/deck/attempt?deck=${a.DeckName}&question=${no}`
                  );
                } else if (type === "PracticeTopical") {
                  history.push(
                    `/deck/topical/attempt?deck=${a.DeckName}&question=${no}`
                  );
                } else {
                  history.push(
                    `/deck/topical/pastpaper/attempt?deck=${a.DeckName}&question=${no}`
                  );
                }
              }}
            >
              Attempt
            </GradientBtn>
            {"  "}
            <GradientBtn
              style={{
                fontSize: "0.9em",
                borderRadius: "30px",
                margin: 0,
                padding: "0.6em 2em",
                marginRight: "0.5em",
              }}
              gradient={"#EC5863 9.34%, #F39EA5 91.82%"}
              onClick={() => {
                DeleteBookmark(a.DeckName, a.QuestionID).then(() => {
                  onDelete();
                });
              }}
              theme="danger"
            >
              Remove
            </GradientBtn>
          </div>
        </div>
      ))}
    </div>
  );
};

function GetBadge(type) {
  if (type === "Topical") {
    return <Badge theme="info">Topical</Badge>;
  } else {
    return <Badge theme="success">Yearly</Badge>;
  }
}
