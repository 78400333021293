import React, { useState, useEffect } from "react";
import styles from "./QuestionComponent.module.css";
import Chip from "@material-ui/core/Chip";
import TagsIcon from "@material-ui/icons/LocalOffer";

const hiddenTags = [
  "bpb",
  "bkp",
  "cvc",
  "pvp",
  "bcf",
  "bcq",
  "bpq",
  "dcq",
  "cpq",
  "dpq",
  "fixed",
  "Repeated"
];

const TagList = ({ styleClass, tags, isHidden }) => {
  return (
    <div className={styles.Tags} style={{ marginBottom: "0em" }}>
      {tags.length > 0 && !isHidden ? <TagsIcon  style = {{marginTop: "5px", marginRight:"0.4em"}}/> : <div />}
      <div>
        {tags.map((tag) => (
          <Chip
            style={hiddenTags.includes(tag) ? { display: "none" } : { fontFamily : "Rubik"}}
            size="medium"
            label={tag}
            key={tag}
            color="secondary"
            variant="outlined"
          />
        ))}
      </div>
    </div>
  );
};
export default TagList;
export { hiddenTags };
