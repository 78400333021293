import React, { useState } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import App from "./App";
import { UserProvider } from "./userContext";
import { NotificationsProvider } from "./notificationContext";

ReactDOM.render(
  <UserProvider>
    <NotificationsProvider>
      <Router onContextMenu={(e) => e.preventDefault()}>
        <Route path="/">
          <App />
        </Route>
      </Router>
    </NotificationsProvider>
  </UserProvider>,
  document.getElementById("root")
);
