import { ContactsOutlined } from "@material-ui/icons";
import React, { useEffect, useState, useContext } from "react";
import { BrowserRouter as Router, Route, Switch, Link } from "react-router-dom";
import styles from "./styles.module.css";
import { Collapse, Button, Badge } from "shards-react";

import NUMSLogo from "../../StaticImages/NUMS Logo.png";
import NTSLogo from "../../StaticImages/NTS Logo.png";
import UHSLogo from "../../StaticImages/UHS Logo.jpg";

import BioLogo from "../../StaticImages/Topical Icons/BIOLOGY1.svg";
import ChemLogo from "../../StaticImages/Topical Icons/CHEMSITRY1.svg";
import PhysLogo from "../../StaticImages/Topical Icons/PHYSICS1.svg";
import EngLogo from "../../StaticImages/Topical Icons/ENGLISH1.svg";
import LrLogo from "../../StaticImages/Topical Icons/LOGICAL REASONING1.svg";

import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";

import TopicalPageIcon from "../../StaticImages/TopicalPageIcon.svg";

import { Progress } from "shards-react";

//Bio Icon Imports
import Bioenergetics from "../../StaticImages/Topical Icons/Biology/bioenergetic1.svg";
import BiologicalMolecules from "../../StaticImages/Topical Icons/Biology/biological molecules1.svg";
import BiologyanditsMajorFieldsofSpecialization from "../../StaticImages/Topical Icons/Biology/biology and its major field of specialization1.svg";
import Biotechnology from "../../StaticImages/Topical Icons/Biology/Biotechnology1.svg";
import CellCycle from "../../StaticImages/Topical Icons/Biology/cell cycle1.svg";
import CellStructureandFunction from "../../StaticImages/Topical Icons/Biology/Cell Structure and function1.svg";
import ChromosomesandDNA from "../../StaticImages/Topical Icons/Biology/chromosomes and DNA1.svg";
import CoordinationandControl from "../../StaticImages/Topical Icons/Biology/coordination and control2.svg";
import Enzymes from "../../StaticImages/Topical Icons/Biology/enzyme1.svg";
import Evolution from "../../StaticImages/Topical Icons/Biology/evolution1.svg";
import GaseousExchange from "../../StaticImages/Topical Icons/Biology/gaseous exchange1.svg";
import GrowthandDevelopment from "../../StaticImages/Topical Icons/Biology/growth and development1.svg";
import Homeostasis from "../../StaticImages/Topical Icons/Biology/homeostasis1.svg";
import KingdomAnimalia from "../../StaticImages/Topical Icons/Biology/kingdom animalia1.svg";
import KingdomFungi from "../../StaticImages/Topical Icons/Biology/kingdom fungi1.svg";
import KingdomPlantae from "../../StaticImages/Topical Icons/Biology/kingdom plantae1.svg";
import KingdomProkaryote from "../../StaticImages/Topical Icons/Biology/kingdom prokaryote2.svg";
import KingdomProtista from "../../StaticImages/Topical Icons/Biology/kingdom prokaryote1.svg";
import ManandHisEnvironment from "../../StaticImages/Topical Icons/Biology/Man and His Environment1.svg";
import Nutrition from "../../StaticImages/Topical Icons/Biology/Nutrition1.svg";
import Reproduction from "../../StaticImages/Topical Icons/Biology/reproduction1.svg";
import SupportandMovement from "../../StaticImages/Topical Icons/Biology/support and movement2.svg";
import Transport from "../../StaticImages/Topical Icons/Biology/transport2.svg";
import VariationandGenetics from "../../StaticImages/Topical Icons/Biology/Variation and genetics1.svg";
import VarietyofLife from "../../StaticImages/Topical Icons/Biology/variety of life1.svg";

//Chem Icon Imports
import AcidsBasesandSalts from "../../StaticImages/Topical Icons/Chemistry/acids, bases and salts1.svg";
import AlcoholsPhenolsandEthers from "../../StaticImages/Topical Icons/Chemistry/alcohol1.svg";
import AldehydesandKetones from "../../StaticImages/Topical Icons/Chemistry/aldehydes and ketones1.svg";
import AlkylHalidesandAmines from "../../StaticImages/Topical Icons/Chemistry/alkyl halides and amines1.svg";
import AnalyticalChemistry from "../../StaticImages/Topical Icons/Chemistry/analytical chemistry3.svg";
import AtomicStructure from "../../StaticImages/Topical Icons/Chemistry/atomic structure1.svg";
import CarboxylicAcidsanditsDerivatives from "../../StaticImages/Topical Icons/Chemistry/acids, bases and salts2.svg";
import ChemicalBonding from "../../StaticImages/Topical Icons/Chemistry/chemical bonding1.svg";
import ChemicalEquilibrium from "../../StaticImages/Topical Icons/Chemistry/chemical equilibrium3.svg";
import ChemistryofLife from "../../StaticImages/Topical Icons/Chemistry/chemistry of life1.svg";
import Electrochemistry from "../../StaticImages/Topical Icons/Chemistry/electrochemistry1.svg";
import EnvironmentalChemistry from "../../StaticImages/Topical Icons/Chemistry/environmental chemistry3.svg";
import Hydrocarbons from "../../StaticImages/Topical Icons/Chemistry/hydrocarbon1.svg";
import IndustrialChemistry from "../../StaticImages/Topical Icons/Chemistry/industrial chemistry2.svg";
import PeriodicityinElements from "../../StaticImages/Topical Icons/Chemistry/periodicity2.svg";
import ReactionKinetics from "../../StaticImages/Topical Icons/Chemistry/reaction kinetics2.svg";
import SolutionandColloids from "../../StaticImages/Topical Icons/Chemistry/solution and colloids1.svg";
import StatesofMatter from "../../StaticImages/Topical Icons/Chemistry/states of matter1.svg";
import Stoichiometry from "../../StaticImages/Topical Icons/Chemistry/stoichiometry1.svg";
import Thermochemistry from "../../StaticImages/Topical Icons/Chemistry/thermochemistry1.svg";
import dandfBlockElements from "../../StaticImages/Topical Icons/Chemistry/d and f block elements1.svg";
import sandpBlockElements from "../../StaticImages/Topical Icons/Chemistry/s and p block1.svg";
import macromolecules from "../../StaticImages/Topical Icons/Chemistry/macromolecules1.png";
import fundementalorganicchemistry from "../../StaticImages/Topical Icons/Chemistry/fundamentalorganicchemistry1.png";
import fundementalchemistry from "../../StaticImages/Topical Icons/Chemistry/fundamentalchemistry1.png";
//Phys Icon Imports
import AlternatingCurrent from "../../StaticImages/Topical Icons/Physics/alternating current2.svg";
import AtomicSpectra from "../../StaticImages/Topical Icons/Physics/atomic spectra1.svg";
import CircularMotionandMomentum from "../../StaticImages/Topical Icons/Physics/circular motion and momentum2.svg";
import CurrentElectricity from "../../StaticImages/Topical Icons/Physics/current electricity1.svg";
import DawnofModernPhysics from "../../StaticImages/Topical Icons/Physics/dawn of modern physics1.svg";
import ElectromagneticWavesandElectronics from "../../StaticImages/Topical Icons/Physics/electromagneticwavesandelectronics11.svg";
import Electrostatics from "../../StaticImages/Topical Icons/Physics/electrostatics1.svg";
import FluidDynamics from "../../StaticImages/Topical Icons/Physics/fluiddynamics3.svg";
import ForcesandMotion from "../../StaticImages/Topical Icons/Physics/forcesandmotion1.svg";
import Gravitation from "../../StaticImages/Topical Icons/Physics/gravitation2.svg";
import HeatandThermodynamics from "../../StaticImages/Topical Icons/Physics/thermodynamics2.svg";
import MagnetismandElectromagneticInduction from "../../StaticImages/Topical Icons/Physics/magnetism and electromagnetic induction1.svg";
import Measurements from "../../StaticImages/Topical Icons/Physics/measurement1.svg";
import MotioninTwoDimensions from "../../StaticImages/Topical Icons/Physics/motion in two dimension1.svg";
import NuclearPhysics from "../../StaticImages/Topical Icons/Physics/nuclear1.svg";
import OpticsNatureofLightandOpticalInstruments from "../../StaticImages/Topical Icons/Physics/optics nature of light and optical instruments.svg";
import OscillationsandSimpleHarmonicMotion from "../../StaticImages/Topical Icons/Physics/oscillations and simple harmonic motion2.svg";
import PhysicsofSolids from "../../StaticImages/Topical Icons/Physics/physics of solids1.svg";
import ScalarsandVectors from "../../StaticImages/Topical Icons/Physics/scalars and vectors1.svg";
import WaveMotionandSound from "../../StaticImages/Topical Icons/Physics/wave motion and sound1.svg";
import WorkPowerandEnergy from "../../StaticImages/Topical Icons/Physics/work power and energy1.svg";
import electromagneticwaves from "../../StaticImages/Topical Icons/Physics/electromagneticwaves1.png";
import electronics from "../../StaticImages/Topical Icons/Physics/electronics1.png";

//Eng Icon Imports
import FillInTheBlanks from "../../StaticImages/Topical Icons/English/Fill in the Blanks.svg";
import GrammarAndPunctuation from "../../StaticImages/Topical Icons/English/Grammar and Punctuation.svg";
import IdentifyErrorsInSentences from "../../StaticImages/Topical Icons/English/Identify errors in sentences.svg";
import TensesAndSentenceStructure from "../../StaticImages/Topical Icons/English/Tenses and Sentence Structure.svg";
import Vocabulary from "../../StaticImages/Topical Icons/English/Vocabulary.svg";
import Passage from "../../StaticImages/Topical Icons/English/Passage.svg";

//Lr Icon Imports
import CauseAndEffect from "../../StaticImages/Topical Icons/Logical Reasoning/Cause and Effect.svg";
import CourseOfAction from "../../StaticImages/Topical Icons/Logical Reasoning/Course of Action.svg";
import CriticalThinking from "../../StaticImages/Topical Icons/Logical Reasoning/Critical Thinking.svg";
import LettersAndSymbolSeries from "../../StaticImages/Topical Icons/Logical Reasoning/Letters and Symbols Series.svg";
import LogicalDeduction from "../../StaticImages/Topical Icons/Logical Reasoning/Logical Deduction.svg";
import LogicalProblems from "../../StaticImages/Topical Icons/Logical Reasoning/Logical Problems.svg";

import StatsDropdownIcon from "../../StaticImages/topicalstatsdropdown.png";

import {
  GetYearlyDecks,
  GetTopicalDecks,
  GetTopicalStats,
  GetRecentActivities,
} from "../../APIManager";
import { UserContext } from "../../userContext";

const TopicalPage = () => {
  const bioTopics = [
    "Biology and its Major Fields of Specialization",
    "Biological Molecules",
    "Enzymes",
    "Cell Structure and Function",
    "Bio-diversity | Variety of Life",
    "Variety of Life",
    "Kingdom Prokaryote",
    "Kingdom Protista",
    "Kingdom Fungi",
    "Kingdom Plantae",
    "Kingdom Animalia",
    "Bioenergetics",
    "Nutrition",
    "Gaseous Exchange",
    "Transport",
    "Homeostasis",
    "Support and Movement",
    "Coordination and Control",
    "Reproduction",
    "Growth and Development",
    "Chromosomes and DNA",
    "Cell Cycle",
    "Variation and Genetics",
    "Biotechnology",
    "Evolution",
    "Man and His Environment",
  ];
  const chemTopics = [
    "Introduction to Fundamental Concepts of Chemistry",
    "Experimental Techniques in Chemistry",
    "Stoichiometry",
    "States of Matter - Gases, Liquids and Solids",
    "Atomic Structure",
    "Chemical Bonding",
    "Thermochemistry",
    "Chemical Equilibrium",
    "Solution and Colloids",
    "Electrochemistry",
    "Acids, Bases and Salts",
    "Reaction Kinetics",
    "Periodicity in Elements",
    "s and p Block Elements",
    "d and f Block Elements",
    "Fundamental Principles of Organic Chemistry",
    "Hydrocarbons",
    "Alkyl Halides and Amines",
    "Alcohols, Phenols and Ethers",
    "Aldehydes and Ketones",
    "Carboxylic Acids and its Derivatives",
    "Chemistry of Life",
    "Industrial Chemistry",
    "Analytical Chemistry",
    "Environmental Chemistry",
    "Macromolecules",
  ];
  const physTopics = [
    "Measurements",
    "Scalars and Vectors",
    "Forces and Motion",
    "Motion in Two Dimensions",
    "Work, Power and Energy",
    "Circular Motion and Momentum",
    "Gravitation",
    "Fluid Dynamics",
    "Oscillations and Simple Harmonic Motion",
    "Wave Motion and Sound",
    "Optics, Nature of Light and Optical Instruments",
    "Heat and Thermodynamics",
    "Electrostatics",
    "Current Electricity",
    "Magnetism and Electromagnetic Induction",
    "Electromagnetic Waves",
    "Alternating Current",
    "Electromagnetic Waves and Electronics",
    "Physics of Solids",
    "Electronics",
    "Dawn of Modern Physics",
    "Atomic Spectra",
    "Nuclear Physics",
  ];
  const engTopics = [
    "Key Vocabulary",
    "Tenses and Sentence Structure",
    "Grammar and Punctuation",
    "Fill in the blank",
    "Identify errors in sentence",
    "Passage",
  ];
  const lrTopics = [
    "Critical Thinking",
    "Letters and Symbols Series",
    "Logical Deduction",
    "Logical Problems",
    "Course of Action",
    "Cause and Effect",
  ];

  let [userContext, SetUserContext] = useContext(UserContext);

  const [bioCollapse, SetBioCollapse] = useState();
  const [chemCollapse, SetChemCollapse] = useState();
  const [physCollapse, SetPhysCollapse] = useState();
  const [engCollapse, SetEngCollapse] = useState();
  const [lrCollapse, SetLrCollapse] = useState();

  const [bioDecks, SetBioDecks] = useState(bioTopics);
  const [chemDecks, SetChemDecks] = useState(chemTopics);
  const [physDecks, SetPhysDecks] = useState(physTopics);
  const [engDecks, SetEngDecks] = useState(engTopics);
  const [lrDecks, SetLrDecks] = useState(lrTopics);

  const [topicalStats, SetTopicalStats] = useState(null);
  const [activity, SetActivity] = useState(null);

  useEffect(() => {
    GetTopicalDecks().then((res) => {
      var bio = [];
      var chem = [];
      var phys = [];
      var eng = [];
      var lr = [];
      res.data.forEach((deck) => {
        if (deck.Group === "Biology") {
          bio.push(deck.Name);
        } else if (deck.Group === "Chemistry") {
          chem.push(deck.Name);
        } else if (deck.Group === "Physics") {
          phys.push(deck.Name);
        } else if (deck.Group === "English") {
          eng.push(deck.Name);
        } else if (deck.Group === "Logical Reasoning") {
          lr.push(deck.Name);
        }
      });
      bio.sort((a, b) => {
        return bioTopics.indexOf(a) - bioTopics.indexOf(b);
      });
      chem.sort((a, b) => {
        return chemTopics.indexOf(a) - chemTopics.indexOf(b);
      });
      phys.sort((a, b) => {
        return physTopics.indexOf(a) - physTopics.indexOf(b);
      });
      eng.sort((a, b) => {
        return engTopics.indexOf(a) - engTopics.indexOf(b);
      });
      lr.sort((a, b) => {
        return lrTopics.indexOf(a) - lrTopics.indexOf(b);
      });
      SetBioDecks(bio);
      SetChemDecks(chem);
      SetPhysDecks(phys);
      SetEngDecks(eng);
      SetLrDecks(lr);
    });
  }, []);

  useEffect(() => {
    GetTopicalStats().then((res) => {
      console.log(res.data);
      SetTopicalStats(res.data);
    });

    GetRecentActivities().then((res) => {
      console.log(res.data);
      SetActivity(res.data);
    });
  }, []);

  function GetImgSrc(deck) {
    switch (deck) {
      case "Bioenergetics":
        return Bioenergetics;
        break;
      case "Biological Molecules":
        return BiologicalMolecules;
        break;
      case "Biology and its Major Fields of Specialization":
        return BiologyanditsMajorFieldsofSpecialization;
        break;
      case "Biology and its Major Fields of Specialization":
        return BiologyanditsMajorFieldsofSpecialization;
        break;
      case "Biotechnology":
        return Biotechnology;
        break;
      case "Cell Cycle":
        return CellCycle;
        break;
      case "Cell Structure and Function":
        return CellStructureandFunction;
        break;
      case "Chromosomes and DNA":
        return ChromosomesandDNA;
        break;
      case "Coordination and Control":
        return CoordinationandControl;
        break;
      case "Enzymes":
        return Enzymes;
        break;
      case "Evolution":
        return Evolution;
        break;
      case "Gaseous Exchange":
        return GaseousExchange;
        break;
      case "Growth and Development":
        return GrowthandDevelopment;
        break;
      case "Homeostasis":
        return Homeostasis;
        break;
      case "Kingdom Animalia":
        return KingdomAnimalia;
        break;
      case "Kingdom Fungi":
        return KingdomFungi;
        break;
      case "Kingdom Plantae":
        return KingdomPlantae;
        break;
      case "Kingdom Prokaryote":
        return KingdomProkaryote;
        break;

      case "Kingdom Protista":
        return KingdomProtista;
        break;

      case "Man and His Environment":
        return ManandHisEnvironment;
        break;

      case "Nutrition":
        return Nutrition;
        break;

      case "Reproduction":
        return Reproduction;
        break;

      case "Support and Movement":
        return SupportandMovement;
        break;

      case "Transport":
        return Transport;
        break;

      case "Variation and Genetics":
        return VariationandGenetics;
        break;

      case "Variety of Life":
        return VarietyofLife;
        break;
      case "Bio-diversity | Variety of Life":
        return VarietyofLife;

      case "Acids, Bases and Salts":
        return AcidsBasesandSalts;
        break;
      case "Alcohols, Phenols and Ethers":
        return AlcoholsPhenolsandEthers;
        break;
      case "Aldehydes and Ketones":
        return AldehydesandKetones;
        break;
      case "Alkyl Halides and Amines":
        return AlkylHalidesandAmines;
        break;
      case "Analytical Chemistry":
        return AnalyticalChemistry;
        break;
      case "Atomic Structure":
        return AtomicStructure;
        break;
      case "Carboxylic Acids and its Derivatives":
        return CarboxylicAcidsanditsDerivatives;
        break;
      case "Chemical Bonding":
        return ChemicalBonding;
        break;
      case "Chemical Equilibrium":
        return ChemicalEquilibrium;
        break;
      case "Chemistry of Life":
        return ChemistryofLife;
        break;
      case "Electrochemistry":
        return Electrochemistry;
        break;
      case "Environmental Chemistry":
        return EnvironmentalChemistry;
        break;
      case "Hydrocarbons":
        return Hydrocarbons;
        break;
      case "Industrial Chemistry":
        return IndustrialChemistry;
        break;
      case "Periodicity in Elements":
        return PeriodicityinElements;
        break;
      case "Reaction Kinetics":
        return ReactionKinetics;
        break;
      case "Solution and Colloids":
        return SolutionandColloids;
        break;
      case "States of Matter":
        return StatesofMatter;
        break;
      case "States of Matter - Gases, Liquids and Solids":
        return StatesofMatter;
      case "Stoichiometry":
        return Stoichiometry;
        break;
      case "Thermochemistry":
        return Thermochemistry;
        break;
      case "d and f Block Elements":
        return dandfBlockElements;
        break;
      case "s and p Block Elements":
        return sandpBlockElements;
        break;
      case "Macromolecules":
        return macromolecules;
      case "Fundamental Principles of Organic Chemistry":
        return fundementalorganicchemistry;
      case "Introduction to Fundamental Concepts of Chemistry":
        return fundementalchemistry;
      case "Experimental Techniques in Chemistry":

      case "Alternating Current":
        return AlternatingCurrent;
        break;
      case "Atomic Spectra":
        return AtomicSpectra;
        break;
      case "Circular Motion and Momentum":
        return CircularMotionandMomentum;
        break;
      case "Current Electricity":
        return CurrentElectricity;
        break;
      case "Dawn of Modern Physics":
        return DawnofModernPhysics;
        break;
      case "Electromagnetic Waves and Electronics":
        return ElectromagneticWavesandElectronics;
        break;
      case "Electrostatics":
        return Electrostatics;
        break;
      case "Fluid Dynamics":
        return FluidDynamics;
        break;
      case "Forces and Motion":
        return ForcesandMotion;
        break;
      case "Gravitation":
        return Gravitation;
        break;
      case "Heat and Thermodynamics":
        return HeatandThermodynamics;
        break;
      case "Magnetism and Electromagnetic Induction":
        return MagnetismandElectromagneticInduction;
        break;
      case "Measurements":
        return Measurements;
        break;
      case "Motion in Two Dimensions":
        return MotioninTwoDimensions;
        break;
      case "Nuclear Physics":
        return NuclearPhysics;
        break;
      case "Optics, Nature of Light and Optical Instruments":
        return OpticsNatureofLightandOpticalInstruments;
        break;
      case "Oscillations and Simple Harmonic Motion":
        return OscillationsandSimpleHarmonicMotion;
        break;
      case "Physics of Solids":
        return PhysicsofSolids;
        break;
      case "Scalars and Vectors":
        return ScalarsandVectors;
        break;
      case "Wave Motion and Sound":
        return WaveMotionandSound;
        break;
      case "Work, Power and Energy":
        return WorkPowerandEnergy;
        break;
      case "Electromagnetic Waves":
        return electromagneticwaves;
      case "Electronics":
        return electronics;

      case "Fill in the blank":
        return FillInTheBlanks;
      case "Key Vocabulary":
        return Vocabulary;
      case "Identify errors in sentence":
        return IdentifyErrorsInSentences;
      case "Tenses and Sentence Structure":
        return TensesAndSentenceStructure;
      case "Grammar and Punctuation":
        return GrammarAndPunctuation;
      case "Passage":
        return Passage;

      case "Cause and Effect":
        return CauseAndEffect;
      case "Critical Thinking":
        return CriticalThinking;
      case "Letters and Symbols Series":
        return LettersAndSymbolSeries;
      case "Logical Deduction":
        return LogicalDeduction;
      case "Logical Problems":
        return LogicalProblems;
      case "Course of Action":
        return CourseOfAction;
      default:
        return NTSLogo;
    }
  }

  function GetBadge(deck) {
    const badge = (
      <span style={{ marginTop: "-0.5em" }}>
        <Badge style={{ fontSize: ".5em" }} theme="success">
          High Yield
        </Badge>
      </span>
    );
    switch (deck) {
      case "Variety of Life":
        return badge;
      case "Bioenergetics":
        return badge;
      case "Biological Molecules":
        return badge;
      case "Cell Structure and Function":
        return badge;
      case "Coordination and Control":
        return badge;
      case "Kingdom Animalia":
        return badge;
      case "Enzymes":
        return badge;
      case "Evolution":
        return badge;
      case "Gaseous Exchange":
        return badge;
      case "Nutrition":
        return badge;
      case "Transport":
        return badge;
      case "Kingdom Prokaryote":
        return badge;
      case "Reproduction":
        return badge;
      case "Support and Movement":
        return badge;
      case "Variation and Genetics":
        return badge;
      case "Introduction to Fundamental Concepts of Chemistry":
        return badge;
      case "Atomic Structure":
        return badge;
      case "States of Matter - Gases, Liquids and Solids":
        return badge;
      case "Chemical Equilibrium":
        return badge;
      case "Reaction Kinetics":
        return badge;
      case "Thermochemistry":
        return badge;
      case "Electrochemistry":
        return badge;
      case "Chemical Bonding":
        return badge;
      case "s and p Block Elements":
        return badge;
      case "d and f Block Elements":
        return badge;
      case "Fundamental Principles of Organic Chemistry":
        return badge;
      case "Hydrocarbons":
        return badge;
      case "Alkyl Halides and Amines":
        return badge;
      case "Alcohols, Phenols and Ethers":
        return badge;
      case "Aldehydes and Ketones":
        return badge;
      case "Carboxylic Acids and its Derivatives":
        return badge;
      case "Macromolecules":
        return badge;
      case "Forces and Motion":
        return badge;
      case "Work, Power and Energy":
        return badge;
      case "Circular Motion and Momentum":
        return badge;
      case "Wave Motion and Sound":
        return badge;
      case "Heat and Thermodynamics":
        return badge;
      case "Electrostatics":
        return badge;
      case "Current Electricity":
        return badge;
      case "Magnetism and Electromagnetic Induction":
        return badge;
      case "Electromagnetic Waves":
        return badge;
      case "Electronics":
        return badge;
      case "Dawn of Modern Physics":
        return badge;
      case "Atomic Spectra":
        return badge;
      case "Nuclear Physics":
        return badge;
      case "Key Vocabulary":
        return badge;
      case "Tenses and Sentence Structure":
        return badge;
      case "Grammar and Punctuation":
        return badge;
      case "Fill in the blank":
        return badge;
      case "Identify errors in sentence":
        return badge;
      case "Passage":
        return badge;

      case "Critical Thinking":
        return badge;
      case "Letters and Symbols Series":
        return badge;
      case "Logical Deduction":
        return badge;
      case "Logical Problems":
        return badge;
      case "Course of Action":
        return badge;
      case "Cause and Effect":
        return badge;
      default:
        return null;
    }
  }

  return (
    <div className={styles.GradientDiv}>
      <div className={styles.MainDiv}>
        <div className={styles.ContentDiv}>
          <div className={styles.PageHeader}>
            <h1>
              <span>
                <img src={TopicalPageIcon} />
              </span>
              <span> Topical MCQs</span>
            </h1>
            <h4>
              Welcome
              {userContext.fullname
                ? ` ${userContext.fullname.split(" ").slice(0, 2).join(" ")}! `
                : "! "}
              Practising questions after reading a chapter is the best way to
              learn and remember all the concepts. Select any chapter and start
              solving MCQs!
            </h4>
          </div>
          <div className={styles.GroupsContainer}>
            <div className={styles.Group}>
              <div
                className={styles.GroupHeader}
                onClick={() => {
                  SetEngCollapse(!engCollapse);
                }}
              >
                <img src={EngLogo} className={styles.LogoImage} />
                <span className={styles.GroupName}>
                  English {engDecks ? `(${engDecks.length}  Topics)` : ""}{" "}
                </span>
                <div className={styles.ArrowContainer}>
                  <div className={styles.ArrowContainer}>
                    <span
                      className={`${styles.ArrowIcon} ${
                        engCollapse ? styles.ArrowUp : styles.ArrowIcon
                      }`}
                      style={{ width: "fitContent" }}
                    >
                      <ArrowDropDownIcon
                        style={{ fontSize: "6em" }}
                        onClick={() => {
                          SetEngCollapse(!engCollapse);
                        }}
                      />
                    </span>
                  </div>
                </div>
              </div>
              <div className={styles.PaperList} style={{ width: "100%" }}>
                <Collapse open={engCollapse} style={{ width: "100%" }}>
                  <div className={styles.PapersContainer}>
                    {engDecks.map((deck) => (
                      <TopicalRow
                        key={deck}
                        key={deck}
                        deck={deck}
                        GetImgSrc={GetImgSrc}
                        GetBadge={GetBadge}
                        topicalStats={topicalStats}
                        activity={activity}
                      />
                    ))}
                  </div>
                </Collapse>
              </div>
            </div>
            <div className={styles.Group}>
              <div
                className={styles.GroupHeader}
                onClick={() => {
                  SetBioCollapse(!bioCollapse);
                }}
              >
                <img src={BioLogo} className={styles.LogoImage} />
                <span className={styles.GroupName}>
                  Biology {bioDecks ? `(${bioDecks.length}  Topics)` : ""}{" "}
                </span>
                <div className={styles.ArrowContainer}>
                  <span
                    className={`${styles.ArrowIcon} ${
                      bioCollapse ? styles.ArrowUp : styles.ArrowIcon
                    }`}
                    style={{ width: "fitContent" }}
                  >
                    <ArrowDropDownIcon
                      style={{ fontSize: "6em" }}
                      onClick={() => {
                        SetBioCollapse(!bioCollapse);
                      }}
                    />
                  </span>
                </div>
              </div>
              <div className={styles.PaperList}>
                <Collapse open={bioCollapse}>
                  <div className={styles.PapersContainer}>
                    {bioDecks.map((deck) => (
                      <TopicalRow
                        key={deck}
                        deck={deck}
                        GetImgSrc={GetImgSrc}
                        GetBadge={GetBadge}
                        topicalStats={topicalStats}
                        activity={activity}
                      />
                    ))}
                  </div>
                </Collapse>
              </div>
            </div>
            <div className={styles.Group}>
              <div
                className={styles.GroupHeader}
                onClick={() => {
                  SetChemCollapse(!chemCollapse);
                }}
              >
                <img src={ChemLogo} className={styles.LogoImage} />
                <span className={styles.GroupName}>
                  Chemistry {chemDecks ? `(${chemDecks.length}  Topics)` : ""}{" "}
                </span>
                <div className={styles.ArrowContainer}>
                  <span
                    className={`${styles.ArrowIcon} ${
                      chemCollapse ? styles.ArrowUp : styles.ArrowIcon
                    }`}
                    style={{ width: "fitContent" }}
                  >
                    <ArrowDropDownIcon
                      style={{ fontSize: "6em" }}
                      onClick={() => {
                        SetChemCollapse(!chemCollapse);
                      }}
                    />
                  </span>
                </div>
              </div>
              <div className={styles.PapersList}>
                <Collapse open={chemCollapse}>
                  <div className={styles.PapersContainer}>
                    {chemDecks.map((deck) => (
                      <TopicalRow
                        key={deck}
                        deck={deck}
                        GetImgSrc={GetImgSrc}
                        GetBadge={GetBadge}
                        topicalStats={topicalStats}
                        activity={activity}
                      />
                    ))}
                  </div>
                </Collapse>
              </div>
            </div>
            <div className={styles.Group}>
              <div
                className={styles.GroupHeader}
                onClick={() => {
                  SetPhysCollapse(!physCollapse);
                }}
              >
                <img src={PhysLogo} className={styles.LogoImage} />
                <span className={styles.GroupName}>
                  Physics {physDecks ? `(${physDecks.length}  Topics)` : ""}{" "}
                </span>
                <div className={styles.ArrowContainer}>
                  <div className={styles.ArrowContainer}>
                    <span
                      className={`${styles.ArrowIcon} ${
                        physCollapse ? styles.ArrowUp : styles.ArrowIcon
                      }`}
                      style={{ width: "fitContent" }}
                    >
                      <ArrowDropDownIcon
                        style={{ fontSize: "6em" }}
                        onClick={() => {
                          SetPhysCollapse(!physCollapse);
                        }}
                      />
                    </span>
                  </div>
                </div>
              </div>
              <div className={styles.PaperList}>
                <Collapse open={physCollapse} style={{ width: "100%" }}>
                  <div className={styles.PapersContainer}>
                    {physDecks.map((deck) => (
                      <TopicalRow
                        key={deck}
                        deck={deck}
                        GetImgSrc={GetImgSrc}
                        GetBadge={GetBadge}
                        topicalStats={topicalStats}
                        activity={activity}
                      />
                    ))}
                  </div>
                </Collapse>
              </div>
            </div>
            <div className={styles.Group}>
              <div
                className={styles.GroupHeader}
                onClick={() => {
                  SetLrCollapse(!lrCollapse);
                }}
              >
                <img src={LrLogo} className={styles.LogoImage} />
                <span className={styles.GroupName}>
                  Logical Reasoning{" "}
                  {lrDecks ? `(${lrDecks.length}  Topics)` : ""}{" "}
                </span>
                <div className={styles.ArrowContainer}>
                  <div className={styles.ArrowContainer}>
                    <span
                      className={`${styles.ArrowIcon} ${
                        lrCollapse ? styles.ArrowUp : styles.ArrowIcon
                      }`}
                      style={{ width: "fitContent" }}
                    >
                      <ArrowDropDownIcon
                        style={{ fontSize: "6em" }}
                        onClick={() => {
                          SetLrCollapse(!lrCollapse);
                        }}
                      />
                    </span>
                  </div>
                </div>
              </div>
              <div className={styles.PaperList} style={{ width: "100%" }}>
                <Collapse open={lrCollapse} style={{ width: "100%" }}>
                  <div className={styles.PapersContainer}>
                    {lrDecks.map((deck) => (
                      <TopicalRow
                        key={deck}
                        deck={deck}
                        GetImgSrc={GetImgSrc}
                        GetBadge={GetBadge}
                        topicalStats={topicalStats}
                        activity={activity}
                      />
                    ))}
                  </div>
                </Collapse>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const TopicalRow = ({ deck, GetBadge, GetImgSrc, topicalStats, activity }) => {
  const [isOpen, SetOpen] = useState(false);
  if (topicalStats) {
    var percent =
      Math.round(
        ((topicalStats[deck]?.Correct * 100) / topicalStats[deck]?.Total) * 100
      ) / 100;
  } else {
    var percent = 0;
  }

  if (!percent) {
    percent = 0;
  }
  var activityPercent = 0;
  var deckActivity = activity?.filter((d) => d.DeckName == deck);
  if (deckActivity?.length > 0) {
    deckActivity = deckActivity[0];
    activityPercent =
      Math.round(((deckActivity.number * 100) / deckActivity.total) * 100) /
      100;
  } else {
    activityPercent = 0;
  }

  return (
    <div key={deck} className={styles.Paper}>
      <div className={styles.PaperName}>
        <img src={GetImgSrc(deck)} />
        <Link to={`/deck/topical/pastpaper/attempt?deck=${deck}`}>
          {deck} {GetBadge(deck)}
        </Link>{" "}
        <div style={{ backgroundColor: "transparent" }}>
          <img
            className={`${styles.TopicalStatsDropDown} ${
              isOpen ? styles.TopicalStatsDropDownUp : ""
            }`}
            src={StatsDropdownIcon}
            onClick={() => {
              SetOpen(!isOpen);
            }}
          />
        </div>
      </div>
      {
        <Collapse open={isOpen}>
          <div className={styles.ProgressBarDiv}>
            <div className={styles.ProgressHeading}>
              <div>Percentage Correct</div>
              <div>{percent}%</div>
            </div>
            <Progress
              className={styles.PercentageProgress}
              theme="primary"
              value={percent}
            />
          </div>
          <div className={styles.ProgressBarDiv}>
            <div className={styles.ProgressHeading}>
              <div>Percentage Complete</div>
              <div>{activityPercent}%</div>
            </div>
            <Progress
              className={styles.ProgressProgress}
              theme="primary"
              value={activityPercent}
            />
          </div>
        </Collapse>
      }
      <hr />
    </div>
  );
};

export default TopicalPage;
