import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { Alert, Badge } from "shards-react";
import "bootstrap/dist/css/bootstrap.min.css";
import "shards-ui/dist/css/shards.min.css";
import styles from "./NewTestDeckResult.module.css";
import CircularBar from "react-multicolor-circular-progress-bar";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { Progress } from "shards-react";
import {
  Card,
  CardHeader,
  CardTitle,
  CardImg,
  CardBody,
  CardFooter,
  Button,
  Fade,
  FormRadio,
} from "shards-react";

import GradientBtn from "../GradientBtn/GradientBtn";

import BioIcon from "../../StaticImages/AKUMockPage/bioIcon.svg";
import ChemIcon from "../../StaticImages/AKUMockPage/chemIcon.svg";
import PhysIcon from "../../StaticImages/AKUMockPage/physicsIcon.svg";
import EngIcon from "../../StaticImages/AKUMockPage/engicon.svg";
import SRIcon from "../../StaticImages/AKUMockPage/reasoningIcon.svg";

import Highlight1Icon from "../../StaticImages/AKUMockPage/highlighticon1.svg";
import Highlight2Icon from "../../StaticImages/AKUMockPage/highlighticon2.svg";
import Highlight3Icon from "../../StaticImages/AKUMockPage/highlighticon3.svg";

function NewTestDeckResult({
  total,
  correct,
  skipped,
  wrong,
  totalTime,
  deckName,
  testPaper,
  isMock,
  OnReviewTestClicked,
  OnReAttemptClicked,
  showNegativeMarking,
  negativeMarking,
}) {
  /*  var total = 200;
  var correct = 140;
  var skipped = 10;
  var wrong = 50; */

  const [showReviewScreen, SetShowReviewScreen] = useState(false);

  console.log(testPaper);

  var EngCorrect = 0;
  var EngSkipped = 0;
  var EngWrong = 0;
  var BioCorrect = 0;
  var BioSkipped = 0;
  var BioWrong = 0;
  var ChemCorrect = 0;
  var ChemSkipped = 0;
  var ChemWrong = 0;
  var PhysCorrect = 0;
  var PhysSkipped = 0;
  var PhysWrong = 0;
  var SRCorrect = 0;
  var SRSkipped = 0;
  var SRWrong = 0;

  var engQuestions = 0;
  var bioQuestions = 0;
  var chemQuestions = 0;
  var physQuestions = 0;
  var srQuestions = 0;

  testPaper.forEach((question, index) => {
    //Bio
    if (question.tags.includes("Biology")) {
      bioQuestions++;
      if (question.correct) {
        BioCorrect++;
      } else if (!question.selectedOption) {
        BioSkipped++;
      } else {
        BioWrong++;
      }
      return;
    }
    //Chem
    if (question.tags.includes("Chemistry")) {
      chemQuestions++;
      if (question.correct) {
        ChemCorrect++;
      } else if (!question.selectedOption) {
        ChemSkipped++;
      } else {
        ChemWrong++;
      }
      return;
    }
    //Phys
    if (question.tags.includes("Physics")) {
      physQuestions++;
      if (question.correct) {
        PhysCorrect++;
      } else if (!question.selectedOption) {
        PhysSkipped++;
      } else {
        PhysWrong++;
      }
      return;
    }
    if (question.tags.includes("Logical Reasoning")) {
      srQuestions++;
      if (question.correct) {
        SRCorrect++;
      } else if (!question.selectedOption) {
        SRSkipped++;
      } else {
        SRWrong++;
      }
      return;
    }
    if (question.tags.includes("English")) {
      engQuestions++;
      if (question.correct) {
        EngCorrect++;
      } else if (!question.selectedOption) {
        EngSkipped++;
      } else {
        EngWrong++;
      }
      return;
    }
  });

  var EngScore = EngCorrect;
  var BioScore = BioCorrect; //- BioWrong * 0.25;
  var ChemScore = ChemCorrect; //- ChemWrong * 0.25;
  var PhysScore = PhysCorrect; //- PhysWrong * 0.25;
  var SRScore = SRCorrect;

  console.log(ChemWrong);

  var CumulativeScore = BioScore + ChemScore + PhysScore + EngScore + SRScore;
  var SciencesScore = BioScore + ChemScore + PhysScore;
  var MathSRScore = EngScore + SRScore;
  var TotalNegativeMarking =
    (BioWrong + ChemWrong + PhysWrong + EngWrong) * 0.25;
  if (negativeMarking) {
    CumulativeScore = CumulativeScore - TotalNegativeMarking;
  } else {
    TotalNegativeMarking = 0;
  }

  console.log(BioCorrect);

  var scorePercentage =
    Math.round(((correct * 100 - negativeMarking * 100) / total) * 100) / 100;
  console.log(totalTime);
  var formattedTotalTime = new Date(totalTime * 1000)
    .toISOString()
    .substr(11, 8);
  var averageTimeTaken =
    correct + wrong > 0 ? totalTime / (correct + wrong) : 0;

  var circleBarConfig = GetCircleBarConfig(total, correct, skipped, wrong);

  const rows = !showNegativeMarking
    ? [
        createData("Total Questions", total),
        createData("Attempted Questions", correct + wrong),
        createData("Correct Questions", correct),
        createData("Wrong Questions", wrong),
        createData("Skipped Questions", skipped),
        createData(
          "Average Time Taken per Question",
          String(Math.round((averageTimeTaken * 10) / 10)) + " seconds"
        ),
        createData("Total Time Taken", formattedTotalTime),
      ]
    : [
        createData("Total Questions", total),
        createData("Attempted Questions", correct + wrong),
        createData("Correct Questions", correct),
        createData("Wrong Questions", wrong),
        createData("Negative Marking", -TotalNegativeMarking),
        createData("Skipped Questions", skipped),
        createData(
          "Average Time Taken per Question",
          String(Math.round((averageTimeTaken * 10) / 10)) + " seconds"
        ),
        createData("Total Time Taken", formattedTotalTime),
      ];

  function To2Decimal(number) {
    return Math.round(number * 100) / 100;
  }

  return (
    <div>
      <div
        className={`${styles.MainDiv}`}
        style={showReviewScreen ? { display: "none" } : null}
      >
        <div className={`${styles.PageTitle}`}>{deckName}</div>
        <div className={`${styles.ButtonContainer}`}>
          <GradientBtn
            style={{ padding: "0.8em 2em", borderRadius: "30px" }}
            gradient={"#00D396 0%, #90BAFF 94.59%"}
            onClick={() => {
              OnReviewTestClicked();
            }}
          >
            Review My Answers
          </GradientBtn>
          <GradientBtn
            style={{ padding: "0.8em 2em", borderRadius: "30px" }}
            gradient={"#EC5863 0%, #90BAFF 94.59%"}
            onClick={() => {
              OnReAttemptClicked();
            }}
          >
            Re-Attempt This Paper
          </GradientBtn>
        </div>

        <div className={`${styles.CircleBarFlex}`}>
          <CircleBarBox
            circleBarConfig={GetCircleBarConfig(
              testPaper.length,
              correct,
              skipped,
              wrong
            )}
            correct={correct}
            wrong={wrong}
            skipped={skipped}
            total={total}
            negativeMarking={negativeMarking}
            scorePercentage={To2Decimal((CumulativeScore - TotalNegativeMarking) * 100 / total)}
            HeadingTitle={`Cumulative Score`}
          />
          <div className={`${styles.CircleBarBox}`}>
            <p className={`${styles.CircleBoxTitle}`}>Target Score</p>
            <div>
              <CircularBar
                scale={2}
                angleTransition={[0.8 * 360]}
                colors={["#00D396", "#c9c9c9"]}
                gapGradient={0}
                stroke={{ color: "#eeeeee", width: 18 }}
                title={{
                  name: `${80}%`,
                  align: "middle",
                  fontWeight: "bold",
                  fontSize: "20",
                  color: "Black",
                }}
                percent={{
                  value: 100,
                  showValue: false,
                }}
              />
            </div>
          </div>
        </div>
        <div className={styles.HighlightsTile}>
          <div className={styles.HighlightDiv}>
            <img src={Highlight1Icon} />
            <div>
              <div>Total Marks Scored</div>
              <div>
                {correct - TotalNegativeMarking} / {total}
              </div>
            </div>
          </div>
          <div className={styles.HighlightDiv}>
            {" "}
            <img src={Highlight2Icon} />
            <div>
              <div>Total Time Taken</div>
              <div>{formattedTotalTime}</div>
            </div>
          </div>
          <div className={styles.HighlightDiv}>
            {" "}
            <img src={Highlight3Icon} />
            <div>
              <div>Avg time per question</div>
              <div>{String(Math.round((averageTimeTaken * 10) / 10))} secs</div>
            </div>
          </div>
        </div>
        <div className={styles.SecondTile}>
          <label>Subject Breakdown</label>
          <div className={styles.SubjectBreakdownContainer}>
            <SubjectBreakDown
              index={5}
              img={EngIcon}
              score={EngScore}
              total={engQuestions}
              correct={EngCorrect}
              skipped={EngSkipped}
              wrong={EngWrong}
            />
            <SubjectBreakDown
              index={1}
              img={BioIcon}
              score={BioScore}
              total={bioQuestions}
              correct={BioCorrect}
              skipped={BioSkipped}
              wrong={BioWrong}
            />
            <SubjectBreakDown
              index={2}
              img={ChemIcon}
              score={ChemScore}
              total={chemQuestions}
              correct={ChemCorrect}
              skipped={ChemSkipped}
              wrong={ChemWrong}
            />
            <SubjectBreakDown
              index={3}
              img={PhysIcon}
              score={PhysScore}
              total={physQuestions}
              correct={PhysCorrect}
              skipped={PhysSkipped}
              wrong={PhysWrong}
            />
            {srQuestions > 0 && (
              <SubjectBreakDown
                index={4}
                img={SRIcon}
                score={SRScore}
                total={srQuestions}
                correct={SRCorrect}
                skipped={SRSkipped}
                wrong={SRWrong}
              />
            )}
          </div>
        </div>
        <br />
        <div className={`${styles.SummaryTableBox}`}>
          <p className={`${styles.SummaryBoxTitle}`}>Summary</p>
          <SummaryTable rows={rows} />
        </div>
      </div>
    </div>
  );
}

function GetAngles(total, correct, skipped, wrong) {
  //angleTransition={GetAngles(total, correct, skipped, wrong)}
  //colors={["#00e040", "#eeff00", "#eb2502"]}
  /*  var cAngle = (correct / total) * 360;
  var sAngle = cAngle + (skipped / total) * 360;
  var wAngle = sAngle + (skipped / total) * 360; */
}

function GetCircleBarConfig(total, correct, skipped, wrong) {
  /*  total = 100;
  correct = 50;
  skipped = 0;
  wrong = 50; */

  const CORRECT_COLOR = "#00D396";
  const WRONG_COLOR = "#EC5863";
  const SKIPPED_COLOR = "#FFC727";

  //Case 1 : C,S,W > 0  OR C , W > 0
  if (correct > 0 && wrong > 0) {
    var cAngle = (correct / total) * 360;
    var sAngle = cAngle + (skipped / total) * 360;
    return {
      angleTransition: [cAngle, sAngle],
      colors: [CORRECT_COLOR, SKIPPED_COLOR, WRONG_COLOR],
    };
  }
  //Case 2 : C == 0
  if (correct === 0 && wrong > 0 && skipped > 0) {
    var sAngle = (skipped / total) * 360;
    var wAngle = sAngle + (wrong / total) * 360;
    return {
      angleTransition: [sAngle],
      colors: [SKIPPED_COLOR, WRONG_COLOR],
    };
  }
  //Case 3 : C == 0 W==0
  if (correct === 0 && wrong === 0) {
    return {
      angleTransition: [359],
      colors: [SKIPPED_COLOR, SKIPPED_COLOR],
    };
  }
  //Case 4 : C ,S == 0 W > 0
  if (correct === 0 && skipped === 0 && wrong > 0) {
    return {
      angleTransition: [359],
      colors: [WRONG_COLOR, WRONG_COLOR],
    };
  }
  //Case 5 :  W ===0 C,S > 0
  if (wrong === 0 && skipped > 0 && correct > 0) {
    var cAngle = (correct / total) * 360;
    return {
      angleTransition: [cAngle],
      colors: [CORRECT_COLOR, SKIPPED_COLOR],
    };
  }
}

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

function createData(name, data) {
  return { name, data };
}

const useStyles = makeStyles({
  table: {
    minWidth: 300,
  },
});

export default NewTestDeckResult;

function SummaryTable(props) {
  const { rows } = props;
  const classes = useStyles();

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="customized table">
        <TableBody>
          {rows.map((row) => (
            <StyledTableRow key={row.name}>
              <StyledTableCell component="th" scope="row">
                {row.name}
              </StyledTableCell>
              <StyledTableCell align="right">{row.data}</StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

const CircleBarBox = ({
  circleBarConfig,
  correct,
  negativeMarking,
  total,
  scorePercentage,
  wrong,
  skipped,
  HeadingTitle,
}) => {
  return (
    <div className={`${styles.CircleBarBox}`}>
      <p className={`${styles.CircleBoxTitle}`}>{HeadingTitle}</p>
      <div>
        <CircularBar
          scale={2}
          angleTransition={circleBarConfig.angleTransition}
          colors={circleBarConfig.colors}
          gapGradient={0}
          stroke={{ color: "#eeeeee", width: 18 }}
          title={{
            name: `${scorePercentage}%`,
            align: "middle",
            fontWeight: "bold",
            fontSize: "20",
            color: "Black",
          }}
          percent={{
            value: 100,
            showValue: false,
          }}
        />
      </div>
      <div className={`${styles.CircleBarLegend}`}>
        <div
          className={`${styles.CircleBarLegendColorBox}`}
          style={{ backgroundColor: "#00e040" }}
        ></div>
        Correct Questions: {correct}
      </div>
      <div className={`${styles.CircleBarLegend}`}>
        <div
          className={`${styles.CircleBarLegendColorBox}`}
          style={{ backgroundColor: "#eb2502" }}
        ></div>
        Wrong Questions: {wrong}
      </div>
      <div className={`${styles.CircleBarLegend}`}>
        <div
          className={`${styles.CircleBarLegendColorBox}`}
          style={{ backgroundColor: "#eeff00" }}
        ></div>
        Skipped Questions: {skipped}
      </div>
    </div>
  );
};

const SubjectBreakDown = ({
  index,
  img,
  score,
  total,
  correct,
  skipped,
  wrong,
}) => {
  var percent = Math.round(((score * 100) / total) * 100) / 100;

  return (
    <div>
      <img src={img} />
      <div className={styles.ActivityProgress}>
        <span className={styles.ProgressSpan}>
          <Progress
            className={styles[`ProgressBar${index}`]}
            theme="primary"
            value={percent}
          />
        </span>
        <label>
          Correct: <strong>{correct}</strong> Wrong: {wrong} Skipped: {skipped}
        </label>
        <span
          className={`${styles.ActivityPercent} ${
            styles[`ProgressTextColor${index}`]
          }`}
        >
          {" "}
          {score}/{total}
        </span>
      </div>
    </div>
  );
};
