import { useState, useEffect } from "react";
import { useHistory, useParams, withRouter } from "react-router";
import { useTimer } from "react-timer-hook";
import DeckMetaInfo from "../YearlyDecksMeta";
import { LogTestAttempt } from "../../../APIManager";

function useTestDeck(testDeckAPI, deckName, enableAKUMarking) {
  console.log(DeckMetaInfo);
  const [downloading, SetDownloading] = useState(true);
  const [deck, SetDeck] = useState({});
  const [deckID, SetDeckID] = useState("");
  const noOfQuestions = deck?.length;
  const paperDuration = deckName ? DeckMetaInfo[deckName].Time * 60 : 9000;

  const [testPaper, SetTestPaper] = useState();

  const [showStartScreen, SetShowStartScreen] = useState(true);
  const [showQuestionComponent, SetShowQuestionComponent] = useState(false);
  const [showResultScreen, SetShowResultScreen] = useState(false);
  const [showReviewComponent, SetShowReviewComponent] = useState(false);

  const [currentQuestion, SetCurrentQuestion] = useState({});
  const [currentQID, SetCurrentQID] = useState(null);
  const [questionNo, SetQuestionNo] = useState(1);

  const [nextEnabled, SetNextEnabled] = useState(false);
  const [backEnabled, SetBackEnabled] = useState(false);

  const [testPaused, SetTestPaused] = useState(false);

  const [testResult, SetTestResult] = useState(null);

  const noOfAttemptedQuestions = GetNoOfAttemptedQuestions();

  useEffect(() => {
    if (!deck || !deck[questionNo - 1]) {
      return;
    }
    window.scrollTo({ top: 0 });
    SetCurrentQuestion(deck[questionNo - 1]);
    SetCurrentQID(deck[questionNo - 1]._id);
  }, [questionNo, deck]);

  const {
    seconds,
    minutes,
    hours,
    days,
    isRunning,
    start,
    pause,
    resume,
    restart,
  } = useTimer({
    expiryTimestamp: GetExipiryTimeStamp(),
    onExpire: () => console.warn("onExpire called"),
    autoStart: false,
  });


  function GetExipiryTimeStamp() {
    const timeOBJ = new Date();
    timeOBJ.setSeconds(timeOBJ.getSeconds() + paperDuration);
    return timeOBJ;
  }

  useEffect(() => {
    SetDownloading(true);
    testDeckAPI(deckName).then((deck) => {
      SetDeckID(deck.data._id);
      SetDeck(deck.data.Questions.filter((q) => q.Published === true));
      SetDownloading(false);
      InitializeTestPaper(
        deck.data.Questions.filter((q) => q.Published === true)
      );
      //start();
    });
  }, []);

  useEffect(() => {
    if (questionNo === deck.length) {
      SetNextEnabled(false);
    } else {
      SetNextEnabled(true);
    }
    if (questionNo === 1) {
      SetBackEnabled(false);
    } else {
      SetBackEnabled(true);
    }
  }, [questionNo, deck]);

  function GetNoOfAttemptedQuestions() {
    if (!testPaper) {
      return 0;
    }
    var no = 0;
    testPaper.forEach((q) => {
      if (q.selectedOption) {
        no++;
      }
    });
    return no;
  }

  var NextQuestion = () => {
    if (testPaused) {
      return;
    }
    if (CanGoToNextQuestion(questionNo, noOfQuestions, true)) {
      SetQuestionNo(questionNo + 1);
    }
  };

  var PreviousQuestion = () => {
    if (testPaused) {
      return;
    }
    if (CanGoToPrevQuestion(questionNo, noOfQuestions, true)) {
      SetQuestionNo(questionNo - 1);
    }
  };

  function OnTestStart() {
    SetShowStartScreen(false);
    SetShowQuestionComponent(true);
    start();
  }

  function OnTestFinsh() {
    pause();
    SetShowStartScreen(false);
    SetShowQuestionComponent(false);
    FormulateResult();
    SetShowResultScreen(true);
    SetQuestionNo(1);
  }

  var OnOptionClicked = (optionLetter) => {
    var test = testPaper;
    test[questionNo - 1].selectedOption = optionLetter;
    test[questionNo - 1].correct = currentQuestion.Options.filter((option) => {
      return option.OptionLetter === optionLetter;
    })[0].IsCorrect;
    SetTestPaper([...test]);
  };

  var OnFlagClicked = (flagged) => {
    var test = testPaper;
    test[questionNo - 1].flagged = flagged;
    SetTestPaper([...test]);
  };

  var OnPause = () => {
    pause();
    SetTestPaused(true);
  };

  var OnResume = () => {
    resume();
    SetTestPaused(false);
  };

  var SkipToQuestion = (skipquestionNo) => {
    skipquestionNo = parseInt(skipquestionNo);
    if (CanSkipToQuestion(skipquestionNo, noOfQuestions, true)) {
      SetQuestionNo(skipquestionNo);
    }
  };

  function OnReviewClicked() {
    SetShowResultScreen(false);
    SetShowReviewComponent(true);
  }

  function OnBackClicked() {
    SetShowResultScreen(true);
    SetShowReviewComponent(false);
  }

  function OnResultClicked() {
    SetShowResultScreen(true);
    SetShowReviewComponent(false);
  }

  function FormulateResult() {
    var NoOfQuestions = noOfQuestions;
    var attempts;
    var correct = 0;
    var wrong = 0;
    var skipped = 0;
    var negativeMarking = 0;
    var timeTaken;
    var avgQuestionTime;
    testPaper.forEach((question) => {
      if (!question.selectedOption) {
        skipped++;
        return;
      }
      if (question.correct) {
        correct++;
      } else {
        wrong++;
        if (
          enableAKUMarking &&
          (question.tags.includes("Biology") ||
            question.tags.includes("Chemistry") ||
            question.tags.includes("Physics"))
        ) {
          negativeMarking = negativeMarking + 0.25;
        }
      }
    });
    attempts = correct + wrong;
    timeTaken = paperDuration - (seconds + minutes * 60 + hours * 3600);
    avgQuestionTime = timeTaken;

    console.log(timeTaken);

    SetTestResult({
      NoOfQuestions,
      attempts,
      correct,
      wrong,
      skipped,
      timeTaken,
      avgQuestionTime,
      negativeMarking,
    });

    LogTestAttempt(
      deckName,
      deckID,
      NoOfQuestions,
      correct,
      skipped,
      wrong,
      testPaper,
      timeTaken
    );

    return {
      NoOfQuestions,
      attempts,
      correct,
      wrong,
      skipped,
      timeTaken,
      avgQuestionTime,
    };
  }

  function SetReAttemptMode() {
    SetQuestionNo(1)
    InitializeTestPaper(deck)
    OnTestStart()
  }

  function SetTestReviewMode(testAttemptObject) {
    SetQuestionNo(1);
    SetTestPaper(testAttemptObject.TestPaper);

    var NoOfQuestions = noOfQuestions;
    var attempts;
    var correct = 0;
    var wrong = 0;
    var skipped = 0;
    var negativeMarking = 0;
    var timeTaken;
    var avgQuestionTime;
    testAttemptObject.TestPaper.forEach((question) => {
      if (!question.selectedOption) {
        skipped++;
        return;
      }
      if (question.correct) {
        correct++;
      } else {
        wrong++;
        if (
          enableAKUMarking &&
          (question.tags.includes("Biology") ||
            question.tags.includes("Chemistry") ||
            question.tags.includes("Physics"))
        ) {
          negativeMarking = negativeMarking + 0.25;
        }
      }
    });
    attempts = correct + wrong;
    timeTaken = testAttemptObject.TimeTaken;
    avgQuestionTime = timeTaken;

    console.log(timeTaken);

    SetTestResult({
      NoOfQuestions,
      attempts,
      correct,
      wrong,
      negativeMarking,
      skipped,
      timeTaken,
      avgQuestionTime,
    });
    SetShowStartScreen(false);
    SetShowQuestionComponent(false);
    SetShowResultScreen(true);
  }

  return {
    downloading: downloading,
    deck: deck,
    noOfQuestions: noOfQuestions,
    showStartScreen: showStartScreen,
    showQuestionComponent: showQuestionComponent,
    showResultScreen: showResultScreen,
    showReviewComponent: showReviewComponent,
    paperDuration: paperDuration,
    seconds,
    minutes,
    hours,
    OnTestStart,
    currentQuestion,
    currentQID,
    questionNo,
    nextEnabled,
    backEnabled,
    NextQuestion,
    PreviousQuestion,
    testPaper,
    OnOptionClicked,
    testPaused,
    OnPause,
    OnResume,
    noOfAttemptedQuestions,
    SkipToQuestion,
    OnTestFinsh,
    testResult,
    OnResultClicked,
    OnReviewClicked,
    OnFlagClicked,
    OnBackClicked,
    SetTestReviewMode,
    SetReAttemptMode
  };

  function InitializeTestPaper(questions) {
    var paper = [];
    questions.forEach((q) => {
      var tags = [];
      q.Tags.forEach((t) => {
        tags.push(t.name);
      });
      var question = {};
      question.id = q._id;
      question.selectedOption = null;
      question.correct = false;
      question.flagged = false;
      question.tags = tags;
      paper.push(question);
    });
    console.log(paper);
    SetTestPaper(paper);
  }
}
export default useTestDeck;

function CanGoToNextQuestion(questionNo, totalQuestionNo, questionNavAllowed) {
  var nextquestionNo = questionNo + 1;
  if (
    nextquestionNo > 0 &&
    nextquestionNo <= totalQuestionNo &&
    questionNavAllowed
  ) {
    return true;
  }
  return false;
}

function CanGoToPrevQuestion(questionNo, totalQuestionNo, questionNavAllowed) {
  var prevquestionNo = questionNo - 1;
  if (
    prevquestionNo > 0 &&
    prevquestionNo <= totalQuestionNo &&
    questionNavAllowed
  ) {
    return true;
  }
  return false;
}

function CanSkipToQuestion(questionNo, totalQuestionNo, questionNavAllowed) {
  var nextquestionNo = questionNo;
  if (
    nextquestionNo > 0 &&
    nextquestionNo <= totalQuestionNo &&
    questionNavAllowed
  ) {
    return true;
  }
  return false;
}
