import React, { useEffect, useState } from "react";
import styles from "./QuestionComponent.module.css";
import {
  Card,
  CardHeader,
  CardTitle,
  CardImg,
  CardBody,
  CardFooter,
  Button,
  Fade,
} from "shards-react";

import { CircularProgress } from "@material-ui/core";

import QuestionText from "./QuestionText";
import QuizOptionsContainer from "./QuizOptionsContainer";
import QuestionCardHeader from "./QuestionCardHeader";
import BookMark from "./BookMark";
import TagList from "./TagList";
import NavButton from "./NavButtons";
import ReportQuestion from "./ReportQuestionComponent";
import TestSkipQuestion from "./TestSkipQuestionComponent";
//import TestSkipQuestion from "./TestSkipQuestionComponent";
import ExplanationFooter from "./ExplanationFooter";

import ProgressBar from "./ProgressBar";

//noti stackl for snack bar popup mamnahemnt
const Tags = ["PMC Sample 1", "Biology"];

const TestModeReviewComponent = ({
  deckName,
  questionNo,
  NextQuestion,
  PreviousQuestion,
  currentQuestion,
  nextEnabled,
  backEnabled,
  isExplanationPurchased,
  loadingQuestion,
  SkipToQuestion,
  NoOfQuestions,
  testPaper,
  noOfAttemptedQuestions,
  OnBackClicked,
  HideBookMarkBtn,
}) => {
  const [reportModalVisible, SetReportModalVisible] = useState(false);
  const [skipModalVisible, SetSkipModalVisible] = useState(false);
  const [eliminationToolEnabled, SetEliminationToolEnabled] = useState(false);

  const options = GenerateOptions();

  function GenerateOptions() {
    var optionsArray = currentQuestion?.Options?.map((option) => {

      if(deckName?.includes("NUMS Mock") && questionNo > 150){
        return {
          color: "OptionColor",
          isSelected:
            testPaper[questionNo - 1].selectedOption === option.OptionLetter,
          optionLetter: option.OptionLetter,
          optionText: option.OptionText,
        };
      }

      return {
        color: GetOptionColor(option),
        isSelected:
          testPaper[questionNo - 1].selectedOption === option.OptionLetter ||
          option.IsCorrect,
        optionLetter: option.OptionLetter,
        optionText: option.OptionText,
      };
    });

    return optionsArray;

    function GetOptionColor(option) {
      if (option.IsCorrect) {
        if (!testPaper[questionNo - 1].selectedOption) {
          return "warning";
        } else {
          return "CorrectOptionColor";
        }
      } else {
        if (testPaper[questionNo - 1].selectedOption === option.OptionLetter) {
          return "InCorrectOptionColor";
        } else {
          return "OptionColor";
        }
      }
    }
  }

  document.onkeydown = checkKey;
  //window.scrollTo({ top: 0, behavior: "smooth" })

  useEffect(() => {}, [currentQuestion]);

  if (!currentQuestion.QuestionText) {
    return null;
  }

  function OnAttempt(optionLetter) {}

  return (
    <div className={styles.MainQuestionCard}>
      <div className={`${styles.TestModeOptionPanel}`}>
        <div className={`${styles.TestModeOptions}`}>
          <Button
            onClick={() => {
              OnBackClicked();
            }}
          >
            Go Back
          </Button>
        </div>
      </div>
      <Card
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        {reportModalVisible ? (
          <ReportQuestion
            OnCloseCB={() => {
              SetReportModalVisible(false);
            }}
            OnSubmitCB={() => {
              SetReportModalVisible(false);
            }}
            deckName={deckName}
            questionID={currentQuestion._id}
            isOpen={reportModalVisible}
          />
        ) : null}

        {skipModalVisible ? (
          <TestSkipQuestion
            OnCloseCB={() => {
              SetSkipModalVisible(false);
            }}
            isOpen={skipModalVisible}
            OnSkipCB={(no) => {
              SkipToQuestion(no);
              SetSkipModalVisible(false);
            }}
            numberOfQuestions={NoOfQuestions}
            testPaper={testPaper}
          />
        ) : null}

        <QuestionCardHeader
          reportOnClick={() => {
            SetReportModalVisible(true);
          }}
          skipOnClick={() => {
            SetSkipModalVisible(true);
          }}
        />

        <CardBody className={styles.QuestionBody}>
          {/* {RenderLoadingSpinner(classes.spinner, QuestionContent)} */}
          <div className={styles.QuestionMainBody}>
            {!loadingQuestion ? (
              <>
                {!HideBookMarkBtn && (
                  <BookMark
                    questionID={currentQuestion._id}
                    deckName={deckName}
                    deckType={"Yearly"}
                  />
                )}
                <QuestionText questionText={currentQuestion.QuestionText} />
                {RenderQuestionImage(currentQuestion.QuestionImage)}
                <TagList tags={currentQuestion?.Tags.map((tag) => tag.name)} />
                <QuizOptionsContainer
                  showEliminate={false}
                  options={options}
                  onClick={() => {}}
                  eliminationToolEnabled={eliminationToolEnabled}
                  toggleEliminationTool={(e) => {
                    SetEliminationToolEnabled(!eliminationToolEnabled);
                  }}
                />
              </>
            ) : (
              <div className={styles.QuestionLoading}>
                <CircularProgress size={70} />
              </div>
            )}
          </div>
          <NavButton
            questionNo={questionNo}
            isBackEnabled={backEnabled}
            isNextEnabled={nextEnabled}
            onBackClick={() => {
              PreviousQuestion();
            }}
            onNextClick={() => {
              NextQuestion();
            }}
          />
          <ProgressBar
            questionNo={questionNo}
            NoOfQuestions={NoOfQuestions}
            text={`${noOfAttemptedQuestions} / ${NoOfQuestions} attempted`}
          />
        </CardBody>
        <ExplanationFooter
          showAnswer={true}
          ExplanationContent={currentQuestion.ExplanationText}
          ExplanationImage={currentQuestion.ExplanationImage}
          isBlur={false}
          QuestionID={currentQuestion._id}
        />
      </Card>
    </div>
  );

  function checkKey(e) {
    e = e || window.event;

    if (e.keyCode == "38") {
      // up arrow
    } else if (e.keyCode == "40") {
    } else if (e.keyCode == "37") {
      try {
        if (
          backEnabled &&
          !loadingQuestion &&
          !skipModalVisible &&
          !reportModalVisible
        ) {
          PreviousQuestion();
          /*  ReactGA.event({
            category: "Question",
            action: "ArrowKeyNext/Back",
            label: "Yearly/Topical",
          }); */
        }
      } catch {}
    } else if (e.keyCode == "39") {
      if (
        nextEnabled &&
        !loadingQuestion &&
        !skipModalVisible &&
        !reportModalVisible
      ) {
        NextQuestion();
        /*  ReactGA.event({
          category: "Question",
          action: "ArrowKeyNext/Back",
          label: "Yearly/Topical",
        }); */
      }
    }
  }
};

function RenderQuestionImage(image) {
  if (image) {
    return (
      <div>
        <img
          src={image}
          className={styles.QuestionImage}
          style={{
            marginRight: "1rem",
            marginTop: "1rem",
            marginBottom: "1rem",
          }}
        />
      </div>
    );
  } else {
    return <div />;
  }
}

export default TestModeReviewComponent;
