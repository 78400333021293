import React, { useEffect, useState } from "react";
import styles from "./TestMode.module.css";
import { Modal, ModalBody, ModalHeader, Button, Fade } from "shards-react";
import { useMediaQuery } from "@material-ui/core";
import CheckCircleOutline from "@material-ui/icons/CheckCircleOutlined";
import CancelFilled from "@material-ui/icons/Cancel";
import PauseCircleFilled from "@material-ui/icons/PauseCircleFilled";
import PlayCircleFilled from "@material-ui/icons/PlayCircleFilled";
export default function TestModeOptionPanel({
  showFinishButton,
  showPauseButton,
  testPaused,
  timerText,
  ResumeTest,
  PauseTest,
  FinishTest,
  testPaper,
  showMockAttemptWarning,
}) {
  const [finishPromptOpen, SetFinishPromptOpen] = useState(false);
  const smallDevice = useMediaQuery("(max-width:460px)");
  const [skipped, SetSkipped] = useState(0);

  useEffect(() => {
    var skipped = 0;
    testPaper?.forEach((question) => {
      if (!question.selectedOption) {
        skipped++;
        return;
      }
    });
    SetSkipped(skipped);
  });

  return (
    <div className={`${styles.TestModeOptionPanel}`}>
      <Modal
        open={finishPromptOpen}
        toggle={() => {
          SetFinishPromptOpen(false);
        }}
        centered
      >
        <ModalHeader className={styles.CenterModalHeader}>Confirm❗</ModalHeader>
        <ModalBody>
          <div style={{ fontSize: "1.2em", textAlign: "center" }}>
            {" "}
            Are you sure you want to finish the test?
            <div hidden={!skipped}>
              You still have {skipped} unattempted questions
            </div>
            Once you select the ‘Finish’ button, you receive your score report and will be able to review your attempted answers.

            <br />
            <br />
            <strong style={{ color: "Red" }} hidden={!showMockAttemptWarning}>
            Please note that once you press the ‘Finish’ button, you will not be able to attempt this paper in Mock Mode again. However, you can review your answers and explanations at any time later.
            </strong>
          </div>
          <div style={{ marginTop: "1em", textAlign: "center" }}>
            <span style={{ marginRight: ".5em" }}>
              <Button
                theme="QuestionBlue"
                onClick={() => {
                  FinishTest();
                  SetFinishPromptOpen(false);
                }}
              >
                <CheckCircleOutline
                  style={{
                    marginRight: "0.4em",
                    marginLeft: "-0.6em",
                    marginTop: "-0.1em",
                  }}
                />
                Finish
              </Button>
            </span>
            <Button
              theme="RedAccent"
              onClick={() => {
                SetFinishPromptOpen(false);
              }}
            >
              <CancelFilled
                style={{
                  marginRight: "0.4em",
                  marginLeft: "-0.6em",
                  marginTop: "-0.1em",
                }}
              />
              Cancel
            </Button>
          </div>
        </ModalBody>
      </Modal>
      <div className={`${styles.TestModeOptions}`}>
        <Button
          style={{
            display: showFinishButton ? "inline" : "none",
            marginRight: "0.5em",
          }}
          theme="danger"
          size={smallDevice ? "" : ""}
          onClick={() => {
            SetFinishPromptOpen(true);
          }}
        >
          <CheckCircleOutline
            hidden={smallDevice}
            style={{
              marginRight: "0.4em",
              marginLeft: "-0.6em",
              marginTop: "-0.1em",
            }}
          />
          Finish
        </Button>
        <Button
          style={{
            display: showPauseButton ? "inline" : "none",
          }}
          size={smallDevice ? "" : ""}
          theme={testPaused ? "success" : "warning"}
          onClick={testPaused ? ResumeTest : PauseTest}
        >
          {testPaused ? (
            <>
              <PlayCircleFilled
                hidden={smallDevice}
                style={{
                  marginRight: "0.4em",
                  marginLeft: "-0.6em",
                  marginTop: "-0.1em",
                }}
              />
              Resume
            </>
          ) : (
            <>
              <PauseCircleFilled
                hidden={smallDevice}
                style={{
                  marginRight: "0.4em",
                  marginLeft: "-0.6em",
                  marginTop: "-0.1em",
                }}
              />{" "}
              Pause
            </>
          )}
        </Button>
      </div>
      <div
        className={`${styles.TestModeTimer}`}
      >{`Time Left: ${timerText}`}</div>
    </div>
  );
}
