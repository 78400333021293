import HeaderImage from "../../../StaticImages/Blog/NewPreMedLogo.png";
import FoundersImage from "../../../StaticImages/Blog/FoundersImage.png";
import UmarBoardImage from "../../../StaticImages/Blog/UmarBoardImage.png";
import HasnainCodingImage from "../../../StaticImages/Blog/HasnainCodingImage.png";
import TeamZoomImage from "../../../StaticImages/Blog/TeamZoomImage.png";
import CoreTeamImage from "../../../StaticImages/Blog/CoreTeamImage.png";
export var PreMedStoryArticle = {
  HeaderImg: CoreTeamImage,
  Tags: ["Featured", "PreMed.PK"],
  Title: "The Vision and Journey behind PreMed.PK",
  Desc: "Take a walk down memory lane revisiting how PreMed.PK started and what are its future goals and visions",
  Date: "April 7, 2022",
  AuthorName: <>Sibgha Alam &bull; Muhammad Danish</>,
  Article: (
    <>
      <p>
        Pre-Medical: a journey packed with uncertainty, frustration and anxiety,
        and yet barely ever spoken about. From the ever-changing policies to the
        financial burden of academies and tuitions, at each step a student with
        the mere dream of getting into a medical school, faces novel problems
        and challenges and many times, has no one to mentor and guide him/her
        through that. Hasnain Mankani, a 2nd-year medical student at the Aga
        Khan University and Fahd Shaikh, a 3rd-year student at Dow Medical
        College, while going through the same tiring journey of getting into
        their dream universities, realised they wanted to be the change in the
        system that was benefitting none but itself. And from there began the
        development of the nascent idea to the now known PreMed.PK - a platform
        designed solely for pre-medical students.
      </p>
      <br />
      <p>
        First and foremost, efficient question banks with proper answers and
        their explanations have remained absent in the landscape of MDCAT
        preparation in Pakistan; from books with faulty marking schemes to
        confusing and contradicting statements in the textbooks, there was
        nothing a student could trust and rely on. On top of that, the new
        enforced system of online testing for NMDCAT by PMC also brought forth
        its own set of problems! It was a huge financial strain on parents and
        students, Rs. 6,000 only for the exam and the practice questions on TEPS
        platform for another Rs. 3,600, discouraged many students from the
        career.
      </p>
      <br />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <img src={UmarBoardImage} />
        <br />
        <p style={{ textAlign: "center" }}>
          The Chief Technology Officer, Umar Mahar, briefing the founders about
          the tech stack and various services deployed at PreMed.PK{" "}
        </p>
      </div>
      <br />
      <p>
        However, PreMed.PK has challenged that, revolutionising studying into a
        more up-to-date and fun version. Keeping in mind the new system of
        online testing by PMC, there are more than 15,000 MCQs taken from
        various reliable sources such as the past papers of NMDCAT, NUMS, NTS,
        UHS MCAT, AKU Test, etc. The answer keys have been reviewed multiple
        times for there to remain no doubt and the explanations have been
        compiled by students, who themselves went past and succeeded through
        these tests. And most importantly, the platform was built to be
        affordable for everyone so that finances is not the factor directing
        dreams but passion and hard work are. Furthermore, there is an option to
        report questions, you are doubtful about the marking scheme of, and an
        option to save questions for future reviewing so all concepts are fully
        mastered. A website also aligns the process of learning in a way easier
        manner - you can always access it, unlike books which become a burden to
        carry and also can be prone to misfortune! Imagine the pages tearing up
        or chai spilling on it during one of your late night study sessions.
        PreMed.PK saves you from the hassle. It will become the companion you
        definitely needed!
      </p>
      <br />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <img src={HasnainCodingImage} />
        <br />
        <p style={{ textAlign: "center" }}>
          We are proud to say that all our coding has been done in-house. It has
          been a process where we have experimented with various new algorithms
          and machine learning techniques to enhance the interface for our
          customers.{" "}
        </p>
      </div>
      <br />
      <p>
        Apart from the question bank, PreMed.PK also recognized the need for a
        trustworthy source of information about not only the upcoming tests,
        their curriculum and patterns but also career advice and guidance
        throughout the premedical and medical journey. Facebook groups and their
        constant notifications with half the members sharing absolutely anything
        and the other half worrying over the mostly unchecked and unauthorized
        posts shared, can be nothing short of anxiety-inducing in themselves. An
        hour of scrolling through the page will have you more lost and confused
        than you were in the beginning, ending up with more questions than
        answers! In an attempt to change this, PreMed.PK has successfully built
        a community of 25 dedicated members from all across Pakistan to provide
        an honest and constant support system, helping students navigate through
        the challenges they face in this journey, not only exponentially
        reducing the anxiety but also directing them to the right path, that
        would inevitably have them landing at the doorstep of their dream
        universities!
      </p>
      <br />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <img src={TeamZoomImage} />
        <br />
        <p style={{ textAlign: "center" }}>
          Our team has a mix of students from all regions of Pakistan. An
          initiative for pre-medical students by pre-medical and medical
          students. That’s what makes us unique.{" "}
        </p>
      </div>
      <br />
      <p>
        From individually writing questions, their explanations, and coding the
        website, the team of PreMed.PK has worked hard and launched this
        platform solely for the doctors of tomorrow!
      </p>
      <br />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <img src={CoreTeamImage} />
        <br />
        <p style={{ textAlign: "center" }}>
          The PreMed.PK Team truly wishes to make a difference by
          revolutionizing the way pre-medical students prepare for their medical
          school entrance exams. We are here to guide you at every step of the
          way. Happy Learning!
        </p>
      </div>
      <br />
    </>
  ),
};
