import React, { useState, useEffect, useCallback, useContext } from "react";
import usePracticeDeck from "./Hooks/usePracticeDeck";
import PracticeQuestionComponent from "./PracticeQuestionComponent";
import DeckStartComponent from "../../Pages/QuestionDeck/DeckStartComponent";

import { Modal, ModalBody, ModalHeader } from "shards-react";
import GradientBtn from "../../Components/GradientBtn/GradientBtn.jsx";

import CircularProgress from "@material-ui/core/CircularProgress";

import { UserContext } from "../../userContext";

import { useHistory, useParams, withRouter } from "react-router";
import { Prompt } from "react-router-dom";

import { GetTestPaper } from "../../APIManager";
import useTestDeck from "./Hooks/useTestDeck";

import QuizStartScreen from "./TestModeDeckStartComponent";
import TestModeOptionPanel from "./TestModeOptionPanel";
import TestModeQuestionComponent from "./TestModeQuestionComponent";
import TestModeDeckResult from "./TestModeDeckResult";
import NewTestDeckResult from "./NewTestDeckResult";
import TestModeReviewComponent from "./TestModeReviewComponent";

import { GetMockAttemptTestPaper } from "../../APIManager";

import Footer from "../NewFooter/Footer";

export default function YearlyPracticeComponent() {
  let search = window.location.search;
  let params = new URLSearchParams(search);

  const [user, setUser] = useContext(UserContext);
  const [premiumModalOpen, SetPremiumModalOpen] = useState(false);
  const isPremium = user.addonspurchased.includes("Premium");

  const [previousAttempt, SetPreviousAttempt] = useState(null);
  const [gettingState, SetGettingState] = useState(true);

  useEffect(() => {
    GetMockAttemptTestPaper(deckName).then((res) => {
      SetPreviousAttempt(res.data);
      SetGettingState(false);
    });
  }, []);

  const history = useHistory();
  const deckName = params.get("deck");
  const {
    downloading,
    noOfQuestions,
    deck,
    showQuestionComponent,
    showResultScreen,
    showReviewComponent,
    showStartScreen,
    paperDuration,
    seconds,
    minutes,
    hours,
    OnTestStart,
    currentQID,
    currentQuestion,
    questionNo,
    nextEnabled,
    backEnabled,
    NextQuestion,
    PreviousQuestion,
    testPaper,
    OnOptionClicked,
    testPaused,
    OnPause,
    OnResume,
    noOfAttemptedQuestions,
    SkipToQuestion,
    OnTestFinsh,
    testResult,
    OnResultClicked,
    OnReviewClicked,
    OnFlagClicked,
    OnBackClicked,
    SetTestReviewMode,
    SetReAttemptMode,
  } = useTestDeck(GetTestPaper, deckName);

  console.log(currentQuestion);
  useEffect(() => {
    window.onbeforeunload = function () {
      return "Do you really want to leave?";
    };

    return () => {
      window.onbeforeunload = function () {};
    };
  }, []);

  return (
    <>
      <Prompt
        when={!showStartScreen}
        message="There are unsaved changes, do you wish to discard them?"
      />
      {(showStartScreen || showQuestionComponent) && (
        <TestModeOptionPanel
          showFinishButton={showQuestionComponent}
          showPauseButton={showQuestionComponent}
          timerText={GetTimerText()}
          /* timerText={new Date((hours * 3600) + (minutes * 60) + seconds)
            .toISOString()
            .substr(11, 8)} */
          testPaused={testPaused}
          PauseTest={OnPause}
          ResumeTest={OnResume}
          FinishTest={OnTestFinsh}
          testPaper={testPaper}
        />
      )}
      <PremiumModal
        isOpen={premiumModalOpen}
        OnClick={() => {
          history.push("/plans");
        }}
      />

      {showStartScreen && (
        <>
          {" "}
          <QuizStartScreen
            Name={deckName}
            NoOfQuestions={noOfQuestions}
            showResume={false}
            loading={downloading || gettingState}
            onStartCB={() => {
              if (isPremium) {
                OnTestStart();
              } else {
                SetPremiumModalOpen(true);
              }
            }}
          />
          <AlreadyAttemptedModal
            isOpen={previousAttempt}
            onClick={() => {}}
            isDownloading={downloading}
            user={user}
            OnAgainClick={() => {
              if (isPremium) {
                OnTestStart();
              } else {
                SetPremiumModalOpen(true);
              }
            }}
            OnReviewClick={() => {
              SetTestReviewMode(previousAttempt);
            }}
          />
        </>
      )}
      {showQuestionComponent && (
        <TestModeQuestionComponent
          deckName={deckName}
          questionNo={questionNo}
          NoOfQuestions={noOfQuestions}
          currentQuestion={currentQuestion}
          nextEnabled={nextEnabled}
          backEnabled={backEnabled}
          NextQuestion={NextQuestion}
          PreviousQuestion={PreviousQuestion}
          testPaper={testPaper}
          OnOptionClicked={OnOptionClicked}
          testPaused={testPaused}
          OnPause={OnPause}
          noOfAttemptedQuestions={noOfAttemptedQuestions}
          SkipToQuestion={SkipToQuestion}
          OnFlagClicked={OnFlagClicked}
          OnPasue={OnPause}
          OnResume={OnResume}
        />
      )}
      {showResultScreen && (
        <NewTestDeckResult
          total={testResult.NoOfQuestions}
          correct={testResult.correct}
          skipped={testResult.skipped}
          wrong={testResult.wrong}
          totalTime={testResult.timeTaken}
          deckName={deckName}
          OnReviewTestClicked={OnReviewClicked}
          OnReAttemptClicked={SetReAttemptMode}
          showNegativeMarking={
            deckName.includes("AKU") ||
            (deckName.includes("ETEA") && !deckName.includes("2019"))
          }
          negativeMarking={testResult.negativeMarking}
          testPaper={testPaper}
        />
      )}
      {showReviewComponent && (
        <TestModeReviewComponent
          deckName={deckName}
          questionNo={questionNo}
          NoOfQuestions={noOfQuestions}
          currentQuestion={currentQuestion}
          nextEnabled={nextEnabled}
          backEnabled={backEnabled}
          NextQuestion={NextQuestion}
          PreviousQuestion={PreviousQuestion}
          testPaper={testPaper}
          OnOptionClicked={OnOptionClicked}
          testPaused={testPaused}
          OnPause={OnPause}
          noOfAttemptedQuestions={noOfAttemptedQuestions}
          SkipToQuestion={SkipToQuestion}
          OnBackClicked={OnBackClicked}
        />
      )}
      <Footer />
    </>
  );
  function GetTimerText() {
    return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(
      2,
      "0"
    )}:${String(seconds).padStart(2, "0")}`;
  }
}

const PremiumModal = ({ isOpen, OnClick }) => {
  return (
    <Modal
      open={isOpen}
      toggle={() => {
        //SetModalOpen(!modalOpen);
      }}
    >
      <ModalHeader>Premium Feature</ModalHeader>
      <ModalBody>
        <div
          style={{
            fontSize: "1.2em",
            textAlign: "center",
            fontFamily: "Rubik",
          }}
        >
          Your currrent plan does not have access to{" "}
          <span style={{ color: "var(--QuestionBlue)" }}>Timed Test Mode.</span>{" "}
          Switch to our{" "}
          <span style={{ color: "var(--RedAccent)" }}>Premium Plan</span> to
          access this feature!
          <br />
          <br />
          <GradientBtn
            style={{
              fontSize: "0.7em",
              borderRadius: "30px",
              margin: 0,
            }}
            gradient={"#D31027 0%, #EA384D  51%, #D31027  100%"}
            onClick={OnClick}
          >
            Purchase Premium Plan
          </GradientBtn>
        </div>
      </ModalBody>
    </Modal>
  );
};

const AlreadyAttemptedModal = ({
  isOpen,
  OnAgainClick,
  OnReviewClick,
  mockName,
  user,
  isDownloading,
}) => {
  function onClick() {}

  return (
    <Modal
      open={isOpen}
      toggle={() => {
        //SetModalOpen(!modalOpen);
      }}
      centered
    >
      <ModalHeader>Paper already attempted!</ModalHeader>
      <ModalBody>
        <div
          style={{
            fontSize: "1.2em",
            textAlign: "center",
            fontFamily: "Rubik",
          }}
        >
          Hey {user.fullname}, it looks like you have already attempted this
          Yearly Paper. You can choose to review the answers and explanations
          from your previous attempt or re- attempt the same paper again.
          However, if you choose to re-attempt this paper, you will lose the
          scorecard and review session from your previous attempt.📝
          <br />
          <br />
          <GradientBtn
            style={{
              fontSize: "0.7em",
              borderRadius: "30px",
              margin: 0,
            }}
            gradient={"#00D396 0%, #90BAFF 94.59%"}
            disabled={isDownloading}
            onClick={OnReviewClick}
          >
            {!isDownloading ? "Review Previous Attempt" : "Loading"}
          </GradientBtn>
          <br />
          <br />
          <GradientBtn
            style={{
              fontSize: "0.7em",
              borderRadius: "30px",
              margin: 0,
            }}
            gradient={"#EC5863 0%, #90BAFF 94.59%"}
            disabled={isDownloading}
            onClick={OnAgainClick}
          >
            {!isDownloading ? "Attempt Again" : "Loading"}
          </GradientBtn>
        </div>
      </ModalBody>
    </Modal>
  );
};
