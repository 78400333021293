import { ContactsOutlined } from "@material-ui/icons";
import React, { useEffect, useState, useContext } from "react";
import { BrowserRouter as Router, Route, Switch, Link } from "react-router-dom";
import { useHistory } from "react-router-dom";

import styles from "./styles.module.css";
import { Collapse, Button, Badge } from "shards-react";

import NUMSLogo from "../../StaticImages/NUMS Logo.png";
import NTSLogo from "../../StaticImages/NTS Logo.png";
import UHSLogo from "../../StaticImages/UHS Logo.jpg";
import PMCLogo from "../../StaticImages/PMC Logo.png";
import AKULogo from "../../StaticImages/PreMedAKULogo.svg";
import ETEALogo from "../../StaticImages/etealogo.jpg";
import FMDCLogo from "../../StaticImages/fmdclogo.jpg";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";

import YearlyPageIcon from "../../StaticImages/YearlyPageIcon.svg";

import { GetYearlyDecks } from "../../APIManager";
import { UserContext } from "../../userContext";

import { VerifyMockAddon } from "../../MockUtlityFunctions";

const pmc2021Decks = [
  /*   "PMC Sample Solved Paper 1",
  "PMC Sample Solved Paper 2",
  "PMC Sample Solved Paper 3",
  "PMC Sample Solved Paper 4", */
  "PMC Practice Test 1",
  "PMC Practice Test 2",
  "PMC Practice Test 3",
  "PMC Practice Test 4",
  "PMC Practice Test 5",
  "PMC Practice Test 6",
  "PMC Practice Test 7",
  "PMC Practice Test 8",
  "PMC Practice Test 9",
  "PMC Practice Test 10",
  "PMC Practice Test 11",
  "PMC Practice Test 12",
  "PMC Practice Test 13",
  "PMC Practice Test 14",
  "PMC Practice Test 15",
  "PMC Practice Test 16",
  "PMC Practice Test 17",
  "PMC Practice Test 18",
  "PMC Practice Test 19",
  "PMC Practice Test 20",
  "PMC Practice Test 21",
  "PMC Practice Test 22",
  "PMC Practice Test 23",
  "PMC Practice Test 24",
  "PMC Practice Test 25",
  "PMC Practice Test 26",
  "PMC Practice Test 27",
  "PMC Practice Test 28",
  "PMC Practice Test 29",
  "PMC Practice Test 30",
  "PMC Practice Test 31",
  "PMC Practice Test 32",
  "PMC Practice Test 33",
  "PMC Practice Test 34",
  "PMC Practice Test 35",
  "PMC Practice Test 36",
];

const PMCPage = () => {
  let history = useHistory();
  let [userContext, SetUserContext] = useContext(UserContext);
  const [pmc2021PracticeCollapse, SetPmc2021PracticeCollapse] = useState(false);
  const [pmc2022PracticeCollapse, SetPmc2022PracticeCollapse] = useState(false);
  const [pmcCollapse, SetPmcCollapse] = useState(false);
  const isPremium = userContext.addonspurchased.includes("Premium");

  return (
    <div className={styles.GradientDiv}>
      <div className={styles.MainDiv}>
        <div className={styles.ContentDiv}>
          <div className={styles.PageHeader}>
            <h1>
              <span style={{ marginRight: "1em" }}>
                <img src={PMCLogo} />
              </span>
              <span> PMC MCQs</span>
            </h1>
            <h4>
              Welcome
              {userContext.fullname
                ? ` ${userContext.fullname.split(" ").slice(0, 2).join(" ")}! `
                : "! "}
              Attempt a Full-Length Yearly Paper today and experience the
              feeling of giving the exam on the actual test day
            </h4>
          </div>

          <div className={styles.GroupsContainer}>
            <div className={styles.Group}>
              <div
                className={styles.GroupHeader}
                onClick={() => {
                  SetPmc2022PracticeCollapse(!pmc2022PracticeCollapse);
                }}
              >
                <span className={styles.GroupName}>
                  PMC 2022 Practice Tests
                  <br />
                  <label
                    class={`${styles.comingSoonLabel} py-2 px-4`}
                    style={{ marginRight: "0.5em" }}
                  >
                    New Pattern
                  </label>
                  {/*  <label class={`${styles.comingSoonLabel} py-2 px-4`}>
                    Coming Soon
                  </label> */}
                </span>
                <div className={styles.ArrowContainer}>
                  <span
                    className={`${styles.ArrowIcon} ${
                      pmc2022PracticeCollapse
                        ? styles.ArrowUp
                        : styles.ArrowIcon
                    }`}
                    style={{ width: "fitContent" }}
                  >
                    <ArrowDropDownIcon
                      style={{ fontSize: "6em" }}
                      onClick={() => {
                        SetPmc2022PracticeCollapse(!pmc2022PracticeCollapse);
                      }}
                    />
                  </span>
                </div>
              </div>
              <Collapse open={pmc2022PracticeCollapse}>
                <div className={styles.PapersContainer}>
                  <div
                    key={"PMC Practice Test 1 2022"}
                    className={styles.Paper}
                  >
                    <div className={styles.PaperName}>
                      <Link
                        to={`/deck/attempt?deck=${"PMC Practice Test 1 (2022)"}`}
                        style={{ marginRight: "0.2em" }}
                      >
                        PMC Practice Test 1 2022
                      </Link>
                      <span
                        className={styles.PaperNameBadgeSpan}
                        hidden={isPremium}
                      >
                        {" "}
                        <label class={`${styles.freeLabel} py-2 px-4`}>
                          Free
                        </label>
                      </span>
                    </div>
                    <hr />
                  </div>
                  {Array(4)
                    .fill(0)
                    .map((a, index) => (
                      <div
                        key={`PMC Practice Test ${index + 2} 2022`}
                        className={styles.Paper}
                      >
                        <div className={styles.PaperName}>
                          <Link
                            to={`/deck/attempt?deck=${`PMC Practice Test ${
                              index + 2
                            } (2022)`}`}
                            style={{ marginRight: "0.2em" }}
                          >
                            {`PMC Practice Test ${index + 2} 2022`}{" "}
                          </Link>
                          <span
                            className={styles.PaperNameBadgeSpan}
                            hidden={isPremium}
                          >
                            {" "}
                            <label class={`${styles.premiumLabel} py-2 px-4`}>
                              Premium
                            </label>
                          </span>
                        </div>
                        <hr />
                      </div>
                    ))}{" "}
                </div>
              </Collapse>
            </div>
            <div className={styles.Group}>
              <div
                className={styles.GroupHeader}
                onClick={() => {
                  SetPmc2021PracticeCollapse(!pmc2021PracticeCollapse);
                }}
              >
                {/*  <img src={PMCLogo} className={styles.LogoImage} /> */}
                <span className={styles.GroupName}>
                  PMC 2021 Practice Tests <br />
                  <span className={styles.PaperNameBadgeSpan}>
                    {" "}
                    <label class={`${styles.comingSoonLabel} py-2 px-4`}>
                      Old Pattern
                    </label>
                  </span>
                </span>
                <div className={styles.ArrowContainer}>
                  <span
                    className={`${styles.ArrowIcon} ${
                      pmc2021PracticeCollapse
                        ? styles.ArrowUp
                        : styles.ArrowIcon
                    }`}
                    style={{ width: "fitContent" }}
                  >
                    <ArrowDropDownIcon
                      style={{ fontSize: "6em" }}
                      onClick={() => {
                        SetPmc2021PracticeCollapse(!pmc2021PracticeCollapse);
                      }}
                    />
                  </span>
                </div>
              </div>
              <Collapse open={pmc2021PracticeCollapse}>
                <div className={styles.PapersContainer}>
                  {pmc2021Decks
                    .filter((d) => d !== "PMC National MDCAT 2020")
                    .map((deck) => (
                      <div key={deck} className={styles.Paper}>
                        <div className={styles.PaperName}>
                          <Link
                            to={`/deck/attempt?deck=${deck}`}
                            style={{ marginRight: "0.2em" }}
                          >
                            {deck}
                          </Link>
                          <span
                            className={styles.PaperNameBadgeSpan}
                            hidden={isPremium}
                          >
                            {" "}
                            <label class={`${styles.premiumLabel} py-2 px-4`}>
                              Premium
                            </label>
                          </span>
                        </div>
                        <hr />
                      </div>
                    ))}
                  {/*      {Array(6)
                    .fill(0)
                    .map((a, index) => (
                      <div
                        key={`PMC Paper ${index + 31}`}
                        className={styles.Paper}
                      >
                        <div className={styles.PaperName}>
                          {`PMC Practice Test ${index + 31}`}{" "}
                          <span className={styles.PaperNameBadgeSpan}>
                            {" "}
                            <label
                              class={`${styles.comingSoonLabel} py-2 px-4`}
                            >
                              Coming Soon
                            </label>
                          </span>
                        </div>
                        <hr />
                      </div>
                    ))} */}
                </div>
              </Collapse>
            </div>
            <div className={styles.Group}>
              <div
                className={styles.GroupHeader}
                onClick={() => {
                  SetPmcCollapse(!pmcCollapse);
                }}
              >
                <span className={styles.GroupName}>
                  PMC MDCAT 2020
                  <br />
                  <span className={styles.PaperNameBadgeSpan}>
                    {" "}
                    <label class={`${styles.comingSoonLabel} py-2 px-4`}>
                      Old Pattern
                    </label>
                  </span>
                </span>
                <div className={styles.ArrowContainer}>
                  <span
                    className={`${styles.ArrowIcon} ${
                      pmcCollapse ? styles.ArrowUp : styles.ArrowIcon
                    }`}
                    style={{ width: "fitContent" }}
                  >
                    <ArrowDropDownIcon
                      style={{ fontSize: "6em" }}
                      onClick={() => {
                        SetPmcCollapse(!pmcCollapse);
                      }}
                    />
                  </span>
                </div>
              </div>
              <Collapse open={pmcCollapse}>
                <div className={styles.PapersContainer}>
                  <div key={"PMC National MDCAT 2020"} className={styles.Paper}>
                    <div className={styles.PaperName}>
                      <Link
                                            to={userContext.isloggedin ? `/deck/attempt?deck=${"PMC National MDCAT 2020"}` : `/attempt?deck=${"PMC National MDCAT 2020"}` }
                        style={{ marginRight: "0.2em" }}
                      >
                        PMC National MDCAT 2020
                      </Link>
                      <span
                        className={styles.PaperNameBadgeSpan}
                        hidden={isPremium}
                      >
                        {" "}
                        <label class={`${styles.freeLabel} py-2 px-4`}>
                          Free
                        </label>
                      </span>
                    </div>
                    <hr />
                  </div>
                </div>
              </Collapse>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PMCPage;
