import React, { useState, useEffect } from "react";
import {  withRouter } from "react-router";
import "bootstrap/dist/css/bootstrap.min.css";
import "shards-ui/dist/css/shards.min.css";
import {
  Button,
  Fade,
} from "shards-react";
import QuestionComponent from "./TestModeReviewQuestionComponent";
import { GetAttemptCalendar, GetMockDeckID } from "../../APIManager";
import { GetMockQuestions } from "../../APIManager";
import { GetMockQuestion } from "../../APIManager";


import styles from "./TestMode.module.css";


function QuizDeck(props) {
  const {testPaper, onBackCB} = props;   
 
  //Content Indentifiers / Query State
  let search = window.location.search;
  let params = new URLSearchParams(search);
  const [deckName, SetDeckName] = useState(props.deckName);
  const [questionNo, SetQuestionNo] = useState(null);
  const [questionID, SetQuestionID] = useState("");
  const [deckID, SetDeckID] = useState("");
  const [validDeck, setValidDeck] = useState("");
  const [validQuestion, SetValidQuestion] = useState("");
  const [questionList, SetQuestionList] = useState([]);
  const [lastAttemptNo, SetLastAttemptNo] = useState("");
  //Content Question State
  var fetchedDeckName = "";
  const [fetchedQuestionID, SetFetchedQuestionID] = useState("");
  const [questionTags, SetQuestionTags] = useState([]);
  const [questionContent, SetQuestionContent] = useState("");
  const [questionImage, SetQuestionImage] = useState("");
  const [questionOptions, SetQuestionOptions] = useState([]);
  const [explanationContent, SetExplanationContent] = useState("");
  const [explanationImage, SetExplanationImage] = useState("");
  //const[QuestionText , SetQuestionText] = useState("");




  //Control State
  const [questionSpecified, SetQuestionSpecified] = useState(false);
  const [showResumeQuestion, SetShowResumeQuestion] = useState(false);
  const [showStartPage, SetShowStartPage] = useState(false);
  const [showQuestionPage, SetShowQuestionPage] = useState(true);
  const [showAnswer, SetShowAnswer] = useState(false);
  const [nextActive, SetNextActive] = useState(true);
  const [previousActive, SetPreviousActive] = useState(false);


  if (deckName != params.get("deck")) {
    SetDeckName(params.get("deck"));
  }

  //This function only runs once and does so after the initlal render is complete
  //This function runs each time the URL query for deck name changes and also after the initlal render is complete
  useEffect(() => {
    //console.log(testPaper)
    if (deckName) {
      ValidateDeckName(deckName).then((valid) => {
        if (valid) {
          SetShowQuestionPage(true);
          SetShowStartPage(false);
          SetQuestionNo(1)
         // console.log("Start Screen");
        } else {
         // console.log("Invalid Deck Name");
        }
      });
    } else {
     // console.log("Deck name not specified");
    }
  }, [deckName]);


  function SetNavButtonStates(currentNo, length) {
    if (questionList.length != 0 && questionNo != 0) {
      if (currentNo == 1) {
        SetPreviousActive(false);
      } else {
        SetPreviousActive(true);
      }
      if (currentNo == length) {
        SetNextActive(false);
      } else {
        SetNextActive(true);
      }
    }
  }

  useEffect(() => {
    //console.log("use effect run" + questionNo);
    if (questionNo) {
      FetchQuestion(deckName, questionList[questionNo - 1]);
      SetNextActive(questionNo + 1 <= questionList.length);
      SetPreviousActive(questionNo - 1 > 0);
    }
  }, [questionNo]);

  async function FetchQuestion(name, qid) {
    if (qid == fetchedQuestionID) {
    } else {
      SetFetchedQuestionID(qid);
      let promise = await GetMockQuestion(name, qid);
      const data = promise.data;
      if (!data.Error) {
        SetQuestionContent(data.QuestionText);
        SetQuestionOptions(data.Options);
        SetQuestionTags(data.Tags);
        SetQuestionImage(data.QuestionImage);
        SetExplanationContent(data.ExplanationText);
        SetExplanationImage(data.ExplanationImage);
      }
    }
  }

  async function ValidateDeckName(name) {
    if (name.length === 0) {
      return false;
    } else {
      let promise = await GetMockDeckID(name);
      //await promise;
      const data = promise.data;
      if (data.Error) {
        return false;
      } else {
        let promise = await GetMockQuestions(name);
        var questionsIDs = promise.data;
        const data = promise.data;
        if (questionList != questionsIDs) {
          SetQuestionList([...questionsIDs]);
        }

        return true;
      }
    }
  }

  function NextQuestion() {
    const nextQuestion = parseInt(questionNo) + 1;
    ClearQuestionState();
    SetQuestionNo(nextQuestion);
  }

  function SkipToQuestion(no) {
    ClearQuestionState();
    SetQuestionNo(no);
  }

  function PreviousQuestion() {
    const previousQuestion = parseInt(questionNo) - 1;
    ClearQuestionState();
    SetQuestionNo(previousQuestion);
  }
  function ResumeLastQuestion() {
    const nextQuestion = lastAttemptNo;
    ClearQuestionState();
    SetQuestionNo(nextQuestion);
  }
  function ClearQuestionState() {
    SetQuestionContent("");
    SetQuestionOptions([]);
    SetQuestionTags([]);
    SetQuestionImage("");
  }


  return (
    <div class="animatedParent">
        <div
          className={`${styles.TestModeOptionPanel}`}
        >
          <div className={`${styles.TestModeOptions}`}>
            <Button
              size="lg"
              onClick={() => {
                onBackCB()
              }}
            >
              Go Back
            </Button>
          </div>
        </div>
        <Fade in={true}>
          <QuestionComponent
            ShowQuestion={showQuestionPage}
            QuestionNo={questionNo}
            DeckName={deckName}
            QuestionID={questionList[parseInt(questionNo) - 1]}
            QuestionContent={questionContent}
            QuestionOptions={questionOptions}
            QuestionTags={questionTags}
            QuestionImage={questionImage}
            ExplanationContent={explanationContent}
            ExplanationImage={explanationImage}
            TotalQuestions={questionList.length}
            NextActive={nextActive}
            PreviousActive={previousActive}
            NextCB={() => {
              NextQuestion();
            }}
            PreviousCB={() => {
              PreviousQuestion();
            }}
            SkipCB={SkipToQuestion}
            AttemptCB={(option) => {
             // console.log(option);

             // SetOptionAnswer(option.OptionLetter, option.IsCorrect);
              /*   SubmitAttempt({
                Option: option.OptionLetter,
                Correct: option.IsCorrect,
              }); */
            }}
            DeckType="Yearly"
            //IsTestPaused={testPaused}
            SelectedOption={
              testPaper[questionNo - 1]?.selectedOption
            }
            hideTags = {true}
          />
        </Fade>
    </div>
  );
}

export default withRouter(QuizDeck); //Exporting with router to ensure location and key are set as props

