import React, { useState } from "react";
import { BrowserRouter as Router, Route, Switch, Link } from "react-router-dom";
import styles from "./ContactUs.module.css";
import { Collapse, Button } from "shards-react";
import whatsapp from "../../StaticImages/Icons/whatsapp-fancy.svg";
import messenger from "../../StaticImages/Icons/messenger-fancy.svg";
import mail from "../../StaticImages/Icons/mail-fancy.svg";

import HeadImage from "../../StaticImages/Contact Us Page/ContactHeaderPic.png";

import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { QuestionAnswer } from "@material-ui/icons";

import useMediaQuery from "@material-ui/core/useMediaQuery";

const OurTeam = () => {
  const DeviceQuery450 = useMediaQuery("(max-width:450px)");
  return (
    <div>
      {/*    <div className={styles.Cover}>
        <div className={styles.Title}>
          <span>Get in touch with us</span>
        </div>
        <div className={styles.SubTitle}>
          <span>
            Want to get in touch? We'd love to hear from you. Here's how you can
            reach us...
          </span>
        </div>
      </div> */}

      <div className={styles.ImgContainer}>
        <div>
          {" "}
          {!DeviceQuery450 && (
            <h1 className={styles.spectrumUnderline}>
              We would love to hear from you
            </h1>
          )}
          {DeviceQuery450 && (
            <>
              <h1 className={styles.spectrumUnderline} style = 
              {{marginBottom : "0em"}}>We would love to</h1><br/>
              <h1 className={styles.spectrumUnderline}>hear from you</h1>
            </>
          )}
        </div>
        <img className={styles.HeadImage} src={HeadImage} />
      </div>
      <div className={styles.ContactBoxFlexContainer}>
        <div className={styles.ContactBoxMainContainer}>
          <div className={styles.ContactBoxBox}>
            <img className={styles.ContactBoxBoxIcon} src={whatsapp} />
            <span
              style={{ color: "#2bb140" }}
              className={styles.ContactBoxBoxHeading}
            >
              WhatsApp
            </span>
            <Button
              theme="success"
              onClick={() => {
                var win = window.open(
                  "https://api.whatsapp.com/send/?phone=923061289229&text&app_absent=0&lang=en",
                  "_blank"
                );
                win.focus();
              }}
            >
              Click to start a chat
            </Button>
          </div>
        </div>
        <div className={styles.ContactBoxMainContainer}>
          <div className={styles.ContactBoxBox}>
            <img className={styles.ContactBoxBoxIcon} src={messenger} />
            <span
              style={{ color: "#039dfd" }}
              className={styles.ContactBoxBoxHeading}
            >
              FB Messenger
            </span>
            <Button
              theme="primary"
              onClick={() => {
                var win = window.open(
                  "https://www.messenger.com/t/104600425163664/?messaging_source=source%3Apages%3Amessage_shortlink",
                  "_blank"
                );
                win.focus();
              }}
            >
              Click to start a chat
            </Button>
          </div>
        </div>
        <div className={styles.ContactBoxMainContainer}>
          <div className={styles.ContactBoxBox}>
            <img className={styles.ContactBoxBoxIcon} src={mail} />
            <span
              style={{ color: "#fba028" }}
              className={styles.ContactBoxBoxHeading}
            >
              Email
            </span>
            <Button
              theme="warning"
              onClick={() => {
                var win = window.open("mailto:contact@premed.pk", "_blank");
                win.focus();
              }}
            >
              contact@premed.pk
            </Button>
          </div>
        </div>
      </div>
      <div id="FAQ" name="FAQ" className={styles.ContactUsFAQTitle}>
        Frequently Asked Questions
      </div>
      <div className={styles.FAQContainer}>
        <FAQBox
          question={"What is PreMed.PK?"}
          answer={
            "PreMed.PK is an online Question Bank that aims to provide the future doctors of Pakistan with access to thousands of MDCAT MCQs. Our QBank offers past papers from ten different MDCAT conducting bodies including PMC, NUMS, NTS, UHS, ETE, and FMDC. It is our goal that every student across Pakistan has access to our online QBank irrespective of their location, family income or background and can enjoy studying at their convenience without the need to buy expensive courses and past paper books."
          }
        />
        <FAQBox
          question="Why should I choose PreMed.PK?"
          answer={
            <>
              1. Receive access to over 15,000+ Yearly & Topical Questions!
              <br />
              2. Get detailed explanation for every MCQ clarifying your
              concepts!
              <br />
              3.Attempt full-length papers in two different modes: Timed and
              Tutor Mode!
              <br />
              4. Save questions to review them later!
              <br />
              5. Based on your performances, get real-time Statistics &
              Performance Analysis to help you in gauging your weak areas!
              <br />
              6. Avoid travel time and expensive tuition expenditures by
              attempting our QBank on your laptop or smartphone, any time any
              place!
              <br />
              7. Get acclimatised to the new computerised pattern introduced by
              PMC for conducting the MDCAT by using our interactive test-taking
              interface!
            </>
          }
        />
        <FAQBox
          question="Is our QBank better than past paper books?"
          answer={
            <>
              1. We definitely believe that going digital is the way forward for
              the future. Not only does our QBank provide explanations for every
              individual question but it is also constantly updated with new
              questions everyday which the past paper books fail to do.
              <br />
              2. Our questions are carefully handpicked and thoroughly reviewed
              by students who have already undergone and aced the MDCAT. We
              ensure that a student using our QBank does not only cover every
              aspect of the syllabus but also grasps solid concepts.
              <br />
              3. Past paper books are often riddled with incorrect marking
              schemes which become a reason of confusion for many students. Our
              feature of reporting a question ensures all incorrect questions
              are corrected in a real-time fashion ensuring students learn the
              correct concepts at all times.
            </>
          }
        />
        <FAQBox
          question="Who are the people behind PreMed.PK?"
          answer={
            <>
              PreMed.PK is a startup launched by students of the Aga Khan
              University and Dow Medical College. We are a growing team of
              medical students from all over Pakistan. Visit the{" "}
              <Link to={`/our-team`}>Team</Link> page to find out more about us!
            </>
          }
        />
      </div>
    </div>
  );
};

export default OurTeam;
const FAQBox = ({ question, answer }) => {
  const [collapse, SetCollapse] = useState(false);
  return (
    <div>
      <div className={styles.FAQMainBox}>
        <div
          className={styles.FAQQuestionBox}
          onClick={() => {
            SetCollapse(!collapse);
          }}
        >
          <label>{collapse ? "-" : "+"}</label>
          <h2>{question}</h2>
        </div>
        <Collapse open={collapse}>
          <div className={styles.FAQContentContainer}>
            <div className={styles.FAQLine}> </div>
            <div className={styles.FAQContent}>{answer}</div>
          </div>
        </Collapse>
      </div>
    </div>
  );
};

/* const FAQBox = (props) => {
  const [collapse, SetCollapse] = useState(false);

  const { question, answer } = props;

  return (
    <div className={styles.FAQBox}>
      <div
        className={styles.FAQHeader}
        onClick={() => {
          SetCollapse(!collapse);
        }}
      >
        <div className={styles.FAQQuestion}>{question}</div>
        <div className={styles.ArrowContainer}>
          <span
            className={`${styles.ArrowIcon} ${
              collapse ? styles.ArrowUp : styles.ArrowIcon
            }`}
            style={{ width: "fitContent" }}
          >
            <ArrowDropDownIcon
              style={{ fontSize: "5em" }}
              onClick={() => {
                SetCollapse(!collapse);
              }}
            />
          </span>
        </div>
      </div>
      <div className={styles.FAQContentContainer}>
        <Collapse open={collapse}>
          <div className={styles.FAQContent}>{answer}</div>
        </Collapse>
      </div>
    </div>
  );
};
 */
