import React from "react";
import styles from "./Reviews.module.css";
import { ReviewsList } from "../HomePage/Reviews";
import ScrollAnimation from "react-animate-on-scroll";

export default function Reviews() {
  return (
    <div className = {styles.MainDiv}>
      <h1 className={`${styles.mainTitle}`}>
        <span className={styles.spectrumUnderline}>Students love PreMed.PK</span>
        <br />
       {/*  <span className={styles.spectrumUnderline}>You can see for yourself here</span> */}
      </h1>
      <br />
      <div className={styles.ReviewGrid}>
        {ReviewsList.map((r, index) => {
          return (
            <ScrollAnimation animateOnce duration={0.3 + ((index % 4) * 0.4 + Math.random())} animateIn="fadeInUp">
              <div
                className={styles.ReviewBox}
                style={{ backgroundColor: colors.RandomColor(index) }}
              >
                <div>{r.ReviewContent}</div>
                <br />
                <h2>{r.Name}</h2>
                <label>
                  {r.UniName} <br /> {r.UniClass}
                </label>
              </div>
            </ScrollAnimation>
          );
        })}
      </div>
    </div>
  );
}

var colors = [
  "#c58ef3",
  "#f27b93",
  "#6cc3f7",
  "#f38ece",
  "#46d0a0",
  "#f2c47b",
  "#866cf7",
  "#468fd0",
];

colors.RandomColor = function (index) {
  return this[seed[index]];
};

var seed = [
  3, 1, 6, 1, 4, 2, 7, 6, 3, 2, 4, 6, 5, 3, 4, 6, 5, 2, 5, 6, 4, 1, 5, 0, 5, 6,
  0, 7, 4, 3, 2, 6, 4, 7, 4, 5, 2, 6, 4, 3, 3, 7, 6, 1, 4, 3, 4, 2, 3, 6, 1, 3,
  2, 1, 3, 2, 6, 1, 3, 0, 2, 6, 1, 3, 0, 6, 4, 4, 6, 2, 2, 5, 1, 2, 4, 1, 7, 6,
  3, 6, 6, 6, 6, 1, 3, 7, 0, 1, 6, 0, 2, 4, 6, 6, 2, 6, 6, 4, 2, 2, 6, 4, 2, 6,
  1, 6, 3, 1, 2, 4, 7, 2, 7, 5, 4, 2, 5, 4, 1, 5, 4, 4, 1, 0, 3, 3, 6, 2, 5, 5,
  5, 0, 4, 3, 0, 1, 1, 4, 5, 4, 2, 1, 0, 5, 6, 7, 4, 1, 3, 2, 6, 5, 6, 6, 4, 2,
  1, 6, 0, 3, 1, 6, 6, 4, 5, 7, 3, 6, 3, 2, 1, 6, 1, 6, 1, 4, 4, 7, 6, 3, 2, 4,
  6, 5, 3, 4, 6, 5, 2, 5, 6, 4, 1, 5, 0, 5, 6, 0, 7, 4, 3, 2, 6, 4, 7, 4, 5, 2,
  6, 4, 3, 3, 7, 6, 1, 4, 3, 4, 2, 3, 6, 1, 3, 2, 1, 3, 2, 6, 1, 3, 0, 6, 4, 4,
  6, 2, 2, 5, 1, 2, 4, 1, 7, 6, 3, 6, 6, 6, 6, 1, 3, 7, 0, 1, 6, 0, 2, 4, 6, 6,
  2, 6, 6, 4, 2, 2, 6, 4, 2, 6, 1, 6, 3, 1, 2, 4, 7, 2, 7, 5, 4, 2, 5, 1, 4, 7,
  0, 1, 1, 7, 4, 3, 4, 0, 0, 2, 6, 2, 3, 2, 1, 5, 5, 2, 1, 5, 6, 0, 4, 0, 6, 4,
  5, 2, 6, 6, 0,
];
