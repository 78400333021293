import React from "react";
import hasnainimg from "../../StaticImages/TeamPhotos/Hasnain Pic.png";
import fahdimg from "../../StaticImages/TeamPhotos/Fahd Pic.png";
import illyunimg from "../../StaticImages/TeamPhotos/Illyun Pic.png";
import tahaimg from "../../StaticImages/TeamPhotos/Taha Pic.png";
import umarimg from "../../StaticImages/TeamPhotos/Umar Pic.png";
import nancyimg from "../../StaticImages/TeamPhotos/Nancy Pic.png";
import ahamadimg from "../../StaticImages/TeamPhotos/Ahmad Shahid Pic.png";
import danishimg from "../../StaticImages/TeamPhotos/Danish Pic.png";
import alishbaimg from "../../StaticImages/TeamPhotos/Alishba Anwar Pic.png";
import muinimg from "../../StaticImages/TeamPhotos/Mian Muin Pic.png";
import sibghaimg from "../../StaticImages/TeamPhotos/Sibgha Pic.png";
import hamadimg from "../../StaticImages/TeamPhotos/Hamad Pic.png";
import umerimg from "../../StaticImages/TeamPhotos/Umer Pic.png";
import saarahimg from "../../StaticImages/TeamPhotos/Saarah Pic.png";
import zaraimg from "../../StaticImages/TeamPhotos/Zara Pic.png";
import asadimg from "../../StaticImages/TeamPhotos/Asad Pic.png";
import anjaleenimg from "../../StaticImages/TeamPhotos/Anjaleen Pic.png";
import ehsanimg from "../../StaticImages/TeamPhotos/Ehsan Pic.png";
import ismailimg from "../../StaticImages/TeamPhotos/Ismail Pic.png";
import nazimg from "../../StaticImages/TeamPhotos/Naz Pic.png";
import shizaimg from "../../StaticImages/TeamPhotos/Shiza Pic.png";

import salviaimg from "../../StaticImages/TeamPhotos/Salvia Pic.png";
import shanzaeimg from "../../StaticImages/TeamPhotos/Sahnzae Pic.png";
import ridaimg from "../../StaticImages/TeamPhotos/Rida Pic.png";
import nehaimg from "../../StaticImages/TeamPhotos/Neha Pic.png";
import humnaimg from "../../StaticImages/TeamPhotos/Humna Pic.png";
import emanimg from "../../StaticImages/TeamPhotos/Eman Pic.png";
import adeenaimg from "../../StaticImages/TeamPhotos/Adeena Pic.png";

import womanimg from "../../StaticImages/TeamPhotos/WomanPic.png";

export var TeamMembers = [
  {
    Name: "Muhammad Hasnain Mankani",
    Role: "Co-Founder",
    Image: hasnainimg,
    Dept: "Core",
    Bio: (
      <>
        "Hasnain Mankani is a second year medical student at the Aga Khan
        University. He is super excited to work on this project of
        revolutionizing how PreMedical students study fot the MDCAT in Pakistan.
        <br />
        In his free time, Hasnain enjoys coding, swimming, and reading mystery
        thrillers. His favourite book is Origin by Dan Brown!"
      </>
    ),
    Socials: {
      Facebook: "https://www.facebook.com/hasnain.mankani/",
      Instagram: "https://www.instagram.com/hasnain_mankani/",
      Twitter: "https://twitter.com/hasnain_mankani",
    },
  },
  {
    Name: "Fahd Niaz Shaikh",
    Role: "Co-Founder",
    Image: fahdimg,
    Dept: "Core",
    Bio: (
      <>
        Fahd is a Year III medical student at Dow Medical College in Karachi. He
        has vowed to create Pakistan's foremost platform for turning future
        doctors into natural problem-solvers!
        <br />
        <br /> He loves to build stuff, play the guitar, and keep up with the
        world politics. When he is not working he is seen binge-sleeping and
        binge-eating.
      </>
    ),
    Socials: {
      Facebook: "https://www.facebook.com/fahd.niaz.92/",
      Instagram: "",
      Twitter: "",
    },
  },
  {
    Name: "Muhammad Umar Mahar",
    Role: "Chief Technology Officer",
    Image: umarimg,
    Bio: (
      <>
        Umar is a Year II medical student at The Aga Khan University. Umar is
        super passionate about merging the boundaries between technology,
        education, and medicine, and hopes that his efforts will help thousands
        of students become future doctors. <br />
        While usually found programming, he is also found tinkering with Arduino
        Robots or gaming on his custom-built Gaming PC.
        <br /> <br /> PS: Umar has a passion for rounded corners and is greatly
        inspired by Apple in his design approach
      </>
    ),
    Dept: "Core",
    Socials: {
      Facebook: "https://www.facebook.com/profile.php?id=100013544472061",
      Instagram: "https://www.instagram.com/_umarmahar_/",
      Twitter: "https://twitter.com/UmarMahar2001",
    },
  },
  {
    Name: "Taha Shaikh",
    Role: "General Manager",
    Image: tahaimg,
    Dept: "Core",
    Bio: (
      <>
        Taha is a first year medical student at the Aga Khan University in
        Karachi. He believes he can thrive in a growth-oriented environment and
        feels the concept behind PreMed.PK is exactly what he wanted! <br />
        <br />
        It is a platform made for providing an easy study pathway to Med-school
        aspirants, innovating it with wonderful ideas and then working
        tirelessly to hold hands of our team members and registered users and
        make their long journeys towards a prestigious profession swift and
        easier!{" "}
      </>
    ),
    Socials: {
      Facebook: "https://www.facebook.com/taha.shaikh.520",
      Instagram: "https://www.instagram.com/tahashaikh2002",
      Twitter: "https://twitter.com/ShaikhSahabT",
    },
  },
  /*   {
    Name: "Illyun Banani",
    Role: "Education Manager",
    Image: illyunimg,
    Dept: "Core",
    Bio: (
      <>
        Illiyun Banani is a first year student at the Aga Khan University.
        Having benefitted from the resources PreMed.PK had to offer in its first
        year, he decided to join the endeavor and contribute towards its growth,
        revolutionizing pre-medical education in the country!
        <br />
        <br /> In his free time, Illiyun likes to read up about aviation
        services and attempt as many Sudoku puzzles as possible!
      </>
    ),
    Socials: {
      Facebook: "https://www.facebook.com/Illiyun.Amin.Banani",
      Instagram: "https://www.instagram.com/illiyun.b/",
      Twitter: "",
    },
  }, */
  {
    Name: "Nancy Hemnani",
    Role: "HR Manager",
    Image: nancyimg,
    Dept: "Core",
    Bio: (
      <>
        "Nancy is a Cedar College Graduate and an aspiring medical student. When
        she is not working for PreMed.PK to make preparation for one of the
        toughest exams in a future doctor's life easier, she can be seen hanging
        upside down during Aerial Yoga or learning Sign Language to be an
        Interpreter.
        <br /> <br />
        In her free time, she also likes to go for a swim and be a bollywood
        fanatic. Nancy has always found a great sense of well-being by making
        community service an active part of her life. "
      </>
    ),
    Socials: {
      Facebook: "https://www.facebook.com/profile.php?id=100001485530489",
      Instagram: "https://www.instagram.com/nancyveerh/",
      Twitter: "https://twitter.com/eNVyHem",
    },
  },
  {
    Name: "Asad Fatimi",
    Role: "Chief Strategy Officer",
    Image: asadimg,
    Dept: "Core",
    Bio: <></>,
    Socials: {
      Facebook: "https://www.facebook.com/asad.fatimi",
      Instagram: "https://www.instagram.com/asadfatimi/",
      Twitter: "https://twitter.com/asad_fatimi",
    },
  },
  {
    Name: "Naz Elahi",
    Role: "Marketing Manager",
    Image: nazimg,
    Dept: "Core",
    Bio: (
      <>
        Naz is a first year medical student at the the Dow Medical College.
        Knowing how stressful the admission process is, Naz wants to play a part
        in making resources accessible to everyone. <br />
        When not studying, Naz likes catching up on tv shows and spending time
        with her family.
      </>
    ),
    Socials: {
      Facebook: "",
      Instagram: "",
      Twitter: "",
    },
  },
  /*   {
    Name: "Anjaleen Agrawalla",
    Role: "Senior Mentor",
    Bio: (
      <>
        Anjaleen is a first year medical student at the Aga Khan University. She
        is super excited to help out aspiring medical students get to their
        dream med-school.
        <br />
        <br /> In her free time, Anjaleen likes to spend time at the gym and
        read novels to de-stress.
      </>
    ),
    City: "Karachi",
    Dept: "HR",
    Image: anjaleenimg,
  }, */

  {
    Name: "Ahmad Shahid",
    Role: "Senior Editor",
    Image: ahamadimg,
    Dept: "Education",
    Bio: (
      <>
        "Ahmad is a first year medical student at the Dow Medical College in
        Karachi. His aim is to assist this venture, in its vision, to smooth the
        robust and draining admission process of medical universities, by using
        his rational thinking and analytical skills to aid the candidates in
        having the best preparation, possible. <br />
        <br />
        Ahmad is a big anime fan. You'll find him debating about how 'One Piece'
        is the epitome of epic fantasy."
      </>
    ),
    Socials: {
      Facebook: "https://www.facebook.com/ahmad.shahid.39395/",
      Instagram: "https://www.instagram.com/ahmagenta.03/",
      Twitter: "",
    },
  },
  {
    Name: "Syeda Alishba Anwer",
    Role: "Senior Mentor",
    Image: alishbaimg,
    Dept: "Education",
    Bio: (
      <>
        "Alishba is a first year medical student at the United Medical and
        Dental College in Karachi. Working at Premed.PK for the last 8 months
        has taught her professionalism the ability to innovate in every field of
        life. <br />
        <br />
        When she is free, Alishba passes the time by getting in touch with old
        friends and talking to them about literally everything!"
      </>
    ),
    Socials: {
      Facebook: "https://www.facebook.com/alishbaanwer123",
      Instagram: "https://www.instagram.com/alishba_anwer",
      Twitter: "https://twitter.com/alishba_anwer",
    },
  },
  {
    Name: "Mohammad Danish",
    Role: "Senior Editor",
    Image: danishimg,
    Dept: "Education",
    Bio: (
      <>
        Danish is a first year medical student at the Dow Medical College. He is
        thrilled to be a part of this project of helping students across
        Pakistan prepare for the MDCAT and thus, get into their desired medical
        universities.
        <br />
        <br /> When Danish is not trying to understand a chapter of Physiology
        from Guyton, he takes special interest in watching cricket and spending
        time with family.
      </>
    ),
    Socials: {
      Facebook: "https://www.facebook.com/profile.php?id=100041165166002",
      Instagram: "https://www.instagram.com/mdanish2002/",
      Twitter: "",
    },
  },
  {
    Name: "Muin Jamshed",
    Role: "Senior Content Reviewer",
    Image: muinimg,
    Dept: "Education",
    Bio: (
      <>
        "Muin is an A2 pre-medical student at Nixor College in Karachi. Working
        towards helping students has always been a part of his work ethic and he
        thoroughly enjoys doing this and much more at PreMed.PK.
        <br />
        <br /> When he's not studying or working, he cycles, swims, and watches
        a lot of psychological thrillers."
      </>
    ),
    Socials: {
      Facebook: "https://www.facebook.com/profile.php?id=100010838774307",
      Instagram: "https://www.instagram.com/mjamshed.18/",
      Twitter: "",
    },
  },
  {
    Name: "Salvia",
    Role: "Content Writer",
    Image: salviaimg,
    Dept: "Education",
    Bio: <></>,
    Socials: {
      Facebook: "",
      Instagram: "",
      Twitter: "",
    },
  },
  {
    Name: "Sibgha Alam",
    Role: "Content Writer",
    Image: sibghaimg,
    Dept: "Education",
    Bio: (
      <>
        "Sibgha is a final year pre-med A-Levels student from a small town in
        interior Sindh, FFC. She strives to make education accessible and equal
        for all, and in this pursuit, works at PreMed.PK.
        <br />
        <br /> When not writing poetry or reading tragic memoirs, you'll find
        her embroidering in a corner of her room listening to Taylor Swift on
        repeat – simultaneously, ranting about it all on her Instagram blog! "
      </>
    ),
    Socials: {
      Facebook: "https://www.facebook.com/profile.php?id=100075905240941",
      Instagram: "https://www.instagram.com/coloursandchaos_/",
      Twitter: "https://mobile.twitter.com/sibghaalam",
    },
  },
  {
    Name: "Faiqa",
    Role: "Junior Content Reviewer",
    Image: womanimg,
    Dept: "Education",
    Bio: <></>,
    Socials: {
      Facebook: null,
      Instagram: null,
      Twitter: null,
    },
  },
  {
    Name: "Eman Alamgir",
    Role: "Junior Editor",
    Image: emanimg,
    Dept: "Education",
    Bio: <></>,
    Socials: {
      Facebook: "",
      Instagram: "",
      Twitter: "",
    },
  },
  {
    Name: "Adeena Shafique",
    Role: "Senior Design Executive",
    Image: adeenaimg,
    Dept: "Marketing",
    Bio: <></>,
    Socials: {
      Facebook: "",
      Instagram: "",
      Twitter: "",
    },
  },
  {
    Name: "Hamad",
    Role: "Ambassador Program Manager",
    Image: hamadimg,
    Dept: "Marketing",
    Bio: (
      <>
        "Hamad is a pre-medical A-Levels student on a gap year from Sargodha! As
        a believer in e-learning, he is delighted to be on this journey of
        digitalizing the education system to make studying for the MDCAT easier
        for all pre-medical students.
        <br />
        <br /> Besides this, in his free time, you will probably find Hamad at
        the gym or at the basketball court or editing montages!"
      </>
    ),
    Socials: {
      Facebook: "https://www.facebook.com/hamadm3557",
      Instagram: "https://www.instagram.com/hamadhamadhamadhamadhamadhamad/",
      Twitter: "https://twitter.com/hamad_1738",
    },
  },
  {
    Name: "Eshan Chawla",
    City: "Karachi",
    Role: "Junior Marketing Executive",
    Dept: "HR",
    Image: ehsanimg,
    Bio: (
      <>
        Eshan is a first year medical student at AKU. He is super excited to
        help students from every corner of Pakistan, where education is not
        easily accessible and he strongly supports PreMed in acheiving that
        dream
        <br />
        <br /> In his free time he likes hitting the gym and staying updated on
        the latest news
      </>
    ),
  },
  {
    Name: "Ismail Khan",
    City: "Karachi",
    Role: "Junior Marketing Executive",
    Bio: (
      <>
        Ismail is a first year medical student at the Aga Khan University. His
        dream is to make studies easy for pre-medical students and support them
        in all aspects of their journey.
      </>
    ),
    Dept: "HR",
    Image: ismailimg,
  },
  {
    Name: "Shiza Saad",
    City: "Karachi",
    Role: "Design Executive",
    Bio: (
      <>
        Shiza is an O-Levels student studying at the Karachi Grammar School. As
        an aspiring premed student, she looks forward to working on this
        enterprise to learn essential skills such as leadership and
        communication. <br /> When free, you’ll find her engaged in digital art
        on her iPad or binging Gilmore girls. Her favourite book is The Fault in
        Our Stars by John Green.
      </>
    ),
    Dept: "Marketing",
    Image: shizaimg,
  },
  {
    Name: "Umer Ejaz",
    Role: "Junior Marketing Executive",
    Image: umerimg,
    Dept: "Marketing",
    Bio: (
      <>
        Umer is a second year FSc student at the Hamza Army Public School and
        College in Rawalpindi. He is super excited to work with everyone here
        and learn new skills and techniques.
        <br />
        <br />
        In his free time, Umer enjoys editing videos, making posters, watching
        anime and playing video games. His favourite anime is 'Anohana: The
        Flower we saw that day
      </>
    ),
    Socials: {
      Facebook: "https://www.facebook.com/omer.ejaz.52",
      Instagram: "https://www.instagram.com/umer.ej/",
      Twitter: "https://twitter.com/Umerej1",
    },
  },
  {
    Name: "Rida",
    Role: "Junior Editor",
    Image: ridaimg,
    Dept: "Education",
    Bio: <></>,
    Socials: {
      Facebook: "",
      Instagram: "",
      Twitter: "",
    },
  },
  {
    Name: "Humna Sajid",
    Role: "Sales Representative",
    Image: humnaimg,
    Dept: "Marketing",
    Bio: <></>,
    Socials: {
      Facebook: "",
      Instagram: "",
      Twitter: "",
    },
  },
  {
    Name: "Neha Manazir",
    Role: "Marketing and Outreach Executive",
    Image: nehaimg,
    Dept: "Marketing",
    Bio: <></>,
    Socials: {
      Facebook: "",
      Instagram: "",
      Twitter: "",
    },
  },
  {
    Name: "Saarah Saeed",
    Role: "Media and Outreach Executive",
    Image: saarahimg,
    Dept: "Marketing",
    Bio: (
      <>
        "Saarah Saeed is a pre-medical student at The Lyceum School in Karachi.
        She is super excited to be a part of this team to help the next
        generation of future doctors achieve their dreams of getting into
        medical universities.
        <br />
        <br />
        In her free time, Saarah enjoys baking, nature photography, reading
        about space, and binging superhero shows/movies. "
      </>
    ),
    Socials: {
      Facebook: "",
      Instagram: "https://www.instagram.com/saarah.03",
      Twitter: "",
    },
  },
  {
    Name: "Shanzae Amina",
    Role: "Sales Representative",
    Image: shanzaeimg,
    Dept: "Marketing",
    Bio: <></>,
    Socials: {
      Facebook: "",
      Instagram: "",
      Twitter: "",
    },
  },
  {
    Name: "Zunaira",
    Role: "Junior Marketing Executive",
    Image: womanimg,
    Dept: "Marketing",
    Bio: <></>,
    Socials: {
      Facebook: null,
      Instagram: null,
      Twitter: null,
    },
  },
  {
    Name: "Zara Sheikha",
    Role: "Junior Marketing Analyst",
    Image: zaraimg,
    Dept: "Marketing",
    Bio: (
      <>
        "Zara is in her second year of O-Levels, currently studying at the
        Karachi Grammar School. Her avid passion for sciences makes her an eager
        member of the team, and she looks forward to learning from and working
        with other experienced members of the team.
        <br />
        <br /> When she's not studying, Zara likes to go for a run at the local
        park or read sci-fi novels."
      </>
    ),
    Socials: {
      Facebook: "https://www.facebook.com/zara.sheikha.142",
      Instagram: "http://instagram.com/zara_sheikha_",
      Twitter: "",
    },
  },
];
