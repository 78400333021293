import React, { useState, useContext, useEffect } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { useLocation, useHistory } from "react-router-dom";
import axios from "axios";

import ListSubheader from "@material-ui/core/ListSubheader";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Collapse from "@material-ui/core/Collapse";
import Divider from "@material-ui/core/Divider";
import InboxIcon from "@material-ui/icons/MoveToInbox";
import DraftsIcon from "@material-ui/icons/Drafts";
import HomeIcon from "@material-ui/icons/Home";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import StarBorder from "@material-ui/icons/StarBorder";
import WatchLaterIcon from "@material-ui/icons/WatchLater";
import BookmarksIcon from "@material-ui/icons/Bookmarks";
import AssignmentIcon from "@material-ui/icons/Assignment";
import StatsIcon from "@material-ui/icons/Equalizer";
import LockIcon from "@material-ui/icons/Lock";
import SvgIcon from "@material-ui/core/SvgIcon";
import CalculatorIcon from "../../../../StaticImages/Icons/calculator.svg";
import AccountIcon from "@material-ui/icons/AccountCircle";
import NotificationsIcon from "@material-ui/icons/Notifications";

import Avatar from "@material-ui/core/Avatar";

import styles from "./SideBar.module.css";

import { UserContext } from "../../../../userContext";

import { red } from "@material-ui/core/colors";

const SideBar = () => {
  const [user, setUser] = useContext(UserContext);
  let history = useHistory();

  const isPremium = user.addonspurchased.includes("Premium");

  //console.log(user);

  const [open, setOpen] = React.useState(true);

  const handleClick = () => {
    setOpen(!open);
    history.push("/dashboard");
  };

  const location = useLocation();
  //console.log(location.pathname);

  const selectedColor = "rgba(286,88,99,0.3)";
  const textColor = "#EC5863";

  return (
    <div style={{ color: textColor }} className={styles.SideBar}>
      <List
        component="nav"
        aria-labelledby="nested-list-subheader"
        /*   subheader={
          <ListSubheader component="div" id="nested-list-subheader">
            Nested List Items
          </ListSubheader>
        } */
      >
        <ListItem>
          <ListItemIcon>
            <Avatar style={{ backgroundColor: "#c31622" }}>
              {user.fullname[0]}
            </Avatar>
          </ListItemIcon>

          <ListItemText>
            <span style={{ color: "black" }}>Welcome back,</span> <br />
            <span style={{ fontSize: "1.4em" }}>
              {user.fullname.split(" ").slice(0, 2).join(" ")}
            </span>
          </ListItemText>
        </ListItem>

        <hr style={{ borderColor: textColor }} />

        <ListItem
          button
          onClick={() => {
            history.push("/dashboard");
          }}
          style={
            location.pathname === "/dashboard"
              ? { backgroundColor: selectedColor }
              : null
          }
        >
          <ListItemIcon>
            <HomeIcon style={{ fill: textColor }} />
          </ListItemIcon>
          <ListItemText primary="My Dashboard" />
        </ListItem>
        <ListItem
          button
          onClick={() => {
            history.push(isPremium ? "/recent-activity" : "/plans");
          }}
          style={
            location.pathname === "/recent-activity"
              ? { backgroundColor: selectedColor }
              : null
          }
        >
          <ListItemIcon>
            {isPremium ? (
              <WatchLaterIcon style={{ fill: textColor }} />
            ) : (
              <LockIcon style={{ fill: textColor }} />
            )}
          </ListItemIcon>
          <ListItemText primary="My Recent Activity" />
        </ListItem>

        <ListItem
          button
          onClick={() => {
            history.push(isPremium ? "/saved-questions" : "/plans");
          }}
          style={
            location.pathname === "/saved-questions"
              ? { backgroundColor: selectedColor }
              : null
          }
        >
          <ListItemIcon>
            {isPremium ? (
              <BookmarksIcon style={{ fill: textColor }} />
            ) : (
              <LockIcon style={{ fill: textColor }} />
            )}
          </ListItemIcon>
          <ListItemText primary="My Saved Questions" />
        </ListItem>

        <ListItem
          button
          onClick={() => {
            history.push("/stats");
          }}
          style={
            location.pathname === "/stats"
              ? { backgroundColor: selectedColor }
              : null
          }
        >
          <ListItemIcon>
            <StatsIcon style={{ fill: textColor }} />
          </ListItemIcon>
          <ListItemText primary="My Stats" />
        </ListItem>
        <ListItem
          button
          onClick={() => {
            history.push("/my-notifications");
          }}
          style={
            location.pathname === "/my-notifications"
              ? { backgroundColor: selectedColor }
              : null
          }
        >
          <ListItemIcon>
            <NotificationsIcon style={{ fill: textColor }} />
          </ListItemIcon>
          <ListItemText primary="My Notifications" />
        </ListItem>

        <ListItem
          onClick={() => {
            history.push("/my-account");
          }}
          style={
            location.pathname === "/my-account"
              ? { backgroundColor: selectedColor }
              : null
          }
          button
        >
          <ListItemIcon>
            <AccountIcon style={{ fill: textColor }} />
          </ListItemIcon>
          <ListItemText primary="Account" />
        </ListItem>
      </List>
    </div>
  );
};

export default SideBar;
