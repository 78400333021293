import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { Alert, Badge } from "shards-react";
import "bootstrap/dist/css/bootstrap.min.css";
import "shards-ui/dist/css/shards.min.css";
import styles from "./TestModeDeckResult.module.css";
import CircularBar from "react-multicolor-circular-progress-bar";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import {
  Card,
  CardHeader,
  CardTitle,
  CardImg,
  CardBody,
  CardFooter,
  Button,
  Fade,
  FormRadio,
} from "shards-react";

import GradientBtn from "../GradientBtn/GradientBtn";

function QuizResultScreen({
  total,
  correct,
  skipped,
  wrong,
  totalTime,
  deckName,
  testPaper,
  isMock,
  OnReviewTestClicked,
  showNegativeMarking,
  negativeMarking,
}) {
  /*  var total = 200;
  var correct = 140;
  var skipped = 10;
  var wrong = 50; */

  const [showReviewScreen, SetShowReviewScreen] = useState(false);

  var scorePercentage =
    Math.round(((correct * 100 - negativeMarking * 100) / total) * 100) / 100;
  console.log(totalTime);
  var formattedTotalTime = new Date(totalTime * 1000)
    .toISOString()
    .substr(11, 8);
  var averageTimeTaken =
    correct + wrong > 0 ? totalTime / (correct + wrong) : 0;

  var circleBarConfig = GetCircleBarConfig(total, correct, skipped, wrong);

  const rows = !showNegativeMarking
    ? [
        createData("Total Questions", total),
        createData("Attempted Questions", correct + wrong),
        createData("Correct Questions", correct),
        createData("Wrong Questions", wrong),
        createData("Skipped Questions", skipped),
        createData(
          "Average Time Taken per Question",
          String(Math.round((averageTimeTaken * 10) / 10)) + " seconds"
        ),
        createData("Total Time Taken", formattedTotalTime),
      ]
    : [
        createData("Total Questions", total),
        createData("Attempted Questions", correct + wrong),
        createData("Correct Questions", correct),
        createData("Wrong Questions", wrong),
        createData("Negative Marking", -negativeMarking),
        createData("Skipped Questions", skipped),
        createData(
          "Average Time Taken per Question",
          String(Math.round((averageTimeTaken * 10) / 10)) + " seconds"
        ),
        createData("Total Time Taken", formattedTotalTime),
      ];

  return (
    <div>
      <div
        className={`${styles.MainDiv}`}
        style={showReviewScreen ? { display: "none" } : null}
      >
        <div className={`${styles.PageTitle}`}>{deckName}</div>
        <div className={`${styles.ButtonContainer}`}>
          <GradientBtn
            style={{ padding: "0.8em 2em", borderRadius: "30px" }}
            gradient={"#77A1D3 0%, #79CBCA  51%, #77A1D3  100%"}
            onClick={() => {
              OnReviewTestClicked();
            }}
          >
            Review My Answers
          </GradientBtn>
        </div>

        <div className={`${styles.CircleBarFlex}`}>
          <div className={`${styles.CircleBarBox}`}>
            <p className={`${styles.CircleBoxTitle}`}>
              Your Score: {correct - negativeMarking}/{total}
            </p>
            <div>
              <CircularBar
                scale={2}
                angleTransition={circleBarConfig.angleTransition}
                colors={circleBarConfig.colors}
                gapGradient={0}
                stroke={{ color: "#eeeeee", width: 10 }}
                title={{
                  name: `${scorePercentage}%`,
                  align: "middle",
                  fontWeight: "bold",
                  fontSize: "20",
                }}
                percent={{
                  value: 100,
                  showValue: false,
                }}
              />
            </div>
            <div className={`${styles.CircleBarLegend}`}>
              <div
                className={`${styles.CircleBarLegendColorBox}`}
                style={{ backgroundColor: "#00e040" }}
              ></div>
              Correct Questions: {correct}
            </div>
            <div className={`${styles.CircleBarLegend}`}>
              <div
                className={`${styles.CircleBarLegendColorBox}`}
                style={{ backgroundColor: "#eb2502" }}
              ></div>
              Wrong Questions: {wrong}
            </div>
            <div className={`${styles.CircleBarLegend}`}>
              <div
                className={`${styles.CircleBarLegendColorBox}`}
                style={{ backgroundColor: "#eeff00" }}
              ></div>
              Skipped Questions: {skipped}
            </div>
          </div>
          <div className={`${styles.CircleBarBox}`}>
            <p className={`${styles.CircleBoxTitle}`}>
              Target Score: {Math.round(total * 0.8)}/{total}
            </p>
            <div>
              <CircularBar
                scale={2}
                angleTransition={[0.8 * 360]}
                colors={["#00e040", "#c9c9c9"]}
                gapGradient={0}
                stroke={{ color: "#eeeeee", width: 10 }}
                title={{
                  name: `${80}%`,
                  align: "middle",
                  fontWeight: "bold",
                  fontSize: "20",
                }}
                percent={{
                  value: 100,
                  showValue: false,
                }}
              />
            </div>
            {/*      *of users on PreMed.pk */}
          </div>
        </div>
        <div className={`${styles.SummaryTableBox}`}>
          <p className={`${styles.SummaryBoxTitle}`}>Summary</p>
          <SummaryTable rows={rows} />
        </div>
      </div>
    </div>
  );
}

function GetAngles(total, correct, skipped, wrong) {
  //angleTransition={GetAngles(total, correct, skipped, wrong)}
  //colors={["#00e040", "#eeff00", "#eb2502"]}
  /*  var cAngle = (correct / total) * 360;
  var sAngle = cAngle + (skipped / total) * 360;
  var wAngle = sAngle + (skipped / total) * 360; */
}

function GetCircleBarConfig(total, correct, skipped, wrong) {
  /*  total = 100;
  correct = 50;
  skipped = 0;
  wrong = 50; */

  const CORRECT_COLOR = "#00e040";
  const WRONG_COLOR = "#eb2502";
  const SKIPPED_COLOR = "#eeff00";

  //Case 1 : C,S,W > 0  OR C , W > 0
  if (correct > 0 && wrong > 0) {
    var cAngle = (correct / total) * 360;
    var sAngle = cAngle + (skipped / total) * 360;
    return {
      angleTransition: [cAngle, sAngle],
      colors: [CORRECT_COLOR, SKIPPED_COLOR, WRONG_COLOR],
    };
  }
  //Case 2 : C == 0
  if (correct === 0 && wrong > 0 && skipped > 0) {
    var sAngle = (skipped / total) * 360;
    var wAngle = sAngle + (wrong / total) * 360;
    return {
      angleTransition: [sAngle],
      colors: [SKIPPED_COLOR, WRONG_COLOR],
    };
  }
  //Case 3 : C == 0 W==0
  if (correct === 0 && wrong === 0) {
    return {
      angleTransition: [359],
      colors: [SKIPPED_COLOR, SKIPPED_COLOR],
    };
  }
  //Case 4 : C ,S == 0 W > 0
  if (correct === 0 && skipped === 0 && wrong > 0) {
    return {
      angleTransition: [359],
      colors: [WRONG_COLOR, WRONG_COLOR],
    };
  }
  //Case 5 :  W ===0 C,S > 0
  if (wrong === 0 && skipped > 0 && correct > 0) {
    var cAngle = (correct / total) * 360;
    return {
      angleTransition: [cAngle],
      colors: [CORRECT_COLOR, SKIPPED_COLOR],
    };
  }
}

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

function createData(name, data) {
  return { name, data };
}

const useStyles = makeStyles({
  table: {
    minWidth: 300,
  },
});

export default QuizResultScreen;

function SummaryTable(props) {
  const { rows } = props;
  const classes = useStyles();

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="customized table">
        <TableBody>
          {rows.map((row) => (
            <StyledTableRow key={row.name}>
              <StyledTableCell component="th" scope="row">
                {row.name}
              </StyledTableCell>
              <StyledTableCell align="right">{row.data}</StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
