import React, { useContext, useState } from "react";
import { useHistory, useParams, withRouter } from "react-router";

import styles from "./PlansPage.module.css";

import { Modal, ModalBody, ModalHeader } from "shards-react";
import GradientBtn from "../../Components/GradientBtn/GradientBtn.jsx";
import ClearOutlinedIcon from "@material-ui/icons/ClearOutlined";

import PlansJumboGraphic from "../../StaticImages/PlansVectorEdited.png";
import tickIcon from "../../StaticImages/tick-icon.svg";
import { UserContext } from "../../userContext";

import bestValueRibbon from "../../StaticImages/bestvalueribbon.svg";

import { Link } from "react-router-dom";
import { isYandex } from "react-device-detect";

export default function PlansPage() {
  const history = useHistory();
  let [userContext, SetUserContext] = useContext(UserContext);
  console.log(userContext);

  const [modalOpen, SetModalOpen] = useState(false);
  const [modalConfig, SetModalConfig] = useState({});

  function OnPremiumClicked() {
    if (!userContext.addonspurchased.includes("NUMS Mock Bundle 1")) {
      SetModalConfig({
        Title: "PreMed.PK",
        Body: "Hey there👋 We’ve got a better deal for you! Avail our Premium Plan and 4 NUMS Mocks worth Rs. 3000 in just Rs. 1500.",
        OnOkayClick: () => {
          history.push("/checkout?sku=NUMS Mock Bundle 1");
          SetModalOpen(false);
        },
        ButtonTitle: "Avail Offer",
        NoThanksButtonVisible: true,
        onNoThanksClick: () => {
          SetModalOpen(false);
          history.push("/checkout");
        },
      });
      SetModalOpen(true);
    } else {
      history.push("/checkout");
    }
  }

  return (
    <div className={`${styles.mainContainer} m-0 pt-5`}>
      <PurchaseModal
        isOpen={modalOpen}
        OnClose={() => {
          SetModalOpen(false);
        }}
        config={modalConfig}
      />
      {/*    <div
        className="d-flex justify-content-center mb-5"
        style={{ marginBottom: "0em" }}
      >
        <img
          className={`${styles.jumboPic}`}
          src={PlansJumboGraphic}
          alt="nothing"
        />
      </div> */}
      {/*  <h1 className={`${styles.thinTitle}`}>Pricing Details</h1> */}
      <h1 className={`${styles.mainTitle}`}>BEST PLANS, AFFORDABLE PRICES</h1>
      <h1 className={`${styles.subTitle}`}>
        Making best education accessible to every aspiring doctor
      </h1>
      <br />
      <div className={`container ${styles.container} mb-5`}>
        {/* Check containers max width */}
        <div className="row">
          <div className="col-12 col-md-6 p-3">
            <div
              className={`${styles.pricingCard} ${styles.free} d-flex flex-column justify-content-between`}
            >
              <div>
                <h2 className={`${styles.cardTitle} mb-2`}>
                  {" "}
                  <span className={`${styles.strong}`}>Free</span> Plan
                </h2>
                <h2 className={`${styles.cardTitle} mb-0`}>
                  {" "}
                  <span className={`${styles.strong}`}>0 pkr </span>
                </h2>
                <p className={`${styles.cardSubTitle}`}>
                  Free Forever
                  <p className={`${styles.cardSubTitle2}`}>
                    This plan is automatically subscribed when you create an
                    account on PreMed.PK
                  </p>
                </p>

                <hr className={`${styles.cardHR}`} />
                <div>
                  <div className={`d-flex ${styles.CardTextRow}`}>
                    <i className={`bi bi-check ${styles.checkIcon}`}></i>
                    <p className={`${styles.cardText} mb-0`}>
                      Access to 1,500 questions
                    </p>
                  </div>
                  <div className={`d-flex ${styles.CardTextRow}`}>
                    <i className="bi bi-check checkIcon"></i>
                    <p className={`${styles.cardText} mb-0`}>
                      Access to 10 past paper questions per topic
                    </p>
                  </div>
                  <div className={`d-flex ${styles.CardTextRow}`}>
                    <i className="bi bi-check checkIcon"></i>
                    <p className={`${styles.cardText} mb-0`}>
                      Attempt 7 Full-Length Yearly Papers of each board
                      including PMC, UHS, NUMS, NTS, ETEA & FMDC.
                    </p>
                  </div>
                  <div className={`d-flex ${styles.CardTextRow}`}>
                    <i className="bi bi-check checkIcon"></i>
                    <p className={`${styles.cardText} mb-0`}>
                      Attempt Full-Length Yearly Paper in Tutor Mode only.
                    </p>
                  </div>
                  <div className={`d-flex ${styles.CardTextRow}`}>
                    <i className="bi bi-check checkIcon"></i>
                    <p className={`${styles.cardText} mb-0`}>
                      Access to the website for the entire MDCAT season for
                      Free!
                    </p>
                  </div>
                </div>
              </div>
              <a
                className={`${styles.actionButton} py-3`}
                onClick={() => {
                  if (!userContext.isLoggedIn) {
                    history.push({
                      pathname: "/login",
                    });
                  } else {
                  }
                }}
              >
                {userContext.isLoggedIn ? "ALREADY SUBSCRIBED" : "SIGN UP"}
              </a>
            </div>
          </div>
          <div className="col-12 col-md-6 p-3">
            <div
              className={`${styles.pricingCard} ${styles.premium} d-flex flex-column justify-content-between`}
            >
              <div>
                <h2 className={`${styles.cardTitle} mb-2`}>
                  {" "}
                  <span className={`${styles.strong}`}>Premium </span> Plan
                </h2>
                <h2 className={`${styles.cardTitle} mb-0`}>
                  {" "}
                  <span className={`${styles.strong}`}>
                    <span className={styles.PriceWrapper}>
                      <div className={styles.PriceSlash}></div> <div>4000</div>
                    </span>{" "}
                    2000 pkr
                  </span>
                </h2>
                <p className={`${styles.cardSubTitle}`}>
                  Most Popular{" "}
                  <p className={`${styles.cardSubTitle2}`}>
                    This plan includes one-time purchase fees and will remain
                    valid till the MDCAT 2023 session ends!
                  </p>
                </p>

                <hr className={`${styles.cardHR}`} />
                <div>
                  <div className={`d-flex ${styles.CardTextRow}`}>
                    <i className="bi bi-check checkIcon"></i>
                    <p className={`${styles.cardText} mb-0`}>
                      Access to 34,000+ questions wit detailed explanations
                    </p>
                  </div>

                  <div className={`d-flex ${styles.CardTextRow}`}>
                    <i className="bi bi-check checkIcon"></i>
                    <p className={`${styles.cardText} mb-0`}>
                      Attempt 80+ Full-Length Yearly Papers of all provincial
                      MDCAT boards including PMC, NUMS, UHS, DUHS, ETEA, SZABMU
                      & BUMHS.
                    </p>
                  </div>
                  <div className={`d-flex ${styles.CardTextRow}`}>
                    <i className="bi bi-check checkIcon"></i>
                    <p className={`${styles.cardText} mb-0`}>
                      Attempt Full-Length Yearly Paper in Tutor and Timed Test
                      Modes.{" "}
                    </p>
                  </div>
                  <div className={`d-flex ${styles.CardTextRow}`}>
                    <i className="bi bi-check checkIcon"></i>
                    <p className={`${styles.cardText} mb-0`}>
                      Save any question and attempt it later
                    </p>
                  </div>
                  <div className={`d-flex ${styles.CardTextRow}`}>
                    <i className="bi bi-check checkIcon"></i>
                    <p className={`${styles.cardText} mb-0`}>
                      Access to real-time statistics allowing you to gauge your
                      performance
                    </p>
                  </div>
                  <div className={`d-flex ${styles.CardTextRow}`}>
                    <i className="bi bi-check checkIcon"></i>
                    <p className={`${styles.cardText} mb-0`}>
                      Access to Recent Activity to revisit your attempted
                      questions
                    </p>
                  </div>
                </div>
              </div>

              <a
                className={`${styles.actionButton} py-3`}
                onClick={() => {
                  if (!userContext.isLoggedIn) {
                    history.push({
                      pathname: "/login",
                    });
                  } else {
                    if (userContext.addonspurchased.includes("Premium")) {
                      history.push({
                        pathname: "/dashboard",
                      });
                      return;
                    } else {
                      history.push({
                        pathname: "/checkout",
                      });
                    }
                    //OnPremiumClicked();
                  }
                }}
              >
                {userContext.addonspurchased.includes("Premium")
                  ? "ALREADY PURCHASED"
                  : "PURCHASE"}
              </a>
            </div>
          </div>
          <div hidden className="col-12 col-md-4 p-3">
            {/*        <div
              className={`${styles.pricingCard} ${styles.mockBundle3} d-flex flex-column justify-content-between`}
            >
              <div>
                <h2 className={`${styles.cardTitle} mb-2`}>
                  {" "}
                  <span className={`${styles.strong}`}>NUMS Mock</span> Bundle
                </h2>
                <h2 className={`${styles.cardTitle} mb-0`}>
                  {" "}
                  <span className={`${styles.strong}`}>1000 pkr</span>
                </h2>
                <p className={`${styles.cardSubTitle}`}>
                  Best Value
                  <p className={`${styles.cardSubTitle2}`}>
                    This plan includes one-time purchase fees and will remain
                    valid till the NUMS Exam.
                  </p>
                </p>

                <hr className={`${styles.cardHR}`} />
                <div>
                  <div className={`d-flex ${styles.CardTextRow}`}>
                    <i className="bi bi-check checkIcon"></i>
                    <p className={`${styles.cardText} mb-0`}>
                      Access to 1 Free and 3 paid NUMS Mocks
                    </p>
                  </div>
                  <div className={`d-flex ${styles.CardTextRow}`}>
                    <i className="bi bi-check checkIcon"></i>
                    <p className={`${styles.cardText} mb-0`}>
                      Practice MCQs according to latest 2022 NUMS syllabus
                    </p>
                  </div>
                  <div className={`d-flex ${styles.CardTextRow}`}>
                    <i className="bi bi-check checkIcon"></i>
                    <p className={`${styles.cardText} mb-0`}>
                      Attempt these mocks in Timed Test Mode according to real
                      NUMS Exam timing of 3 hours for Section A and B.
                    </p>
                  </div>
                  <div className={`d-flex ${styles.CardTextRow}`}>
                    <i className="bi bi-check checkIcon"></i>
                    <p className={`${styles.cardText} mb-0`}>
                      Attempt Psychological Reasoning Questions
                    </p>
                  </div>
                  <div className={`d-flex ${styles.CardTextRow}`}>
                    <i className="bi bi-check checkIcon"></i>
                    <p className={`${styles.cardText} mb-0`}>
                      Flag any question and review it later
                    </p>
                  </div>
                  <div className={`d-flex ${styles.CardTextRow}`}>
                    <i className="bi bi-check checkIcon"></i>
                    <p className={`${styles.cardText} mb-0`}>
                      Detailed scorecard and performance analysis
                    </p>
                  </div>
                  <div className={`d-flex ${styles.CardTextRow}`}>
                    <i className="bi bi-check checkIcon"></i>
                    <p className={`${styles.cardText} mb-0`}>
                      Detailed solutions for every MCQ after finishing your mock
                      test
                    </p>
                  </div>
                </div>
              </div>

              <a
                className={`${styles.actionButton} py-3`}
                onClick={() => {
                  if (!userContext.isLoggedIn) {
                    history.push({
                      pathname: "/login",
                    });
                  } else {
                    if (userContext.addonspurchased.includes("NUMS Mock Bundle")) {
                      history.push({
                        pathname: "/dashboard",
                      });
                      return;
                    }
                    history.push("/checkout?sku=NUMS Mock Bundle");
                  }
                }}
              >
                {userContext.addonspurchased.includes("NUMS Mock Bundle")
                  ? "ALREADY PURCHASED"
                  : "PURCHASE"}
              </a>
            </div> */}
            <div
              className={`${styles.MockBundleContainer} d-flex flex-column justify-content-between`}
            >
              <MockBundleCard
                h1Bold="NUMS"
                h1="Mock Bundle 1"
                h2={
                  <>
                    <i className="bi bi-check checkIcon"></i> Access to 1 Free,
                    3 Paid NUMS Mocks & Subscription of 23,000+ MCQs & all
                    Premium features.
                  </>
                }
                price={
                  <>
                    {" "}
                    <span className={styles.PriceWrapper}>
                      <div className={styles.PriceSlash}></div> <div>3000</div>
                    </span>{" "}
                    1500 PKR
                  </>
                }
                buttonText={
                  userContext.addonspurchased.includes("NUMS Mock Bundle 1")
                    ? "Purchased"
                    : "Purchase"
                }
                sku="NUMS Mock Bundle 1"
                toLink="/checkout?sku=NUMS Mock Bundle 1"
                index={3}
              />
              <MockBundleCard
                h1Bold="NUMS"
                h1="Mock Bundle 2"
                h2={
                  <>
                    Access 1 Free and 3 Paid NUMS Mocks prepared according to
                    NUMS Syllabus.
                  </>
                }
                price={
                  <>
                    {" "}
                    <span className={styles.PriceWrapper}>
                      <div className={styles.PriceSlash}></div> <div>2000</div>
                    </span>{" "}
                    1000 PKR
                  </>
                }
                buttonText={
                  userContext.addonspurchased.includes("NUMS Mock Bundle 2")
                    ? "Purchased"
                    : "Purchase"
                }
                sku="NUMS Mock Bundle 2"
                toLink="/checkout?sku=NUMS Mock Bundle 2"
                index={1}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <h1 className={`${styles.strong} ${styles.subTitle}`}>
          We believe that financial issues should never be an obstacle for
          obtaining quality education
        </h1>
        <h1 className={`${styles.subTitle} mb-4`}>
          Apply for the PreMed.PK scholarship program today!
        </h1>

        <a
          href=""
          className={`${styles.extraButton} mb-5`}
          onClick={() => {
            window
              .open("https://forms.gle/ThSVBAcBabz1v1vh8", "_blank")

              .focus();
          }}
        >
          Click here to apply
        </a>
      </div>
      {/*    <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSfu4PT28qx6ypjMlgZ6R5iBilDE60Ht_0siMlbK8pYAlQ-irA/viewform?embedded=true" width="100%" height="974" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe> */}

      <div className={`${styles.spacer}`}></div>
    </div>
  );
}

const MockBundleCard = ({
  h1Bold,
  h1,
  h2,
  price,
  buttonText,
  toLink,
  ribbon,
  sku,
  index,
}) => {
  const history = useHistory();
  let [userContext, SetUserContext] = useContext(UserContext);
  const [modalOpen, SetModalOpen] = useState(false);
  const [modalConfig, SetModalConfig] = useState({});

  function UserHas(sku) {
    return userContext.addonspurchased.includes(sku);
  }

  function ButtonOnClickHandler() {
    /*     if (sku === "AKU Mock Bundle 1") {
      if (UserHas("AKU Mock Bundle 1")) {
        return;
      }
      if (UserHas("AKU Mock Bundle 4")) {
        SetModalConfig({
          Title: "PreMed.PK",
          Body: "You have already bought Mock Bundle 4, which includes all AKU Mocks and Premium Access",
          OnOkayClick: () => {
            SetModalOpen(false);
          },
          ButtonTitle: "Okay",
        });
        SetModalOpen(true);
        return;
      }
      if (!UserHas("Premium")) {
        SetModalConfig({
          Title: "PreMed.PK",
          Body: "Would you like to buy AKU Mock Bundle 4 instead and save Rs. 500! For only Rs. 2000 you can avail all AKU Mocks and Premium Access",
          OnOkayClick: () => {
            history.push("/checkout?sku=AKU Mock Bundle 4");
            SetModalOpen(false);
          },
          ButtonTitle: "Avail Offer",
          NoThanksButtonVisible: true,
          onNoThanksClick: () => {
            history.push("/checkout?sku=AKU Mock Bundle 1");
            SetModalOpen(false);
          },
        });
        SetModalOpen(true);
        return;
      }

      history.push(toLink);
    }
    if (sku === "AKU Mock Bundle 2") {
      if (UserHas("AKU Mock Bundle 2")) {
        return;
      }
      if (UserHas("AKU Mock Bundle 1")) {
        SetModalConfig({
          Title: "PreMed.PK",
          Body: "You have already bought Mock Bundle 1, which includes all AKU Mocks",
          OnOkayClick: () => {
            SetModalOpen(false);
          },
          ButtonTitle: "Okay",
        });
        SetModalOpen(true);
        return;
      }
      if (UserHas("AKU Mock Bundle 4")) {
        SetModalConfig({
          Title: "PreMed.PK",
          Body: "You have already bought Mock Bundle 4, which includes all AKU Mocks and Premium Access",
          OnOkayClick: () => {
            SetModalOpen(false);
          },
          ButtonTitle: "Okay",
        });
        SetModalOpen(true);
        return;
      }
      if (UserHas("AKU Mock Bundle 3") && !UserHas("Premium")) {
        SetModalConfig({
          Title: "PreMed.PK",
          Body: "Would you like to buy AKU Mock Bundle 4 instead and save Rs. 500! For only Rs. 2000 you can avail all AKU Mocks and Premium Access",
          OnOkayClick: () => {
            history.push("/checkout?sku=AKU Mock Bundle 4");
            SetModalOpen(false);
          },
          ButtonTitle: "Avail Offer",
          NoThanksButtonVisible: true,
          onNoThanksClick: () => {
            history.push("/checkout?sku=AKU Mock Bundle 2");
            SetModalOpen(false);
          },
        });
        SetModalOpen(true);
        return;
      }
      history.push(toLink);
    }

    if (sku === "AKU Mock Bundle 3") {
      if (UserHas("AKU Mock Bundle 3")) {
        return;
      }
      if (UserHas("AKU Mock Bundle 1")) {
        SetModalConfig({
          Title: "PreMed.PK",
          Body: "You have already bought Mock Bundle 1, which includes all AKU Mocks",
          OnOkayClick: () => {
            SetModalOpen(false);
          },
          ButtonTitle: "Okay",
        });
        SetModalOpen(true);
        return;
      }
      if (UserHas("AKU Mock Bundle 4")) {
        if (UserHas("AKU Mock Bundle 4")) {
          return;
        }
        SetModalConfig({
          Title: "PreMed.PK",
          Body: "You have already bought Mock Bundle 4, which includes all AKU Mocks and Premium Access",
          OnOkayClick: () => {
            SetModalOpen(false);
          },
          ButtonTitle: "Okay",
        });
        SetModalOpen(true);
        return;
      }

      if (UserHas("AKU Mock Bundle 2") && !UserHas("Premium")) {
        SetModalConfig({
          Title: "PreMed.PK",
          Body: "Would you like to buy AKU Mock Bundle 4 instead and save Rs. 500! For only Rs. 2000 you can avail all AKU Mocks and Premium Access",
          OnOkayClick: () => {
            history.push("/checkout?sku=AKU Mock Bundle 4");
            SetModalOpen(false);
          },
          ButtonTitle: "Avail Offer",
          NoThanksButtonVisible: true,
          onNoThanksClick: () => {
            history.push("/checkout?sku=AKU Mock Bundle 3");
            SetModalOpen(false);
          },
        });
        SetModalOpen(true);
        return;
      }
      history.push(toLink);
    }

    if (sku === "AKU Mock Bundle 4") {
      if (UserHas("AKU Mock Bundle 1")) {
        if (UserHas("Premium")) {
          SetModalConfig({
            Title: "PreMed.PK",
            Body: "You have already bought Mock Bundle 1, which includes all AKU Mocks and you have Premium Access",
            OnOkayClick: () => {
              SetModalOpen(false);
            },
            ButtonTitle: "Okay",
          });
          SetModalOpen(true);
          return;
        } else {
          SetModalConfig({
            Title: "PreMed.PK",
            Body: "You have already bought AKU Mock Bundle 1 which includes all AKU Mocks. Would you instead like to buy our Premium Plans for only Rs. 1000",
            OnOkayClick: () => {
              history.push("/checkout?sku=Premium");
              SetModalOpen(false);
            },
            ButtonTitle: "Avail Offer",
            NoThanksButtonVisible: true,
            onNoThanksClick: () => {
              SetModalOpen(false);
            },
          });
          SetModalOpen(true);
          return;
        }
      }
      if (!UserHas("AKU Mock Bundle 1") && UserHas("Premium")) {
        SetModalConfig({
          Title: "PreMed.PK",
          Body: "Hey, it looks like you already have Premium, would you like to instead buy AKU Mock Bundle 1 which includes all AKU Mocks for only Rs. 1500",
          OnOkayClick: () => {
            history.push("/checkout?sku=AKU Mock Bundle 1");
            SetModalOpen(false);
          },
          ButtonTitle: "Avail Offer",
          NoThanksButtonVisible: true,
          onNoThanksClick: () => {
            SetModalOpen(false);
          },
        });
        SetModalOpen(true);
        return;
      }

      if (UserHas("AKU Mock Bundle 2") && UserHas("AKU Mock Bundle 3")) {
        if (UserHas("Premium")) {
          SetModalConfig({
            Title: "PreMed.PK",
            Body: "You have already bought Mock Bundle 2 and 3 , which includes all AKU Mocks and you already have Premium Access ",
            OnOkayClick: () => {
              SetModalOpen(false);
            },
            ButtonTitle: "Okay",
          });
          SetModalOpen(true);
          return;
        } else {
          SetModalConfig({
            Title: "PreMed.PK",
            Body: "You have already bought AKU Mock Bundle 2 and 3 which includes all AKU Mocks. Would you instead like to buy our Premium Plans for only Rs. 1000",
            OnOkayClick: () => {
              history.push("/checkout?sku=Premium");
              SetModalOpen(false);
            },
            ButtonTitle: "Avail Offer",
            NoThanksButtonVisible: true,
            onNoThanksClick: () => {
              SetModalOpen(false);
            },
          });
          SetModalOpen(true);
          return;
        }
      }
      history.push(toLink);
    }
 */

    if (sku === "NUMS Mock Bundle 2") {
      if (UserHas("NUMS Mock Bundle 2")) {
        return;
      }
      if (UserHas("NUMS Mock Bundle 1")) {
        SetModalConfig({
          Title: "PreMed.PK",
          Body: "You have already bought NUMS Mock Bundle 1, which includes all NUMS Mocks and Premium Access",
          OnOkayClick: () => {
            SetModalOpen(false);
          },
          ButtonTitle: "Okay",
        });
        SetModalOpen(true);
        return;
      }
      if (!UserHas("Premium")) {
        SetModalConfig({
          Title: "PreMed.PK",
          Body: "Hey there👋 We’ve got a better deal for you! Avail our Premium Plan and 4 NUMS Mocks worth Rs. 4000 in just Rs. 2000.",
          OnOkayClick: () => {
            history.push("/checkout?sku=NUMS Mock Bundle 1");
            SetModalOpen(false);
          },
          ButtonTitle: "Avail Offer",
          NoThanksButtonVisible: true,
          onNoThanksClick: () => {
            history.push("/checkout?sku=NUMS Mock Bundle 2");
            SetModalOpen(false);
          },
        });
        SetModalOpen(true);
        return;
      }
      history.push(toLink);
    }

    if (sku === "NUMS Mock Bundle 1") {
      if (UserHas("NUMS Mock Bundle 2")) {
        if (UserHas("Premium")) {
          SetModalConfig({
            Title: "PreMed.PK",
            Body: "You have already bought NUMS Mock Bundle 2, which includes all AKU Mocks and you have purchased Premium Access",
            OnOkayClick: () => {
              SetModalOpen(false);
            },
            ButtonTitle: "Okay",
          });
          SetModalOpen(true);
          return;
        } else {
          SetModalConfig({
            Title: "PreMed.PK",
            Body: "You have already bought NUMS Mock Bundle 2 which includes all AKU Mocks. Would you instead like to buy our Premium Plans for only Rs. 1000",
            OnOkayClick: () => {
              history.push("/checkout?sku=Premium");
              SetModalOpen(false);
            },
            ButtonTitle: "Avail Offer",
            NoThanksButtonVisible: true,
            onNoThanksClick: () => {
              SetModalOpen(false);
            },
          });
          SetModalOpen(true);
          return;
        }
      }
      if (!UserHas("NUMS Mock Bundle 1") && UserHas("Premium")) {
        SetModalConfig({
          Title: "PreMed.PK",
          Body: "Hey, it looks like you already have Premium, would you like to instead buy NUMS Mock Bundle 2 which includes all AKU Mocks for only Rs. 1000",
          OnOkayClick: () => {
            history.push("/checkout?sku=NUMS Mock Bundle 2");
            SetModalOpen(false);
          },
          ButtonTitle: "Avail Offer",
          NoThanksButtonVisible: true,
          onNoThanksClick: () => {
            SetModalOpen(false);
          },
        });
        SetModalOpen(true);
        return;
      }
      history.push(toLink);
    }
  }

  return (
    <>
      <PurchaseModal
        isOpen={modalOpen}
        OnClose={() => {
          SetModalOpen(false);
        }}
        config={modalConfig}
      />
      <div
        className={`${styles.pricingCard} ${styles.mockBundle} ${
          styles["mockBundle" + index]
        }`}
      >
        <div>
          <img
            hidden={!ribbon}
            src={bestValueRibbon}
            className={styles.CardRibbon}
          ></img>
          <h2 className={`${styles.cardTitle} mb-2`}>
            {" "}
            <span className={`${styles.strong}`}>{h1Bold}</span> {h1}
          </h2>
          <h2 className={`${styles.cardTitle} mb-0`}>
            {" "}
            <span className={`${styles.strong}`}>{price}</span>
          </h2>
          <hr className={`${styles.cardHR}`} />
          <p className={`${styles.cardSubTitle} ${styles.MockTickMark}`}>
            {h2}
          </p>
        </div>

        <div
          onClick={ButtonOnClickHandler}
          className={`${styles.actionButtonMock} py-3`}
        >
          {buttonText}
        </div>
      </div>
    </>
  );
};

const PurchaseModal = ({ isOpen, OnClick, onClose, config }) => {
  return (
    <Modal
      open={isOpen}
      toggle={() => {
        if (config.NoThanksButtonVisible) {
          config.onNoThanksClick();
        } else {
          config.OnOkayClick();
        }
      }}
    >
      <ModalHeader className="ShardsModalClosable">
        {config.Title}
        <div className={"ShardsModalCloseBtn"}>
          <ClearOutlinedIcon
            onClick={() => {
              if (config.NoThanksButtonVisible) {
                config.onNoThanksClick();
              } else {
                config.OnOkayClick();
              }
            }}
          />
        </div>
      </ModalHeader>
      <ModalBody>
        <div
          style={{
            fontSize: "1.2em",
            textAlign: "center",
            fontFamily: "Rubik",
          }}
        >
          {config.Body}
          <br />
          <br />
          <GradientBtn
            style={{
              fontSize: "0.7em",
              borderRadius: "30px",
              margin: 0,
            }}
            gradient={"#D31027 0%, #EA384D  51%, #D31027  100%"}
            onClick={config.OnOkayClick}
          >
            {config.ButtonTitle}
          </GradientBtn>
          <div
            hidden={!config.NoThanksButtonVisible}
            onClick={config.onNoThanksClick}
          >
            No, Thanks
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};
