import React, { useState, useContext, useEffect } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import axios from "axios";

import styles from "./MyStats.module.css";
import DoctorCoat from "../../../../StaticImages/doctor-coat.svg";

import { Progress } from "shards-react";

import { GetTotalQuestions } from "../../../../APIManager";
import {
  GetUniqueQuestionsAttempted,
  GetTotalQuestionsAttempted,
  GetCorrectQuestionsAttempted,
  GetStatsBySubject,
  GetAverageTimeTaken,
  GetAttemptCalendar,
} from "../../../../APIManager";

import GaugeChart from "react-gauge-chart";

import CountUp from "react-countup";
import { ResponsiveRadar } from "@nivo/radar";
import { ResponsiveCalendar } from "@nivo/calendar";
import { ResponsiveBar } from "@nivo/bar";

const MyStats = () => {
  const [totalQuestions, SetTotalQuestions] = useState(0);
  const [uniqueQuestions, SetUniqueQuestions] = useState(0);
  const [totalAttempts, SetTotalAttempts] = useState(1);
  const [correctAttempts, SetCorrectAttempts] = useState(0);

  const [bioStats, SetBioStats] = useState({});
  const [chemStats, SetChemStats] = useState({});
  const [physStats, SetPhysStats] = useState({});
  const [engStats, SetEngStats] = useState({});
  const [lrStats,SetLrStats] = useState({})

  const [timeTaken, SetTimeTaken] = useState(0);

  const [attemptCalendar, SetAttemptCalendar] = useState([]);

  const data = [
    {
      taste: "Biology",
      Prepareness: 99,
    },
    {
      taste: "Chemistry",
      Prepareness: 84,
    },
    {
      taste: "Physics",
      Prepareness: 69,
    },
    {
      taste: "English",
      Prepareness: 30,
    },
  ];

  useEffect(() => {
    GetTotalQuestions().then((res) => {
      SetTotalQuestions(res.data[0].count);
    });

    GetUniqueQuestionsAttempted().then((res) => {
      SetUniqueQuestions(res.data);
    });

    GetTotalQuestionsAttempted().then((res) => {
      SetTotalAttempts(res.data.totalattempts);
    });

    GetCorrectQuestionsAttempted().then((res) => {
      SetCorrectAttempts(res.data.correctattempts);
    });

    GetStatsBySubject("biology").then((res) => {
      SetBioStats(res.data);
    });
    GetStatsBySubject("chemistry").then((res) => {
      SetChemStats(res.data);
    });
    GetStatsBySubject("physics").then((res) => {
      SetPhysStats(res.data);
    });
    GetStatsBySubject("english").then((res) => {
      SetEngStats(res.data);
    });

    GetStatsBySubject("Logical Reasoning").then((res) => {
      SetLrStats(res.data);
    });

    GetAverageTimeTaken().then((res) => {
      SetTimeTaken(res.data);
    });

    GetAttemptCalendar().then((res) => {
      SetAttemptCalendar(res.data);
    });
  }, []);

  return (
    <div className={styles.MyStatsMainBox}>
      {/*  <div className={styles.PageTitle}>Your Stats so far..</div> */}
      <div className={styles.Dashboard_Main_Grid}>
        <div
          className={`${styles.Dashboard_Panel_3c} ${styles.Dashboard_Panel} ${styles.HeadingStats_Panel}`}
        >
          <div className={styles.HeadingStats}>
            <div className={styles.HeadingStats_Title}>
              <div className={styles.MyStatsHeading}>
                <h1>My Stats</h1>
              </div>
              You have attempted {uniqueQuestions} out of {totalQuestions}{" "}
              Questions on PreMed.PK
              <div className={styles.HeadingStats_Progress}>
                <Progress
                  theme="primary"
                  value={(uniqueQuestions * 100) / totalQuestions}
                />
              </div>
            </div>
            <div style={{ width: "fit-content", textAlign: "end" }}>
              <img src={DoctorCoat} className={styles.DoctorCoatImage} />
              <Progress theme="primary" value={0} />
            </div>
          </div>
        </div>
        <div
          className={`${styles.Dashboard_Panel} ${styles.Dashboard_Panel_2r}`}
        >
          <div className={styles.Speedometer_Panel}>
            <GaugeChart
              id="gauge-chart2"
              nrOfLevels={5}
              percent={totalAttempts ? correctAttempts / totalAttempts : 0}
              cornerRadius={0}
              arcPadding={0}
              textColor={"gray"}
              colors={["#ff0000", "#ff8000", "#ffff00", "#9ce917", "#00ff00"]}
              animDelay={711}
            />
            <div className={styles.Stats_Title}>
              Percentage Questions Correct
            </div>
          </div>
        </div>
        <div className={styles.Dashboard_Panel}>
          <div style={{ color: "#1976D2" }} className={styles.Stats_Number}>
            <CountUp
              start={0}
              end={engStats.Total ? engStats.Total : 0}
              duration={4}
            ></CountUp>
          </div>
          <div className={styles.Stats_Title}>English Questions Attempted</div>
        </div>

        <div className={styles.Dashboard_Panel}>
          <div style={{ color: "#16a61d" }} className={styles.Stats_Number}>
            <CountUp
              start={0}
              end={bioStats.Total ? bioStats.Total : 0}
              duration={4}
            ></CountUp>
          </div>
          <div className={styles.Stats_Title}>Biology Questions Attempted</div>
        </div>
        <div className={styles.Dashboard_Panel}>
          <div style={{ color: "#FF9800" }} className={styles.Stats_Number}>
            <CountUp
              start={0}
              end={chemStats.Total ? chemStats.Total : 0}
              duration={4}
            ></CountUp>
          </div>
          <div className={styles.Stats_Title}>
            Chemistry Questions Attempted
          </div>
        </div>
        <div className={styles.Dashboard_Panel}>
          <div style={{ color: "#6A1B9A" }} className={styles.Stats_Number}>
            <CountUp
              start={0}
              end={physStats.Total ? physStats.Total : 0}
              duration={4}
            ></CountUp>
          </div>
          <div className={styles.Stats_Title}>Physics Questions Attempted</div>
        </div>
        <div
          className={`${styles.Dashboard_Panel} ${styles.Dashboard_Panel_2c} ${styles.Dashboard_Panel_2r}`}
        >
          <ResponsiveBar
            data={[
              {
                subject: "English",
                PercentageCorrect: engStats.Percentage
                  ? engStats.Percentage
                  : 0,
              },
              {
                subject: "Biology",
                PercentageCorrect: bioStats.Percentage
                  ? bioStats.Percentage
                  : 0,
              },
              {
                subject: "Chemistry",
                PercentageCorrect: chemStats.Percentage
                  ? chemStats.Percentage
                  : 0,
              },
              {
                subject: "Physics",
                PercentageCorrect: physStats.Percentage
                  ? physStats.Percentage
                  : 0,
              },
              {
                subject: "Logical Reasoning",
                PercentageCorrect: lrStats.Percentage
                  ? lrStats.Percentage
                  : 0,
              },
            ]}
            indexBy="subject"
            keys={["PercentageCorrect"]}
            margin={{ top: 50, right: 40, bottom: 50, left: 60 }}
            valueScale={{ type: "linear" }}
            indexScale={{ type: "band", round: true }}
            colors={GetBarColor}
            borderColor={{ from: "color", modifiers: [["darker", 1.6]] }}
            axisTop={null}
            axisRight={null}
            maxValue={100}
            theme={{
              axis: {
                ticks: {
                  text: {
                    fontSize: 15,
                  },
                },
                legend: {
                  text: {
                    fontSize: 15,
                  },
                },
              },
            }}
            axisBottom={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: "Subject",
              legendPosition: "middle",
              legendOffset: 32,
            }}
            axisLeft={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: "Percentage Correct",
              legendPosition: "middle",
              legendOffset: -40,
            }}
            labelSkipWidth={12}
            labelSkipHeight={12}
            labelTextColor={{ from: "color", modifiers: [["darker", 1.6]] }}
            animate={true}
            motionStiffness={90}
            motionDamping={15}
          />
          {/* <ResponsiveRadar
            data={[
              {
                taste: "Biology",
                Prepareness: bioStats.Percentage ? bioStats.Percentage : 0,
              },
              {
                taste: "Chemistry",
                Prepareness: chemStats.Percentage ? chemStats.Percentage : 0,
              },
              {
                taste: "Physics",
                Prepareness: physStats.Percentage ? physStats.Percentage : 0,
              },
              {
                taste: "English",
                Prepareness: engStats.Percentage ? engStats.Percentage : 0,
              },
            ]}
            margin={{ top: 70, right: 80, bottom: 40, left: 80 }}
            keys={["Prepareness"]}
            indexBy="taste"
            maxValue={100}
            curve="linearClosed"
            borderWidth={2}
            borderColor={{ from: "color" }}
            gridLevels={5}
            gridShape="circular"
            gridLabelOffset={36}
            enableDots={true}
            dotSize={10}
            dotColor={{ theme: "background" }}
            dotBorderWidth={2}
            dotBorderColor={{ from: "color" }}
            enableDotLabel={true}
            dotLabel="value"
            dotLabelYOffset={-12}
            colors={["red"]}
            fillOpacity={0.25}
            blendMode="multiply"
            animate={true}
            motionConfig="wobbly"
            isInteractive={true}
            legends={[
              {
                anchor: "top-left",
                direction: "column",
                translateX: -50,
                translateY: -40,
                itemWidth: 80,
                itemHeight: 20,
                itemTextColor: "#999",
                symbolSize: 12,
                symbolShape: "circle",
                effects: [
                  {
                    on: "hover",
                    style: {
                      itemTextColor: "#000",
                    },
                  },
                ],
              },
            ]}
          /> */}
        </div>
        <div className={styles.Dashboard_Panel}>
          <div style={{ color: "#f00c89" }} className={styles.Stats_Number}>
            <CountUp
              start={0}
              end={timeTaken / 1000}
              duration={2}
              decimals={2}
              suffix="s"
            ></CountUp>
          </div>
          <div className={styles.Stats_Title}>Avg Time per Question</div>
        </div>

        <div className={styles.Dashboard_Panel}>
          <div style={{ color: "#4cf5d6" }} className={styles.Stats_Number}>
            <CountUp
              start={0}
              end={totalAttempts}
              duration={5.75}
              suffix=""
            ></CountUp>
          </div>
          <div className={styles.Stats_Title}>Total Attempts</div>
        </div>

        <div
          className={`${styles.Dashboard_Panel} ${styles.Dashboard_Panel_3c}`}
          style = {{overflowX : "scroll"}}
        >
          <div style = {{height: "100%", widows : "100%", minWidth:"1000px"}}>
            <ResponsiveCalendar
              data={attemptCalendar}
              from="2023-01-01"
              to="2023-12-31"
              emptyColor="#eeeeee"
              colors={["#61cdbb", "#97e3d5", "#e8c1a0", "#f47560"]}
              margin={{ top: 40, right: 40, bottom: 40, left: 40 }}
              yearSpacing={40}
              monthBorderWidth={0}
              monthBorderColor="#ffffff"
              dayBorderWidth={2}
              dayBorderColor="#ffffff"
              monthSpacing={15}
              tooltip={(data) => {
                // console.log(data);
                if (data.value === undefined) return null;
                var months = [
                  "January",
                  "February",
                  "March",
                  "April",
                  "May",
                  "June",
                  "July",
                  "August",
                  "September",
                  "October",
                  "November",
                  "December",
                ];
                var monthName = months[data.date.getMonth()]; // "July" (or current month)
                return (
                  <span
                    style={{
                      backgroundColor: "white",
                      padding: "10px",
                      boxShadow: "1px 1px 1px 1px #d4d4d4",
                      borderStyle: "solid",
                      borderWidth: "1px",
                      borderColor: "#dedede",
                    }}
                  >
                    {monthName} {data.day[8]}
                    {data.day[9]} {data.date.getFullYear()} :{" "}
                    <strong>{data.value}</strong>
                  </span>
                );
              }}
              legends={[
                {
                  anchor: "bottom-right",
                  direction: "row",
                  translateY: 36,
                  itemCount: 4,
                  itemWidth: 42,
                  itemHeight: 36,
                  itemsSpacing: 14,
                  itemDirection: "right-to-left",
                },
              ]}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

function GetBarColor(data) {
  var subject = data.indexValue;
  if (subject === "Biology") {
    return "#C4183C";
  }
  if (subject === "Chemistry") {
    return "#10ba00";
  }
  if (subject === "English") {
    return "#0082ba";
  }
  if (subject === "Physics") {
    return "#e8b602";
  }
  return "#789875";
}
export default MyStats;
