import { useState, useEffect, useContext } from "react";
import { useHistory, useParams, withRouter } from "react-router";

import ReactGA from "react-ga";

import {
  GetDeckID,
  GetTopicalDeckID,
  GetQuestions,
  GetTopicalQuestions,
  GetTopicalPastPaperQuestions,
  GetQuestion,
  LogQuestionAttempt,
  GetLastQuestion,
  GetTopicalQuestion,
} from "../../../APIManager";
import { UserContext } from "../../../userContext";

/**
 *
 * @return {[deckName : string, questionNo : int, NextQuestion: function, PreviousQuestion: function]} props
 *
 */
function usePracticeDeck() {
  const [user, SetUser] = useContext(UserContext);

  let search = window.location.search;
  let params = new URLSearchParams(search);

  var time = performance.now();

  const history = useHistory();

  const [deckName, SetDeckName] = useState(params.get("deck"));
  const [questionNo, SetQuestionNo] = useState(
    parseInt(params.get("question"))
  );
  if (deckName != params.get("deck")) {
    SetDeckName(params.get("deck"));
  }
  if (questionNo != params.get("question") && questionNo) {
    SetQuestionNo(parseInt(params.get("question")));
  }

  const [questionList, SetQuestionList] = useState([]);
  const [currentQuestion, SetCurrentQuestion] = useState({});
  const [fetchedQID, SetFetchedQID] = useState(null);
  const [loadingQuestion, SetLoadingQuestion] = useState(true);

  const [showDeckStart, SetShowDeckStart] = useState(false);

  //Derived Constants
  const totalQuestionNo = questionList.length;
  const deckType = window.location.pathname.includes("topical")
    ? "topical"
    : "yearly";
  const pastPaperTopicals = window.location.pathname.includes("pastpaper");
  //UI Controls
  const questionNavAllowed = true;
  const [showStartPage, SetShowStartPage] = useState(false);
  const [showQuestionPage, SetShowQuestionPage] = useState(false);

  const [nextEnabled, SetNextEnabled] = useState(false);
  const [backEnabled, SetBackEnabled] = useState(false);

  //Nav Functions
  var NextQuestion = () => {
    if (loadingQuestion) {
      return;
    }
    if (CanGoToNextQuestion(questionNo, totalQuestionNo, questionNavAllowed)) {
      SetQuestionNo(questionNo + 1);
      SetLoadingQuestion(true);
      history.push({
        search: "?deck=" + deckName + "&question=" + parseInt(questionNo + 1),
      });
      time = performance.now();
    }
  };

  var PreviousQuestion = () => {
    if (loadingQuestion) {
      return;
    }
    if (CanGoToPrevQuestion(questionNo, totalQuestionNo, questionNavAllowed)) {
      SetQuestionNo(questionNo - 1);
      SetLoadingQuestion(true);
      history.push({
        search: "?deck=" + deckName + "&question=" + parseInt(questionNo - 1),
      });
      time = performance.now();
    }
  };

  var SkipToQuestion = (skipquestionNo) => {
    skipquestionNo = parseInt(skipquestionNo);
    if (loadingQuestion) {
      return;
    }
    if (
      CanSkipToQuestion(skipquestionNo, totalQuestionNo, questionNavAllowed)
    ) {
      SetQuestionNo(skipquestionNo);
      SetLoadingQuestion(true);
      history.push({
        search: "?deck=" + deckName + "&question=" + parseInt(skipquestionNo),
      });
      time = performance.now();
    }
  };

  function onStartCB() {
    history.push({
      search: "?deck=" + deckName + "&question=1",
    });
    time = performance.now();
    SetQuestionNo(1);
  }

  function OnAttempt(optionLetter, isCorrect) {
    console.log(optionLetter);
    var timetaken = performance.now() - time;
    if (timetaken > 120000) {
      timetaken = 120000;
    }

    ReactGA.event({
      category: "Question",
      action: "Attempted",
      label: deckType === "yearly" ? "Yearly" : "Topical",
    });
    if (user.isLoggedIn) {
      LogQuestionAttempt({
        DeckName: deckName,
        QuestionID: questionList[parseInt(questionNo) - 1],
        Type: deckType === "yearly" ? "Yearly" : "Topical",
        OptionChoice: optionLetter,
        Correct: isCorrect,
        TimeTaken: Math.round(timetaken),
        QuestionNo: questionNo,
        LastAttemptType:
          deckType === "yearly"
            ? "Yearly"
            : pastPaperTopicals
            ? "TopicalPastPaper"
            : "TopicalPractice",
      });
    }
  }

  useEffect(async () => {
    if (!deckName) {
      // history.push("/404");
    }
    if (deckName && !questionNo) {
      var deckValidRes;
      deckValidRes = await ValidateDeckName(
        deckName,
        deckType,
        pastPaperTopicals
      );
      if (deckValidRes.validDeck) {
        SetShowQuestionPage(false);
        SetShowStartPage(true);
        SetQuestionList(deckValidRes.questionsIDs);
      } else {
        history.push("/404");
      }
    } else if (deckName && questionNo) {
      deckValidRes = await ValidateQuestionNo(
        deckName,
        questionNo,
        deckType,
        pastPaperTopicals
      );
      if (deckValidRes.validDeck) {
        SetShowQuestionPage(true);
        SetShowStartPage(false);
        SetQuestionList(deckValidRes.questionsIDs);
      } else {
        history.push("/404");
      }
    }
  }, [deckName, questionNo, pastPaperTopicals]);

  useEffect(() => {
    //SetNavButtonStates(questionNo, questionList.length);
    if (questionList?.length > 0) {
      FetchQuestion(deckName, questionList[questionNo - 1]);
    }
  }, [questionList, questionNo, fetchedQID]);

  useEffect(() => {
    if (questionNo === questionList.length) {
      SetNextEnabled(false);
    } else {
      SetNextEnabled(true);
    }
    if (questionNo === 1) {
      SetBackEnabled(false);
    } else {
      SetBackEnabled(true);
    }
  }, [questionNo, questionList]);

  async function FetchQuestion(deckName, qid, forceRefresh) {
    if (qid == fetchedQID && !forceRefresh) {
      // SetLoadingQuestion(false);
    } else {
      SetFetchedQID(qid);
      let promise;
      if (deckType === "yearly") {
        promise = await GetQuestion(deckName, qid);
      } else if (deckType === "topical") {
        promise = await GetTopicalQuestion(deckName, qid);
      }

      const data = promise.data;
      if (!data.Error) {
        SetCurrentQuestion(data);
        SetLoadingQuestion(false);
      } else {
        SetLoadingQuestion(false);
      }
    }
  }

  function RefreshQuestion() {
    console.log("Refresh")
    FetchQuestion(deckName, questionList[questionNo - 1], true).then((res) => {});
  }

  return {
    deckName: deckName,
    questionNo,
    questionNo,
    NextQuestion: NextQuestion,
    PreviousQuestion: PreviousQuestion,
    showStartPage: showStartPage,
    showQuestionPage: showQuestionPage,
    NoOfQuestions: totalQuestionNo,
    currentQuestion: currentQuestion,
    nextEnabled: nextEnabled,
    backEnabled: backEnabled,
    loadingQuestion: loadingQuestion,
    onStartCB: onStartCB,
    SkipToQuestion: SkipToQuestion,
    OnAttempt: OnAttempt,
    RefreshQuestion: RefreshQuestion,
  };
}

function CanGoToNextQuestion(questionNo, totalQuestionNo, questionNavAllowed) {
  var nextquestionNo = questionNo + 1;
  if (
    nextquestionNo > 0 &&
    nextquestionNo <= totalQuestionNo &&
    questionNavAllowed
  ) {
    return true;
  }
  return false;
}

function CanGoToPrevQuestion(questionNo, totalQuestionNo, questionNavAllowed) {
  var prevquestionNo = questionNo - 1;
  if (
    prevquestionNo > 0 &&
    prevquestionNo <= totalQuestionNo &&
    questionNavAllowed
  ) {
    return true;
  }
  return false;
}

function CanSkipToQuestion(questionNo, totalQuestionNo, questionNavAllowed) {
  var nextquestionNo = questionNo;
  if (
    nextquestionNo > 0 &&
    nextquestionNo <= totalQuestionNo &&
    questionNavAllowed
  ) {
    return true;
  }
  return false;
}

async function ValidateDeckName(deckName, deckType, pastPaperTopicals) {
  if (deckName.length === 0) {
    return { validDeck: false, questionsIDs: null };
  } else {
    let promise;

    if (deckType == "yearly") {
      promise = await GetDeckID(deckName);
    } else {
      promise = await GetTopicalDeckID(deckName);
    }
    //await promise;
    const data = promise.data;
    if (data.Error) {
      return { validDeck: false, questionsIDs: null };
    } else {
      let promise;
      if (deckType == "yearly") {
        promise = await GetQuestions(deckName);
      } else {
        if (pastPaperTopicals) {
          promise = await GetTopicalPastPaperQuestions(deckName);
        } else {
          promise = await GetTopicalQuestions(deckName);
        }
      }
      var questionsIDs = [];
      const data = promise.data;
      data.forEach((element) => {
        questionsIDs.push(element.Questions._id);
      });
      return { validDeck: true, questionsIDs: questionsIDs };
    }
  }
}

async function ValidateQuestionNo(
  deckName,
  questionNo,
  deckType,
  pastPaperTopicals
) {
  if (deckName.length === 0) {
    return { validDeck: false, questionsIDs: null };
  } else if (questionNo.length === 0 || isNaN(questionNo)) {
    return { validDeck: false, questionsIDs: null };
  } else {
    let promise;

    if (deckType == "yearly") {
      promise = await GetQuestions(deckName);
    } else {
      if (pastPaperTopicals) {
        promise = await GetTopicalPastPaperQuestions(deckName);
      } else {
        promise = await GetTopicalQuestions(deckName);
      }
    }
    var questionsIDs = [];
    const data = promise.data;
    data.forEach((element) => {
      questionsIDs.push(element.Questions._id);
    });
    var number = parseInt(questionNo);
    if (number > questionsIDs.length || number < 1) {
      return { validDeck: false, questionsIDs: null };
    } else {
      return { validDeck: true, questionsIDs: questionsIDs };
    }
  }
}

export default usePracticeDeck;
