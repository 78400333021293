import HeaderImage from "../../../StaticImages/Blog/NewPreMedLogo.png";
import FoundersImage from "../../../StaticImages/Blog/FoundersImage.png";
import UmarBoardImage from "../../../StaticImages/Blog/UmarBoardImage.png";
import HasnainCodingImage from "../../../StaticImages/Blog/HasnainCodingImage.png";
import TeamZoomImage from "../../../StaticImages/Blog/TeamZoomImage.png";
import CoreTeamImage from "../../../StaticImages/Blog/CoreTeamImage.png";
export var MBBSvsBDSArticle = {
  HeaderImg:
    "https://premedpk-cdn.sgp1.digitaloceanspaces.com/CDN/Blog/MBBSvsBDSCoverImage.jpeg",
  Tags: ["Featured"],
  Title: "MBBS vs BDS",
  Desc: "Confused whether to pursue MBBS or BDS? Do not worry, here we outline the differences between these two degrees and discuss which profession you should pursue based on your interest and passion.",
  Date: "June 3, 2022",
  AuthorName: <>Mohammad Danish</>,
  Article: (
    <>
      <p>
        We would like to start off by saying that both these qualifications are
        highly respectable and as such both dentists and medical doctors are an
        integral part of the healthcare system. The purpose of this article is
        to guide those who are confused between pursuing a Bachelor of Dental
        Surgery (BDS) and becoming dentists or a Bachelor of Medicine and
        Bachelor of Surgery (MBBS) and thus, becoming Medical Doctors. While
        both are excellent options, they are in some ways vastly different yet
        in others, fairly similar. In the article we will be focusing on the
        following aspects:
        <ol>
          <li>How their curriculums differ</li>
          <li>Future prospects in terms of postgraduate options</li>
          <li>
            Lifestyle differences as students and healthcare professionals
          </li>
        </ol>
      </p>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <img src="https://premedpk-cdn.sgp1.digitaloceanspaces.com/CDN/Blog/MBBSvsBDSImage1.png" />
      </div>
      <h2>Comparing the BDS and MBBS curricula</h2>
      <p>
        The BDS program is a 4 year program and while the first year BDS
        syllabus tends to be almost identical to that of the MBBS program except
        for a greater focus on the oral cavity and a few additional subjects
        such as Oral Biology, from the second year onwards there is a shift in
        the curriculum with the introduction of subjects such as Oral Pathology
        and Science of Dental materials and Laboratory Techniques. The last two
        years of the program are relatively clinical in nature focusing on
        surgical procedures. On the contrary, the MBBS program’s duration is 5
        years and the program is comparatively wider in scope as medical doctors
        have to learn about the entire human body in extensive detail, unlike
        dentists who are specialists of the oral cavity. The first two years of
        medicine (MBBS) are those of basic sciences where foundational subjects
        such as Physiology, Pathology, and Biochemistry are taught in detail.
        From the third year onwards, clinical rotations are introduced and every
        year from the 3rd onward, there is an increasing emphasis on clinical
        training. It is true, however, that BDS students have more hands-on work
        to do as most of the work dentists do (in a clinical setting) are
        surgical procedures. This, in turn, means that many students take up
        dentistry because it has in some ways a larger artistic side and
        creative side to it. Simply put, both BDS and MBBS programs have
        different yet challenging curriculums with neither being an easy option.
      </p>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <img src="https://premedpk-cdn.sgp1.digitaloceanspaces.com/CDN/Blog/MBBSvsBDSImage2.png" />
      </div>
      <h2>Future prospects in terms of postgraduate options</h2>
      <p>
        Due to the differences in their respective curricula, dentists and
        doctors have different options in terms of postgraduate studies. Medical
        Doctors have been taught a more diverse curriculum and as such, they can
        pursue further education in a vast variety of subject areas whereas
        dentists have relatively fewer options. This, however, does not
        translate to a lack of options in any way. Dentists can choose to
        specialize in different aspects of dentistry such as orthodontics,
        prosthodontics, etc. Both, though, can take a common route and pursue a
        career in public health. Lastly, one important thing to note is that it
        is objectively easier for medical doctors to study and work abroad in
        countries such as the US especially if you consider the costs involved.
      </p>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <img src="https://premedpk-cdn.sgp1.digitaloceanspaces.com/CDN/Blog/MBBSvsBDSImage3.png" />
      </div>
      <h2>Lifestyle differences as students and healthcare professionals</h2>
      <p>
        Dentists, in general, do have better work-life balance as compared to
        doctors as their working hours tend to be more flexible. In a way, their
        profession may be perceived as less stressful because there are fewer
        encounters where the patient’s life is at stake. Yet it is important to
        note that dentistry is physically more demanding and as such a large
        proportion of senior dentists tend to suffer from back and neck issues.
        On the other hand, the average medical doctor, especially early on in
        his/her career and during residency, tends to lead a more stressful
        lifestyle than the average dentist owing to longer, more irregular
        working hours and a large number of emergencies.
      </p>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <img src="https://premedpk-cdn.sgp1.digitaloceanspaces.com/CDN/Blog/MBBSvsBDSImage4.png" />
      </div>
      <h2>Last Words of Advice</h2>
      <p>
        Last but not the least, the most important thing at the end of the day
        is what you want, what your dreams are and where you see yourself in the
        future. Neither degrees are by any means a walk in the park, and they
        both happen to be excellent career options.
      </p>
    </>
  ),
};
