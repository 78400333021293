import React, { useState } from "react";
import { BrowserRouter as Router, Route, Switch, Link } from "react-router-dom";
import styles from "./OurTeam.module.css";
import { Button } from "shards-react";
import whatsapp from "../../StaticImages/Icons/whatsapp-fancy.svg";
import messenger from "../../StaticImages/Icons/messenger-fancy.svg";
import mail from "../../StaticImages/Icons/mail-fancy.svg";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Typography from "@material-ui/core/Typography";

import umarimg from "../../StaticImages/TeamPhotos/UmarPic.png";
import fahdimg from "../../StaticImages/TeamPhotos/FahdPic.png";
import hasnainimg from "../../StaticImages/TeamPhotos/HasnainPic.png";
import tahaimg from "../../StaticImages/TeamPhotos/TahaPic.png";
import illyunimg from "../../StaticImages/TeamPhotos/IllyunPic.png";

import TeamsGraphic from "../../StaticImages/TeamsGraphic.svg";

import GradCrossButton from "../../StaticImages/GradCrossButton.svg";

import HeadImage from "../../StaticImages/Meet the Team Vector.png";

import TeamColumnMobile from "./TeamColumnMobile";

import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Dialog from "@material-ui/core/Dialog";
import Slide from "@material-ui/core/Slide";

import FBIcon from "../../StaticImages/SocialIcons/icons8-facebook-144.png";
import InstaIcon from "../../StaticImages/SocialIcons/icons8-instagram-144.png";
import TwitterIcon from "../../StaticImages/SocialIcons/icons8-twitter-144.png";

import { TeamMembers } from "./TeamMembers";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const OurTeam = () => {
  var persons = TeamMembers;
  const smallDeviceQuery = useMediaQuery("(max-width:760px)");
  const secondPill = useMediaQuery("(max-width:610px)");

  const [autoCompleteOptions, SetAutoCompleteOptions] = useState(
    persons.map((p) => p.Name)
  );
  const [searchInput, SetSearchInput] = useState("");
  const [showSearchLabel, SetShowSearchLabel] = useState(true);

  const [selectedCategory, SetSelectedCategory] = useState("All");

  const [selectedPerson, SetSelectedPerson] = useState("");
  const [modalOpen, SetModalOpen] = useState(false);

  var presonsRowsData = chunkArrayInGroups(
    persons.filter(
      (p) => p.Dept === selectedCategory || selectedCategory === "All"
    ),
    3
  );

  var MobilePersonsData = persons.filter(
    (p) => p.Dept === selectedCategory || selectedCategory === "All"
  );

  var noOfRows = presonsRowsData.length;
  if (noOfRows) {
    var personsInLastRow = presonsRowsData[noOfRows - 1].length;
    var foo = new Array(3 - personsInLastRow);
    if (personsInLastRow != 3) {
      /*   for (let index = 0; index < foo.length; index++) {
        presonsRowsData[presonsRowsData.length - 1].push({
          Name: null,
          Role: null,
          Image: null,
        });
      } */
    }
  }

  function onCatButtonClick(e) {
    SetSelectedCategory(e.target.formTarget);
  }

  return (
    <div className={`${styles.MainDiv} ${modalOpen ? styles.Blur : ""}`}>
      {true && (
        <Dialog
          scroll="body"
          TransitionComponent={Transition}
          keepMounted
          //onClose={handleClose}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
          fullWidth
          maxWidth="md"
          onClose={() => {
            SetModalOpen(false);
          }}
          open={modalOpen}
          className={styles.ModalRoot}
        >
          <div className={`${styles.modalcontent}`}>
            <div class="modal-body">
              <div
                class="d-flex flex-row flex-row-reverse flex-end"
                onClick={() => {
                  SetModalOpen(false);
                  console.log("hello");
                }}
                style={{ cursor: "pointer" }}
              >
                <img src={GradCrossButton} />
              </div>
              <div class="row">
                <div class="col">
                  <div className={`${styles.modalImg}`}>
                    <img width="100%" src={selectedPerson.Image} />
                  </div>
                </div>
                <div class="col">
                  <h1 className={`${styles.teamName}`}>
                    {selectedPerson.Name}
                  </h1>
                  <h4 className={`${styles.teamTitle}`}>
                    {selectedPerson.Role}
                  </h4>
                  <p className={`${styles.modalPara}`}>{selectedPerson.Bio}</p>
                  <a
                    href={selectedPerson?.Socials?.Facebook}
                    target="_blank"
                    hidden={!selectedPerson?.Socials?.Facebook}
                  >
                    <img className={styles.SocialMediaIcons} src={FBIcon} />
                  </a>
                  <a
                    href={selectedPerson?.Socials?.Instagram}
                    target="_blank"
                    hidden={!selectedPerson?.Socials?.Instagram}
                  >
                    <img className={styles.SocialMediaIcons} src={InstaIcon} />
                  </a>
                  <a
                    href={selectedPerson?.Socials?.Twitter}
                    target="_blank"
                    hidden={!selectedPerson?.Socials?.Twitter}
                  >
                    <img
                      className={styles.SocialMediaIcons}
                      src={TwitterIcon}
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </Dialog>
      )}
      <div class={`${styles.mainContainer} m-0`}>
        <div class={`d-flex justify-content-center mb-5`}>
          <div class={`${styles.spectrumUL}`}>
            <h1 class={`${styles.mainTitle}`}>
              <span class={styles.spectrumUnderline}>
                We’re building something great!
              </span>
            </h1>
            {/*      <div class={`row justify-content-center`}>
              <div class={`col ${styles.ULcomponentG}`}></div>
              <div class={`col ${styles.ULcomponentP}`}></div>
              <div class={`col ${styles.ULcomponentR}`}></div>
              <div class={`col ${styles.ULcomponentG}`}></div>
              <div class={`col ${styles.ULcomponentP}`}></div>
              <div class={`col ${styles.ULcomponentR}`}></div>
              <div class={`col ${styles.ULcomponentG}`}></div>
            </div> */}
          </div>
        </div>

        <div class={`d-flex justify-content-center mb-5 ${styles.gradBG}`}>
          <img class={`${styles.jumboGraphic}`} src={TeamsGraphic} alt="" />
        </div>
        <div></div>
        <div className={styles.SearchBox}>
          <div>
            {!secondPill && (
              <div class={`${styles.TeamBtnContainer}`}>
                <button
                  type="button"
                  class={
                    selectedCategory === "All"
                      ? `${styles.TeamRedActive}`
                      : null
                  }
                  formTarget="All"
                  onClick={onCatButtonClick}
                >
                  All
                </button>
                <button
                  type="button"
                  class={
                    selectedCategory === "Core"
                      ? `${styles.TeamRedActive}`
                      : null
                  }
                  formTarget="Core"
                  onClick={onCatButtonClick}
                >
                  Core
                </button>
                <button
                  type="button"
                  class={
                    selectedCategory === "Education"
                      ? `${styles.TeamRedActive}`
                      : null
                  }
                  formTarget="Education"
                  onClick={onCatButtonClick}
                >
                  Education
                </button>
                <button
                  type="button"
                  class={
                    selectedCategory === "Marketing"
                      ? `${styles.TeamRedActive}`
                      : null
                  }
                  formTarget="Marketing"
                  onClick={onCatButtonClick}
                >
                  Marketing
                </button>
              </div>
            )}
            {secondPill && (
              <div class={`${styles.TeamBtnContainer}`}>
                <button
                  type="button"
                  class={
                    selectedCategory === "All"
                      ? `${styles.TeamRedActive}`
                      : null
                  }
                  formTarget="All"
                  onClick={onCatButtonClick}
                >
                  All
                </button>
                <button
                  type="button"
                  class={
                    selectedCategory === "Core"
                      ? `${styles.TeamRedActive}`
                      : null
                  }
                  formTarget="Core"
                  onClick={onCatButtonClick}
                >
                  Core
                </button>
                <button
                  type="button"
                  class={
                    selectedCategory === "Education"
                      ? `${styles.TeamRedActive}`
                      : null
                  }
                  formTarget="Education"
                  onClick={onCatButtonClick}
                >
                  Education
                </button>
                <button
                  type="button"
                  class={
                    selectedCategory === "Marketing"
                      ? `${styles.TeamRedActive}`
                      : null
                  }
                  formTarget="Marketing"
                  onClick={onCatButtonClick}
                >
                  Marketing
                </button>
              </div>
            )}
            {/*   <Autocomplete
              id="combo-box-demo"
              options={autoCompleteOptions}
              renderOption={(option) => (
                <Typography style={{ fontSize: "1.2rem" }}>{option}</Typography>
              )}
              onChange={(event, newValue) => {
                SetSearchInput(newValue);
                SetShowSearchLabel(!newValue);
              }}
              onInputChange={(event, newValue) => {
                SetShowSearchLabel(!newValue);
              }}
              getOptionLabel={(option) => option}
              renderInput={(params) => (
                <TextField
                  {...params}
                  id="standard-basic"
                  label={
                    !showSearchLabel
                      ? "  "
                      : "Search by name, city, or district"
                  }
                  variant="standard"
                  fullWidth
                  inputProps={{
                    ...params.inputProps,
                    min: 0,
                    style: { textAlign: "center", fontSize: "1.7em" },
                  }} // the change is here
                  InputLabelProps={{
                    min: 0,
                    style: {
                      textAlign: "center",
                      width: "100%",
                      fontSize: "1.7em",
                    },
                    shrink: false,
                  }}
                  hintStyle={{ width: "600px", textAlign: "center" }}
                />
              )}
            /> */}
          </div>
        </div>
        <div
          class={`tab-content ${styles.TabContentDiv}`}
          id="pills-tabContent"
          hidden={smallDeviceQuery}
        >
          <div
            class="tab-pane fade show active"
            id="team-core"
            role="tabpanel"
            aria-labelledby="team-core"
          >
            {presonsRowsData.map((row) => {
              return (
                <>
                  {" "}
                  <div class={`${styles.teamPicContainer} p-4 pb-0 row`}>
                    {row.map((p) => {
                      return <TeamPhotoBox Img={p.Image} Name={p.Name} />;
                    })}
                  </div>
                  <div class={`${styles.nameRow} p-4 pb-0 row`}>
                    {row.map((p) => {
                      return (
                        <TeamNameBox
                          Name={p.Name}
                          Title={p.Role}
                          OnReadMore={() => {
                            SetSelectedPerson(p);
                            SetModalOpen(true);
                          }}
                        />
                      );
                    })}
                  </div>
                </>
              );
            })}
          </div>
          <div
            class="tab-pane fade"
            id="team-tech"
            role="tabpanel"
            aria-labelledby="team-tech"
          >
            <div class="teamPicContainer nameRow p-4 pb-0 row">
              <div class="col">
                <img
                  class="teamPic"
                  src="./images/fahd-transparent.png"
                  alt=""
                />
              </div>
            </div>
            <div class="nameRow p-4 pb-0 row">
              <div class="col">
                <h1 class="mt-3 teamName">John Doe</h1>
                <h3 class="teamTitle">CEO</h3>
                <button
                  type="button"
                  class="teamButton py-2 px-4"
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal"
                >
                  Read More
                </button>
              </div>
            </div>
          </div>
          <div
            class="tab-pane fade"
            id="team-edu"
            role="tabpanel"
            aria-labelledby="team-edu"
          >
            ...
          </div>
          <div
            class="tab-pane fade"
            id="team-marketing"
            role="tabpanel"
            aria-labelledby="team-marketing"
          >
            ...
          </div>
          <div
            class="tab-pane fade"
            id="team-ambassador"
            role="tabpanel"
            aria-labelledby="team-ambassador"
          >
            ...
          </div>
        </div>
        <div hidden={!smallDeviceQuery}>
          <TeamColumnMobile persons={MobilePersonsData} />
        </div>
      </div>
    </div>
  );
};

const NavButton = ({ labelText }) => {
  return (
    <button class={`${styles.navlink} active`} type="button" role="tab">
      Core
    </button>
  );
};

const TeamPhotoBox = ({ Img, Name }) => {
  return (
    <>
      <div class="col">
        <img class={`${styles.teamPic}`} src={Img} alt={Name} />
      </div>
    </>
  );
};

const TeamNameBox = ({ Name, Title, OnReadMore }) => {
  return (
    <div class="col">
      <h1 class={`mt-3 ${styles.teamName}`}>{Name}</h1>
      <h3 class={`${styles.teamTitle}`}>{Title}</h3>
      {Name && (
        <button
          type="button"
          class={`${styles.teamButton} py-2 px-4`}
          data-bs-toggle="modal"
          data-bs-target="#exampleModal"
          onClick={OnReadMore}
        >
          Read More
        </button>
      )}
    </div>
  );
};

function chunkArrayInGroups(arr, size) {
  var myArray = [];
  for (var i = 0; i < arr.length; i += size) {
    myArray.push(arr.slice(i, i + size));
  }
  return myArray;
}

export default OurTeam;
