import React, { useState, useEffect } from "react";
import { useHistory, useParams, withRouter } from "react-router";
import { Alert, Badge } from "shards-react";
import "bootstrap/dist/css/bootstrap.min.css";
import "shards-ui/dist/css/shards.min.css";
import { Modal, ModalBody, ModalHeader, Button, Fade } from "shards-react";
import { Progress } from "shards-react";
import { Breadcrumb, BreadcrumbItem } from "shards-react";
import Timer from "easytimer.js";
import QuizStartScreen from "./TestModeDeckStartComponent";
import QuestionComponent from "./TestModeQuestionComponent";
import QuizResultScreen from "./TestModeDeckResult";

import { GetAttemptCalendar, GetMockDeckID } from "../../APIManager";
import { GetMockQuestions } from "../../APIManager";
import { GetMockQuestion } from "../../APIManager";
import { LogQuestionAttempt } from "../../APIManager";
import { GetLastQuestion } from "../../APIManager";
import { LogTestAttempt } from "../../APIManager";

import CircularProgress from "@material-ui/core/CircularProgress";

import Footer from "../../Components/Footer/Footer";

import { ContactlessOutlined } from "@material-ui/icons";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import styles from "./TestMode.module.css";
import { useRef } from "react";

var timer = new Timer({ countdown: true, startValues: { seconds: 9000 } });

const DeckMetaInfo = {
  "PMC Mock 1": {
    Eng: 20,
    Bio: 68,
    Chem: 56,
    Phys: 56,
    Lr: 10,
    Time: 210,
  },
  "NUMS Mock 1": {
    Eng: 15,
    Bio: 60,
    Chem: 38,
    Phys: 37,
    Time: 150,
  },
  "TEST DECK" : { Eng: 20, Bio: 80, Chem: 60, Phys: 40, Time: 150 },
  "Test Mock 1": {
    Eng: 20,
    Bio: 80,
    Chem: 60,
    Phys: 40,
    Time: 150,
  },
  "AKU Mock Paper 1": {
    Bio: 20,
    Chem: 20,
    Phys: 20,
    ScienceReasoning: 20,
    MathReasoning: 20,
    Time: 120,
  },
  "AKU Mock Paper 2": {
    Eng: 20,
    Bio: 20,
    Chem: 20,
    Phys: 20,
    Lr: 20,
    Time: 120,
  },
  "PMC National MDCAT 2020": {
    Eng: 20,
    Bio: 80,
    Chem: 60,
    Phys: 40,
    Time: 150,
  },
  "Sindh MCAT NTS 2019": { Eng: 20, Bio: 80, Chem: 60, Phys: 40, Time: 150 },
  "Sindh MCAT NTS 2018": { Eng: 10, Bio: 30, Chem: 30, Phys: 30, Time: 120 },
  "Sindh MCAT NTS 2017": { Eng: 10, Bio: 30, Chem: 30, Phys: 30, Time: 120 },
  "Sindh MCAT NTS 2016 (SMBBMC Lyari)": {
    Eng: 10,
    Bio: 30,
    Chem: 30,
    Phys: 30,
    Time: 120,
  },
  "Sindh MCAT NTS 2016 (DUHS and JSMU)": {
    Eng: 10,
    Bio: 30,
    Chem: 30,
    Phys: 30,
    Time: 120,
  },
  "Sindh MCAT NTS 2015 (SMBBMC Lyari)": {
    Eng: 10,
    Bio: 30,
    Chem: 30,
    Phys: 30,
    Time: 120,
  },
  "Sindh MCAT NTS 2015 (DUHS and JSMU)": {
    Eng: 10,
    Bio: 30,
    Chem: 30,
    Phys: 30,
    Time: 120,
  },
  "Sindh MCAT NTS 2014": { Eng: 10, Bio: 30, Chem: 30, Phys: 30, Time: 120 },
  "Sindh MCAT NTS 2013": { Eng: 10, Bio: 30, Chem: 30, Phys: 30, Time: 120 },
  "Sindh MCAT NTS 2012": { Eng: 10, Bio: 30, Chem: 30, Phys: 30, Time: 120 },
  "Sindh MCAT NTS 2011": { Eng: 10, Bio: 30, Chem: 30, Phys: 30, Time: 120 },
  "Sindh MCAT NTS 2010": { Eng: 10, Bio: 30, Chem: 30, Phys: 30, Time: 120 },
  "NUMS 2020": { Eng: 20, Bio: 80, Chem: 60, Phys: 40, Time: 150 },
  "NUMS 2019 (Reconduct)": { Eng: 20, Bio: 80, Chem: 60, Phys: 40, Time: 150 },
  "NUMS 2019 (Cancelled)": { Eng: 20, Bio: 80, Chem: 60, Phys: 40, Time: 150 },
  "NUMS 2018": { Eng: 20, Bio: 80, Chem: 60, Phys: 40, Time: 150 },
  "NUMS 2017": { Eng: 20, Bio: 70, Chem: 45, Phys: 45, Time: 180 },
  "NUMS 2016": { Eng: 20, Bio: 70, Chem: 45, Phys: 45, Time: 180 },
  "NUMS 2015": { Eng: 20, Bio: 80, Chem: 60, Phys: 30, Lr: 10, Time: 180 },
  "NUMS 2014": { Eng: 20, Bio: 80, Chem: 60, Phys: 30, Lr: 10, Time: 180 },
  "PMC Sample Solved Paper 1": {
    Eng: 20,
    Bio: 68,
    Chem: 56,
    Phys: 56,
    Lr: 10,
    Time: 210,
  },
  "PMC Sample Solved Paper 2": {
    Eng: 20,
    Bio: 68,
    Chem: 56,
    Phys: 56,
    Lr: 10,
    Time: 210,
  },
  "PMC Sample Solved Paper 3": {
    Eng: 20,
    Bio: 68,
    Chem: 56,
    Phys: 56,
    Lr: 10,
    Time: 210,
  },
  "PMC Sample Solved Paper 4": {
    Eng: 20,
    Bio: 68,
    Chem: 56,
    Phys: 56,
    Lr: 10,
    Time: 210,
  },
  "PMC Sample Solved Paper 5": {
    Eng: 20,
    Bio: 68,
    Chem: 56,
    Phys: 56,
    Lr: 10,
    Time: 210,
  },
  "PMC Practice Test 1": {
    Eng: 20,
    Bio: 68,
    Chem: 56,
    Phys: 56,
    Lr: 10,
    Time: 210,
  },
  "PMC Practice Test 2": {
    Eng: 20,
    Bio: 68,
    Chem: 56,
    Phys: 56,
    Lr: 10,
    Time: 210,
  },
  "PMC Practice Test 3": {
    Eng: 20,
    Bio: 68,
    Chem: 56,
    Phys: 56,
    Lr: 10,
    Time: 210,
  },
  "PMC Practice Test 4": {
    Eng: 20,
    Bio: 68,
    Chem: 56,
    Phys: 56,
    Lr: 10,
    Time: 210,
  },
  "PMC Practice Test 5": {
    Eng: 20,
    Bio: 68,
    Chem: 56,
    Phys: 56,
    Lr: 10,
    Time: 210,
  },
  "PMC Practice Test 6": {
    Eng: 20,
    Bio: 68,
    Chem: 56,
    Phys: 56,
    Lr: 10,
    Time: 210,
  },
  "UHS MDCAT 2019": {
    Eng: 20,
    Bio: 80,
    Chem: 60,
    Phys: 40,
    Time: 150,
  },
  "UHS MDCAT 2018": {
    Eng: 30,
    Bio: 88,
    Chem: 58,
    Phys: 44,
    Time: 150,
  },
};

function QuizDeck(props) {
  var time = performance.now();

  //Content Indentifiers / Query State
  let search = window.location.search;
  let params = new URLSearchParams(search);
  const [deckName, SetDeckName] = useState(params.get("deck"));
  const [questionNo, SetQuestionNo] = useState(null);
  const [questionID, SetQuestionID] = useState("");
  const [deckID, SetDeckID] = useState("");
  const [validDeck, setValidDeck] = useState("");
  const [validQuestion, SetValidQuestion] = useState("");
  const [questionList, SetQuestionList] = useState([]);
  const [lastAttemptNo, SetLastAttemptNo] = useState("");
  //Content Question State
  var fetchedDeckName = "";
  const [fetchedQuestionID, SetFetchedQuestionID] = useState("");
  const [questionTags, SetQuestionTags] = useState([]);
  const [questionContent, SetQuestionContent] = useState("");
  const [questionImage, SetQuestionImage] = useState("");
  const [questionOptions, SetQuestionOptions] = useState([]);
  const [explanationContent, SetExplanationContent] = useState("");
  const [explanationImage, SetExplanationImage] = useState("");
  //const[QuestionText , SetQuestionText] = useState("");

  //Control State
  const [questionSpecified, SetQuestionSpecified] = useState(false);
  const [showResumeQuestion, SetShowResumeQuestion] = useState(false);
  const [showStartPage, SetShowStartPage] = useState(false);
  const [showQuestionPage, SetShowQuestionPage] = useState(false);
  const [showAnswer, SetShowAnswer] = useState(false);
  const [nextActive, SetNextActive] = useState(true);
  const [previousActive, SetPreviousActive] = useState(false);

  //Test Recording
  const [testPaper, SetTestPaper] = useState([]);
  const [numberOfQuestions, SetNumberOfQuestions] = useState(0);
  const [attemptedQuestions, SetAttemptedQuestions] = useState(0);
  const [correctQuestions, SetCorrectQuestions] = useState(0);
  const [wrongQuestions, SetWrongQuestions] = useState(0);
  const [unattemptedQuestions, SetUnattemptedQuestions] = useState(0);
  const [timeTaken, SetTimeTaken] = useState(0);
  const [avgTimeTaken, SetAvgTimeTaken] = useState(0);

  //Timer Controls
  const [paperDuration, SetPaperDuration] = useState(params.get("deck") ? DeckMetaInfo[params.get("deck")].Time * 60 : 9000);
  const [currentTime, SetCurrentTime] = useState(null);
  const [currentTimeS, SetCurrentTimeS] = useState(null);

  //Test Controls
  const [testActive, SetTestActive] = useState(false);
  const [testEnded, SetTestEnded] = useState(false);
  const [testPaused, SetTestPaused] = useState(false);

  const [finishPromptOpen, SetFinishPromtOpen] = useState(false);

  //timer.start({ countdown: true, startValues: { seconds: 309 } });
  var StartTimer;
  var StopTimer;

  const FinshRef = useRef();
  FinshRef.current = FinishTest;

  const PaperDurationRef = useRef();
  PaperDurationRef.current = paperDuration;

  //Hooks and others
  const history = useHistory();
  const smallDevice = useMediaQuery("(max-width:460px)");
  const Rootprops = props;

  if (deckName != params.get("deck")) {
    SetDeckName(params.get("deck"));
  }

  //This function only runs once and does so after the initlal render is complete
  //This function runs each time the URL query for deck name changes and also after the initlal render is complete
  useEffect(() => {
    if (deckName) {
      ValidateDeckName(deckName).then((valid) => {
        if (valid) {
          SetShowQuestionPage(false);
          SetShowStartPage(true);
        //  console.log("Start Screen");
        } else {
         // console.log("Invalid Deck Name");
          history.push("/404");
        }
      });
    } else {
    //  console.log("Deck name not specified");
      //history.push("/404");
    }
  }, [deckName]);

  useEffect(() => {
    timer = new Timer({ countdown: true, startValues: { seconds: PaperDurationRef } });
    timer.reset();
    timer.stop();
    timer.addEventListener("secondsUpdated", function (e) {
      SetCurrentTime(timer.getTimeValues().toString());
      SetCurrentTimeS(timer.getTotalTimeValues().seconds.toString());
    });
    timer.addEventListener("targetAchieved", () => {
      FinshRef.current();
      UnMountCloseInterceptor();
    });
    return () => {
      timer.reset(testPaper);
    };
  }, []);

  //console.log(testPaper);
  function SetNavButtonStates(currentNo, length) {
    if (questionList.length != 0 && questionNo != 0) {
      if (currentNo == 1) {
        SetPreviousActive(false);
      } else {
        SetPreviousActive(true);
      }
      if (currentNo == length) {
        SetNextActive(false);
      } else {
        SetNextActive(true);
      }
    }
  }

  useEffect(() => {
    //console.log("use effect run" + questionNo);
    if (questionNo) {
      FetchQuestion(deckName, questionList[questionNo - 1]);
      SetNextActive(questionNo + 1 <= questionList.length);
      SetPreviousActive(questionNo - 1 > 0);
    }
  }, [questionNo]);

  async function FetchQuestion(name, qid) {
    if (qid == fetchedQuestionID) {
    } else {
      SetFetchedQuestionID(qid);
      let promise = await GetMockQuestion(name, qid);
      const data = promise.data;
      if (!data.Error) {
        SetQuestionContent(data.QuestionText);
        SetQuestionOptions(data.Options);
        SetQuestionTags(data.Tags);
        SetQuestionImage(data.QuestionImage);
        SetExplanationContent(data.ExplanationText);
        SetExplanationImage(data.ExplanationImage);
      }
    }
  }

  async function ValidateDeckName(name) {
    if (name.length === 0) {
      return false;
    } else {
      let promise = await GetMockDeckID(name);
      //await promise;
      const data = promise.data;
      if (data.Error) {
        return false;
      } else {
        let promise = await GetMockQuestions(name);
        var questionsIDs = promise.data;
        const data = promise.data;
        if (questionList != questionsIDs) {
          SetQuestionList([...questionsIDs]);
          InitializeTestPaper(questionsIDs);
        }

        return true;
      }
    }
  }

  function InitializeTestPaper(ids) {
    var paper = [];
    ids.forEach((id) => {
      var question = {};
      question.id = id;
      question.selectedOption = null;
      question.correct = false;
      paper.push(question);
    });
    SetTestPaper(paper);
  }

  function NextQuestion() {
    time = performance.now();
    const nextQuestion = parseInt(questionNo) + 1;
    ClearQuestionState();
    SetQuestionNo(nextQuestion);
  }

  function SkipToQuestion(no) {
    time = performance.now();
    ClearQuestionState();
    SetQuestionNo(no);
  }

  function PreviousQuestion() {
    time = performance.now();
    const previousQuestion = parseInt(questionNo) - 1;
    ClearQuestionState();
    SetQuestionNo(previousQuestion);
  }
  function ResumeLastQuestion() {
    time = performance.now();
    const nextQuestion = lastAttemptNo;
    ClearQuestionState();
    SetQuestionNo(nextQuestion);
  }
  function ClearQuestionState() {
    SetQuestionContent("");
    SetQuestionOptions([]);
    SetQuestionTags([]);
    SetQuestionImage("");
  }
  function AssignTimerControls(start, resume, pause, stop, reset, timerState) {
    StartTimer = start;
    //console.log(timerState);
  }

  function StartTimer() {
    timer.start({ countdown: true, startValues: { seconds: paperDuration } }); //fix accoriding to no of questions
   // console.log("timer started");
  }
console.log(currentTimeS)
  function ResumeTest() {
    timer.start();
    SetTestPaused(false);
  }

  function PauseTest() {
    timer.pause();
    SetTestPaused(true);
  }

  function SetOptionAnswer(option, isCorrect) {
   // console.log(option, isCorrect, testPaper);
    var paper = testPaper;
    var newQuestion = {};
    newQuestion.id = paper[questionNo - 1].id;
    newQuestion.selectedOption = option;
    newQuestion.correct = isCorrect;
    paper[questionNo - 1] = newQuestion;
    SetTestPaper(paper);
    SetAttemptedQuestions(GetNumberOfAttempts(paper));
  }

  function FinishTest() {
    timer.stop();
    var noQuestions = testPaper.length;
    var attempts;
    var correct = 0;
    var wrong = 0;
    var skipped = 0;
    var timeTaken;
    var avgQuestionTime;
    testPaper.forEach((question) => {
      if (!question.selectedOption) {
        skipped++;
        return;
      }
      if (question.correct) {
        correct++;
      } else {
        wrong++;
      }
    });
    attempts = correct + wrong;
    timeTaken = paperDuration - currentTimeS;
    avgQuestionTime = timeTaken;
    //
    SetNumberOfQuestions(noQuestions);
    SetAttemptedQuestions(attempts);
    SetCorrectQuestions(correct);
    SetWrongQuestions(wrong);
    SetUnattemptedQuestions(skipped);
    SetTimeTaken(timeTaken);
    SetAvgTimeTaken(avgQuestionTime);
    SetTestActive(false);
    SetTestEnded(true);
    SetTestPaused(false);

    LogTestAttempt(
      deckName,
      deckID,
      noQuestions,
      correct,
      skipped,
      wrong,
      testPaper,
      timeTaken
    );

    //console.log(`correct: ${correct} wrong:${wrong}  skipped:${skipped}`)
    //console.log(currentTimeS)
  }

  function GetNumberOfAttempts(test) {
    var attempts = 0;
    test.forEach((q) => {
      if (q.selectedOption) {
        attempts++;
      }
    });
    return attempts;
  }

  function MountCloseInterceptor() {
    window.onbeforeunload = function () {
      return "Do you really want to leave?";
    };
  }
  function UnMountCloseInterceptor() {
    window.onbeforeunload = function () {};
  }
  return (
    <div class="animatedParent" onContextMenu={(e)=> e.preventDefault()}>
      <Modal
        open={finishPromptOpen}
        toggle={() => {
          SetFinishPromtOpen(false);
        }}
        centered
      >
        <ModalHeader>Confirm</ModalHeader>
        <ModalBody>
          <div style={{ fontSize: "1.2em" }}>
            {" "}
            Do you really want to finish the test?
          </div>
          <div style={{ marginTop: "1em" }}>
            <span style={{ marginRight: ".5em" }}>
              <Button
                onClick={() => {
                  FinishTest();
                  UnMountCloseInterceptor();
                  SetFinishPromtOpen(false);
                }}
              >
                Finish
              </Button>
            </span>
            <Button
              onClick={() => {
                SetFinishPromtOpen(false);
              }}
            >
              Cancel
            </Button>
          </div>
        </ModalBody>
      </Modal>
      <div
        class="animated bounceInDown"
        style={testEnded ? { display: "none" } : {}}
      >
        <div
          className={`${styles.TestModeOptionPanel}`}
          style={testEnded ? { display: "none" } : {}}
        >
          <div className={`${styles.TestModeOptions}`}>
            <Button
              style={{
                display:
                  !showStartPage && testActive && !testEnded
                    ? "inline"
                    : "none",
                marginRight: "0.5em",
              }}
              theme="danger"
              size={smallDevice ? " " : "lg"}
              onClick={() => {
                SetFinishPromtOpen(true);
              }}
            >
              Finish
            </Button>
            <Button
              style={{
                display:
                  !showStartPage && testActive && !testEnded
                    ? "inline"
                    : "none",
              }}
              size={smallDevice ? " " : "lg"}
              theme={testPaused ? "success" : "warning"}
              onClick={testPaused ? ResumeTest : PauseTest}
            >
              {testPaused ? "Resume" : "Pause"}
            </Button>
          </div>
          <div className={`${styles.TestModeTimer}`}>{`Time Left: ${
            currentTime
              ? currentTime
              : new Date(paperDuration * 1000).toISOString().substr(11, 8)
          }`}</div>
        </div>
        <Fade in={true}>
          <QuestionComponent
            ShowQuestion={showQuestionPage}
            QuestionNo={questionNo}
            DeckName={deckName}
            QuestionID={questionList[parseInt(questionNo) - 1]}
            QuestionContent={questionContent}
            QuestionOptions={questionOptions}
            QuestionTags={questionTags}
            QuestionImage={questionImage}
            ExplanationContent={explanationContent}
            ExplanationImage={explanationImage}
            TotalQuestions={questionList.length}
            NextActive={nextActive}
            PreviousActive={previousActive}
            NextCB={() => {
              NextQuestion();
            }}
            PreviousCB={() => {
              PreviousQuestion();
            }}
            SkipCB={SkipToQuestion}
            AttemptCB={(option) => {
             // console.log(option);

              SetOptionAnswer(option.OptionLetter, option.IsCorrect);
              /*   SubmitAttempt({
                Option: option.OptionLetter,
                Correct: option.IsCorrect,
              }); */
            }}
            DeckType="Yearly"
            IsTestPaused={testPaused}
            SelectedOption={
              testActive ? testPaper[questionNo - 1].selectedOption : null
            }
            NoOfAttempts={attemptedQuestions}
            hideTags = {true}
          />
          {!showStartPage && !showQuestionPage && !testEnded ? (
            <div
            className = "mylasdasdas"
              style={{
                display: "flex",
                justifyContent: "center",
                flexWrap: "wrap",
                height : '90vh',
                alignContent: "center"
              }}
            >
              <CircularProgress size = {100}/>
            </div>
          ) : null}
          <RenderStartScreen
            showScreen={showStartPage}
            name={deckName}
            no={questionList.length}
            history={history}
            showResume={showResumeQuestion}
            resumeNumber={lastAttemptNo}
            resumeCB={ResumeLastQuestion}
            onStartCB={() => {
              SetQuestionNo(1);
              SetShowQuestionPage(true);
              SetShowStartPage(false);
              SetTestActive(true);
              StartTimer();
              MountCloseInterceptor();
            }}
          />
        </Fade>
      </div>
      {testEnded ? (
        <QuizResultScreen
          total={numberOfQuestions}
          correct={correctQuestions}
          wrong={wrongQuestions}
          skipped={unattemptedQuestions}
          totalTime={timeTaken}
          deckName={deckName}
          testPaper={testPaper}
          isMock = {true}
        />
      ) : (
        <></>
      )}
      <Footer type="normal" />
    </div>
  );
}

export default withRouter(QuizDeck); //Exporting with router to ensure location and key are set as props
function RenderStartScreen(props) {
  const {
    showScreen,
    name,
    no,
    history,
    showResume,
    resumeNumber,
    resumeCB,
    onStartCB,
  } = props;
  if (showScreen === true) {
    return (
      <div>
        <QuizStartScreen
          key={name + no}
          onStartCB={() => {
            onStartCB();
          }}
          deckName={name}
          noQuestions={no}
          showResume={showResume}
          resumeNumber={resumeNumber}
          resumeCB={resumeCB}
          buttonText
        />
      </div>
    );
  } else {
    return <div></div>;
  }
}
