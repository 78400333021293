import { useState, useEffect } from "react";
import { useHistory, useParams, withRouter } from "react-router";

import ReactGA from "react-ga";

import {
  GetBookmarkQuestions,
  GetDeckID,
  GetTopicalDeckID,
  GetQuestions,
  GetTopicalQuestions,
  GetQuestion,
  LogQuestionAttempt,
  GetLastQuestion,
  GetTopicalQuestion,
} from "../../../APIManager";

/**
 *
 * @return {[deckName : string, questionNo : int, NextQuestion: function, PreviousQuestion: function]} props
 *
 */
function useBookmarksDeck() {
  var time = performance.now();
  const [questionNo, SetQuestionNo] = useState(1);
  const [questionList, SetQuestionList] = useState([]);
  const [currentQuestion, SetCurrentQuestion] = useState({});
  const totalQuestionNo = questionList.length;

  const [showStartPage, SetShowStartPage] = useState(true);
  const [downloadedPaper, SetDownloadedPaper] = useState(false);
  const [showQuestionPage, SetShowQuestionPage] = useState(false);

  const [nextEnabled, SetNextEnabled] = useState(false);
  const [backEnabled, SetBackEnabled] = useState(false);

  useEffect(() => {
    GetBookmarkQuestions().then((res) => {
      SetQuestionList(res.data);
      SetDownloadedPaper(true);
    });
  }, []);

  useEffect(() => {
    if (questionList.length < 1) {
      return;
    }
    var cq = questionList[questionNo - 1];
    cq._id = cq.QuestionID;
    SetCurrentQuestion(cq);
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [questionList, questionNo, downloadedPaper]);

  useEffect(() => {
    if (questionNo === questionList.length) {
      SetNextEnabled(false);
    } else {
      SetNextEnabled(true);
    }
    if (questionNo === 1) {
      SetBackEnabled(false);
    } else {
      SetBackEnabled(true);
    }
  }, [questionNo, questionList]);

  var NextQuestion = () => {
    if (CanGoToNextQuestion(questionNo, totalQuestionNo, true)) {
      SetQuestionNo(questionNo + 1);
      time = performance.now();
    }
  };

  var PreviousQuestion = () => {
    if (CanGoToPrevQuestion(questionNo, totalQuestionNo, true)) {
      SetQuestionNo(questionNo - 1);
      time = performance.now();
    }
  };

  var SkipToQuestion = (skipquestionNo) => {
    skipquestionNo = parseInt(skipquestionNo);
    if (CanSkipToQuestion(skipquestionNo, totalQuestionNo, true)) {
      SetQuestionNo(skipquestionNo);
      time = performance.now();
    }
  };

  function onStartCB() {
    if (!downloadedPaper) {
      return false;
    }
    time = performance.now();
    SetQuestionNo(1);
    SetShowStartPage(false);
    SetShowQuestionPage(true);
  }

  function OnAttempt(optionLetter, isCorrect) {
    console.log(optionLetter);
    var timetaken = performance.now() - time;
    if (timetaken > 120000) {
      timetaken = 120000;
    }

    ReactGA.event({
      category: "Question",
      action: "Attempted",
      label: currentQuestion.Type,
    });

    LogQuestionAttempt({
      DeckName: currentQuestion.DeckName,
      QuestionID: currentQuestion.QuestionID,
      Type: currentQuestion.Type,
      OptionChoice: optionLetter,
      Correct: isCorrect,
      TimeTaken: Math.round(timetaken),
    });
  }

  function CanGoToNextQuestion(
    questionNo,
    totalQuestionNo,
    questionNavAllowed
  ) {
    var nextquestionNo = questionNo + 1;
    if (
      nextquestionNo > 0 &&
      nextquestionNo <= totalQuestionNo &&
      questionNavAllowed
    ) {
      return true;
    }
    return false;
  }

  function CanGoToPrevQuestion(
    questionNo,
    totalQuestionNo,
    questionNavAllowed
  ) {
    var prevquestionNo = questionNo - 1;
    if (
      prevquestionNo > 0 &&
      prevquestionNo <= totalQuestionNo &&
      questionNavAllowed
    ) {
      return true;
    }
    return false;
  }

  function CanSkipToQuestion(questionNo, totalQuestionNo, questionNavAllowed) {
    var nextquestionNo = questionNo;
    if (
      nextquestionNo > 0 &&
      nextquestionNo <= totalQuestionNo &&
      questionNavAllowed
    ) {
      return true;
    }
    return false;
  }

  return {
    questionNo,
    NextQuestion: NextQuestion,
    PreviousQuestion: PreviousQuestion,
    showStartPage: showStartPage,
    showQuestionPage: showQuestionPage,
    downloadedPaper: downloadedPaper,
    NoOfQuestions: totalQuestionNo,
    currentQuestion: currentQuestion,
    nextEnabled: nextEnabled,
    backEnabled: backEnabled,
    onStartCB: onStartCB,
    SkipToQuestion: SkipToQuestion,
    OnAttempt: OnAttempt,
    DeckName: currentQuestion?.DeckName,
  };
}

export default useBookmarksDeck;
