import React, { useState, useContext, useEffect } from "react";
import styles from "./SignUpPage.module.css";

import Group18 from "../../StaticImages/SignUpPage/Group 18.svg";

import { Button } from "shards-react";
import Alert from "@material-ui/lab/Alert";
import AlertTitle from "@material-ui/lab/AlertTitle";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import { BrowserRouter as Router, Route, Switch, Link } from "react-router-dom";
import { useLocation, useHistory } from "react-router-dom";
import { UserContext } from "../../userContext";
import { RefreshUserContext } from "../../UtilityFunctions";
import InputMask from "react-input-mask";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Typography from "@material-ui/core/Typography";

import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import { createFilterOptions } from "@material-ui/lab/Autocomplete";

import loginIcon from "../../StaticImages/LoginPageIcon.jpg";

import { Login, Signup, getLoggedInUser } from "../../APIManager";
import ReactGA from "react-ga";
import { Email } from "@material-ui/icons";

const useStyles = makeStyles({
  select: {
    "& *": {
      backgroundColor: "#4e75a7",
    },
    "& li": {
      color: "white",
    },
  },
});

const filterOptions = createFilterOptions({
  matchFrom: "start",
  ignoreCase: true,
  trim: true,
});

export default function () {
  /*  console.log(
    document.getElementById("LoginPageMainDiv")?.getBoundingClientRect()
  ); */
  let [userContext, SetUserContext] = useContext(UserContext);
  // console.log(userContext)
  let history = useHistory();

  useEffect(() => {
    if (userContext.isLoggedIn) {
      history.push("./dashboard");
    }
  }, []);

  const [showPassword, SetShowPassword] = useState(false);
  const [waitingOnResponse, SetWaitingOnResponse] = useState(false);

  //Form Variables
  const [fullname, SetFullname] = useState("");
  const [username, SetUsername] = useState("");
  const [confirmUsername, SetConfirmUsername] = useState("");
  const [phonenumber, SetPhonenumber] = useState("");
  const [city, SetCity] = useState("");
  const [cityRaw, SetCityRaw] = useState("");
  const [school, SetSchool] = useState("");
  const [password, SetPassword] = useState("");
  const [confirmpassword, SetConfirmpassword] = useState("");
  const [referal, SetReferal] = useState("");
  const [referalRaw, SetReferalRaw] = useState("");

  //Form Validation
  const [fullnameValid, SetFullnameValid] = useState(true);
  const [usernameValid, SetUsernameValid] = useState(true);
  const [confirmUsernameValid, SetConfirmUsernameValid] = useState(true);
  const [phonenumberValid, SetPhonenumberValid] = useState(true);
  const [cityValid, SetCityValid] = useState(true);
  const [schoolValid, SetSchoolValid] = useState(true);
  const [passwordValid, SetPasswordValid] = useState(true);
  const [confirmpasswordValid, SetConfirmpasswordValid] = useState(true);
  const [referalValid, SetReferalValid] = useState(true);

  //Form Error Texts
  const [fullnameErrorText, SetFullnameErrorText] = useState("");
  const [usernameErrorText, SetUsernameErrorText] = useState("");
  const [confirmUsernameErrorText, SetConfirmUsernameErrorText] = useState("");
  const [phonenumberErrorText, SetPhonenumberErrorText] = useState("");
  const [cityErrorText, SetCityErrorText] = useState("");
  const [schoolErrorText, SetSchoolErrorText] = useState("");
  const [passwordErrorText, SetPasswordErrorText] = useState("");
  const [confirmpasswordErrorText, SetConfirmpasswordErrorText] = useState("");
  const [referalErrorText, SetReferalErrorText] = useState("");

  //Form shake control
  const [shakeFullname, SetShakeFullname] = useState(false);
  const [shakeUsername, SetShakeUsername] = useState(false);
  const [shakeConfirmUsername, SetShakeConfirmUsername] = useState(false);
  const [shakePhonenumber, SetShakePhonenumber] = useState(false);
  const [shakeCity, SetShakeCity] = useState(false);
  const [shakeSchool, SetShakeSchool] = useState(false);
  const [shakePassword, SetShakePassword] = useState(false);
  const [shakeConfirmpassword, SetShakeConfirmpassword] = useState(false);
  const [shakereferal, SetShakeReferal] = useState(false);

  //Server Error
  const [serverError, SetServerError] = useState(false);
  const [serverErrorText, SetServerErrorText] = useState("");

  const classes = useStyles();

  //OnChange Handlers
  function OnUsernameChange(event) {
    // console.log(event);
    var newUsername = event.target.value.trim();
    SetUsername(newUsername);
    ValidateUsername(newUsername);
    ValidateConfirmUsername(confirmUsername, newUsername);
  }

  function OnConfirmUsernameChange(event) {
    // console.log(event);
    var newConfirmUsername = event.target.value.trim();
    SetConfirmUsername(newConfirmUsername);
    ValidateConfirmUsername(newConfirmUsername, username);
  }

  function OnFullnameChange(event) {
    var newFullname = event.target.value.trim();
    SetFullname(newFullname);
    ValidateFullname(newFullname);
  }
  function OnPhonenumberChange(event) {
    var newPhonenumber = event.target.value.replace("-", "").trim();
    if (isNaN(newPhonenumber)) {
      ValidatePhonenumber(newPhonenumber);
      return;
    }
    SetPhonenumber(newPhonenumber);
    ValidatePhonenumber(newPhonenumber);
  }
  function OnCityChange(event) {
    var newCity = event === null ? "" : event.trim();
    SetCity(newCity);
    ValidateCity(newCity);
  }

  function OnSchoolChange(event) {
    var newSchool = event === null ? "" : event.trim();
    SetSchool(newSchool);
    ValidateSchool(newSchool);
  }

  function OnPasswordChange(event) {
    var newPassword = event.target.value.trim();
    SetPassword(newPassword);
    ValidatePassword(newPassword);
    ValidateConfirmpassword(confirmpassword, newPassword);
  }

  function OnConfirmpasswordChange(event) {
    var newConfirmpassword = event.target.value.trim();
    SetConfirmpassword(newConfirmpassword);
    ValidateConfirmpassword(newConfirmpassword, password);
  }

  function OnReferalChange(event) {
    var newReferal = event;
    SetReferal(newReferal);
    ValidateReferal(newReferal);
  }

  //Validators
  function ValidateUsername(newUsername) {
    if (newUsername.length === 0) {
      SetUsernameErrorText("This field can not be empty");
      SetUsernameValid(false);
      return false;
    }
    var regex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!regex.test(newUsername)) {
      SetUsernameErrorText("Please enter a valid email address");
      SetUsernameValid(false);
      return false;
    } else {
      SetUsernameErrorText("");
      SetUsernameValid(true);
      return true;
    }
  }

  function ValidateConfirmUsername(newConfirmUsername, username) {
    if (newConfirmUsername !== username) {
      SetConfirmUsernameErrorText("Emails don't match");
      SetConfirmUsernameValid(false);
      return false;
    } else {
      SetConfirmUsernameErrorText("");
      SetConfirmUsernameValid(true);
      return true;
    }
  }

  function ValidateFullname(newFullname) {
    if (newFullname.length === 0) {
      SetFullnameErrorText("This field can not be empty");
      SetFullnameValid(false);
      return false;
    }
    if (newFullname.length < 5) {
      SetFullnameErrorText("Please enter your Full Name");
      SetFullnameValid(false);
      return false;
    }
    var regex = /^[a-zA-Z ]+$/;
    if (!regex.test(newFullname)) {
      SetFullnameErrorText("Full name can only have alphabets");
      SetFullnameValid(false);
      return false;
    } else {
      SetFullnameErrorText("");
      SetFullnameValid(true);
      return true;
    }
  }

  function ValidatePhonenumber(newPhonenumber) {
    if (newPhonenumber.length === 0) {
      SetPhonenumberErrorText("This field can not be empty");
      SetPhonenumberValid(false);
      return false;
    } else {
      /*  var regex = /^[0][3][0-9]{2}[0-9]{7}$/;
    if (!regex.test(newPhonenumber)) {
      SetPhonenumberErrorText("Please enter a valid Pakistani phone number");
      SetPhonenumberValid(false);
      return false;
    }  */
      SetPhonenumberErrorText("");
      SetPhonenumberValid(true);
      return true;
    }
  }

  function ValidateCity(newCity) {
    if (!newCity || newCity.length === 0) {
      SetCityErrorText("This field can not be empty");
      SetCityValid(false);
      return false;
    } else {
      SetCityErrorText("");
      SetCityValid(true);
      return true;
    }
  }

  function ValidateSchool(newSchool) {
    if (!newSchool || newSchool.length === 0) {
      SetSchoolErrorText("This field can not be empty");
      SetSchoolValid(false);
      return false;
    } else {
      SetSchoolErrorText("");
      SetSchoolValid(true);
      return true;
    }
  }

  function ValidatePassword(newPassword) {
    if (newPassword.length === 0) {
      SetPasswordErrorText("This field can not be empty");
      SetPasswordValid(false);
      return false;
    }
    if (newPassword.length < 8) {
      SetPasswordErrorText("Password must be atleast 8 characters long");
      SetPasswordValid(false);
      return false;
    } else {
      SetPasswordErrorText("");
      SetPasswordValid(true);
      return true;
    }
  }

  function ValidateConfirmpassword(newConfirmpassword, password) {
    if (newConfirmpassword !== password) {
      SetConfirmpasswordErrorText("Passwords don't match");
      SetConfirmpasswordValid(false);
      return false;
    } else {
      SetConfirmpasswordErrorText("");
      SetConfirmpasswordValid(true);
      return true;
    }
  }

  function ValidateReferal(newReferal) {
    return true;
    if (!newReferal || newReferal.length === 0) {
      SetReferalErrorText("This field can not be empty");
      SetReferalValid(false);
      return false;
    } else {
      SetReferalErrorText("");
      SetReferalValid(true);
      return true;
    }
  }

  //Submission Logic

  function OnSubmitClicked() {
    var usernamevalid = ValidateUsername(username);
    var confirmusernamevalid = ValidateConfirmUsername(
      confirmUsername,
      username
    );
    var fullnamevalid = ValidateFullname(fullname);
    var phonenumbervalid = ValidatePhonenumber(phonenumber);
    var cityvalid = ValidateCity(city);
    var schoolvalid = ValidateSchool(school);
    var passwordvalid = ValidatePassword(password);
    var confirmpasswordvalid = ValidateConfirmpassword(
      confirmpassword,
      password
    );
    var referalValid = ValidateReferal(referal);
    console.log(referalValid);
    SetShakeUsername(!usernamevalid);
    SetShakeConfirmUsername(!confirmUsernameValid);
    SetShakeFullname(!fullnamevalid);
    SetShakePhonenumber(!phonenumbervalid);
    SetShakeCity(!cityvalid);
    SetShakeSchool(!schoolvalid);
    SetShakePassword(!passwordvalid);
    SetShakeConfirmpassword(!confirmpasswordvalid);
    SetShakeReferal(!referalValid);

    if (
      usernamevalid &&
      confirmusernamevalid &&
      fullnamevalid &&
      phonenumbervalid &&
      cityvalid &&
      schoolvalid &&
      passwordvalid &&
      confirmpasswordvalid &&
      referalValid
    ) {
      SetWaitingOnResponse(true);
      SetServerError(false);
      SetServerErrorText("");
      Signup({
        fullname: fullname,
        username: username.toLowerCase().trim(),
        phonenumber: phonenumber,
        city: city,
        school: school,
        password: password.trim(),
        referal: referal,
      })
        .then((res) => {
          SetWaitingOnResponse(false);
          ResolveServerResponse(res);
        })
        .catch(() => {
          SetWaitingOnResponse(false);
          SetServerError(true);
          SetServerErrorText("Network Error");
        });
    }
  }

  function ResolveServerResponse(res) {
    var data = res.data;
    if (data.Error === true) {
      SetServerError(true);
      SetServerErrorText(data.ErrorText);
    } else {
      SetServerError(false);
      SetServerErrorText("");
      ReactGA.event({
        category: "User Accounts",
        action: "Register",
      });
      Login({
        username: username.toLowerCase().trim(),
        password: password.trim(),
      }).then((res) => {
        RefreshUserContext(SetUserContext, userContext).then(() => {
          history.push("/dashboard?newuser=1");
        });
      });
    }
  }
  return (
    <div
      className={`${styles.parentContainer} d-flex py-5 justify-content-center`}
    >
      <div className={`${styles.mainContainer} m-0 p-5`}>
        <div class="row">
          <div class="col-12 col-lg-8">
            <div className={styles.DesktopPageTitleDiv}>
              <h1 className={styles.mainTitle}>Real Stories</h1>
              <h1 className={styles.mainTitle}>Real Users</h1>
              <p className={styles.mainSubtitle}>
                Hear from them and get inspired
              </p>
            </div>
            <div className={styles.MobilePageTitleDiv}>
              <h1 className={styles.mainTitle}>Sign Up on</h1>
              <h1 className={styles.mainTitle}>PreMed.PK</h1>
              <p className={styles.mainSubtitle}>
                Start your journey towards becoming a future doctor
              </p>
            </div>
            <img className={styles.TitleImage} src={Group18} alt="" />
            <div class="row">
              <div class="col p-3">
                <div className={`${styles.comments} ${styles.commentLeft}`}>
                  <h3 className={`${styles.commentTitle}`}>
                    {" "}
                    <span className={`${styles.strong}`}>
                      Aliha Shabbir,
                    </span>{" "}
                    <span className={`${styles.weak}`}>
                      King Edward Medical University and AKU Class of 2026
                    </span>{" "}
                  </h3>
                  <p className={`${styles.commentText}`}>
                    The website is very impressively organised. I would
                    definitely recommend all the premedical students to use
                    PreMed if they want to ace their entrance exams!
                  </p>
                </div>
              </div>
              <div class="col p-3 ">
                <div className={`${styles.comments} ${styles.commentRight1}`}>
                  <h3 className={`${styles.commentTitle}`}>
                    {" "}
                    <span className={`${styles.strong}`}>
                      Aliza Aamir,{" "}
                    </span>{" "}
                    <span className={`${styles.weak}`}>
                      LUMHS Jamshoro Class of 2027
                    </span>{" "}
                  </h3>
                  <p className={`${styles.commentText}`}>
                    This website is a lifesaver. It enables you to strengthen
                    your weaker aspects with topical MCQs of every chapter.
                    Without a doubt, I would recommend this to all premed
                    students if they want to ace their exams. Thank You PreMed!
                    It would not have been possible without your help.
                  </p>
                </div>
                <div className={`${styles.comments} ${styles.commentRight2}`}>
                  <h3 className={`${styles.commentTitle}`}>
                    {" "}
                    <span className={`${styles.strong}`}>
                      Arsalan Bakhtiyar,{" "}
                    </span>{" "}
                    <span className={`${styles.weak}`}>
                      Khyber Medical College Peshawar Class of 2027
                    </span>{" "}
                  </h3>
                  <p className={`${styles.commentText}`}>
                    This website is a gem in providing authentic stuff related
                    to MDCAT. It has all the resources anyone would need, I
                    highly recommend it to all the aspirants out there.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-lg-4 d-flex justify-content-end">
            <div
              class={`${styles.signupCard} p-3 d-flex flex-column justify-content-between`}
            >
              <div>
                <h1 className={`${styles.cardTitle}`}>Create an Account</h1>
                <p className={`${styles.cardSubtitle}`}>Start Learning Today</p>
              </div>
              <div
                className={styles.ErrorAlert}
                style={serverError ? { display: "" } : { display: "none" }}
              >
                <Alert severity="error">
                  <AlertTitle>Error</AlertTitle>
                  {serverErrorText}
                </Alert>
              </div>
              <div
                className={styles.InputField}
                style={
                  shakeFullname ? { animation: "shake .5s" } : { animation: "" }
                }
                onAnimationEnd={() => {
                  SetShakeFullname(false);
                }}
              >
                <TextField
                  fullWidth
                  id="fullname"
                  name="fullname"
                  label={fullname ? "" : "Full Name"}
                  error={!fullnameValid}
                  variant="outlined"
                  helperText={fullnameErrorText}
                  inputProps={{ style: { fontSize: "1em" }, maxLength: 50 }} // font size of input text
                  InputLabelProps={{
                    style: { fontSize: "1em", backgroundColor: "white" },
                    shrink: false,
                  }} // font size of input label
                  onChange={OnFullnameChange}
                />
              </div>
              <div
                className={styles.InputField}
                style={
                  shakeUsername ? { animation: "shake .5s" } : { animation: "" }
                }
                onAnimationEnd={() => {
                  SetShakeUsername(false);
                }}
              >
                <TextField
                  fullWidth
                  id="email"
                  name="email"
                  label={username ? "" : "Email"}
                  error={!usernameValid}
                  variant="outlined"
                  helperText={usernameErrorText}
                  inputProps={{ style: { fontSize: "1em" }, maxLength: 50 }} // font size of input text
                  InputLabelProps={{
                    style: { fontSize: "1em", backgroundColor: "white" },
                    shrink: false,
                  }} // font size of input label
                  onChange={OnUsernameChange}
                />
              </div>
              <div
                className={styles.InputField}
                style={
                  shakeConfirmUsername
                    ? { animation: "shake .5s" }
                    : { animation: "" }
                }
                onAnimationEnd={() => {
                  SetShakeConfirmUsername(false);
                }}
              >
                <TextField
                  fullWidth
                  autoComplete="false"
                  value={confirmUsername}
                  id="email"
                  name="email"
                  label={confirmUsername ? "" : "Confirm Email Address"}
                  error={!confirmUsernameValid}
                  variant="outlined"
                  helperText={confirmUsernameErrorText}
                  inputProps={{ style: { fontSize: "1em" }, maxLength: 50 }} // font size of input text
                  InputLabelProps={{
                    style: { fontSize: "1em", backgroundColor: "white" },
                    shrink: false,
                  }} // font size of input label
                  onChange={OnConfirmUsernameChange}
                />
              </div>
              <div
                className={styles.InputField}
                style={
                  shakePassword ? { animation: "shake .5s" } : { animation: "" }
                }
                onAnimationEnd={() => {
                  SetShakePassword(false);
                }}
              >
                <TextField
                  fullWidth
                  id="password"
                  name="password"
                  label={password ? "" : "Password"}
                  error={!passwordValid}
                  variant="outlined"
                  type={showPassword ? "text" : "password"}
                  helperText={passwordErrorText}
                  onChange={OnPasswordChange}
                  inputProps={{
                    style: { fontSize: "1em" },
                    maxLength: 50,
                  }} // font size of input text
                  InputLabelProps={{
                    style: { fontSize: "1em", backgroundColor: "white" },
                    shrink: false,
                  }} // font size of input label
                  //FormHelperTextProps={{ style: { fontSize: "1em" } }} // font size of input label
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => {
                            SetShowPassword(!showPassword);
                          }}
                        >
                          {showPassword ? (
                            <VisibilityIcon />
                          ) : (
                            <VisibilityOffIcon />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </div>

              <div
                className={styles.InputField}
                style={
                  shakeConfirmpassword
                    ? { animation: "shake .5s" }
                    : { animation: "" }
                }
                onAnimationEnd={() => {
                  SetShakeConfirmpassword(false);
                }}
              >
                <TextField
                  fullWidth
                  id="password"
                  name="password"
                  label={confirmpassword ? "" : "Confirm Password"}
                  error={!confirmpasswordValid}
                  variant="outlined"
                  type={showPassword ? "text" : "password"}
                  helperText={confirmpasswordErrorText}
                  onChange={OnConfirmpasswordChange}
                  inputProps={{
                    style: { fontSize: "1em" },
                    maxLength: 50,
                  }} // font size of input text
                  InputLabelProps={{
                    style: { fontSize: "1em", backgroundColor: "white" },
                    shrink: false,
                  }} // font size of input label
                  //FormHelperTextProps={{ style: { fontSize: "1em" } }} // font size of input label
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => {
                            SetShowPassword(!showPassword);
                          }}
                        >
                          {showPassword ? (
                            <VisibilityIcon />
                          ) : (
                            <VisibilityOffIcon />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
              <div
                className={styles.InputField}
                style={
                  shakeFullname ? { animation: "shake .5s" } : { animation: "" }
                }
                onAnimationEnd={() => {
                  SetShakePhonenumber(false);
                }}
              >
                <TextField
                  fullWidth
                  id="phonenumber"
                  name="phonenumber"
                  label={phonenumber ? "" : "Phone Number"}
                  error={!phonenumberValid}
                  variant="outlined"
                  helperText={phonenumberErrorText}
                  inputProps={{ style: { fontSize: "1em" }, maxLength: 50 }} // font size of input text
                  InputLabelProps={{
                    style: { fontSize: "1em", backgroundColor: "white" },
                    shrink: false,
                  }} // font size of input label
                  value={phonenumber}
                  onChange={OnPhonenumberChange}
                />
              </div>
              <div
                className={styles.SelectField}
                style={
                  shakeCity ? { animation: "shake .5s" } : { animation: "" }
                }
                onAnimationEnd={() => {
                  SetShakeCity(false);
                }}
              >
                <Autocomplete
                  id="city"
                  name="city"
                  filterOptions={filterOptions}
                  options={cities}
                  renderOption={(option) => (
                    <Typography style={{ fontSize: "0.9em" }}>
                      {option}
                    </Typography>
                  )}
                  onChange={(event, newValue) => {
                    OnCityChange(newValue);
                  }}
                  onInputChange={(event, newValue) => {
                    SetCityRaw(newValue);
                  }}
                  classes={{ paper: classes.select }}
                  getOptionLabel={(option) => option}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={cityRaw ? "" : "City"}
                      variant="outlined"
                      error={!cityValid}
                      helperText={cityErrorText}
                      inputProps={{
                        ...params.inputProps,
                        style: { fontSize: "1em" },
                      }}
                      InputLabelProps={{
                        style: {
                          fontSize: "1em",
                          backgroundColor: "white",
                        },
                        shrink: false,
                      }} // font size of input label
                    />
                  )}
                />
              </div>
              <div
                className={styles.SelectField}
                style={
                  shakeSchool ? { animation: "shake .5s" } : { animation: "" }
                }
                onAnimationEnd={() => {
                  SetShakeSchool(false);
                }}
              >
                <Autocomplete
                  id="school"
                  name="school"
                  filterOptions={filterOptions}
                  freeSolo={true}
                  options={schools}
                  renderOption={(option) => (
                    <Typography style={{ fontSize: "0.9rem" }}>
                      {option}
                    </Typography>
                  )}
                  onInputChange={(event, newValue) => {
                    OnSchoolChange(newValue);
                  }}
                  classes={{ paper: classes.select }}
                  getOptionLabel={(option) => option}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={school ? "" : "School / College"}
                      variant="outlined"
                      error={!schoolValid}
                      helperText={schoolErrorText ? schoolErrorText + ". " : ""}
                      inputProps={{
                        ...params.inputProps,
                        style: { fontSize: "1rem" },
                        maxLength: 50,
                      }}
                      InputLabelProps={{
                        style: {
                          fontSize: "1em",
                          backgroundColor: "white",
                        },
                        shrink: false,
                      }} // font size of input label
                    />
                  )}
                />
              </div>
              <div
                className={styles.SelectField}
                style={
                  shakereferal ? { animation: "shake .5s" } : { animation: "" }
                }
                onAnimationEnd={() => {
                  SetShakeReferal(false);
                }}
              >
                <Autocomplete
                  id="referal"
                  name="referal"
                  filterOptions={filterOptions}
                  options={["Facebook", "Instagram", "Twitter"]}
                  renderOption={(option) => (
                    <Typography style={{ fontSize: "0.9em" }}>
                      {option}
                    </Typography>
                  )}
                  onChange={(event, newValue) => {
                    OnReferalChange(newValue);
                  }}
                  onInputChange={(event, newValue) => {
                    SetReferalRaw(newValue);
                  }}
                  classes={{ paper: classes.select }}
                  getOptionLabel={(option) => option}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={referalRaw ? "" : "How did you hear about us"}
                      variant="outlined"
                      error={!referalValid}
                      helperText={referalErrorText}
                      inputProps={{
                        ...params.inputProps,
                        style: { fontSize: "1em" },
                      }}
                      InputLabelProps={{
                        style: {
                          fontSize: "1em",
                          backgroundColor: "white",
                        },
                        shrink: false,
                      }} // font size of input label
                    />
                  )}
                />
              </div>
              <button
                className={`${styles.submitButton} mb-2 w-100`}
                disabled={waitingOnResponse}
                onClick={OnSubmitClicked}
              >
                <div class="d-flex m-0 p-0 justify-content-between">
                  <p className={`${styles.buttonLabel} m-0`}>Sign Up</p>
                  <i class="bi bi-arrow-right m-0"></i>
                </div>
              </button>

              <Link to="/Login" className={`w-100 ${styles.alternateLink}`}>
                {" "}
                Existing user? Sign in instead
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const schools = [
  "Adamjee Govt. Science College, Karachi",
  "Islamia Govt. Science College, Karachi",
  "Govt. PECHS Education Foundation Science College, Karachi",
  "Govt. Degree Science & Commerce College, Gulshan-e-Iqbal, Karachi",
  "Govt. Degree Boys College, Gulzar-e-Hijri, Karachi",
  "Govt. National College, Karachi",
  "Govt. Degree Boys College, Gulistan-e-Jauhar, Karachi",
  "Govt. Jamia Millia Degree College, Karachi",
  "Govt. Superior Science College, Karachi",
  "Govt. Boys Inter College, Landhi, Karachi",
  "Allama Iqbal Govt. – Boys Science College, Karachi",
  "Govt Degree College, Malir Cantt, Karachi",
  "ARG Govt. Degree Boys College, Landhi, Quaidabad, Karachi",
  "Govt. Degree College Murad Memon Goth, Karachi",
  "Govt. Degree Boys College, Korangi, Karachi",
  "D.J Sindh Govt. Science College, Karachi",
  "S.M. Govt. Science College, Shahrah-e-Liaquat, Karachi",
  "Govt. Aisha Bawani College, Karachi",
  "Govt. Degree Science & Commerce College, Lyari, Karachi",
  "Haji Abdullah Haroon Govt. College, Karachi",
  "Govt. Delhi Inter Science College",
  "Govt. City College Musa Colony, Karachi",
  "Govt. Degree Science College, Liaquatabad, Karachi",
  "Govt. Sirajud Daula Degree College, Karachi",
  "Pakistan Shipowners Govt. College, Karachi",
  "Govt. Degree Boys College, North Karachi, Karachi",
  "Govt Degree Boys College, North Karachi",
  "Govt. Degree Boys College, Mangopir, Karachi",
  "Govt. Degree Boys College, Konkar, Karachi",
  "Govt. Degree Boys College, Surjani Town, Karachi",
  "Govt. Degree Boys College, New Karachi, Karachi",
  "Govt. Degree Boys College, Buffer Zone, Karachi",
  "Govt. Jinnah College, Nazimabad, Karachi",
  "Govt. Degree Science & Commerce College, Orangi Town, Karachi",
  "Govt. Degree Boys College Asifabad, Karachi",
  "Govt. Degree Boys College, Keamari, Karachi",
  "Govt. College For Men, Nazimabad, Karachi",
  "Govt. Degree Boys College, Shams Pir, Karachi",
  "Govt. Degree Boys College, Baldia Town, Karachi",
  "Govt. Degree Boys College,  KMC Store NIshter Road, Karachi",
  "Begum Nusrat Bhutto Govt. Boys College, Lyari, Karachi",
  "Govt. Degree Boys College, Ibrahim Hyderi, Karachi",
  "Govt. Boys H.S.S. Kamal Khan Jokhio, Karachi",
  "Ghazi Muhammad Bin Qasim H.S.S. Agra Taj Colony Chak,",
  "Allama Iqbal Govt. Boys H.S.S., Block 16, FB Area Karachi",
  "Govt. Comprehensive H.S.S, Korangi-0.3, Karachi",
  "Govt. Boys Comprehensive H.S.S., Azizabad, Karachi",
  "Govt. Boys H.S.S. No. 2 K, Area Korangi, Karachi",
  "Govt. Mumtaz Boys H.S.S. Landhi, Karachi",
  "Govt. Boys H.S.S. Konkar Village Malir Karachi",
  "Abdullah Govt. College for Women, Karachi",
  "Govt. College for Women, Nazimabad, Karachi",
  "Sir Syed Govt. Girls College, Karachi",
  "APWA Govt. College for Women, Karachi",
  "Shaheed-e-Millat Govt. Degree Girls College, Azizabad, Karachi",
  "Govt. Degree Girls College, Al-Noor, FB Area, Karachi",
  "Riaz Govt. Girls College, Liaquatabad, Karachi",
  "Govt. Degree College for Women, Nazimabad, Karachi",
  "Govt. Girls Degree College, North Karachi",
  "Govt. Girls Degree College, Nazimabad, Karachi",
  "Govt. College for Women, New Karachi, Karachi",
  "Govt. Girls Inter College Orangi Sector, Karachi",
  "Govt. Degree Girls College, Orangi, Karachi",
  "Govt. Girls College, Metroville SITE, Karachi",
  "Govt. College for Women, Shahrah-e-Liaquat, Karachi",
  "Govt. Inter Girls College, Lyari, Karachi",
  "Govt. Karachi College for Women, Chand Bibi Road, Karachi",
  "Govt. SMB Fatima Jinnah Girls College, Karachi",
  "Govt. Girls Degree College Zamzama, Karachi",
  "Govt. College for Women, Korangi 06, Karachi",
  "Govt, College for Women, Korangi-4, Karachi",
  "Govt. Girls Degree College, Korangi, Karachi",
  "Govt Degree Girls College, Ibrahim Hydri, Karachi",
  "Govt. College for Women Saudabad, Karachi",
  "Govt. Girls Science College, Shah Faisal Colony, Karachi",
  "Allama Iqbal Govt. Girls College, Shah Faisal Colony No. 3, Karachi",
  "Liaquat Govt. Girls College Malir, Karachi",
  "Govt. Degree College Malir Cantt, Karachi",
  "Govt. Girls College, Landhi, Karachi",
  "Govt. Girls College, PIB Colony, Karachi",
  "St. Lawrance Govt. College for Women, Karachi",
  "Govt. Degree Girls College, Lines Area, Karachi",
  "Bamm PECHS Govt. College for Women, Karachi",
  "Khatoon-e-Pakistan Government College for Women, Stadium Road",
  "Govt. Degree College for Boys and Girls Stadium Road, Karachi",
  "Govt. Degree Science and Commerce College, Gulshan-e-Iqbal, Karachi",
  "Govt. Girls Degree College, Mehmoodabad, Karachi",
  "Govt. Degree Girls College, Safora Goth, Gulistan-e-Jauhar, Karachi",
  "Govt. Degree Girls College, Pak Colony, Karachi",
  "Govt. Degree Girls College, Buffer Zone, Karachi",
  "Jan Muhammad Brohi Govt. Degree Girls College, Gulshah-e- Maymar, Karachi",
  "Govt. Degree Girls College, Surjani Town, Karachi",
  "Govt. Degree Girls College, Shahnawaz Shar Goth, Karachi",
  "Govt. Girls Degree College, Gulzar-e-Hijri, Karachi",
  "Marium Mukhtiar Govt. Girls Degree College, Liaquatabad, Karachi",
  "Bibi Asifa Govt. Girls College, Muzaffarabad, Landhi, Karachi",
  "Shaheed Benazir Bhutto Govt. Girls College, Lyari, Karachi",
  "Govt. Girls College, Sharrafi Goth, Karachi",
  "Govt. Girls H.S.S. Landhi, Karachi",
  "Govt. Comprehensive H.S.S, North Nazimabad, Karachi",
  "Govt. Girls H.S.S. Bhiro Village, Karachi",
  "APWA Govt. Girls H.S.S., Liaquatabad, Karachi",
  "PAF Govt. Girls H.S.S. Karachi",
  "Govt. Girls H.S.S. Green Belt, Karachi",
  "Khaldunia Public School, Islamabad",
  "The City School",
  "Bahria College",
  "Cadet College, Hassanabdal",
  "Army Public School",
  "Beaconhouse School",
  "Lahore Grammar School",
  "Nixor A-Levels",
  "Karachi Grammar School",
  "The Lyceum A-Levels",
  "Cedar A-Levels",
  "Cordoba School for A-Levels",
  "Dawood Public School A-Levels",
  "Aisha Bawany Academy",
  "Karachi Public School",
  "Pak-Turk International Schools and Colleges",
  "Bahria Foundation College, Nazimabad",
  "Foundation Public School",
  "St. Michaels Convent School",
  "St. Joseph’s Convent School",
  "Habib Public School",
  "Hamdard Public School",
  "Garrison Academy",
  "St. Anthony’s College",
  "St. Patrick’s High School",
  "St. Peter’s High School",
  "Progressive Public School",
  "DA Public School",
  "White House Grammar School",
  "Generation’s School",
  "The Avicenna School",
  "Froebel Education Centre",
  "Washington International School",
  "L’ecole for Advance Studies",
  "Karachi Cadet School",
  "Falconhouse Grammar School",
  "The OASYS School",
  "Westminster School & College",
  "Aitchison Model School",
  "Jaffar Public School",
  "K N Academy",
  "Ladybird Grammar School",
  "The American School",
  "Happy Home School",
  "Shaheen Public School",
  "Southshore School for A-Level Studies",
  "The Indus Academy",
  "Usman Public School",
  "The A.M.I School",
  "Anees Hussain, Karachi",
  "Meritorious Science College, Karachi",
  "Roots Millennium College, Karachi",
  "Dar-e-Arqam Schools",
  "Sadiq Public School",
  "Kinnaird College",
  "COMMECS College",
  "NCR-CET College",
  "Fazaia Inter College",
  "DHACSS Shaikh Khalifa Bin Zayed Campus",
  "Punjab Group of Colleges",
  "Private Intermediate",
  "Private A-Levels",
  "Allied School",
  "TCF School",
];
const cities = [
  "Abbottabad",
  "Adezai",
  "Ali Bandar",
  "Amir Chah",
  "Attock",
  "Ayubia",
  "Bahawalpur",
  "Baden",
  "Bagh",
  "Bahawalnagar",
  "Burewala",
  "Banda Daud Shah",
  "Bannu district|Bannu",
  "Batagram",
  "Bazdar",
  "Bela",
  "Bellpat",
  "Bhag",
  "Bhakkar",
  "Bhalwal",
  "Bhimber",
  "Birote",
  "Buner",
  "Burj",
  "Chiniot",
  "Chachro",
  "Chagai",
  "Chah Sandan",
  "Chailianwala",
  "Chakdara",
  "Chakku",
  "Chakwal",
  "Chaman",
  "Charsadda",
  "Chhatr",
  "Chichawatni",
  "Chitral",
  "Dadu",
  "Dera Ghazi Khan",
  "Dera Ismail Khan",
  "Dalbandin",
  "Dargai",
  "Darya Khan",
  "Daska",
  "Dera Bugti",
  "Dhana Sar",
  "Digri",
  "Dina City|Dina",
  "Dinga",
  "Diwana",
  "Dokri",
  "Drosh",
  "Duki",
  "Dushi",
  "Duzab",
  "Faisalabad",
  "Fateh Jang",
  "Ghotki",
  "Gwadar",
  "Gujranwala",
  "Gujrat",
  "Gadra",
  "Gajar",
  "Gandava",
  "Garhi Khairo",
  "Garruck",
  "Ghakhar Mandi",
  "Ghanian",
  "Ghauspur",
  "Ghazluna",
  "Girdan",
  "Gulistan",
  "Gwash",
  "Hyderabad",
  "Hala",
  "Haripur",
  "Hab Chauki",
  "Hafizabad",
  "Hameedabad",
  "Hangu",
  "Harnai",
  "Hasilpur",
  "Haveli Lakha",
  "Hinglaj",
  "Hoshab",
  "Islamabad",
  "Islamkot",
  "Ispikan",
  "Jacobabad",
  "Jamshoro",
  "Jhang",
  "Jhelum",
  "Jamesabad",
  "Jampur",
  "Janghar",
  "Jati(Mughalbhin)",
  "Jauharabad",
  "Jhal",
  "Jhal Jhao",
  "Jhatpat",
  "Jhudo",
  "Jiwani",
  "Jungshahi",
  "Karachi",
  "Kotri",
  "Kalam",
  "Kalandi",
  "Kalat",
  "Kamalia",
  "Kamararod",
  "Kamber",
  "Kamokey",
  "Kanak",
  "Kandi",
  "Kandiaro",
  "Kanpur",
  "Kapip",
  "Kappar",
  "Karak City",
  "Karodi",
  "Kashmor",
  "Kasur",
  "Katuri",
  "Keti Bandar",
  "Khairpur",
  "Khanaspur",
  "Khanewal",
  "Kharan",
  "kharian",
  "Khokhropur",
  "Khora",
  "Khushab",
  "Khuzdar",
  "Kikki",
  "Klupro",
  "Kohan",
  "Kohat",
  "Kohistan",
  "Kohlu",
  "Korak",
  "Korangi",
  "Kot Sarae",
  "Kotli",
  "Lahore",
  "Larkana",
  "Lahri",
  "Lakki Marwat",
  "Lasbela",
  "Latamber",
  "Layyah",
  "Leiah",
  "Liari",
  "Lodhran",
  "Loralai",
  "Lower Dir",
  "Shadan Lund",
  "Multan",
  "Mandi Bahauddin",
  "Mansehra",
  "Mian Chanu",
  "Mirpur",
  ", Pakistan|Moro",
  "Mardan",
  "Mach",
  "Madyan",
  "Malakand",
  "Mand",
  "Manguchar",
  "Mashki Chah",
  "Maslti",
  "Mastuj",
  "Mastung",
  "Mathi",
  "Matiari",
  "Mehar",
  "Mekhtar",
  "Merui",
  "Mianwali",
  "Mianez",
  "Mirpur Batoro",
  "Mirpur Khas",
  "Mirpur Sakro",
  "Mithi",
  "Mongora",
  "Murgha Kibzai",
  "Muridke",
  "Musa Khel Bazar",
  "Muzaffar Garh",
  "Muzaffarabad",
  "Nawabshah",
  "Nazimabad",
  "Nowshera",
  "Nagar Parkar",
  "Nagha Kalat",
  "Nal",
  "Naokot",
  "Nasirabad",
  "Nauroz Kalat",
  "Naushara",
  "Nur Gamma",
  "Nushki",
  "Nuttal",
  "Okara",
  "Ormara",
  "Peshawar",
  "Panjgur",
  "Pasni City",
  "Paharpur",
  "Palantuk",
  "Pendoo",
  "Piharak",
  "Pirmahal",
  "Pishin",
  "Plandri",
  "Pokran",
  "Pounch",
  "Quetta",
  "Qambar",
  "Qamruddin Karez",
  "Qazi Ahmad",
  "Qila Abdullah",
  "Qila Ladgasht",
  "Qila Safed",
  "Qila Saifullah",
  "Rawalpindi",
  "Rabwah",
  "Rahim Yar Khan",
  "Rajan Pur",
  "Rakhni",
  "Ranipur",
  "Ratodero",
  "Rawalakot",
  "Renala Khurd",
  "Robat Thana",
  "Rodkhan",
  "Rohri",
  "Sialkot",
  "Sadiqabad",
  "Safdar Abad- (Dhaban Singh)",
  "Sahiwal",
  "Saidu Sharif",
  "Saindak",
  "Sakrand",
  "Sanjawi",
  "Sargodha",
  "Saruna",
  "Shabaz Kalat",
  "Shadadkhot",
  "Shahbandar",
  "Shahpur",
  "Shahpur Chakar",
  "Shakargarh",
  "Shangla",
  "Sharam Jogizai",
  "Sheikhupura",
  "Shikarpur",
  "Shingar",
  "Shorap",
  "Sibi",
  "Sohawa",
  "Sonmiani",
  "Sooianwala",
  "Spezand",
  "Spintangi",
  "Sui",
  "Sujawal",
  "Sukkur",
  "Suntsar",
  "Surab",
  "Swabi",
  "Swat",
  "Tando Adam",
  "Tando Bago",
  "Tangi",
  "Tank City",
  "Tar Ahamd Rind",
  "Thalo",
  "Thatta",
  "Toba Tek Singh",
  "Tordher",
  "Tujal",
  "Tump",
  "Turbat",
  "Umarao",
  "Umarkot",
  "Upper Dir",
  "Uthal",
  "Vehari",
  "Veirwaro",
  "Vitakri",
  "Wadh",
  "Wah Cantt",
  "Warah",
  "Washap",
  "Wasjuk",
  "Wazirabad",
  "Yakmach",
  "Zhob",
  "Other",
];
