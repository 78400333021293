import React, { useState, useContext, useEffect } from "react";
import { Prompt } from "react-router-dom";
import styles from "./QuestionComponent.module.css";
import { UserContext } from "../../userContext";
import { Card, CardBody } from "shards-react";

import { CircularProgress } from "@material-ui/core";

import QuestionText from "./QuestionText";
import QuizOptionsContainer from "./QuizOptionsContainer";
import QuestionCardHeader from "./QuestionCardHeader";
import BookMark from "./BookMark";
import FlagButton from "./FlagButton";
import TagList from "./TagList";
import NavButton from "./NavButtons";
import ReportQuestion from "./ReportQuestionComponent";
import TestSkipQuestion from "./TestSkipQuestionComponent";
//import TestSkipQuestion from "./TestSkipQuestionComponent";
import ExplanationFooter from "./ExplanationFooter";

import ProgressBar from "./ProgressBar";
import { Pause } from "@material-ui/icons";

//noti stackl for snack bar popup mamnahemnt
const Tags = ["PMC Sample 1", "Biology"];

const TestModeQuestionComponent = ({
  deckName,
  questionNo,
  NextQuestion,
  PreviousQuestion,
  currentQuestion,
  nextEnabled,
  backEnabled,
  isExplanationPurchased,
  loadingQuestion,
  SkipToQuestion,
  NoOfQuestions,
  testPaper,
  OnOptionClicked,
  testPaused,
  noOfAttemptedQuestions,
  OnFlagClicked,
  OnPause,
  OnResume,
  HideBookMarkBtn,
}) => {
  const [reportModalVisible, SetReportModalVisible] = useState(false);
  const [skipModalVisible, SetSkipModalVisible] = useState(false);
  const [eliminationToolEnabled, SetEliminationToolEnabled] = useState(false);

  const options = GenerateOptions();
  console.log(testPaper);
  const isQuestionFlagged = testPaper.filter(
    (q) => q.id == currentQuestion._id
  )[0].flagged;

  function GenerateOptions() {
    var optionsArray = currentQuestion?.Options?.map((option) => {
      return {
        color:
          testPaper[questionNo - 1].selectedOption === option.OptionLetter
            ? "QuestionBlue"
            : "OptionColor",
        isSelected:
          testPaper[questionNo - 1].selectedOption === option.OptionLetter,
        optionLetter: option.OptionLetter,
        optionText: option.OptionText,
      };
    });
    return optionsArray;
  }

  document.onkeydown = checkKey;
  //window.scrollTo({ top: 0, behavior: "smooth" })

  useEffect(() => {
    window.scrollTo({ top: 0 });
    console.log("Hello");
  }, [questionNo]);

  if (!currentQuestion.QuestionText) {
    return null;
  }

  function OnOptionPressed(optionLetter) {
    OnOptionClicked(optionLetter);
    OnAttempt(optionLetter);
  }

  function OnAttempt(optionLetter) {}

  return (
    <div className={styles.MainQuestionCard}>
      <Prompt
        when={true}
        message="There are unsaved changes, do you wish to discard them?"
      />
      <Card
        className={`${testPaused ? styles.TestBlur : " "}`}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        {reportModalVisible ? (
          <ReportQuestion
            OnCloseCB={() => {
              SetReportModalVisible(false);
              OnResume();
            }}
            OnSubmitCB={() => {
              SetReportModalVisible(false);
              OnResume();
            }}
            deckName={deckName}
            questionID={currentQuestion._id}
            isOpen={reportModalVisible}
          />
        ) : null}

        {skipModalVisible ? (
          <TestSkipQuestion
            OnCloseCB={() => {
              SetSkipModalVisible(false);
            }}
            isOpen={skipModalVisible}
            OnSkipCB={(no) => {
              SkipToQuestion(no);
              SetSkipModalVisible(false);
            }}
            numberOfQuestions={NoOfQuestions}
            testPaper={testPaper}
            blind={true}
          />
        ) : null}

        <QuestionCardHeader
          reportOnClick={() => {
            SetReportModalVisible(true);
            OnPause();
          }}
          skipOnClick={() => {
            SetSkipModalVisible(true);
          }}
        />

        <CardBody className={styles.QuestionBody}>
          {/* {RenderLoadingSpinner(classes.spinner, QuestionContent)} */}
          <div className={styles.QuestionMainBody}>
            {!loadingQuestion ? (
              <>
                <div className={styles.TestModeSaveBar}>
                  {!HideBookMarkBtn && (
                    <BookMark
                      questionID={currentQuestion._id}
                      deckName={deckName}
                      deckType={"Yearly"}
                    />
                  )}
                  <FlagButton
                    isFlagged={isQuestionFlagged}
                    onClick={() => {
                      OnFlagClicked(!isQuestionFlagged);
                    }}
                  />
                </div>
                <QuestionText questionText={currentQuestion.QuestionText} />
                {RenderQuestionImage(currentQuestion.QuestionImage)}
                <TagList tags={currentQuestion?.Tags.map((tag) => tag.name)} />
                <QuizOptionsContainer
                  showEliminate
                  options={options}
                  onClick={OnOptionPressed}
                  eliminationToolEnabled={eliminationToolEnabled}
                  toggleEliminationTool={(e) => {
                    SetEliminationToolEnabled(!eliminationToolEnabled);
                  }}
                  key={currentQuestion._id}
                />
              </>
            ) : (
              <div className={styles.QuestionLoading}>
                <CircularProgress size={70} />
              </div>
            )}
          </div>
          <NavButton
            questionNo={questionNo}
            isBackEnabled={backEnabled}
            isNextEnabled={nextEnabled}
            onBackClick={() => {
              PreviousQuestion();
            }}
            onNextClick={() => {
              NextQuestion();
            }}
          />
          <ProgressBar
            questionNo={questionNo}
            NoOfQuestions={NoOfQuestions}
            text={`${noOfAttemptedQuestions} / ${NoOfQuestions} attempted`}
          />
        </CardBody>
      </Card>
    </div>
  );

  function checkKey(e) {
    e = e || window.event;

    if (e.keyCode == "38") {
      // up arrow
    } else if (e.keyCode == "40") {
    } else if (e.keyCode == "37") {
      try {
        if (
          backEnabled &&
          !loadingQuestion &&
          !skipModalVisible &&
          !reportModalVisible
        ) {
          PreviousQuestion();
          /*  ReactGA.event({
            category: "Question",
            action: "ArrowKeyNext/Back",
            label: "Yearly/Topical",
          }); */
        }
      } catch {}
    } else if (e.keyCode == "39") {
      if (
        nextEnabled &&
        !loadingQuestion &&
        !skipModalVisible &&
        !reportModalVisible
      ) {
        NextQuestion();
        /*  ReactGA.event({
          category: "Question",
          action: "ArrowKeyNext/Back",
          label: "Yearly/Topical",
        }); */
      }
    }
  }
};

function RenderQuestionImage(image) {
  if (image) {
    return (
      <div>
        <img
          src={image}
          className={styles.QuestionImage}
          style={{
            marginRight: "1rem",
            marginTop: "1rem",
            marginBottom: "1rem",
          }}
        />
      </div>
    );
  } else {
    return <div />;
  }
}

export default TestModeQuestionComponent;
