import React, { useState, useEffect } from "react";
import Chip from "@material-ui/core/Chip";
import TagsIcon from "@material-ui/icons/LocalOffer";

const hiddenTags = ["bpb","bkp","cvc","pvp","bcf","bcq","bpq","dcq","cpq","dpq"];

const TagList = (props) =>{
    const {styleClass , tags, hideTagIcon}  = props;
    return (
        <div  className = {styleClass} style={{ marginBottom: "1em" }}>
          {tags.length > 0 && !hideTagIcon ? <TagsIcon style = {{marginTop : ".3em"}}/> : <div />}
          {tags.map((tag) => (
            <Chip
            style={
              hiddenTags.includes(tag.name) ? { display: "none" } : {}
            }
              size="medium"
              label={tag.name}
              key = {tag.name}
              color="secondary"
              variant="outlined"
            />
          ))}
        </div>
      );
}
export default TagList;
export { hiddenTags}