var PMCPracticeTestConfig = {
  Eng: 20,
  Bio: 68,
  Chem: 56,
  Phys: 56,
  Lr: 10,
  Time: 210,
};

var PMCPracticeTest2022Config = {
  Eng: 18,
  Bio: 68,
  Chem: 54,
  Phys: 54,
  Lr: 6,
  Time: 210,
};

const DeckMetaInfo = {
  "PreMedPK MDCAT Mock 1": {
    Eng: 18,
    Bio: 68,
    Chem: 54,
    Phys: 54,
    Lr: 6,
    Time: 210
  },
  "PreMedPK MDCAT Mock 2": {
    Eng: 18,
    Bio: 68,
    Chem: 54,
    Phys: 54,
    Lr: 6,
    Time: 210
  },
  "PreMedPK MDCAT Mock 3": {
    Eng: 18,
    Bio: 68,
    Chem: 54,
    Phys: 54,
    Lr: 6,
    Time: 210
  },
  "PreMedPK MDCAT Mock 4": {
    Eng: 18,
    Bio: 68,
    Chem: 54,
    Phys: 54,
    Lr: 6,
    Time: 210
  },
  "PreMedPK MDCAT Mock 5": {
    Eng: 18,
    Bio: 68,
    Chem: 54,
    Phys: 54,
    Lr: 6,
    Time: 210
  },
  "PMC Mock 1": {
    Eng: 20,
    Bio: 68,
    Chem: 56,
    Phys: 56,
    Lr: 10,
    Time: 210,
  },
  "NUMS Mock 1": {
    Eng: 15,
    Bio: 60,
    Chem: 38,
    Phys: 37,
    Time: 150,
  },
  "AKU Mock Paper 1": {
    Bio: 20,
    Chem: 20,
    Phys: 20,
    ScienceReasoning: 20,
    MathReasoning: 20,
    Time: 120,
  },
  "AKU Mock Paper 2": {
    Bio: 20,
    Chem: 20,
    Phys: 20,
    ScienceReasoning: 20,
    MathReasoning: 20,
    Time: 120,
  },
  "AKU Mock Paper 3": {
    Bio: 20,
    Chem: 20,
    Phys: 20,
    ScienceReasoning: 20,
    MathReasoning: 20,
    Time: 120,
  },
  "AKU Mock Paper 4": {
    Bio: 20,
    Chem: 20,
    Phys: 20,
    ScienceReasoning: 20,
    MathReasoning: 20,
    Time: 120,
  },
  "AKU Mock Paper 5": {
    Bio: 20,
    Chem: 20,
    Phys: 20,
    ScienceReasoning: 20,
    MathReasoning: 20,
    Time: 120,
  },
  "AKU Mock Paper 6": {
    Bio: 20,
    Chem: 20,
    Phys: 20,
    ScienceReasoning: 20,
    MathReasoning: 20,
    Time: 120,
  },

  "NUMS Mock Paper 1": {
    Eng: 15,
    Bio: 55,
    Chem: 40,
    Phys: 40,
    Psych: 100,
    Time: 180,
  },
  "NUMS Mock Paper 2": {
    Eng: 15,
    Bio: 55,
    Chem: 40,
    Phys: 40,
    Psych: 100,
    Time: 180,
  },
  "NUMS Mock Paper 3": {
    Eng: 15,
    Bio: 55,
    Chem: 40,
    Phys: 40,
    Psych: 100,
    Time: 180,
  },
  "NUMS Mock Paper 4": {
    Eng: 15,
    Bio: 55,
    Chem: 40,
    Phys: 40,
    Psych: 100,
    Time: 180,
  },

  "PMC National MDCAT 2020": {
    Eng: 20,
    Bio: 80,
    Chem: 60,
    Phys: 40,
    Time: 150,
    TutorModeFree: true,
  },
  "TEST DECK": { Eng: 20, Bio: 80, Chem: 60, Phys: 40, Time: 150 },
  "Sindh MCAT NTS 2019": {
    Eng: 20,
    Bio: 80,
    Chem: 60,
    Phys: 40,
    Time: 150,
    TutorModeFree: true,
  },
  "Sindh MCAT NTS 2018": { Eng: 10, Bio: 30, Chem: 30, Phys: 30, Time: 120 },
  "Sindh MCAT NTS 2017": { Eng: 10, Bio: 30, Chem: 30, Phys: 30, Time: 120 },
  "Sindh MCAT NTS 2016 (SMBBMC Lyari)": {
    Eng: 10,
    Bio: 30,
    Chem: 30,
    Phys: 30,
    Time: 120,
  },
  "Sindh MCAT NTS 2016 (DUHS and JSMU)": {
    Eng: 10,
    Bio: 30,
    Chem: 30,
    Phys: 30,
    Time: 120,
  },
  "Sindh MCAT NTS 2015 (SMBBMC Lyari)": {
    Eng: 10,
    Bio: 30,
    Chem: 30,
    Phys: 30,
    Time: 120,
  },
  "Sindh MCAT NTS 2015 (DUHS and JSMU)": {
    Eng: 10,
    Bio: 30,
    Chem: 30,
    Phys: 30,
    Time: 120,
  },
  "Sindh MCAT NTS 2014 (DUHS and JSMU)": {
    Eng: 10,
    Bio: 30,
    Chem: 30,
    Phys: 30,
    Time: 120,
  },
  "Sindh MCAT NTS 2013 (DUHS and JSMU)": {
    Eng: 10,
    Bio: 30,
    Chem: 30,
    Phys: 30,
    Time: 120,
  },
  "Sindh MCAT NTS 2012 (DUHS and JSMU)": {
    Eng: 10,
    Bio: 30,
    Chem: 30,
    Phys: 30,
    Time: 120,
  },
  "Sindh MCAT NTS 2011 (DUHS and JSMU)": {
    Eng: 10,
    Bio: 30,
    Chem: 30,
    Phys: 30,
    Time: 120,
  },
  "Sindh MCAT NTS 2010 (DUHS and JSMU)": {
    Eng: 10,
    Bio: 30,
    Chem: 30,
    Phys: 30,
    Time: 120,
  },
  "NUMS 2020": {
    Eng: 20,
    Bio: 80,
    Chem: 60,
    Phys: 40,
    Time: 150,
    TutorModeFree: false,
  },
  "NUMS 2021": {
    Eng: 20,
    Bio: 80,
    Chem: 60,
    Phys: 40,
    Time: 150,
    TutorModeFree: true,
  },
  "NUMS 2019 (Reconduct)": { Eng: 20, Bio: 80, Chem: 60, Phys: 40, Time: 150 },
  "NUMS 2019 (Cancelled)": { Eng: 20, Bio: 80, Chem: 60, Phys: 40, Time: 150 },
  "NUMS 2018": { Eng: 20, Bio: 80, Chem: 60, Phys: 40, Time: 150 },
  "NUMS 2017": { Eng: 20, Bio: 70, Chem: 45, Phys: 45, Time: 180 },
  "NUMS 2016": { Eng: 20, Bio: 70, Chem: 45, Phys: 45, Time: 180 },
  "NUMS 2015": { Eng: 20, Bio: 80, Chem: 60, Phys: 30, Lr: 10, Time: 180 },
  "NUMS 2014": { Eng: 20, Bio: 80, Chem: 60, Phys: 30, Lr: 10, Time: 180 },
  "PMC Sample Solved Paper 1": {
    Eng: 20,
    Bio: 68,
    Chem: 56,
    Phys: 56,
    Lr: 10,
    Time: 210,
  },
  "PMC Sample Solved Paper 2": {
    Eng: 20,
    Bio: 68,
    Chem: 56,
    Phys: 56,
    Lr: 10,
    Time: 210,
  },
  "PMC Sample Solved Paper 3": {
    Eng: 20,
    Bio: 68,
    Chem: 56,
    Phys: 56,
    Lr: 10,
    Time: 210,
  },
  "PMC Sample Solved Paper 4": {
    Eng: 20,
    Bio: 68,
    Chem: 56,
    Phys: 56,
    Lr: 10,
    Time: 210,
  },
  "PMC Sample Solved Paper 5": {
    Eng: 20,
    Bio: 68,
    Chem: 56,
    Phys: 56,
    Lr: 10,
    Time: 210,
  },
  "PMC Practice Test 1": {
    Eng: 20,
    Bio: 68,
    Chem: 56,
    Phys: 56,
    Lr: 10,
    Time: 210,
  },
  "PMC Practice Test 2": {
    Eng: 20,
    Bio: 68,
    Chem: 56,
    Phys: 56,
    Lr: 10,
    Time: 210,
  },
  "PMC Practice Test 3": {
    Eng: 20,
    Bio: 68,
    Chem: 56,
    Phys: 56,
    Lr: 10,
    Time: 210,
  },
  "PMC Practice Test 4": {
    Eng: 20,
    Bio: 68,
    Chem: 56,
    Phys: 56,
    Lr: 10,
    Time: 210,
  },
  "PMC Practice Test 5": {
    Eng: 20,
    Bio: 68,
    Chem: 56,
    Phys: 56,
    Lr: 10,
    Time: 210,
  },
  "PMC Practice Test 6": {
    Eng: 20,
    Bio: 68,
    Chem: 56,
    Phys: 56,
    Lr: 10,
    Time: 210,
  },
  "PMC Practice Test 7": PMCPracticeTestConfig,
  "PMC Practice Test 8": PMCPracticeTestConfig,
  "PMC Practice Test 9": PMCPracticeTestConfig,
  "PMC Practice Test 10": PMCPracticeTestConfig,
  "PMC Practice Test 11": PMCPracticeTestConfig,
  "PMC Practice Test 12": PMCPracticeTestConfig,
  "PMC Practice Test 13": PMCPracticeTestConfig,
  "PMC Practice Test 14": PMCPracticeTestConfig,
  "PMC Practice Test 15": PMCPracticeTestConfig,
  "PMC Practice Test 16": PMCPracticeTestConfig,
  "PMC Practice Test 17": PMCPracticeTestConfig,
  "PMC Practice Test 18": PMCPracticeTestConfig,
  "PMC Practice Test 19": PMCPracticeTestConfig,
  "PMC Practice Test 20": PMCPracticeTestConfig,
  "PMC Practice Test 21": PMCPracticeTestConfig,
  "PMC Practice Test 22": PMCPracticeTestConfig,
  "PMC Practice Test 23": PMCPracticeTestConfig,
  "PMC Practice Test 24": PMCPracticeTestConfig,
  "PMC Practice Test 25": PMCPracticeTestConfig,
  "PMC Practice Test 26": PMCPracticeTestConfig,
  "PMC Practice Test 27": PMCPracticeTestConfig,
  "PMC Practice Test 28": PMCPracticeTestConfig,
  "PMC Practice Test 29": PMCPracticeTestConfig,
  "PMC Practice Test 30": PMCPracticeTestConfig,
  "PMC Practice Test 31": PMCPracticeTestConfig,
  "PMC Practice Test 32": PMCPracticeTestConfig,
  "PMC Practice Test 33": PMCPracticeTestConfig,
  "PMC Practice Test 34": PMCPracticeTestConfig,
  "PMC Practice Test 35": PMCPracticeTestConfig,
  "PMC Practice Test 36": PMCPracticeTestConfig,

  "PMC Practice Test 1 (2022)": {
    ...PMCPracticeTest2022Config,
    TutorModeFree: true,
  },
  "PMC Practice Test 2 (2022)": { ...PMCPracticeTest2022Config },
  "PMC Practice Test 3 (2022)": { ...PMCPracticeTest2022Config },
  "PMC Practice Test 4 (2022)": { ...PMCPracticeTest2022Config },
  "PMC Practice Test 5 (2022)": { ...PMCPracticeTest2022Config },

  "UHS MDCAT 2019": {
    Eng: 20,
    Bio: 80,
    Chem: 60,
    Phys: 40,
    Time: 150,
    TutorModeFree: true,
  },
  "UHS MDCAT 2018": {
    Eng: 30,
    Bio: 88,
    Chem: 58,
    Phys: 44,
    Time: 150,
  },
  "UHS MDCAT 2017": {
    Eng: 30,
    Bio: 88,
    Chem: 58,
    Phys: 44,
    Time: 150,
  },
  "UHS MDCAT 2017 Reconduct": {
    Eng: 30,
    Bio: 88,
    Chem: 58,
    Phys: 44,
    Time: 150,
  },
  "UHS MDCAT 2016": {
    Eng: 30,
    Bio: 88,
    Chem: 58,
    Phys: 44,
    Time: 150,
  },
  "UHS MDCAT 2015": {
    Eng: 30,
    Bio: 88,
    Chem: 58,
    Phys: 44,
    Time: 150,
  },

  "UHS MDCAT 2014": {
    Eng: 30,
    Bio: 88,
    Chem: 58,
    Phys: 44,
    Time: 150,
  },
  "UHS MDCAT 2013": {
    Eng: 30,
    Bio: 88,
    Chem: 58,
    Phys: 44,
    Time: 150,
  },
  "UHS MDCAT 2012": {
    Eng: 30,
    Bio: 88,
    Chem: 58,
    Phys: 44,
    Time: 150,
  },
  "UHS MDCAT 2011": {
    Eng: 30,
    Bio: 88,
    Chem: 58,
    Phys: 44,
    Time: 150,
  },
  "UHS MDCAT 2010": {
    Eng: 30,
    Bio: 88,
    Chem: 58,
    Phys: 44,
    Time: 150,
  },
  "UHS MDCAT 2009": {
    Eng: 30,
    Bio: 88,
    Chem: 58,
    Phys: 44,
    Time: 150,
  },
  "UHS MDCAT 2008": {
    Eng: 30,
    Bio: 88,
    Chem: 58,
    Phys: 44,
    Time: 150,
  },
  "ETEA MDCAT 2013": {
    Eng: 20,
    Bio: 60,
    Chem: 60,
    Phys: 60,
    Time: 180,
    TutorModeFree: false,
  },
  "ETEA MDCAT 2014": {
    Eng: 20,
    Bio: 60,
    Chem: 60,
    Phys: 60,
    Time: 180,
    TutorModeFree: false,
  },
  "ETEA MDCAT 2015": {
    Eng: 20,
    Bio: 60,
    Chem: 60,
    Phys: 60,
    Time: 180,
    TutorModeFree: false,
  },
  "ETEA MDCAT 2016": {
    Eng: 20,
    Bio: 60,
    Chem: 60,
    Phys: 60,
    Time: 180,
    TutorModeFree: false,
  },

  "ETEA MDCAT 2017": {
    Eng: 20,
    Bio: 60,
    Chem: 60,
    Phys: 60,
    Time: 180,
    TutorModeFree: false,
  },

  "ETEA MDCAT 2018": {
    Eng: 20,
    Bio: 60,
    Chem: 60,
    Phys: 60,
    Time: 180,
    TutorModeFree: false,
  },
  "ETEA MDCAT 2019": {
    Eng: 20,
    Bio: 80,
    Chem: 60,
    Phys: 40,
    Time: 150,
    TutorModeFree: true,
  },
  "FMDC 2018": {
    Eng: 20,
    Bio: 70,
    Chem: 45,
    Phys: 45,
    Time: 190,
    TutorModeFree: true,
  },
  "FMDC 2017": {
    Eng: 20,
    Bio: 70,
    Chem: 45,
    Phys: 45,
    Time: 190,
    TutorModeFree: false,
  },
  "FMDC 2016": {
    Eng: 20,
    Bio: 70,
    Chem: 45,
    Phys: 45,
    Time: 190,
    TutorModeFree: false,
  },

  "FMDC 2015": {
    Eng: 20,
    Bio: 70,
    Chem: 45,
    Phys: 45,
    Time: 190,
    TutorModeFree: true,
  },

  "HEC Phase II 2017": {
    Eng: 20,
    Bio: 40,
    Chem: 30,
    Phys: 30,
    TIme: 120,
    TutorModeFree: false,
  },
  "HEC Phase I 2017": {
    Eng: 20,
    Bio: 40,
    Chem: 30,
    Phys: 30,
    TIme: 120,
    TutorModeFree: false,
  },
};

export default DeckMetaInfo;
