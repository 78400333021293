export var AKUInterviewArticle = {
  HeaderImg:
    "https://premedpk-cdn.sgp1.cdn.digitaloceanspaces.com/CDN/Blog/AKUInterviewBlogPic1.png",
  Tags: ["Featured", "AKU"],
  Title: "Walking through the AKU Interview",
  Desc: "Confused, doubtful, and lost about facing the Aga Khan University Interview? This write-up has all you need to know on preparing and conducting yourself well on the day of the interview, based on personal experiences from AKU students!",
  Date: "June 27, 2022",
  AuthorName: <>Eman Anwar</>,
  Article: (
    <>
      <p>
        The AKU interview process can seem rather intimidating, and this guide
        provides a clear idea of what to expect on the day.
      </p>

      <h2>Overview</h2>
      <p>
        There are two interviews for every candidate, each lasting around 30-40
        minutes. There could be a gap between both interviews. It is helpful to
        remember the name of your first interviewer, as it could be asked in the
        second interview. Some important advice: do not let the outcome of your
        first interview affect your conduct in the next one. Being demotivated
        or overconfident never helps, so be sure to enter the second interview
        with a fresh mindset.
      </p>
      <br />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <img
          style={{ maxHeight: "400px" }}
          src="https://premedpk-cdn.sgp1.cdn.digitaloceanspaces.com/CDN/Blog/AKUInterviewBlogPic2.jpg"
        />
      </div>
      <br />
      <h2>Attire</h2>
      <p>
        Dress formally, but don’t go overboard. For boys, a dress shirt is
        recommended, even a suit if you’re comfortable with it. Shalwar kameez
        or presentable Western clothes work for girls. Whatever you’re wearing,
        you should carry it confidently.
      </p>
      <br />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <img
          style={{ maxHeight: "400px" }}
          src="https://premedpk-cdn.sgp1.cdn.digitaloceanspaces.com/CDN/Blog/AKUInterviewBlogPic3.jpg"
        />
      </div>
      <br />
      <h2>Location</h2>
      <p>
        If you’re in Karachi, you’ll be called for the interview at the AKU
        campus, which includes a fun shuttle ride! For other candidates there
        are centers like the Pearl Continental Hotel in Lahore and Rawalpindi.
        As far as professionalism is concerned, it is highly important to reach
        the venue on time.
      </p>
      <h2>Setting</h2>
      <p>
        Pearl Continental has a hall with different stations, where multiple
        interviews are conducted simultaneously. In Karachi, you’re escorted to
        any of the campus buildings for your interview. After arriving, you’ll
        be asked to fill out a form with basic information such as family and
        sibling details. They’ll make photocopies of the form and deliver it to
        your interviewer.
      </p>
      <h2>Etiquettes</h2>
      <p>
        Your personality will be assessed based on your manners as well. Don't
        forget to knock the door before entering, offer Salaam or any greeting,
        and ask for permission before taking a seat. Be polite, humble, and
        respectful throughout the conversation. At the end, remember to thank
        your interviewer for their time.
      </p>
      <h2>Procedure</h2>
      <p>
        The interviewer might be writing notes as you’re speaking, try to avoid
        looking at the paper and getting distracted. Some of them might ask
        straightforward questions from a list; others conduct it more like an
        open-ended conversation. Your interviewer could either be very friendly
        or strict, it makes no difference on the result. The duration of the
        interview does not matter, people with 15 minute interviews have
        received admission, while those with 90 minute interviews have been
        rejected, and vice versa.
      </p>
      <br />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <img
          style={{ maxHeight: "400px" }}
          src="https://premedpk-cdn.sgp1.cdn.digitaloceanspaces.com/CDN/Blog/AKUInterviewBlogPic4.jpg"
        />
      </div>
      <br />
      <h2>Talking Points </h2>
      <p>
        First and foremost, do not lie. If they proceed to cross-question, there
        is a high risk of being caught. It is worth mentioning that your
        interviewers do not have your Stage II forms, so they will question you
        based on what you talk about. You can carry the conversation in whatever
        direction you choose! Lead the topic towards your passion, hobbies and
        personal interests, they will sense your enthusiasm and genuinely enjoy
        interacting with you. It is best to avoid discussing religion or
        politics. For any controversial subject, try choosing the middle ground
        while describing your stance.
      </p>
      <br />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <img
          style={{ maxHeight: "400px" }}
          src="https://premedpk-cdn.sgp1.cdn.digitaloceanspaces.com/CDN/Blog/AKUInterviewBlogPic5.jpg"
        />
      </div>
      <br />
      <h2>General Advice</h2>
      <p>
        It is normal to have a certain degree of anxiety associated with the
        interview. For some applicants, it is their first interview ever. If you
        feel under pressure, the following tips will keep you composed
        throughout the conversation! Take a deep breath before you start
        talking. Think before you speak, there is no rush to respond on the
        spot. If you're asked a question you do not have an immediate answer to,
        request them for a minute to consider it. The interviewer might cut you
        off while you're speaking, but do not let it unnerve you. It only
        indicates they have heard what they were looking for. First impressions
        do count, hence your posture should be calm and confident. Sit straight,
        maintain eye contact, and act pleasant and natural, you’ll do great!{" "}
        <br /> <br /> We hope this was a helpful picture of what the AKU
        interview looks like. This stage of the application does depend on luck
        to a good extent, hence do not let the outcome demoralize you.
        Candidates with a high number of extracurriculars have not always been
        accepted, while those with a few significant activities have made it.
        Trust your abilities and let your goals and motivation resonate through
        your words. We wish you all the best and hope you secure admission to
        the AKU MBBS programme! Reach out to us on Facebook and Instagram for
        any doubts and concerns.
      </p>
    </>
  ),
};
