import React, { useState, useContext, useEffect } from "react";
import styles from "./styles.module.css";
import { Button } from "shards-react";
import Alert from "@material-ui/lab/Alert";
import AlertTitle from "@material-ui/lab/AlertTitle";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import { BrowserRouter as Router, Route, Switch, Link } from "react-router-dom";
import { useLocation, useHistory } from "react-router-dom";
import { UserContext } from "../../userContext";

import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";

import loginIcon from "../../StaticImages/LoginPageIcon.jpg";
import { ForgotPasswordRequest } from "../../APIManager";

import GradientBtn from "../../Components/GradientBtn/GradientBtn";

const ForgotPassword = () => {
  let [userContext, SetUserContext] = useContext(UserContext);

  const [waitingOnResponse, SetWaitingOnResponse] = useState(false);

  const [username, SetUsername] = useState("");
  const [usernameValid, SetUsernameValid] = useState(true);
  const [usernameErrorText, SetUsernameErrorText] = useState("");
  const [shakeUsername, SetShakeUsername] = useState(false);

  const [serverResponse, SetServerResponse] = useState("");
  const [serverResponseText, SetServerResponseText] = useState("");

  //console.log(userContext);
  let history = useHistory();

  useEffect(() => {
    if (userContext.isLoggedIn) {
      history.push("./dashboard");
    }
  }, []);

  function OnUsernameChange(event) {
    var newUsername = event.target.value.trim();
    SetUsername(newUsername);
    ValidateUsername(newUsername);
  }

  function ValidateUsername(newUsername) {
    if (newUsername.length === 0) {
      SetUsernameErrorText("This field cannot be empty*");
      SetUsernameValid(false);
      return false;
    }
    var regex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!regex.test(newUsername)) {
      SetUsernameErrorText("Enter a valid email address");
      SetUsernameValid(false);
      return false;
    } else {
      SetUsernameErrorText("");
      SetUsernameValid(true);
      return true;
    }
  }

  function OnSubmitClicked() {
    var usernamevalid = ValidateUsername(username);
    SetShakeUsername(!usernamevalid);
    if (usernamevalid) {
      SetWaitingOnResponse(true);
      SetServerResponse("");
      SetServerResponseText("");
      ForgotPasswordRequest({
        username: username.toLowerCase().trim(),
      })
        .then((res) => {
          SetWaitingOnResponse(false);
          ResolveServerResponse(res);
        })
        .catch(() => {
          SetWaitingOnResponse(false);
          SetServerResponse("Error");
          SetServerResponseText("Network Error. Try Again");
        });
    }
  }
  function ResolveServerResponse(res) {
    var data = res.data;
    if (data.success === true) {
      SetServerResponse("Success");
      SetServerResponseText(
        "Recovery instructions have been sent to your email, you can safely close this tab."
      );
    } else {
      SetServerResponse("Error");
      SetServerResponseText(data.ErrorText);
    }
  }

  return (
    <div className={styles.MainDiv} id="LoginPageMainDiv">
      <div className={styles.Container}>
        <div className={styles.ForgotPasswordBox}>
          <div className={styles.Heading}>Reset Password</div>
          <div
            className={styles.ErrorAlert}
            style={serverResponse ? { display: "" } : { display: "none" }}
          >
            <Alert severity={serverResponse.toLowerCase()}>
              <AlertTitle>{serverResponse}</AlertTitle>
              {serverResponseText}
            </Alert>
          </div>
          <div
            className={styles.InputField}
            style={
              shakeUsername ? { animation: "shake .5s" } : { animation: "" }
            }
            onAnimationEnd={() => {
              SetShakeUsername(false);
            }}
          >
            <TextField
              fullWidth
              id="outlined-basic"
              label="Please enter your email"
              error={!usernameValid}
              variant="outlined"
              helperText={usernameErrorText}
              inputProps={{ style: { fontSize: "1.2em" } }} // font size of input text
              InputLabelProps={{
                style: { fontSize: "1.2em", backgroundColor: "white" },
              }} // font size of input label
              onChange={OnUsernameChange}
            />
          </div>
          <div className={styles.Button}>
            <GradientBtn
              style={{
                fontSize: "1.1em",
                borderRadius: "30px",
                margin: 0,
                marginRight: "1em",
                padding: "1em 1.8em",
                width: "100%"
              }}
              gradient={"#D31027 0%, #EA384D  51%, #D31027  100%"}
              disabled={waitingOnResponse}
              onClick={() => {
                OnSubmitClicked();
              }}
            >
              Reset Password
            </GradientBtn>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
