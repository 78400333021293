import { useEffect } from "react";
import { withRouter, useLocation } from "react-router-dom";

function PageTracker({ history, ReactGA }) {
  var previousroute = "";
  useEffect(() => {
    const unlisten = history.listen((location) => {
      if (previousroute != location.pathname) {
       // console.log("Page event triggeres" + " " + location.pathname)
        ReactGA.pageview(location.pathname);
      }
      previousroute = location.pathname;
    });
    return () => {
      unlisten();
    };
  }, []);

  return null;
}

export default withRouter(PageTracker);
