import HeaderImage from "../../../StaticImages/Blog/NewPreMedLogo.png";
import FoundersImage from "../../../StaticImages/Blog/FoundersImage.png";
import UmarBoardImage from "../../../StaticImages/Blog/UmarBoardImage.png";
import HasnainCodingImage from "../../../StaticImages/Blog/HasnainCodingImage.png";
import TeamZoomImage from "../../../StaticImages/Blog/TeamZoomImage.png";
import CoreTeamImage from "../../../StaticImages/Blog/CoreTeamImage.png";
export var DomicileArticle = {
  HeaderImg:
    "https://premedpk-cdn.sgp1.digitaloceanspaces.com/CDN/Blog/DomicileCoverImage.png",
  Tags: ["Featured"],
  Title: "The process of getting one’s domicile made",
  Desc: "Getting all your documents ready at the correct time is highly important for enrolling for the MDCAT entrance exam. Here, we discuss the entire process to get one’s domicile made.",
  Date: "May 20, 2022",
  AuthorName: <>Ahmad Shahid &bull; Illiyun Banani</>,
  Article: (
    <>
      <p>
        Before we get into what the domicile certificate is, its implications,
        and the process to get it made, let’s discuss how this concept is
        integrated with our medical admission process.
        <br />
        In Pakistan, we have Public Medical Schools, catering to different parts
        of the nation; Dow University caters to Sindh (specifically to Karachi),
        King Edward Medical University to Lahore, Khyber Medical College to
        Peshawar, and many more examples to be left undiscussed. ‘Catering’ in
        this context means that only candidates, from the mentioned domains,
        would be allowed to apply to the respective colleges.
        <br />
        Candidates are required to get their domicile made, as proof of their
        claim of belonging to a particular region. For example, to apply to Dow
        University, via one’s domicile, he/she would verify with the admission
        committee that they indeed are a resident of Karachi, and hence under
        the requirements, are eligible to apply.
        <br />
      </p>
      <br />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <img src="https://premedpk-cdn.sgp1.digitaloceanspaces.com/CDN/Blog/DomicileImage1.jpg" />
      </div>
      <br />
      <p>
        Each district, annotated, has its own District Commissioner’s (DC)
        Office. Since they are all government-based, a standard timing is
        followed: '9 am to 11 am’.
        <br />
        The following documents are required to complement your application for
        domicile:
        <ul>
          <li>CNIC of your parents and yours</li>
          <li>Covid Vaccination Certificate</li>
          <li>Birth Certificate</li>
          <li>
            Intermediate and Matric Transcripts/O level and A level Transcripts
            (Certificates),
          </li>
          <li>Utility Bills (Gas, Electricity) for the past month</li>
          <li>Domicile of a Parent (Preferably Father’s)</li>
        </ul>
        <br />
        The charges, at the time when this blog was written (May 2022), were Rs.
        600.
        <br />
        Alongside your domicile, you would be receiving your Permanent Residence
        Certificate, also known as Form D, and your Form C.
        <br />
        The implications of the two are presented below;
        <ol>
          <li>
            Form D/Permanent Residence Certificate verifies the details of your
            current residence and is substantial when applying for a job in a
            government institution.
          </li>
          <li>Form C is crucial for applying to a public university.</li>
        </ol>
      </p>
      <p>
        The time taken to get the domicile made varies, however, on average,
        takes about two weeks. Normally, you receive a message on your cell
        phone, informing you that your domicile is ready for collection.
        <br />
        The arc of your admission journey, which is going to require your
        domicile, lies near the curation of the merit list. For the merit list,
        you would be required to submit your domicile certificate, alongside
        your parent's domicile. This happens during the later time of the year,
        near October, so we would suggest that you get the domicile made for you
        and for your parent as well as soon as possible.
        <br />
        We hope this article proves to be helpful in getting your respective
        domicile made. If you face any problems, you can always reach out to us
        on Instagram or Facebook and we will be more than glad to help you out!
      </p>
    </>
  ),
};
