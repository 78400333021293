import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { useHistory } from "react-router-dom";
import { Alert, Badge } from "shards-react";
import "bootstrap/dist/css/bootstrap.min.css";
import "shards-ui/dist/css/shards.min.css";
//import "./DeckStartComponent.css";
import styles from "./DeckStartComponent.module.css";
import { GetDeckDescription } from "../../APIManager";
import Lightbulbicon from "../../StaticImages/light-bulb.svg";
import YearlyModeToggle from "./YearlyModeToggle";
import TutorModeIcon from "../../StaticImages/TimedModeIcon.svg";

import {
  Card,
  CardHeader,
  CardTitle,
  CardImg,
  CardBody,
  CardFooter,
  Button,
  Fade,
  FormRadio,
} from "shards-react";
import NUMSLogo from "../../StaticImages/NUMS Logo.png";
import NTSLogo from "../../StaticImages/NTS Logo.png";
import UHSLogo from "../../StaticImages/UHS Logo.jpg";
import PMCLogo from "../../StaticImages/PMC Logo.png";
import AKULogo from "../../StaticImages/PreMedAKULogo.svg";

import DeckMetaInfo from "./YearlyDecksMeta";

import GradientBtn from "../GradientBtn/GradientBtn";

function QuizStartScreen({
  onStartCB,
  Name,
  NoOfQuestions,
  showResume,
  resumeNumber,
  resumeCB,
  loading,
}) {
  /*  const [Name , SetDeckName] = useState(props.deckName);
const [Questions , SetQuestions] = useState(props.noQuestions); */
  const Questions = NoOfQuestions;

  useEffect(() => {
    GetDeckDescription(Name).then((res) => {
      //SetDeckDescription(res.data);
      // console.log(res.data)
    });
  }, [Name]);

  const history = useHistory();

  function GetImgSrc(deck) {
    if (!deck) {
      return NTSLogo;
    }
    if (deck.includes("PMC")) {
      return PMCLogo;
    }
    if (deck.includes("UHS")) {
      return UHSLogo;
    }
    if (deck.includes("NTS")) {
      return NTSLogo;
    }
    if (deck.includes("NUMS")) {
      return NUMSLogo;
    }
    if (deck.includes("AKU")) {
      return AKULogo;
    } else {
      return NTSLogo;
    }
  }

  // console.log(props);
  if (!Name) {
    return <></>;
  }
  return (
    <div style={{ textAlign: "center", margin: "auto" }}>
      <div className={styles.MainDiv}>
        <div className={styles.DeckHeaderContainer}>
          <div>
            <img style={{ borderRadius: "50%" }} src={GetImgSrc(Name)} />
          </div>
          <div className={styles.DeckHeader}>
            <div className={styles.DeckName}>{Name}</div>
            <div className={styles.Questions}>{Questions} Questions</div>
          </div>
        </div>
        <br />
        <div className={styles.ModeInfoContainer}>
          <img src={TutorModeIcon} />
          <br />
          <h3>Mock Mode</h3>
          <div>
            <div className={`d-flex ${styles.CardTextRow}`}>
              <i className={`bi bi-check ${styles.checkIcon}`}></i>
              <p className={`${styles.cardText} mb-0`}>
                The paper will be timed according to original time given for the
                paper (180 minutes).
              </p>
            </div>
            <div className={`d-flex ${styles.CardTextRow}`}>
              <i className={`bi bi-check ${styles.checkIcon}`}></i>
              <p className={`${styles.cardText} mb-0`}>
                A detailed score report to help you gauge your weak concepts
                will be provided once you finish the paper.
              </p>
            </div>
            <div className={`d-flex ${styles.CardTextRow}`}>
              <i className={`bi bi-check ${styles.checkIcon}`}></i>
              <p className={`${styles.cardText} mb-0`}>
                The correct answers and detailed explanations will be provided
                after finishing the paper.
              </p>
            </div>
          </div>
          <GradientBtn
            style={{
              fontSize: "0.8em",
              borderRadius: "30px",
              margin: 0,
              marginRight: "1em",
              padding: "1em 1.8em",
            }}
            gradient={"#02AAB0 0%, #00CDAC  51%, #02AAB0  100%"}
            onClick={() => {
              onStartCB();
            }}
            disabled={loading}
          >
            {loading ? "Getting Test Ready" : "Start Test"}
          </GradientBtn>

          {RenderResumeQuestion(showResume, resumeNumber, resumeCB)}
        </div>
        <div className={styles.FactBox}>
          <div className={styles.DYKContainer}>
            <div>Instructions</div>
          </div>
          <div className={styles.Fact}>
            <ul>
              <li>
                {" "}
                This paper consists of a total of {Questions} Questions
                <ul>
                  <li>15 English</li>
                  <li>55 Biology</li>
                  <li>40 Chemistry</li>
                  <li>40 Physics</li>
                  <li>100 Psychological Reasoning</li>
                </ul>
              </li>
              {DeckMetaInfo[Name] ? (
                DeckMetaInfo[Name].ScienceReasoning ? (
                  <li>
                    The Biology, Chemistry, and Physics Sections in the AKU Test
                    are subject to negative marking (-0.25 per incorrect
                    question). We will also be applying negative marking in
                    these three sections in the final score report.
                  </li>
                ) : (
                  <></>
                )
              ) : (
                <></>
              )}
              <li>
                Each question in the Science and English section has only one
                correct answer.
              </li>
              <li>
                Please keep a paper and pen ready with you if you wish to do any
                rough work.
              </li>
              <li>
                You can move between questions using the left/right arrow keys
                too.
              </li>
              <li>
                You can flag a question and come back to attempt it at any time.
              </li>
              {DeckMetaInfo[Name] ? (
                DeckMetaInfo[Name].ScienceReasoning ? (
                  <li>
                    Disclaimer: PreMed.PK and this Mock are not affillilated
                    with AKU in anyway.
                  </li>
                ) : (
                  <></>
                )
              ) : (
                <></>
              )}
              {DeckMetaInfo[Name] ? (
                Name === "PMC Mock 1" || "NUMS Mock 1" ? (
                  <></>
                ) : (
                  <li>
                    Some questions of this paper have been modified, as they
                    were cancelled that year.
                  </li>
                )
              ) : (
                <></>
              )}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default QuizStartScreen;

function RenderResumeQuestion(show, no, cb) {
  if (show) {
    return (
      <Button
        style={{ margin: "1em" }}
        theme="success"
        onClick={(e) => {
          cb();
        }}
      >
        Resume from question {no}
      </Button>
    );
  }
}
