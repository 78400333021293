import React, { useEffect } from "react";

import PaymentRequestImage from "../../../../StaticImages/paymentrequest.png";

import styles from "./RequestPaymentPage.module.css";

import { PostPaymentRequest } from "../../../../APIManager";

import { useParams } from "react-router-dom";

export default function RequestPaymentPage() {
  useEffect(() => {
    PostPaymentRequest(couponCode, couponID).then((res) => {
      console.log(res);
    });
  }, []);

  let { couponCode, couponID } = useParams();
  return (
    <div className={styles.MainDiv}>
      <h1>
        Your payment request was{" "}
        <span style={{ color: "#00D396" }}>successful!</span>
      </h1>

      <p>
        Our <span style={{ color: "#98BDF9" }}>finance</span>{" "}
        <span style={{ color: "#F57D77" }}>team</span> will get
        back to you shortly!
      </p>

      <img
        src={PaymentRequestImage}
        alt="Person standing with phone and payment"
        width="70%"
        height="auto"
      />
    </div>
  );
}
