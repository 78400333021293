import React from "react";
import { Redirect, Route } from "react-router-dom";
import { UserContext } from "./userContext";
import { useState, useContext } from "react";
import { LocalConvenienceStoreOutlined } from "@material-ui/icons";
import { getLoggedInUser } from "./APIManager";
import { RefreshUserContext } from "./UtilityFunctions";

export const ProtectedRoute = ({ Component: Component, ...rest }) => {
  const [user, setUser] = useContext(UserContext);

  return (
    <Route
      {...rest}
      render={(props) => {
        //console.log("user  is " + user);
        ReCheckAuth();
        if (user.isLoggedIn) {
          return <Component {...props} />;
        } else {
          return <Redirect to="/login" />;
        }
      }}
    />
  );

  function ReCheckAuth() {
    // console.log("Recheck Auth");
    RefreshUserContext(setUser, user).then((res) => {});
  }
};
