export const SubTopicTags = {
  "Biology and its Major Fields of Specialization": [
    "Introduction to Biology and Some Major Fields of Specialization",
    "Levels of Biological Organization",
    "Applications of Biology For The Service of Mankind",
    "Biological Control and Integrated Disease Management",
    "Protection and Conservation of the Enviroment",
  ],
  "Biological Molecules": [
    "Introduction to Biological Molecules",
    "Carbon",
    "Water",
    "Carbohydrates",
    "Proteins",
    "Lipids",
    "Conugated Molecules",
    "Nucleic Acids",
  ],
  Enzymes: [
    "Structure and Characteristics of Enzymes",
    "Mechanism of Enzyme Action",
    "Factors affecting the rate of Enzyme Action",
    "Inhibition of Enzymes",
    "Classification of Enzymes",
    "Types of Cofactors",
  ],
  "Cell Structure and Function": [
    "Introduction to Cell and it's theory",
    "Compare the structure of a typical plant and animal cell",
    "Techniques used in Cell Biology",
    "Cell Wall",
    "Cytoplasm and Organelles",
    "Prokaryotic and Eukaryotic Cells",
    "Plasma Membrane",
  ],
  "Biodiversity | Variety of Life": [
    "Classification System - Two to Five Kingdoms",
    "Viruses - Classification, Discovery & Structure",
    "Bacteriophage",
    "HIV",
    "Viral Diseases",
    "Prions and Viroids",
  ],
  "Kingdom Prokaryote": [
    "Taxonomy, Ecology & Diversity of Bacteria",
    "Archaea",
    "Size and Structure of Bacteria",
    "Motility and Nutrition in Bacteria",
    "Growth and Reproduction in Bacteria",
    "Bacterial flora of humans",
    "Importance and Control of bacteria",
    "Shape of Bacteria",
  ],
  "Kingdom Protista": [
    "Introduction and history of Protists",
    "Diversity and major groups of protista",
    "Fungus-like protists",
    "Importance of Protists",
  ],
  "Kingdom Fungi": [
    "Characteristics of Fungi",
    "Structure of Fungi",
    "Nutrition in Fungi",
    "Reprodution in Fungi",
    "Classification and Diversity of Fungi",
    "Importance of Fungi",
  ],
  "Kingdom Plantae": [
    "Classification of Plantae",
    "Division Bryophyta",
    "Adaptation To Land Habitat",
    "Division Tracheophyta",
    "Psilopsida (Psilophyta)",
    "Evolution of the Leaf",
    "Lycopsida",
    "Sphenopsida",
    "Pteropsida",
    "Evolution of Seed Habit",
  ],
  "Kingdom Animalia": [
    "Introduction to Kingdom Animalia",
    "Evolution to complexity in animals",
    "Classification according to Coelem",
    "Grade Radiata & Grade Bilateria",
    "Phylums - porifera to chordata",
  ],
  Bioenergetics: [
    "Introduction to Photosynthesis",
    "Chloroplasts",
    "Light Dependent Mechanism of Photosynthesis",
    "Factors affecting photosynthesis",
    "Introduction to Respiration",
    "Respiration Mechanism - Glycolysis to Electron Transport Chain",
    "Anaerobic Respiration",
    "CAM & C4 Plants",
    "Photosynthetic pigments",
    "Light Independent Mechanism of Photosynthesis",
  ],
  Nutrition: [
    "Autotrophic Nutrition",
    "Heterotrophic Nutrition",
    "Digestion and Absorption",
    "Some Common Diseases Related to Nutrition",
  ],
  "Gaseous Exchange": [
    "Introduction to Gaseous Exchange",
    "Gaseous Exchange in Plants",
    "Gaseous Exchange in Animals",
    "Respiratory System of Man",
    "Transport of Gases",
    "Respiratory Disorders",
  ],
  Tansport: [
    "Transport in Plants",
    "Transpiration and Translocation",
    "Transport in Animals",
    "Transport in Man",
    "Cardiovascular Disorders",
    "Lymphatic System",
    "Immunity and its types",
  ],
  Homeostasis: [
    "Introduction to Homeostasis",
    "Osmoregulation",
    "Excretion in Animals",
    "Structure and Importance of the Kidney",
    "Disorders of the Urinary Tract",
    "Thermoregulation",
  ],
  "Support and Movement": [
    "Support & Movement in Plants",
    "Support and Movement in Animals",
    "Human Skeleton",
    "Disorders of Skeleton",
    "Ultrastructure & Mechanism of Skeletal Muscle Contraction",
    "Types of Muscles",
    "Locomotion in Protoctista and Invertebrates",
    "Locomotion and Skeleton in Vertebrates",
    "Types of Joints",
  ],
  "Coordination and Control": [
    "Coordination in Plants",
    "Coordination in Animals",
    "Steps Involved in Nervous Coordination",
    "Evolution of the Nervous System",
    "Central Nervous System",
    "Disorders of the Nervous System",
    "Endocrine System and its Hormones",
    "Feedback Mechanism",
    "Nature and Types of Behaviour",
    "Peripheral Nervous System",
  ],
  Reproduction: [
    "Reproduction in Plants",
    "Reproduction in Animals",
    "Reproduction in Males",
    "Disorders of the Reproductive System",
    "Sexually Transmitted Diseases",
    "Acquired Immune Deficiency Syndrome",
    "Reproduction in Females",
  ],
  "Growth and Development": [
    "Growth and Development in Plants",
    "Growth and Development in Animals",
    "Embryonic Development",
    "Aging & Regeneration",
    "Abnormal Development",
  ],
  "Chromosomes and DNA": [
    "Types & Composition of Chromosomes",
    "The Chromosomal Theory of Inheritance",
    "DNA as hereditary material",
    "The Chemical Nature of DNA",
    "Genetics",
    "DNA Replication",
    "Translation",
    "Mutations",
  ],
  "Cell Cycle": [
    "Introduction to the Cell Cycle",
    "Interphase",
    "Mitosis",
    "Meiosis",
    "Meiotic Errors",
  ],
  "Variation and Genetics": [
    "Mendelian Inheritance",
    "Exceptions To Mendelian Inheritance",
    "Blood Group System",
    "Polygenic Inheritance And Epistasis",
    "Gene Linkage and Crossing Over",
    "Sex Determination",
    "Sex Linkage",
    "Diabetes Mellitus & its Genetic Basis",
    "Inherited Diseases",
  ],
  Biotechnology: [
    "Genetic Engineering & its tools",
    "Scope and importance of Biotechnology",
    "Gene Therapy",
    "Tissue Culture",
  ],
  "Man and His Environment": [
    "Biogeochemical Cycle",
    "The Flow Of Energy",
    "Ecological Succession",
    "Population Dynamics",
    "Human Impact on the Environment",
    "Renewable & Non-Renewable Resources",
    "Depletion Of Resources",
    "Health & Diseases",
  ],
  Measurements: [
    "Introduction to Physics",
    "Physical Quantities",
    "International System of Units",
    "Errors and Uncertainties",
    "Significant Figures",
    "Precision and Accuracy",
    "Assessment of Total Uncertainty",
    "Dimensions of Physical Quantities",
  ],
  "Scalars and Vectors": [
    "Basic Concepts of Vectors",
    "Cartesian Coordinate System",
    "Vector Addition by Rectangular Components",
    "Product of Two Vectors",
    "Resolution of Vectors",
    "Torque",
    "Equilibrium of Forces",
    "Equilibrium of Torques",
  ],
  "Forces and Motion": [
    "Displacement, Velocity and Acceleration",
    "Velocity-Time Graph",
    "Equations of Uniformly Accelerated Motion",
    "Newton's Laws of Motion",
    "Momentum and Explosive Forces",
    "Elastic and Inelastic Collisions",
    "Force Due to Water Flow",
    "Momentum",
  ],
  "Motion in Two Dimensions": [
    "Projectile motion",
    "Maximum height and range of projectile",
    "Projectile trajectory",
    "Applications of projectile motion",
  ],
  "Work, Power & Energy": [
    "Work Done by Constant Force and Variable Forces",
    "Work Done by Gravitational Field",
    "Power and Energy",
    "Interconversion of Potential Energy and Kinetic Energy",
    "Conservation of Energy",
    "Non Conventional Energy Sources",
    "Absolute Potential Energy",
    "Escape Velocity",
    "Renewable and Non Renewable Resources",
  ],
  "Circular Motion & Momentum": [
    "Angular Displacement, Velocity and Acceleration",
    "Relation between Angular and Linear Velocities",
    "Centripetal Force",
    "Torque and Moment of Inertia",
    "Angular Momentum and Torque",
    "Law of Conservation of Angular Momentum",
    "Rotational Kinetic Energy",
    "Problems involving an Inclined Plane",
  ],
  Gravitation: [
    "Newton's Law of Universal Gravitation",
    "Variation of Gravity with Altitude and Depth",
    "Real and Apparent Weight",
    "Orbital Velocity",
    "Geostationary Orbits",
    "Newtons and Einsteins Views of Gravitation",
  ],
  "Fluid Dynamics": [
    "Viscous Fluids",
    "Fluid Friction And Stokes Law",
    "Terminal Velocity",
    "Fluid Flow",
    "Equation Of Continuity",
    "Bernoulli's Equation and its Application",
  ],
  "Oscillations and Simple Harmonic Motion": [
    "Simple Harmonic Motion and Uniform Circular Motion",
    "Horizontal Mass Spring System",
    "Simple Pendulum",
    "Energy Conservation in SHM",
    "Energy Conservation in SHM",
    "Free and Forced Oscillations",
    "Resonance",
    "Damped Oscillations",
  ],
  "Wave Motion and Sound": [
    "Periodic Waves",
    "Progessive Waves",
    "Superposition Of Waves",
    "Interference of Waves",
    "Beats",
    "Reflection of Waves and Phase Change",
    "Stationary Waves",
    "Fundamental and Overtone Vibration",
    "Resonance of Air Column and Organ Pipes",
    "Doppler Effect",
  ],
  "Optics, Nature of Light and Optical Instruments": [
    "Nature Of Light",
    "Wave Front",
    "Huygen's Principle",
    "Interference of Light",
    "Young's Double Slit Experiment",
    "Diffraction of Light",
    "Diffraction of X-rays by Crystal",
    "Polarization of Light",
    "Magnifying and Resolving Power of Optical Instruments",
    "Simple, Compound and Astronomical Microscopes",
    "Spectrometer",
    "Speed of Light",
    "Principles and Types of Fibre Optics",
    "Signal Transmission and Conversion To Sound",
    "Losses of Power",
  ],
  "Heat and Thermodynamics": [
    "Kinetic Theory of Gases",
    "Internal Energy",
    "Work and Heat",
    "First Law of Thermodynamics",
    "Reversible and Irreversible Processes",
    "Heat Engine",
    "Second Law of Thermodynamics",
    "Thermodynamic Scale of Temperature",
    "Petrol Engine",
    "Entropy",
  ],
  Electrostatics: [
    "Columbs Law",
    "Fields of Force",
    "Electric Field Lines",
    "Applications of Electrostatics",
    "Electric Flux",
    "Gauss's Law and its Application",
    "Electric Potential",
    "Electron Volt",
    "Electric and Gravitational Forces",
    "Capacitor",
    "Capacitance of a Parallel Plate Capacitor",
    "Electric Polarization of Dielectrics",
    "Energy Stored in a Capacitor",
    "Charging and Discharging a Capacitor",
  ],
  "Current Electricity": [
    "Electric Current",
    "Source and Effects of Current",
    "Ohms Law",
    "Resistivity and its Dependence upon Temperature",
    "Wire Wound Variable Resistors",
    "Colour Code for Carbon Resistances",
    "Thermistor",
    "Electrical Power and Power Dissipation in Resistors",
    "Electomotive Force and Potential Difference",
    "Thermocouples",
    "Variation in Thermoelectric EMF with Temperature",
    "Kirchoff's Law",
    "Wheatstone Bridge and Potentiometer",
  ],
  "Magnetism and Electromagnetic Induction": [
    "Magnetic Field Due to Current in a Long Straight Wire",
    "Force on a Current Carrying Conductor in a Uniform Magnetic Field",
    "Magnetic Flux and Flux Density",
    "Amperes Law and Determination of Flux Density",
    "Force on a Moving Charge in a Magnetic Field",
    "Motion of a Charged Particle in an Electric and Magnetic Field",
    "Determination of e/m of an Electron",
    "Cathode Ray Oscilloscope",
    "Torque on a Current Carrying Coil",
    "Galvanometer",
    "Induced EMF and Induced Current",
    "Faradays and Lenz Law and Induced EMF",
    "Mutual and Self Induction",
    "Energy Stored in an Inductor",
    "A.C. and D.C. Generator",
    "Transformers",
  ],
  "Electromagnetic Waves": [
    "Production of electromagnetic waves",
    "Information carried by EM waves and their reception",
  ],
  "Alternating Current": [
    "Alternating Voltage and Current",
    "A.C. Circuits",
    "Impedance",
    "R-C, R-L and R-L-C Series AC Circuits",
    "Series & Parallel Resonance Circuit",
    "Principle of Generation, Transmission and Reception of Electromagnetic Waves",
    "Modulation",
    "Maxwell Equation",
  ],
  "Physics of Solids": [
    "Classification of Solids",
    "Elastic Moduli",
    "Hooke's Law",
    "Mechanical and Electrical Properties of Solids",
    "Energy Band Theory",
  ],
  Electronics: [
    "Superconductors",
    "Theory of Magnetism",
    "Semiconductors",
    "Doping of Impurities",
    "P-N Junction and its Characteristics",
    "Rectification",
    "Transistors",
    "Op-Amps",
    "Comparators",
    "Fundamental Logic Gates",
    "Applications of Gates in Control Systems",
  ],
  "Dawn of Modern Physics": [
    "Reference Frames",
    "Special Theory of Relativity",
    "Black Body Radiation",
    "Photoelectric Effect",
    "Compton Effect",
    "Pair Production and Annihilation",
    "Wave Nature of Particles",
    "Electron Microscope",
  ],
  "Atomic Spectra": [
    "Spectrum of Hydrogen Atom",
    "Bohrs Model of the Hydrogen Atom",
    "De-Broglie Waves and Hydrogen Atom",
    "Inner Shell Transitions And Characteristic X-rays",
    "Uncertainty Within The Atom",
    "Laser",
  ],
  "Nuclear Physics": [
    "Composition of Atomic Nuclei",
    "Mass Spectrograph",
    "Mass Defect And Binding Energy",
    "Radioactivity",
    "Nuclear Reactions",
    "Biological Effects and Uses of Radiation",
  ],
  "Introduction to Fundamental Concepts of Chemistry": [
    "Introduction to the Atom",
    "Isotopes",
    "Analysis of a Compound - Empirical And Molecular Formulas",
    "Significant Figures",
    "Use of Exponents (Logarithms)",
    "Error and Deviation",
    "Important Assumptions of Stoichiometric Calculations",
    "Hydrated and Anhydrous Compounds",
  ],
  "Experimental Techniques in Chemistry": [
    "Filtration",
    "Crystallization",
    "Sublimation",
    "Solvent Extraction",
    "Chromatography",
  ],
  "Stoichiometry": [
    "Mole",
    "Percentage Correct",
    "Limiting and Non Limiting Reagents",
    "Theoretical Yield, Actual Yield and Percentage Yield",
  ],
  "States of Matter - Gases, Liquids and Solids": [
    "Gas Laws and General Gas Equation",
    "Avogadros Law",
    "Daltons Law of Partial Pressures",
    "Diffusion And Effusion",
    "Non Ideal Behaviour Of Gases",
    "Kinetic Molecular Theory Of Gases",
    "Real and Ideal Gas Equation",
    "Properties of Liquids and Water",
    "Boiling Point and Vapor Pressure",
    "Evaporation",
    "Liquid Crystals",
    "Viscocity and Surface Tension",
    "Types of Solids",
    "Properties of Crystalline Solids",
    "Crystal Lattice and Lattice Energy",
    "Isomorphism and Polymorphism",
  ],
  "Atomic Structure": [
    "Sub Atomic Particles and Their Discoveries",
    "Rutherfords Model of Atom",
    "Plancks Quantum Theory",
    "Bohrs Model of Atom",
    "Atomic Spectrum",
    "Wave-Particle Nature of Matter",
    "Heisenberg's Uncertainty Principle",
    "Experiments in Atomic Struture",
  ],
  "Chemical Bonding": [
    "Energetics of Bond Formation",
    "Ionization Energy",
    "Electron Affinity and Electronegativity",
    "Bond Energy and Bond Length",
    "Electrovalent or Ionic Bond",
    "Covalent Bond",
    "Co-ordinate or dative covalent bond",
    "VSEPR Theory",
    "Metallic Bonding",
    "Intermolecular Forces",
    "Hydrogen Bonding",
  ],
  "Thermochemistry": [
    "Spontaneous and Non-Spontaneous Reactions",
    "System, Surrounding and State Function",
    "Standard States and Enthalpy Changes",
    "Laws of Thermodynamics",
    "Enthalpies of Reactions and its Types",
    "Heat Capacity and Calorimetry",
    "Hess’s Law",
    "Born-Haber Cycle",
  ],
  "Chemical Equilibrium": [
    "Reversible and Irreversible Reactions",
    "State of Chemical Equilibrium",
    "Equilibrium Constant",
    "Solubility Product and Precipitation Reactions",
    "Factors Affecting Equilibria",
    "Haber’s Process",
  ],
  "Solution and Colloids": [
    "Concentration Units of Solutions",
    "Types of Solutions",
    "Raoults Law",
    "Vapour Pressures of Liquid-Liquid Solutions",
    "Solubility and Solubility Curves",
    "Colligative Properties of Solutions",
    "Energetics of Solution",
    "Hydration And Hydrolysis",
  ],
  "Electrochemistry": [
    "Oxidation Number or State",
    "Electrolysis",
    "Redox Equations",
    "Electrochemical Series",
    "Types of Electrochemical Cells",
  ],
  "Acids, Bases and Salts": [
    "Acidic, Basic and Amphoteric Substances",
    "Lowery Bronsted Concepts for Acids and Bases",
    "Conjugate Acid-Base Pairs",
    "Strength of Acid and Bases",
    "Lewis Concept of Acid and Base",
    "Buffer Solutions and Their Applications",
    "Salt Hydrolysis",
  ],
  "Reaction Kinetics": [
    "Rate and velocity of chemical reaction",
    "Order of reaction and its determination",
    "Activation energy and activated complex",
    "Collision theory and transition state",
    "Catalysis",
  ],
  "Periodicity in Elements": [
    "Introduction to the Periodic Table",
    "The Modern Periodic Table",
    "Periodic Trends",
    "Evolution of the Periodic Table",
    "Electronic Configuration",
  ],
  "s and p Block Elements": [
    "Introduction to s and p Block Elements",
    "General Behaviour of Alkali and Alkaline Earth Metals",
    "Downs Cell",
    "The Diaphragm Cell",
    "Roles of Gypsum",
    "Roles of Lime",
  ],
  "d and f Block Elements": [
    "Introduction to d and f Block Elements",
    "Properties of Transition Elements",
    "Complex Compounds",
    "Some Examples of Transition Elements and Their Applications",
    "Corrosion Chromates and Dichromates",
    "Silvering of Mirrors",
  ],
  "Fundamental Principles of Organic Chemistry": [
    "Introduction to Fundamental Principles of Organic Chemistry",
    "Importance of Organic Chemistry",
    "Sources of Organic Compounds",
    "Cracking of Petroleum",
    "Classifications of Organic Compounds",
    "Functional Groups",
  ],
  "Hydrocarbons": [
    "Introduction to Hydrocarbons",
    "Nomenclature of Hydrocarbons",
    "Alkanes and Paraffins",
    "Alkenes",
    "Alkynes",
    "Benzene and its Preparation",
    "Reactions of Benzene",
    "Comparison of Reactivities of Alkanes, Alkenes and Benzene",
  ],
  "Alkyl Halides and Amines": [
    "Introduction to Alkyl Halides and Amines",
    "Nomenclature of Alkyl Halides",
    "Methods of Preparation Of Alkyl Halides",
    "Reactivity and Reactions of Alkyl Halides",
    "Grignard Reagent",
  ],
  "Alcohols, Phenols and Ether ": [
    "Introduction to Alcohols, Phenols and Ether",
    "Distinction Between Primary, Secondary and Tertiary Alcohols",
    "Uses of Alcohols",
  ],
  "Aldehydes and Ketones": [
    "Introduction to Aldehydes and Ketones",
    "Nomenclature of Adehydes and Ketones",
    "Physical Properties of Aldehydes And Ketones",
    "Preparation of Aldehydes And Ketones",
    "Reactivity and Reactions of Carbonyl Group",
    "Identification and Uses of Carbonyl Compounds",
  ],
  "Carboxylic Acids and its Derivatves": [
    "Introduction to Carboxylic Acids",
    "Nomenclature of Carboxylic Acids",
    "General Methods Of Preparation",
    "Physical Characteristics",
    "Reactivity of Carboxyl Group",
    "Reactions Of Carboxylic Acids",
    "Amino Acids",
  ],
  "Chemistry of Life": [
    "Carbohydrates",
    "Proteins",
    "Enzymes",
    "Lipids",
    "Nucleic Acids",
    "Minerals of Biological Signifance",
  ],
  "Industrial Chemistry": [
    "Fertilizers and their Classification",
    "Elements Essential for Plant Growth",
    "Industries",
    "Safety Considerations in Process Industries",
    "Dyes",
  ],
  "Analytical Chemistry": [
    "Classical Method of Analysis",
    "Modern Methods of Analysis",
  ],
  "Environmental Chemistry": [
    "Introduction and Types of Pollution",
    "Factors affecting Water Quality",
    "Solid Waste Management and Water Treatment",
    "Chemistry of Troposphere and Stratosphere",
    "Nucleic Acids",
    "Minerals of Biological Signifance",
  ],
  "Macromolecules": [
    "Introduction and Structure of Polymers",
    "Types of Polymers",
    "Polymerization Process",
    "Synthetic Polymers and Biopolymers",
  ],

};

var topics = [];

/**
forces in motion
motion in two dimensions 
work power energy 
circular motion and momentum 
oscillations and simple harmonic motion
waves motion and sound
electromagnetic waves
 */

