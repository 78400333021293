import React, { useState, useEffect, useCallback, useContext } from "react";
import usePracticeDeck from "./Hooks/usePracticeDeck";
import PracticeQuestionComponent from "./PracticeQuestionComponent";
import DeckStartComponent from "../../Pages/QuestionDeck/DeckStartComponent";

import { Modal, ModalBody, ModalHeader } from "shards-react";
import GradientBtn from "../../Components/GradientBtn/GradientBtn.jsx";

import CircularProgress from "@material-ui/core/CircularProgress";

import { UserContext } from "../../userContext";

import { useHistory, useParams, withRouter } from "react-router";
import { Prompt } from "react-router-dom";

import { GetMockPaper } from "../../APIManager";
import useTestDeck from "./Hooks/useTestDeck";

import QuizStartScreen from "./MockModeDeckStartComponent";
import TestModeOptionPanel from "./TestModeOptionPanel";
import TestModeQuestionComponent from "./TestModeQuestionComponent";
import TestModeDeckResult from "./TestModeDeckResult";
import TestModeReviewComponent from "./TestModeReviewComponent";

import NUMSMockDeckResult from "./NUMSMockDeckResult";

import { LogMilestone, GetMockAttemptTestPaper } from "../../APIManager";

import { VerifyMockAddon } from "../../MockUtlityFunctions";

import Footer from "../NewFooter/Footer";
import { SettingsApplications } from "@material-ui/icons";

export default function MockModeComponent() {
  let search = window.location.search;
  let params = new URLSearchParams(search);
  const deckName = params.get("deck");

  const [user, setUser] = useContext(UserContext);
  const mockAlreadyAttempted = user.milestones.includes(deckName);

  const [premiumModalOpen, SetPremiumModalOpen] = useState(false);
  const isPremium = user.addonspurchased.includes("Premium");

  const [alreadyAttemptedModalOpen, SetAlreadyAttemptedModalOpen] =
    useState(false);

  const history = useHistory();

  if (!VerifyMockAddon(deckName, user.addonspurchased)) {
    history.push("/plans");
  }

  const {
    downloading,
    noOfQuestions,
    deck,
    showQuestionComponent,
    showResultScreen,
    showReviewComponent,
    showStartScreen,
    paperDuration,
    seconds,
    minutes,
    hours,
    OnTestStart,
    currentQID,
    currentQuestion,
    questionNo,
    nextEnabled,
    backEnabled,
    NextQuestion,
    PreviousQuestion,
    testPaper,
    OnOptionClicked,
    testPaused,
    OnPause,
    OnResume,
    noOfAttemptedQuestions,
    SkipToQuestion,
    OnTestFinsh,
    testResult,
    OnResultClicked,
    OnReviewClicked,
    OnFlagClicked,
    OnBackClicked,
    SetTestReviewMode,
  } = useTestDeck(GetMockPaper, deckName, true);
  console.log(testResult);
  useEffect(() => {
    if (!downloading && mockAlreadyAttempted) {
      SetAlreadyAttemptedModalOpen(true);
    }
  }, [downloading]);

  console.log(currentQuestion);
  useEffect(() => {
    window.onbeforeunload = function () {
      return "Do you really want to leave?";
    };

    return () => {
      window.onbeforeunload = function () {};
    };
  }, []);

  return (
    <>
      <Prompt
        when={!showStartScreen}
        message="There are unsaved changes, do you wish to discard them?"
      />
      {(showStartScreen || showQuestionComponent) && (
        <TestModeOptionPanel
          showFinishButton={showQuestionComponent}
          showPauseButton={showQuestionComponent}
          timerText={GetTimerText()}
          /* timerText={new Date((hours * 3600) + (minutes * 60) + seconds)
            .toISOString()
            .substr(11, 8)} */
          testPaused={testPaused}
          PauseTest={OnPause}
          ResumeTest={OnResume}
          FinishTest={() => {
            OnTestFinsh();
            LogMilestone(deckName);
          }}
          testPaper={testPaper}
          showMockAttemptWarning={true}
        />
      )}
      <PremiumModal
        isOpen={premiumModalOpen}
        OnClick={() => {
          history.push("/plans");
        }}
      />
      <AlreadyAttemptedModal
        isOpen={alreadyAttemptedModalOpen}
        mockName={deckName}
        OnClick={(TestPaper) => {
          SetTestReviewMode(TestPaper);
          SetAlreadyAttemptedModalOpen(false);
        }}
        user={user}
      />
      {showStartScreen && (
        <QuizStartScreen
          Name={deckName}
          NoOfQuestions={noOfQuestions}
          showResume={false}
          loading={downloading}
          onStartCB={() => {
            if (mockAlreadyAttempted) {
              return;
            }
            if (isPremium) {
              OnTestStart();
            } else {
              OnTestStart();
            }
          }}
        />
      )}
      {showQuestionComponent && (
        <TestModeQuestionComponent
          deckName={deckName}
          questionNo={questionNo}
          NoOfQuestions={noOfQuestions}
          currentQuestion={currentQuestion}
          nextEnabled={nextEnabled}
          backEnabled={backEnabled}
          NextQuestion={NextQuestion}
          PreviousQuestion={PreviousQuestion}
          testPaper={testPaper}
          OnOptionClicked={OnOptionClicked}
          testPaused={testPaused}
          OnPause={OnPause}
          noOfAttemptedQuestions={noOfAttemptedQuestions}
          SkipToQuestion={SkipToQuestion}
          OnFlagClicked={OnFlagClicked}
          OnPasue={OnPause}
          OnResume={OnResume}
          HideBookMarkBtn
        />
      )}
      {showResultScreen && (
        <NUMSMockDeckResult
          total={testResult.NoOfQuestions}
          correct={testResult.correct}
          skipped={testResult.skipped}
          wrong={testResult.wrong}
          totalTime={testResult.timeTaken}
          deckName={deckName}
          OnReviewTestClicked={OnReviewClicked}
          showNegativeMarking={deckName.includes("AKU")}
          negativeMarking={testResult.negativeMarking}
          testPaper = {testPaper}

        />
      )}
      {showReviewComponent && (
        <TestModeReviewComponent
          deckName={deckName}
          questionNo={questionNo}
          NoOfQuestions={noOfQuestions}
          currentQuestion={currentQuestion}
          nextEnabled={nextEnabled}
          backEnabled={backEnabled}
          NextQuestion={NextQuestion}
          PreviousQuestion={PreviousQuestion}
          testPaper={testPaper}
          OnOptionClicked={OnOptionClicked}
          testPaused={testPaused}
          OnPause={OnPause}
          noOfAttemptedQuestions={noOfAttemptedQuestions}
          SkipToQuestion={SkipToQuestion}
          OnBackClicked={OnBackClicked}
          HideBookMarkBtn
        />
      )}
      <Footer />
    </>
  );
  function GetTimerText() {
    return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(
      2,
      "0"
    )}:${String(seconds).padStart(2, "0")}`;
  }
}

const PremiumModal = ({ isOpen, OnClick }) => {
  return (
    <Modal
      open={isOpen}
      toggle={() => {
        //SetModalOpen(!modalOpen);
      }}
    >
      <ModalHeader>Premium Feature</ModalHeader>
      <ModalBody>
        <div
          style={{
            fontSize: "1.2em",
            textAlign: "center",
            fontFamily: "Rubik",
          }}
        >
          Your currrent plan does not have access to{" "}
          <span style={{ color: "var(--QuestionBlue)" }}>Timed Test Mode.</span>{" "}
          Switch to our{" "}
          <span style={{ color: "var(--RedAccent)" }}>Premium Plan</span> to
          access this feature!
          <br />
          <br />
          <GradientBtn
            style={{
              fontSize: "0.7em",
              borderRadius: "30px",
              margin: 0,
            }}
            gradient={"#D31027 0%, #EA384D  51%, #D31027  100%"}
            onClick={OnClick}
          >
            Purchase Premium Plan
          </GradientBtn>
        </div>
      </ModalBody>
    </Modal>
  );
};

const AlreadyAttemptedModal = ({ isOpen, OnClick, mockName, user }) => {
  const [downloading, SetDownloading] = useState(false);
  function onClick() {
    SetDownloading(true);
    GetMockAttemptTestPaper(mockName).then((res) => {
      OnClick(res.data);
    });
  }

  return (
    <Modal
      open={isOpen}
      toggle={() => {
        //SetModalOpen(!modalOpen);
      }}
      centered
    >
      <ModalHeader>Mock already attempted!</ModalHeader>
      <ModalBody>
        <div
          style={{
            fontSize: "1.2em",
            textAlign: "center",
            fontFamily: "Rubik",
          }}
        >
          Hey {user.fullname}, you have already attempted this mock. However,
          you can review your answers and explanations by clicking below📝
          <br />
          <br />
          <GradientBtn
            style={{
              fontSize: "0.7em",
              borderRadius: "30px",
              margin: 0,
            }}
            gradient={"#D31027 0%, #EA384D  51%, #D31027  100%"}
            disabled={downloading}
            onClick={onClick}
          >
            {!downloading ? "Review Paper" : "Loading"}
          </GradientBtn>
        </div>
      </ModalBody>
    </Modal>
  );
};
