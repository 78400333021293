import React, { useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import styles from "./QuestionComponent.module.css";
import { CardFooter, Button, Fade } from "shards-react";
import parse from "html-react-parser";

import { styled } from "@material-ui/core";

import Rating from "@material-ui/lab/Rating";

import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";

import GradientBtn from "../GradientBtn/GradientBtn";

import { LogRating } from "../../APIManager";

import FavoriteIcon from "@material-ui/icons/Favorite";
import FavoriteBorderIcon from "@material-ui/icons/FavoriteBorder";

import { UserContext } from "../../userContext";
import NotLoggedInToggle from "./NotLoggedInToggle";

const StyledRating = styled(Rating)({
  "& .MuiRating-iconFilled": {
    color: "#ff6d75",
  },
  "& .MuiRating-iconHover": {
    color: "#ff3d47",
  },
});

const ExplanationFooter = ({
  showAnswer,
  isBlur,
  ExplanationContent,
  ExplanationImage,
  Reference,
  QuestionID,
}) => {
  console.log(QuestionID);
  const history = useHistory();
  const [user, SetUser] = useContext(UserContext);
  const [notLoggedInOpen, SetNotLoggedInOpen] = useState(false);

  if (!showAnswer) {
    return null;
  }
  return (
    <div id="answerFooter">
      <NotLoggedInToggle
        open={notLoggedInOpen}
        onClose={() => {
          SetNotLoggedInOpen(false);
        }}
      />
      <BlurBox isBlur={isBlur}>
        <Fade in={true}>
          <CardFooter>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignContent: "center",
                alignItems: "center",
              }}
            >
              <div className={styles.ContentMaxWidth}>
                <h5>Answer Explanation:</h5>
                <div className={styles.ExplanationContent}>
                  {parse(ExplanationContent)}
                  {RenderExplanationImage(ExplanationImage)}
                </div>
                <br />
                <ExplanationRating
                  key={QuestionID}
                  questionID={QuestionID}
                  isBlur={isBlur}
                  notLoggedIn={!user.isLoggedIn}
                  notLoggedInCB={() => {
                    SetNotLoggedInOpen(true);
                  }}
                />
                <br />
                {Reference && (
                  <>
                    <h5>Explanation Reference:</h5>
                    <div className={styles.ExplanationContent}>
                      {parse(Reference)}
                    </div>
                  </>
                )}
              </div>
            </div>
          </CardFooter>
        </Fade>
      </BlurBox>
    </div>
  );
};

function RenderExplanationImage(image) {
  if (image) {
    return (
      <div>
        <img
          src={image}
          className={styles.QuestionImage}
          style={{
            marginRight: "1rem",
            marginTop: "1rem",
            marginBottom: "1rem",
          }}
        />
      </div>
    );
  } else {
    return <div />;
  }
}

const ExplanationRating = ({
  questionID,
  isBlur,
  notLoggedIn,
  notLoggedInCB,
}) => {
  const [rating, SetRating] = useState(isBlur ? 0 : 0); //3);
  const [showSnackBar, SetShowSnackBar] = useState(false);
  const [lock, SetLock] = useState(isBlur ? true : false);

  console.log(questionID);

  function OnRatingChange(newValue) {
    SetRating(newValue);
    SetShowSnackBar(true);
    SetLock(true);
    //Add API Call
  }

  return (
    <div className={styles.RatingLineDiv}>
      <Snackbar
        open={showSnackBar}
        autoHideDuration={6000}
        onClose={() => {
          SetShowSnackBar(false);
        }}
      >
        <Alert
          onClose={() => {
            SetShowSnackBar(false);
          }}
          severity="success"
        >
          Thank You for your feedback!
        </Alert>
      </Snackbar>
      <span style={{ marginRight: "10px" }}>
        <h5>Explanation Rating:</h5>
      </span>
      <span
        className={styles.RatingDiv}
        style={lock ? { marginBottom: "11px" } : null}
      >
        <StyledRating
          value={rating}
          onChange={(event, newValue) => {
            if (notLoggedIn) {
              notLoggedInCB();
              return;
            }
            OnRatingChange(newValue);
            LogRating(questionID, newValue);
          }}
          readOnly={lock}
          icon={<FavoriteIcon fontSize="inherit" />}
          emptyIcon={<FavoriteBorderIcon fontSize="inherit" />}
        />
      </span>{" "}
      We take special care of the opinions of our users. Rate this explanation
      so that we can further improve it for you!
    </div>
  );
};

const BlurBox = ({ children, isBlur }) => {
  const history = useHistory();

  if (!isBlur) {
    return <div>{children}</div>;
  } else {
  }
  return (
    <div className={styles.ExplanationContainer}>
      <div className={styles.PurchaseExplanationContainer}>
        <div className={styles.PurchaseExplanationText}>
          Your plan only allows access to 10 explanations.
          <br />
          Switch to our premium plan today to get access
          <br />
          <GradientBtn
            style={{ fontSize: "0.5em", borderRadius: "30px" }}
            gradient={"#D31027 0%, #EA384D  51%, #D31027  100%"}
            onClick={() => {
              history.push("/plans");
            }}
          >
            Purchase
          </GradientBtn>
        </div>
      </div>
      <div className={styles.BlurExplanation}>{children}</div>
    </div>
  );
};

export default ExplanationFooter;
