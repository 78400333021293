import YoutubeEmbeded from "../../../Components/YoutubeEmbeded/YoutubeEmbeded";

export var InternationalStudentsArticle = {
  HeaderImg:
    "https://premedpk-cdn.sgp1.digitaloceanspaces.com/CDN/Blog/InternationStudentBlogPic1.jpg",
  Tags: ["Featured"],
  Title: "Applying to medical universities as an International Student",
  Desc: "International Students are often confused regarding what documents and processes need to be completed to apply to Medical and Dental Universities in Pakistan. Using this blog, we will try to tackle all the confusions to make the entire process streamlined!",
  Date: "June 27, 2022",
  AuthorName: <>Ahmad Shahid</>,
  Article: (
    <>
      <p>
        A lot of confusion arises for international students when they find
        themselves applying to Pakistani Medical and Dental Universities for
        MBBS and BDS on international seats. Here through this blog, we try to
        answer some of the frequently asked questions from candidates and
        students about the admission process. If you have any further questions,
        you can always reach out to us on Facebook or Instagram and we will be
        more than happy to help you out! International students, similar to
        local applicants, may choose between applying to a private or a public
        medical university, but the process differs for the category chosen. We
        shall discuss admission procedures for both types of universities,
        starting from Private Universities.
      </p>

      <h2>1. Private Universities</h2>
      <p>
        The processes vary from university to university hence, we shall start
        with AKU’s requirements and move on to other universities.
      </p>
      <p style={{ overflowWrap: "break-word" }}>
        <strong>Aga Khan University (AKU)</strong> has no special requirements,
        in terms of nationality or domicile however, a prerequisite, established
        by the Pakistan Medical Commission (PMC) is that the MDCAT must be
        cleared by the candidate. Before 2022, international students could give
        the SAT or the American/Canadian MCAT as an alternative to the AKU test
        but that policy is no longer valid and every student has to appear for
        the AKU Test. AKU also has a number of centres across the globe hence,
        international students do not have to come to Pakistan to give the AKU
        Test and can give it in the nearest possible city. After passing the AKU
        Test, an interview is scheduled but international students can wish to
        give this interview online as well. International students are required
        to pay international fees which can be checked using the link mentioned
        below. The rest of the requirements are the same as what would be
        implemented on a local student. The link to AKU’s page, concerning the
        requirements, is attached:
        (https://www.aku.edu/admissions/Documents/admission-information-MBBS.pdf).
      </p>
      <br />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <img
          style={{ maxHeight: "400px" }}
          src="https://premedpk-cdn.sgp1.digitaloceanspaces.com/CDN/Blog/InternationStudentBlogPic2.jpg"
        />
      </div>
      <br />
      <p style={{ overflowWrap: "break-word" }}>
        For <strong>Zaiuddin University (ZU)</strong>, to apply as an overseas
        student, you are only required to meet the criteria set by the PMC, that
        is to clear the MDCAT, and also Zaiuddin’s internal test. Most
        importantly, as a foreign applicant, a Pakistani Passport or National
        Identity Card for Overseas Pakistanis (NICOP) is not a necessity. Once
        all the conditions have been met and the candidate has cleared
        Zaiuddin’s written test with significant merit, she/he would be called
        for an interview, the interview being not as robust as AKU’s. You may
        check more about the conditions and requirements on Zaiuddin
        University’s website, along side the fee structure, on the link provided
        below;
        (https://zmc.zu.edu.pk/undergraduate/bachelor-of-medicine-bachelor-of-surgery-mbbs/introduction/)
      </p>
      <br />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <img
          style={{ maxHeight: "400px" }}
          src="https://premedpk-cdn.sgp1.digitaloceanspaces.com/CDN/Blog/InternationStudentBlogPic3.jpg"
        />
      </div>
      <br />
      <p style={{ overflowWrap: "break-word" }}>
        <strong>Liaquat National Medical College (LMNC)</strong> also
        facilitates foreign students and overseas Pakistani applicants though
        the overseas Pakistanis, having a Pakistani nationality, are treated as
        local applicants, in terms of the fee structure. The requirements are
        clearance of the MDCAT and the interview stage, conducted by the
        college. Facilitations can be provided by the university, such as
        arranging a virtual interview however, this varies from case to case
        basis. A merit list is prepared on the basis of the MDCAT score, the
        Board Examinations (Equivalency), and the internal interview.
        <br />
        https://lnmc.lnh.edu.pk/admission.html <br />
        https://lnmc.lnh.edu.pk/Admission/2022MBBS%20Fee%20Structure.pdf <br />
        These two links may be used to get more information on the eligibility
        criteria and fee structure.
      </p>
      <br />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <img
          style={{ maxHeight: "400px" }}
          src="https://premedpk-cdn.sgp1.digitaloceanspaces.com/CDN/Blog/InternationStudentBlogPic4.jpg"
        />
      </div>
      <br />
      <h2>2. Public Universities</h2>
      <p>
        To apply to public universities, students have the option of multiple
        approaches.
      </p>
      <p style={{ overflowWrap: "break-word" }}>
        <ul>
          <li>
            One would be to apply via the{" "}
            <strong>Pakistan Technical Assistance Program (PTAP)</strong>. This
            program is a direct initiative from the Ministry of Economic
            Affairs, that offers 20 seats to applicants carrying a dual
            nationality with one of them being Pakistan’s. The program comes
            with a cost benefit. The website for this organization and process
            can be checked for the fees structure of reach university, but for
            Dow Mecial College, the applicant would only be required to pay
            50,000 PKR approximately (essentially the merit fee). Moreover,
            qualifying candidates may choose between the prestigious public
            medical colleges of Pakistan, to enroll in. Some of the options
            available are: DUHS, KEMU, AIMC, RMU, FJMC, SIMS, AMC, NMC, and
            more. Furthermore, the applicant’s domicile won’t be considered,
            given the/she has completed his/her grade 11 and 12 from aboard.
            This would imply that the applicant would be able to apply to King
            Edward’s College, in Lahore, and Dow University, in Karachi,
            something that isn’t possible for local applicants. To apply, the
            form on the website, <br />
            (https://ead.gov.pk/Detail/NDczNzA5ZGUtMTc2Zi00ZjVlLWI5OWUtNWZiMTlmMmFhZWRi),
            <br />
            released in August, must be filled. Alongside the filled form, MDCAT
            scores, attested copies of educational transcripts, attested copies
            of NICOP or Passport of the applicant and a parent, passport size
            photographs, and Equivalency from IBCC, must be sent via mail.
            Attestation can be done by the local consulate or the ambassy. A
            merit list would be prepared and released in November. An interview,
            as a formality, would be set up in Islamabad, though one may be
            accommodated with online interviews.
            <br />
            <br /> You can check out this video from Random and More to get to
            know more about this topic: How can international students get into
            medical colleges of Pakistan I Dow Medical College (PTAP):
            https://www.youtube.com/watch?v=toVXLmKdbyI
          </li>
          <li>
            <strong>Overseas Pakistanis</strong>, carrying a dual nationality,
            must fill out the form on DOW’s official website
            (https://www.duhs.edu.pk/new/). This would be needed for them to
            apply to DOW Medical College or any other public university of
            Sindh. Equivalency and clearing the MDCAT is a cruciality. Depending
            upon the results of the merit list, the applicant would be given the
            option of the medical college. The attached website link carries the
            requirements and the fee structure for different public
            institutions.
            <br />
            https://www.duhs.edu.pk/new/wp-content/uploads/2021/01/20210106-Notice-for-overseas-2020-21.pdf{" "}
            <br />
            Unlike the PTAP program, this approach lacks the cost benefit and
            fees is unreasonably high also because it is in US dollars. You may
            use the link, provided above, to go through the fee structure.
          </li>
          <br />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img
              style={{ maxHeight: "400px" }}
              src="https://premedpk-cdn.sgp1.digitaloceanspaces.com/CDN/Blog/InternationStudentBlogPic5.jpg"
            />
          </div>
          <br />
          <li>
            <strong>Foreign Students</strong>, not having a Pakistani Passport
            or NICOP, can apply to Dow International Medical Collge (DIMC). The
            form would be made available on Dow’s official website
            (https://www.duhs.edu.pk/new/). Clearance of MDCAT and Equivalency
            is a necessity and the admission process would be similar to the
            one, mentioned prior for Overseas Pakistanis, the only difference
            being applicants, in this category, would be lacking a Pakistan
            nationality. <br />
            https://www.duhs.edu.pk/new/wp-content/uploads/2021/01/20210106-Notice-for-overseas-2020-21.pdf{" "}
            <br />
            The link mentioned above may be used to check for the remaining
            requirements.
          </li>
        </ul>
      </p>
      <br />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <img
          style={{ maxHeight: "400px" }}
          src="https://premedpk-cdn.sgp1.digitaloceanspaces.com/CDN/Blog/InternationStudentBlogPic6.jpg"
        />
      </div>
      <br />
      <p>
        At the end of this blog, we are adding the links of a few YouTube videos
        by some very resourceful channels which you can also skim through to get
        a further idea about the entire process:
        <br />
        <br />
        <YoutubeEmbeded embedId="6bSSbFWC9Ws" />
        <br />
        <br />
        <YoutubeEmbeded embedId="gy7YbsquC6U" />
        <br />
        <br />
        <YoutubeEmbeded embedId="yaCNkeaKv_4" /> <br />
        <br />
        <YoutubeEmbeded embedId="-CyAr1bgEik" /> <br />
        <br />
        <br /> We hope that, through this guide, your questions and confusions
        were answered, but if you have any further questions, you can always
        reach out to PreMed.PK’s social media platforms (Facebook & Instagram)
        for further guidance. If you feel any of the information stated here is
        not up to date or accurate, please feel free to email us at
        contact@premed.pk and we will solve the problem immediately.
      </p>
    </>
  ),
};
