import React, { useState, useEffect, useContext } from "react";
import ReactDOM from "react-dom";
import { useHistory } from "react-router-dom";
import { Alert, Badge } from "shards-react";
import "bootstrap/dist/css/bootstrap.min.css";
import "shards-ui/dist/css/shards.min.css";
import styles from "./DeckStartComponent.module.css";
import { GetDeckDescription } from "../../APIManager";
import Lightbulbicon from "../../StaticImages/light-bulb.svg";
import YearlyModeToggle from "./YearlyModeToggle";
import TutorModeIcon from "../../StaticImages/TutorModeIcon.svg";

import { UserContext } from "../../userContext";

import GradientBtn from "../GradientBtn/GradientBtn";


import {
  Card,
  CardHeader,
  CardTitle,
  CardImg,
  CardBody,
  CardFooter,
  Button,
  Fade,
  FormRadio,
} from "shards-react";
import NUMSLogo from "../../StaticImages/NUMS Logo.png";
import NTSLogo from "../../StaticImages/NTS Logo.png";
import UHSLogo from "../../StaticImages/UHS Logo.jpg";
import PMCLogo from "../../StaticImages/PMC Logo.png";
import AKULogo from "../../StaticImages/AKU Logo.png";
import ETEALogo from "../../StaticImages/etealogo.jpg";
import FMDCLogo from "../../StaticImages/fmdclogo.jpg";
import HECLogo from "../../StaticImages/HEC.png";
import PreMedIcon from "../../StaticImages/PreMedCircleLogo.png";

import DeckMetaInfo from "./YearlyDecksMeta";

function QuizStartScreen({
  onStartCB,
  Name,
  NoOfQuestions,
  showResume,
  resumeNumber,
  resumeCB,
}) {
  /*  const [Name , SetDeckName] = useState(props.deckName);
const [Questions , SetQuestions] = useState(props.noQuestions); */
  const Questions = NoOfQuestions;
  const [deckDescription, SetDeckDescription] = useState("Hello");
  const history = useHistory();

  const [user, SetUser] = useContext(UserContext);
  const isPremium = user.addonspurchased.includes("Premium");

  useEffect(() => {
    GetDeckDescription(Name).then((res) => {
      //SetDeckDescription(res.data);
      // console.log(res.data)
    });
  }, [Name]);

  function GetImgSrc(deck) {
    if (!deck) {
      return NTSLogo;
    }
    if (deck.includes("PMC")) {
      return PMCLogo;
    }
    if (deck.includes("NTS")) {
      return NTSLogo;
    }
    if (deck.includes("UHS")) {
      return UHSLogo;
    }
    if (deck.includes("NUMS")) {
      return NUMSLogo;
    } 
    if (deck.includes("ETEA")) {
      return ETEALogo;
    } 
    if (deck.includes("PreMed")) {
      return PreMedIcon;
    } 
    if (deck.includes("HEC") || deck.includes("FMDC")) {
      return FMDCLogo;
    } 
    else {
      return NTSLogo;
    }
  }

  // console.log(props);
  if (!Name) {
    return <></>;
  }
  return (
    <div style={{ textAlign: "center", margin: "auto" }}>
      <div className={styles.MainDiv}>
        <div className={styles.DeckHeaderContainer}>
          <div>
            <img src={GetImgSrc(Name)} />
          </div>
          <div className={styles.DeckHeader}>
            <div className={styles.DeckName}>{Name}</div>
            <div className={styles.Questions}>{Questions} Questions</div>
            <YearlyModeToggle
              onClick={() => {
                history.push("/deck/testmode?deck=" + Name);
              }}
              TutorActive
              isTutorPaid={!DeckMetaInfo[Name].TutorModeFree}
            />
          </div>
        </div>
        <div className={styles.ModeInfoContainer}>
          <img src={TutorModeIcon} />
          <br />
          <h3>Tutor Mode</h3>
          <div>
            <div className={`d-flex ${styles.CardTextRow}`}>
              <i className={`bi bi-check ${styles.checkIcon}`}></i>
              <p className={`${styles.cardText} mb-0`}>
                This paper is NOT <strong>timed</strong>
              </p>
            </div>
            <div className={`d-flex ${styles.CardTextRow}`}>
              <i className={`bi bi-check ${styles.checkIcon}`}></i>
              <p className={`${styles.cardText} mb-0`}>
                The Correct answer and explanation will be shown instantly once
                you select any option
              </p>
            </div>
            <div className={`d-flex ${styles.CardTextRow}`}>
              <i className={`bi bi-check ${styles.checkIcon}`}></i>
              <p className={`${styles.cardText} mb-0`}>
                Timer and detailed score report is not avaiable in 'Tutor Mode'
                and can be instead accessed in 'Time Test Mode'
              </p>
            </div>
          </div>
          <GradientBtn
            style={{
              fontSize: "0.8em",
              borderRadius: "30px",
              margin: 0,
              marginRight: "1em",
              padding: "1em 1.8em",
            }}
            gradient={"#02AAB0 0%, #00CDAC  51%, #02AAB0  100%"}
            onClick={() => {
              onStartCB();
            }}
          >
            Start Attempting Questions{" "}
          </GradientBtn>
        </div>
        {RenderResumeQuestion(showResume, resumeNumber, resumeCB)}
        <div className={styles.FactBox}>
          <div className={styles.DYKContainer}>
            <div>Instructions</div>
          </div>
          <div className={styles.Fact}>
            <ul>
              <li>
                {" "}
                This paper consists of total {Questions} Questions
                <ul>
                  <li>
                    {DeckMetaInfo[Name] ? DeckMetaInfo[Name].Eng : 20} English
                  </li>
                  <li>
                    {DeckMetaInfo[Name] ? DeckMetaInfo[Name].Bio : 80} Biology
                  </li>
                  <li>
                    {DeckMetaInfo[Name] ? DeckMetaInfo[Name].Chem : 60}{" "}
                    Chemistry
                  </li>
                  <li>
                    {DeckMetaInfo[Name] ? DeckMetaInfo[Name].Phys : 40} Physics
                  </li>
                  {DeckMetaInfo[Name] ? (
                    DeckMetaInfo[Name].Lr ? (
                      <li>{DeckMetaInfo[Name].Lr} Logical Reasoning</li>
                    ) : (
                      <></>
                    )
                  ) : (
                    <></>
                  )}
                </ul>
              </li>
              <li>
                The time alloted for this paper was {DeckMetaInfo[Name]?.Time}{" "}
                minutes, however, since you are attempting this in tutor mode,
                there is no timer and you are free to leave at any point and
                resume or start over.
              </li>
              <li>Each question has only one correct answer.</li>
              <li>
                You can move between questions using left/right arrow keys too.
              </li>
              <li>You can save a question, and come back to it at any time.</li>
              <li>
                Some questions of this paper have been modified, as they were
                cancelled that year.
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default QuizStartScreen;

function RenderResumeQuestion(show, no, cb) {
  if (show) {
    return (
      <Button
        style={{ margin: "1em" }}
        theme="success"
        onClick={(e) => {
          cb();
        }}
      >
        Resume from question {no}
      </Button>
    );
  }
}
