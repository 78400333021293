import React from "react";
import styles from "./QuestionComponent.module.css";
import parse from "html-react-parser";

import { CardTitle } from "shards-react";

const QuestionText = ({ questionText }) => {
  return (
    <CardTitle>
      <div className={styles.QuestionText}>
        {parse(
          questionText.replace(
            /\s/g /* all kinds of spaces*/,
            " " /* ordinary space */
          )
        )}
      </div>
    </CardTitle>
  );
};

export default QuestionText;
