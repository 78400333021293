import React from "react";
import { Redirect, Route } from "react-router-dom";
import { UserContext } from "./userContext";
import { useState, useContext } from "react";
import { LocalConvenienceStoreOutlined } from "@material-ui/icons";
import { getLoggedInUser } from "./APIManager";
import { RefreshUserContext } from "./UtilityFunctions";

export const PremiumRoute = ({ Component: Component, ...rest }) => {
  const [user, setUser] = useContext(UserContext);

  return (
    <Route
      {...rest}
      render={(props) => {
        //console.log("user  is " + user);
        ReCheckAuth();
        if (user.isLoggedIn) {
          if (user.addonspurchased.includes("Premium")) {
            return <Component {...props} />;
          } else {
            return <Redirect to="/plans" />;
          }
        } else {
          return <Redirect to="/login" />;
        }
      }}
    />
  );

  function ReCheckAuth() {
   RefreshUserContext(setUser, user)
  }
};
