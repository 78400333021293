import React, { useState, useContext, useEffect } from "react";
import styles from "./DeckStartComponent.module.css";
import { Badge } from "shards-react";
import { UserContext } from "../../userContext";
import {
  Button,
  FormInput,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
} from "shards-react";
import GradientBtn from "../../Components/GradientBtn/GradientBtn.jsx";
import ClearOutlinedIcon from "@material-ui/icons/ClearOutlined";

import { useHistory } from "react-router-dom";

export default function TopicalModeToggle({
  onClick,
  onPracticeClick,
  onPastPaperClick,
}) {
  const [user, setUser] = useContext(UserContext);
  const [modalOpen, SetModalOpen] = useState(false);
  const isPremium = user.addonspurchased.includes("Premium");

  const history = useHistory();
  const pastPaperTopicals = window.location.pathname.includes("pastpaper");

  const PracticeActive = !pastPaperTopicals;

  function OnTimedClick() {
    onClick();
  }

  return (
    <div className={styles.YearlyModeToggle}>
      <Modal
        open={modalOpen}
        toggle={() => {
          SetModalOpen(!modalOpen);
        }}
      >
        <ModalHeader className="ShardsModalClosable">
          Premium Feature{" "}
          <div className={"ShardsModalCloseBtn"}>
            <ClearOutlinedIcon
              onClick={() => {
                SetModalOpen(!modalOpen);
              }}
            />
          </div>
        </ModalHeader>
        <ModalBody>
          <div style={{ fontSize: "1.2em", textAlign: "center", color: "Red" }}>
            This feature is only available for Premium Users
            <br />
            <br />
            <GradientBtn
              style={{
                fontSize: "0.7em",
                borderRadius: "30px",
                margin: 0,
              }}
              gradient={"#D31027 0%, #EA384D  51%, #D31027  100%"}
              onClick={onClick}
            >
              Purchase Premium Plan
            </GradientBtn>
          </div>
        </ModalBody>
      </Modal>
      <div
        onClick={PracticeActive ? onPastPaperClick : null}
        className={!PracticeActive ? styles.Active : ""}
      >
        Past Paper Questions
        <div className={`${styles.PillLabel} ${styles.FreeLabel}`}>Free</div>
      </div>
      <div
        onClick={!PracticeActive ? onPracticeClick : null}
        className={PracticeActive ? styles.Active : ""}
      >
        Practice Questions
        <div className={`${styles.PillLabel} ${styles.PremiumLabel}`}>
          Premium
        </div>
      </div>
    </div>
  );
}
