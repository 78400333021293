import React, { useDebugValue, useEffect, useContext, useState } from "react";
import SavedIcon from "@material-ui/icons/TurnedIn";
import NotSavedIcon from "@material-ui/icons/TurnedInNot";
import IconButton from "@material-ui/core/IconButton";
import MatButton from "@material-ui/core/Button";
import styles from "./QuestionComponent.module.css";

import {
  LogSaveQuestion,
  GetIsQuestionSaved,
  DeleteBookmark,
} from "../../APIManager";
import { UserContext } from "../../userContext";
import NotLoggedInToggle from "./NotLoggedInToggle";

const BookMark = ({ isHidden, deckName, questionID, deckType }) => {
  const [user, SetUser] = useContext(UserContext);
  const [notLoggedInModalOpen, SetNotLoggedInModalOpen] = useState(false);

  const [isSaved, SetIsSaved] = useState(false);

  useEffect(() => {
    if (!user.isLoggedIn) {
      return;
    }
    GetIsQuestionSaved(deckName, questionID).then((res) => {
      SetIsSaved(res.data);
    });
  }, [deckName, questionID]);

  function onClick() {
    if (!user.isLoggedIn) {
      SetNotLoggedInModalOpen(true);
      return;
    }

    if (!isSaved) {
      LogSaveQuestion(deckName, questionID, deckType).then((res) => {
        SetIsSaved(true);
      });
    } else {
      DeleteBookmark(deckName, questionID).then(() => {
        SetIsSaved(false);
      });
    }
  }

  if (isHidden) {
    return <></>;
  }

  return (
    <>
      <NotLoggedInToggle
        open={notLoggedInModalOpen}
        onClose={() => {
          SetNotLoggedInModalOpen(false);
        }}
      />
      <div className={styles.BookMarkContainer}>
        <IconButton size="large" onClick={onClick}>
          {isSaved ? <SavedIcon size="large" /> : <NotSavedIcon />}
        </IconButton>
        <MatButton
          color="secondary"
          onClick={onClick}
          style={{ fontFamily: "Rubik" }}
        >
          {isSaved ? "Un-Save this question" : "Save this question"}
        </MatButton>
      </div>
    </>
  );
};

export default BookMark;
