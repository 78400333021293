import React from "react";

import styles from "./Ambassadors.module.css";

export default function TeamColumnMobile({ persons }) {
  return (
    <div>
      {persons.map((person) => {
        return (
          <>
            <div class={`${styles.teamPicContainer} p-4 pb-0 row`}>
              <TeamPhotoBox Img={person.Image} />;
            </div>
            <div class={`${styles.nameRow} p-4 pb-0 row`}>
              <TeamNameBox
                Name={person.Name}
                Title={person.School}
                City={person.City}
              />
            </div>
          </>
        );
      })}
    </div>
  );
}

const TeamPhotoBox = ({ Img }) => {
  return (
    <>
      <div class="col">
        <img class={`${styles.teamPic}`} src={Img} alt="" />
      </div>
    </>
  );
};

const TeamNameBox = ({ Name, Title, City }) => {
  return (
    <div class="col">
      <h1 class={`mt-3 ${styles.teamName}`}>{Name}</h1>
      <h3 class={`${styles.teamTitle}`}>{Title}</h3>
      {Name && <label class={`${styles.teamButton} py-2 px-4`}>{City}</label>}
    </div>
  );
};
