import React, { useEffect, useContext } from "react";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import { Snackbar } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { LogReport } from "../../APIManager";

import { FormCheckbox } from "shards-react";
import { StylesContext } from "@material-ui/styles";

import styles from "./QuestionComponent.module.css";

import NotLoggedInToggle from "./NotLoggedInToggle";

import { UserContext } from "../../userContext";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ReportQuestion({
  OnCloseCB,
  OnSubmitCB,
  deckName,
  questionID,
  isOpen,
}) {
  console.log(isOpen);

  const [user, SetUser] = useContext(UserContext);

  const [error, SetError] = React.useState(false);
  const [complaint, SetComplaint] = React.useState("");

  const [showAlreadyReported, SetShowAlreadyReported] = React.useState(false);
  const [showSuccessReported, SetShowSuccessReported] = React.useState(false);

  const [wrongQuestion, SetWrongQuestion] = React.useState(false);
  const [wrongOptions, SetWrongOptions] = React.useState(false);
  const [wrongExplanation, SetWrongExplanation] = React.useState(false);
  const [wrongTags, SetWrongTags] = React.useState(false);
  const [duplicateQuestion, SetDuplicateQuestion] = React.useState(false);
  const [wrongAnswer, SetWrongAnswer] = React.useState(false)

  useEffect(() => {
    ValidateComplaintInput();
  }, [complaint]);
  function ValidateComplaintInput() {
    if (complaint.length < 2) {
      SetError(true);
    } else {
      SetError(false);
    }
  }

  useEffect(() => {
    ResetFields();
  }, [questionID]);
  const handleClose = () => {
    OnCloseCB();
  };

  if (!user.isLoggedIn) {
    return <NotLoggedInToggle open={isOpen} onClose={OnCloseCB} />;
  }

  return (
    <div>
      <Dialog
        scroll="body"
        open={isOpen}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        className={styles.ReportQuestionModal}
      >
        <DialogTitle id="alert-dialog-slide-title">
          {"🚨 Report an Issue"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Please fill out the following as carefully and detailed as possible,
            which will ensure your problem is addressed effectively. Once the
            complaint is reviewed, you will be also emailed the response
          </DialogContentText>
          <TextField
            variant="outlined"
            onChange={(e) => {
              SetComplaint(e.target.value);
            }}
            value={complaint}
            error={error}
            autoFocus
            multiline
            helperText={error ? "This field cannot be left empty" : ""}
            margin="dense"
            id="Complaint"
            type="text"
            fullWidth
            label="What is the issue with this question"
            inputProps={{ maxLength: 799 }}
            InputLabelProps={{ style: { fontSize: "0.9em" } }}
          />

          <DialogContentText id="alert-dialog-slide-description">
            <strong style={{ fontSize: "1.3em" }}>
              {" "}
              Select all that apply
            </strong>
          </DialogContentText>
          <FormCheckbox
            inline
            checked={wrongQuestion}
            onChange={(e) => SetWrongQuestion(!wrongQuestion)}
          >
            <strong>Question is Wrong</strong>
          </FormCheckbox>

          <FormCheckbox
            inline
            checked={wrongOptions}
            onChange={(e) => SetWrongOptions(!wrongOptions)}
          >
            <strong>Options are Wrong</strong>
          </FormCheckbox>
          <FormCheckbox
            inline
            checked={wrongAnswer}
            onChange={(e) => SetWrongAnswer(!wrongAnswer)}
          >
            <strong>Answer is marked wrong</strong>
          </FormCheckbox>
          <FormCheckbox
            inline
            checked={wrongExplanation}
            onChange={(e) => SetWrongExplanation(!wrongExplanation)}
          >
            <strong>Explanation is Wrong</strong>
          </FormCheckbox>
          <FormCheckbox
            inline
            checked={duplicateQuestion}
            onChange={(e) => SetDuplicateQuestion(!duplicateQuestion)}
          >
            <strong>Duplicate Question</strong>
          </FormCheckbox>
          <FormCheckbox
            inline
            checked={wrongTags}
            onChange={(e) => SetWrongTags(!wrongTags)}
          >
            <strong>Wrong Tags</strong>
          </FormCheckbox>
        </DialogContent>
        <DialogActions>
          <Button
            disabled={error}
            style={{ margin: "1em" }}
            onClick={() => {
              OnSubmit();
            }}
            variant="contained"
            color="primary"
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );

  function OnSubmit() {
    if (!error) {
      LogReport({
        DeckName: deckName,
        QuestionID: questionID,
        Complaint: complaint,
        Tags: GetTags(),
      });
      SetShowSuccessReported(true);
      OnSubmitCB();
      ResetFields();
    }
  }

  function ResetFields() {
    SetComplaint("");
    SetWrongExplanation(false);
    SetWrongOptions(false);
    SetWrongQuestion(false);
    SetWrongTags(false);
    SetDuplicateQuestion(false);
  }

  function GetTags() {
    const tags = [];
    if (wrongQuestion) {
      tags.push("Wrong Question");
    }
    if (wrongOptions) {
      tags.push("Wrong Options");
    }
    if (wrongExplanation) {
      tags.push("Wrong Explanation");
    }
    if (duplicateQuestion) {
      tags.push("Duplicate Question");
    }
    if (wrongTags) {
      tags.push("Wrong Tags");
    }
    if (wrongAnswer){
      tags.push("Wrong answer marked")
    }
    return tags;
  }
}
