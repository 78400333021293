import React from "react";
import styles from "./QuestionComponent.module.css"

export default function ProgressBar({ questionNo, NoOfQuestions, text }) {
  const total = parseInt(NoOfQuestions);
  const percentage = (questionNo / total) * 100 + "%";
  return (
    <>
      <div
        className={`progress ProgressBar ${styles.ContentMaxWidth}`}
        style={{ height: "20px", backgroundColor: "lightgray" }}
      >
        <div
          class="progress-bar progress-bar-animated"
          role="progressbar"
          style={{
            width: percentage,
            height: "20px",
            "background-color": "#639AF6",
          }}
          aria-valuenow="75"
          aria-valuemin="0"
          aria-valuemax="100"
        ></div>
      </div>
      <div
        style={{
          fontSize: "1.2em",
          fontFamily: "Rubik",
          fontWeight: "bolder",
          width: "100%",
          textAlign: "center",
        }}
      >
        {text ? text : `${questionNo} of ${total} Questions`}
      </div>
    </>
  );
}
