import React, { useState, useEffect } from "react";
import { useHistory, useParams, withRouter } from "react-router";
import { Badge } from "shards-react";
import parse from "html-react-parser";
import "bootstrap/dist/css/bootstrap.min.css";
import "shards-ui/dist/css/shards.min.css";
import {
  Card,
  CardHeader,
  CardTitle,
  CardImg,
  CardBody,
  CardFooter,
  Button,
  Fade,
} from "shards-react";
import ReactGA from "react-ga";
import { Progress } from "shards-react";
import { Breadcrumb, BreadcrumbItem } from "shards-react";
import "./TestModeQuestionComponent.css";
import ReportQuestion from "../QuestionDeck/ReportQuesion";
import SkipToQuestion from "../QuestionDeck/SkipToQuestion";
import { BurstMode, Deck, QueueTwoTone } from "@material-ui/icons";

import { makeStyles } from "@material-ui/core/styles";
import MatButton from "@material-ui/core/Button";
import Chip from "@material-ui/core/Chip";
import CircularProgress from "@material-ui/core/CircularProgress";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";

import TagsIcon from "@material-ui/icons/LocalOffer";
import SavedIcon from "@material-ui/icons/TurnedIn";
import NotSavedIcon from "@material-ui/icons/TurnedInNot";
import IconButton from "@material-ui/core/IconButton";

import TagList from "../../Components/TagList/TagList"

import {
  LogSaveQuestion,
  GetIsQuestionSaved,
  DeleteBookmark,
} from "../../APIManager";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  chips: {
    display: "flex",
    justifyContent: "left",
    flexWrap: "wrap",
    "& > *": {
      margin: theme.spacing(0.5),
    },
  },
  spinner: {
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    "& > *": {
      margin: theme.spacing(0.5),
    },
  },
}));

let CardStyle = {
  width: "100%",
  height: "100%",
  left: "0",
  top: "0",
};

function QuestionComponent(props) {
  document.onkeydown = checkKey;

  const [showAnswer, SetShowAnswer] = useState(false);

  const [reportOpen, SetReportOpen] = useState(false);
  const [isReported, SetIsReported] = useState(false);
  const [showSnackBar, SetShowSnackBar] = useState(false);
  const [showSuccessSnackBar, SetShowSuccessSnackBar] = useState(false);
  const [skipOpen, SetSkipOpen] = useState(false);
  const [hasScrolled, setHasScrolled] = useState(false);

  const [isSaved, SetIsSaved] = useState(false);

  const {
    ShowQuestion,
    PreviousCB,
    NextCB,
    PreviousActive,
    NextActive,
    QuestionNo,
    DeckName,
    QuestionID,
    QuestionContent,
    QuestionOptions,
    QuestionTags,
    QuestionImage,
    ExplanationContent,
    ExplanationImage,
    TotalQuestions,
    AttemptCB,
    SkipCB,
    DeckType,
    IsTestPaused,
    SelectedOption,
    NoOfAttempts,
    hideTags
  } = props;
  
  const [selectedOption, SetSelectedOption] = useState(
    SelectedOption ? SelectedOption : ""
  );
  
  useEffect(() => {
    //console.log(props)
    SetSelectedOption(SelectedOption);
  }, [SelectedOption]);

  useEffect(() => {
    FetchIsSaved(DeckName, QuestionID);
  }, [DeckName, QuestionID]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [QuestionNo]);

  function FetchIsSaved(DeckName, QuestionID) {
    GetIsQuestionSaved(DeckName, QuestionID).then((res) => {
     // console.log(res);
      if (res.data) {
        SetIsSaved(true);
       // console.log(res.data);
      } else {
        SetIsSaved(false);
        //console.log(res.data);
      }
    });
  }

  function onSaveClick() {
    if (!isSaved) {
      LogSaveQuestion(DeckName, QuestionID, DeckType).then(() => {
        FetchIsSaved(DeckName, QuestionID);
      });
    } else {
      DeleteBookmark(DeckName, QuestionID).then(() => {
        FetchIsSaved(DeckName, QuestionID);
      });
    }
  }

  function checkKey(e) {
    e = e || window.event;

    if (e.keyCode == "38") {
      // up arrow
    } else if (e.keyCode == "40") {
      //console.log("down");
    } else if (e.keyCode == "37") {
      try {
        if (
          PreviousActive &&
          QuestionContent &&
          !skipOpen &&
          !reportOpen &&
          !IsTestPaused
        ) {
          SetShowAnswer(false);
         // SetSelectedOption("");
          SetIsReported(false);
          PreviousCB();
          ReactGA.event({
            category: "Question",
            action: "ArrowKeyNext/Back",
            label: "TestMode"
          })
        }
      } catch {}
    } else if (e.keyCode == "39") {
      if (
        NextActive &&
        QuestionContent &&
        !skipOpen &&
        !reportOpen &&
        !IsTestPaused
      ) {
        SetShowAnswer(false);
        SetIsReported(false);
       // SetSelectedOption("");
        NextCB();
        ReactGA.event({
          category: "Question",
          action: "ArrowKeyNext/Back",
          label: "TestMode"
        })
      }
    }
  }

  const [progressBarStyle, SetProgressBarStyle] = useState(
    CalculateProgressBarStyle(QuestionNo, TotalQuestions)
  );
  const classes = useStyles();
  const getNextButtonClass = () => {
    if (NextActive) {
      return "page-item";
    } else {
      return "page-item disabled";
    }
  };
  const getPreviousButtonClass = () => {
    if (PreviousActive) {
      return "page-item";
    } else {
      return "page-item disabled";
    }
  };
  const NextButtonClass = getNextButtonClass();
  const PreviousButtonClass = getPreviousButtonClass();
  if (ShowQuestion) {
    return (
      <Card
        style={CardStyle}
        className={`${IsTestPaused ? "BlurQuestion" : " "}`}
      >
        <SkipToQuestion
          numberOfQuestions={TotalQuestions}
          currentQuestion={QuestionNo}
          isOpen={skipOpen}
          key={skipOpen + "skip"}
          onCloseCB={() => {
            SetSkipOpen(false);
          }}
          onSkipCB={(no) => {
           // console.log("hello");
            SetShowAnswer(false);
            SetIsReported(false);
            //SetSelectedOption("");
            SetSkipOpen(false);
            SkipCB(no);
            ReactGA.event({
              category: "Question",
              action: "Skipped",
              label: "TestMode"
            })
          }}
        />
        <ReportQuestion
          key={reportOpen + "report"}
          questionID={QuestionID}
          deckName={DeckName}
          isOpen={reportOpen && !isReported}
          onCloseCB={() => {
            SetReportOpen(false);
          }}
          onSubmitCB={() => {
            SetIsReported(true);
            SetShowSuccessSnackBar(true);
          }}
        />
        <Snackbar
          open={showSnackBar}
          autoHideDuration={6000}
          onClose={() => {
            SetShowSnackBar(false);
          }}
        >
          <Alert
            onClose={() => {
              SetShowSnackBar(false);
            }}
            severity="warning"
          >
            Question Already Reported
          </Alert>
        </Snackbar>
        <Snackbar
          open={showSuccessSnackBar}
          autoHideDuration={6000}
          onClose={() => {
            SetShowSuccessSnackBar(false);
          }}
        >
          <Alert
            onClose={() => {
              SetShowSuccessSnackBar(false);
            }}
            severity="success"
          >
            Question Succesfuly Reported
          </Alert>
        </Snackbar>
        <CardHeader>
          <div className="QuestionCardHeader">
            <div className="SkipButton">
              <MatButton
                color="primary"
                onClick={() => {
                  SetSkipOpen(true);
                }}
                disabled={IsTestPaused}
              >
                Skip to a Question
              </MatButton>
            </div>
            <div className="ReportButton">
              <MatButton
                color="secondary"
                onClick={() => {
                  if (isReported) {
                    SetShowSnackBar(true);
                  } else {
                    SetReportOpen(true);
                  }
                }}
                disabled={IsTestPaused}
              >
                Report this Question
              </MatButton>
            </div>
          </div>
        </CardHeader>

        <CardBody className="QuestionBody">
          {RenderLoadingSpinner(classes.spinner, QuestionContent)}
          <div className="QuestionMainBody">
            <span
              style={{
                marginLeft: "-1em",
                marginTop: ".5em",
                marginBottom: ".7em",
              }}
            >
              {" "}
              <BookMark
                showControl={QuestionContent}
                disabled={IsTestPaused}
                isSaved={isSaved}
                onChange={onSaveClick}
              />
            </span>
            <CardTitle>
              <div className="QuestionContent">
                {parse(
                  QuestionContent.replace(
                    /\s/g /* all kinds of spaces*/,
                    " " /* ordinary space */
                  )
                )}
              </div>
            </CardTitle>
            {
              hideTags ? <></> :  <TagList styleClass = {classes.chips} tags = {QuestionTags}/>
            }
           
            {RenderQuestionImage(QuestionImage)}
            {/*  <p>
            {QuestionOptions.map((option) => (
              <h7>
                {option.OptionLetter + ") " + option.OptionText} <br />{" "}
              </h7>
            ))}
          </p> */}
            {QuestionOptions.map((option) => (
              <div className="OptionButton" key = {option.OptionLetter}>
                <QuizOptionButton
                  Content={
                    option.OptionLetter +
                    ") " +
                    option.OptionText.replace("<p>", "").replace(
                      "</p>",
                      ""
                    )
                  }
                  SelectedAnswer={selectedOption}
                  disabled={IsTestPaused}
                  OptionLetter={option.OptionLetter}
                  IsCorrect={option.IsCorrect}
                  ShowBadges={showAnswer}
                  EventHandler={(props) => {
                    SetSelectedOption(props.OptionLetter)
                    AttemptCB(props);
                  }}
                ></QuizOptionButton>{" "}
                <br />
              </div>
            ))}
          </div>
        </CardBody>

        <div className="QuestionNav">
          <nav aria-label="Page navigation example" className="PageNav">
            <ul
              class="pagination justify-content-center"
              style={{ fontSize: "1.3rem" }}
            >
              <li
                class={PreviousButtonClass}
                onClick={(target) => {
                  if (PreviousActive && !IsTestPaused) {
                    SetShowAnswer(false);
                    //SetSelectedOption("");
                    SetIsReported(false);
                    PreviousCB();
                  }
                }}
              >
                {" "}
                <div
                  class="page-link"
                  style={
                    PreviousActive
                      ? { cursor: "pointer", color: "#08bc7d" }
                      : {}
                  }
                >
                  Back
                </div>
              </li>
              <li
                class="page-item"
                backgroundColor="#08bc7d"
                style={{ color: "#08bc7d" }}
              >
                <a class="page-link">{QuestionNo}</a>
              </li>

              <li
                class={NextButtonClass}
                onClick={() => {
                  if (NextActive && !IsTestPaused) {
                    SetShowAnswer(false);
                    SetIsReported(false);
                   // SetSelectedOption("");
                    NextCB();
                  }
                }}
              >
                <div
                  class="page-link"
                  style={
                    NextActive ? { cursor: "pointer", color: "#08bc7d" } : {}
                  }
                >
                  Next
                </div>
              </li>
            </ul>
          </nav>
          <div className = "TestModeQuestionComp_NavText">{NoOfAttempts}/{TotalQuestions} Attempted</div>
        </div>
        {RenderProgressBar(NoOfAttempts, TotalQuestions)}
        <div id="answerFooter" style={{ minHeight: "5vh" }}>
          {RenderAnswerFooter(showAnswer, ExplanationContent, ExplanationImage)}
        </div>
      </Card>
    );
  } else {
    return <div />;
  }
}

function RenderProgressBar(NoOfAttempts, TotalQuestions) {
  var no = parseInt(NoOfAttempts) ;
  const total = parseInt(TotalQuestions);
  const percentage = (no / total) * 100 + "%";
  return (
    <div
      class="progress ProgressBar"
      style={{ height: "20px", backgroundColor: "lightgray" }}
    >
      <div
        class="progress-bar progress-bar-animated"
        role="progressbar"
        style={{
          width: percentage,
          height: "20px",
          "background-color": "#08bc7d",
        }}
        aria-valuenow="75"
        aria-valuemin="0"
        aria-valuemax="100"
      >
        <div style={{ fontSize: ".9rem" }}>
          {no} out of {total} Questions Attempted
        </div>
      </div>
    </div>
  );
}

function CalculateProgressBarStyle(QuestionNo, TotalQuestions) {
  const no = parseInt(QuestionNo);
  const total = parseInt(TotalQuestions);
  const percentage = (no / total) * 100 + "%";
  //{{ width: {CalculateProgressBarWidth(QuestionNo, TotalQuestions)}, height: "20px" }}
  return { width: "percentage", height: "20px" };
}

function RenderLoadingSpinner(styleClass, content) {
  if (content.length > 1) {
    return <div />;
  } else {
    return (
      <div className={styleClass}>
        <CircularProgress />
      </div>
    );
  }
}

function RenderTags(styleClass, tags) {
  return (
    <div className={styleClass} style={{ marginBottom: "1em" }}>
      {tags.length > 0 ? <TagsIcon style = {{marginTop : ".3em"}} /> : <div />}
      {tags.map((tag) => (
        <Chip
        style={
          tag.name === "bpb" || tag.name === "bcf" || tag.name === "pvc" || tag.name === "cvc" ? { display: "none" } : {}
        }
          size="medium"
          label={tag.name}
          key = {tag.name}
          color="secondary"
          variant="outlined"
        />
      ))}
    </div>
  );
}

function RenderQuestionImage(image) {
  if (image) {
    return (
      <div>
        <img
          src={image}
          className="QuestionImage"
          style={{
            marginRight: "1rem",
            marginTop: "1rem",
            marginBottom: "1rem",
          }}
        />
      </div>
    );
  } else {
    return <div />;
  }
}

function RenderExplanationImage(image) {
  if (image) {
    return (
      <div>
        <img
          src={image}
          className="QuestionImage"
          style={{
            marginRight: "1rem",
            marginTop: "1rem",
            marginBottom: "1rem",
          }}
        />
      </div>
    );
  } else {
    return <div />;
  }
}

function RenderAnswerFooter(showAnswer, ExplanationContent, ExplanationImage) {
  if (showAnswer === true) {
    return (
      <div>
        <Fade in={true}>
          <CardFooter>
            <h5>Answer Explanation:</h5>
            <div className="ExplanationContent">
              {parse(ExplanationContent)}
              {RenderExplanationImage(ExplanationImage)}
            </div>
          </CardFooter>
        </Fade>
      </div>
    );
  } else {
    return null;
  }
}
const QuizOptionButton = (props) => {
  return (
    <Button
      className="OptionButton"
      block={true}
      disabled={props.disabled}
      outline={props.SelectedAnswer === props.OptionLetter ? false : true}
      theme={"OptionColor"}
      onClick={() => {
        props.EventHandler(props);
      }}
    >
      {parse(props.Content)}
      {/* {props.Content} &rarr; */}
    </Button>
  );
};
const BookMark = (props) => {
  const { showControl, isSaved, onChange, disabled } = props;
  if (!showControl) {
    return <></>;
  }

  return (
    <div>
      <IconButton size="large" onClick={onChange} disabled={disabled}>
        {isSaved ? <SavedIcon size="large" /> : <NotSavedIcon />}
      </IconButton>
      <MatButton color="secondary" onClick={onChange} disabled={disabled}>
        {isSaved ? "Un-Save this question" : "Save this question"}
      </MatButton>
    </div>
  );
};

export default QuestionComponent;

var smoothScroll = function (elementId) {
  var MIN_PIXELS_PER_STEP = 16;
  var MAX_SCROLL_STEPS = 30;
  var target = document.getElementById(elementId);
  var scrollContainer = target;
  do {
    scrollContainer = scrollContainer.parentNode;
    if (!scrollContainer) return;
    scrollContainer.scrollTop += 1;
  } while (scrollContainer.scrollTop == 0);

  var targetY = 0;
  do {
    if (target == scrollContainer) break;
    targetY += target.offsetTop;
  } while ((target = target.offsetParent));

  var pixelsPerStep = Math.max(
    MIN_PIXELS_PER_STEP,
    (targetY - scrollContainer.scrollTop) / MAX_SCROLL_STEPS
  );

  var stepFunc = function () {
    scrollContainer.scrollTop = Math.min(
      targetY,
      pixelsPerStep + scrollContainer.scrollTop
    );

    if (scrollContainer.scrollTop >= targetY) {
      return;
    }

    window.requestAnimationFrame(stepFunc);
  };

  window.requestAnimationFrame(stepFunc);
};

const insertAt = (str, sub, pos) =>
  `${str.slice(0, pos)}${sub}${str.slice(pos)}`;
