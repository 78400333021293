import React, { useState, useContext, useEffect } from "react";
import styles from "./styles.module.css";
import { useHistory, useParams, withRouter } from "react-router";
import LockIcon from "@material-ui/icons/Lock";
import { Icon, IconButton } from "@material-ui/core";
import NotificationsIcon from "../StaticImages/mynotificationsicon.svg";
import { Tooltip } from "@material-ui/core";

import { NotificationContext } from "../notificationContext";

import { GetOfferTime, FormatOfferDate } from "../Pages/HomePage/HomePage";

import {
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  InputGroup,
  Collapse,
  Button,
  Badge,
  Popover,
  PopoverBody,
  PopoverHeader,
} from "shards-react";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import { BrowserRouter as Router, Route, Switch, Link } from "react-router-dom";
import { UserContext } from "../userContext";

import Avatar from "@material-ui/core/Avatar";
import Popper from "@material-ui/core/Popper";

import AccountPopper from "./AccountPopper";
import "../Navbar.scss";
import "./Navbar.css";

import GradientBtn from "../Components/GradientBtn/GradientBtn.jsx";

import useMediaQuery from "@material-ui/core/useMediaQuery";

import { Logout, getLoggedInUser } from "../APIManager";

import PreMedLogo from "../StaticImages/PreMedLogo.png";
import { Notifications } from "@material-ui/icons";
const linkStyle = { textDecoration: "none" };
const ForgotPassword = () => {
  let [userContext, SetUserContext] = useContext(UserContext);
  const [collapseOpen, SetCollapseOpen] = useState(false);
  const [dropdownOpen, SetDropdownOpen] = useState(false);

  const DeviceQuery770 = useMediaQuery("(max-width:770px)");

  const DeviceQuery1110 = useMediaQuery("(max-width:1110px)");
  const DeviceQuery1010 = useMediaQuery("(max-width:1010px)");
  const DeviceQuery940 = useMediaQuery("(max-width:940px)");
  const DeviceQuery890 = useMediaQuery("(max-width:890px)");

  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
    SetUserDropDownOpen(true);
  };
  const [userDropDownOpen, SetUserDropDownOpen] = useState(false);
  const id = userDropDownOpen ? "simple-popper" : undefined;

  const [aboutDropdownOpen, SetAboutDropdownOpen] = useState(false);
  const history = useHistory();

  const isPremiumUser = userContext.addonspurchased.includes("Premium");

  return (
    <>
      <Navbar
        className="NAVBAR-SHADOW"
        sticky={"top"}
        type="light"
        theme="custom"
        expand="md"
        id="PREMEDNAVBAR"
      >
        <NavbarBrand className={"PreMedLogo"} href="#">
          <Link
            to="/"
            style={linkStyle}
            onClick={() => {
              SetCollapseOpen(false);
            }}
          >
            <span
              className={styles.Logo}
              onClick={() => {
                SetCollapseOpen(false);
              }}
            >
              <img src={PreMedLogo} />
            </span>
          </Link>
        </NavbarBrand>
        <NavbarToggler
          onClick={() => {
            SetCollapseOpen(!collapseOpen);
          }}
        />

        <Collapse open={collapseOpen} navbar>
          <Nav navbar>
            <NavItem>
              <Link
                to="/"
                style={linkStyle}
                onClick={() => {
                  SetCollapseOpen(false);
                }}
              >
                <NavLink className={"NavLink"} active>
                  <div>Home</div>
                </NavLink>
              </Link>
            </NavItem>
            <Dropdown
              open={dropdownOpen}
              toggle={() => {
                SetDropdownOpen(!dropdownOpen);
              }}
            >
              <NavLink
                className={"NavLink"}
                active
                href="#"
                onClick={() => {
                  SetDropdownOpen(!dropdownOpen);
                }}
              >
                <div>
                  Dashboard{" "}
                  <i
                    class="arrow ArrowDown"
                    style={{ marginLeft: "4px", marginBottom: "2px" }}
                  ></i>
                </div>
              </NavLink>
              <DropdownMenu>
                <Link
                  onClick={() => {
                    SetCollapseOpen(false);
                  }}
                  to="/dashboard"
                  style={linkStyle}
                >
                  <DropdownItem>My Dashboard</DropdownItem>
                </Link>

                <Link
                  onClick={() => {
                    SetCollapseOpen(false);
                  }}
                  to="/recent-activity"
                  style={linkStyle}
                >
                  <DropdownItem>
                    {" "}
                    <LockIcon
                      hidden={isPremiumUser}
                      style={{
                        marginLeft: "-0.2em",
                        marginRight: "0.5ch",
                        marginTop: "-0.2em",
                      }}
                    />
                    My Recent Activity
                  </DropdownItem>
                </Link>
                <Link
                  onClick={() => {
                    SetCollapseOpen(false);
                  }}
                  to="/saved-questions"
                  style={linkStyle}
                >
                  <DropdownItem>
                    {" "}
                    <LockIcon
                      hidden={isPremiumUser}
                      style={{
                        marginLeft: "-0.2em",
                        marginRight: "0.5ch",
                        marginTop: "-0.2em",
                      }}
                    />
                    My Saved Questions
                  </DropdownItem>
                </Link>
                <Link
                  onClick={() => {
                    SetCollapseOpen(false);
                  }}
                  to="/stats"
                  style={linkStyle}
                >
                  <DropdownItem>My Stats</DropdownItem>
                </Link>
                <Link
                  onClick={() => {
                    SetCollapseOpen(false);
                  }}
                  to="/my-notifications"
                  style={linkStyle}
                >
                  <DropdownItem>My Notifications</DropdownItem>
                </Link>
                <Link
                  onClick={() => {
                    SetCollapseOpen(false);
                  }}
                  to="/my-account"
                  style={linkStyle}
                >
                  <DropdownItem>My Account</DropdownItem>
                </Link>
              </DropdownMenu>
            </Dropdown>
            <NavItem
              onClick={() => {
                SetCollapseOpen(false);
              }}
            >
              <Link to="/pmc" style={linkStyle}>
                <NavLink className={"NavLink"} active>
                  <div className={styles.NavItemTagged}>
                    PMC MCQs <div>NEW</div>
                  </div>
                </NavLink>
              </Link>
            </NavItem>
            <NavItem
              onClick={() => {
                SetCollapseOpen(false);
              }}
            >
              <Link to="/yearly" style={linkStyle}>
                <NavLink className={"NavLink"} active>
                  <div>Yearly MCQs</div>
                </NavLink>
              </Link>
            </NavItem>
            <NavItem
              onClick={() => {
                SetCollapseOpen(false);
              }}
            >
              <Link to="/topicals" style={linkStyle}>
                <NavLink className={"NavLink"} active>
                  <div>Topical MCQs</div>
                </NavLink>
              </Link>
            </NavItem>
            <NavItem
              onClick={() => {
                SetCollapseOpen(false);
              }}
            >
              <Link to="/mocks" style={linkStyle}>
                <NavLink className={"NavLink"} active>
                  <div>
                    <div className={styles.NavItemTagged}>Mocks</div>
                  </div>
                </NavLink>
              </Link>
            </NavItem>

            <NavItem
              onClick={() => {
                SetCollapseOpen(false);
              }}
            >
              <Link to="/pricing" style={linkStyle}>
                <NavLink className={"NavLink"} active>
                  <div>Pricing</div>
                </NavLink>
              </Link>
            </NavItem>
            <NavItem
              onClick={() => {
                SetCollapseOpen(false);
              }}
              hidden={DeviceQuery890 && !DeviceQuery770}
            >
              <Link to="/reviews" style={linkStyle}>
                <NavLink className={"NavLink"} active>
                  <div>Reviews</div>
                </NavLink>
              </Link>
            </NavItem>
            <NavItem
              onClick={() => {
                SetCollapseOpen(false);
              }}
              hidden={DeviceQuery940 && !DeviceQuery770}
            >
              <Link to="/blog" style={linkStyle}>
                <NavLink className={"NavLink"} active>
                  <div>Blog</div>
                </NavLink>
              </Link>
            </NavItem>
            {/*  <NavItem
            onClick={() => {
              SetCollapseOpen(false);
            }}
            style={!userContext.isLoggedIn ? { display: "none" } : {}}
          >
            <Link to="/tests" style={linkStyle}>
              <NavLink className={"NavLink"} active>
                <div>Tests</div>
              </NavLink>
            </Link>
          </NavItem> */}
            {/*   <NavItem
            onClick={() => {
              SetCollapseOpen(false);
            }}
          >
            <Link to="/Our-Team" style={linkStyle}>
              <NavLink className={"NavLink"} active>
                <div>Team</div>
              </NavLink>
            </Link>
          </NavItem>
          <NavItem
            onClick={() => {
              SetCollapseOpen(false);
            }}
          >
            <Link to="/contact-us" style={linkStyle}>
              <NavLink className={"NavLink"} active>
                <div>Contact Us</div>
              </NavLink>
            </Link>
          </NavItem> */}
            {(!DeviceQuery1010 || DeviceQuery770) && (
              <Dropdown
                open={aboutDropdownOpen}
                toggle={() => {
                  SetAboutDropdownOpen(!aboutDropdownOpen);
                }}
              >
                <NavLink
                  className={"NavLink"}
                  active
                  href="#"
                  onClick={() => {
                    SetAboutDropdownOpen(!aboutDropdownOpen);
                  }}
                >
                  <div>
                    About Us
                    <i
                      class="arrow ArrowDown"
                      style={{ marginLeft: "4px", marginBottom: "2px" }}
                    ></i>
                  </div>
                </NavLink>
                <DropdownMenu>
                  <Link
                    onClick={() => {
                      SetCollapseOpen(false);
                    }}
                    to="/contact-us"
                    style={linkStyle}
                  >
                    <DropdownItem>Contact Us</DropdownItem>
                  </Link>
                  <Link
                    onClick={() => {
                      SetCollapseOpen(false);
                    }}
                    to="/Our-Team"
                    style={linkStyle}
                  >
                    <DropdownItem>Our Team</DropdownItem>
                  </Link>
                </DropdownMenu>
              </Dropdown>
            )}
            {userContext.fullname && DeviceQuery770 && (
              <NavItem
                onClick={() => {
                  SetCollapseOpen(false);
                }}
              >
                <Link
                  to=""
                  style={linkStyle}
                  onClick={() => {
                    Logout().then(() => {
                      window.location.reload();
                    });
                  }}
                >
                  <NavLink className={"NavLink"} active>
                    <div>Log Out</div>
                  </NavLink>
                </Link>
              </NavItem>
            )}
          </Nav>
          <Nav
            navbar
            className="ml-auto"
            style={userContext.isLoggedIn ? { display: "none" } : {}}
          >
            <InputGroup size="sm" block seamless>
              <Button
                pill
                theme="QuestionBlue"
                outline
                className="NavBar-Login-Button"
                onClick={() => {
                  SetCollapseOpen(false);
                  history.push({
                    pathname: "/login",
                  });
                }}
              >
                Login
              </Button>
              <Button
                pill
                theme="QuestionBlue"
                onClick={() => {
                  SetCollapseOpen(false);
                  history.push({
                    pathname: "/signup",
                  });
                }}
              >
                Sign Up
              </Button>
            </InputGroup>
          </Nav>
          <Nav
            navbar
            className="ml-auto"
            style={userContext.isLoggedIn ? {} : { display: "none" }}
          >
            <InputGroup size="sm" block seamless>
              {/*  <Button
              outline
              className="NavBar-Login-Button"
              onClick={() => {
                SetCollapseOpen(false);
                Logout().then(() => {
                  RefreshLoggedInUser(SetUserContext).then(() => {
                    history.push("/");
                  });
                });
              }}
            >
              Logout
            </Button> */}
              <div
                style={{
                  display: "flex",
                  position: "relative",
                  justifyContent: "center",
                  alignItems: "center",
                  fontSize: "2.5em",
                  marginRight: "10px",
                }}
                onClick={() => {
                  history.push("/my-notifications");
                  SetCollapseOpen(false);
                }}
              >
                <NotificationsBell />
              </div>
              <GradientBtn
                style={{
                  fontSize: "0.8em",
                  borderRadius: "30px",
                  margin: 0,
                  marginRight: "1em",
                  padding: "1em 1.8em",
                }}
                gradient={
                  !isPremiumUser
                    ? "#D31027 0%, #EA384D  51%, #D31027  100%"
                    : "#02AAB0 0%, #00CDAC  51%, #02AAB0  100%"
                }
                onClick={() => {
                  if (isPremiumUser) {
                    history.push("/my-account");
                  } else {
                    history.push("/plans");
                  }
                  SetCollapseOpen(false);
                }}
              >
                {isPremiumUser ? "Premium" : "Free Plan"}
              </GradientBtn>
              <Dropdown
                open={userDropDownOpen}
                toggle={() => {
                  SetUserDropDownOpen(!userDropDownOpen);
                }}
                className={styles.DropDownIconContainer}
              >
                <ListItemIcon>
                  <Avatar
                    style={{ backgroundColor: "#c31622", fontFamily: "Rubik" }}
                    onClick={handleClick}
                  >
                    {userContext.fullname[0]}
                  </Avatar>
                </ListItemIcon>
                <DropdownMenu className={styles.PopperContainer}>
                  {userDropDownOpen && (
                    <AccountPopper
                      user={userContext}
                      onClose={() => {
                        SetUserDropDownOpen(false);
                        SetCollapseOpen(false);
                      }}
                    />
                  )}
                </DropdownMenu>
              </Dropdown>
            </InputGroup>
          </Nav>
        </Collapse>
      </Navbar>
      <OfferBanner user={userContext} />
    </>
  );
};

const OfferBanner = ({ user }) => {
  const [showBanner, SetShowBanner] = useState(
    !user?.addonspurchased?.includes("Premium")
  );

  var offerDate = GetOfferTime()
  var formattedDate = FormatOfferDate(offerDate)

  useEffect(() => {
    SetShowBanner(!user?.addonspurchased?.includes("Premium"));
  }, [user]);

  const history = useHistory();

  return (
    <div
      className={styles.OfferBanner}
      style={{ position: "sticky", zIndex: 999 }}
      hidden={!showBanner}
    >
      <div className={styles.BannerText}>
        <marquee>
          {" "}
          Join our early MDCAT session for the Year 2023 at 50% off in only Rs.
          2000. Offer ends on {formattedDate}!
        </marquee>
      </div>
      <div className={styles.BannerAction}>
        <button
          onClick={() => {
            history.push("/plans");
          }}
        >
          Buy Now
        </button>
        <span
          onClick={() => {
            SetShowBanner(false);
          }}
        >
          X
        </span>
      </div>
    </div>
  );
};

function RefreshLoggedInUser(setUser) {
  return getLoggedInUser().then((res) => {
    setUser({
      isLoggedIn: res.data.isloggedin,
      username: res.data.username,
      fullname: res.data.fullname,
      phonenumber: res.data.phonenumber,
      city: res.data.city,
      school: res.data.school,
      accountcreateddate: res.data.accountcreateddate,
      accountstatus: res.data.accountstatus,
      subscriptionstatus: res.data.subscriptionstatus,
      subscriptionstartdate: res.data.subscriptionstartdate,
      subscriptionenddate: res.data.subscriptionenddate,
      freeuser: res.data.freeuser,
      purchasedmocks: res.data.purchasedmocks,
      addonspurchased: res.data.addonspurchased,
    });
  });
}
export default ForgotPassword;

const NotificationsBell = () => {
  const [rawnotifications, SetNotifications] = useContext(NotificationContext);
  const [userContext, SetUserContext] = useContext(UserContext);
  var Notifications = [];
  rawnotifications.forEach((notification) => {
    notification.isread = userContext.notificationsread.includes(
      notification._id
    );
    Notifications.push(notification);
  });
  const unreadnotifications = Notifications.filter((n) => !n.isread);
  const count = unreadnotifications.length;

  return (
    <>
      {" "}
      <Tooltip
        title={`You have ${count} new notifications`}
        style={{ cursor: "pointer" }}
      >
        <img
          src={NotificationsIcon}
          style={{ height: "1em", cursor: "pointer" }}
        />
      </Tooltip>
      <span className={styles.NotificationBadge} style={{ cursor: "pointer" }}>
        {count}
      </span>
    </>
  );
};
