import React from "react";
import "./Footer.css";
import { BrowserRouter as Router, Route, Switch, Link } from "react-router-dom";
import { Button } from "shards-react";

import image from "../../StaticImages/Hasnain.jpg";

import fbicon from "../../StaticImages/Footer/facebook-circle.svg";
import instaicon from "../../StaticImages/Footer/instagram-circle.svg";
import whatsappicon from "../../StaticImages/Footer/whatsapp-circle.svg";
import twittericon from "../../StaticImages/Footer/twitter-circle.svg";

import privacyPolicy from "../../StaticImages/Privacy Policy.pdf";
import TAC from "../../StaticImages/Terms and Conditions.pdf";

import styles from "./footer.module.css";

const Footer = (props) => {
  const { type } = props;

  return (
    <div className={styles.PageFooter}>
      <div
        className={
          type === "dash"
            ? styles.LargeFooterContainerDash
            : styles.LargeFooterContainer
        }
      >
        <FirstColumn />
        <SecondColumn />
        <ThirdColumn />
        <FourthColumn />
      </div>
      <div
        className={
          type === "dash"
            ? styles.MediumFooterContainerDash
            : styles.MediumFooterContainer
        }
      >
        <div>
          <FirstColumn />
        </div>
        <div>
          <ThirdColumn />
          <SecondColumn />
          <FourthColumn />
        </div>
      </div>
      <div className={styles.CopyRightInfo}>
        Copyright 2021 PreMed.PK | Designed by students from AKU and DOW
      </div>
    </div>
  );
};
export default Footer;

const FirstColumn = (props) => {
  return (
    <div className={styles.FirstCol}>
      <h3>PreMed.PK</h3>
      <Link to="/">
        <span className={styles.FooterLink}>Home</span>
      </Link>
      <Link to="/dashboard">
        <span className={styles.FooterLink}>Dashboard</span>
      </Link>
      <Link to="/yearly">
        <span className={styles.FooterLink}>Yearly</span>
      </Link>
      <Link to="/topicals">
        <span className={styles.FooterLink}>Topical</span>
      </Link>
      <Link to="/tests">
        <span className={styles.FooterLink}>Tests</span>
      </Link>
      <Link to="/our-team">
        <span className={styles.FooterLink}>Our Team</span>
      </Link>
    </div>
  );
};

const SecondColumn = (props) => {
  return (
    <div className={styles.FirstCol}>
      <h3>Support</h3>
      <Link to="/contact-us">
        <span className={styles.FooterLink}>Contact Us</span>
      </Link>
      <Link to="/contact-us#FAQ">
        <span className={styles.FooterLink}>FAQ</span>
      </Link>
    </div>
  );
};

const ThirdColumn = (props) => {
  return (
    <div className={styles.FirstCol}>
      <h3>Resources</h3>
      <a
        className={styles.FooterLink}
        href={privacyPolicy}
        without
        rel="noopener noreferrer"
        target="_blank"
      >
        Privacy Policy
      </a>
      <a
        className={styles.FooterLink}
        href={TAC}
        without
        rel="noopener noreferrer"
        target="_blank"
      >
        Terms and Conditions
      </a>
    </div>
  );
};

const FourthColumn = (props) => {
  return (
    <div className={styles.FirstCol}>
      <h3>Follow Us</h3>
      <div className={styles.SocialMediaContainer}>
      <a href="https://www.facebook.com/PreMed.PK"> <img className={styles.SocialMediaIcons} src={fbicon} /> </a>
      <a href="https://www.instagram.com/premed.pk/"> <img className={styles.SocialMediaIcons} src={instaicon} /></a>
      <a href="https://twitter.com/premedpk?s=21"> <img className={styles.SocialMediaIcons} src={twittericon} /></a>
      </div>
    </div>
  );
};
