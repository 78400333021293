import React from "react";
import styles from "./AccountPopper.module.css";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Avatar from "@material-ui/core/Avatar";
import { useLocation, useHistory } from "react-router-dom";
import { ClickAwayListener } from "@material-ui/core";

import { Logout } from "../APIManager";

export default function AccountPopper({ user, onClose }) {
  let history = useHistory();

  return (
    <div className={styles.AccountPopper}>
      <ClickAwayListener onClickAway={onClose}>
        <List
          component="nav"
          aria-labelledby="nested-list-subheader"
          /*   subheader={
          <ListSubheader component="div" id="nested-list-subheader">
            Nested List Items
          </ListSubheader>
        } */
        >
          <ListItem>
            <ListItemIcon>
              <Avatar style={{ backgroundColor: "#c31622" }}>
                {user.fullname[0]}
              </Avatar>
            </ListItemIcon>

            <ListItemText>
              <span style={{ color: "black", fontSize: "1.3em" }}>
                {user.fullname.split(" ").slice(0, 2).join(" ")}
              </span>{" "}
              <br />
              <span style={{ fontSize: "0.8em" }}>{user.username}</span>
            </ListItemText>
          </ListItem>

          <hr className={styles.Divider} />
          <ListItem
            button
            onClick={() => {
              history.push("/my-account");
              onClose();
            }}
          >
            <ListItemText>Account</ListItemText>
          </ListItem>
          <hr className={styles.Divider} />
          <ListItem
            button
            onClick={() => {
              history.push("/dashboard");
              onClose();
            }}
          >
            <ListItemText>Dashboard</ListItemText>
          </ListItem>
          <hr className={styles.Divider} />
          <ListItem button>
            <ListItemText
              onClick={() => {
                Logout().then(() => {
                  window.location.reload();
                  onClose();
                });
              }}
            >
              Logout
            </ListItemText>
          </ListItem>
        </List>
      </ClickAwayListener>
    </div>
  );
}
