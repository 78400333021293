import YoutubeEmbeded from "../../../Components/YoutubeEmbeded/YoutubeEmbeded";

export var AKUTestVlogArticle = {
  HeaderImg:
    "https://premedpk-cdn.sgp1.digitaloceanspaces.com/CDN/Blog/AKUTestVlogPic1.png",
  Tags: ["Featured", "AKU"],
  Title: "Live reactions after the AKU Exam",
  Desc: "PreMed.PK & Akmal Shahzad from AKU ‘25 bring you live reactions from students after the AKU exam in Karachi. Numerous responses were collected regarding the students experiences after the exam. We also tried to collect opinions of students about essential details such as the expected cutoffs and the date of result announcements!",
  Date: "August 16, 2022",
  AuthorName: <>Akmal Shazad</>,
  Article: (
    <>
     <p>
        <YoutubeEmbeded embedId="bgC8z4C87MQ" />
      </p>
      <p>
        On the 13th of August, 2022 the Aga Khan University conducted an
        entrance exam for its MBBS programme all across Pakistan. To find out
        the students experience and thoughts about the exam, PreMed.PK visited
        the testing centre in Karachi, Signature Banquet, to meet with students,
        offer them congratulations for getting done with this massive feat of an
        exam, and to gather their thoughts about the exam. Watch the video below
        to find out more about what the students had to say regarding the exam!
      </p>
      <h2>What is PreMed.PK</h2>
      <p>
        Premed.PK is an online question-bank startup initiated by Hasnain
        Mankani, a second year medical student at Aga Khan University and Fahd
        Shaikh, a third year medical student at Dow Medical College.
      </p>
      <p>
        In order to provide those appearing in the AKU entrance exam this year
        an opportunity to hone their preparation by practicing time management
        and questions of ranging difficulty levels, PreMed.PK launched 5 AKU
        Mocks specially designed to target weak points. We are proud to announce
        that precisely 5 questions from the mentioned AKU mocks were repeated in
        the AKU exam word for word!
      </p>
      <p>
        In addition to featuring Mock Papers for a wide range of medical
        entrance exams in Pakistan, PreMed.PK also hosts a spectacular question
        bank of 20,000 MCQs extracted from a versatile band of sources namely
        the official past papers of NMDCAT, NUMS, NTS, UHS MCAT, AKU Test, etc.
        The testing system has been peculiarly modeled following the blueprint
        of the new system of online testing by PMC to ensure that aspirants gain
        familiarity with the system during their preparation. The answer keys
        pool to our question bank has gone through numerous assessments and
        reviews to eliminate any possible error whereas the explanations to the
        questions are designed by students who themselves have benefitted from
        PreMed.PK and succeeded in clearing their entrance exams.
      </p>
      <p>
        Alongside the 20,000 questions offered on the website, PreMed.PK is
        renowned for its innovative features such as displaying Real-Time
        statistics for both Topical and Yearly Questions, an elimination tool to
        help you practice the option elimination method when attempting
        questions, an option to choose either the Tutor mode which allows you to
        attempt the paper at your own pace, or the Timed Mode to boost your time
        management skills and an option to save the questions that you find
        difficult for later!
      </p>
    </>
  ),
};
