import React, { useEffect, useState } from "react";
import styles from "./QuestionComponent.module.css";
import OptionButton from "./OptionButton";
import { FormCheckbox } from "shards-react";
import MatButton from "@material-ui/core/Button";

/**
 *
 * @param {{options: [{color : string , isSelected : boolean, optionLetter : string, optionText : string}] onClick: Function}} props
 *
 */
const QuizOptionButton = ({
  options,
  onClick,
  eliminationToolEnabled,
  toggleEliminationTool,
  showEliminate,
  questionID,
}) => {
  const [hideEliminated, SetHideEliminated] = useState(false);

  return (
    <>
      <div className={styles.EliminateHeading} hidden={!showEliminate}>
        <label>Elimination Tool:</label>
        <span>
          <FormCheckbox
            toggle
            checked={eliminationToolEnabled}
            onChange={() => {
              toggleEliminationTool();
            }}
          />
        </span>
        <MatButton
          color="primary"
          onClick={(e) => {
            SetHideEliminated(!hideEliminated);
          }}
          hidden={!eliminationToolEnabled}
          style={{ marginLeft: 0 }}
        >
          {hideEliminated ? "Show" : "Hide"} eliminated options
        </MatButton>
      </div>
      <div className={styles.OptionsContainer}>
        {options?.map((option) => (
          <OptionButton
            color={option.color}
            isSelected={option.isSelected}
            optionLetter={option.optionLetter}
            optionText={option.optionText}
            showEliminate={eliminationToolEnabled}
            hideEliminated={hideEliminated}
            onClick={(e) => {
              onClick(e);
            }}
            questionID={questionID}
          />
        ))}
      </div>
    </>
  );
};

export default QuizOptionButton;
