import { QueueSharp, TrafficRounded } from "@material-ui/icons";
import axios from "axios";

const DEVLOPMENT_MODE = false;
var rootPath = "";
if (DEVLOPMENT_MODE === true) {
  //rootPath = "http://192.168.100.8:4002";
  // rootPath = "http://192.168.205.245:4002";
  rootPath = "http://127.0.0.1:4002";
} else {
  rootPath = "https://testapi.premed.pk";
  rootPath = "https://api.premed.pk";
}

//const path = "http://localhost:4000";
//const path = "192.168.100.5:4000";

const config = {
  onUploadProgress: function (progressEvent) {
    var percentCompleted = Math.round(
      (progressEvent.loaded * 100) / progressEvent.total
    );
    console.log(percentCompleted);
    console.log(percentCompleted);
  },
};

export function getLoggedInUser() {
  var data;
  data = axios({
    method: "GET",
    withCredentials: true,
    url: rootPath + "/LoggedInUser",
  });
  return data;
}

export function Signup(props) {
  // console.log(props);
  var data = axios({
    method: "POST",
    data: {
      fullname: props.fullname,
      username: props.username,
      phonenumber: props.phonenumber,
      city: props.city,
      school: props.school,
      password: props.password,
      referal: props.referal,
    },
    withCredentials: true,
    url: rootPath + "/signup",
  });
  return data;
}
export function UpdateAccountInfo(props) {
  var data = axios({
    method: "POST",
    data: {
      fullname: props.fullname,
      phonenumber: props.phonenumber,
      city: props.city,
      school: props.school,
    },
    withCredentials: true,
    url: rootPath + "/UpdateAccountInfo",
  });
  return data;
}

export function UpdateAccountPassword(oldpassword, newpassword) {
  console.log("Password");
  var data = axios({
    method: "POST",
    data: {
      oldpassword: oldpassword,
      newpassword: newpassword,
    },
    withCredentials: true,
    url: rootPath + "/UpdateAccountPassword",
  });
  return data;
}

export function Login(props) {
  var data = axios({
    method: "POST",
    data: {
      username: props.username,
      password: props.password,
    },
    withCredentials: true,
    url: rootPath + "/login",
  });
  return data;
}
export function SubmitQuestion(deck, question) {
  var data;
  data = axios({
    method: "POST",
    withCredentials: true,
    url: rootPath + "/AddNewQuestion",
    data: { deckName: deck, question: question },
  });
  return data;
}
export function SubmitNewDeck(deck) {
  var data;
  data = axios({
    method: "POST",
    withCredentials: true,
    url: rootPath + "/AddNewDeck",
    data: {
      deckName: deck.deckName,
      deckDescription: deck.deckDescription,
      deckImage: deck.deckImage,
    },
  });
  return data;
}
export function GetDeckNames() {
  var data;
  data = axios({
    method: "GET",
    withCredentials: true,
    url: rootPath + "/DeckNames",
  });
  return data;
}
export function GetQuestions(deckName) {
  var data;
  data = axios({
    method: "GET",
    withCredentials: true,
    url: rootPath + "/GetQuestions?deck=" + deckName,
  });
  return data;
}
export function GetDeckID(deckName) {
  var data;
  data = axios({
    method: "GET",
    withCredentials: true,
    url: rootPath + "/GetDeckID?deck=" + deckName,
  });
  return data;
}

export function GetQuestion(deckName, questionID) {
  var data;
  data = axios({
    method: "GET",
    withCredentials: true,
    url: rootPath + "/GetQuestion?deck=" + deckName + "&question=" + questionID,
    onDownloadProgress: function (progressEvent) {
      // console.log("this is the progress");
    },
  });
  return data;
}

export function LogQuestionAttempt({
  DeckName,
  QuestionID,
  Type,
  OptionChoice,
  Correct,
  TimeTaken,
  LastAttemptType,
  QuestionNo,
}) {
  var data;
  data = axios({
    method: "POST",
    withCredentials: true,
    url: rootPath + "/attempt/log",
    data: {
      DeckName: DeckName,
      QuestionID: QuestionID,
      Type: Type,
      OptionChoice: OptionChoice,
      Correct: Correct,
      TimeTaken: TimeTaken,
      LastAttemptType: LastAttemptType,
      QuestionNo: QuestionNo,
    },
  });
  return data;
}

export function LogReport(props) {
  const { DeckName, QuestionID, Complaint, Tags } = props;
  var data;
  data = axios({
    method: "POST",
    withCredentials: true,
    url: rootPath + "/reports/log",
    data: {
      DeckName: DeckName,
      QuestionID: QuestionID,
      Complaint: Complaint,
      Tags: Tags,
    },
  });
  return data;
}

export function GetLastQuestion(deckName) {
  var data;
  data = axios({
    method: "GET",
    withCredentials: true,
    url: rootPath + "/attempts/lastattempt?deck=" + deckName,
  });
  return data;
}

export function GetRecentActivities() {
  var data;
  data = axios({
    method: "GET",
    withCredentials: true,
    url: rootPath + "/activity",
  });
  return data;
}

export function GetSavedQuestions() {
  var data;
  data = axios({
    method: "GET",
    withCredentials: true,
    url: rootPath + "/bookmarks",
  });
  return data;
}

export function GetIsQuestionSaved(deckName, questionID) {
  var data;
  data = axios({
    method: "GET",
    withCredentials: true,
    url: rootPath + "/isquestionbookmarked/" + deckName + "/" + questionID,
  });
  return data;
}

export function DeleteBookmark(deckName, questionID) {
  var data;
  data = axios({
    method: "DELETE",
    withCredentials: true,
    url: rootPath + "/deletebookmark/" + deckName + "/" + questionID,
  });
  return data;
}

export function LogSaveQuestion(DeckName, QuestionID, Type) {
  var data;
  data = axios({
    method: "POST",
    withCredentials: true,
    url: rootPath + "/bookmarks/create",
    data: {
      DeckName: DeckName,
      QuestionID: QuestionID,
      Type: Type,
    },
  });
  return data;
}

export function GetTags() {
  var data;
  data = axios({
    method: "GET",
    withCredentials: true,
    url: rootPath + "/Tags",
  });
  return data;
}

export function GetTotalQuestions() {
  var data;
  data = axios({
    method: "GET",
    withCredentials: true,
    url: rootPath + "/TotalQuestions",
  });
  return data;
}

export function GetUniqueQuestionsAttempted() {
  var data;
  data = axios({
    method: "GET",
    withCredentials: true,
    url: rootPath + "/attempts/uniqueattempts",
  });
  return data;
}

export function GetTotalQuestionsAttempted() {
  var data;
  data = axios({
    method: "GET",
    withCredentials: true,
    url: rootPath + "/attempts/totalattempts",
  });
  return data;
}

export function GetCorrectQuestionsAttempted() {
  var data;
  data = axios({
    method: "GET",
    withCredentials: true,
    url: rootPath + "/attempts/correctattempts",
  });
  return data;
}

export function GetStatsBySubject(subject) {
  var data;
  data = axios({
    method: "GET",
    withCredentials: true,
    url: rootPath + "/attempts/stats/" + subject,
  });
  return data;
}

export function GetAverageTimeTaken(subject) {
  var data;
  data = axios({
    method: "GET",
    withCredentials: true,
    url: rootPath + "/attempts/averagetimetaken",
  });
  return data;
}

export function GetAttemptCalendar() {
  var data;
  data = axios({
    method: "GET",
    withCredentials: true,
    url: rootPath + "/attempts/calendar",
  });
  return data;
}

export function GetTopicalDeckID(deckName) {
  var data;
  data = axios({
    method: "GET",
    withCredentials: true,
    url: rootPath + "/GetTopicalDeckID?deck=" + deckName,
  });
  return data;
}

export function GetTopicalQuestions(deckName) {
  var data;
  data = axios({
    method: "GET",
    withCredentials: true,
    url: rootPath + "/GetTopicalQuestions?deck=" + deckName,
  });
  return data;
}

export function GetTopicalPastPaperQuestions(deckName) {
  var data;
  data = axios({
    method: "GET",
    withCredentials: true,
    url: rootPath + "/GetTopicalPastPaperQuestions?deck=" + deckName,
  });
  return data;
}

export function GetTopicalQuestion(deckName, questionID) {
  var data;
  data = axios({
    method: "GET",
    withCredentials: true,
    url:
      rootPath +
      "/GetTopicalQuestion?deck=" +
      deckName +
      "&question=" +
      questionID,
  });
  return data;
}

export function ForgotPasswordRequest(props) {
  var data = axios({
    method: "POST",
    data: {
      username: props.username,
    },
    withCredentials: true,
    url: rootPath + "/Forgot-Password",
  });
  return data;
}

export function VerifyPasswordResetToken(userid, token) {
  var data = axios({
    method: "GET",
    withCredentials: true,
    url: rootPath + `/VerifyPasswordResetToken/${userid}/${token}`,
  });
  return data;
}

export function ResetPassword(userid, token, password) {
  var data = axios({
    method: "POST",
    data: {
      userid: userid,
      token: token,
      password: password,
    },
    withCredentials: true,
    url: rootPath + "/reset-password",
  });
  return data;
}

export function GetYearlyDecks() {
  var data;
  data = axios({
    method: "GET",
    withCredentials: true,
    url: rootPath + "/GetPublishedYearlyDecks",
  });
  return data;
}
export function GetTopicalDecks() {
  var data;
  data = axios({
    method: "GET",
    withCredentials: true,
    url: rootPath + "/GetPublishedTopicalDecks",
  });
  return data;
}

export function Logout() {
  var data;
  data = axios({
    method: "GET",
    withCredentials: true,
    url: rootPath + "/logout",
  });
  return data;
}

export function GetTotalTimePracticing() {
  var data;
  data = axios({
    method: "GET",
    withCredentials: true,
    url: rootPath + "/attempts/totaltimetaken",
  });
  return data;
}

export function GetQuestionOfTheDay() {
  var data;
  data = axios({
    method: "GET",
    withCredentials: true,
    url: rootPath + "/questionoftheday",
  });
  return data;
}

export function GetDeckDescription(DeckName) {
  var data;
  data = axios({
    method: "GET",
    withCredentials: true,
    url: rootPath + `/GetDeckDescription/${DeckName}`,
  });
  return data;
}
export function LogTestAttempt(
  DeckName,
  DeckID,
  TotalQuestions,
  Correct,
  Skipped,
  Wrong,
  TestPaper,
  TimeTaken
) {
  var data;
  data = axios({
    method: "POST",
    withCredentials: true,
    url: rootPath + "/logtestattempt",
    data: {
      DeckName: DeckName,
      DeckID: DeckID,
      TotalQuestions: TotalQuestions,
      Correct: Correct,
      Skipped: Skipped,
      Wrong: Wrong,
      TestPaper: TestPaper,
      TimeTaken: TimeTaken,
    },
  });
  return data;
}

export function GetMockQuestions(deckName) {
  var data;
  data = axios({
    method: "GET",
    withCredentials: true,
    url: rootPath + "/GetMockQuestions?deck=" + deckName,
  });
  return data;
}
export function GetMockDeckID(deckName) {
  var data;
  data = axios({
    method: "GET",
    withCredentials: true,
    url: rootPath + "/GetMockDeckID?deck=" + deckName,
  });
  return data;
}

export function GetMockQuestion(deckName, questionID) {
  var data;
  data = axios({
    method: "GET",
    withCredentials: true,
    url:
      rootPath +
      "/GetMockQuestion?deck=" +
      deckName +
      "&question=" +
      questionID,
  });
  return data;
}

export function PurchaseMocks(couponcode) {
  var data = axios({
    method: "GET",
    withCredentials: true,
    url: rootPath + `/PurchaseMocks/${couponcode}`,
  });
  return data;
}

export function GetTestPaper(deckName) {
  var data = axios({
    method: "GET",
    withCredentials: true,
    url: rootPath + `/completetestpaper/${deckName}`,
  });
  return data;
}

export function VerifyCouponCode(couponcode) {
  var data = axios({
    method: "GET",
    withCredentials: true,
    url: rootPath + `/VerifyCouponCode/${couponcode}`,
  });
  return data;
}

export function PurchaseAddon(SKU, DiscountCode, ProofOfPayment, Referal) {
  var data = axios({
    method: "POST",
    withCredentials: true,
    url: rootPath + `/PurchaseAddon`,
    data: {
      SKU: SKU,
      DiscountCode: DiscountCode,
      ProofOfPayment: ProofOfPayment,
      Referal: Referal,
    },
  });
  return data;
}

export function LogRating(QuestionID, Rating) {
  var data;
  data = axios({
    method: "POST",
    withCredentials: true,
    url: rootPath + "/rating/log",
    data: {
      QuestionID: QuestionID,
      Rating: Rating,
    },
  });
  return data;
}

export function LogDifficulty(QuestionID, Difficulty) {
  var data;
  data = axios({
    method: "POST",
    withCredentials: true,
    url: rootPath + "/Difficulty/log",
    data: {
      QuestionID: QuestionID,
      Difficulty: Difficulty,
    },
  });
  return data;
}

export function GetBookmarkQuestions() {
  var data;
  data = axios({
    method: "GET",
    withCredentials: true,
    url: rootPath + "/getbookmarkquestions",
  });
  return data;
}

export function GetMockPaper(mockName) {
  var data = axios({
    method: "GET",
    withCredentials: true,
    url: rootPath + `/getcompletemockpaper/${mockName}`,
  });
  return data;
}

export function LogMilestone(milestone) {
  var data;
  data = axios({
    method: "POST",
    withCredentials: true,
    url: rootPath + "/SetMilestone",
    data: {
      milestone: milestone,
    },
  });
  return data;
}

export function GetMockAttemptTestPaper(mockName) {
  var data = axios({
    method: "GET",
    withCredentials: true,
    url: rootPath + `/GetMockAttemptPaper/${mockName}`,
  });
  return data;
}

export function GetNotifications() {
  var data = axios({
    method: "GET",
    withCredentials: true,
    url: rootPath + `/GetNotifications`,
  });
  return data;
}

export function MarkNotificationsAsRead(Notifications) {
  var data;
  data = axios({
    method: "POST",
    withCredentials: true,
    url: rootPath + "/MarkNotificationsAsRead",
    data: {
      Notifications: Notifications,
    },
  });
  return data;
}

export function UpdateGoals(Goals) {
  var data;
  data = axios({
    method: "POST",
    withCredentials: true,
    url: rootPath + "/UpdateGoals",
    data: {
      Goals: Goals,
    },
  });
  return data;
}
export function GetTopicalStats() {
  var data = axios({
    method: "GET",
    withCredentials: true,
    url: rootPath + `/attempts/topicalstats/`,
  });
  return data;
}

export function PostPaymentRequest(couponCode, couponID) {
  var data;
  data = axios({
    method: "POST",
    withCredentials: true,
    url: rootPath + "/PaymentReimbursementRequest",
    data: {
      couponCode: couponCode,
      couponID: couponID,
    },
  });
  return data;
}

export function FrontEndUpdateQuestion({
  deckName,
  questionID,
  updatedTags,
  updatedExplanation,
}) {
  var data;
  data = axios({
    method: "POST",
    withCredentials: true,
    url: rootPath + "/FrontEndUpdateQuestion",
    data: {
      deckName,
      questionID,
      updatedTags,
      updatedExplanation,
    },
  });
  return data;
}


export function GetQuestionRealDeck(questionID) {
  var data = axios({
    method: "GET",
    withCredentials: true,
    url: rootPath + `/GetQuestionRealDeck/${questionID}`,
  });
  return data;
}


