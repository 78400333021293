import React, { useState, createContext } from "react";

export const UserContext = createContext();

export const UserProvider = (props) => {
  const [user, setUser] = useState({
    isLoggedIn: false,
    username: "",
    fullname: "",
    phonenumber: "",
    city: "",
    school: "",
    accountcreateddate: "",
    accountstatus: "",
    subscriptionstatus: "",
    subscriptionstartdate: "",
    subscriptionenddate: "",
    freeuser: "",
    purchasedmocks: "",
    addonspurchased: [],
    milestones: [],
    notificationsread: [],
    otherinfo: {},
    isadminuser: false,
    admin: {},
  });

  return (
    <UserContext.Provider value={[user, setUser]}>
      {props.children}
    </UserContext.Provider>
  );
};
