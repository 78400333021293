import React, { useState, useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";

import GradientBtn from "../GradientBtn/GradientBtn";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function SkipQuestionComponent({
  OnCloseCB,
  OnSkipCB,
  numberOfQuestions,
  currentQuestion,
  isOpen,
}) {
  const [open, setOpen] = React.useState(isOpen);

  const [error, SetError] = React.useState(false);
  const [skipNo, SetSkipNo] = React.useState(1);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    OnCloseCB();
  };
  const HandleOnChange = (e) => {
    SetSkipNo(e);
  };

  useEffect(() => {
    if (isNaN(skipNo) || skipNo === "") {
      SetError(true);
      return;
    } else {
      if (parseInt(skipNo) < 1 || parseInt(skipNo) > numberOfQuestions)
        SetError(true);
      else {
        SetError(false);
      }
    }
  }, [skipNo]);

  function HandleKeyPress(e) {
    if (e.keyCode == 13) {
      if (!error) {
        OnSubmit();
        return false;
      }
      // put the login here
    }
  }

  return (
    <div>
      <Dialog
        scroll="body"
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle
          id="alert-dialog-slide-title"
          style={{ textAlign: "center" }}
        >
          {"Skip to Question "}
          {skipNo}
        </DialogTitle>
        <DialogContent>
          <TextField
            style={{ minWidth: "17em", minHeight: "4em" }}
            value={skipNo}
            variant="outlined"
            onChange={(e) => {
              HandleOnChange(e.target.value);
            }}
            onKeyDown={HandleKeyPress}
            error={error}
            autoFocus
            helperText={
              error
                ? "Please enter a valid Number between 1 and " + numberOfQuestions
                : ""
            }
            margin="dense"
            id="Complaint"
            type="number"
            inputProps={{ type: "number" }}
            fullWidth
            label="Question No"
            inputProps={{ maxLength: 3 }}
            InputLabelProps={{
              style: { backgroundColor: "white" },
            }}
          />

          <div
            className="ButtonDiv"
            style={{
              marginTop: "1em",
              marginBottom: ".4em",
              display: "flex",
              alignContent: "center",
              alignItems: "center",
              flexDirection: "column cc",
            }}
          >
            <GradientBtn
              style={{ padding: "0.8em 2em", borderRadius: "30px" }}
              gradient={"#77A1D3 0%, #79CBCA  51%, #77A1D3  100%"}
              disabled={error}
              onClick={() => {
                OnSubmit();
              }}
            >
              Submit
            </GradientBtn>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );

  function OnSubmit() {
    if (skipNo == currentQuestion) {
      OnCloseCB();
    } else {
      OnSkipCB(skipNo);
    }
  }
}
