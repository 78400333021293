import React from "react";
import styles from "./QuestionComponent.module.css";

const NavButton = ({
  questionNo,
  isBackEnabled,
  isNextEnabled,
  onBackClick,
  onNextClick,
}) => {
  const getNextButtonClass = () => {
    if (isNextEnabled) {
      return "page-item";
    } else {
      return "page-item disabled";
    }
  };
  const getPreviousButtonClass = () => {
    if (isBackEnabled) {
      return "page-item";
    } else {
      return "page-item disabled";
    }
  };
  const NextButtonClass = getNextButtonClass();
  const PreviousButtonClass = getPreviousButtonClass();
  return (
    <div className={styles.QuestionNav}>
      <nav className={styles.PageNav}>
        <ul
          class="pagination justify-content-center"
          style={{ fontSize: "1.3rem" }}
        >
          <li
            class={PreviousButtonClass}
            onClick={(target) => {
              if (isBackEnabled) {
                onBackClick();
              }
            }}
          >
            {" "}
            <div
              class="page-link"
              style={
                isBackEnabled ? { cursor: "pointer", color: "##639AF6" } : {}
              }
            >
              Back
            </div>
          </li>
          <li
            class="page-item"
            backgroundColor="##639AF6"
            style={{ color: "##639AF6" }}
          >
            <a style={{ backgroundColor: "#639AF6" }} class="page-link">
              {questionNo}
            </a>
          </li>

          <li
            class={NextButtonClass}
            onClick={() => {
              if (isNextEnabled) {
                onNextClick();
              }
            }}
          >
            <div
              class="page-link"
              style={
                isNextEnabled ? { cursor: "pointer", color: "##639AF6" } : {}
              }
            >
              Next
            </div>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default NavButton;
