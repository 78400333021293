export function VerifyMockAddon(mockName, addonspurchased) {
  console.log(addonspurchased);

  if (mockName == "AKU Mock Paper 1") {
    return true;
  }
  if (
    addonspurchased.includes("AKU Mock Bundle 1") ||
    addonspurchased.includes("AKU Mock Bundle 4")
  ) {
    return true;
  }
  if (addonspurchased.includes("AKU Mock Bundle 2")) {
    if (mockName == "AKU Mock Paper 2" || mockName == "AKU Mock Paper 3") {
      return true;
    }
  }
  if (addonspurchased.includes("AKU Mock Bundle 3")) {
    if (mockName == "AKU Mock Paper 4" || mockName == "AKU Mock Paper 5") {
      return true;
    }
  }
  if (mockName == "NUMS Mock Paper 1") {
    return true;
  }
  if(mockName.includes("NUMS")){
    if(addonspurchased.includes("NUMS Mock Bundle 1") || addonspurchased.includes("NUMS Mock Bundle 2")){
      return true
    }
  }


  return false;
}
