import React from "react";
import SavedIcon from "@material-ui/icons/Flag";
import NotSavedIcon from "@material-ui/icons/FlagOutlined";
import IconButton from "@material-ui/core/IconButton";
import MatButton from "@material-ui/core/Button";
import styles from "./QuestionComponent.module.css";
import SvgIcon from "@material-ui/core/SvgIcon";
//import FlagIcon from "../../StaticImages/flag-rounded.svg"
import { ReactComponent as FlagIcon } from "../../StaticImages/flag-rounded.svg";
import { ReactComponent as FlagIconSolid } from "../../StaticImages/flag-rounded-solid.svg";
const FlagButton = ({ isHidden, isFlagged, onClick }) => {
  if (isHidden) {
    return <></>;
  }

  return (
    <div className={styles.BookMarkContainer}>
      <IconButton size="large" onClick={onClick}>
        {isFlagged ? (
          <SvgIcon style={{ height: "18px" }}>
            <FlagIconSolid />
          </SvgIcon>
        ) : (
          <SvgIcon style={{ height: "18px" }}>
            <FlagIcon />
          </SvgIcon>
        )}
      </IconButton>
      <MatButton
        color="secondary"
        onClick={onClick}
        style={{ fontFamily: "Rubik" }}
      >
        {isFlagged ? "Un-Flag" : "Flag"}
      </MatButton>
    </div>
  );
};

export default FlagButton;
