import React, { useState, useContext, useEffect } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Route, Switch, Link } from "react-router-dom";
import Skeleton from "@material-ui/lab/Skeleton";
import axios from "axios";
import TagList from "../../../../Components/TagList/TagList";

import { useMediaQuery } from "@material-ui/core";

import TitleIcon from "../../../../StaticImages/recentactivityicon.svg";

import GradientBtn from "../../../../Components/GradientBtn/GradientBtn.jsx";

import {
  Card,
  CardHeader,
  CardTitle,
  CardImg,
  CardBody,
  CardFooter,
  Button,
  Fade,
  Badge,
} from "shards-react";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import ReplayIcon from "@material-ui/icons/Replay";
import { useLocation, useHistory } from "react-router-dom";

import { GetRecentActivities } from "../../../../APIManager";
import styles from "./RecentActivity.module.css";

import { Progress } from "shards-react";
import { deviceType } from "react-device-detect";

const RecentActivity = () => {
  const DeviceQuery500 = useMediaQuery("(max-width:500px)");
  const [activities, SetActivities] = useState([]);
  const [fetched, SetFetched] = useState(false);

  useEffect(() => {
    GetRecentActivities().then((res) => {
      SetActivities(res.data);
      SetFetched(true);
    });
  }, []);

  return (
    <div className={styles.RecentActivityMainBox}>
      <div className={styles.PageHeader}>
        <div>
          {" "}
          <img src={TitleIcon} />
        </div>

        <div>
          <h1>My Recent Activity</h1>
          <h2>Get a summary of your latest activity!</h2>
        </div>
      </div>
      <div className={styles.RecentActivity_MainFlex}>
        {fetched ? (
          activities.length > 0 ? (
            RenderActivities(activities, DeviceQuery500)
          ) : (
            <NoActivity />
          )
        ) : (
          RenderSkeleton()
        )}
      </div>
    </div>
  );
};

export default RecentActivity;

const NoActivity = (props) => {
  return (
    <div className={styles.NoActivity}>
      You have no Recent Activity <br />
      <span>
        Check out our <Link to="/yearly">Yearly</Link> or{" "}
        <Link to="/topicals">Topical</Link> papers to get started{" "}
      </span>
    </div>
  );
};

const RenderActivities = (rawData, isMobileDevice) => {
  let history = useHistory();

  rawData.sort(function (a, b) {
    return a.date > b.date ? -1 : a.date > b.date ? 1 : 0;
  });
  //console.log(rawData);
  var d = new Date("2021-02-16T21:35:38.236Z");
  return (
    <div>
      {rawData.map((a) => (
        <div className={styles.ActivityMainBox}>
          <div className={styles.ActivityHeading}>
            {a.DeckName} {GetBadge(a.type)}
          </div>
          <div className={styles.ActivityDate}>
            Date Last Attempted: {GetFormattedTime(a.date)}
          </div>
          <div className={styles.ActivityProgress}>
            <span style={{ width: "80%" }}>
              <Progress
                className={styles.ProgressBar}
                theme="primary"
                value={(a.number * 100) / a.total}
              />
            </span>
            <span className={styles.ActivityPercent}>
              {`${Math.round((a.number * 100) / a.total)}%`}
            </span>
          </div>
          <div>
            {a.number !== a.total ? (
              <span style={{ marginRight: ".2em" }}>
                {" "}
                <GradientBtn
                  style={{
                    fontSize: "0.8em",
                    borderRadius: "30px",
                    margin: 0,
                    padding: "0.6em 1.8em",
                    marginRight: "0.5em",
                  }}
                  gradient={"#639AF6 9.34%, #98BDF9 91.82%"}
                  onClick={() => {
                    if (a.type === "Topical") {
                      if (a.TopicalType === "PracticeQuestion") {
                        history.push(
                          `/deck/topical/attempt?deck=${a.DeckName}&question=${a.number}`
                        );
                      } else {
                        history.push(
                          `/deck/topical/pastpaper/attempt?deck=${a.DeckName}&question=${a.number}`
                        );
                      }
                    } else {
                      history.push(
                        `/deck/attempt?deck=${a.DeckName}&question=${a.number}`
                      );
                    }
                  }}
                >
                  <PlayArrowIcon />
                  Resume {!isMobileDevice ? `from Question ${a.number}` : ""}
                </GradientBtn>
              </span>
            ) : (
              <></>
            )}
            <span style={{ marginRight: ".2em" }}>
              {" "}
              <GradientBtn
                style={{
                  fontSize: "0.8em",
                  borderRadius: "30px",
                  margin: 0,
                  padding: "0.6em 1.8em",
                }}
                gradient={"#abe9cd 0%, #3eadcf 74%"}
                onClick={() => {
                  if (a.type === "Topical") {
                    history.push(`/deck/topical/attempt?deck=${a.DeckName}`);
                  } else {
                    history.push(`/deck/attempt?deck=${a.DeckName}`);
                  }
                }}
              >
                <ReplayIcon />
                Start Over
              </GradientBtn>
            </span>
          </div>
        </div>
      ))}
    </div>
  );
};

const RenderSkeleton = (props) => {
  var rawData = [1, 2, 3, 4];
  return (
    <div>
      {rawData.map((a) => (
        <div className={styles.ActivityMainBox}>
          <div className={styles.ActivityHeading}>
            <Skeleton />
          </div>
          <div>
            <Skeleton />
          </div>
          <div className={styles.ActivityProgress}>
            <span style={{ width: "80%" }}>
              <Skeleton />
            </span>
          </div>
        </div>
      ))}
    </div>
  );
};

function GetDate(date) {
  var d = new Date(date);
  return d.toLocaleTimeString() + " " + d.toLocaleDateString();
}

function GetBadge(type) {
  if (type === "Topical") {
    return <Badge theme="info">Topical</Badge>;
  } else {
    return <Badge theme="success">Yearly</Badge>;
  }
}
function GetFormattedTime(date) {
  var d = new Date(date);
  var months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  var monthName = months[d.getMonth()];
  return `${monthName} ${date[8]}${date[9]} ${d.getFullYear()}`;
}
