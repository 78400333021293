import React from "react";

import styles from "./OurTeam.module.css";

export default function TeamColumnMobile({ persons }) {
  console.log(persons)
  return (
    <div>
      {persons.map((person) => {
        return (
          <>
            <div class={`${styles.teamPicContainer} p-4 pb-0 row`}>
              <TeamPhotoBox Img={person.Image} />
            </div>
            <div class={`${styles.nameRow} p-4 pb-0 row`}>
              <TeamNameBox Name={person.Name} Title={person.Role} />
            </div>
          </>
        );
      })}
    </div>
  );
}

const TeamPhotoBox = ({ Img }) => {
  return (
    <>
      <div class="col">
        <img class={`${styles.teamPic}`} src={Img} alt="" />
      </div>
    </>
  );
};

const TeamNameBox = ({ Name, Title }) => {
  return (
    <div class="col">
      <h1 class={`mt-3 ${styles.teamName}`}>{Name}</h1>
      <h3 class={`${styles.teamTitle}`}>{Title}</h3>
      {/*  <button
          type="button"
          class={`${styles.teamButton} py-2 px-4`}
          data-bs-toggle="modal"
          data-bs-target="#exampleModal"
        >
          Read More
        </button> */}
    </div>
  );
};
