import React, { useState, useEffect, useContext } from "react";
import styles from "./DifficultyRating.module.css";

import SadEmoji from "../../../StaticImages/sademoji.png";
import SmileEmoji from "../../../StaticImages/smileemoji.png";
import ConfusedEmoji from "../../../StaticImages/confusedemoji.png";

import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import { Fade } from "shards-react";
import { LogDifficulty } from "../../../APIManager";

import { UserContext } from "../../../userContext";
import NotLoggedInToggle from "../NotLoggedInToggle";

export default function DifficultyRating({ questionID }) {
  const [difficulty, SetDifficulty] = useState(); //3);
  const [showSnackBar, SetShowSnackBar] = useState(false);
  const [lock, SetLock] = useState(false);

  const [user, SetUser] = useContext(UserContext);

  const [notLoggedInModalOpen, SetNotLoggedInModalOpen] = useState(false);

  function onChange(newValue) {
    if (!user.isLoggedIn) {
      SetNotLoggedInModalOpen(true);
      return;
    }
    if (lock) {
      return;
    }
    SetDifficulty(newValue);
    SetLock(true);
    SetShowSnackBar(true);
    LogDifficulty(questionID, newValue);
  }

  return (
    <Fade in={true}>
      <NotLoggedInToggle
        open={notLoggedInModalOpen}
        onClose={() => {
          SetNotLoggedInModalOpen(false);
        }}
      />
      <div className={styles.MainContainer}>
        <Snackbar
          open={showSnackBar}
          autoHideDuration={6000}
          onClose={() => {
            SetShowSnackBar(false);
          }}
        >
          <Alert
            onClose={() => {
              SetShowSnackBar(false);
            }}
            severity="success"
          >
            Thank You for your feedback!
          </Alert>
        </Snackbar>
        <h3>Difficulty Rating</h3>
        <div className={styles.BoxContainer}>
          <div
            className={styles.GradientBox1}
            onClick={() => {
              onChange(1);
            }}
          >
            <div className={difficulty == 1 ? styles.SelectedBox : null}>
              <img src={SmileEmoji} />
              <div className={styles.Text1}>Easy</div>
            </div>
          </div>
          <div
            className={styles.GradientBox2}
            onClick={() => {
              onChange(2);
            }}
          >
            <div className={difficulty == 2 ? styles.SelectedBox : null}>
              <img src={ConfusedEmoji} />
              <div className={styles.Text2}>Medium</div>
            </div>
          </div>
          <div
            className={styles.GradientBox3}
            onClick={() => {
              onChange(3);
            }}
          >
            <div className={difficulty == 3 ? styles.SelectedBox : null}>
              <img src={SadEmoji} />
              <div className={styles.Text3}>Difficult</div>
            </div>
          </div>
        </div>
      </div>
    </Fade>
  );
}
