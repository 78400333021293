import React from "react";
import "./BlogArticle.scss";

import { AcingAkuTestArticle } from "./Articles/LifeAtAku";
import { PreMedStoryArticle } from "./Articles/PreMedStory";
import { MBBSvsBDSArticle } from "./Articles/MBBSvsBDS";
import { DomicileArticle } from "./Articles/Domicile";
import { AKUTestArticle } from "./Articles/AKUTest";
import { AKUInterviewArticle } from "./Articles/AKUInterview";
import { InternationalStudentsArticle } from "./Articles/InternationalStudents";
import { AKUTestVlogArticle } from "./Articles/AKUTestVlog";
import { useParams, useHistory } from "react-router-dom";
import { useEffect } from "react";
import { ReapplyingtoAKUArticle } from "./Articles/ReapplyingtoAKU";
import { AdmissionProcedureArticle } from "./Articles/AdmissionProcedure";

export default function BlogArticle() {
  let { blogID } = useParams();
  let history = useHistory();
  var blogContent = {};
  switch (blogID) {
    case "1":
      blogContent = PreMedStoryArticle;
      break;
    case "2":
      blogContent = AcingAkuTestArticle;
      break;
    case "3":
      blogContent = MBBSvsBDSArticle;
      break;
    case "4":
      blogContent = DomicileArticle;
      break;
    case "5":
      blogContent = AKUTestArticle;
      break;
    case "6":
      blogContent = AKUInterviewArticle;
      break;
    case "7":
      blogContent = InternationalStudentsArticle;
      break;
    case "8":
      blogContent = AKUTestVlogArticle;
      break;
    case "9":
      blogContent = ReapplyingtoAKUArticle;
      break;
      case "10":
        blogContent = AdmissionProcedureArticle;
        break;
    default:
      break;
  }
  function PreviousClicked() {
    if (blogID !== "1") {
      history.push(`/blog/${(parseInt(blogID) - 1).toString()}`);
    }
  }
  function NextClicked() {
    if (blogID !== "10") {
      history.push(`/blog/${(parseInt(blogID) + 1).toString()}`);
    }
  }

  useEffect(() => {
    window.location.hash = blogContent?.Title?.replace(
      /\s+/g,
      "-"
    ).toLowerCase();
  }, [blogID]);

  return (
    <div className="BlogArticleScope">
      <section class="section">
        <div class="container">
          <div class="columns">
            <div class="column">
              <figure class="image">
                <img
                  src={blogContent.HeaderImg}
                  alt="Placeholder Image"
                  class="article-hero-img"
                />
              </figure>
            </div>
            <div class="column">
              <div class="level is-mobile">
                <div class="level-left">
                  {blogContent.Tags.map((tag) => (
                    <div class="level-item">
                      <span class="tag is-link is-light is-medium">{tag}</span>
                    </div>
                  ))}
                </div>
              </div>

              <h1 class="title is-size-1">{blogContent.Title}</h1>

              <div class="level is-mobile">
                <div class="level-left">
                  <div class="level-item">
                    <span class="subtitle is-6 has-text-grey">
                      {blogContent.Date}
                    </span>
                  </div>
                  <div class="level-item">
                    <span class="subtitle is-6 has-text-grey">&bull;</span>
                  </div>
                  <div class="level-item">
                    <span class="subtitle is-6 has-text-grey">10 min read</span>
                  </div>
                </div>
              </div>

              <div class="level is-mobile">
                <div class="level-left">
                  {/*      <div class="level-item">
                    <figure class="image is-64x64">
                      <img src="./images/Umar.jpg" alt="" class="is-rounded" />
                    </figure>
                  </div> */}
                  <div class="level-item">
                    <span class="subtitle is-6">{blogContent.AuthorName}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="section">
        <div class="container">
          <div class="content">{blogContent.Article}</div>

          <div class="block">
            <nav class="pagination is-rounded" role="navigation">
              <a class="icon-text has-text-weight-bold pagination-previous">
                {/*  <span class="icon">
                  <i class="fas fa-arrow-left"></i>
                </span> */}
                <span
                  class="pm-span-underline"
                  onClick={PreviousClicked}
                  style={{ minWidth: "6em" }}
                >
                  Previous
                </span>
              </a>
              <a class="icon-text has-text-weight-bold pagination-next">
                <span
                  class="pm-span-underline"
                  onClick={NextClicked}
                  style={{ minWidth: "6em" }}
                >
                  Next
                </span>
                {/*  <span class="icon">
                  <i class="fas fa-arrow-right"></i>
                </span> */}
              </a>
            </nav>
          </div>
        </div>
      </section>
    </div>
  );
}
