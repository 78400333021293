import React, { useState, useContext, useEffect } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import axios from "axios";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import parse from "html-react-parser";

import {
  Card,
  CardHeader,
  CardTitle,
  CardImg,
  CardBody,
  CardFooter,
  Button,
  ButtonGroup,
  Fade,
  Badge,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
} from "shards-react";
import Chip from "@material-ui/core/Chip";
import IconButton from "@material-ui/core/IconButton";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import { useLocation, useHistory } from "react-router-dom";
import styles from "./MyAccount.module.css";
import InputMask from "react-input-mask";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Typography from "@material-ui/core/Typography";
import {
  Logout,
  getLoggedInUser,
  UpdateAccountInfo,
  UpdateAccountPassword,
} from "../../../../APIManager";

import { UserContext } from "../../../../userContext";
import { RefreshUserContext } from "../../../../UtilityFunctions";
import { findAllByDisplayValue } from "@testing-library/dom";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const MyAccount = () => {
  const [user, setUser] = useContext(UserContext);
  //console.log(user);
  return (
    <div className={styles.MainBox}>
      <div className={styles.PageHeader}>
        <h2>My Account</h2>
      </div>
      <div className={styles.MainContent}>
        {/*   <AccountCard user={user} setUser={setUser} /> */}
        <PersonalDetailsCard user={user} setUser={setUser} />
        <PasswordChangeForm user={user} setUser={setUser} />
      </div>
    </div>
  );
};

const AccountCard = (props) => {
  const sm = useMediaQuery("(max-width:750px)");
  let history = useHistory();
  const { user, setUser } = props;
  var badgetheme;
  var startdate = "";
  var enddate = "";
  if (user.subscriptionstatus === "Active") {
    badgetheme = "success";
  }
  if (user.subscriptionstatus === "Payment Pending") {
    badgetheme = "warning";
  }
  if (user.subscriptionstatus === "Not Active") {
    badgetheme = "danger";
  }
  if (user.subscriptionstartdate) {
    startdate = FormatDate(new Date(user.subscriptionstartdate));
  } else {
    startdate = "";
  }
  if (user.subscriptionenddate) {
    enddate = FormatDate(new Date(user.subscriptionenddate));
  } else {
    enddate = "";
  }
  return (
    <div className={styles.Card}>
      <div className={styles.CardHeading}>
        <h5>Account</h5>
        <hr />
      </div>
      <div className={styles.CardContent}>
        <div className={styles.FieldHeading}>
          Subscription Status:{" "}
          <Badge size="lg" theme={badgetheme}>
            {user.subscriptionstatus}
          </Badge>
        </div>
        <div className={styles.FieldHeading}>
          Subscription Start Date: {startdate}
        </div>
        <div className={styles.FieldHeading}>
          Subscription End Date: {enddate}
        </div>
        <div className={styles.ButtonToolbar}>
          <Button
            onClick={() => {
              Logout().then(() => {
                RefreshUserContext(setUser, user).then(() => {
                  history.push("/");
                });
              });
            }}
            size={sm ? null : "lg"}
          >
            Logout
          </Button>
          {user.subscriptionstatus === "Active" ||
          user.subscriptionstatus === "Payment Pending" ? null : (
            <span className={styles.PurchaseButton}>
              <Button theme="success" size={sm ? null : "lg"}>
                Purchase Subscription
              </Button>
            </span>
          )}
        </div>
      </div>
    </div>
  );
};

const PersonalDetailsCard = (props) => {
  const sm = useMediaQuery("(max-width:750px)");
  const [fontSize, SetFontSize] = useState("1.5em");
  useEffect(() => {
    if (sm) {
      SetFontSize("1.1em");
    } else {
      SetFontSize("1.5em");
    }
  }, [sm]);
  const { user, setUser } = props;

  //IForm Logic
  const [updateEnabled, SetUpdateEnabled] = useState(false);
  const [cancelVisible, SetCancelVisible] = useState(false);
  const [waitingForResponse, SetWaitingForResponse] = useState(false);
  const [isServerError, SetIsServerError] = useState(false);
  const [serverErrorText, SetServerErrorText] = useState("");

  //Form Variables
  const [fullname, SetFullname] = useState(user.fullname);
  const [username, SetUsername] = useState(user.username);
  const [phonenumber, SetPhonenumber] = useState(user.phonenumber);
  const [city, SetCity] = useState(user.city);
  const [school, SetSchool] = useState(user.school);

  //Form Validation
  const [fullnameValid, SetFullnameValid] = useState(true);
  const [usernameValid, SetUsernameValid] = useState(true);
  const [phonenumberValid, SetPhonenumberValid] = useState(true);
  const [cityValid, SetCityValid] = useState(true);
  const [schoolValid, SetSchoolValid] = useState(true);

  //Form Error Texts
  const [fullnameErrorText, SetFullnameErrorText] = useState("");
  const [usernameErrorText, SetUsernameErrorText] = useState("");
  const [phonenumberErrorText, SetPhonenumberErrorText] = useState("");
  const [cityErrorText, SetCityErrorText] = useState("");
  const [schoolErrorText, SetSchoolErrorText] = useState("");

  useEffect(() => {
    if (!waitingForResponse) {
      if (
        fullname !== user.fullname ||
        phonenumber !== user.phonenumber ||
        city !== user.city ||
        school !== user.school
      ) {
        SetCancelVisible(true);
        if (fullnameValid && phonenumberValid && cityValid && schoolValid) {
          SetUpdateEnabled(true);
        } else {
          SetUpdateEnabled(false);
        }
      } else {
        SetCancelVisible(false);
        SetUpdateEnabled(false);
      }
    } else {
      SetUpdateEnabled(false);
      SetCancelVisible(false);
    }
  }, [
    fullname,
    username,
    phonenumber,
    city,
    school,
    waitingForResponse,
    fullnameValid,
    phonenumberValid,
    cityValid,
    schoolValid,
  ]);

  function OnUsernameChange(event) {
    //console.log(event);
    var newUsername = event.target.value.trim();
    SetUsername(newUsername);
    ValidateUsername(newUsername);
  }

  function OnFullnameChange(event) {
    var newFullname = event.target.value;
    SetFullname(newFullname);
    ValidateFullname(newFullname);
  }
  function OnPhonenumberChange(event) {
    var newPhonenumber = event.target.value.replace("-", "").trim();
    SetPhonenumber(newPhonenumber);
    ValidatePhonenumber(newPhonenumber);
  }
  function OnCityChange(event) {
    var newCity = event === null ? "" : event.trim();
    SetCity(newCity);
    ValidateCity(newCity);
  }

  function OnSchoolChange(event) {
    var newSchool = event === null ? "" : event.trim();
    SetSchool(newSchool);
    ValidateSchool(newSchool);
  }
  //Validators
  function ValidateUsername(newUsername) {
    if (newUsername.length === 0) {
      SetUsernameErrorText("Email address can't be empty");
      SetUsernameValid(false);
      return false;
    }
    var regex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!regex.test(newUsername)) {
      SetUsernameErrorText("Enter a valid email address");
      SetUsernameValid(false);
      return false;
    } else {
      SetUsernameErrorText("");
      SetUsernameValid(true);
      return true;
    }
  }
  function ValidateFullname(newFullname) {
    if (newFullname.length === 0) {
      SetFullnameErrorText("Full name can't be empty");
      SetFullnameValid(false);
      return false;
    }
    if (newFullname.length < 5) {
      SetFullnameErrorText("Please enter a valid Full Name");
      SetFullnameValid(false);
      return false;
    }
    var regex = /^[a-zA-Z ]+$/;
    if (!regex.test(newFullname)) {
      SetFullnameErrorText("Full name can only have alphabets");
      SetFullnameValid(false);
      return false;
    } else {
      SetFullnameErrorText("");
      SetFullnameValid(true);
      return true;
    }
  }

  function ValidatePhonenumber(newPhonenumber) {
    if (newPhonenumber.length === 0) {
      SetPhonenumberErrorText("Phonenumber can't be empty");
      SetPhonenumberValid(false);
      return false;
    }
    var regex = /^[0][3][0-9]{2}[0-9]{7}$/;
    if (!regex.test(newPhonenumber)) {
      SetPhonenumberErrorText("Enter a valid Pakistan phonenumber");
      SetPhonenumberValid(false);
      return false;
    } else {
      SetPhonenumberErrorText("");
      SetPhonenumberValid(true);
      return true;
    }
  }

  function ValidateCity(newCity) {
    if (!newCity || newCity.length === 0) {
      SetCityErrorText("City can't be empty");
      SetCityValid(false);
      return false;
    } else {
      SetCityErrorText("");
      SetCityValid(true);
      return true;
    }
  }

  function ValidateSchool(newSchool) {
    if (!newSchool || newSchool.length === 0) {
      SetSchoolErrorText("School can't be empty");
      SetSchoolValid(false);
      return false;
    } else {
      SetSchoolErrorText("");
      SetSchoolValid(true);
      return true;
    }
  }

  function CancelChanges() {
    SetFullname(user.fullname);
    SetPhonenumber(user.phonenumber);
    SetCity(user.city);
    SetSchool(user.school);

    ValidateFullname(user.fullname);
    ValidatePhonenumber(user.phonenumber);
    ValidateCity(user.city);
    ValidateSchool(user.school);
  }

  function SubmitForm() {
    if (fullnameValid && phonenumberValid && cityValid && schoolValid) {
      SetWaitingForResponse(true);
      SetIsServerError(false);
      SetServerErrorText("");
      UpdateAccountInfo({
        fullname: fullname,
        phonenumber: phonenumber,
        city: city,
        school: school,
      })
        .then((res) => {
          ResolveServerResponse(res);
        })
        .catch(() => {
          SetWaitingForResponse(false);
          SetIsServerError(true);
          SetServerErrorText("Network Error");
        });
    }
  }
  function ResolveServerResponse(res) {
    var data = res.data;
    if (data.Error === true) {
      SetIsServerError(true);
      SetServerErrorText(data.ErrorText);
      RefreshUserContext(setUser, user).then(() => {
        SetWaitingForResponse(false);
        // SetCancelVisible(false)
        //SetUpdateEnabled(false)
      });
    } else {
      SetIsServerError(false);
      SetServerErrorText("");
      RefreshUserContext(setUser, user).then(() => {
        SetWaitingForResponse(false);
        //SetCancelVisible(false)
        //SetUpdateEnabled(false)
      });
    }
  }
  return (
    <div className={styles.Card}>
      <div className={styles.CardHeading}>
        <h5>Personal Details</h5>
        <hr />
      </div>
      <div className={styles.CardContent}>
        <div className={styles.InputField}>
          <TextField
            value={fullname}
            fullWidth
            id="outlined-basic"
            label="Full Name"
            error={!fullnameValid}
            variant="outlined"
            helperText={fullnameErrorText}
            inputProps={{ style: { fontSize: fontSize }, maxLength: 50 }} // font size of input text
            InputLabelProps={{
              style: {
                fontSize: fontSize,
                backgroundColor: "white",
                marginRight: ".5em",
              },
            }} // font size of input label
            onChange={OnFullnameChange}
          />
        </div>
        <div className={styles.InputField}>
          <TextField
            value={username}
            fullWidth
            disabled
            id="outlined-basic"
            label="Email"
            //error={!usernameValid}
            variant="outlined"
            //helperText={usernameErrorText}
            inputProps={{ style: { fontSize: fontSize }, maxLength: 50 }} // font size of input text
            InputLabelProps={{
              style: { fontSize: fontSize, backgroundColor: "white" },
            }} // font size of input label
            //onChange={OnUsernameChange}
          />
        </div>
        <div className={styles.InputField}>
          <InputMask
            value={phonenumber}
            inputProps={{
              style: { letterSpacing: "2px", fontSize: fontSize },
            }}
            label="Phone Number"
            mask={"0\\399-9999999"}
            value={phonenumber}
            onChange={OnPhonenumberChange}
          >
            {(inputProps) => (
              <TextField
                {...inputProps}
                fullWidth
                //id="outlined-basic"
                //label="Phone Number"
                //type="number"
                error={!phonenumberValid}
                variant="outlined"
                helperText={phonenumberErrorText}
                inputProps={{ style: { fontSize: fontSize } }} // font size of input text
                InputLabelProps={{
                  style: { fontSize: fontSize, backgroundColor: "white" },
                }} // font size of input label
                // onChange={OnUsernameChange}
              />
            )}
          </InputMask>
        </div>
        <div className={styles.InputField}>
          <Autocomplete
            value={city}
            id="combo-box-demo"
            options={cities}
            renderOption={(option) => (
              <Typography style={{ fontSize: fontSize }}>{option}</Typography>
            )}
            onChange={(event, newValue) => {
              OnCityChange(newValue);
            }}
            getOptionLabel={(option) => option}
            renderInput={(params) => (
              <TextField
                {...params}
                label="City"
                variant="outlined"
                error={!cityValid}
                helperText={cityErrorText}
                inputProps={{
                  ...params.inputProps,
                  style: { fontSize: fontSize },
                }}
                InputLabelProps={{
                  style: { fontSize: fontSize, backgroundColor: "white" },
                }} // font size of input label
              />
            )}
          />
        </div>
        <div className={styles.InputField}>
          <Autocomplete
            value={school}
            id="combo-box-demo"
            freeSolo={true}
            options={schools}
            renderOption={(option) => (
              <Typography style={{ fontSize: fontSize }}>{option}</Typography>
            )}
            onInputChange={(event, newValue) => {
              OnSchoolChange(newValue);
            }}
            getOptionLabel={(option) => option}
            renderInput={(params) => (
              <TextField
                {...params}
                label="School"
                variant="outlined"
                error={!schoolValid}
                helperText={schoolErrorText}
                inputProps={{
                  ...params.inputProps,
                  style: { fontSize: fontSize },
                  maxLength: 50,
                }}
                InputLabelProps={{
                  style: { fontSize: fontSize, backgroundColor: "white" },
                }} // font size of input label
              />
            )}
          />
        </div>
        <div>
          <Button onClick={SubmitForm} size="lg" disabled={!updateEnabled}>
            Save Changes
          </Button>
          <Button
            onClick={CancelChanges}
            theme="danger"
            style={cancelVisible ? { marginLeft: "1em" } : { display: "none" }}
            size="lg"
          >
            Cancel
          </Button>
        </div>
      </div>
    </div>
  );
};

const PasswordChangeForm = () => {
  const sm = useMediaQuery("(max-width:750px)");
  const [fontSize, SetFontSize] = useState("1.5em");
  useEffect(() => {
    if (sm) {
      SetFontSize("1.1em");
    } else {
      SetFontSize("1.5em");
    }
  }, [sm]);

  //Form Control
  const [waitingOnResponse, SetWaitingOnResponse] = useState(false);
  const [saveEnabled, SetSaveEnabled] = useState(false);
  const [cancelShown, SetCancelShown] = useState(false);
  //Error Modal
  const [showModal, SetShowModal] = useState(false);
  const [modalHeading, SetModalHeading] = useState("");
  const [modalText, SetModalText] = useState("");

  const [showPassword, SetShowPassword] = useState(false);
  //Form Variables
  const [oldpassword, SetOldpassword] = useState("");
  const [newpassword, SetNewpassword] = useState("");
  const [confirmnewpassword, SetConfirmnewpassword] = useState("");
  //Form Validation
  const [oldpasswordValid, SetOldpasswordValid] = useState(true);
  const [newpasswordValid, SetNewpasswordValid] = useState(true);
  const [confirmnewpasswordValid, SetConfirmnewpasswordValid] = useState(true);
  //Form Error Texts
  const [oldpasswordErrorText, SetOldpasswordErrorText] = useState("");
  const [newpasswordErrorText, SetNewpasswordErrorText] = useState("");
  const [confirmnewpasswordErrorText, SetConfirmnewpasswordErrorText] =
    useState("");

  useEffect(() => {
    if (!waitingOnResponse) {
      if (
        !oldpassword &&
        !newpassword &&
        !confirmnewpassword &&
        oldpasswordValid &&
        newpasswordValid &&
        confirmnewpasswordValid
      ) {
        SetSaveEnabled(false);
        SetCancelShown(false);
      } else {
        if (
          oldpasswordValid &&
          newpasswordValid &&
          confirmnewpasswordValid &&
          oldpassword &&
          newpassword &&
          confirmnewpassword
        ) {
          SetSaveEnabled(true);
          SetCancelShown(true);
        } else {
          SetSaveEnabled(false);
          SetCancelShown(true);
        }
      }
    } else {
      SetSaveEnabled(false);
      SetCancelShown(false);
    }
  }, [
    oldpassword,
    newpassword,
    confirmnewpassword,
    oldpasswordValid,
    newpasswordValid,
    confirmnewpasswordValid,
    waitingOnResponse,
  ]);

  function OnOldpasswordChange(event) {
    var oldPassword = event.target.value;
    SetOldpassword(oldPassword);
    ValidateOldpassword(oldPassword);
  }

  function OnNewpasswordChange(event) {
    var newPassword = event.target.value;
    SetNewpassword(newPassword);
    ValidateNewpassword(newPassword);
    ValidateConfirmnewpassword(confirmnewpassword, newPassword);
  }

  function OnConfirmnewpasswordChange(event) {
    var newConfirmnewpassword = event.target.value;
    SetConfirmnewpassword(newConfirmnewpassword);
    ValidateConfirmnewpassword(newConfirmnewpassword, newpassword);
  }

  function ValidateOldpassword(oldPassword) {
    if (oldPassword.length === 0) {
      SetOldpasswordErrorText("Password cant be empty");
      SetOldpasswordValid(false);
      return false;
    } else {
      SetOldpasswordErrorText("");
      SetOldpasswordValid(true);
      return true;
    }
  }

  function ValidateNewpassword(newPassword) {
    if (newPassword.length === 0) {
      SetNewpasswordErrorText("Password can't be empty");
      SetNewpasswordValid(false);
      return false;
    }
    if (newPassword.length < 8) {
      SetNewpasswordErrorText("Password must be more than 8 characters long");
      SetNewpasswordValid(false);
      return false;
    } else {
      SetNewpasswordErrorText("");
      SetNewpasswordValid(true);
      return true;
    }
  }

  function ValidateConfirmnewpassword(newConfirmpassword, newpassword) {
    if (newConfirmpassword !== newpassword) {
      SetConfirmnewpasswordErrorText("Passwords don't match");
      SetConfirmnewpasswordValid(false);
      return false;
    } else {
      SetConfirmnewpasswordErrorText("");
      SetConfirmnewpasswordValid(true);
      return true;
    }
  }

  return (
    <div className={styles.Card}>
      <Modal
        size="sm"
        open={showModal}
        toggle={() => {
          SetShowModal(!showModal);
        }}
      >
        <ModalHeader>{modalHeading}</ModalHeader>
        <ModalBody>
          <div
            style={{ width: "100%", textAlign: "center", marginBottom: "1em" }}
          >
            {modalText}
          </div>
          <div style={{ width: "100%", textAlign: "center" }}>
            <Button
              onClick={() => {
                SetShowModal(!showModal);
              }}
            >
              Okay
            </Button>
          </div>
        </ModalBody>
      </Modal>
      <div className={styles.CardHeading}>
        <h5>Change Password</h5>
        <hr />
      </div>
      <div className={styles.CardContent}>
        <div className={styles.InputField}>
          <TextField
            value={oldpassword}
            fullWidth
            id="outlined-basic"
            label="Current Password"
            error={!oldpasswordValid}
            variant="outlined"
            type={showPassword ? "text" : "password"}
            helperText={oldpasswordErrorText}
            onChange={OnOldpasswordChange}
            inputProps={{
              style: { fontSize: fontSize },
              maxLength: 50,
            }} // font size of input text
            InputLabelProps={{
              style: { fontSize: fontSize, backgroundColor: "white" },
            }} // font size of input label
            //FormHelperTextProps={{ style: { fontSize: "1.2em" } }} // font size of input label
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => {
                      SetShowPassword(!showPassword);
                    }}
                  >
                    {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </div>
        <div className={styles.InputField}>
          <TextField
            value={newpassword}
            fullWidth
            id="outlined-basic"
            label="New Password"
            error={!newpasswordValid}
            variant="outlined"
            type={showPassword ? "text" : "password"}
            helperText={newpasswordErrorText}
            onChange={OnNewpasswordChange}
            inputProps={{
              style: { fontSize: fontSize },
              maxLength: 50,
            }} // font size of input text
            InputLabelProps={{
              style: { fontSize: fontSize, backgroundColor: "white" },
            }} // font size of input label
            //FormHelperTextProps={{ style: { fontSize: "1.2em" } }} // font size of input label
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => {
                      SetShowPassword(!showPassword);
                    }}
                  >
                    {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </div>
        <div className={styles.InputField}>
          <TextField
            value={confirmnewpassword}
            fullWidth
            id="outlined-basic"
            label="Confirm New Password"
            error={!confirmnewpasswordValid}
            variant="outlined"
            type={showPassword ? "text" : "password"}
            helperText={confirmnewpasswordErrorText}
            onChange={OnConfirmnewpasswordChange}
            inputProps={{
              style: { fontSize: fontSize },
              maxLength: 50,
            }} // font size of input text
            InputLabelProps={{
              style: { fontSize: fontSize, backgroundColor: "white" },
            }} // font size of input label
            //FormHelperTextProps={{ style: { fontSize: "1.2em" } }} // font size of input label
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => {
                      SetShowPassword(!showPassword);
                    }}
                  >
                    {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </div>
        <div>
          <Button onClick={SubmitForm} size="lg" disabled={!saveEnabled}>
            Change Password
          </Button>
          <Button
            onClick={CancelClicked}
            style={cancelShown ? { marginLeft: "1em" } : { display: "none" }}
            theme="danger"
            size="lg"
          >
            Cancel
          </Button>
        </div>
      </div>
    </div>
  );
  function CancelClicked() {
    SetOldpassword("");
    SetNewpassword("");
    SetConfirmnewpassword("");
    SetOldpasswordValid(true);
    SetNewpasswordValid(true);
    SetConfirmnewpasswordValid(true);
    SetOldpasswordErrorText("");
    SetNewpasswordErrorText("");
    SetConfirmnewpasswordErrorText("");
  }
  function SubmitForm() {
    if (oldpassword && newpassword && oldpasswordValid && newpasswordValid) {
      SetWaitingOnResponse(true);
      SetShowModal(false);
      SetModalHeading("");
      SetModalText("");
      UpdateAccountPassword(oldpassword, newpassword)
        .then((res) => {
          ResolveServerResponse(res);
        })
        .catch(() => {
          SetWaitingOnResponse(false);
          SetModalHeading("Network Error");
          SetModalText("Password could not be changed due to a network error");
        });
    }
  }
  function ResolveServerResponse(res) {
    var data = res.data;
    if (data.Error === true) {
      SetShowModal(true);
      SetModalHeading("Error");
      SetModalText(data.ErrorText);
      CancelClicked();
      SetWaitingOnResponse(false);
    } else {
      SetShowModal(true);
      SetModalHeading("Success");
      SetModalText("Password changed Successfuly");
      CancelClicked();
      SetWaitingOnResponse(false);
    }
  }
};
export default MyAccount;



function FormatDate(dateObj) {
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  //console.log(dateObj.getUTCDate());
  const month = monthNames[dateObj.getUTCMonth()];
  const day = String(dateObj.getUTCDate()).padStart(2, "0");

  const year = dateObj.getUTCFullYear();
  const output = month + "\n" + day + ", " + year;
  return output;
}

const cities = [
  "Abbottabad",
  "Adezai",
  "Ali Bandar",
  "Amir Chah",
  "Attock",
  "Ayubia",
  "Bahawalpur",
  "Baden",
  "Bagh",
  "Bahawalnagar",
  "Burewala",
  "Banda Daud Shah",
  "Bannu district|Bannu",
  "Batagram",
  "Bazdar",
  "Bela",
  "Bellpat",
  "Bhag",
  "Bhakkar",
  "Bhalwal",
  "Bhimber",
  "Birote",
  "Buner",
  "Burj",
  "Chiniot",
  "Chachro",
  "Chagai",
  "Chah Sandan",
  "Chailianwala",
  "Chakdara",
  "Chakku",
  "Chakwal",
  "Chaman",
  "Charsadda",
  "Chhatr",
  "Chichawatni",
  "Chitral",
  "Dadu",
  "Dera Ghazi Khan",
  "Dera Ismail Khan",
  "Dalbandin",
  "Dargai",
  "Darya Khan",
  "Daska",
  "Dera Bugti",
  "Dhana Sar",
  "Digri",
  "Dina City|Dina",
  "Dinga",
  ", Pakistan|Diplo",
  "Diwana",
  "Dokri",
  "Drosh",
  "Duki",
  "Dushi",
  "Duzab",
  "Faisalabad",
  "Fateh Jang",
  "Ghotki",
  "Gwadar",
  "Gujranwala",
  "Gujrat",
  "Gadra",
  "Gajar",
  "Gandava",
  "Garhi Khairo",
  "Garruck",
  "Ghakhar Mandi",
  "Ghanian",
  "Ghauspur",
  "Ghazluna",
  "Girdan",
  "Gulistan",
  "Gwash",
  "Hyderabad",
  "Hala",
  "Haripur",
  "Hab Chauki",
  "Hafizabad",
  "Hameedabad",
  "Hangu",
  "Harnai",
  "Hasilpur",
  "Haveli Lakha",
  "Hinglaj",
  "Hoshab",
  "Islamabad",
  "Islamkot",
  "Ispikan",
  "Jacobabad",
  "Jamshoro",
  "Jhang",
  "Jhelum",
  "Jamesabad",
  "Jampur",
  "Janghar",
  "Jati(Mughalbhin)",
  "Jauharabad",
  "Jhal",
  "Jhal Jhao",
  "Jhatpat",
  "Jhudo",
  "Jiwani",
  "Jungshahi",
  "Karachi",
  "Kotri",
  "Kalam",
  "Kalandi",
  "Kalat",
  "Kamalia",
  "Kamararod",
  "Kamber",
  "Kamokey",
  "Kanak",
  "Kandi",
  "Kandiaro",
  "Kanpur",
  "Kapip",
  "Kappar",
  "Karak City",
  "Karodi",
  "Kashmor",
  "Kasur",
  "Katuri",
  "Keti Bandar",
  "Khairpur",
  "Khanaspur",
  "Khanewal",
  "Kharan",
  "kharian",
  "Khokhropur",
  "Khora",
  "Khushab",
  "Khuzdar",
  "Kikki",
  "Klupro",
  "Kohan",
  "Kohat",
  "Kohistan",
  "Kohlu",
  "Korak",
  "Korangi",
  "Kot Sarae",
  "Kotli",
  "Lahore",
  "Larkana",
  "Lahri",
  "Lakki Marwat",
  "Lasbela",
  "Latamber",
  "Layyah",
  "Leiah",
  "Liari",
  "Lodhran",
  "Loralai",
  "Lower Dir",
  "Shadan Lund",
  "Multan",
  "Mandi Bahauddin",
  "Mansehra",
  "Mian Chanu",
  "Mirpur",
  ", Pakistan|Moro",
  "Mardan",
  "Mach",
  "Madyan",
  "Malakand",
  "Mand",
  "Manguchar",
  "Mashki Chah",
  "Maslti",
  "Mastuj",
  "Mastung",
  "Mathi",
  "Matiari",
  "Mehar",
  "Mekhtar",
  "Merui",
  "Mianwali",
  "Mianez",
  "Mirpur Batoro",
  "Mirpur Khas",
  "Mirpur Sakro",
  "Mithi",
  "Mongora",
  "Murgha Kibzai",
  "Muridke",
  "Musa Khel Bazar",
  "Muzaffar Garh",
  "Muzaffarabad",
  "Nawabshah",
  "Nazimabad",
  "Nowshera",
  "Nagar Parkar",
  "Nagha Kalat",
  "Nal",
  "Naokot",
  "Nasirabad",
  "Nauroz Kalat",
  "Naushara",
  "Nur Gamma",
  "Nushki",
  "Nuttal",
  "Okara",
  "Ormara",
  "Peshawar",
  "Panjgur",
  "Pasni City",
  "Paharpur",
  "Palantuk",
  "Pendoo",
  "Piharak",
  "Pirmahal",
  "Pishin",
  "Plandri",
  "Pokran",
  "Pounch",
  "Quetta",
  "Qambar",
  "Qamruddin Karez",
  "Qazi Ahmad",
  "Qila Abdullah",
  "Qila Ladgasht",
  "Qila Safed",
  "Qila Saifullah",
  "Rawalpindi",
  "Rabwah",
  "Rahim Yar Khan",
  "Rajan Pur",
  "Rakhni",
  "Ranipur",
  "Ratodero",
  "Rawalakot",
  "Renala Khurd",
  "Robat Thana",
  "Rodkhan",
  "Rohri",
  "Sialkot",
  "Sadiqabad",
  "Safdar Abad- (Dhaban Singh)",
  "Sahiwal",
  "Saidu Sharif",
  "Saindak",
  "Sakrand",
  "Sanjawi",
  "Sargodha",
  "Saruna",
  "Shabaz Kalat",
  "Shadadkhot",
  "Shahbandar",
  "Shahpur",
  "Shahpur Chakar",
  "Shakargarh",
  "Shangla",
  "Sharam Jogizai",
  "Sheikhupura",
  "Shikarpur",
  "Shingar",
  "Shorap",
  "Sibi",
  "Sohawa",
  "Sonmiani",
  "Sooianwala",
  "Spezand",
  "Spintangi",
  "Sui",
  "Sujawal",
  "Sukkur",
  "Suntsar",
  "Surab",
  "Swabi",
  "Swat",
  "Tando Adam",
  "Tando Bago",
  "Tangi",
  "Tank City",
  "Tar Ahamd Rind",
  "Thalo",
  "Thatta",
  "Toba Tek Singh",
  "Tordher",
  "Tujal",
  "Tump",
  "Turbat",
  "Umarao",
  "Umarkot",
  "Upper Dir",
  "Uthal",
  "Vehari",
  "Veirwaro",
  "Vitakri",
  "Wadh",
  "Wah Cantt",
  "Warah",
  "Washap",
  "Wasjuk",
  "Wazirabad",
  "Yakmach",
  "Zhob",
  "Other",
];

const schools = [
  "Adamjee Govt. Science College, Karachi",
  "Islamia Govt. Science College, Karachi",
  "Govt. PECHS Education Foundation Science College, Karachi",
  "Govt. Degree Science & Commerce College, Gulshan-e-Iqbal, Karachi",
  "Govt. Degree Boys College, Gulzar-e-Hijri, Karachi",
  "Govt. National College, Karachi",
  "Govt. Degree Boys College, Gulistan-e-Jauhar, Karachi",
  "Govt. Jamia Millia Degree College, Karachi",
  "Govt. Superior Science College, Karachi",
  "Govt. Boys Inter College, Landhi, Karachi",
  "Allama Iqbal Govt. – Boys Science College, Karachi",
  "Govt Degree College, Malir Cantt, Karachi",
  "ARG Govt. Degree Boys College, Landhi, Quaidabad, Karachi",
  "Govt. Degree College Murad Memon Goth, Karachi",
  "Govt. Degree Boys College, Korangi, Karachi",
  "D.J Sindh Govt. Science College, Karachi",
  "S.M. Govt. Science College, Shahrah-e-Liaquat, Karachi",
  "Govt. Aisha Bawani College, Karachi",
  "Govt. Degree Science & Commerce College, Lyari, Karachi",
  "Haji Abdullah Haroon Govt. College, Karachi",
  "Govt. Delhi Inter Science College",
  "Govt. City College Musa Colony, Karachi",
  "Govt. Degree Science College, Liaquatabad, Karachi",
  "Govt. Sirajud Daula Degree College, Karachi",
  "Pakistan Shipowners Govt. College, Karachi",
  "Govt. Degree Boys College, North Karachi, Karachi",
  "Govt Degree Boys College, North Karachi",
  "Govt. Degree Boys College, Mangopir, Karachi",
  "Govt. Degree Boys College, Konkar, Karachi",
  "Govt. Degree Boys College, Surjani Town, Karachi",
  "Govt. Degree Boys College, New Karachi, Karachi",
  "Govt. Degree Boys College, Buffer Zone, Karachi",
  "Govt. Jinnah College, Nazimabad, Karachi",
  "Govt. Degree Science & Commerce College, Orangi Town, Karachi",
  "Govt. Degree Boys College Asifabad, Karachi",
  "Govt. Degree Boys College, Keamari, Karachi",
  "Govt. College For Men, Nazimabad, Karachi",
  "Govt. Degree Boys College, Shams Pir, Karachi",
  "Govt. Degree Boys College, Baldia Town, Karachi",
  "Govt. Degree Boys College,  KMC Store NIshter Road, Karachi",
  "Begum Nusrat Bhutto Govt. Boys College, Lyari, Karachi",
  "Govt. Degree Boys College, Ibrahim Hyderi, Karachi",
  "Govt. Boys H.S.S. Kamal Khan Jokhio, Karachi",
  "Ghazi Muhammad Bin Qasim H.S.S. Agra Taj Colony Chak,",
  "Allama Iqbal Govt. Boys H.S.S., Block 16, FB Area Karachi",
  "Govt. Comprehensive H.S.S, Korangi-0.3, Karachi",
  "Govt. Boys Comprehensive H.S.S., Azizabad, Karachi",
  "Govt. Boys H.S.S. No. 2 K, Area Korangi, Karachi",
  "Govt. Mumtaz Boys H.S.S. Landhi, Karachi",
  "Govt. Boys H.S.S. Konkar Village Malir Karachi",
  "Abdullah Govt. College for Women, Karachi",
  "Govt. College for Women, Nazimabad, Karachi",
  "Sir Syed Govt. Girls College, Karachi",
  "APWA Govt. College for Women, Karachi",
  "Shaheed-e-Millat Govt. Degree Girls College, Azizabad, Karachi",
  "Govt. Degree Girls College, Al-Noor, FB Area, Karachi",
  "Riaz Govt. Girls College, Liaquatabad, Karachi",
  "Govt. Degree College for Women, Nazimabad, Karachi",
  "Govt. Girls Degree College, North Karachi",
  "Govt. Girls Degree College, Nazimabad, Karachi",
  "Govt. College for Women, New Karachi, Karachi",
  "Govt. Girls Inter College Orangi Sector, Karachi",
  "Govt. Degree Girls College, Orangi, Karachi",
  "Govt. Girls College, Metroville SITE, Karachi",
  "Govt. College for Women, Shahrah-e-Liaquat, Karachi",
  "Govt. Inter Girls College, Lyari, Karachi",
  "Govt. Karachi College for Women, Chand Bibi Road, Karachi",
  "Govt. SMB Fatima Jinnah Girls College, Karachi",
  "Govt. Girls Degree College Zamzama, Karachi",
  "Govt. College for Women, Korangi 06, Karachi",
  "Govt, College for Women, Korangi-4, Karachi",
  "Govt. Girls Degree College, Korangi, Karachi",
  "Govt Degree Girls College, Ibrahim Hydri, Karachi",
  "Govt. College for Women Saudabad, Karachi",
  "Govt. Girls Science College, Shah Faisal Colony, Karachi",
  "Allama Iqbal Govt. Girls College, Shah Faisal Colony No. 3, Karachi",
  "Liaquat Govt. Girls College Malir, Karachi",
  "Govt. Degree College Malir Cantt, Karachi",
  "Govt. Girls College, Landhi, Karachi",
  "Govt. Girls College, PIB Colony, Karachi",
  "St. Lawrance Govt. College for Women, Karachi",
  "Govt. Degree Girls College, Lines Area, Karachi",
  "Bamm PECHS Govt. College for Women, Karachi",
  "Khatoon-e-Pakistan Government College for Women, Stadium Road",
  "Govt. Degree College for Boys and Girls Stadium Road, Karachi",
  "Govt. Degree Science and Commerce College, Gulshan-e-Iqbal, Karachi",
  "Govt. Girls Degree College, Mehmoodabad, Karachi",
  "Govt. Degree Girls College, Safora Goth, Gulistan-e-Jauhar, Karachi",
  "Govt. Degree Girls College, Pak Colony, Karachi",
  "Govt. Degree Girls College, Buffer Zone, Karachi",
  "Jan Muhammad Brohi Govt. Degree Girls College, Gulshah-e- Maymar, Karachi",
  "Govt. Degree Girls College, Surjani Town, Karachi",
  "Govt. Degree Girls College, Shahnawaz Shar Goth, Karachi",
  "Govt. Girls Degree College, Gulzar-e-Hijri, Karachi",
  "Marium Mukhtiar Govt. Girls Degree College, Liaquatabad, Karachi",
  "Bibi Asifa Govt. Girls College, Muzaffarabad, Landhi, Karachi",
  "Shaheed Benazir Bhutto Govt. Girls College, Lyari, Karachi",
  "Govt. Girls College, Sharrafi Goth, Karachi",
  "Govt. Girls H.S.S. Landhi, Karachi",
  "Govt. Comprehensive H.S.S, North Nazimabad, Karachi",
  "Govt. Girls H.S.S. Bhiro Village, Karachi",
  "APWA Govt. Girls H.S.S., Liaquatabad, Karachi",
  "PAF Govt. Girls H.S.S. Karachi",
  "Govt. Girls H.S.S. Green Belt, Karachi",
  "Khaldunia Public School, Islamabad",
  "The City School",
  "Bahria College",
  "Cadet College, Hassanabdal",
  "Army Public School",
  "Beaconhouse School",
  "Lahore Grammar School",
  "Nixor A-Levels",
  "Karachi Grammar School",
  "The Lyceum A-Levels",
  "Cedar A-Levels",
  "Cordoba School for A-Levels",
  "Dawood Public School A-Levels",
  "Aisha Bawany Academy",
  "Karachi Public School",
  "Pak-Turk International Schools and Colleges",
  "Bahria Foundation College, Nazimabad",
  "Foundation Public School",
  "St. Michaels Convent School",
  "St. Joseph’s Convent School",
  "Habib Public School",
  "Hamdard Public School",
  "Garrison Academy",
  "St. Anthony’s College",
  "St. Patrick’s High School",
  "St. Peter’s High School",
  "Progressive Public School",
  "DA Public School",
  "White House Grammar School",
  "Generation’s School",
  "The Avicenna School",
  "Froebel Education Centre",
  "Washington International School",
  "L’ecole for Advance Studies",
  "Karachi Cadet School",
  "Falconhouse Grammar School",
  "The OASYS School",
  "Westminster School & College",
  "Aitchison Model School",
  "Jaffar Public School",
  "K N Academy",
  "Ladybird Grammar School",
  "The American School",
  "Happy Home School",
  "Shaheen Public School",
  "Southshore School for A-Level Studies",
  "The Indus Academy",
  "Usman Public School",
  "The A.M.I School",
  "Anees Hussain, Karachi",
  "Meritorious Science College, Karachi",
  "Roots Millennium College, Karachi",
  "Dar-e-Arqam Schools",
  "Sadiq Public School",
  "Kinnaird College",
  "COMMECS College",
  "NCR-CET College",
  "Fazaia Inter College",
  "DHACSS Shaikh Khalifa Bin Zayed Campus",
  "Punjab Group of Colleges",
  "Private Intermediate",
  "Private A-Levels",
  "Allied School",
  "TCF School",
];
