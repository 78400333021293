import React, { useEffect, useState, useContext } from "react";
import { useMediaQuery } from "@material-ui/core";
import styles from "./CheckOutPage.module.css";

import TextField from "@material-ui/core/TextField";
import {
  Button,
  FormInput,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  FormSelect,
} from "shards-react";
import { useHistory, useParams, withRouter } from "react-router";
import { UserContext } from "../../userContext";
import { NotificationContext } from "../../notificationContext";

import AbstractSwirlBG from "../../StaticImages/AbstractSwirlBG.PNG";

import ExamIcon from "../../StaticImages/meezan-square.png";
import JazzCash from "../../StaticImages/jazzcash-square.png";
import EasyPaisa from "../../StaticImages/easypaisa-square.png";
import Bank from "../../StaticImages/meezan-square.png";
import whatsapp from "../../StaticImages/Icons/whatsapp-new.png";
import messenger from "../../StaticImages/Icons/messenger-new.png";
import mail from "../../StaticImages/Icons/mail-fancy.svg";
import PaymentTuto from "../../StaticImages/PaymentTutorial1.png";
import CreditCard from "../../StaticImages/creditcard.svg";
import SadFace from "../../StaticImages/sadface.svg";

import GradientBtn from "../../Components/GradientBtn/GradientBtn";

import {
  VerifyCouponCode,
  PurchaseAddon,
  GetNotifications,
} from "../../APIManager";

import { useFileUpload } from "use-file-upload";

import PhotoUpload from "../../Components/PhotoUpload/PhotoUpload.jsx";

import CountUp from "react-countup";

const SKUs = {
  Premium: {
    Price: 2000,
    DisplayName: "Premium Plan",
    OrderHero: (
      <div className={styles.OrderHero}>
        <div>Premium</div>
      </div>
    ),
  },
/*   "AKU Mock Bundle 1": {
    Price: 1500,
    DisplayName: "AKU Mock Bundle 1",
    OrderHero: (
      <div className={styles.OrderHero}>
        <div style={{ fontSize: "0.9em" }}>AKU Mocks</div>
      </div>
    ),
  },
  "AKU Mock Bundle 2": {
    Price: 1000,
    DisplayName: "AKU Mock Bundle 2",
    OrderHero: (
      <div className={styles.OrderHero}>
        <div style={{ fontSize: "0.9em" }}>AKU Mocks</div>
      </div>
    ),
  },
  "AKU Mock Bundle 3": {
    Price: 1000,
    DisplayName: "AKU Mock Bundle 3",
    OrderHero: (
      <div className={styles.OrderHero}>
        <div style={{ fontSize: "0.9em" }}>AKU Mocks</div>
      </div>
    ),
  },
  "AKU Mock Bundle 4": {
    Price: 2000,
    DisplayName: "AKU Mock Bundle 4",
    OrderHero: (
      <div className={styles.OrderHero}>
        <div style={{ fontSize: "0.9em" }}>AKU Mocks</div>
      </div>
    ),
  },
  "NUMS Mock Bundle 1": {
    Price: 1500,
    DisplayName: "NUMS Mock Bundle 1",
    OrderHero: (
      <div className={styles.OrderHero}>
        <div style={{ fontSize: "0.75em" }}>NUMS Mocks</div>
      </div>
    ),
  },
  "NUMS Mock Bundle 2": {
    Price: 1000,
    DisplayName: "NUMS Mock Bundle 2",
    OrderHero: (
      <div className={styles.OrderHero}>
        <div style={{ fontSize: "0.75em" }}>NUMS Mocks</div>
      </div>
    ),
  }, */
};

export default function () {
  const history = useHistory();
  const [file, selectFile] = useFileUpload();
  const [base64Image, SetBase64Image] = useState("");

  const DeviceQuery840 = useMediaQuery("(max-width:840px)");

  let search = window.location.search;
  let params = new URLSearchParams(search);
  var sku = params.get("sku");
  if (!sku) {
    sku = "Premium";
  }

  var paymentproofInput;
  const [paymentProof, SetPaymentProof] = useState("");
  const [fileName, SetFileName] = useState("");

  let [userContext, SetUserContext] = useContext(UserContext);

  let [notifcations, SetNotifications] = useContext(NotificationContext);

  if (!userContext.isLoggedIn) {
    history.push("/login");
  }

  const SubTotal = SKUs[sku].Price;
  const [discount, SetDiscount] = useState(0);
  const total = SubTotal - discount;

  const [referal, SetReferal] = useState("");

  const [discountCode, SetDiscountCode] = useState("");
  const [discountValid, SetDiscountValid] = useState(false);
  const [discountError, SetDiscountError] = useState(false);

  const [processingModalOpen, SetProcessingModalOpen] = useState(false);

  const [shakeDiscountCode, SetShakeDiscountCode] = useState(false);
  const [waitingForRes, SetWaitingForRes] = useState(false);
  const [modalOpen, SetModalOpen] = useState(false);
  const [modalContents, SetModalContents] = useState({
    Header: "Error",
    Content: "",
    OnOkay: () => {},
  });

  useEffect(() => {
    if (!file) {
      console.log(file);
      return;
    }
    let reader = new FileReader();
    reader.addEventListener("load", function (e) {
      let text = e.target.result;
      console.log(text);
      SetBase64Image(text);
    });
    reader.readAsDataURL(file.file);
  }, [file]);

  function OnApply() {
    VerifyCouponCode(discountCode.trim()).then((res) => {
      if (res.data.Error) {
        SetDiscountValid(false);
        SetDiscountError(true);
        SetDiscount(0);
        SetShakeDiscountCode(true);
      } else {
        SetDiscount(res.data.Amount);
        SetDiscountValid(true);
        SetDiscountError(false);
      }
    });
  }

  var PaymentMethods = () => {
    return (
      <div className={styles.PaymentMethods}>
        <div className={styles.PaymentHeading}>
          <img src={CreditCard} />
          Payment Details
        </div>
        <div className={styles.PaymentMethodContainer}>
          <div className={styles.PaymentMethod}>
            <img src={JazzCash} />
            <div>
              <strong> Jazz Cash</strong>
              <br />
              0333-3324911 (Muhammad Uzair)
              <br />
              0325-6064069 (Fahad Niaz Sheikh)
              <br /> 0336-2542685 (Niaz Hussain)
            </div>
          </div>
          <div className={styles.PaymentMethod}>
            <img src={Bank} />
            <div>
              <strong>Meezan Bank</strong>
              <br />
              Fahd Niaz Shaikh <br />
              Account No: 99170105642737
            </div>
          </div>
          <div className={styles.PaymentMethod}>
            <img src={EasyPaisa} />
            <div>
              <strong>Easy Paisa</strong>
              <br />
              0336-2542685(Fahad Niaz Sheikh)
              <br />
              0336-2541727 (Abbas Ali Mankani)
             
            </div>
          </div>
        </div>
        <div style={{ textAlign: "center" }}>
          <label
            className={`${styles.btnGrad}`}
            style={{ fontSize: "0.7em", borderRadius: "2em" }}
          >
            {fileName ? fileName : `Upload Proof of Payment`}
            <input
              type="file"
              hidden
              accept=".png,.jpeg,.jpg"
              ref={(ref) => (paymentproofInput = ref)}
              onChange={(e) => {
                if (e.target.files[0] !== undefined) {
                  let file = e.target.files[0];
                  let reader = new FileReader();
                  reader.addEventListener("load", function (e) {
                    let text = e.target.result;
                    SetPaymentProof(text);
                  });
                  SetFileName(e.target.files[0].name);
                  // console.log("memom");
                  //console.log(e.target.files);
                  reader.readAsDataURL(file);
                } else {
                  if (!paymentProof) {
                    SetPaymentProof("");
                    SetFileName("");
                  }
                }
                if (DeviceQuery840) {
                  document.getElementById("PurchaseButton").scrollIntoView({
                    behavior: "smooth",
                    block: "center",
                  });
                }
              }}
            />
          </label>
          <div style={{ fontWeight: "700" }}>
            ❗ Hey{" "}
            <span style={{ color: "var(--RedAccent)" }}>
              {userContext.fullname}
            </span>
            , make sure to press the{" "}
            <span style={{ color: "var(--QuestionBlue)" }}>'Purchase'</span>{" "}
            button after uploading the receipt
          </div>
          <br />
          <img src={paymentProof} style={{ width: "100%" }} width="350px" />
        </div>
      </div>
    );
  };

  var ContactUs = () => {
    return (
      <div className={styles.ContactMethods}>
        <div className={styles.ContactHeading}>
          <img src={SadFace} />
          Having trouble? Contact Us Now!
        </div>

        <div className={styles.ContactMethodContainer}>
          <div className={styles.ContactBoxFlexContainer}>
            <div className={styles.ContactBoxMainContainer}>
              <div className={styles.ContactBoxBox}>
                <img className={styles.ContactBoxBoxIcon} src={whatsapp} />
                <span
                  style={{ color: "#2bb140" }}
                  className={styles.ContactBoxBoxHeading}
                >
                  WhatsApp
                </span>
                <Button
                  theme="success"
                  onClick={() => {
                    var win = window.open(
                      "https://api.whatsapp.com/send/?phone=923061289229&text&app_absent=0&lang=en",
                      "_blank"
                    );
                    win.focus();
                  }}
                >
                  Click to start a chat
                </Button>
              </div>
            </div>
          </div>
          <div className={styles.ContactBoxFlexContainer}>
            <div className={styles.ContactBoxMainContainer}>
              <div className={styles.ContactBoxBox}>
                <img className={styles.ContactBoxBoxIcon} src={messenger} />
                <span
                  style={{ color: "#039dfd" }}
                  className={styles.ContactBoxBoxHeading}
                >
                  FB Messenger
                </span>
                <Button
                  theme="primary"
                  onClick={() => {
                    var win = window.open(
                      "https://www.messenger.com/t/104600425163664/?messaging_source=source%3Apages%3Amessage_shortlink",
                      "_blank"
                    );
                    win.focus();
                  }}
                >
                  Click to start a chat
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className={styles.MainDiv}>
      <Modal
        open={processingModalOpen}
        toggle={() => {
          //SetModalOpen(!modalOpen);
        }}
      >
        <ModalHeader>{"Processing"}</ModalHeader>
        <ModalBody style={{ fontSize: "1.1em", fontFamily: "Rubik" }}>
          Your order is being processed, please wait a few moments
        </ModalBody>
        <ModalFooter></ModalFooter>
      </Modal>
      <Modal
        open={modalOpen}
        toggle={() => {
          SetModalOpen(!modalOpen);
        }}
      >
        <ModalHeader>{modalContents.Header}</ModalHeader>
        <ModalBody>{modalContents.Content}</ModalBody>
        <ModalFooter>
          <Button
            onClick={() => {
              modalContents.OnOkay();
            }}
          >
            {modalContents.ButtonText}
          </Button>
        </ModalFooter>
      </Modal>
      <div className={styles.CheckoutMainBox}>
        <div className={styles.PromoBox}>
          <h1>
            Hey{" "}
            <span style={{ color: "var(--QuestionBlue)" }}>
              {userContext.fullname}!{" "}
            </span>{" "}
            You are making the right choice! Follow these 6 simple steps to
            subscribe to our {SKUs[sku].DisplayName}.
          </h1>
          <div className={styles.TutoContainer}>
            <img src={PaymentTuto} />
            <GradientBtn
              style={{
                fontSize: "0.8em",
                borderRadius: "30px",
                margin: "auto",
                padding: "1em 1.8em",
              }}
              gradient="#D31027 0%, #EA384D  51%, #D31027  100%"
              onClick={() => {
                window.open(
                  "https://www.youtube.com/watch?v=avYrHf3dvy0&list=PLFYvvG2VcsZIYRt_YfmtkjOjPClq5uiUN&index=3",
                  "_blank"
                );
              }}
            >
              Watch Tutorial
            </GradientBtn>
          </div>
          {!DeviceQuery840 && <PaymentMethods />}
          {!DeviceQuery840 && <ContactUs />}
        </div>
        <div className={styles.SummaryBox}>
          <p>Order Summary</p>
          {SKUs[sku].OrderHero}
          <br />
          <p>
            PreMed.PK {SKUs[sku].DisplayName}
            <br />
            <strong>Rs. {SKUs[sku].Price}</strong>
          </p>
          <div className={styles.DiscountBar}>
            <label>Discount Code</label>
            <div>
              <div>
                <FormInput
                  placeholder=""
                  inline
                  value={discountCode}
                  onChange={(e) => {
                    SetDiscountCode(e.target.value.toUpperCase());
                  }}
                  valid={discountValid}
                  invalid={discountError}
                  style={
                    shakeDiscountCode
                      ? { animation: "shake .5s" }
                      : { animation: "" }
                  }
                  onAnimationEnd={() => {
                    SetShakeDiscountCode(false);
                  }}
                  onKeyPress={(e) => {
                    console.log("Hello");
                    if (e.key == "Enter") {
                      OnApply();
                    }
                  }}
                />
              </div>
              <div>
                <Button
                  outline
                  onClick={OnApply}
                  disabled={!discountCode.length > 0}
                >
                  Apply
                </Button>
              </div>
            </div>
          </div>
          <div className={styles.CheckoutTable}>
            <div>
              <p>Sub Total</p>
              <p> Rs. {SubTotal}</p>
            </div>
            <div>
              <p>Discount</p>
              <p>
                Rs.{" "}
                <span>
                  <CountUp start={0} end={discount} duration={1}></CountUp>
                </span>
              </p>
            </div>
            <div>
              <p>Total</p>
              <p>
                Rs.{" "}
                <span>
                  <CountUp start={0} end={total} duration={1}></CountUp>
                </span>
              </p>
            </div>
          </div>

          <br />
          <label>How did you hear about us?</label>
          <FormSelect
            value={referal}
            onChange={(e) => {
              SetReferal(e.target.value);
            }}
            title="How did you hear about us"
          >
            <option disabled value=""></option>
            <option value="Facebook">Facebook</option>
            <option value="Instagram">Instagram</option>
            <option value="Friends">Friends</option>
            <option value="TikTok">TikTok</option>
            <option value="WhatsApp">WhatsApp</option>
            <option value="Other">Other</option>
          </FormSelect>
          <br />
          <Button
            id="PurchaseButton"
            data-toggle="tooltip"
            data-placement="top"
            title={file?.name ? "" : "Please upload proof of payment first"}
            disabled={(!paymentProof && total > 0) || waitingForRes}
            onClick={() => {
              SetWaitingForRes(true);
              SetProcessingModalOpen(true);
              try {
                PurchaseAddon(sku, discountCode.trim(), paymentProof, referal)
                  .then((res) => {
                    SetProcessingModalOpen(false);
                    HandleResponse(res.data);
                  })
                  .catch((error) => {
                    console.log(error);
                    SetProcessingModalOpen(false);
                    SetModalContents({
                      Header: "Error",
                      Content: (
                        <>
                          Sorry, your purchase is not able to be processed at
                          this time. Please contact us for help <br />
                          {String(error)}
                        </>
                      ),
                      OnOkay: () => {
                        SetModalOpen(false);
                      },
                      ButtonText: "Okay",
                    });
                    SetModalOpen(true);
                    SetWaitingForRes(false);
                  });
              } catch (error) {
                SetModalContents({
                  Header: "Error",
                  Content:
                    "Sorry, your purchase is not able to be processed at this time. Please contact us for help",
                  OnOkay: () => {
                    SetModalOpen(false);
                  },
                  ButtonText: "Okay",
                });
                SetModalOpen(true);
                SetWaitingForRes(false);
                SetProcessingModalOpen(false);
              }
            }}
          >
            Purchase
          </Button>
          <br />
          {file?.name
            ? ""
            : "Before clicking purchase, please upload the proof of payment and select 'How did you hear about us?'"}
          {DeviceQuery840 && <PaymentMethods />}
          {DeviceQuery840 && <ContactUs />}
        </div>
      </div>
    </div>
  );

  function HandleResponse(response) {
    SetWaitingForRes(false);
    if (response.Error) {
      switch (response.ErrorText) {
        case "Order already placed":
          SetModalContents({
            Header: "Error",
            Content:
              "Your order has already been placed. Please wait while we verify your payment.",
            OnOkay: () => {
              SetModalOpen(false);
            },
            ButtonText: "Okay",
          });
          SetModalOpen(true);
          break;
        case "Already Purchased":
          SetModalContents({
            Header: "Error",
            Content:
              "You have already purchased this. You can reload the page if you are unable to use the new features",
            OnOkay: () => {
              SetModalOpen(false);
            },
            ButtonText: "Okay",
          });
          SetModalOpen(true);
          break;

        case "Invalid Code":
          SetModalContents({
            Header: "Error",
            Content:
              "The coupon code you have entered is not valid, please enter a correct coupon code",
            OnOkay: () => {
              SetModalOpen(false);
            },
            ButtonText: "Okay",
          });
          SetModalOpen(true);
          break;

        default:
          break;
      }
    } else {
      switch (response.SuccessText) {
        case "Successfuly Placed":
          SetModalContents({
            Header: "Success! 🎉",
            Content:
              "Your order has been placed successfully! We will verify your payment and activate your account as soon as possible! You will also receive a confirmation email once your account is activated. If you do not receive it, please check your spam folder",
            OnOkay: () => {
              SetModalOpen(false);
              GetNotifications().then((res) => {
                SetNotifications(res.data);
                history.push({
                  pathname: "/my-notifications",
                });
              });
            },
            ButtonText: "Okay",
          });

          SetModalOpen(true);
          break;

        case "Successfuly Purchased":
          SetModalContents({
            Header: "Success! 🎉",
            Content:
              sku === "Premium"
                ? "You have successfully purchased PreMed.PK Premium Subscription"
                : `You have successfully purchased PreMed.PK ${sku}`,
            OnOkay: () => {
              SetModalOpen(false);
              window.location.href = "/dashboard";
            },
            ButtonText: "Let’s get studying!",
          });

          SetModalOpen(true);

        default:
          break;
      }
    }
  }
}
