import React, { useState } from "react";
import { BrowserRouter as Router, Route, Switch, Link } from "react-router-dom";
import styles from "./Ambassadors.module.css";
import { Button, Badge } from "shards-react";
import whatsapp from "../../StaticImages/Icons/whatsapp-fancy.svg";
import messenger from "../../StaticImages/Icons/messenger-fancy.svg";
import mail from "../../StaticImages/Icons/mail-fancy.svg";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Typography from "@material-ui/core/Typography";
import { createFilterOptions } from "@material-ui/lab/Autocomplete";
import GradientBtn from "../../Components/GradientBtn/GradientBtn";

import umarimg from "../../StaticImages/TeamPhotos/UmarPic.png";
import fahdimg from "../../StaticImages/TeamPhotos/FahdPic.png";
import hasnainimg from "../../StaticImages/TeamPhotos/HasnainPic.png";
import tahaimg from "../../StaticImages/TeamPhotos/TahaPic.png";
import illyunimg from "../../StaticImages/TeamPhotos/IllyunPic.png";

import TeamsGraphic from "../../StaticImages/TeamsGraphic.svg";

import GradCrossButton from "../../StaticImages/GradCrossButton.svg";

import HeadImage from "../../StaticImages/Meet the Team Vector.png";

import TeamColumnMobile from "./TeamColumnMobile";

import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Dialog from "@material-ui/core/Dialog";
import Slide from "@material-ui/core/Slide";

import fbicon from "../../StaticImages/Footer/facebook-circle.svg";
import instaicon from "../../StaticImages/Footer/instagram-circle.svg";
import whatsappicon from "../../StaticImages/Footer/whatsapp-circle.svg";
import twittericon from "../../StaticImages/Footer/twitter-circle.svg";

import MagGlassIcon from "../../StaticImages/magnifying-glass.svg";

import { TeamMembers } from "./Ambassadors";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const filterOptions = createFilterOptions({
  matchFrom: "start",
  ignoreCase: true,
  trim: true,
});

const Ambassadors = () => {
  var persons = TeamMembers;
  persons = persons.filter((p) => p.School !== "Aga Khan University");
  const smallDeviceQuery = useMediaQuery("(max-width:760px)");
  const secondPill = useMediaQuery("(max-width:610px)");
  const MediaQueryMax500 = useMediaQuery("(max-width:500px)");

  const [autoCompleteOptions, SetAutoCompleteOptions] = useState(
    cities.concat(persons.map((p) => p.Name))
  );
  const [searchInput, SetSearchInput] = useState("");
  const [showSearchLabel, SetShowSearchLabel] = useState(true);

  const [selectedCategory, SetSelectedCategory] = useState("Brand Associate");

  const [selectedPerson, SetSelectedPerson] = useState("");
  const [modalOpen, SetModalOpen] = useState(false);
  persons = persons.filter(
    (p) => p.Role === selectedCategory || selectedCategory === "All"
  );
  if (searchInput) {
    persons = persons.filter(
      (p) => p.City == searchInput || p.Name == searchInput
    );
  }

  var presonsRowsData = chunkArrayInGroups(persons, 3);

  var noOfRows = presonsRowsData.length;
  if (noOfRows) {
    var personsInLastRow = presonsRowsData[noOfRows - 1].length;
    var foo = new Array(3 - personsInLastRow);
    if (personsInLastRow != 3) {
      /*   for (let index = 0; index < foo.length; index++) {
        presonsRowsData[presonsRowsData.length - 1].push({
          Name: null,
          Role: null,
          Image: null,
        });
      } */
    }
  }

  function onCatButtonClick(e) {
    SetSelectedCategory(e.target.formTarget);
  }

  return (
    <div className={styles.MainDiv}>
      {true && (
        <Dialog
          scroll="body"
          TransitionComponent={Transition}
          keepMounted
          //onClose={handleClose}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
          fullWidth
          maxWidth="md"
          onClose={() => {
            SetModalOpen(false);
          }}
          open={modalOpen}
        >
          <div className={`${styles.modalcontent}`}>
            <div class="modal-body">
              <div
                class="d-flex flex-row flex-row-reverse flex-end"
                onClick={() => {
                  SetModalOpen(false);
                  console.log("hello");
                }}
                style={{ cursor: "pointer" }}
              >
                <img src={GradCrossButton} />
              </div>
              <div class="row">
                <div class="col">
                  <div className={`${styles.modalImg}`}>
                    <img width="100%" src={selectedPerson.Image} />
                  </div>
                </div>
                <div class="col">
                  <h1 className={`${styles.teamName}`}>
                    {selectedPerson.Name}
                  </h1>
                  <h4 className={`${styles.teamTitle}`}>
                    {selectedPerson.Role}
                  </h4>
                  <p className={`${styles.modalPara}`}>{selectedPerson.Bio}</p>
                  <a href="https://www.facebook.com/PreMed.PK">
                    {" "}
                    <img
                      className={styles.SocialMediaIcons}
                      src={fbicon}
                    />{" "}
                  </a>
                  <a href="https://www.instagram.com/premed.pk/">
                    {" "}
                    <img className={styles.SocialMediaIcons} src={instaicon} />
                  </a>
                  <a href="https://twitter.com/premedpk?s=21">
                    {" "}
                    <img
                      className={styles.SocialMediaIcons}
                      src={twittericon}
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </Dialog>
      )}
      <div class={`${styles.mainContainer} m-0`}>
        <div class={`d-flex justify-content-center mb-5`}>
          <div class={`${styles.spectrumUL}`}>
            <h1 class={`${styles.mainTitle}`}>
              Help Pre<span style={{ color: "#EC5863" }}>M</span>ed.PK grow
              through our Campus Associate Program
            </h1>
          </div>
        </div>
        <div className={styles.ApplyHeading}>
          Click here to{" "}
          <GradientBtn
            gradient={"#02AAB0 0%, #00CDAC  51%, #02AAB0  100%"}
            style={{
              fontSize: "0.6em",
              borderRadius: "1.7em",
              margin: 0,
              marginLeft: "1em",
              marginRight: "1em",
              paddingLeft: "1.8em",
              paddingRight: "1.8em",
              paddingTop: "0.4em",
              paddingBottom: "0.4em",
              fontWeight: "800",
            }}
            onClick={() => {
              window
                .open("https://forms.gle/iB9skTugSoVs7gxf6", "_blank")
                .focus();
            }}
          >
            APPLY
          </GradientBtn>{" "}
          now
        </div>
        <div className={styles.SubTitle}>
          Search up your city, district, college or universities to find out
          about the ambssadors nearest to you and avail{" "}
          <span style={{ color: "#EC5863" }}>amazing discounts</span> and
          features.
        </div>
        <div className={styles.SearchBox}>
          <div>
            <div class={`${styles.TeamBtnContainer}`}>
              <button
                type="button"
                class={
                  selectedCategory === "Campus Associate"
                    ? `${styles.TeamRedActive}`
                    : null
                }
                formTarget="Campus Associate"
                onClick={onCatButtonClick}
              >
                Campus Associates
              </button>
              <button
                type="button"
                class={
                  selectedCategory === "Brand Associate"
                    ? `${styles.TeamRedActive}`
                    : null
                }
                formTarget="Brand Associate"
                onClick={onCatButtonClick}
              >
                Brand Associates
              </button>
            </div>
            <Autocomplete
              id="combo-box-demo"
              options={autoCompleteOptions}
              filterOptions={filterOptions}
              renderOption={(option) => (
                <Typography style={{ fontSize: "1.2rem", fontFamily: "Rubik" }}>
                  {option}
                </Typography>
              )}
              onChange={(event, newValue) => {
                SetSearchInput(newValue);
                SetShowSearchLabel(!newValue);
              }}
              onInputChange={(event, newValue) => {
                SetShowSearchLabel(!newValue);
              }}
              getOptionLabel={(option) => option}
              renderInput={(params) => (
                <TextField
                  {...params}
                  id="standard-basic"
                  label={
                    !showSearchLabel ? (
                      "  "
                    ) : (
                      <span style={{ fontFamily: "Rubik" }}>
                        <span>
                          <img
                            width={MediaQueryMax500 ? "20px" : "30px"}
                            src={MagGlassIcon}
                          />
                        </span>{" "}
                        Search by name, city, or district
                      </span>
                    )
                  }
                  variant="standard"
                  fullWidth
                  inputProps={{
                    ...params.inputProps,
                    min: 0,
                    style: { textAlign: "center", fontSize: "1.7em" },
                  }} // the change is here
                  InputLabelProps={{
                    min: 0,
                    style: {
                      textAlign: "center",
                      width: "100%",
                      fontSize: MediaQueryMax500 ? "1em" : "1.7em",
                    },
                    shrink: false,
                  }}
                  hintStyle={{ width: "600px", textAlign: "center" }}
                />
              )}
            />
          </div>
        </div>
        <div
          class={`tab-content ${styles.TabContentDiv}`}
          id="pills-tabContent"
          hidden={smallDeviceQuery}
        >
          <div
            class="tab-pane fade show active"
            id="team-core"
            role="tabpanel"
            aria-labelledby="team-core"
          >
            {presonsRowsData.map((row) => {
              return (
                <>
                  {" "}
                  <div class={`${styles.teamPicContainer} p-4 pb-0 row`}>
                    {row.map((p) => {
                      return <TeamPhotoBox Img={p.Image} />;
                    })}
                  </div>
                  <div class={`${styles.nameRow} p-4 pb-0 row`}>
                    {row.map((p) => {
                      return (
                        <TeamNameBox
                          Name={p.Name}
                          Title={p.School}
                          City={p.City}
                        />
                      );
                    })}
                  </div>
                </>
              );
            })}
            <div
              class="modal fade"
              id="exampleModal"
              tabindex="-1"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div class="modal-dialog modal-lg">
                <div class="modal-content">
                  <div class="modal-body">
                    <div class="d-flex flex-row flex-row-reverse flex-end">
                      <button
                        type="button"
                        class="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      ></button>
                    </div>
                    <div class="row">
                      <div class="col">
                        <img
                          class="modalImg"
                          width="100%"
                          src="./images/fahd.png"
                          alt=""
                        />
                      </div>
                      <div class="col">
                        <h1 class="teamName">John Doe</h1>
                        <h4 class="teamTitle">CEO</h4>
                        <p class="modalPara">
                          Lorem ipsum dolor sit amet consectetur adipisicing
                          elit. Excepturi iste ullam officia, molestias, amet
                          hic, ipsum dolorem suscipit at nulla nisi reiciendis
                          culpa aut laudantium dolor soluta placeat ab nemo?
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="tab-pane fade"
            id="team-tech"
            role="tabpanel"
            aria-labelledby="team-tech"
          >
            <div class="teamPicContainer nameRow p-4 pb-0 row">
              <div class="col">
                <img
                  class="teamPic"
                  src="./images/fahd-transparent.png"
                  alt=""
                />
              </div>
            </div>
            <div class="nameRow p-4 pb-0 row">
              <div class="col">
                <h1 class="mt-3 teamName">John Doe</h1>
                <h3 class="teamTitle">CEO</h3>
                <button
                  type="button"
                  class="teamButton py-2 px-4"
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal"
                >
                  Read More
                </button>
              </div>
            </div>
          </div>
          <div
            class="tab-pane fade"
            id="team-edu"
            role="tabpanel"
            aria-labelledby="team-edu"
          >
            ...
          </div>
          <div
            class="tab-pane fade"
            id="team-marketing"
            role="tabpanel"
            aria-labelledby="team-marketing"
          >
            ...
          </div>
          <div
            class="tab-pane fade"
            id="team-Associate"
            role="tabpanel"
            aria-labelledby="team-Associate"
          >
            ...
          </div>
        </div>
        <div hidden={!smallDeviceQuery}>
          <TeamColumnMobile persons={persons} />
        </div>
      </div>
    </div>
  );
};

const NavButton = ({ labelText }) => {
  return (
    <button class={`${styles.navlink} active`} type="button" role="tab">
      Core
    </button>
  );
};

const TeamPhotoBox = ({ Img }) => {
  return (
    <>
      <div class="col">
        <img
          class={`${styles.teamPic}`}
          style={{ maxWidth: "280px" }}
          src={Img}
          alt=""
        />
      </div>
    </>
  );
};

const TeamNameBox = ({ Name, Title, City }) => {
  return (
    <div class="col">
      <h1 class={`mt-3 ${styles.teamName}`}>{Name}</h1>
      <h3 class={`${styles.teamTitle}`}>{Title}</h3>
      {Name && <label class={`${styles.teamButton} py-2 px-4`}>{City}</label>}
    </div>
  );
};

function chunkArrayInGroups(arr, size) {
  var myArray = [];
  for (var i = 0; i < arr.length; i += size) {
    myArray.push(arr.slice(i, i + size));
  }
  return myArray;
}

export default Ambassadors;

const schools = [
  "Adamjee Govt. Science College, Karachi",
  "Islamia Govt. Science College, Karachi",
  "Govt. PECHS Education Foundation Science College, Karachi",
  "Govt. Degree Science & Commerce College, Gulshan-e-Iqbal, Karachi",
  "Govt. Degree Boys College, Gulzar-e-Hijri, Karachi",
  "Govt. National College, Karachi",
  "Govt. Degree Boys College, Gulistan-e-Jauhar, Karachi",
  "Govt. Jamia Millia Degree College, Karachi",
  "Govt. Superior Science College, Karachi",
  "Govt. Boys Inter College, Landhi, Karachi",
  "Allama Iqbal Govt. – Boys Science College, Karachi",
  "Govt Degree College, Malir Cantt, Karachi",
  "ARG Govt. Degree Boys College, Landhi, Quaidabad, Karachi",
  "Govt. Degree College Murad Memon Goth, Karachi",
  "Govt. Degree Boys College, Korangi, Karachi",
  "D.J Sindh Govt. Science College, Karachi",
  "S.M. Govt. Science College, Shahrah-e-Liaquat, Karachi",
  "Govt. Aisha Bawani College, Karachi",
  "Govt. Degree Science & Commerce College, Lyari, Karachi",
  "Haji Abdullah Haroon Govt. College, Karachi",
  "Govt. Delhi Inter Science College",
  "Govt. City College Musa Colony, Karachi",
  "Govt. Degree Science College, Liaquatabad, Karachi",
  "Govt. Sirajud Daula Degree College, Karachi",
  "Pakistan Shipowners Govt. College, Karachi",
  "Govt. Degree Boys College, North Karachi, Karachi",
  "Govt Degree Boys College, North Karachi",
  "Govt. Degree Boys College, Mangopir, Karachi",
  "Govt. Degree Boys College, Konkar, Karachi",
  "Govt. Degree Boys College, Surjani Town, Karachi",
  "Govt. Degree Boys College, New Karachi, Karachi",
  "Govt. Degree Boys College, Buffer Zone, Karachi",
  "Govt. Jinnah College, Nazimabad, Karachi",
  "Govt. Degree Science & Commerce College, Orangi Town, Karachi",
  "Govt. Degree Boys College Asifabad, Karachi",
  "Govt. Degree Boys College, Keamari, Karachi",
  "Govt. College For Men, Nazimabad, Karachi",
  "Govt. Degree Boys College, Shams Pir, Karachi",
  "Govt. Degree Boys College, Baldia Town, Karachi",
  "Govt. Degree Boys College,  KMC Store NIshter Road, Karachi",
  "Begum Nusrat Bhutto Govt. Boys College, Lyari, Karachi",
  "Govt. Degree Boys College, Ibrahim Hyderi, Karachi",
  "Govt. Boys H.S.S. Kamal Khan Jokhio, Karachi",
  "Ghazi Muhammad Bin Qasim H.S.S. Agra Taj Colony Chak,",
  "Allama Iqbal Govt. Boys H.S.S., Block 16, FB Area Karachi",
  "Govt. Comprehensive H.S.S, Korangi-0.3, Karachi",
  "Govt. Boys Comprehensive H.S.S., Azizabad, Karachi",
  "Govt. Boys H.S.S. No. 2 K, Area Korangi, Karachi",
  "Govt. Mumtaz Boys H.S.S. Landhi, Karachi",
  "Govt. Boys H.S.S. Konkar Village Malir Karachi",
  "Abdullah Govt. College for Women, Karachi",
  "Govt. College for Women, Nazimabad, Karachi",
  "Sir Syed Govt. Girls College, Karachi",
  "APWA Govt. College for Women, Karachi",
  "Shaheed-e-Millat Govt. Degree Girls College, Azizabad, Karachi",
  "Govt. Degree Girls College, Al-Noor, FB Area, Karachi",
  "Riaz Govt. Girls College, Liaquatabad, Karachi",
  "Govt. Degree College for Women, Nazimabad, Karachi",
  "Govt. Girls Degree College, North Karachi",
  "Govt. Girls Degree College, Nazimabad, Karachi",
  "Govt. College for Women, New Karachi, Karachi",
  "Govt. Girls Inter College Orangi Sector, Karachi",
  "Govt. Degree Girls College, Orangi, Karachi",
  "Govt. Girls College, Metroville SITE, Karachi",
  "Govt. College for Women, Shahrah-e-Liaquat, Karachi",
  "Govt. Inter Girls College, Lyari, Karachi",
  "Govt. Karachi College for Women, Chand Bibi Road, Karachi",
  "Govt. SMB Fatima Jinnah Girls College, Karachi",
  "Govt. Girls Degree College Zamzama, Karachi",
  "Govt. College for Women, Korangi 06, Karachi",
  "Govt, College for Women, Korangi-4, Karachi",
  "Govt. Girls Degree College, Korangi, Karachi",
  "Govt Degree Girls College, Ibrahim Hydri, Karachi",
  "Govt. College for Women Saudabad, Karachi",
  "Govt. Girls Science College, Shah Faisal Colony, Karachi",
  "Allama Iqbal Govt. Girls College, Shah Faisal Colony No. 3, Karachi",
  "Liaquat Govt. Girls College Malir, Karachi",
  "Govt. Degree College Malir Cantt, Karachi",
  "Govt. Girls College, Landhi, Karachi",
  "Govt. Girls College, PIB Colony, Karachi",
  "St. Lawrance Govt. College for Women, Karachi",
  "Govt. Degree Girls College, Lines Area, Karachi",
  "Bamm PECHS Govt. College for Women, Karachi",
  "Khatoon-e-Pakistan Government College for Women, Stadium Road",
  "Govt. Degree College for Boys and Girls Stadium Road, Karachi",
  "Govt. Degree Science and Commerce College, Gulshan-e-Iqbal, Karachi",
  "Govt. Girls Degree College, Mehmoodabad, Karachi",
  "Govt. Degree Girls College, Safora Goth, Gulistan-e-Jauhar, Karachi",
  "Govt. Degree Girls College, Pak Colony, Karachi",
  "Govt. Degree Girls College, Buffer Zone, Karachi",
  "Jan Muhammad Brohi Govt. Degree Girls College, Gulshah-e- Maymar, Karachi",
  "Govt. Degree Girls College, Surjani Town, Karachi",
  "Govt. Degree Girls College, Shahnawaz Shar Goth, Karachi",
  "Govt. Girls Degree College, Gulzar-e-Hijri, Karachi",
  "Marium Mukhtiar Govt. Girls Degree College, Liaquatabad, Karachi",
  "Bibi Asifa Govt. Girls College, Muzaffarabad, Landhi, Karachi",
  "Shaheed Benazir Bhutto Govt. Girls College, Lyari, Karachi",
  "Govt. Girls College, Sharrafi Goth, Karachi",
  "Govt. Girls H.S.S. Landhi, Karachi",
  "Govt. Comprehensive H.S.S, North Nazimabad, Karachi",
  "Govt. Girls H.S.S. Bhiro Village, Karachi",
  "APWA Govt. Girls H.S.S., Liaquatabad, Karachi",
  "PAF Govt. Girls H.S.S. Karachi",
  "Govt. Girls H.S.S. Green Belt, Karachi",
  "Khaldunia Public School, Islamabad",
  "The City School",
  "Bahria College",
  "Cadet College, Hassanabdal",
  "Army Public School",
  "Beaconhouse School",
  "Lahore Grammar School",
  "Nixor A-Levels",
  "Karachi Grammar School",
  "The Lyceum A-Levels",
  "Cedar A-Levels",
  "Cordoba School for A-Levels",
  "Dawood Public School A-Levels",
  "Aisha Bawany Academy",
  "Karachi Public School",
  "Pak-Turk International Schools and Colleges",
  "Bahria Foundation College, Nazimabad",
  "Foundation Public School",
  "St. Michaels Convent School",
  "St. Joseph’s Convent School",
  "Habib Public School",
  "Hamdard Public School",
  "Garrison Academy",
  "St. Anthony’s College",
  "St. Patrick’s High School",
  "St. Peter’s High School",
  "Progressive Public School",
  "DA Public School",
  "White House Grammar School",
  "Generation’s School",
  "The Avicenna School",
  "Froebel Education Centre",
  "Washington International School",
  "L’ecole for Advance Studies",
  "Karachi Cadet School",
  "Falconhouse Grammar School",
  "The OASYS School",
  "Westminster School & College",
  "Aitchison Model School",
  "Jaffar Public School",
  "K N Academy",
  "Ladybird Grammar School",
  "The American School",
  "Happy Home School",
  "Shaheen Public School",
  "Southshore School for A-Level Studies",
  "The Indus Academy",
  "Usman Public School",
  "The A.M.I School",
  "Anees Hussain, Karachi",
  "Meritorious Science College, Karachi",
  "Roots Millennium College, Karachi",
  "Dar-e-Arqam Schools",
  "Sadiq Public School",
  "Kinnaird College",
  "COMMECS College",
  "NCR-CET College",
  "Fazaia Inter College",
  "DHACSS Shaikh Khalifa Bin Zayed Campus",
  "Punjab Group of Colleges",
  "Private Intermediate",
  "Private A-Levels",
  "Allied School",
  "TCF School",
];
const cities = [
  "Abbottabad",
  "Adezai",
  "Ali Bandar",
  "Amir Chah",
  "Attock",
  "Ayubia",
  "Bahawalpur",
  "Baden",
  "Bagh",
  "Bahawalnagar",
  "Burewala",
  "Banda Daud Shah",
  "Bannu district|Bannu",
  "Batagram",
  "Bazdar",
  "Bela",
  "Bellpat",
  "Bhag",
  "Bhakkar",
  "Bhalwal",
  "Bhimber",
  "Birote",
  "Buner",
  "Burj",
  "Chiniot",
  "Chachro",
  "Chagai",
  "Chah Sandan",
  "Chailianwala",
  "Chakdara",
  "Chakku",
  "Chakwal",
  "Chaman",
  "Charsadda",
  "Chhatr",
  "Chichawatni",
  "Chitral",
  "Dadu",
  "Dera Ghazi Khan",
  "Dera Ismail Khan",
  "Dalbandin",
  "Dargai",
  "Darya Khan",
  "Daska",
  "Dera Bugti",
  "Dhana Sar",
  "Digri",
  "Dina City|Dina",
  "Dinga",
  "Diwana",
  "Dokri",
  "Drosh",
  "Duki",
  "Dushi",
  "Duzab",
  "Faisalabad",
  "Fateh Jang",
  "Ghotki",
  "Gwadar",
  "Gujranwala",
  "Gujrat",
  "Gadra",
  "Gajar",
  "Gandava",
  "Garhi Khairo",
  "Garruck",
  "Ghakhar Mandi",
  "Ghanian",
  "Ghauspur",
  "Ghazluna",
  "Girdan",
  "Gulistan",
  "Gwash",
  "Hyderabad",
  "Hala",
  "Haripur",
  "Hab Chauki",
  "Hafizabad",
  "Hameedabad",
  "Hangu",
  "Harnai",
  "Hasilpur",
  "Haveli Lakha",
  "Hinglaj",
  "Hoshab",
  "Islamabad",
  "Islamkot",
  "Ispikan",
  "Jacobabad",
  "Jamshoro",
  "Jhang",
  "Jhelum",
  "Jamesabad",
  "Jampur",
  "Janghar",
  "Jati(Mughalbhin)",
  "Jauharabad",
  "Jhal",
  "Jhal Jhao",
  "Jhatpat",
  "Jhudo",
  "Jiwani",
  "Jungshahi",
  "Karachi",
  "Kotri",
  "Kalam",
  "Kalandi",
  "Kalat",
  "Kamalia",
  "Kamararod",
  "Kamber",
  "Kamokey",
  "Kanak",
  "Kandi",
  "Kandiaro",
  "Kanpur",
  "Kapip",
  "Kappar",
  "Karak City",
  "Karodi",
  "Kashmor",
  "Kasur",
  "Katuri",
  "Keti Bandar",
  "Khairpur",
  "Khanaspur",
  "Khanewal",
  "Kharan",
  "kharian",
  "Khokhropur",
  "Khora",
  "Khushab",
  "Khuzdar",
  "Kikki",
  "Klupro",
  "Kohan",
  "Kohat",
  "Kohistan",
  "Kohlu",
  "Korak",
  "Korangi",
  "Kot Sarae",
  "Kotli",
  "Lahore",
  "Larkana",
  "Lahri",
  "Lakki Marwat",
  "Lasbela",
  "Latamber",
  "Layyah",
  "Leiah",
  "Liari",
  "Lodhran",
  "Loralai",
  "Lower Dir",
  "Shadan Lund",
  "Multan",
  "Mandi Bahauddin",
  "Mansehra",
  "Mian Chanu",
  "Mirpur",
  ", Pakistan|Moro",
  "Mardan",
  "Mach",
  "Madyan",
  "Malakand",
  "Mand",
  "Manguchar",
  "Mashki Chah",
  "Maslti",
  "Mastuj",
  "Mastung",
  "Mathi",
  "Matiari",
  "Mehar",
  "Mekhtar",
  "Merui",
  "Mianwali",
  "Mianez",
  "Mirpur Batoro",
  "Mirpur Khas",
  "Mirpur Sakro",
  "Mithi",
  "Mongora",
  "Murgha Kibzai",
  "Muridke",
  "Musa Khel Bazar",
  "Muzaffar Garh",
  "Muzaffarabad",
  "Nawabshah",
  "Nazimabad",
  "Nowshera",
  "Nagar Parkar",
  "Nagha Kalat",
  "Nal",
  "Naokot",
  "Nasirabad",
  "Nauroz Kalat",
  "Naushara",
  "Nur Gamma",
  "Nushki",
  "Nuttal",
  "Okara",
  "Ormara",
  "Peshawar",
  "Panjgur",
  "Pasni City",
  "Paharpur",
  "Palantuk",
  "Pendoo",
  "Piharak",
  "Pirmahal",
  "Pishin",
  "Plandri",
  "Pokran",
  "Pounch",
  "Quetta",
  "Qambar",
  "Qamruddin Karez",
  "Qazi Ahmad",
  "Qila Abdullah",
  "Qila Ladgasht",
  "Qila Safed",
  "Qila Saifullah",
  "Rawalpindi",
  "Rabwah",
  "Rahim Yar Khan",
  "Rajan Pur",
  "Rakhni",
  "Ranipur",
  "Ratodero",
  "Rawalakot",
  "Renala Khurd",
  "Robat Thana",
  "Rodkhan",
  "Rohri",
  "Sialkot",
  "Sadiqabad",
  "Safdar Abad- (Dhaban Singh)",
  "Sahiwal",
  "Saidu Sharif",
  "Saindak",
  "Sakrand",
  "Sanjawi",
  "Sargodha",
  "Saruna",
  "Shabaz Kalat",
  "Shadadkhot",
  "Shahbandar",
  "Shahpur",
  "Shahpur Chakar",
  "Shakargarh",
  "Shangla",
  "Sharam Jogizai",
  "Sheikhupura",
  "Shikarpur",
  "Shingar",
  "Shorap",
  "Sibi",
  "Sohawa",
  "Sonmiani",
  "Sooianwala",
  "Spezand",
  "Spintangi",
  "Sui",
  "Sujawal",
  "Sukkur",
  "Suntsar",
  "Surab",
  "Swabi",
  "Swat",
  "Tando Adam",
  "Tando Bago",
  "Tangi",
  "Tank City",
  "Tar Ahamd Rind",
  "Thalo",
  "Thatta",
  "Toba Tek Singh",
  "Tordher",
  "Tujal",
  "Tump",
  "Turbat",
  "Umarao",
  "Umarkot",
  "Upper Dir",
  "Uthal",
  "Vehari",
  "Veirwaro",
  "Vitakri",
  "Wadh",
  "Wah Cantt",
  "Warah",
  "Washap",
  "Wasjuk",
  "Wazirabad",
  "Yakmach",
  "Zhob",
  "Other",
];
