import HeaderImage from "../../../StaticImages/Blog/AKUTestBlogImage.png";
import QuadImage from "../../../StaticImages/Blog/QuadImage.png";
import UCImage from "../../../StaticImages/Blog/UCImage.png";
import CIMEImage from "../../../StaticImages/Blog/CIMEImage.png";
export var AcingAkuTestArticle = {
  HeaderImg: HeaderImage,
  Tags: ["Featured", "AKU"],
  Title: "Guide to Acing the AKU Test",
  Desc: "All the guidance you need to ace the AKU entrance exam with flying colours. Written by students who passed the AKU exam",
  Date: "May 1, 2022",
  AuthorName: <>Salvia Qazi &bull; Ahmad Shahid</>,
  Article: (
    <>
      <p>
        Most premed students, if not all, aim for the Aga Khan University as
        their dream medical school and to reach there, a hefty journey lies in
        front of them, including the AKU entry test. A long road lies ahead
        which contains several crisscrossing paths, that only flare your
        uncertainty and indecisiveness along with creating a black hole of
        questions, led by the most notorious of them all; Where to start? If you
        too are being persecuted by the same question then continue reading;
        we’re about to unravel it all, one step at a time.
      </p>

      <h2>Schedule</h2>
      <p>
        <span>
          <span>
            <span>
              Like with every other journey, first comes the schedule. When
              should you start preparing for the test and how? Before we go into
              further details you need to realise and understand that your board
              exams syllabus is going to be a big chunk of the AKU entry test
              syllabus so acing your board exams should be your utmost priority.
              Focusing on your board exams is very essential as there is more
              than one possible outcome of the AKU test results. That is why it
              is in your best interest to ace your board exams so you can have
              more opportunities at hand in case things go south. Plus,
              concentrating on your exams and thoroughly studying your courses
              will build up and strengthen your main concepts for the AKU test
              as well. as well.
            </span>
          </span>
        </span>
      </p>

      <p>
        <span>
          <span>
            <span>
              Alongside prioritizing your board exams, try to familiarise
              yourself with the Federal/Punjab board books so you can have a
              clear idea of what topics are similar to the AKU syllabus and what
              you will have to do additionally. Once you’re done with your
              exams, take a break for a day or two, rewire and relax your mind
              so you can start fresh. Taking a break and giving your mind some
              rest is extremely important! A lot of students tend to not give
              themselves the needed rest and it only results in them being burnt
              out, which subsequently affects their preparation. Hence, give
              yourself the time you need and then start with the A2/2nd-year
              syllabus first as you would have recently given your exams, and it
              will take a lot less time and effort to get done with it. Give
              yourself not more than two weeks for this and then move on to
              AS/1st-year concepts. AS syllabus specifically, is more or less
              filled with topics that require rote learning, rather than concept
              building, so it is a much better strategy to cover those a bit
              closer to the test date.
            </span>
          </span>
        </span>
      </p>
      <p>&nbsp;</p>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <img src={QuadImage} />
        <br />
        <p style={{ textAlign: "center" }}>
          The AKU Medical College has a completely different vibe at night
        </p>
      </div>
      <h2>Other Resources to Enhance Learning</h2>
      <p>
        Your test prep has already been started through your board syllabus
        revision and now you need additional books to refer to. Federal/Punjab
        board books are going to be a great help and will take your test
        preparation up a notch. You would have already gone through these books
        during your board exam preparation so now you would have an idea as to
        what topics are going to be a revision from your board concepts and what
        topics would require more attention and effort. This is why it is
        important to familiarise yourself with books, from other boards,
        beforehand. A Level syllabus varies quite significantly from the FSc
        board, so you must have a strong command over both the syllabi in order
        to ace the entry test and the MDCAT. <br />
        Moreover, SAT II can be of some additional help for review. Doing
        questions from there can be very useful as they are accurate examples of
        what will come in the AKU test. Princeton Review is the best option for
        Biology and Physics. For further review and practice, you can test
        yourself through the AKU Mock papers available on Premed.PK. This will
        tie up your preparation and ensure that no bits are left.
      </p>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <img src={UCImage} />
        <br />
        <p style={{ textAlign: "center" }}>
          The 17-storey University Centre which will house the Faculty of Arts
          Department at AKU. It also has a state-of-the-art library spread on
          one entire floor and a super modern cafeteria as well.
        </p>
      </div>
      <h2>Academies and Resources for Practice</h2>
      <p>
        Moving on to another question that you might come across is which
        academy should you join? Truthfully, academies are not as necessary as
        one might think they are. They too have their pros and cons that might
        vary from person to person. Academies cost a large sum of fortune and
        include wastage of time as a side product. Commuting itself takes a good
        amount of time plus, meeting friends and impulsively planned hangouts
        end up being a big distraction; one that you cannot afford at this
        crucial stage. More often than not, people look up to these academies
        because they offer a well enough routine to refer to, but other than
        that it is truly possible to study the same things on your own.
        Especially during such an era where the entire world has shifted to
        remote learning and you have books and practice questions merely a click
        away. Quite a few students who are currently attending AKU have shared
        with us their experience of preparing for the entry test on their own
        through reference books and PreMed’s question banks. In their opinion,
        the ‘topical’ feature at PreMed.PK comes in handy, as you don't have to
        wait till you cover the entire syllabus before starting past paper
        practice, you can do it side by side and conquer each paper one topic at
        a time. This way you can divide the workload of practicing past papers
        over several months rather than keeping past papers just for the end
        which lessens the burden quite significantly.
        <br />
        Though, if you would still wish to join an academy, Sir Hashaam’s
        “Heuser” ( https://heuserpk.com/) is a good enough option for AKU
        preparation. Other academies like Anees Hussain and Doctor’s Inn, though
        are good, but their focus is more on MDCAT. Sir Hushaam also offers an
        online Mock which is not that much of a help because it is based more on
        MDCAT questions, but he posts a free mock paper on Facebook which can be
        attempted as a final dusting to your preparation. Furthermore, when the
        time for the entry test rolls around, we will also upload some tips and
        tricks based on the experiences of those students who got into AKU, so
        we can get you covered in that section as well.
      </p>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <img src={CIMEImage} />
        <br />
        <p style={{ textAlign: "center" }}>
          A view of the Centre for Innovation in Medical Education (CIME)
          building at AKU where students can practise hands-on skills on
          mannequins and also develop patient interaction skills via clinical
          session classes.
        </p>
      </div>
      <h2>Last Words of Advice</h2>
      <p>
        Last but not least, give your very best. Working hard definitely pays
        off but working smart is just as necessary and useful. The route you may
        have chosen for yourself might be tough and full of difficulties but it
        is not impossible to conquer. The ones that have passed through before
        you and came out successful were no different than you. They got in
        through their hard work and persistence and so can you. As you go along
        this hectic journey, things might get overwhelming. Not just for you but
        for others as well who are with you in this. Support systems are an
        essential part of this journey, so not just seek help and guidance
        through fair means, but also provide and be there for the ones walking
        alongside you. There will be highs and lows but don't let temporary
        doubts creep in and shake your confidence or make you question your
        capabilities. Just keep working hard and getting back up each time you
        feel like giving up. Somedays, 24 hours will seem a lot less than what
        is needed and you might even question your decisions, but during such
        moments, close your eyes and take a deep breath; remember why you
        started this in the first place and remind yourself of what awaits at
        the other end of the road. Have faith in yourself and your efforts
        because we certainly do.
      </p>
    </>
  ),
};
