import React, { useState, useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import { Button } from "shards-react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";

import GradientBtn from "../GradientBtn/GradientBtn";
import { StylesContext } from "@material-ui/styles";

import styles from "./QuestionComponent.module.css";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function TestSkipQuestionComponent({
  OnCloseCB,
  OnSkipCB,
  numberOfQuestions,
  currentQuestion,
  isOpen,
  testPaper,
  blind,
}) {
  const [open, setOpen] = React.useState(isOpen);

  const [error, SetError] = React.useState(false);
  const [skipNo, SetSkipNo] = React.useState(1);

  const [pageNo, SetPageNo] = useState(1);
  const [QperPage, SetQperPage] = useState(50);

  const [prevEnabled, SetPrevEnabled] = useState(false);
  const [nextEnabled, SetNextEnabled] = useState(false);

  var questionList = [];

  testPaper.forEach((q, index) => {
    questionList.push({
      QNo: index + 1,
      Attempted: q.selectedOption ? true : false,
      Flagged: q.flagged,
      Correct: q.correct,
    });
  });

  var startIndex = 0 + QperPage * (pageNo - 1);
  var endIndex = QperPage * pageNo;
  var splicedquestionList = questionList.slice(startIndex, endIndex);

  useEffect(() => {
    if (pageNo == 1) {
      SetPrevEnabled(false);
    } else {
      SetPrevEnabled(true);
    }

    if (pageNo * QperPage >= questionList.length) {
      SetNextEnabled(false);
    } else {
      SetNextEnabled(true);
    }
  }, [pageNo]);

  function handlePrevPage() {}

  function handleNextPage() {}

  // (0,49)

  return (
    <div>
      <Dialog
        scroll="body"
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={OnCloseCB}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle
          id="alert-dialog-slide-title"
          style={{ textAlign: "center" }}
        >
          {"Skip to Question "}
        </DialogTitle>
        <DialogContent>
          <div className={styles.SkipGrid}>
            {splicedquestionList.slice().map((element, index) => {
              var qNo = index + 1;
              var classNameString = styles.SkipGridItem;
              if (element.Attempted) {
                classNameString = `${classNameString} ${styles.SkipGridItemAttempted}`;
              }
              if (element.Flagged) {
                classNameString = `${classNameString} ${styles.SkipGridItemFlagged}`;
              }
              if (element.Correct && !blind) {
                classNameString = `${classNameString} ${styles.SkipGridItemCorrect}`;
              }
              if (!element.Correct && element.Attempted && !blind) {
                classNameString = `${classNameString} ${styles.SkipGridItemWrong}`;
              }
              return (
                <div
                  className={classNameString}
                  key={`skipgrid-${element.QNo}`}
                  onClick={() => {
                    OnSkipCB(element.QNo);
                  }}
                >
                  {element.QNo}
                </div>
              );
            })}
          </div>
          <div className={styles.TestSkipNavButtons}>
            <span>
              <Button
                pill
                disabled={!prevEnabled}
                onClick={() => {
                  SetPageNo(pageNo - 1);
                }}
              >
                Prev
              </Button>
            </span>
            <span>
              <Button
                pill
                disabled={!nextEnabled}
                onClick={() => {
                  SetPageNo(pageNo + 1);
                }}
              >
                Next
              </Button>
            </span>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );

  function OnSubmit() {
    if (skipNo == currentQuestion) {
      OnCloseCB();
    } else {
      OnSkipCB(skipNo);
    }
  }
}
