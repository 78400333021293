import HeaderImage from "../../../StaticImages/Blog/NewPreMedLogo.png";
import FoundersImage from "../../../StaticImages/Blog/FoundersImage.png";
import UmarBoardImage from "../../../StaticImages/Blog/UmarBoardImage.png";
import HasnainCodingImage from "../../../StaticImages/Blog/HasnainCodingImage.png";
import TeamZoomImage from "../../../StaticImages/Blog/TeamZoomImage.png";
import CoreTeamImage from "../../../StaticImages/Blog/CoreTeamImage.png";
export var ReapplyingtoAKUArticle = {
  HeaderImg:
    "https://premedpk-cdn.sgp1.digitaloceanspaces.com/CDN/Blog/ReapplyingtoAKUBlogPic1.jpeg",
  Tags: ["Featured", "AKU"],
  Title: "Reapplying to AKU",
  Desc: "A balanced take by two med students from AKU on the various aspects to consider when deciding to reappear in the AKU test",
  Date: "December 10, 2022",
  AuthorName: <>Zara Widad &bull; Basil Rehman</>,
  Article: (
    <>
      <p>
        It’s been almost two weeks since the AKU results came out and we’ve
        gotten numerous messages on whether one should reapply to AKU and if so,
        what should their primary strategy be.
      </p>
      <p>
        To answer your questions, we interviewed two third year medical students
        at AKU who reapplied, to give an overview of how they came to the
        decision of reapplying and how they strategized their time. We have
        divided the blog into different aspects about the process and both
        students have given their two cents on it.
      </p>
      <h2>An Introduction to our Interviewees</h2>
      <p>
        Our first interviewee is <strong>Zara Widad</strong>, a Karachiite who
        completed her A Levels from Nixor College. On not clearing the AKU test
        the first time around, she started her MBBS degree at Dow Medical
        College, Karachi. She then reapplied in the year 2020 and got into AKU!
      </p>
      <p>
        Our second speaker is <strong>Basil Rehman</strong> from Gujranwala.
        <br />
        <br /> Here's Basil's introduction in his own words:
      </p>
      <p>
        <i>
          "A brief introduction about me, I couldn’t get into AKU the first time
          around so I had to reapply again the subsequent year to get in and I’m
          not the only one. Almost 25 students from the 2025 batch are
          reapplicants so those of you that are reapplying, don’t lose hope. You
          guys still have a great chance to get in. I was simultaneously
          enrolled in a government medical college in Punjab (Gujranwala Medical
          College) and I will be sharing my experience with you all. One of the
          most frequently asked questions is whether or not I should be
          reapplying to AKU, is it worth it? The answer I think is very
          subjective and not at all a simple one so I will try to cover the
          factors that need to be considered so you guys can make an informed
          decision."
        </i>
      </p>
      <h2>Studying/Academic wise:</h2>
      <p>
        <strong>Zara:</strong> The teaching style at AKU emphasizes more on
        team-based learning such as PBLs rather than the usual lecture-based
        method. These help in clarifying concepts and breaking down the large
        chunks of information into smaller objectives.
      </p>
      <p>
        <strong>Basil:</strong> In my opinion, AKU’s curriculum is very well
        suited to those that wish to choose the USMLE pathway. The first two
        years focus on all the 7 basic sciences unlike medical colleges in
        Punjab where usually only Anatomy, Biochemistry, and Physiology are
        taught in the first years. I have heard UHS is changing to a modular
        system as well so that might change soon.
      </p>
      <p>
        Now to explain what the hyped ‘modular system’ is, it is basically
        studying all the basic sciences subjects in context with a specific
        system in our body. An example could be the cardiovascular system,
        respiratory system, etc. This helps with grasping concepts in a better
        way and being able to relate the different sciences especially relating
        Pathology, Pharmacology, and Microbiology with Anatomy, Biochemistry,
        and Physiology and it is easier to make sense of things this way. It is
        also more interesting for eager first-year medical students to be
        learning about pathology and pharmacology and developing methodological
        thinking from the very start. It is further complemented by the PBLs and
        TBLs which are basically short group discussions that are a great way to
        actively recall whatever you’ve studied throughout the week and helps
        you look at things from different perspectives.
      </p>
      <p>
        If you’re not a person who benefits from lectures and prefers
        self-study, the lectures here at AKU are not mandatory (only the labs,
        PBLs, TBLs, and tutorials are). So, if you think there are better
        resources on the internet, or you’d do a better job at studying the same
        thing more efficiently from somewhere else at your own pace and time,
        AKU allows you to do that. The third year is somewhat easier with only
        ENT/Eye as the Proff rotation and gives you plenty of time throughout
        the year if you want to start stepping early.
      </p>
      <p>
        Another thing that I think gives AKU students an edge is having studied
        most of the content for step 1 in the first two years as compared to
        other colleges that cover the same syllabus over four years, so you’re
        better equipped with the knowledge needed to pass the Step 1 exam early.
        Also AKU does give a slot in the final year for electives abroad so in
        my opinion the curriculum in AKU is designed in a way that is very well
        suited to those that are planning to take the Step exams.
      </p>
      <h2>Student Life:</h2>
      <p>
        <strong>Zara:</strong> There is a strong emphasis on having a good work
        life balance therefore, a number of sports tournaments are held
        throughout the year along with a wide array of events. Just passing by
        the quad, you’d often see someone playing the guitar and having fun!
      </p>
      <p>
        <strong>Basil:</strong> Compared to GMC, AKU definitely has a very
        different and unique student life. The university sometimes itself hosts
        events such as beach plans and annual dinners to help everyone stay
        relaxed and also enjoy medical school to the fullest. But this also
        depends a lot on the person as there is no compulsion to attend these
        events, and due to the random mixing in PBLs and clinical groups, one
        still gets to interact with the entire batch.
      </p>
      <h2>Fee Structure:</h2>
      <p>
        <strong>Basil:</strong> The fee structure is something that you should
        definitely have a look at when reapplying which is available on the
        website and I’m sure most of you already have an idea about it (it
        increases every year so keep that in mind as well). The great thing
        about AKU is that it does provide you with financial aid and according
        to their website, almost 50% of the students are on financial aid. It is
        divided into grants and loans and you can read more about it on the
        website (www.aku.edu). Also, there are 5 scholarships available for
        students that get in and it’s been shifted from merit-based to
        need-based for the last two years as far as I know. The fee is obviously
        way higher than the government medical colleges but even If you compare
        it with the private medical colleges in Pakistan it is considerably
        higher even double in some cases so while applying and choosing between
        options you need to keep this factor in mind as well for practicality.
      </p>
      <h2>Opportunities including societies and research:</h2>
      <p>
        <strong>Basil:</strong> AKU has an amazing student body and functioning
        societies which is something that definitely sets it apart from other
        colleges. Compared to my previous college where I had to take an
        initiative to start our own student research society and had to try
        building things from scratch, in AKU all of the things are already up
        and running. Different interest groups are present where you can connect
        with other members and explore more about that field or just use it as a
        platform to socialize with seniors or alumni and build networks. Talks
        with the faculty and alumni are a very common occurrence and are perfect
        for learning about their journeys, any research they’ve been doing, or
        getting your questions answered. There is an office of career counseling
        that holds talks exploring different fields you can go on to both
        clinical and non-clinical.
      </p>
      <p>
        Since Step 1 has already gone past and Step 2 is expected in 2024, it
        will all come down to your CV and networking. AKU has opportunities for
        both. In the second year’s curriculum, a research module takes place
        where mentorship is provided by a faculty member and it is a great way
        to initiate your research journey. Apart from this, a lot of research
        opportunities are shared through interest groups and seniors. Also,
        different conferences are happening all the time, so you really have all
        the opportunities to boost your CV and make contacts. It is also a great
        experience being a part of such societies and hosting different events
        helps you learn a lot about event management and team skills and is an
        experience on its own.
      </p>
      <h3>Rotations and clinical experience:</h3>
      <p>
        <strong>Zara:</strong> Another important factor for me was the clinical
        experience. They complete the entire basic sciences studies in the first
        two years to just focus on hands-on clinical experience in the next
        three, which in my opinion is vital to put the knowledge acquired into
        practice. Since the class size is small, therefore a one on one
        interaction with the consultants further enhances the learning process.
        AKU has a buddy system where they match you up with seniors who have
        gone through the same rotation so it further helps in solidifying
        concepts and learning patient skills.
      </p>
      <h2>
        If one decides to reapply, how should they study for the AKU test again?
      </h2>
      <p>
        <strong>Zara:</strong> Preparing for the AKU test again sure is not an
        easy task since it requires a lot more discipline and drive to remain
        focused, yet achievable since one has a better idea on how to structure
        their preparation. Therefore, if one does decide to join a med school,
        their prime focus should be to study their respective curriculum, and
        then to begin preparing for the AKU entrance test two months prior to
        the exam, making sure to practice as many questions in order to build
        good concepts. It’s important not to let your med school studies get
        affected since the main aim is to become a good doctor irrespective of
        the institution.
      </p>
      <p>
        <strong>Basil:</strong> Honestly, if you have studied for the AKU Test
        once before, then it should be easier for you to give the exam again.
        You can choose to go through the content again from the respective board
        books but I would suggest you all to do maximum questions and give Mocks
        available online to see which chapters you are weak in so that you can
        focus solely on those. The AKU test is a lot about time management and
        being able to perform under pressure and since you all must have given
        the paper before, this will definitely come to your advantage. Your
        medical school performance might dip for 2 months, but start studying
        the medical school content as soon as you’re done with the AKU exam and
        you will be back up to pace in no time!
      </p>
      <h2>Conclusion</h2>
      <p>
        <strong>Zara:</strong> Though reapplying was a risk I took after
        weighing all my reasons, because it was quite possible not to get in,
        which could have taken a toll on me, so with keeping that in mind I
        decided to take this path. Therefore, this decision is very subjective
        depending on the kind of med school experience one would want to have.
      </p>
      <p>
        <strong>Basil:</strong> Cutting down on the generic, your goal should be
        to become a great doctor instead of getting into AKU and trust me that
        although there are a lot of great doctors out there that aren’t from
        AKU, it does provide you with a lot of great opportunities with all its
        societies and a vast alumni network but only for those that are willing
        to avail them. So, keep your hopes up and IA you’ll do great wherever
        you are destined to be.
      </p>
    </>
  ),
};
