import React, { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import usePracticeDeck from "./Hooks/usePracticeDeck";
import PracticeQuestionComponent from "./PracticeQuestionComponent";
import DeckStartComponent from "./DeckStartComponent";

import CircularProgress from "@material-ui/core/CircularProgress";
import Footer from "../NewFooter/Footer";

import { Modal, ModalBody, ModalHeader } from "shards-react";
import GradientBtn from "../../Components/GradientBtn/GradientBtn.jsx";
import ClearOutlinedIcon from "@material-ui/icons/ClearOutlined";

import { UserContext } from "../../userContext";

import DeckMetaInfo from "./YearlyDecksMeta";

export default function YearlyPracticeComponent() {
  const history = useHistory();
  const {
    deckName,
    questionNo,
    NextQuestion,
    PreviousQuestion,
    showStartPage,
    showQuestionPage,
    NoOfQuestions,
    currentQuestion,
    nextEnabled,
    backEnabled,
    loadingQuestion,
    onStartCB,
    SkipToQuestion,
    OnAttempt,
    RefreshQuestion,
  } = usePracticeDeck();

  const [user, SetUser] = useContext(UserContext);
  const isPremium = user.addonspurchased.includes("Premium");
  const [premiumModalOpen, SetPremiumModalOpen] = useState(false);

  const isFreeDeck = DeckMetaInfo[deckName]?.TutorModeFree;

  if (showQuestionPage && !isFreeDeck && !isPremium && !premiumModalOpen) {
    SetPremiumModalOpen(true);
  }

  return (
    <>
      <PremiumModal
        isOpen={premiumModalOpen}
        OnClick={() => {
          history.push("/plans");
        }}
        onClose={() => {
          SetPremiumModalOpen(false);
        }}
      />
      {!showStartPage && !showQuestionPage && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flexWrap: "wrap",
            height: "90vh",
            alignContent: "center",
          }}
        >
          <CircularProgress size={100} />
        </div>
      )}
      {showStartPage && (
        <DeckStartComponent
          Name={deckName}
          NoOfQuestions={NoOfQuestions}
          onStartCB={() => {
            if (isPremium || DeckMetaInfo[deckName].TutorModeFree) {
              onStartCB();
            } else {
              SetPremiumModalOpen(true);
            }
          }}
        />
      )}
      {showQuestionPage && (
        <PracticeQuestionComponent
          deckName={deckName}
          questionNo={questionNo}
          NextQuestion={NextQuestion}
          PreviousQuestion={PreviousQuestion}
          currentQuestion={currentQuestion}
          nextEnabled={nextEnabled}
          backEnabled={backEnabled}
          loadingQuestion={loadingQuestion}
          isExplanationPurchased={true}
          SkipToQuestion={SkipToQuestion}
          NoOfQuestions={NoOfQuestions}
          OnAttempt={OnAttempt}
          deckType={"Yearly"}
          isBlurred={!isFreeDeck && !isPremium}
          RefreshQuestion={RefreshQuestion}
        />
      )}
      <Footer />
    </>
  );
}

const PremiumModal = ({ isOpen, OnClick, onClose }) => {
  return (
    <Modal
      open={isOpen}
      toggle={() => {
        onClose();
      }}
    >
      <ModalHeader className="ShardsModalClosable">
        Premium Feature{" "}
        <div className={"ShardsModalCloseBtn"}>
          <ClearOutlinedIcon onClick={onClose} />
        </div>
      </ModalHeader>
      <ModalBody>
        <div
          style={{
            fontSize: "1.2em",
            textAlign: "center",
            fontFamily: "Rubik",
          }}
        >
          Your currrent plan does not have access to this Paper in{" "}
          <span style={{ color: "var(--QuestionBlue)" }}>Tutor Mode.</span>{" "}
          Switch to our{" "}
          <span style={{ color: "var(--RedAccent)" }}>Premium Plan</span> to
          access this feature!
          <br />
          <br />
          <GradientBtn
            style={{
              fontSize: "0.7em",
              borderRadius: "30px",
              margin: 0,
            }}
            gradient={"#D31027 0%, #EA384D  51%, #D31027  100%"}
            onClick={OnClick}
          >
            Purchase Premium Plan
          </GradientBtn>
        </div>
      </ModalBody>
    </Modal>
  );
};
