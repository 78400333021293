import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { Alert, Badge } from "shards-react";
import "bootstrap/dist/css/bootstrap.min.css";
import "shards-ui/dist/css/shards.min.css";
import styles from "./TopicalDeckStartComponent.module.css";
import { GetDeckDescription } from "../../APIManager";
import Lightbulbicon from "../../StaticImages/light-bulb.svg";

import TopicalModeToggle from "./TopicalModeToggle";

import { useHistory } from "react-router-dom";

import "./DeckStartComponent.css";

import GradientBtn from "../GradientBtn/GradientBtn";

import Bioenergetics from "../../StaticImages/Topical Icons/Biology/bioenergetic1.svg";
import BiologicalMolecules from "../../StaticImages/Topical Icons/Biology/biological molecules1.svg";
import BiologyanditsMajorFieldsofSpecialization from "../../StaticImages/Topical Icons/Biology/biology and its major field of specialization1.svg";
import Biotechnology from "../../StaticImages/Topical Icons/Biology/Biotechnology1.svg";
import CellCycle from "../../StaticImages/Topical Icons/Biology/cell cycle1.svg";
import CellStructureandFunction from "../../StaticImages/Topical Icons/Biology/Cell Structure and function1.svg";
import ChromosomesandDNA from "../../StaticImages/Topical Icons/Biology/chromosomes and DNA1.svg";
import CoordinationandControl from "../../StaticImages/Topical Icons/Biology/coordination and control2.svg";
import Enzymes from "../../StaticImages/Topical Icons/Biology/enzyme1.svg";
import Evolution from "../../StaticImages/Topical Icons/Biology/evolution1.svg";
import GaseousExchange from "../../StaticImages/Topical Icons/Biology/gaseous exchange1.svg";
import GrowthandDevelopment from "../../StaticImages/Topical Icons/Biology/growth and development1.svg";
import Homeostasis from "../../StaticImages/Topical Icons/Biology/homeostasis1.svg";
import KingdomAnimalia from "../../StaticImages/Topical Icons/Biology/kingdom animalia1.svg";
import KingdomFungi from "../../StaticImages/Topical Icons/Biology/kingdom fungi1.svg";
import KingdomPlantae from "../../StaticImages/Topical Icons/Biology/kingdom plantae1.svg";
import KingdomProkaryote from "../../StaticImages/Topical Icons/Biology/kingdom prokaryote2.svg";
import KingdomProtista from "../../StaticImages/Topical Icons/Biology/kingdom prokaryote1.svg";
import ManandHisEnvironment from "../../StaticImages/Topical Icons/Biology/Man and His Environment1.svg";
import Nutrition from "../../StaticImages/Topical Icons/Biology/Nutrition1.svg";
import Reproduction from "../../StaticImages/Topical Icons/Biology/reproduction1.svg";
import SupportandMovement from "../../StaticImages/Topical Icons/Biology/support and movement2.svg";
import Transport from "../../StaticImages/Topical Icons/Biology/transport2.svg";
import VariationandGenetics from "../../StaticImages/Topical Icons/Biology/Variation and genetics1.svg";
import VarietyofLife from "../../StaticImages/Topical Icons/Biology/variety of life1.svg";

import AcidsBasesandSalts from "../../StaticImages/Topical Icons/Chemistry/acids, bases and salts1.svg";
import AlcoholsPhenolsandEthers from "../../StaticImages/Topical Icons/Chemistry/alcohol1.svg";
import AldehydesandKetones from "../../StaticImages/Topical Icons/Chemistry/aldehydes and ketones1.svg";
import AlkylHalidesandAmines from "../../StaticImages/Topical Icons/Chemistry/alkyl halides and amines1.svg";
import AnalyticalChemistry from "../../StaticImages/Topical Icons/Chemistry/analytical chemistry3.svg";
import AtomicStructure from "../../StaticImages/Topical Icons/Chemistry/atomic structure1.svg";
import CarboxylicAcidsanditsDerivatives from "../../StaticImages/Topical Icons/Chemistry/acids, bases and salts2.svg";
import ChemicalBonding from "../../StaticImages/Topical Icons/Chemistry/chemical bonding1.svg";
import ChemicalEquilibrium from "../../StaticImages/Topical Icons/Chemistry/chemical equilibrium3.svg";
import ChemistryofLife from "../../StaticImages/Topical Icons/Chemistry/chemistry of life1.svg";
import Electrochemistry from "../../StaticImages/Topical Icons/Chemistry/electrochemistry1.svg";
import EnvironmentalChemistry from "../../StaticImages/Topical Icons/Chemistry/environmental chemistry3.svg";
import Hydrocarbons from "../../StaticImages/Topical Icons/Chemistry/hydrocarbon1.svg";
import IndustrialChemistry from "../../StaticImages/Topical Icons/Chemistry/industrial chemistry2.svg";
import PeriodicityinElements from "../../StaticImages/Topical Icons/Chemistry/periodicity2.svg";
import ReactionKinetics from "../../StaticImages/Topical Icons/Chemistry/reaction kinetics2.svg";
import SolutionandColloids from "../../StaticImages/Topical Icons/Chemistry/solution and colloids1.svg";
import StatesofMatter from "../../StaticImages/Topical Icons/Chemistry/states of matter1.svg";
import Stoichiometry from "../../StaticImages/Topical Icons/Chemistry/stoichiometry1.svg";
import Thermochemistry from "../../StaticImages/Topical Icons/Chemistry/thermochemistry1.svg";
import dandfBlockElements from "../../StaticImages/Topical Icons/Chemistry/d and f block elements1.svg";
import sandpBlockElements from "../../StaticImages/Topical Icons/Chemistry/s and p block1.svg";

import AlternatingCurrent from "../../StaticImages/Topical Icons/Physics/alternating current2.svg";
import AtomicSpectra from "../../StaticImages/Topical Icons/Physics/atomic spectra1.svg";
import CircularMotionandMomentum from "../../StaticImages/Topical Icons/Physics/circular motion and momentum2.svg";
import CurrentElectricity from "../../StaticImages/Topical Icons/Physics/current electricity1.svg";
import DawnofModernPhysics from "../../StaticImages/Topical Icons/Physics/dawn of modern physics1.svg";
import ElectromagneticWavesandElectronics from "../../StaticImages/Topical Icons/Physics/electromagneticwavesandelectronics11.svg";
import Electrostatics from "../../StaticImages/Topical Icons/Physics/electrostatics1.svg";
import FluidDynamics from "../../StaticImages/Topical Icons/Physics/fluiddynamics3.svg";
import ForcesandMotion from "../../StaticImages/Topical Icons/Physics/forcesandmotion1.svg";
import Gravitation from "../../StaticImages/Topical Icons/Physics/gravitation2.svg";
import HeatandThermodynamics from "../../StaticImages/Topical Icons/Physics/thermodynamics2.svg";
import MagnetismandElectromagneticInduction from "../../StaticImages/Topical Icons/Physics/magnetism and electromagnetic induction1.svg";
import Measurements from "../../StaticImages/Topical Icons/Physics/measurement1.svg";
import MotioninTwoDimensions from "../../StaticImages/Topical Icons/Physics/motion in two dimension1.svg";
import NuclearPhysics from "../../StaticImages/Topical Icons/Physics/nuclear1.svg";
import OpticsNatureofLightandOpticalInstruments from "../../StaticImages/Topical Icons/Physics/optics nature of light and optical instruments.svg";
import OscillationsandSimpleHarmonicMotion from "../../StaticImages/Topical Icons/Physics/oscillations and simple harmonic motion2.svg";
import PhysicsofSolids from "../../StaticImages/Topical Icons/Physics/physics of solids1.svg";
import ScalarsandVectors from "../../StaticImages/Topical Icons/Physics/scalars and vectors1.svg";
import WaveMotionandSound from "../../StaticImages/Topical Icons/Physics/wave motion and sound1.svg";
import WorkPowerandEnergy from "../../StaticImages/Topical Icons/Physics/work power and energy1.svg";

//Eng Icon Imports
import FillInTheBlanks from "../../StaticImages/Topical Icons/English/Fill in the Blanks.svg";
import GrammarAndPunctuation from "../../StaticImages/Topical Icons/English/Grammar and Punctuation.svg";
import IdentifyErrorsInSentences from "../../StaticImages/Topical Icons/English/Identify errors in sentences.svg";
import TensesAndSentenceStructure from "../../StaticImages/Topical Icons/English/Tenses and Sentence Structure.svg";
import Vocabulary from "../../StaticImages/Topical Icons/English/Vocabulary.svg";
import Passage from "../../StaticImages/Topical Icons/English/Passage.svg";

//Lr Icon Imports
import CauseAndEffect from "../../StaticImages/Topical Icons/Logical Reasoning/Cause and Effect.svg";
import CourseOfAction from "../../StaticImages/Topical Icons/Logical Reasoning/Course of Action.svg";
import CriticalThinking from "../../StaticImages/Topical Icons/Logical Reasoning/Critical Thinking.svg";
import LettersAndSymbolSeries from "../../StaticImages/Topical Icons/Logical Reasoning/Letters and Symbols Series.svg";
import LogicalDeduction from "../../StaticImages/Topical Icons/Logical Reasoning/Logical Deduction.svg";
import LogicalProblems from "../../StaticImages/Topical Icons/Logical Reasoning/Logical Problems.svg";

import AcademyModeIcon from "../../StaticImages/academymodeicon.svg";
import PastPaperModeIcon from "../../StaticImages/pastpapermodeicon.svg";

import {
  Card,
  CardHeader,
  CardTitle,
  CardImg,
  CardBody,
  CardFooter,
  Button,
  Fade,
  FormRadio,
} from "shards-react";

const DeckInfo = {
  name: "NUMS 2019 Paper",
  noQuestions: "200",
};

const engTopics = [
  "Key Vocabulary",
  "Tenses and Sentence Structure",
  "Grammar and Punctuation",
  "Fill in the blank",
  "Identify errors in sentence",
  "Passage",
];
const lrTopics = [
  "Critical Thinking",
  "Letters and Symbols Series",
  "Logical Deduction",
  "Logical Problems",
  "Course of Action",
  "Cause and Effect",
];

function QuizStartScreen({
  onStartCB,
  Name,
  NoOfQuestions,
  showResume,
  resumeNumber,
  resumeCB,
  isPastPaperTopicalMode,
}) {
  /*  const [Name , SetDeckName] = useState(props.deckName);
  const [Questions , SetQuestions] = useState(props.noQuestions); */
  const Questions = NoOfQuestions;
  const [deckDescription, SetDeckDescription] = useState("");

  const history = useHistory();

  useEffect(() => {
    GetDeckDescription(Name).then((res) => {
      SetDeckDescription(res.data);
      // console.log(res.data)
    });
  }, [Name]);

  function GetImgSrc(deck) {
    switch (deck) {
      case "Bioenergetics":
        return Bioenergetics;
        break;
      case "Biological Molecules":
        return BiologicalMolecules;
        break;
      case "Biology and its Major Fields of Specialization":
        return BiologyanditsMajorFieldsofSpecialization;
        break;
      case "Biology and its Major Fields of Specialization":
        return BiologyanditsMajorFieldsofSpecialization;
        break;
      case "Biotechnology":
        return Biotechnology;
        break;
      case "Cell Cycle":
        return CellCycle;
        break;
      case "Cell Structure and Function":
        return CellStructureandFunction;
        break;
      case "Chromosomes and DNA":
        return ChromosomesandDNA;
        break;
      case "Coordination and Control":
        return CoordinationandControl;
        break;
      case "Enzymes":
        return Enzymes;
        break;
      case "Evolution":
        return Evolution;
        break;
      case "Gaseous Exchange":
        return GaseousExchange;
        break;
      case "Growth and Development":
        return GrowthandDevelopment;
        break;
      case "Homeostasis":
        return Homeostasis;
        break;
      case "Kingdom Animalia":
        return KingdomAnimalia;
        break;
      case "Kingdom Fungi":
        return KingdomFungi;
        break;
      case "Kingdom Plantae":
        return KingdomPlantae;
        break;
      case "Kingdom Prokaryote":
        return KingdomProkaryote;
        break;

      case "Kingdom Protista":
        return KingdomProtista;
        break;

      case "Man and His Environment":
        return ManandHisEnvironment;
        break;

      case "Nutrition":
        return Nutrition;
        break;

      case "Reproduction":
        return Reproduction;
        break;

      case "Support and Movement":
        return SupportandMovement;
        break;

      case "Transport":
        return Transport;
        break;

      case "Variation and Genetics":
        return VariationandGenetics;
        break;

      case "Variety of Life":
        return VarietyofLife;
        break;

      case "Acids, Bases and Salts":
        return AcidsBasesandSalts;
        break;
      case "Alcohols, Phenols and Ethers":
        return AlcoholsPhenolsandEthers;
        break;
      case "Aldehydes and Ketones":
        return AldehydesandKetones;
        break;
      case "Alkyl Halides and Amines":
        return AlkylHalidesandAmines;
        break;
      case "Analytical Chemistry":
        return AnalyticalChemistry;
        break;
      case "Atomic Structure":
        return AtomicStructure;
        break;
      case "Carboxylic Acids and its Derivatives":
        return CarboxylicAcidsanditsDerivatives;
        break;
      case "Chemical Bonding":
        return ChemicalBonding;
        break;
      case "Chemical Equilibrium":
        return ChemicalEquilibrium;
        break;
      case "Chemistry of Life":
        return ChemistryofLife;
        break;
      case "Electrochemistry":
        return Electrochemistry;
        break;
      case "Environmental Chemistry":
        return EnvironmentalChemistry;
        break;
      case "Hydrocarbons":
        return Hydrocarbons;
        break;
      case "Industrial Chemistry":
        return IndustrialChemistry;
        break;
      case "Periodicity in Elements":
        return PeriodicityinElements;
        break;
      case "Reaction Kinetics":
        return ReactionKinetics;
        break;
      case "Solution and Colloids":
        return SolutionandColloids;
        break;
      case "States of Matter - Gases, Liquids and Solids":
          return StatesofMatter;
          break;
      case "Stoichiometry":
        return Stoichiometry;
        break;
      case "Thermochemistry":
        return Thermochemistry;
        break;
      case "d and f Block Elements":
        return dandfBlockElements;
        break;
      case "s and p Block Elements":
        return sandpBlockElements;
        break;

      case "Alternating Current":
        return AlternatingCurrent;
        break;
      case "Atomic Spectra":
        return AtomicSpectra;
        break;
      case "Circular Motion and Momentum":
        return CircularMotionandMomentum;
        break;
      case "Current Electricity":
        return CurrentElectricity;
        break;
      case "Dawn of Modern Physics":
        return DawnofModernPhysics;
        break;
      case "Electromagnetic Waves and Electronics":
        return ElectromagneticWavesandElectronics;
        break;
      case "Electrostatics":
        return Electrostatics;
        break;
      case "Fluid Dynamics":
        return FluidDynamics;
        break;
      case "Forces and Motion":
        return ForcesandMotion;
        break;
      case "Gravitation":
        return Gravitation;
        break;
      case "Heat and Thermodynamics":
        return HeatandThermodynamics;
        break;
      case "Magnetism and Electromagnetic Induction":
        return MagnetismandElectromagneticInduction;
        break;
      case "Measurements":
        return Measurements;
        break;
      case "Motion in Two Dimensions":
        return MotioninTwoDimensions;
        break;
      case "Nuclear Physics":
        return NuclearPhysics;
        break;
      case "Optics, Nature of Light and Optical Instruments":
        return OpticsNatureofLightandOpticalInstruments;
        break;
      case "Oscillations and Simple Harmonic Motion":
        return OscillationsandSimpleHarmonicMotion;
        break;
      case "Physics of Solids":
        return PhysicsofSolids;
        break;
      case "Scalars and Vectors":
        return ScalarsandVectors;
        break;
      case "Wave Motion and Sound":
        return WaveMotionandSound;
        break;
      case "Work, Power and Energy":
        return WorkPowerandEnergy;
        break;

      case "Fill in the blank":
        return FillInTheBlanks;
      case "Key Vocabulary":
        return Vocabulary;
      case "Identify errors in sentence":
        return IdentifyErrorsInSentences;
      case "Tenses and Sentence Structure":
        return TensesAndSentenceStructure;
      case "Grammar and Punctuation":
        return GrammarAndPunctuation;
      case "Passage":
        return Passage;

      case "Cause and Effect":
        return CauseAndEffect;
      case "Critical Thinking":
        return CriticalThinking;
      case "Letters and Symbols Series":
        return LettersAndSymbolSeries;
      case "Logical Deduction":
        return LogicalDeduction;
      case "Logical Problems":
        return LogicalProblems;
      case "Course of Action":
        return CourseOfAction;

      default:
        return Lightbulbicon;
    }
  }

  // console.log(props);

  const PracticeModeInfoBox = () => {
    return (
      <div className={styles.ModeInfoContainer}>
        <img src={AcademyModeIcon} />
        <br />
        <h3>Practice Questions</h3>
        <div>
          <div className={`d-flex ${styles.CardTextRow}`}>
            <i className={`bi bi-check ${styles.checkIcon}`}></i>
            <p className={`${styles.cardText} mb-0`}>
              This mode allows you to solve all the practice questions from{" "}
              <strong>well-known academies</strong>.
            </p>
          </div>
          <div className={`d-flex ${styles.CardTextRow}`}>
            <i className={`bi bi-check ${styles.checkIcon}`}></i>
            <p className={`${styles.cardText} mb-0`}>
              Experts at PreMed.PK recommend students to use this mode for
              deeper understanding of the topic.
            </p>
          </div>
          <div className={`d-flex ${styles.CardTextRow}`}>
            <i className={`bi bi-check ${styles.checkIcon}`}></i>
            <p className={`${styles.cardText} mb-0`}>
              It is recommended for students to use this mode after finishing a
              topic for <strong>extra practice</strong>.
            </p>
          </div>
        </div>
        <GradientBtn
          style={{
            fontSize: "0.8em",
            borderRadius: "30px",
            margin: 0,
            marginRight: "1em",
            padding: "1em 1.8em",
          }}
          gradient={"#02AAB0 0%, #00CDAC  51%, #02AAB0  100%"}
          onClick={() => {
            onStartCB();
          }}
        >
          Start Attempting Questions{" "}
        </GradientBtn>
      </div>
    );
  };

  const PastPaperModeInfoBox = () => {
    return (
      <div className={styles.ModeInfoContainer}>
        <img src={PastPaperModeIcon} />
        <br />
        <h3>Past Paper Questions</h3>
        <div>
          <div className={`d-flex ${styles.CardTextRow}`}>
            <i className={`bi bi-check ${styles.checkIcon}`}></i>
            <p className={`${styles.cardText} mb-0`}>
              This mode allows you to solve the questions that have repeated in
              previous year papers.
            </p>
          </div>
          <div className={`d-flex ${styles.CardTextRow}`}>
            <i className={`bi bi-check ${styles.checkIcon}`}></i>
            <p className={`${styles.cardText} mb-0`}>
              This mode is best suited for students to prepare you for
              exam-style questions that are tested.
            </p>
          </div>
          <div className={`d-flex ${styles.CardTextRow}`}>
            <i className={`bi bi-check ${styles.checkIcon}`}></i>
            <p className={`${styles.cardText} mb-0`}>
              It is recommended for students to practice these questions when
              your exam dates are near.
            </p>
          </div>
        </div>
        <GradientBtn
          style={{
            fontSize: "0.8em",
            borderRadius: "30px",
            margin: 0,
            marginRight: "1em",
            padding: "1em 1.8em",
          }}
          gradient={"#02AAB0 0%, #00CDAC  51%, #02AAB0  100%"}
          onClick={() => {
            onStartCB();
          }}
        >
          Start Attempting Questions{" "}
        </GradientBtn>
      </div>
    );
  };

  return (
    <div style={{ textAlign: "center", margin: "auto" }}>
      <div className={styles.MainDiv}>
        <div className={styles.DeckHeaderContainer}>
          <div>
            <img src={GetImgSrc(Name)} />
          </div>
          <div className={styles.DeckHeader}>
            <div className={styles.DeckName}>{Name}</div>
            <div className={styles.Questions}>{Questions} Questions</div>
            <TopicalModeToggle
              onPracticeClick={() => {
                history.push("/deck/topical/attempt?deck=" + Name);
              }}
              onPastPaperClick={() => {
                history.push("/deck/topical/pastpaper/attempt?deck=" + Name);
              }}
              PracticeActive
            />
          </div>
        </div>
        {isPastPaperTopicalMode ? (
          <PastPaperModeInfoBox />
        ) : (
          <PracticeModeInfoBox />
        )}
        {console.log(deckDescription)}
        {deckDescription &&
        !engTopics.includes(Name) &&
        !lrTopics.includes(Name) ? (
          <div className={styles.FactBox}>
            <div className={styles.DYKContainer}>
              <img src={Lightbulbicon}></img>
              <div>Did you know?</div>
            </div>
            <div className={styles.Fact}>{deckDescription}</div>
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default QuizStartScreen;

function RenderResumeQuestion(show, no, cb) {
  if (show) {
    return (
      <Button
        style={{ margin: "1em" }}
        theme="success"
        onClick={(e) => {
          cb();
        }}
      >
        Resume from question {no}
      </Button>
    );
  }
}
