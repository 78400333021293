import React from "react";
import hasnainimg from "../../StaticImages/TeamPhotos/Hasnain Pic.png";
import fahdimg from "../../StaticImages/TeamPhotos/Fahd Pic.png";
import illyunimg from "../../StaticImages/TeamPhotos/Illyun Pic.png";
import tahaimg from "../../StaticImages/TeamPhotos/Taha Pic.png";
import umarimg from "../../StaticImages/TeamPhotos/Umar Pic.png";
import nancyimg from "../../StaticImages/TeamPhotos/Nancy Pic.png";
import ahamadimg from "../../StaticImages/TeamPhotos/Ahmad Shahid Pic.png";
import danishimg from "../../StaticImages/TeamPhotos/Danish Pic.png";
import alishbaimg from "../../StaticImages/TeamPhotos/Alishba Anwar Pic.png";
import muinimg from "../../StaticImages/TeamPhotos/Mian Muin Pic.png";
import sibghaimg from "../../StaticImages/TeamPhotos/Sibgha Pic.png";
import hamadimg from "../../StaticImages/TeamPhotos/Hamad Pic.png";
import umerimg from "../../StaticImages/TeamPhotos/Umer Pic.png";
import saarahimg from "../../StaticImages/TeamPhotos/Saarah Pic.png";
import zaraimg from "../../StaticImages/TeamPhotos/Zara Pic.png";

import asadimg from "../../StaticImages/TeamPhotos/Asad Pic.png";
import anjaleenimg from "../../StaticImages/TeamPhotos/Anjaleen Pic.png";
import ehsanimg from "../../StaticImages/TeamPhotos/Ehsan Pic.png";
import ismailimg from "../../StaticImages/TeamPhotos/Ismail Pic.png";
import emanimg from "../../StaticImages/TeamPhotos/Eman Pic.png";

import womanimg from "../../StaticImages/TeamPhotos/WomanPic.png";
export var TeamMembers = [
  {
    Name: "Muhammad Hasnain Mankani",
    City: "Karachi",
    Role: "Brand Associate",
    Image: hasnainimg,
    Dept: "Core",
    School: "Aga Khan University",
  },
  {
    Name: "Fahd Niaz Shaikh",
    City: "Karachi",
    Role: "Brand Associate",
    Image: fahdimg,
    Dept: "Core",
    School: "DOW Medical College",
  },
  {
    Name: "Muhammad Umar Mahar",
    City: "Karachi",
    Role: "Brand Associate",
    Image: umarimg,
    School: "Aga Khan University",
  },
  {
    Name: "Taha Shaikh",
    City: "Karachi",
    Role: "Brand Associate",
    Image: tahaimg,
    Dept: "Core",
    School: "Aga Khan University",
  },
  {
    Name: "Illyun Banani",
    City: "Karachi",
    Role: "Brand Associate",
    Image: illyunimg,
    Dept: "Core",
    School: "Aga Khan University",
  },
  {
    Name: "Nancy Hemnani",
    City: "Karachi",
    Role: "Campus Associate",
    Image: nancyimg,
    School: "Cedar College",
    Dept: "Core",
  },
  {
    Name: "Asad Fatimi",
    City: "Karachi",
    Role: "Brand Associate",
    Image: asadimg,
    School: "Aga Khan University",
  },
  {
    Name: "Anjaleen Agrawalla",
    City: "Karachi",
    Role: "Brand Associate",
    Image: anjaleenimg,
    School: "Aga Khan University",
  },
  {
    Name: "Eshan Chawla",
    City: "Karachi",
    Role: "Brand Associate",
    Image: ehsanimg,
    School: "Aga Khan University",
  },
  {
    Name: "Ismail Khan",
    City: "Karachi",
    Role: "Brand Associate",
    Image: ismailimg,
    School: "Aga Khan University",
  },
  {
    Name: "Ahmad Shahid",
    City: "Karachi",
    Role: "Brand Associate",
    Image: ahamadimg,
    Dept: "Education",
    School: "DOW Medical College",
  },
  {
    Name: "Syeda Alishba Anwer",
    City: "Karachi",
    Role: "Brand Associate",
    Image: alishbaimg,
    Dept: "Education",
    School: "United Medical and Dental College",
  },
  {
    Name: "Mohammad Danish",
    City: "Karachi",
    Role: "Brand Associate",
    Image: danishimg,
    Dept: "Education",
    School: "DOW Medical College",
  },
  {
    Name: "Muin Jamshed",
    City: "Karachi",
    Role: "Campus Associate",
    Image: muinimg,
    School: "Nixor College",
    Dept: "Education",
  },
  {
    Name: "Sibgha Alam",
    City: "Mirpur",
    Role: "Campus Associate",
    Image: sibghaimg,
    Dept: "Education",
    School: "FFC Grammar School",
  },
  {
    Name: "Hamad",
    City: "Sahiwal",
    Role: "Campus Associate",
    Image: hamadimg,
    Dept: "Marketing",
    School: "Bloomfield Hall School",
  },
  {
    Name: "Umer Ejaz",
    City: "Karachi",
    Role: "Campus Associate",
    Image: umerimg,
    Dept: "Marketing",
    School: "Hamza Army Public School",
  },
  {
    Name: "Saarah Saeed",
    City: "Karachi",
    Role: "Camupus Associate",
    Image: saarahimg,
    Dept: "Marketing",
  },
  {
    Name: "Zara Sheikha",
    City: "Karachi",
    Role: "Campus Associate",
    Image: zaraimg,
    Dept: "Marketing",
    School: "Karachi Grammar School",
  },
  {
    Name: "Faiqa",
    Role: "Campus Associate",
    Image: womanimg,
    City: "Chakwal",
    School: "Fauji Foundation Inter College",
  },
  {
    Name: "Eman Alamgir",
    Role: "Campus Associate",
    Image: emanimg,
    School: "Punjab College",
    City: "Faisalabad",
  },
];
