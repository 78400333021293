export var AKUTestArticle = {
  HeaderImg:
    "https://premedpk-cdn.sgp1.cdn.digitaloceanspaces.com/CDN/Blog/AKUExamBlogPic1.jpg",
  Tags: ["Featured", "AKU"],
  Title: "AKU Exam Day Tips and Tricks",
  Desc: "Besides your knowledge, the Aga Khan University entrance test is a challenge of dealing with nerves, stress, and time management. We’ve outlined the ideal approach to ensure you give your best performance on the day of the exam!",
  Date: "June 12, 2022",
  AuthorName: <>Eman Anwar</>,
  Article: (
    <>
      <p>
        Hello AKU aspirants! The exam day is a daunting encounter and
        unfortunately some students take a long time to recover from the trauma
        of nerve-wracking anxiety, blanking out during the test, and filling the
        answer sheet bubbles haphazardly as time ran out. Exam stress is natural
        and everyone has their own coping mechanism. Figure out what strategy
        suits you and trust yourself, it will work out in the end. We’ve
        compiled some tips based on our own experiences to help you through it!
        <br />
        <ol>
          <li>
            Get a solid night’s sleep. Or if you’re the type to pull
            all-nighters, recharge on caffeine, because you will not get the
            chance to zone out, feel drowsy or tired in general. Skipping on
            breakfast because of nerves and nausea is fine, but have a light
            snack or chocolate for the sugar rush. Being alert is essential.
          </li>
          <br />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img
              style={{ maxHeight: "400px" }}
              src="https://premedpk-cdn.sgp1.cdn.digitaloceanspaces.com/CDN/Blog/AKUExamBlogPic2.jpg"
            />
          </div>
          <br />
          <li>
            Reach the exam centre on time. There will be a long queue outside
            and you do not want to stress about entering the hall in time.
          </li>
          <li>
            No need to bring stationery, the management provides free labelled
            AKU pouches to everyone (they will certainly remind you of that).
          </li>
          <li>
            {" "}
            They will also ask you to remove watches, so spend the 30-minute
            waiting period making sure you have a clear view of the clock, the
            desk is stable, and you’re comfortable with your seating. Ask the
            invigilator to accommodate you in case of issues, you should not
            spend time on it after the exam starts. <br /> <br />
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img
                style={{ maxHeight: "400px" }}
                src="https://premedpk-cdn.sgp1.cdn.digitaloceanspaces.com/CDN/Blog/AKUExamBlogPic3.jpg"
              />
            </div>
            <br />A brief overview of the exam pattern for further context: The
            AKU Test consists of 100 multiple-choice questions. It has five
            sections of Biology, Chemistry, Physics, Science Reasoning and
            Mathematics. The first three sections carry 0.25 negative marks for
            each incorrect answer, the last two have no negative marking.
          </li>
          <br />
          <li>
            The test is attempted with a pencil, which means you can change your
            answers if you have second thoughts after filling in an option.
          </li>
          <li>
            It would help to decide beforehand which section you want to attempt
            first, depending on whether there is negative marking or not. I
            would personally suggest attempting the Science Section first
            because the second part of the exam has no negative marking,
            supposing you have to rely on guesswork due to limited time.
          </li>
          <li>
            Time management is critical and you are given 2 hours to solve 100
            MCQs. Many students are not able to complete the paper due to lack
            of time. Some questions might not make sense, and it is best to
            leave them for the end. Allot a fixed duration for each question and
            do not go beyond that.
          </li>
          <br />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img
              style={{ maxHeight: "400px" }}
              src="https://premedpk-cdn.sgp1.cdn.digitaloceanspaces.com/CDN/Blog/AKUExamBlogPic4.jpg"
            />
          </div>
          <br />
          <li>
            If you get stuck on one MCQ, do not spend extra time on it.
            Especially if you feel that you know the question but cannot
            identify the right option. A few questions are incorrect and are
            removed during the post-exam analysis (about 10 MCQs in 2021).{" "}
          </li>
          <br />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img
              style={{ maxHeight: "400px" }}
              src="https://premedpk-cdn.sgp1.cdn.digitaloceanspaces.com/CDN/Blog/AKUExamBlogPic5.jpg"
            />
          </div>
          <br />
          <li>
            Apply the process of elimination while solving the paper. Cross out
            the incorrect options first, then focus on the remaining answers.
            Not only is this practice time-efficient, it also increases the
            likelihood of choosing the correct answer.
          </li>
          <li>
            {" "}
            Due to negative marking you will have to consider each question
            carefully. If you can eliminate two options, that gives you a 50
            percent chance of getting it right, so selecting an answer is the
            recommended practice. (Consult SAT general resources for a detailed
            guideline on how to attempt questions with negative marks.)
          </li>
          <li>
            You might have the time to recheck and attempt all questions, or you
            might not. Make sure that all the MCQs in the Math and Science
            reasoning sections are shaded by the end of the exam. No negative
            marking, remember!
          </li>
        </ol>
        <br />
        Hope this guide alleviates your anxiety regarding the entrance test. You
        can reach out to us for any personalised guidance. After the exam, there
        is no need to stress about your answers, just pray for the results. We
        wish you luck and hope you clear the test! Above all it was a fun,
        intellectually stimulating exam you don’t get from other institutions.
        The experience will be worth it!
      </p>
    </>
  ),
};
