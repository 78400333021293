import { getLoggedInUser } from "./APIManager";

export async function RefreshUserContext(SetUserContext, user) {
  const res = await getLoggedInUser();
  if (
    user.isLoggedIn === res.data.isloggedin &&
    user.username === res.data.username &&
    user.fullname === res.data.fullname &&
    user.phonenumber === res.data.phonenumber &&
    JSON.stringify(user.addonspurchased) ==
      JSON.stringify(res.data.addonspurchased) &&
    JSON.stringify(user.milestones) == JSON.stringify(res.data.milestones) &&
    JSON.stringify(user.notificationsread) ==
      JSON.stringify(res.data.notificationsread) &&
    JSON.stringify(user.otherinfo) == JSON.stringify(res.data.otherinfo)
  ) {
    console.log("NO Change in user data");
  } else {
    SetUserContext({
      isLoggedIn: res.data.isloggedin,
      username: res.data.username,
      fullname: res.data.fullname,
      phonenumber: res.data.phonenumber,
      city: res.data.city,
      school: res.data.school,
      accountcreateddate: res.data.accountcreateddate,
      accountstatus: res.data.accountstatus,
      subscriptionstatus: res.data.subscriptionstatus,
      subscriptionstartdate: res.data.subscriptionstartdate,
      subscriptionenddate: res.data.subscriptionenddate,
      freeuser: res.data.freeuser,
      purchasedmocks: res.data.purchasedmocks,
      addonspurchased: [...res.data.addonspurchased],
      milestones: [...res.data.milestones],
      notificationsread: [...res.data.notificationsread],
      otherinfo: { ...res.data.otherinfo },
      isadminuser: res.data.isadminuser,
      admin: { ...res.data.admin },
    });
    console.log(res);
    return res;
  }
}
