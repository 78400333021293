import React, { useState, useEffect, useCallback, useContext } from "react";
import usePracticeDeck from "./Hooks/usePracticeDeck";
import PracticeQuestionComponent from "./PracticeQuestionComponent";
import DeckStartComponent from "../../Pages/QuestionDeck/DeckStartComponent";

import { Modal, ModalBody, ModalHeader } from "shards-react";
import GradientBtn from "../GradientBtn/GradientBtn.jsx";

import CircularProgress from "@material-ui/core/CircularProgress";

import { UserContext } from "../../userContext";

import { useHistory, useParams, withRouter } from "react-router";
import { Prompt } from "react-router-dom";

import { GetTestPaper } from "../../APIManager";
import useTestDeck from "./Hooks/useTestDeck";
import useBookmarksDeck from "./Hooks/useBookmarksDeck";

import QuizStartScreen from "./BookmarksDeckStartComponent";
import TestModeOptionPanel from "./TestModeOptionPanel";
import TestModeQuestionComponent from "./TestModeQuestionComponent";
import TestModeDeckResult from "./TestModeDeckResult";
import TestModeReviewComponent from "./TestModeReviewComponent";

import Footer from "../NewFooter/Footer";

export default function BoolmarksModeComponent() {
  let search = window.location.search;
  let params = new URLSearchParams(search);

  const [user, setUser] = useContext(UserContext);
  const [premiumModalOpen, SetPremiumModalOpen] = useState(false);
  const isPremium = user.addonspurchased.includes("Premium");

  const history = useHistory();
  const deckName = params.get("deck");
  const {
    questionNo,
    NextQuestion,
    PreviousQuestion,
    showStartPage,
    showQuestionPage,
    downloadedPaper,
    NoOfQuestions,
    currentQuestion,
    nextEnabled,
    backEnabled,
    onStartCB,
    SkipToQuestion,
    OnAttempt,
    DeckName,
  } = useBookmarksDeck();

  console.log(currentQuestion);
  return (
    <>
      <PremiumModal
        isOpen={premiumModalOpen}
        OnClick={() => {
          history.push("/plans");
        }}
      />
      {showStartPage && (
        <QuizStartScreen
          Name={"Book Marks"}
          NoOfQuestions={NoOfQuestions}
          showResume={false}
          loading={!downloadedPaper}
          onStartCB={() => {
            if (isPremium) {
              onStartCB();
            } else {
              SetPremiumModalOpen(true);
            }
          }}
        />
      )}
      {showQuestionPage && (
        <PracticeQuestionComponent
          deckName={DeckName}
          questionNo={questionNo}
          NextQuestion={NextQuestion}
          PreviousQuestion={PreviousQuestion}
          currentQuestion={currentQuestion}
          nextEnabled={nextEnabled}
          backEnabled={backEnabled}
          loadingQuestion={false}
          isExplanationPurchased={
            user.addonspurchased.includes("Premium") || questionNo < 11
          }
          SkipToQuestion={SkipToQuestion}
          NoOfQuestions={NoOfQuestions}
          OnAttempt={OnAttempt}
          deckType={"Yearly"}
        />
      )}
      <Footer />
    </>
  );
}

const PremiumModal = ({ isOpen, OnClick }) => {
  return (
    <Modal
      open={isOpen}
      toggle={() => {
        //SetModalOpen(!modalOpen);
      }}
    >
      <ModalHeader>Premium Feature</ModalHeader>
      <ModalBody>
        <div
          style={{
            fontSize: "1.2em",
            textAlign: "center",
            fontFamily: "Rubik",
          }}
        >
          Your currrent plan does not have access to{" "}
          <span style={{ color: "var(--QuestionBlue)" }}>Timed Test Mode.</span>{" "}
          Switch to our{" "}
          <span style={{ color: "var(--RedAccent)" }}>Premium Plan</span> to
          access this feature!
          <br />
          <br />
          <GradientBtn
            style={{
              fontSize: "0.7em",
              borderRadius: "30px",
              margin: 0,
            }}
            gradient={"#D31027 0%, #EA384D  51%, #D31027  100%"}
            onClick={OnClick}
          >
            Purchase Premium Plan
          </GradientBtn>
        </div>
      </ModalBody>
    </Modal>
  );
};
