import React, { useState, createContext, useEffect, useContext } from "react";

import { GetNotifications } from "./APIManager";
import { UserContext } from "./userContext";
import { RefreshUserContext } from "./UtilityFunctions";

export const NotificationContext = createContext();

export const NotificationsProvider = (props) => {
  const [userContext, SetUserContext] = useContext(UserContext);
  const [notifications, setNotifications] = useState([]);
  useEffect(() => {
    GetNotifications().then((res) => {
      setNotifications(res.data);
    });
    const interval = setInterval(() => {
      GetNotifications().then((res) => {
        setNotifications(res.data);
      });
    }, 100000);
    return () => clearInterval(interval);
  }, []);

  return (
    <NotificationContext.Provider value={[notifications, setNotifications]}>
      {props.children}
    </NotificationContext.Provider>
  );
};
