import React, { useState, useEffect } from "react";
import parse from "html-react-parser";
import { Button } from "shards-react";

import styles from "./QuestionComponent.module.css";

import AddIcon from "@material-ui/icons/Add";
const QuizOptionButton = ({
  color,
  isSelected,
  optionLetter,
  optionText,
  onClick,
  showEliminate,
  hideEliminated,
}) => {
  const [crossed, SetCrossed] = useState(false);
  console.log(showEliminate);

  useEffect(() => {
    if (!showEliminate) {
      SetCrossed(false);
    }
  }, [showEliminate]);

  return (
    <div className={styles.OptionComponent} hidden={hideEliminated && crossed}>
      <span className={styles.OptionButtonContainer}>
        <div
          className={`${styles.OptionButtonAddIcon} ${
            crossed ? styles.OptionButtonAddIconPlus : null
          }`}
          hidden={!showEliminate}
        >
          <div
            onClick={() => {
              SetCrossed(!crossed);
            }}
          >
            <AddIcon />
          </div>
        </div>
        <Button
          className={styles.OptionButton}
          block={true}
          outline={!isSelected}
          theme={color}
          onClick={() => {
            onClick(optionLetter);
          }}
          disabled={crossed}
        >
          <span
            className={`${styles.OptionText} ${crossed ? styles.EliminatedOption : ""} `}
            style={{
              margin: "-3px",
            }}
          >
            {parse(
              optionLetter +
                ") " +
                optionText?.replace("<p>", "").replace("</p>", "")
            )}
          </span>
        </Button>{" "}
      </span>
    </div>
  );
};

export default QuizOptionButton;
