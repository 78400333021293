import React, { useState, useContext, useEffect } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { useLocation, useHistory } from "react-router-dom";
import axios from "axios";

import styles from "./dashboard.module.css";

import SideBar from "./Components/SideBar/SideBar";
import MyDashboard from "./Pages/MyDashboard/MyDashBoard";
import RecentActivity from "./Pages/Recent Activity/RecentActivity";
import SavedQuestions from "./Pages/Saved Questions/SavedQuestions";
import MyStats from "./Pages/My Stats/MyStats";
import MyAccount from "./Pages/My Account/MyAccount";
import MyNotifications from "./Pages/MyNotifications/MyNotifications";

//import Footer from "../../Components/Footer/Footer"
import Footer from "../../Components/NewFooter/Footer";

const Dashboard = () => {
  const location = useLocation();
  var mainbar = React.createRef();
  //console.log(mainbar)
  return (
    <div>
      <div className={styles.Dashboard_Main_Page}>
        <div className={styles.Dashboard_Side_Bar}>
          <SideBar />
        </div>
        <div className={styles.Dashboard_Main_Bar} id="mainbar">
          {RenderCurrentPage(location.pathname)}
          <Footer type="dash" />
        </div>
      </div>
    </div>
  );
};

function RenderCurrentPage(location) {
  if (location === "/dashboard") {
    return <MyDashboard />;
  } else if (location === "/recent-activity") {
    return <RecentActivity />;
  } else if (location === "/saved-questions") {
    return <SavedQuestions />;
  } else if (location === "/stats") {
    return <MyStats />;
  } else if (location === "/my-account") {
    return <MyAccount />;
  } else if (location === "/my-notifications") {
    return <MyNotifications />;
  } else {
    return "im not the dashboard";
  }
}

export default Dashboard;
