export const ReviewsList = [
  {
    UniLogo:
      "https://premedpk-cdn.sgp1.digitaloceanspaces.com/CDN/UniLogos/kemu.jpg",
    ReviewContent:
      "The website is very impressively organised. I would definitely recommend all the premedical students to use PreMed if they want to ace their entrance exams!",
    Name: "Aliha Shabbir",
    UniName: "King Edward Medical University",
    UniClass: "Class of 2026",
  },
  {
    UniLogo:
      "https://premedpk-cdn.sgp1.digitaloceanspaces.com/CDN/UniLogos/DUHS-Logo.gif",
    ReviewContent:
      "I was thoroughly impressed by this website. It gave me all the resources I needed for my MDCAT preparation and had really nice features as well. Thanks!",
    Name: "Muhammad Farooq",
    UniName: "Dow Medical College",
    UniClass: "Class of 2027",
  },
  {
    UniLogo:
      "https://premedpk-cdn.sgp1.digitaloceanspaces.com/CDN/UniLogos/allama%20iqbal.png",
    ReviewContent:
      "Came across your website a few days ago, it provides all the resources required by a premed student. I found it very helpful in preparing for my test. I am very thankful to the entire team behind this project, thank you once again.",
    Name: "Sadia",
    UniName: "Allama Iqbal Medical College",
    UniClass: "Class of 2027",
  },
  {
    UniLogo:
      "https://premedpk-cdn.sgp1.digitaloceanspaces.com/CDN/UniLogos/LUMHS.JPG",
    ReviewContent:
      "This website is a lifesaver. It enables you to strengthen your weaker aspects with topical MCQs of every chapter. Without a doubt, I would recommend this to all premed students if they want to ace their exams. It would not have been possible without PreMed.PK",
    Name: "Aliza Aamir",
    UniName: "LUMHS",
    UniClass: "Class of 2027",
  },
  {
    UniLogo:
      "https://premedpk-cdn.sgp1.digitaloceanspaces.com/CDN/UniLogos/gujranwala%20medical%20college.jpg",
    ReviewContent:
      "I was attempting the questions on the website and I absolutely love how they challenge you and really clear out the concepts.",
    Name: "Ayesha",
    UniName: "Gujranwala Medical College",
    UniClass: "Class of 2027",
  },
  {
    UniLogo:
      "https://premedpk-cdn.sgp1.digitaloceanspaces.com/CDN/UniLogos/kmdc.jpg",
    ReviewContent:
      "The website offers questions that reinforce important concepts through questions and help you go through syllabus content that might be missing from the textbook.",
    Name: "Rayyan",
    UniName: "Karachi Medical Dental College",
    UniClass: "Class of 2027",
  },
  {
    UniLogo:
      "https://premedpk-cdn.sgp1.digitaloceanspaces.com/CDN/UniLogos/umdclogo.png",
    ReviewContent:
      "Extremely helpful and one of the best websites for NMDCAT preparation. I appreciate your hardwork and am very thankful to you",
    Name: "Alif Ruhi",
    UniName: "United Medical and Dental College",
    UniClass: "Class of 2027",
  },
  {
    UniLogo:
      "https://premedpk-cdn.sgp1.digitaloceanspaces.com/CDN/UniLogos/shifa.jpg",
    ReviewContent:
      "An amazing platform, especially for those who are unable to join academies. So many resources in just a click!",
    Name: "Saad",
    UniName: "Shifa College of Medicine",
    UniClass: "Class of 2027",
  },
  {
    UniLogo:
      "https://premedpk-cdn.sgp1.digitaloceanspaces.com/CDN/UniLogos/ABWA-Medical-College-Logo.png",
    ReviewContent:
      "Really love and appreciate the initiative, extremely helpful and user-friendly. I have been religiously using the website for the past month.",
    Name: "Aishah Asim",
    UniName: "Abwa Medical College Faisalabad",
    UniClass: "Class of 2027",
  },
  {
    UniLogo:
      "https://premedpk-cdn.sgp1.digitaloceanspaces.com/CDN/UniLogos/LUMHS.JPG",
    ReviewContent:
      "This website is a lifesaver. It enables you to strengthen your weaker aspects with topical MCQs of every chapter. Without a doubt, I would recommend this to all premed students if they want to ace their exams. It would not have been possible without PreMed.PK",
    Name: "Aliza Aamir",
    UniName: "LUHMS",
    UniClass: "Class of 2027",
  },
  {
    UniLogo:
      "https://premedpk-cdn.sgp1.digitaloceanspaces.com/CDN/UniLogos/bolan%20medical%20college.jpg",
    ReviewContent:
      "A very impressive website which provided me an opportunity to learn online and practice thousands of MCQs. It will definitely help every student to use PreMed.PK.",
    Name: "Abida Nawab",
    UniName: "Bolan Medical College",
    UniClass: "Class of 2027",
  },
  {
    UniLogo:
      "https://premedpk-cdn.sgp1.digitaloceanspaces.com/CDN/UniLogos/JSMU.png",
    ReviewContent:
      "As someone with an A-levels background, PreMed.PK was the biggest help to me during my MDCAT times as learning books wasn't as easy. The questions helped me remember information much better and I loved how user-friendly the website is.",
    Name: "Eisha Sheikh",
    UniName: "Jinnah Sindh Medical University",
    UniClass: "Class of 2027",
  },
  {
    UniLogo:
      "https://premedpk-cdn.sgp1.digitaloceanspaces.com/CDN/UniLogos/akhtar%20medical%20dental%20college.png",
    ReviewContent:
      "This platform is a great support to the students which help them to achieve their dreams. Even if you are not enrolled at any academy, this website offers enough resources to help you excel in the MDCAT.",
    Name: "Ameema",
    UniName: "Akhtar Saeed Medical & Dental College",
    UniClass: "Class of 2027",
  },
  {
    UniLogo:
      "https://premedpk-cdn.sgp1.digitaloceanspaces.com/CDN/UniLogos/khyber%20medical%20dental%20college.jpg",
    ReviewContent:
      "This website is a gem in providing authentic stuff related to MDCAT. It has all the resources anyone would need, I highly recommend it to all the aspirants out there.",
    Name: "Arsalan Bakhtiyar",
    UniName: "Khyber Medical College Peshawar",
    UniClass: "Class of 2027",
  },
  {
    UniLogo:
      "https://premedpk-cdn.sgp1.digitaloceanspaces.com/CDN/UniLogos/gambat%20medical%20college.jpg",
    ReviewContent:
      "I've tried many platforms for MDCAT preperation but thank God I came across PreMed.PK. The thing I liked the most was the number and quality of the MCQs and the explanations you get for every MCQ and the chapter-wise drills",
    Name: "Ahmed Hassan",
    UniName: "Gambat Institute Of Medical Sciences (GIMS)",
    UniClass: "Class of 2027",
  },
  {
    UniLogo:
      "https://premedpk-cdn.sgp1.digitaloceanspaces.com/CDN/UniLogos/UHS-Logo.jpg",
    ReviewContent:
      "This website served to be very beneficial to me last year because it provided ample practice to me and helped me get all the relevant past papers in one place. I dedicate my admission into UHS to you! Thank You.",
    Name: "Muhammad Ismail",
    UniName: "University of Health Sciences, Lahore",
    UniClass: "Class of 2027",
  },

  /*   {
    UniLogo: "",
    ReviewContent: "I used your website, and it was truly an amazing experience. I'd like to thank your whole team for such amazing work. I spent nearly 7-10k for MDCAT resources but you solved all my problems free of cost with just one click. Thank You so much, may Allah bless you.",
    Name: "Asad",
    UniName: "",
    UniClass: "",
  }, */
];
