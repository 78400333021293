import React from "react";
import { BrowserRouter as Router, Route, Switch, Link } from "react-router-dom";

import styles from "./Footer.module.css";

import FBIcon from "../../StaticImages/SocialIcons/icons8-facebook-144.png";
import InstaIcon from "../../StaticImages/SocialIcons/icons8-instagram-144.png";
import TwitterIcon from "../../StaticImages/SocialIcons/icons8-twitter-144.png";

import privacyPolicy from "../../StaticImages/Privacy Policy.pdf";
import TAC from "../../StaticImages/Terms and Conditions.pdf";

export default function Footer() {
  return (
    <div
      class="container"
      style={{
        maxWidth: "100vw",
        paddingLeft: "0px",
        paddingRight: "0px",
      }}
    >
      <footer
        class="text-center text-lg-start text-white"
        style={{ "background-color": "#1c2331" }}
      >
        <section
          class="d-flex justify-content-between p-4"
          style={{ "background-color": "#D31027" }}
        >
          <div class="me-5"></div>

          <div>
            <a href="" class="text-white me-4">
              <i class="fab fa-facebook-f"></i>
            </a>
            <a href="" class="text-white me-4">
              <i class="fab fa-twitter"></i>
            </a>
            <a href="" class="text-white me-4">
              <i class="fab fa-google"></i>
            </a>
            <a href="" class="text-white me-4">
              <i class="fab fa-instagram"></i>
            </a>
            <a href="" class="text-white me-4">
              <i class="fab fa-linkedin"></i>
            </a>
            <a href="" class="text-white me-4">
              <i class="fab fa-github"></i>
            </a>
          </div>
        </section>

        <section class="" style={{ minHeight: "250px" }}>
          <div class="container text-center text-md-start mt-5">
            <div class="row mt-3">
              <div class="col-md-4 col-lg-5 col-xl-6 mx-auto mb-5">
                <h6
                  class="text-uppercase fw-bold"
                  className={styles.PreMedTitle}
                >
                  Pre<span style={{ color: "#EC5863" }}>M</span>ed.PK
                </h6>
                <hr
                  className={styles.UnderLine}
                  class=""
                  style={{
                    width: "8em",
                    "background-color": "#ED213A",
                    height: "2px",
                    marginTop: "-0.42em",
                  }}
                />
                <p style={{ textAlign: "left" }}>
                  We at PreMed.PK aim to facilitate students preparing for their
                  Medical and Dental College Aptitude Test (MDCAT) each year by
                  providing easy access to over 34,000 questions with authentic
                  and elaborate explanations.
                </p>
                <div className={styles.SocialMediaContainer}>
                  <a href="https://www.facebook.com/PreMed.PK" target="_blank">
                    {" "}
                    <img
                      className={styles.SocialMediaIcons}
                      src={FBIcon}
                    />{" "}
                  </a>
                  <a
                    href="https://www.instagram.com/premed.pk/"
                    target="_blank"
                  >
                    {" "}
                    <img className={styles.SocialMediaIcons} src={InstaIcon} />
                  </a>
                  <a href="https://twitter.com/premedpk?s=21" target="_blank">
                    {" "}
                    <img
                      className={styles.SocialMediaIcons}
                      src={TwitterIcon}
                    />
                  </a>
                </div>
              </div>
              <div class="col-md-2 col-lg-2 col-xl-2 mx-auto mb-4">
                <h6
                  class="text-uppercase fw-bold"
                  className={styles.ColumnTitle}
                >
                  About
                </h6>
                <hr
                  className={styles.UnderLine}
                  style={{
                    width: "60px",
                    "background-color": "#ED213A",
                    height: "2px",
                  }}
                />
                <p className={styles.ColumnLink}>
                  <Link to="/blog/1" className={styles.ColumnLink}>
                    Vision
                  </Link>
                </p>
                <p className={styles.ColumnLink}>
                  <Link to="/blog" className={styles.ColumnLink}>
                    Blog
                  </Link>
                </p>
                {/*  <p className={styles.ColumnLink}>
                  <Link to="/gallery" className={styles.ColumnLink}>
                    Gallery
                  </Link>
                </p> */}
                <p className={styles.ColumnLink}>
                  <Link to="/reviews" className={styles.ColumnLink}>
                    Reviews
                  </Link>
                </p>
              </div>

              <div class="col-md-3 col-lg-2 col-xl-2 mx-auto mb-4">
                <h6
                  class="text-uppercase fw-bold"
                  className={styles.ColumnTitle}
                >
                  Careers
                </h6>
                <hr
                  className={styles.UnderLine}
                  style={{
                    width: "4.8em",
                    "background-color": "#ED213A",
                    height: "2px",
                  }}
                />
                <p className={styles.ColumnLink}>
                  <Link to="/our-team" className={styles.ColumnLink}>
                    Meet the Team
                  </Link>
                </p>
                <p className={styles.ColumnLink}>
                  <Link to="/ambassadors" className={styles.ColumnLink}>
                    Ambassadors
                  </Link>
                </p>
                <p className={styles.ColumnLink}>
                  <a
                    target="_blank"
                    href="https://forms.gle/iB9skTugSoVs7gxf6"
                    className={styles.ColumnLink}
                  >
                    Join Us
                  </a>
                </p>
              </div>

              <div class="col-md-4 col-lg-3 col-xl-2 mx-auto mb-md-0 mb-4">
                <h6
                  class="text-uppercase fw-bold"
                  className={styles.ColumnTitle}
                >
                  Support
                </h6>
                <hr
                  className={styles.UnderLine}
                  style={{
                    width: "4.8em",
                    "background-color": "#ED213A",
                    height: "2px",
                  }}
                />
                <p className={styles.ColumnLink}>
                  <Link to="/contact-us" className={styles.ColumnLink}>
                    Contact Us
                  </Link>
                </p>
                <p className={styles.ColumnLink}>
                  <Link to="/contact-us" className={styles.ColumnLink}>
                    FAQs
                  </Link>
                </p>
                <p className={styles.ColumnLink}>
                  <Link to="/plans" className={styles.ColumnLink}>
                    Pricing
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </section>

        <div
          class="text-center p-3"
          style={{ "background-color": "rgba(0, 0, 0, 0.2)" }}
        >
          © 2020-2022, All Rights Reserved: <a class="text-white">PreMed.PK</a>
          <span style={{ marginLeft: "3ch" }}>
            <a href={privacyPolicy} className={styles.ColumnLink}>
              Privacy
            </a>
          </span>
          <span style={{ marginLeft: "3ch" }}>
            <a href={TAC} className={styles.ColumnLink}>
              Terms and Conditions
            </a>
          </span>
        </div>
      </footer>
    </div>
  );
}
