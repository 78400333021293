import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { Alert, Badge } from "shards-react";
import "bootstrap/dist/css/bootstrap.min.css";
import "shards-ui/dist/css/shards.min.css";
//import "./DeckStartComponent.css";
import styles from "../QuestionDeck/DeckStartComponent.module.css";
import { GetDeckDescription } from "../../APIManager";
import Lightbulbicon from "../../StaticImages/light-bulb.svg";

import {
  Card,
  CardHeader,
  CardTitle,
  CardImg,
  CardBody,
  CardFooter,
  Button,
  Fade,
  FormRadio,
} from "shards-react";
import NUMSLogo from "../../StaticImages/NUMS Logo.png";
import NTSLogo from "../../StaticImages/NTS Logo.png";
import UHSLogo from "../../StaticImages/UHS Logo.jpg";
import PMCLogo from "../../StaticImages/PMC Logo.png";
import AKULogo from "../../StaticImages/AKU Logo.png";

const DeckMetaInfo = {
  "PMC Mock 1": {
    Eng: 20,
    Bio: 68,
    Chem: 56,
    Phys: 56,
    Lr: 10,
    Time: 210,
  },
  "NUMS Mock 1": {
    Eng: 15,
    Bio: 60,
    Chem: 38,
    Phys: 37,
    Time: 150,
  },
  "AKU Mock Paper 1": {
    Bio: 20,
    Chem: 20,
    Phys: 20,
    ScienceReasoning: 20,
    MathReasoning: 20,
    Time: 120,
  },
  "AKU Mock Paper 2": {
    Bio: 20,
    Chem: 20,
    Phys: 20,
    ScienceReasoning: 20,
    MathReasoning: 20,
    Time: 120,
  },
  "PMC National MDCAT 2020": {
    Eng: 20,
    Bio: 80,
    Chem: 60,
    Phys: 40,
    Time: 150,
  },
  "TEST DECK": { Eng: 20, Bio: 80, Chem: 60, Phys: 40, Time: 150 },
  "Sindh MCAT NTS 2019": { Eng: 20, Bio: 80, Chem: 60, Phys: 40, Time: 150 },
  "Sindh MCAT NTS 2018": { Eng: 10, Bio: 30, Chem: 30, Phys: 30, Time: 120 },
  "Sindh MCAT NTS 2017": { Eng: 10, Bio: 30, Chem: 30, Phys: 30, Time: 120 },
  "Sindh MCAT NTS 2016 (SMBBMC Lyari)": {
    Eng: 10,
    Bio: 30,
    Chem: 30,
    Phys: 30,
    Time: 120,
  },
  "Sindh MCAT NTS 2016 (DUHS and JSMU)": {
    Eng: 10,
    Bio: 30,
    Chem: 30,
    Phys: 30,
    Time: 120,
  },
  "Sindh MCAT NTS 2015 (SMBBMC Lyari)": {
    Eng: 10,
    Bio: 30,
    Chem: 30,
    Phys: 30,
    Time: 120,
  },
  "Sindh MCAT NTS 2015 (DUHS and JSMU)": {
    Eng: 10,
    Bio: 30,
    Chem: 30,
    Phys: 30,
    Time: 120,
  },
  "Sindh MCAT NTS 2014": { Eng: 10, Bio: 30, Chem: 30, Phys: 30, Time: 120 },
  "Sindh MCAT NTS 2013": { Eng: 10, Bio: 30, Chem: 30, Phys: 30, Time: 120 },
  "Sindh MCAT NTS 2012": { Eng: 10, Bio: 30, Chem: 30, Phys: 30, Time: 120 },
  "Sindh MCAT NTS 2011": { Eng: 10, Bio: 30, Chem: 30, Phys: 30, Time: 120 },
  "Sindh MCAT NTS 2010": { Eng: 10, Bio: 30, Chem: 30, Phys: 30, Time: 120 },
  "NUMS 2020": { Eng: 20, Bio: 80, Chem: 60, Phys: 40, Time: 150 },
  "NUMS 2019 (Reconduct)": { Eng: 20, Bio: 80, Chem: 60, Phys: 40, Time: 150 },
  "NUMS 2019 (Cancelled)": { Eng: 20, Bio: 80, Chem: 60, Phys: 40, Time: 150 },
  "NUMS 2018": { Eng: 20, Bio: 80, Chem: 60, Phys: 40, Time: 150 },
  "NUMS 2017": { Eng: 20, Bio: 70, Chem: 45, Phys: 45, Time: 180 },
  "NUMS 2016": { Eng: 20, Bio: 70, Chem: 45, Phys: 45, Time: 180 },
  "NUMS 2015": { Eng: 20, Bio: 80, Chem: 60, Phys: 30, Lr: 10, Time: 180 },
  "NUMS 2014": { Eng: 20, Bio: 80, Chem: 60, Phys: 30, Lr: 10, Time: 180 },
  "PMC Sample Solved Paper 1": {
    Eng: 20,
    Bio: 68,
    Chem: 56,
    Phys: 56,
    Lr: 10,
    Time: 210,
  },
  "PMC Sample Solved Paper 2": {
    Eng: 20,
    Bio: 68,
    Chem: 56,
    Phys: 56,
    Lr: 10,
    Time: 210,
  },
  "PMC Sample Solved Paper 3": {
    Eng: 20,
    Bio: 68,
    Chem: 56,
    Phys: 56,
    Lr: 10,
    Time: 210,
  },
  "PMC Sample Solved Paper 4": {
    Eng: 20,
    Bio: 68,
    Chem: 56,
    Phys: 56,
    Lr: 10,
    Time: 210,
  },
  "PMC Sample Solved Paper 5": {
    Eng: 20,
    Bio: 68,
    Chem: 56,
    Phys: 56,
    Lr: 10,
    Time: 210,
  },
  "PMC Practice Test 1": {
    Eng: 20,
    Bio: 68,
    Chem: 56,
    Phys: 56,
    Lr: 10,
    Time: 210,
  },
  "PMC Practice Test 2": {
    Eng: 20,
    Bio: 68,
    Chem: 56,
    Phys: 56,
    Lr: 10,
    Time: 210,
  },
  "PMC Practice Test 3": {
    Eng: 20,
    Bio: 68,
    Chem: 56,
    Phys: 56,
    Lr: 10,
    Time: 210,
  },
  "PMC Practice Test 4": {
    Eng: 20,
    Bio: 68,
    Chem: 56,
    Phys: 56,
    Lr: 10,
    Time: 210,
  },
  "PMC Practice Test 5": {
    Eng: 20,
    Bio: 68,
    Chem: 56,
    Phys: 56,
    Lr: 10,
    Time: 210,
  },
  "PMC Practice Test 6": {
    Eng: 20,
    Bio: 68,
    Chem: 56,
    Phys: 56,
    Lr: 10,
    Time: 210,
  },
  "UHS MDCAT 2019": {
    Eng: 20,
    Bio: 80,
    Chem: 60,
    Phys: 40,
    Time: 150,
  },
  "UHS MDCAT 2018": {
    Eng: 30,
    Bio: 88,
    Chem: 58,
    Phys: 44,
    Time: 150,
  },
};

function QuizStartScreen({
  onStartCB,
  Name,
  NoOfQuestions,
  showResume,
  resumeNumber,
  resumeCB,
}) {
  /*  const [Name , SetDeckName] = useState(props.deckName);
const [Questions , SetQuestions] = useState(props.noQuestions); */
  const Questions = NoOfQuestions;

  useEffect(() => {
    GetDeckDescription(Name).then((res) => {
      //SetDeckDescription(res.data);
      // console.log(res.data)
    });
  }, [Name]);

  function GetImgSrc(deck) {
    if (!deck) {
      return NTSLogo;
    }
    if (deck.includes("PMC")) {
      return PMCLogo;
    }
    if (deck.includes("UHS")) {
      return UHSLogo;
    }
    if (deck.includes("NTS")) {
      return NTSLogo;
    }
    if (deck.includes("NUMS")) {
      return NUMSLogo;
    }
    if (deck.includes("AKU")) {
      return AKULogo;
    } else {
      return NTSLogo;
    }
  }

  // console.log(props);
  if (!Name) {
    return <></>;
  }
  return (
    <div style={{ textAlign: "center", margin: "auto" }}>
      <div className={styles.MainDiv}>
        <div className={styles.DeckHeaderContainer}>
          <div>
            <img src={GetImgSrc(Name)} />
          </div>
          <div className={styles.DeckHeader}>
            <div className={styles.DeckName}>{Name}</div>
            <div className={styles.Questions}>{Questions} Questions</div>
          </div>
        </div>
        <div style={{ lineHeight: "2em" }}>
          <br />
          <br />
          <Button
            theme="success"
            onClick={(e) => {
              onStartCB();
            }}
          >
            Start Test
          </Button>
          {RenderResumeQuestion(showResume, resumeNumber, resumeCB)}
          <br />
          <br />
        </div>
        <div className={styles.FactBox}>
          <div className={styles.DYKContainer}>
            <div>Instructions</div>
          </div>
          <div className={styles.Fact}>
            <ul>
              <li>
                {" "}
                This paper consists of a total of {Questions} Questions
                <ul>
                  {DeckMetaInfo[Name] ? (
                    DeckMetaInfo[Name].Eng ? (
                      <li>{DeckMetaInfo[Name].Eng} English</li>
                    ) : (
                      <></>
                    )
                  ) : (
                    <></>
                  )}
                  <li>
                    {DeckMetaInfo[Name] ? DeckMetaInfo[Name].Bio : 80} Biology
                  </li>
                  <li>
                    {DeckMetaInfo[Name] ? DeckMetaInfo[Name].Chem : 60}{" "}
                    Chemistry
                  </li>
                  <li>
                    {DeckMetaInfo[Name] ? DeckMetaInfo[Name].Phys : 40} Physics
                  </li>
                  {DeckMetaInfo[Name] ? (
                    DeckMetaInfo[Name].Lr ? (
                      <li>{DeckMetaInfo[Name].Lr} Logical Reasoning</li>
                    ) : (
                      <></>
                    )
                  ) : (
                    <></>
                  )}
                  {DeckMetaInfo[Name] ? (
                    DeckMetaInfo[Name].ScienceReasoning ? (
                      <li>
                        {DeckMetaInfo[Name].ScienceReasoning} Science Reasoning
                      </li>
                    ) : (
                      <></>
                    )
                  ) : (
                    <></>
                  )}
                  {DeckMetaInfo[Name] ? (
                    DeckMetaInfo[Name].MathReasoning ? (
                      <li>
                        {DeckMetaInfo[Name].MathReasoning} Mathematics Reasoning
                      </li>
                    ) : (
                      <></>
                    )
                  ) : (
                    <></>
                  )}
                </ul>
              </li>
              {DeckMetaInfo[Name] ? (
                DeckMetaInfo[Name].ScienceReasoning ? (
                  <li>
                    The Biology, Chemistry and Physics Sections in the AKU Test
                    are subject to negative marking (-0.25 per incorrect
                    question).
                  </li>
                ) : (
                  <></>
                )
              ) : (
                <></>
              )}
              <li>
                Since this is Test Mode, answers and explanations will be
                visible to review <strong>after</strong> finsishing the test.
              </li>
              <li>
                The time alloted for this paper was {DeckMetaInfo[Name]?.Time}{" "}
                minutes, hence, this Test Mode attempt will be timed as such.
              </li>
              <li>Each question has only one correct answer.</li>
              <li>
                You can flag questions, and come back to them using the skip
                feature.
              </li>
              <li>
                You can move between questions using left/right arrow keys too.
              </li>
              <li>You can save a question, and come back to it at any time.</li>
              {DeckMetaInfo[Name] ? (
                DeckMetaInfo[Name].ScienceReasoning ? (
                  <li>
                    Disclaimer: PreMed.PK and this mock are not affillilated
                    with AKU in anyway
                  </li>
                ) : (
                  <></>
                )
              ) : (
                <></>
              )}
              {DeckMetaInfo[Name] ? (
                Name === "PMC Mock 1" || "NUMS Mock 1" ? (
                  <></>
                ) : (
                  <li>
                    Some questions of this paper have been modified, as they
                    were cancelled that year.
                  </li>
                )
              ) : (
                <></>
              )}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default QuizStartScreen;

function RenderResumeQuestion(show, no, cb) {
  if (show) {
    return (
      <Button
        style={{ margin: "1em" }}
        theme="success"
        onClick={(e) => {
          cb();
        }}
      >
        Resume from question {no}
      </Button>
    );
  }
}
