import React, { useState, useContext, useEffect } from "react";
import styles from "./DeckStartComponent.module.css";
import { Badge } from "shards-react";
import { UserContext } from "../../userContext";
import {
  Button,
  FormInput,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
} from "shards-react";
import GradientBtn from "../../Components/GradientBtn/GradientBtn.jsx";

export default function YearlyModeToggle({
  onClick,
  TutorActive,
  isTutorPaid,
}) {
  const [user, setUser] = useContext(UserContext);
  const [modalOpen, SetModalOpen] = useState(false);
  const isPremium = user.addonspurchased.includes("Premium");

  function OnTimedClick() {
    onClick();
  }

  return (
    <div className={styles.YearlyModeToggle}>
      <Modal
        open={modalOpen}
        toggle={() => {
          SetModalOpen(!modalOpen);
        }}
      >
        <ModalHeader>Premium Feature</ModalHeader>
        <ModalBody>
          <div style={{ fontSize: "1.2em", textAlign: "center", color: "Red" }}>
            This feature is only available for Premium Users
            <br />
            <br />
            <GradientBtn
              style={{
                fontSize: "0.7em",
                borderRadius: "30px",
                margin: 0,
              }}
              gradient={"#D31027 0%, #EA384D  51%, #D31027  100%"}
              onClick={onClick}
            >
              Purchase Premium Plan
            </GradientBtn>
          </div>
        </ModalBody>
      </Modal>
      <div
        onClick={!TutorActive ? onClick : null}
        className={TutorActive ? styles.Active : ""}
      >
        Tutor Mode
        {isTutorPaid ? (
          <div className={`${styles.PillLabel} ${styles.PremiumLabel}`}>
            Premium
          </div>
        ) : (
          <div className={`${styles.PillLabel} ${styles.FreeLabel}`}>Free</div>
        )}
      </div>
      <div
        onClick={TutorActive ? OnTimedClick : null}
        className={!TutorActive ? styles.Active : ""}
      >
        Timed Test Mode
        <div className={`${styles.PillLabel} ${styles.PremiumLabel}`}>
          Premium
        </div>
      </div>
    </div>
  );
}
