import { ContactsOutlined } from "@material-ui/icons";
import React, { useEffect, useState, useContext } from "react";
import { BrowserRouter as Router, Route, Switch, Link } from "react-router-dom";
import { useHistory } from "react-router-dom";

import styles from "./styles.module.css";
import { Collapse, Button, Badge } from "shards-react";

import NUMSLogo from "../../StaticImages/NUMS Logo.png";
import NTSLogo from "../../StaticImages/NTS Logo.png";
import UHSLogo from "../../StaticImages/UHS Logo.jpg";
import PMCLogo from "../../StaticImages/PMC Logo.png";
import AKULogo from "../../StaticImages/PreMedAKULogo.svg";
import ETEALogo from "../../StaticImages/etealogo.jpg";
import FMDCLogo from "../../StaticImages/fmdclogo.jpg";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";

import YearlyPageIcon from "../../StaticImages/mockpageicon.png";

import PreMedIcon from "../../StaticImages/PreMedCircleLogo.png";

import { GetYearlyDecks } from "../../APIManager";
import { UserContext } from "../../userContext";

import { VerifyMockAddon } from "../../MockUtlityFunctions";

const pmcPapers = [
  /*   "PMC Sample Solved Paper 1",
  "PMC Sample Solved Paper 2",
  "PMC Sample Solved Paper 3",
  "PMC Sample Solved Paper 4", */
  "PMC Practice Test 1",
  "PMC Practice Test 2",
  "PMC Practice Test 3",
  "PMC Practice Test 4",
  "PMC Practice Test 5",
  "PMC Practice Test 6",
];

const premedMocks = [
  "PreMedPK MDCAT Mock 1",
  "PreMedPK MDCAT Mock 2",
  "PreMedPK MDCAT Mock 3",
  "PreMedPK MDCAT Mock 4",
];

const uhsPapers = ["UHS MDCAT 2019", "UHS MDCAT 2018"];
const numsPapers = [
  "NUMS 2020",
  "NUMS 2019 (Reconduct)",
  "NUMS 2019 (Cancelled)",
  "NUMS 2018",
  "NUMS 2017",
  "NUMS 2016",
  "NUMS 2015",
  "NUMS 2014",
];
const ntsPapers = [
  "Sindh MCAT NTS 2019",
  "Sindh MCAT NTS 2018",
  "Sindh MCAT NTS 2017",
  "Sindh MCAT NTS 2016 (SMBBMC Lyari)",
  "Sindh MCAT NTS 2016 (DUHS and JSMU)",
  "Sindh MCAT NTS 2015 (SMBBMC Lyari)",
  "Sindh MCAT NTS 2015 (DUHS and JSMU)",
  "Sindh MCAT NTS 2014",
  "Sindh MCAT NTS 2013",
  "Sindh MCAT NTS 2012",
  "Sindh MCAT NTS 2011",
  "Sindh MCAT NTS 2010",
];

const eteaPapers = [];
const fmdcPapers = [
  "FMDC 2018",
  "FMDC/HEC Phase 2 2017",
  "FMDC/HEC Phase 1 2017",
  "FMDC 2016",
  "FMDC 2015",
  "FMDC 2014",
  "FMDC 2013",
  "FMDC 2012",
];

const MocksPage = () => {
  let history = useHistory();
  let [userContext, SetUserContext] = useContext(UserContext);

  const [numsCollapse, SetNumsCollapse] = useState(false);
  const [ntsCollapse, SetNtsCollapse] = useState(false);
  const [uhsCollapse, SetUhsCollapse] = useState(false);
  const [pmcCollapse, SetPmcCollapse] = useState(false);
  const [akuCollapse, SetAkuCollapse] = useState(false);
  const [eteaCollapse, SetEteaCollapse] = useState(false);
  const [fmdcCollapse, SetFmdcCollapse] = useState(false);

  const [premedCollapse, SetPremedCollapse] = useState(false);

  const [ntsDecks, SetNtsDecks] = useState(ntsPapers);
  const [numsDecks, SetNumsDecks] = useState(numsPapers);
  const [uhsDecks, SetUhsDecks] = useState([]);
  const [pmcDecks, SetPmcDecks] = useState(pmcPapers);
  const [eteaDecks, SetEteaDecks] = useState(eteaPapers);
  const [fmdcDecks, SetFmdcDecks] = useState(fmdcPapers);

  var akuPapers = [
    {
      Name: "AKU Mock Paper 1",
      BadgeText: "Free",
      onBadgeClick: () => {},
      isPurchased: true,
    },
    {
      Name: "AKU Mock Paper 2",
      BadgeText: "",
      onBadgeClick: () => {},
      isPurchased: VerifyMockAddon(
        "AKU Mock Paper 2",
        userContext.addonspurchased
      ),
    },
    {
      Name: "AKU Mock Paper 3",
      BadgeText: "",
      onBadgeClick: () => {},
      isPurchased: VerifyMockAddon(
        "AKU Mock Paper 3",
        userContext.addonspurchased
      ),
    },
    {
      Name: "AKU Mock Paper 4",
      BadgeText: "",
      onBadgeClick: () => {},
      isPurchased: VerifyMockAddon(
        "AKU Mock Paper 4",
        userContext.addonspurchased
      ),
    },
    {
      Name: "AKU Mock Paper 5",
      BadgeText: "",
      onBadgeClick: () => {},
      isPurchased: VerifyMockAddon(
        "AKU Mock Paper 5",
        userContext.addonspurchased
      ),
    },
  ];

  var numsPapers = [
    {
      Name: "NUMS Mock Paper 1",
      BadgeText: "Free",
      onBadgeClick: () => {},
      isPurchased: true,
    },
    {
      Name: "NUMS Mock Paper 2",
      BadgeText: "",
      onBadgeClick: () => {},
      isPurchased: VerifyMockAddon(
        "NUMS Mock Paper 2",
        userContext.addonspurchased
      ),
    },
    {
      Name: "NUMS Mock Paper 3",
      BadgeText: "",
      onBadgeClick: () => {},
      isPurchased: VerifyMockAddon(
        "NUMS Mock Paper 3",
        userContext.addonspurchased
      ),
    },
    {
      Name: "NUMS Mock Paper 4",
      BadgeText: "",
      onBadgeClick: () => {},
      isPurchased: VerifyMockAddon(
        "NUMS Mock Paper 4",
        userContext.addonspurchased
      ),
    },
  ];

  useEffect(() => {
    GetYearlyDecks().then((res) => {
      var nts = [];
      var uhs = [];
      var nums = [];
      var pmc = [];
      res.data.forEach((deck) => {
        if (deck.Group === "SINDH MCAT NTS") {
          nts.push(deck.Name);
        } else if (deck.Group === "UHS MDCAT") {
          uhs.push(deck.Name);
        } else if (deck.Group === "NUMS MDCAT") {
          nums.push(deck.Name);
        } else if (deck.Group === "PMC National MDCAT") {
          pmc.push(deck.Name);
        }
      });
      nts.sort().reverse();
      uhs.sort().reverse();
      nums.sort().reverse();
      pmc.sort((a, b) => {
        return pmcPapers.indexOf(a) - pmcPapers.indexOf(b);
      });
      SetNtsDecks(nts);
      SetUhsDecks(uhs);
      SetNumsDecks(nums);
      SetPmcDecks(pmc);
    });
  }, []);

  return (
    <div className={styles.GradientDiv}>
      <div className={styles.MainDiv}>
        <div className={styles.ContentDiv}>
          <div className={styles.PageHeader}>
            <h1>
              <span>
                <img src={YearlyPageIcon} />
              </span>
              <span>Mocks</span>
            </h1>
            <h4>
              Hey
              {userContext.fullname
                ? ` ${userContext.fullname.split(" ").slice(0, 2).join(" ")}! `
                : "! "}
              Check out these mocks designed by us to help you assess your
              preparation. These papers are as close to what you will get on the
              exam day!
            </h4>
          </div>

          <div className={styles.GroupsContainer}>
            <div className={styles.Group}>
              <div
                className={styles.GroupHeader}
                onClick={() => {
                  SetPremedCollapse(!premedCollapse);
                }}
              >
                <img
                  src={PreMedIcon}
                  className={styles.LogoImage}
                  style={{ width: "7em" }}
                />
                <span
                  className={styles.GroupName}
                  style={{ lineHeight: "0.9em", fontSize: "2.3em" }}
                >
                  MDCAT Mocks (4 Papers) <br />
                  <div
                    style={{
                      fontSize: "0.4em",
                      marginBottom: "-0.5em",
                      marginTop: "0.2em",
                      lineBreak: "normal",
                      whiteSpace: "normal",
                      lineHeight: "1.2em",
                    }}
                  >
                    MDCAT Mocks are made according to the latest PMC MDCAT 2022
                    syllabus and are valid for students from all provinces.
                  </div>
                </span>
                <div className={styles.ArrowContainer}>
                  <div className={styles.ArrowContainer}>
                    <span
                      className={`${styles.ArrowIcon} ${
                        premedCollapse ? styles.ArrowUp : styles.ArrowIcon
                      }`}
                      style={{ width: "fitContent" }}
                    >
                      <ArrowDropDownIcon
                        style={{ fontSize: "6em" }}
                        onClick={() => {
                          SetNumsCollapse(!premedCollapse);
                        }}
                      />
                    </span>
                  </div>
                </div>
              </div>
              <Collapse open={premedCollapse}>
                <div className={styles.PapersContainer}>
                  {premedMocks.map((deck) => (
                    <div key={deck.Name} className={styles.Paper}>
                      <div className={styles.PaperName}>
                        <Link
                          to={
                            userContext.addonspurchased.includes("Premium")
                              ? `/mocks`
                              : "/mocks"
                          }
                          style={{ marginRight: "0.2em" }}
                        >
                          {deck}
                        </Link>
                        <span className={styles.PaperNameBadgeSpan}>
                          {" "}
                          <label className={`${styles.premiumLabel} py-2 px-4`}>
                            {userContext.addonspurchased.includes("Premium")
                              ? "Closed"
                              : "Closed"}
                          </label>
                        </span>
                      </div>
                      <hr />
                    </div>
                  ))}
                </div>
              </Collapse>
            </div>
            <div className={styles.Group}>
              <div
                className={styles.GroupHeader}
                onClick={() => {
                  SetNumsCollapse(!numsCollapse);
                }}
              >
                <img
                  src={NUMSLogo}
                  className={styles.LogoImage}
                  style={{ width: "7em" }}
                />
                <span
                  className={styles.GroupName}
                  style={{ lineHeight: "0.9em", fontSize: "2.3em" }}
                >
                  NUMS Mocks (4 Papers) <br />
                </span>
                <div className={styles.ArrowContainer}>
                  <div className={styles.ArrowContainer}>
                    <span
                      className={`${styles.ArrowIcon} ${
                        numsCollapse ? styles.ArrowUp : styles.ArrowIcon
                      }`}
                      style={{ width: "fitContent" }}
                    >
                      <ArrowDropDownIcon
                        style={{ fontSize: "6em" }}
                        onClick={() => {
                          SetNumsCollapse(!numsCollapse);
                        }}
                      />
                    </span>
                  </div>
                </div>
              </div>
              <Collapse open={numsCollapse}>
                <div className={styles.PapersContainer}>
                  {numsPapers.map((deck) => (
                    <div key={deck.Name} className={styles.Paper}>
                      <div className={styles.PaperName}>
                        <Link
                          to={deck.isPurchased ? `/mocks` : "/mocks"}
                          style={{ marginRight: "0.2em" }}
                        >
                          {deck.Name}
                        </Link>
                        <span className={styles.PaperNameBadgeSpan}>
                          {" "}
                          <label className={`${styles.premiumLabel} py-2 px-4`}>
                            Closed
                          </label>
                        </span>
                      </div>
                      <hr />
                    </div>
                  ))}
                </div>
              </Collapse>
            </div>

            <div className={styles.Group}>
              <div
                className={styles.GroupHeader}
                onClick={() => {
                  SetAkuCollapse(!akuCollapse);
                }}
              >
                <img
                  src={AKULogo}
                  className={styles.LogoImage}
                  style={{ width: "18em" }}
                />
                <span
                  className={styles.GroupName}
                  style={{ lineHeight: "0.9em", fontSize: "2.3em" }}
                >
                  AKU Mocks (5 Papers) <br />
                  <div style={{ fontSize: "0.4em", marginBottom: "-0.5em" }}>
                    Disclaimer: PreMed.PK is not officially affiliated with AKU{" "}
                  </div>
                </span>
                <div className={styles.ArrowContainer}>
                  <div className={styles.ArrowContainer}>
                    <span
                      className={`${styles.ArrowIcon} ${
                        akuCollapse ? styles.ArrowUp : styles.ArrowIcon
                      }`}
                      style={{ width: "fitContent" }}
                    >
                      <ArrowDropDownIcon
                        style={{ fontSize: "6em" }}
                        onClick={() => {
                          SetAkuCollapse(!akuCollapse);
                        }}
                      />
                    </span>
                  </div>
                </div>
              </div>
              <Collapse open={akuCollapse}>
                <div className={styles.PapersContainer}>
                  {akuPapers.map((deck) => (
                    <div key={deck.Name} className={styles.Paper}>
                      <div className={styles.PaperName}>
                        <Link
                          to={deck.isPurchased ? `/mocks` : "/mocks"}
                          style={{ marginRight: "0.2em" }}
                        >
                          {deck.Name}
                        </Link>
                        <span className={styles.PaperNameBadgeSpan}>
                          {" "}
                          <label className={`${styles.premiumLabel} py-2 px-4`}>
                            Closed
                          </label>
                        </span>
                      </div>
                      <hr />
                    </div>
                  ))}
                </div>
              </Collapse>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MocksPage;
