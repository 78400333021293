import React from "react";
import cssstyles from "./GradientBtn.module.css";

export default function GradientBtn({
  gradient,
  children,
  style,
  onClick,
  disabled,
  className,
}) {
  return (
    <button
      style={{
        ...style,
        "background-image": `linear-gradient(
        to right,
       ${gradient}
      )`,
      }}
      className={`${cssstyles.btnGrad} ${className}`}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </button>
  );
}
