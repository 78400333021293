import React, { useState, useContext, useEffect } from "react";
import { BrowserRouter as Router, Route, Switch, Link } from "react-router-dom";
import TextTransition, { presets } from "react-text-transition";

import ReactGA from "react-ga";

import { Progress } from "shards-react";

import { useHistory, useParams, withRouter } from "react-router";
import "./HomePage.css";
import { Button } from "shards-react";

import Countdown, { zeroPad } from "react-countdown";
import FlipNumbers from "react-flip-numbers";

import ScrollAnimation from "react-animate-on-scroll";
import Marquee from "react-fast-marquee";
import { ReviewsList } from "./Reviews";

import TestIcon from "../../StaticImages/TestIcon.png";

import { useDeviceSelectors } from "react-device-detect";

import GradientBtn from "../../Components/GradientBtn/GradientBtn";

import {
  GetTotalQuestions,
  GetUniqueQuestionsAttempted,
} from "../../APIManager";

//hELO THIS IS A TEST

/* import CoverBox1Icon from "../../StaticImages/HomePage/CoverBox1.png";
import CoverBox2Icon from "../../StaticImages/HomePage/CoverBox2.png";
import CoverBox3Icon from "../../StaticImages/HomePage/CoverBox3.png";
import CoverBox4Icon from "../../StaticImages/HomePage/CoverBox4.png";
import CoverBox5Icon from "../../StaticImages/HomePage/CoverBox5.png"; */

import CoverBox1Icon from "../../StaticImages/HomePage/CoverBox1.svg";
import CoverBox2Icon from "../../StaticImages/HomePage/CoverBox2.svg";
import CoverBox3Icon from "../../StaticImages/HomePage/CoverBox3.svg";
import CoverBox4Icon from "../../StaticImages/HomePage/CoverBox4.svg";
import CoverBox5Icon from "../../StaticImages/HomePage/CoverBox5.svg";

import Feature1Image from "../../StaticImages/HomePage/Feature1.png";
import Feature2Image from "../../StaticImages/HomePage/Feature2.png";
import Feature3Image from "../../StaticImages/HomePage/Feature3.png";

//This is just a test

import AKULogo from "../../StaticImages/HomePage/Uni Logos/AKU-Logo.png";
import DUHSLogo from "../../StaticImages/HomePage/Uni Logos/DUHS-Logo.gif";
import JSMULogo from "../../StaticImages/HomePage/Uni Logos/JSMU-Logo.jpg";
import LUMHSLogo from "../../StaticImages/HomePage/Uni Logos/LUMHS-Logo.jpg";
import NTSLogo from "../../StaticImages/HomePage/Uni Logos/NTS-Logo.png";
import NUMSLogo from "../../StaticImages/HomePage/Uni Logos/NUMS-Logo.png";
import PMCLogo from "../../StaticImages/HomePage/Uni Logos/PMC-Logo.png";
import ShifaLogo from "../../StaticImages/HomePage/Uni Logos/Shifa-Logo.jpg";
import UHSLogo from "../../StaticImages/HomePage/Uni Logos/UHS-Logo.jpg";
import ETEALogo from "../../StaticImages/etealogo.jpg";
import ZULogo from "../../StaticImages/HomePage/Uni Logos/AKU-Logo.png";

import StatsRectangle from "../../StaticImages/HomePage/StatsRectangle.svg";
import QuotesSym from "../../StaticImages/HomePage/quotes.svg";
import SmallQuotesSym from "../../StaticImages/HomePage/smallquotes.svg";

import useMediaQuery from "@material-ui/core/useMediaQuery";

import PreMedLogo from "../../StaticImages/PreMedLogo.png";

import hasnainImg from "../../StaticImages/Hasnain.jpg";
import umarImg from "../../StaticImages/Umar-Pic.jpg";
import fahdImg from "../../StaticImages/Fahd-Pic.jpg";
import sameerimg from "../../StaticImages/sameer-pic.jpg";
import styles from "./styles.module.css";

import video1 from "../../StaticImages/HomePage/UI Recording 1.mp4";
import video2 from "../../StaticImages/HomePage/UI Recording 3.mp4";
import video3 from "../../StaticImages/HomePage/UI Recording 2.mp4";

import ReviewBox from "../../Components/Review/ReviewBox";
import FeatureBox from "../../Components/FeatureAnimationBox/FeatureBox";
import Footer from "../../Components/Footer/Footer";
import { UserContext } from "../../userContext";
import { StarOutlineSharp, StyleSharp } from "@material-ui/icons";

import MyGoal from "./MyGoal/MyGoal";

const HomePage = () => {
  const history = useHistory();
  const DeviceQuery1230 = useMediaQuery("(max-width:1900px)");

  const [selectors, data] = useDeviceSelectors(window.navigator.userAgent);
  const { isSafari } = selectors;

  const COVERBOX_HIGHLIGHT_STYLE = {
    color: "#08bc7d",
    display: "inline-block",
    lineHeight: "0em",
  };

  const TestTexts = ["MDCAT", "AKU Test", "NUMS Test"];
  const [index, setIndex] = React.useState(0);

  React.useEffect(() => {
    const intervalId = setInterval(
      () => setIndex((index) => index + 1),
      2000 // every 3 seconds
    );
    return () => clearTimeout(intervalId);
  }, []);

  let [userContext, SetUserContext] = useContext(UserContext);

  const isPremium = userContext.addonspurchased.includes("Premium");

  return (
    <div>
      {isPremium && <PremiumHeroBar User={userContext} />}
      <div hidden={isPremium} className={styles.Mobile_HomePage_Cover}>
        <div className={styles.Mobile_HomePage_Cover_Title}>
          <span>
            <span style={{ color: "#4285F4" }}>Ace</span> your <br />
            <span>MDCAT</span> with
            <br />
            Pre<span style={{ color: "#EC5863" }}>M</span>ed.PK
          </span>
        </div>
        <div className={styles.Mobile_HomePage_Cover_SubTitle}>
          <span>
            Get access to{" "}
            <span style={{ color: "#38761d", fontWeight: "800" }}>
              {" "}
              Pakistan’s largest MDCAT QBank
            </span>{" "}
            of more than{" "}
            <span style={{ color: "#e06666", fontWeight: "800" }}>
              34,000 MCQs
            </span>{" "}
            from latest PMC 2022 syllabus at{" "}
            <span style={{ color: "#e06666", fontWeight: "800" }}>50% off</span>{" "}
            in just{" "}
            <span className={styles.PriceWrapper}>
              <div className={styles.PriceSlash}></div> <div>Rs. 4000</div>
            </span>{" "}
            <span style={{ color: "#6d9eeb", fontWeight: "800" }}>
              Rs. 2000
            </span>
          </span>
          <Countdown
            date={
              new Date(
                GetOfferTime().getFullYear(),
                GetOfferTime().getMonth(),
                GetOfferTime().getDate() + 1 + 0
              )
            }
            renderer={(props) => {
              return (
                <div className={styles.TimerContainer}>
                  <div>THIS OFFER WILL EXPIRE IN</div>
                  <div className={styles.TimerTimeContainer}>
                    <div className={styles.TimerNumBox}>
                      {zeroPad(props.days)}
                      <div className={styles.TimerUnit}>DAYS</div>
                    </div>
                    <span>:</span>
                    <div className={styles.TimerNumBox}>
                      {zeroPad(props.hours)}
                      <div className={styles.TimerUnit}>HOURS</div>
                    </div>
                    <span>:</span>
                    <div className={styles.TimerNumBox}>
                      {zeroPad(props.minutes)}
                      <div className={styles.TimerUnit}>MINUTES</div>
                    </div>
                    <span>:</span>
                    <div className={styles.TimerNumBox}>
                      <div className={styles.TimerUnit}>SECONDS</div>
                      {zeroPad(props.seconds)}
                    </div>
                  </div>
                </div>
              );
            }}
          />
        </div>
        <div className={styles.Mobile_HomePage_Cover_Button}>
          <Link to={"/plans"}>
            <Button
              style={{ marginBottom: "1em" }}
              size="lg"
              theme="QuestionBlue"
            >
              Avail the offer right now!
            </Button>
          </Link>
          <br />
          <Link to={"/attempt?deck=PMC%20National%20MDCAT%202020"}>
            <Button size="" theme="RedAccent">
              Access FREE past papers first!
            </Button>
          </Link>
        </div>
      </div>
      <div className={styles.MobileBoxesContainer}>
        <div className={styles.MobileBoxesColumn}>
          <div className={styles.MobileBox}>
            <h3>34,000+</h3>
            <div>Questions one-click away</div>
            <img src={CoverBox1Icon} />
          </div>
          <div className={styles.MobileBox}>
            <h3>70+</h3>
            <div>Timed Yearly Papers</div>
            <img src={CoverBox2Icon} />
          </div>
          <div className={styles.MobileBox}>
            <h3>Detailed</h3>
            <div>Explanations</div>
            <img src={CoverBox3Icon} />
          </div>
        </div>
        <div className={styles.MobileBoxesColumn}>
          <div className={`${styles.MobileBox} ${styles.MobileBox4}`}>
            <h3>Real-Time</h3>
            <div>Stats and Analysis</div>
            <img src={CoverBox4Icon} />
          </div>
          <div className={`${styles.MobileBox} ${styles.MobileBox5}`}>
            <h3>Save</h3>
            <div>Questions to review later</div>
            <img
              src={CoverBox5Icon}
              className={styles.SmallMobileFeatureIcon}
            />
          </div>
        </div>
      </div>
      <div className={styles.HomePageCover}>
        <div hidden={isPremium} className={styles.CoverTitle}>
          <h1>
            <span style={{ color: "#4285F4" }}>Ace</span> your{" "}
            {!DeviceQuery1230 ? (
              <>
                {" "}
                <span>
                  {!isSafari ? (
                    <TextTransition
                      text={TestTexts[index % TestTexts.length]}
                      springConfig={presets.default}
                      inline
                    />
                  ) : (
                    "MDCAT"
                  )}
                </span>{" "}
                <span className={styles.TestTextTransition}>with</span>{" "}
              </>
            ) : (
              <>
                <br />
                <span>
                  {!isSafari ? (
                    <TextTransition
                      text={TestTexts[index % TestTexts.length]}
                      springConfig={presets.default}
                      inline
                    />
                  ) : (
                    "MDCAT"
                  )}
                </span>{" "}
                <span className={styles.TestTextTransition}>with</span>{" "}
              </>
            )}
            <br />
            Pre<span style={{ color: "#EC5863" }}>M</span>ed.PK
          </h1>
          <h2>
            <span>
              Get access to{" "}
              <span style={{ color: "#38761d", fontWeight: "800" }}>
                {" "}
                Pakistan’s largest MDCAT QBank
              </span>{" "}
              of more than{" "}
              <span style={{ color: "#e06666", fontWeight: "800" }}>
                34,000 MCQs
              </span>{" "}
              from latest PMC 2022 syllabus at{" "}
              <span style={{ color: "#e06666", fontWeight: "800" }}>
                50% off
              </span>{" "}
              in just{" "}
              <span className={styles.PriceWrapper}>
                <div className={styles.PriceSlash}></div> <div>Rs. 4000</div>
              </span>{" "}
              <span style={{ color: "#6d9eeb", fontWeight: "800" }}>
                Rs. 2000
              </span>
            </span>
          </h2>
          <Countdown
            date={
              new Date(
                GetOfferTime().getFullYear(),
                GetOfferTime().getMonth(),
                GetOfferTime().getDate() + 1
              )
            }
            renderer={(props) => {
              return (
                <div className={styles.TimerContainer}>
                  <div>THIS OFFER WILL EXPIRE IN</div>
                  <div className={styles.TimerTimeContainer}>
                    <div className={styles.TimerNumBox}>
                      {zeroPad(props.days)}
                      <div className={styles.TimerUnit}>DAYS</div>
                    </div>
                    <span>:</span>
                    <div className={styles.TimerNumBox}>
                      {zeroPad(props.hours)}
                      <div className={styles.TimerUnit}>HOURS</div>
                    </div>
                    <span>:</span>
                    <div className={styles.TimerNumBox}>
                      {zeroPad(props.minutes)}
                      <div className={styles.TimerUnit}>MINUTES</div>
                    </div>
                    <span>:</span>
                    <div className={styles.TimerNumBox}>
                      <div className={styles.TimerUnit}>SECONDS</div>
                      {zeroPad(props.seconds)}
                    </div>
                  </div>
                </div>
              );
            }}
          />
          <div>
            <Link to={"/plans"}>
              <Button
                style={{ marginBottom: "1em" }}
                size="lg"
                theme="QuestionBlue"
              >
                Avail the offer right now!
              </Button>
            </Link>
            <br />
            <Link to={"/attempt?deck=PMC%20National%20MDCAT%202020"}>
              <Button size="lg" theme="RedAccent">
                Access FREE past papers first!
              </Button>
            </Link>
          </div>
        </div>
        {isPremium && <PremiumCoverTitle User={userContext} />}
        <ScrollAnimation duration={2} animateIn="bounceInRight">
          <div className={styles.CoverBoxesContainer}>
            <div className={styles.BoxesColumn}>
              <div className={`${styles.Box}`}>
                <div className={styles.BoxImageDiv}>
                  <img src={CoverBox1Icon} />
                </div>
                <div className={styles.BoxTextDiv}>
                  <span>
                    <span
                      style={{
                        color: "var(--QuestionBlue)",
                        fontWeight: "800",
                      }}
                    >
                      34,000+
                    </span>{" "}
                    Questions one-click away
                  </span>
                </div>
              </div>
              <div className={`${styles.Box}`}>
                <div className={styles.BoxImageDiv}>
                  <img src={CoverBox2Icon} />
                </div>
                <div className={styles.BoxTextDiv}>
                  <span>
                    <span
                      style={{
                        color: "var(--QuestionBlue)",
                        fontWeight: "800",
                      }}
                    >
                      70+
                    </span>
                    <br />
                    Timed Yearly Papers
                  </span>
                </div>
              </div>
              <div className={`${styles.Box}`}>
                <div className={styles.BoxImageDiv}>
                  <img src={CoverBox3Icon} />
                </div>
                <div className={styles.BoxTextDiv}>
                  <span>
                    <span
                      style={{
                        color: "var(--QuestionBlue)",
                        fontWeight: "800",
                      }}
                    >
                      Detailed
                    </span>
                    <br />
                    Explanations
                  </span>
                </div>
              </div>
            </div>
            <div className={`${styles.BoxesColumn} ${styles.BoxesColumn2}`}>
              <div className={`${styles.Box}`}>
                <div className={styles.BoxImageDiv}>
                  <img src={CoverBox4Icon} />
                </div>
                <div className={styles.BoxTextDiv}>
                  <span>
                    <span
                      style={{
                        color: "var(--QuestionBlue)",
                        fontWeight: "800",
                      }}
                    >
                      Real-Time
                    </span>
                    <br />
                    Stats and Analysis
                  </span>
                </div>
              </div>
              <div className={`${styles.Box}`}>
                <div className={styles.BoxImageDiv}>
                  <img src={CoverBox5Icon} />
                </div>
                <div className={styles.BoxTextDiv}>
                  <span>
                    <span
                      style={{
                        color: "var(--QuestionBlue)",
                        fontWeight: "800",
                      }}
                    >
                      Save
                    </span>
                    <br />
                    Questions to review later
                  </span>
                </div>
              </div>
            </div>
          </div>
        </ScrollAnimation>
      </div>

      <div className={styles.UniHeading}>
        <h1
          style={{
            color: "var(--RedAccent)",
            fontWeight: "800",
          }}
        >
          <span style={{ color: "var(--QuestionBlue)" }}>Everything</span> in
          one place!
        </h1>
        <p>A comprehensive resource of tests across all boards</p>
      </div>
      <UniLogos />
      <br />
      <div className={styles.UniHeading}>
        <h1 style={{ color: "var(--RedAccent)" }}>
          Let the numbers{" "}
          <span style={{ color: "var(--QuestionBlue)" }}>speak</span> for
          themselves...{" "}
        </h1>
        <p>
          Last year, 10,000 future doctors trusted PreMed.PK for their medical
          school admission tests.
        </p>
      </div>
      <br />
      <div className={styles.StatsContainer}>
        <div>
          <div className={styles.SRectangle1}>
            <img src={StatsRectangle} />
          </div>
          <div className={styles.SRectangle2}>
            <img src={StatsRectangle} />
          </div>
          <div className={styles.SRectangle3}>
            <img src={StatsRectangle} />
          </div>
          <div className={styles.StatsDiv}>
            <div>
              <h2>35K</h2>
              <p>SIGNUPS</p>
            </div>
            <div>
              <h2>13M</h2>
              <p>MINUTES SPENT</p>
            </div>
            <div>
              <h2>22M</h2>
              <p>ATTEMPTS</p>
            </div>
          </div>
        </div>
      </div>
      <br />
      {/* <div className={styles.ReviewsMainContainer}>
        <div>
          <img className={styles.ReviewQuoteBig} src={QuotesSym} />
          <h1>Real Stories from real people</h1>
          <h2>Get inspired by these stories</h2>
          <div className={styles.ReviewBoxContainer}>
            <div
              className={`${styles.ReviewBoxColumn} ${styles.ReviewBoxColumn1}`}
            >
              <div className={`${styles.ReviewBox} ${styles.ReviewBox1}`}>
                <div className={styles.ReviewLogo}>
                  <img src={AKULogo} />
                </div>

                <div className={styles.ReviewText}>
                  <img
                    className={styles.ReviewQuoteSmall}
                    src={SmallQuotesSym}
                  />
                  <strong>
                    {" "}
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Minus voluptatibus deserunt odit fuga vero commodi
                  </strong>
                </div>
                <div className={styles.ReviewAuthor}>Taha Sheikh</div>
                <div className={styles.ReviewAuthorUni}>AKU Class of '026</div>
              </div>
            </div>
            <div
              className={`${styles.ReviewBoxColumn} ${styles.ReviewBoxColumn2}`}
            >
              <div className={`${styles.ReviewBox} ${styles.ReviewBox2} `}>
                Box 2
              </div>
              <div className={`${styles.ReviewBox} ${styles.ReviewBox3}`}>
                {" "}
                Box 3
              </div>
            </div>
          </div>
        </div>
      </div> */}
      <br />
      <br />
      <br />
      <br />

      <div className={styles.UniHeading}>
        <h1 style={{ color: "var(--RedAccent)", fontSize: "2.5em" }}>
          Hear from the ones who dreamed & then turned it into{" "}
          <span style={{ color: "var(--QuestionBlue)" }}>reality!</span>
        </h1>
      </div>

      <Marquee gradient={false} className={styles.ReviewMarquee} speed={50}>
        {ReviewsList.map((review) => {
          return (
            <div className={`${styles.ReviewBox}`}>
              <div className={styles.ReviewLogo}>
                <img src={review.UniLogo} />
              </div>

              <div className={styles.ReviewText}>
                <img className={styles.ReviewQuoteSmall} src={SmallQuotesSym} />
                <strong>{review.ReviewContent}</strong>
              </div>
              <div className={styles.ReviewAuthor}>{review.Name}</div>
              <div className={styles.ReviewAuthorUni}>{review.UniName}</div>
            </div>
          );
        })}
      </Marquee>
      <div>
        <div style={{ minHeight: "40em", marginTop: "2em" }}>
          <FeatureBox
            h1=" Test your understanding with over 23,000 questions with detailed explanations"
            h2={
              <>
                {" "}
                <span style={{ color: "var(--QuestionBlue)" }}>
                  Interactive{" "}
                </span>{" "}
                User Interface
              </>
            }
            span="Features to facilitate you such as Report, Skip, and Save a Question"
            align="Left"
            buttonText={
              userContext.isLoggedIn || true
                ? "ATTEMPT PMC PAPER FOR FREE"
                : "Register for Free"
            }
            video={video1}
          />
        </div>
        <div style={{ minHeight: "40em", marginTop: "-2em" }}>
          <FeatureBox
            h1="Track your progress with real-time statistics available on the interactive dashboard!"
            h2={
              <>
                {" "}
                <span style={{ color: "var(--QuestionBlue)" }}>Live </span>{" "}
                Performance Stats and Analysis
              </>
            }
            span=" View metrics such as average time per question, percentage accuracy and correct ratio to gauge your performance."
            align="Right"
            buttonText={
              userContext.isLoggedIn || true
                ? "ATTEMPT PMC PAPER FOR FREE"
                : "Register for Free"
            }
            video={video3}
          />
        </div>
        <div style={{ minHeight: "30em", marginTop: "-1em" }}>
          <FeatureBox
            h1="Practice questions to remember important questions via active learning to get the highest score possible "
            h2={
              <>
                {" "}
                <span style={{ color: "var(--QuestionBlue)" }}>
                  34,000+{" "}
                </span>{" "}
                Questions divided into yearly and topical sections!
              </>
            }
            span="Analyse your performance after each attempt to strengthen short-comings."
            align="Left"
            buttonText={
              userContext.isLoggedIn || true
                ? "ATTEMPT PMC PAPER FOR FREE"
                : "Register for Free"
            }
            video={video2}
          />
        </div>
        <br />
        <br />
        <br />
      </div>
    </div>
  );
};

const PremiumHeroBar = ({ User }) => {
  const [totalQuestions, SetTotalQuestions] = useState(0);
  const [uniqueQuestions, SetUniqueQuestions] = useState(0);

  useEffect(() => {
    GetTotalQuestions().then((res) => {
      SetTotalQuestions(res.data[0].count);
    });
    GetUniqueQuestionsAttempted().then((res) => {
      SetUniqueQuestions(res.data);
    });
  }, []);

  var timerHeading = "⏰ Time left till PMC National MDCAT";
  var timerYear = 2022;
  var timerMonth = 8;
  var timerDay = 7;

  if (User.milestones.includes("GoalsSet")) {
    timerHeading = (
      <>
        🎓 Time left to prepare for my dream university,
        <br /> {User.otherinfo.Goals.DreamUni}
      </>
    );
    var newDate = new Date(User.otherinfo.Goals.ExamDate);
    var timerYear = newDate.getFullYear();
    var timerMonth = newDate.getMonth();
    var timerDay = newDate.getDate();

    console.log(User.otherinfo.Goals.ExamDate);
    console.log(timerYear);
  }

  const [modalOpen, SetModalOpen] = useState(false);

  return (
    <>
      <MyGoal
        isOpen={modalOpen}
        OnClose={() => {
          SetModalOpen(false);
        }}
      />
      <div
        style={{ minHeight: "100vh" }}
        className={styles.Mobile_HomePage_Cover}
      >
        <div className={styles.Mobile_HomePage_Cover_Title}>
          <span>
            Hi{" "}
            <span style={{ color: "#4285F4" }}>
              {User.fullname.split(" ")[0]}👋
            </span>
            <br />
            <span>Ready to ace your MDCAT</span> with
            <br />
            Pre<span style={{ color: "#EC5863" }}>M</span>ed.PK
          </span>
        </div>
        <div className={styles.Mobile_HomePage_Cover_SubTitle}>
          <span> Set your goal by clicking on the timer below!</span>
          <Countdown
            date={new Date(timerYear, timerMonth, timerDay)}
            renderer={(props) => {
              return (
                <div className={styles.TimerContainer}>
                  <div>{timerHeading}</div>
                  <div className={styles.TimerTimeContainer}>
                    <div className={styles.TimerNumBox}>
                      {zeroPad(props.days)}
                      <div className={styles.TimerUnit}>DAYS</div>
                    </div>
                    <span>:</span>
                    <div className={styles.TimerNumBox}>
                      {zeroPad(props.hours)}
                      <div className={styles.TimerUnit}>HOURS</div>
                    </div>
                    <span>:</span>
                    <div className={styles.TimerNumBox}>
                      {zeroPad(props.minutes)}
                      <div className={styles.TimerUnit}>MINUTES</div>
                    </div>
                    <span>:</span>
                    <div className={styles.TimerNumBox}>
                      <div className={styles.TimerUnit}>SECONDS</div>
                      {zeroPad(props.seconds)}
                    </div>
                  </div>
                  <section>
                    <GradientBtn
                      style={{
                        fontSize: "0.4em",
                        borderRadius: "30px",
                        margin: 0,
                        marginRight: "1em",
                        padding: "0.8em 2.5em",
                        width: "100%",
                      }}
                      gradient={"#EC5863 0%, #9BC3F3 40%"}
                      onClick={() => {
                        SetModalOpen(true);
                        ReactGA.event({
                          category: "FeatureUsage",
                          action: "SetGoalBtn",
                        });
                      }}
                    >
                      Set my goal
                    </GradientBtn>
                  </section>
                </div>
              );
            }}
          />
        </div>
        <div className={styles.Mobile_HomePage_Cover_Button}>
          <label className={styles.YouHaveAttempted}>You have attempted </label>
          <br />
          <Progress
            className={styles.ProgressBar}
            theme="primary"
            value={(uniqueQuestions * 100) / totalQuestions}
          />
          <div className={styles.AttemptNumbers}>
            <span style={{ color: "#EC5863" }}>{uniqueQuestions} </span> out of{" "}
            <span style={{ color: "#EC5863" }}>{totalQuestions}</span> questions
          </div>
          <Link to={"/topicals"}>
            <Button
              style={{ marginBottom: "1em" }}
              size=""
              theme="QuestionBlue"
            >
              Attempt Topical MCQs
            </Button>
          </Link>
          <br />
          <Link to={"/yearly"}>
            <Button size="" theme="RedAccent">
              Attempt Yearly MCQs
            </Button>
          </Link>
        </div>
      </div>
    </>
  );
};

const PremiumCoverTitle = ({ User }) => {
  const [totalQuestions, SetTotalQuestions] = useState(0);
  const [uniqueQuestions, SetUniqueQuestions] = useState(0);

  useEffect(() => {
    GetTotalQuestions().then((res) => {
      SetTotalQuestions(res.data[0].count);
    });
    GetUniqueQuestionsAttempted().then((res) => {
      SetUniqueQuestions(res.data);
    });
  }, []);

  var timerHeading = "⏰ Time left till PMC National MDCAT";
  var timerYear = 2022;
  var timerMonth = 8;
  var timerDay = 7;

  if (User.milestones.includes("GoalsSet")) {
    timerHeading = (
      <>
        🎓 Time left to prepare for my dream university,
        <br /> {User.otherinfo.Goals.DreamUni}
      </>
    );
    var newDate = new Date(User.otherinfo.Goals.ExamDate);
    var timerYear = newDate.getFullYear();
    var timerMonth = newDate.getMonth();
    var timerDay = newDate.getDate();

    console.log(User.otherinfo.Goals.ExamDate);
    console.log(timerYear);
  }

  const [modalOpen, SetModalOpen] = useState(false);

  return (
    <div className={styles.CoverTitle}>
      <MyGoal
        isOpen={modalOpen}
        OnClose={() => {
          SetModalOpen(false);
        }}
      />
      <h1>
        <span>
          Hi{" "}
          <span style={{ color: "#4285F4" }}>
            {User.fullname.split(" ")[0]}👋
          </span>
          <br />
          <span>Ready to ace your MDCAT with</span>
          <br />
          Pre<span style={{ color: "#EC5863" }}>M</span>ed.PK
        </span>
      </h1>
      <h2>
        <span> Set your goal by clicking on the timer below!</span>
      </h2>
      <Countdown
        date={new Date(timerYear, timerMonth, timerDay)}
        renderer={(props) => {
          return (
            <div
              className={styles.TimerContainer}
              style={{ "max-width": "600px" }}
            >
              <div style={{ textAlign: "center" }}>{timerHeading}</div>
              <div className={styles.TimerTimeContainer}>
                <div className={styles.TimerNumBox}>
                  {zeroPad(props.days)}
                  <div className={styles.TimerUnit}>DAYS</div>
                </div>
                <span>:</span>
                <div className={styles.TimerNumBox}>
                  {zeroPad(props.hours)}
                  <div className={styles.TimerUnit}>HOURS</div>
                </div>
                <span>:</span>
                <div className={styles.TimerNumBox}>
                  {zeroPad(props.minutes)}
                  <div className={styles.TimerUnit}>MINUTES</div>
                </div>
                <span>:</span>
                <div className={styles.TimerNumBox}>
                  <div className={styles.TimerUnit}>SECONDS</div>
                  {zeroPad(props.seconds)}
                </div>
              </div>
              <section>
                <GradientBtn
                  style={{
                    fontSize: "0.4em",
                    borderRadius: "30px",
                    margin: 0,
                    marginRight: "1em",
                    padding: "0.8em 2.5em",
                    width: "100%",
                  }}
                  gradient={"#EC5863 0%, #9BC3F3 40%"}
                  onClick={() => {
                    SetModalOpen(true);
                    ReactGA.event({
                      category: "FeatureUsage",
                      action: "SetGoalBtn",
                    });
                  }}
                >
                  Set my goal
                </GradientBtn>
              </section>
            </div>
          );
        }}
      />
      <div className={styles.LaptopCoverSecondBoxContainer}>
        <label
          className={styles.YouHaveAttempted}
          style={{ textAlign: "center", width: "100%" }}
        >
          You have attempted{" "}
        </label>
        <br />
        <Progress
          className={styles.ProgressBar}
          theme="primary"
          value={(uniqueQuestions * 100) / totalQuestions}
        />
        <div className={styles.AttemptNumbers} style={{ textAlign: "center" }}>
          <span style={{ color: "#EC5863" }}>{uniqueQuestions} </span> out of{" "}
          <span style={{ color: "#EC5863" }}>{totalQuestions}</span> questions
        </div>
        <Link to={"/topicals"}>
          <Button style={{ marginRight: "1em" }} size="" theme="QuestionBlue">
            Attempt Topical MCQs
          </Button>
        </Link>
        <Link to={"/yearly"}>
          <Button size="" theme="RedAccent">
            Attempt Yearly MCQs
          </Button>
        </Link>
      </div>
    </div>
  );
};

const UniLogos = () => {
  return (
    <div className={styles.UniLogosContainer} id="scrolly-div">
      <div className={styles.UniLogosRow}>
        <UniLogo logo={DUHSLogo} order={9} />
        <UniLogo
          logo="https://premedpk-cdn.sgp1.digitaloceanspaces.com/CDN/UniLogos/KMDC%20Logo.jpg"
          order={7}
        />
        <UniLogo logo={JSMULogo} order={5} />
        <UniLogo logo={LUMHSLogo} order={3} />
        <UniLogo logo={NTSLogo} order={1} />
      </div>
      <div className={styles.UniLogosRow}>
        <UniLogo logo={NUMSLogo} order={10} />
        <UniLogo logo={PMCLogo} order={8} />
        <UniLogo logo={ShifaLogo} order={6} />
        <UniLogo logo={UHSLogo} order={4} />
        <UniLogo logo={ETEALogo} order={2} />
      </div>
    </div>
  );
};

const UniLogo = ({ logo, order }) => {
  var duration = Math.random() * (3 - 0.7) + 0.5;
  const MinTime = 1;
  const OrderFactor = 0.2;
  duration = MinTime + OrderFactor * order;
  return (
    <ScrollAnimation duration={duration} animateIn="bounceInLeft">
      <div className={styles.UniLogoDiv}>
        <img src={logo} />
      </div>
    </ScrollAnimation>
  );
};

export default HomePage;

function GetOfferTime2() {
  // Get the current date and time
  var currentDate = new Date();

  // Add 3 days to the current date
  currentDate.setDate(currentDate.getDate() + 1);

  // Set the time to midnight (00:00:00)
  currentDate.setHours(0, 0, 0, 0);

  // Return the resulting date object
  return currentDate;
}

export function GetOfferTime() {
  // Get the current date and time
  var currentDate = new Date();

  return currentDate;

  // Add 1 day to the current date
  currentDate.setDate(currentDate.getDate() + 1);

  // Check if the date is divisible by 3
  while (currentDate.getDate() % 1 !== 0) {
    // If the date is not divisible by 3, add 1 day
    currentDate.setDate(currentDate.getDate() + 1);
  }

  // Set the time to midnight (00:00:00)
  currentDate.setHours(11, 59, 59, 59);

  // Return the resulting date object
  return currentDate;
}

export function FormatOfferDate(date) {
  // Get the day of the month from the date object
  const day = date.getDate();

  // Create a list of suffixes for the different days of the month
  const suffixes = ["th", "st", "nd", "rd"];

  // Determine the suffix for the day
  let suffix = "";
  if (day >= 4 && day < 21) {
    suffix = "th";
  } else {
    suffix = suffixes[day % 10];
  }

  // Get the month from the date object and convert it to a string
  const month = date.toLocaleString("default", { month: "long" });

  // Get the year from the date object
  const year = date.getFullYear();

  // Return the date as a string in the format "30th November 2022"
  return `${day}${suffix} ${month} ${year}`;
}
