import React, { useContext, useState } from "react";
import usePracticeDeck from "./Hooks/usePracticeDeck";
import PracticeQuestionComponent from "./PracticeQuestionComponent";
import TopicalDeckStartComponent from "./TopicalDeckStartComponent";

import CircularProgress from "@material-ui/core/CircularProgress";
import Footer from "../NewFooter/Footer";
import { UserContext } from "../../userContext";
import { useHistory } from "react-router-dom";

import { Modal, ModalBody, ModalHeader } from "shards-react";
import GradientBtn from "../../Components/GradientBtn/GradientBtn.jsx";
import ClearOutlinedIcon from "@material-ui/icons/ClearOutlined";
import { History } from "@material-ui/icons";
import { useEffect } from "react";

export default function TopicalPracticeComponent() {
  const {
    deckName,
    questionNo,
    NextQuestion,
    PreviousQuestion,
    showStartPage,
    showQuestionPage,
    NoOfQuestions,
    currentQuestion,
    nextEnabled,
    backEnabled,
    loadingQuestion,
    onStartCB,
    SkipToQuestion,
    OnAttempt,
    RefreshQuestion,
  } = usePracticeDeck();

  const history = useHistory();
  const isPastPaperTopicals = window.location.pathname.includes("pastpaper");
  const [user, SetUser] = useContext(UserContext);
  const isPremium = user.addonspurchased.includes("Premium");

  const [pastPaperModalOpen, SetPastPaperModalOpen] = useState(false);
  const [practiceModalOpen, SetPracticeModalOpen] = useState(false);

  useEffect(() => {
    if (questionNo > 10 && !isPremium && isPastPaperTopicals) {
      SetPastPaperModalOpen(true);
    }
  }, [questionNo, isPastPaperTopicals]);

  return (
    <>
      <PremiumPastPaperModal
        isOpen={pastPaperModalOpen}
        OnClick={() => {
          history.push("/plans");
        }}
        onClose={() => {
          SetPastPaperModalOpen();
          SkipToQuestion(10);
        }}
      />
      <PremiumPracticeModal
        isOpen={practiceModalOpen}
        OnClick={() => {
          history.push("/plans");
        }}
        onClose={() => {
          SetPracticeModalOpen(false);
        }}
      />
      {!showStartPage && !showQuestionPage && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flexWrap: "wrap",
            height: "90vh",
            alignContent: "center",
          }}
        >
          <CircularProgress size={100} />
        </div>
      )}
      {showStartPage && (
        <TopicalDeckStartComponent
          Name={deckName}
          NoOfQuestions={NoOfQuestions}
          onStartCB={() => {
            if (!isPremium && !isPastPaperTopicals) {
              SetPracticeModalOpen(true);
            } else {
              onStartCB();
            }
          }}
          isPastPaperTopicalMode={isPastPaperTopicals}
        />
      )}
      {showQuestionPage && (
        <PracticeQuestionComponent
          deckName={deckName}
          questionNo={questionNo}
          NextQuestion={NextQuestion}
          PreviousQuestion={PreviousQuestion}
          currentQuestion={currentQuestion}
          nextEnabled={nextEnabled}
          backEnabled={backEnabled}
          loadingQuestion={loadingQuestion}
          isExplanationPurchased={true}
          SkipToQuestion={SkipToQuestion}
          NoOfQuestions={NoOfQuestions}
          OnAttempt={OnAttempt}
          deckType={"Topical"}
          isBlurred={pastPaperModalOpen}
          RefreshQuestion={RefreshQuestion}
        />
      )}
      <Footer />
    </>
  );
}

const PremiumPastPaperModal = ({ isOpen, OnClick, onClose }) => {
  return (
    <Modal
      open={isOpen}
      toggle={() => {
        onClose();
      }}
    >
      <ModalHeader className="ShardsModalClosable">
        Premium Feature{" "}
        <div className={"ShardsModalCloseBtn"}>
          <ClearOutlinedIcon onClick={onClose} />
        </div>
      </ModalHeader>
      <ModalBody>
        <div
          style={{
            fontSize: "1.2em",
            textAlign: "center",
            fontFamily: "Rubik",
          }}
        >
          Your currrent plan only has access to 10{" "}
          <span style={{ color: "var(--QuestionBlue)" }}>Past Paper</span>{" "}
          topical questions Switch to our{" "}
          <span style={{ color: "var(--RedAccent)" }}>Premium Plan</span> to
          access all questions and much more!
          <br />
          <br />
          <GradientBtn
            style={{
              fontSize: "0.7em",
              borderRadius: "30px",
              margin: 0,
            }}
            gradient={"#D31027 0%, #EA384D  51%, #D31027  100%"}
            onClick={OnClick}
          >
            Purchase Premium Plan
          </GradientBtn>
        </div>
      </ModalBody>
    </Modal>
  );
};

const PremiumPracticeModal = ({ isOpen, OnClick, onClose }) => {
  return (
    <Modal
      open={isOpen}
      toggle={() => {
        onClose();
      }}
    >
      <ModalHeader className="ShardsModalClosable">
        Premium Feature{" "}
        <div className={"ShardsModalCloseBtn"}>
          <ClearOutlinedIcon onClick={onClose} />
        </div>
      </ModalHeader>
      <ModalBody>
        <div
          style={{
            fontSize: "1.2em",
            textAlign: "center",
            fontFamily: "Rubik",
          }}
        >
          Your currrent plan does not have access to{" "}
          <span style={{ color: "var(--QuestionBlue)" }}>
            Topical Practice Questions.
          </span>{" "}
          Switch to our{" "}
          <span style={{ color: "var(--RedAccent)" }}>Premium Plan</span> to
          access this feature!
          <br />
          <br />
          <GradientBtn
            style={{
              fontSize: "0.7em",
              borderRadius: "30px",
              margin: 0,
            }}
            gradient={"#D31027 0%, #EA384D  51%, #D31027  100%"}
            onClick={OnClick}
          >
            Purchase Premium Plan
          </GradientBtn>
        </div>
      </ModalBody>
    </Modal>
  );
};
